import React from 'react'
import { Route, IndexRoute, Redirect } from 'react-router'

import {requireAuthentication} from 'User/Auth/higherOrderComponents/AuthComponent'
import {redirectLoggedInUser} from 'User/Auth/higherOrderComponents/RedirectComponent'
import LogoutComponent from 'User/Auth/higherOrderComponents/LogoutComponent'

// NOTE: here we're making use of the `resolve.root` configuration
// option in webpack, which allows us to specify import paths as if
// they were from the root of the ~/src directory. This makes it
// very easy to navigate to files regardless of how deeply nested
// your current file is.

// Views
import NotFoundView from '404/404'
import MainPageView from 'MainPage/views/MainPageView'
import WerkeysMainPageView from 'WerkeysMainPage/views/MainPageView'
import EntryView from 'Entry/views/EntryView'
import MaintenanceView from 'MiscPages/views/MaintenancePage'

// multiple routes
import UserRoutes from 'User/shared/routes'
import OverviewRoutes from 'Overview/routes'
import ProjectRoutes from 'Projects/routes'
import EventsRoutes from 'Events/shared/routes'
import DocumentsRoutes from 'Documents/routes'
import TimesheetsRoutes from 'Timesheet/routes'
import MessagesRoutes from 'Messages/routes'
import MileageRoutes from 'MileageReports/routes'
import TodoRoutes from 'Todo/routes'
import MoreRoutes from 'More/routes'
import ReportsRoutes from 'Reports/routes'
import FileRoutes from 'FileUpload/routes'
import CustomersRoutes from 'Counterparties/Customers/routes'
import SuppliersRoutes from 'Counterparties/Suppliers/routes'
import InvoicesRoutes from 'Invoices/shared/routes'
import ArticlesRoutes from 'Articles/routes'
import WizardRoutes from 'Wizard/routes'
import MarketPlaceRoutes from 'MarketPlace/shared/routes'
import IntegrationsRoutes from 'Integrations/routes'
import EmployeesRoutes from 'Employees/routes'
import SalariesRoutes from 'Salaries/routes'
import VacanciesRoutes from 'Vacancies/routes'

import getGreyLabelOptions from 'GreyLabel/helpers/greyLabel'

const IS_MAINTAINED = false
const MainPage = getGreyLabelOptions().whiteLabel === 'werkeys'
  ? WerkeysMainPageView
  : MainPageView

const maintenanceRoutes = (
  <Route path='/'>
    <IndexRoute component={MaintenanceView} />
    <Route path='/maintenance' component={MaintenanceView} />
    <Redirect from='*' to='/maintenance' />
  </Route>
)

const usualRoutes = (
  <Route path='/'>
    <IndexRoute component={requireAuthentication(MainPage)} />
    <Route path='/entry' component={redirectLoggedInUser(EntryView)} />
    <Route path='/404' component={NotFoundView} />
    <Route path='/logout' component={LogoutComponent} />

    { UserRoutes }
    { FileRoutes }
    { DocumentsRoutes }
    { TimesheetsRoutes }
    { MileageRoutes }
    { ReportsRoutes }
    { ProjectRoutes }
    { MoreRoutes }
    { OverviewRoutes }
    { TodoRoutes }
    { MessagesRoutes }
    { EventsRoutes }
    { CustomersRoutes }
    { SuppliersRoutes }
    { InvoicesRoutes }
    { ArticlesRoutes }
    { WizardRoutes }
    { MarketPlaceRoutes }
    { IntegrationsRoutes }
    { EmployeesRoutes }
    { SalariesRoutes }
    { VacanciesRoutes }

    <Redirect from='*' to='/404' />
  </Route>
)

export default (IS_MAINTAINED && !window.localStorage.getItem('testLikeABoss')) ? maintenanceRoutes : usualRoutes
