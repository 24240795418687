import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'

import { loadEventAction } from 'Events/shared/actions/event'

export class LoadEventWrapper extends React.Component {
  static propTypes = {
    loadEventFromServer: PropTypes.func,
    event: PropTypes.object,
    params: PropTypes.object,
    ownProps: PropTypes.object,
    ComponentToRender: PropTypes.func
  };

  componentDidMount () {
    const { params, event, loadEventFromServer } = this.props
    if (params.id && !event.id) {
      loadEventFromServer(params.id)
    }
  }

  render () {
    const { ComponentToRender, ownProps } = this.props
    return <ComponentToRender {...ownProps} />
  }
}

export default function (Component) {
  function mapStateToProps (state, ownProps) {
    return {
      ComponentToRender: Component,
      event: state.events.newEvent,
      ownProps: ownProps
    }
  }

  function mapDispatchToProps (dispatch) {
    return {
      loadEventFromServer: (id) => { dispatch(loadEventAction(id, 'new')) }
    }
  }

  return connect(mapStateToProps, mapDispatchToProps)(LoadEventWrapper)
}
