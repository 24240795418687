let API_BASE
if (__PROD__) {
  API_BASE = 'https://live.mrshoebox.com'
} else if (__MILESTONE__) {
  API_BASE = 'https://release-candidate.mrshoebox.com'
} else {
  API_BASE = 'https://stage.mrshoebox.com'
}

let UPLOADS_BASE
if (__PROD__) {
  UPLOADS_BASE = 'https://uploads-mrshoebox-com.s3.amazonaws.com/se'
} else if (__MILESTONE__) {
  UPLOADS_BASE = 'https://rc-mrshoebox-com.s3.amazonaws.com/se'
} else {
  UPLOADS_BASE = 'https://devdata-mrshoebox-com.s3.amazonaws.com/se'
}

export {
  API_BASE,
  UPLOADS_BASE
}
