import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { reduxForm, Field } from 'redux-form'
import { get } from 'lodash'
import moment from 'moment'

// Components
import Navigation from 'Navigation/Navigation'
import Button from 'shared/components/Button/Button'
import DropDown from 'shared/components/FormDropdown'
import Input from 'shared/components/FormInput'
import FileListUpload from 'FileUpload/containers/FileListUpload'

// Actions
import { pushDepositAction } from 'Events/Deposit/actions/deposit'

class Deposit extends Component {
  static propTypes = {
    params: PropTypes.object,
    deposit: PropTypes.object,
    pushDeposit: PropTypes.func,
    t: PropTypes.func,
    settings: PropTypes.object,
    handleSubmit: PropTypes.func
  }
  render () {
    const { deposit, t, settings: { depositPaymentMethods, projects }, handleSubmit, params } = this.props

    return (
      <div className='f-column'>
        <FileListUpload files={deposit.files || []} />
        <div className='form f-column-top'>
          <div className='form__item'>
            <Field name='date' component={Input} placeholder={t('props', 'date').s} type='date' maxDate={moment()} />
          </div>
          <div className='form__item'>
            <Field name='amountWithoutVat' component={Input} placeholder={t('props', 'amount').s} type='float' />
          </div>
          <div className='form__item'>
            <Field
              name='paymentMethodId'
              component={DropDown}
              emptyOption={t('emptyOptions', 'paymentMethod').s}
              big hint={t('props', 'paymentMethod').s}
              options={depositPaymentMethods}
            />
          </div>
          <div className='form__item'>
            <Field
              name='projectId'
              component={DropDown}
              emptyOption={t('emptyOptions', 'project').s}
              big
              hint={t('props', 'project').s}
              options={projects}
            />
          </div>
        </div>
        <div className='f-column-bottom form-buttons'>
          <Button saveButton view='transparent-black' onClick={handleSubmit(this.props.pushDeposit)}>
            { params.id ? t('buttons', 'update').s : t('buttons', 'save').s }
          </Button>
          <Button view='transparent-red' goBackButton>{ t('buttons', 'cancel').s }</Button>
        </div>
      </div>
    )
  }
}

class DepositWithNavigation extends Component {
  static propTypes = {
    params: PropTypes.object,
    deposit: PropTypes.object,
    t: PropTypes.func
  }
  render () {
    const {deposit, t, params} = this.props
    const navBarTitle = deposit.id ? deposit.title : t('navbar', 'title').s
    const backLink = `/events/${get(params, 'id', '')}`
    return (
      <Navigation title={navBarTitle} hideFooter backLink={backLink} className='f-column'>
        <Deposit {...this.props} />
      </Navigation>
    )
  }
}

function mapStateToProps (state) {
  return {
    deposit: state.events.newEvent,
    initialValues: state.events.newEvent,
    filesToUpload: state.filesToUpload,
    settings: state.userSettings,
    t: state.i18n.get('app', 'views', 'Income', 'Deposit', 'DepositView')
  }
}

function mapDispatchToProps (dispatch, ownProps) {
  const isNewEvent = !ownProps.params.id
  const { location: { query: { cameFrom } } } = ownProps
  return {
    pushDeposit: (revenue) => { dispatch(pushDepositAction(isNewEvent, revenue, cameFrom)) }
  }
}

function validate (values) {
  let errors = {}
  if (!values.date) {
    errors.date = true
  }

  return errors
}

const formName = 'deposit'

const DepositForm = reduxForm({
  form: formName,
  validate,
  enableReinitialize: true
})(Deposit)

const DepositWithNavigationForm = reduxForm({
  form: formName,
  validate,
  enableReinitialize: true
})(DepositWithNavigation)

export const DepositView = connect(mapStateToProps, mapDispatchToProps)(DepositForm)
export default connect(mapStateToProps, mapDispatchToProps)(DepositWithNavigationForm)
