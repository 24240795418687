import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Link } from 'react-router'
import { Field } from 'redux-form'
import moment from 'moment'
import { isEmpty } from 'lodash'
import cs from 'classnames'
import { haveRotRut } from 'mrshoebox-ui-components/src/helpers/countrySpecific'

import getGreyLabelOptions from 'GreyLabel/helpers/greyLabel'

// Components
import DropDown from 'shared/components/FormDropdown'
import Input from 'shared/components/FormInput'
import Button from 'shared/components/Button/Button'
import PlusButton from 'shared/components/PlusButton/PlusButton'

// Invoice Components
import InvoiceTypeButton from './partials/InvoiceTypeButton'
import InvoiceCustomer from './partials/InvoiceCustomer'
import InvoiceVatType from './partials/InvoiceVatType'
import InvoiceRows from './partials/InvoiceRows'
import InvoiceImportModal from './partials/InvoiceImportModal'
import InvoiceMessage from './partials/InvoiceMessage'
import InvoiceTotals from './partials/InvoiceTotals'
import InvoiceChangeTypeModal from './partials/InvoiceChangeTypeModal'

// Constants
import {INVOICE, CREDIT_INVOICE} from 'Invoices/shared/constants/InvoiceTypes'
import { CUSTOMER_TYPE_INDIVIDUAL } from 'Counterparties/Customers/constants/CustomerTypes'

// Helpers
import { normalizeNegativeValue } from 'shared/helpers/formNormalizers'

// TODO: temporarily commented feePercentage input for TREP-671. Remove when its needed again.
// const maxLateFeePercentageValue = 99

// TODO: remove mixing self-written reducer formValues with redux-form, migrate to redux-form completely
export default class InvoiceCreate extends Component {
  static propTypes = {
    location: PropTypes.object,
    invoice: PropTypes.object,
    fields: PropTypes.object,
    formValues: PropTypes.object,
    currencies: PropTypes.array,
    saveInvoice: PropTypes.func,
    invoiceClear: PropTypes.func,
    params: PropTypes.object,
    t: PropTypes.func,
    redirectToRotRutForm: PropTypes.func,
    showMessageBox: PropTypes.func,
    invoiceUpdateProps: PropTypes.func,
    removeInvoiceRow: PropTypes.func,
    handleSubmit: PropTypes.func,
    invoiceSettings: PropTypes.object,
    redirectToDiscount: PropTypes.func,
    updateInvoiceRow: PropTypes.func,
    showInvoiceNumberHelp: PropTypes.func,
    changeInvoiceTypeToCredit: PropTypes.func,
    onCloseChangeTypeModal: PropTypes.func,
    clearMessage: PropTypes.func
  }

  constructor (props) {
    super(props)
    this.saveFormToInvoice = this.saveFormToInvoice.bind(this)
    this.onRotRutClick = this.onRotRutClick.bind(this)
    this.onOpenImportModal = this.onOpenImportModal.bind(this)
    this.changeInvoiceTypeModalState = this.changeInvoiceTypeModalState.bind(this)
    this.saveInvoice = this.saveInvoice.bind(this)
    this.redirectToDiscount = this.redirectToDiscount.bind(this)
    this.removeRotRut = this.removeRotRut.bind(this)
    this.changeDueDate = this.changeDueDate.bind(this)
    this.state = { showMessageArea: false, isChangeTypeModalOpened: false }
  }

  componentDidMount () {
    this.clearRotRut()
  }

  componentDidUpdate (_, prevState) {
    if (prevState.isChangeTypeModalOpened !== this.state.isChangeTypeModalOpened && !this.state.isChangeTypeModalOpened) {
      this.refs.changeTypeModal.close()
    }
  }

  clearRotRut () {
    const { invoice: { customer: { customerType } }, invoiceUpdateProps } = this.props
    if (customerType === CUSTOMER_TYPE_INDIVIDUAL) {
      invoiceUpdateProps({euVat: false, invertedVat: false, customerVatNumber: ''})
    } else {
      invoiceUpdateProps({rotRutObject: {}})
    }
  }

  removeRotRut () {
    const { invoiceUpdateProps } = this.props
    invoiceUpdateProps({rotRutObject: {}})
  }

  onRotRutClick () {
    const { invoice: { customer: { customerType }, invoiceRows }, redirectToRotRutForm, showMessageBox, t } = this.props
    if (customerType === CUSTOMER_TYPE_INDIVIDUAL && invoiceRows.length) {
      redirectToRotRutForm()
    } else if (customerType !== CUSTOMER_TYPE_INDIVIDUAL) {
      showMessageBox(t('customer', 'error').s, t('customer', 'errorText').s)
    } else if (!invoiceRows.length) {
      showMessageBox(t('row', 'error').s, t('row', 'isRequired').s)
    }
  }

  onOpenImportModal () {
    this.refs.importModal.open()
  }

  onTypeChangeDeny = () => {
    const { fields: { invoiceFee } } = this.props
    invoiceFee.onChange(normalizeNegativeValue(invoiceFee.value))
    this.changeInvoiceTypeModalState()
  }

  onTypeChangeConfirm = () => {
    const { fields: { invoiceFee } } = this.props
    invoiceFee.onChange(normalizeNegativeValue(invoiceFee.value))
    this.props.changeInvoiceTypeToCredit()
    this.changeInvoiceTypeModalState()
  }

  changeInvoiceTypeModalState () {
    this.setState({ isChangeTypeModalOpened: !this.state.isChangeTypeModalOpened })
  }

  saveFormToInvoice () {
    const {formValues, invoiceUpdateProps} = this.props
    invoiceUpdateProps(formValues)
  }

  saveInvoice () {
    const {saveInvoice, invoice: {customer, invoiceRows}, showMessageBox, t, fields: {invoiceFee}} = this.props

    if (isEmpty(customer)) {
      showMessageBox(t('customer', 'error').s, t('customer', 'isRequired').s)
    } else if (!invoiceRows.length) {
      showMessageBox(t('row', 'error').s, t('row', 'isRequired').s)
    } else if (invoiceFee.value < 0) {
      this.changeInvoiceTypeModalState()
      this.refs.changeTypeModal.open()
    } else {
      this.saveFormToInvoice()
      saveInvoice()
    }
  }

  redirectToDiscount () {
    const { invoice: { totals: { preTotal } }, showMessageBox, t } = this.props
    if (preTotal > 0) {
      this.props.redirectToDiscount()
      this.saveFormToInvoice()
    } else {
      showMessageBox('', t('errors', 'notTotal').s)
    }
  }

  changeDueDate (e) {
    const { fields: { invoiceDate, dueDate }, invoiceSettings, invoice: { customer } } = this.props
    invoiceDate.onChange(e)

    const days = customer.daysForPayment ? customer.daysForPayment : invoiceSettings.default_invoice_days
    const newDate = moment((e.target && e.target.value) || e).add(days, 'days').format('YYYY-MM-DD')

    dueDate.onChange(newDate)
  }

  changeReminderFee = (e) => {
    const { fields: { reminderFee } } = this.props
    reminderFee.onChange(normalizeNegativeValue(e.target.value))
  }

  render () {
    const {
      invoice,
      location: {pathname},
      params,
      t,
      invoiceClear,
      updateInvoiceRow,
      removeInvoiceRow,
      handleSubmit
    } = this.props
    const { whiteLabel } = getGreyLabelOptions()
    const questionStyle = cs('create-invoice__form__question', whiteLabel)
    return (
      <div className='create-invoice'>

        <div className='create-invoice__types-block'>
          <Field name='invoiceType' component={InvoiceTypeButton} radioValue={INVOICE} text={t('selects', 'invoiceType', String(INVOICE)).s} />
          <Field name='invoiceType' component={InvoiceTypeButton} radioValue={CREDIT_INVOICE} text={t('selects', 'invoiceType', String(CREDIT_INVOICE)).s} />
        </div>

        <div className='create-invoice__content'>
          <div className='create-invoice__left'>
            <InvoiceCustomer {...this.props} saveFormToInvoice={this.saveFormToInvoice} />

            <div className='form create-invoice__form'>
              <div className='form__item form__input-plus'>
                <Field name='invoiceNumber'component={Input} placeholder={t('labels', 'invoiceNumber').s} type='number' readOnly={Boolean(params.id)} />
                <div className={questionStyle} onClick={this.props.showInvoiceNumberHelp}><span className='icon-question' /></div>
              </div>
              <div className='create-invoice__form__item form__item'>
                <Field name='invoiceDate' component={Input} placeholder={t('labels', 'invoiceDate').s} onChange={this.changeDueDate} type='date' />
                <Field name='dueDate' component={Input} placeholder={t('labels', 'dueDate').s} type='date' />
              </div>
              <div className='create-invoice__form__item form__item'>
                <Field name='invoiceFee' component={Input} placeholder={t('labels', 'invoiceFee').s} type='float' />
                <div />
                {/* <Input placeholder={t('labels', 'lateFeePercentage').s} {...lateFeePercentage} type='float' maxValue={maxLateFeePercentageValue} /> */}
              </div>
              <div className='create-invoice__form__item form__item'>
                <Input placeholder={t('labels', 'off').s} value={invoice.discountAmount} type='number' readOnly onClick={this.redirectToDiscount} />
                <Field name='reminderFee' component={Input} placeholder={t('labels', 'reminderFee').s} type='float' onChange={this.changeReminderFee} />
              </div>
            </div>
            <InvoiceVatType {...this.props} />
            <div className='create-invoice__currency'>
              <Field name='currencyId' component={DropDown} big options={this.props.currencies || []} hint={t('labels', 'currency').s} />
            </div>
          </div>

          <div className='create-invoice__right'>
            <div className='create-invoice__add-rows-section'>
              <div className='hr'><span>{t('specification', 'name').s}</span></div>

              <InvoiceRows rows={invoice.invoiceRows} location={this.props.location} updateInvoiceRow={updateInvoiceRow} removeInvoiceRow={removeInvoiceRow} saveFormToInvoice={this.saveFormToInvoice} t={t} />

              <div className='create-invoice__add-rows-section__buttons'>
                <Link to={pathname + '/articles'} onClick={this.saveFormToInvoice}>
                  <Button view='transparent-black'>{t('buttons', 'createNewSeries').s}</Button>
                </Link>
                <Button view='transparent-black' onClick={this.onOpenImportModal}>{t('buttons', 'import').s}</Button>
                {haveRotRut && isEmpty(invoice.rotRutObject) && <Button view='transparent-black' onClick={this.onRotRutClick}>{t('labels', 'rotRut').s}</Button>}
                {!isEmpty(invoice.rotRutObject) && <div className='create-invoice__add-rows-section__rot-rut'>
                  <Input placeholder={t('labels', 'rotRut').s} value={invoice.totals.rotRut} type='number' readOnly onClick={this.onRotRutClick} />
                  <PlusButton type='minus' onClick={this.removeRotRut} />
                </div>}
              </div>
            </div>
            <InvoiceMessage t={t} value={this.props.formValues.message} clear={this.props.clearMessage} />
          </div>
        </div>

        <InvoiceTotals />

        <div className='f-column-bottom form-buttons'>
          <Button view='transparent-black' saveButton onClick={handleSubmit(this.saveInvoice)}>{t('buttons', 'save').s}</Button>
          <Button view='transparent-red' goBackButton onClick={invoiceClear}>{t('buttons', 'cancel').s}</Button>
        </div>

        <InvoiceImportModal ref='importModal' t={t} pathname={pathname} saveFormToInvoice={this.saveFormToInvoice} />
        <InvoiceChangeTypeModal ref='changeTypeModal' t={t} onConfirm={this.onTypeChangeConfirm} onDeny={this.onTypeChangeDeny} />
      </div>
    )
  }
}
