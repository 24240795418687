import {
  REPORTS_LIST_UPDATE, REPORTS_LIST_CLEAN,
  REPORT_LOAD_TO_STATE, REPORT_REMOVE_FROM_STATE
} from 'Reports/constants/ActionTypes'

const initialState = {
  listData: [],
  currentReport: {},
  lastPageLoaded: 0,
  hasNextPage: true,
  approvelegacyForm: {}
}

export default function (state = initialState, action) {
  switch (action.type) {
    case REPORTS_LIST_UPDATE:
      return {
        ...state,
        ...action.payload
      }
    case REPORTS_LIST_CLEAN:
      return {
        ...state,
        listData: []
      }
    case REPORT_LOAD_TO_STATE:
      return {
        ...state,
        currentReport: action.payload
      }
    case REPORT_REMOVE_FROM_STATE:
      return {
        ...state,
        currentReport: {}
      }
    default:
      return state
  }
}
