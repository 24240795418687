import * as Sentry from '@sentry/browser'
import { combineReducers } from 'redux'
import { reducer as legacyFormReducer } from 'redux-form-5'
import { reducer as formReducer } from 'redux-form'
import auth from 'User/Auth/reducers/authReducer'
import {i18n} from 'redux-pagan'

import { USER_LOGOUT } from 'User/Auth/constants/ActionTypes'

import events from 'Events/shared/reducers/eventsReducer'
import documents from '../Documents/reducers/documentsReducer'
import projects from '../Projects/reducers/projectsReducer'
import userSettings from 'User/Settings/reducers/userSettingsReducer'
import invoiceSettings from 'User/Settings/reducers/invoiceSettings'
import overview from 'Overview/reducers/overviewReducer'
import filesToUpload from 'FileUpload/reducers/filesToUploadReducer'
import messageBox from 'MessageBox/reducers/messageBoxReducer'
import project from '../Projects/reducers/projectReducer'
import importItems from 'ImportItems/reducers/importItemsReducer'
import invoices from 'Invoices/reducers/invoicesReducer'
import timesheets from 'Timesheet/reducers/timesheetsReducer'
import reports from '../Reports/reducers/reportsReducer'
import messages from '../Messages/reducers/messagesReducer'
import driverLogs from 'mrshoebox-ui-components/src/modules/DriverLogs/reducers'
import navbarSpinner from '../shared/reducers/navbarSpinnerReducer'
import todo from '../Todo/reducers/todoReducer'
import customers from 'Counterparties/Customers/reducers/customersReducer'
import suppliers from 'Counterparties/Suppliers/reducers/suppliersReducer'
import articles from 'Articles/reducers/articlesReducer'
import wizard from 'Wizard/reducers/wizardReducer'
import checkBoxList from 'CheckBoxList/reducers/checkBoxListReducer'
import notifications from 'Notifications/reducers/notificationReducer'
import appInfo from 'AppInfo/reducers/appInfoReducer'
import bridgeRequests from 'appBridge/reducers/bridgeRequestsReducer'
import phoneBook from 'appBridge/PhoneBook/reducers/phoneBookReducer'
import representationGuests from 'Events/Expense/reducers/representationGuestsReducer'
import suggestions from 'shared/components/SuggestionInput/Async/reducer'
import wizardProfile from 'Wizard/reducers/wizardProfileReducer'
import marketplace from 'MarketPlace/reducers'
import integrations from 'Integrations/reducers'
import employees from 'Employees/reducers'
import salaries from 'Salaries/reducers'
import vacancies from 'Vacancies/reducers'

const appReducer = combineReducers({
  auth,
  events,
  representationGuests,
  documents,
  projects,
  project,
  userSettings,
  invoiceSettings,
  overview,
  filesToUpload,
  messageBox,
  importItems,
  timesheets,
  driverLogs,
  reports,
  messages,
  todo,
  navbarSpinner,
  customers,
  suppliers,
  invoices,
  articles,
  wizard,
  i18n,
  checkBoxList,
  notifications,
  appInfo,
  bridgeRequests,
  phoneBook,
  legacyForm: legacyFormReducer,
  form: formReducer,
  suggestions,
  wizardProfile,
  marketplace,
  integrations,
  employees,
  salaries,
  vacancies
})

export default function (state, action) {
  let newState = state
  if (action.type === USER_LOGOUT) {
    newState = {
      i18n: state.i18n,
      notifications: state.notifications,
      appInfo: state.appInfo
    }
    Sentry.setUser()
  }
  return appReducer(newState, action)
}
