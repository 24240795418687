import { INVOICE_SETTINGS_FIELD_UPDATE, INVOICE_SETTINGS_FIELD_CLEAR } from '../constants/InvoiceSettings'

const initialState = {}

export default function (state = initialState, action) {
  switch (action.type) {
    case INVOICE_SETTINGS_FIELD_UPDATE:
      return {
        ...state,
        ...action.payload
      }
    case INVOICE_SETTINGS_FIELD_CLEAR:
      return {}
    default:
      return state
  }
}
