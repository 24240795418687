import React from 'react'
import axios from 'api-client-connector'
import { getCurrentClientCompany } from 'api-client-connector/utils'
import { camelizeKeys, decamelizeKeys } from 'humps'
import { isNil, omitBy } from 'lodash'
import { createAction } from 'redux-act'
import { routerActions } from 'react-router-redux'
import { SubmissionError } from 'redux-form'

import {
  showElementInMessageBoxAction,
  closeMessageBoxAction,
  showMessageBoxWithParamsAction
} from 'MessageBox/actions/messageBox'
import { hideNavbarSpinnerAction, showSlowNavbarSpinnerAction } from 'shared/actions/navbarSpinner'

import { CAPCITO_VALIDATE, CAPCITO_ONBOARDING } from 'MarketPlace/shared/constants/Api'

import InProgress from 'MarketPlace/shared/components/InProgress'

import { isIOS } from 'shared/helpers/utils'
import {
  updateUserSettings,
  updateCurrentCompanyInfo,
  updateInvoiceSettings
} from 'MarketPlace/shared/helpers/updateSettings'

export const capcitoValidatedFieldsToStore = createAction('Capcito validated fields to store')
export const capcitoRedirectLinkToStore = createAction('Store capcito redirect link')

export function getValidatedFields () {
  return async (dispatch) => {
    dispatch(showSlowNavbarSpinnerAction())
    const response = await getValidatedFieldsRequest()
    const { initialValues, currentErrors } = processFields(response.data)
    dispatch(capcitoValidatedFieldsToStore({ initialValues, currentErrors }))
    dispatch(hideNavbarSpinnerAction())

    return { initialValues, currentErrors }
  }
}

function getValidatedFieldsRequest () {
  return axios.post(CAPCITO_VALIDATE(), null)
}

function processFields (fields) {
  const fieldsDetails = camelizeKeys(fields)
    .map(({fieldData, fieldName}) => ({
      name: fieldName,
      value: fieldData.value,
      errors: fieldData.errors
    }))

  const valueReducer = (result, field) => (camelizeKeys({ ...result, [field.name]: field.value }))
  const initialValues = fieldsDetails.reduce(valueReducer, {})
  const errorsReducer = (result, field) => (camelizeKeys({ ...result, [field.name]: field.errors.length ? field.errors : null }))
  const currentErrors = omitBy(fieldsDetails.reduce(errorsReducer, {}), isNil)

  return { initialValues, currentErrors }
}

export function startOnboarding (settings) {
  return async (dispatch) => {
    dispatch(showSlowNavbarSpinnerAction())
    const { currentErrors } = await dispatch(updateSettingsAction(settings))

    if (!Object.keys(currentErrors).length) await dispatch(processOnboarding())
    dispatch(hideNavbarSpinnerAction())
    if (Object.keys(currentErrors).length) throw new SubmissionError(currentErrors)
  }
}

export function updateSettingsAction (settings) {
  return async (dispatch, getState) => {
    dispatch(showSlowNavbarSpinnerAction())

    let validationResult
    let errors = {}

    try {
      const userSettingsResponse = await updateUserSettings(settings)
      const { data: { errors: userSettingsErrors } } = userSettingsResponse

      if (userSettingsErrors && Object.keys(userSettingsErrors).length) errors = userSettingsErrors
    } catch (e) {
      dispatch(processError(e))
    }

    try {
      const response = await updateCurrentCompanyInfo(settings)
      errors = {
        ...errors,
        ...camelizeKeys(response.data.errors)
      }
      if (Object.keys(errors).length) return { currentErrors: camelizeKeys(errors) }
    } catch (e) {
      dispatch(processError(e))
    }

    try {
      const invoiceSettings = getState().invoiceSettings
      await updateInvoiceSettings(settings, invoiceSettings)
      if (Object.keys(errors).length) return { currentErrors: camelizeKeys(errors) }
    } catch (e) {
      console.log(e, e.response)
      dispatch(processError(e))
      errors = {
        ...errors,
        ...e.response.data.errors_stack
      }
      if (Object.keys(errors).length) return { currentErrors: camelizeKeys(errors) }
    }

    validationResult = await dispatch(getValidatedFields())
    dispatch(hideNavbarSpinnerAction())
    return validationResult
  }
}

export function processOnboarding () {
  return async (dispatch, getState) => {
    try {
      const response = await sendOnboardRequest()
      const closeHandler = () => {
        dispatch(closeMessageBoxAction())
        if (isIOS()) {
          // Capcito auth doesn't work in iOS iframe because cookies don't save in it,
          // so have to open new tab.
          window.open(response.data.redirect_link, '_blank')
          dispatch(routerActions.push(`/marketplace`))
        } else {
          dispatch([
            capcitoRedirectLinkToStore(response.data.redirect_link),
            routerActions.push(`/marketplace/capcito/portal`)
          ])
        }
      }
      if (response.status === 200) {
        const t = getState().i18n.get('app', 'views', 'MarketPlace')
        dispatch(showElementInMessageBoxAction(
          <InProgress
            closeHandler={closeHandler}
            description={t('capcitoInProgress', 'applicationCreated').s}
            buttonText={t('capcitoInProgress', 'button').s}
            customHeader={t('capcitoInProgress', 'customHeader').s}
          />,
          closeHandler
        ))
      }
    } catch (e) {
      const t = getState().i18n.get('app', 'shared', 'messages')
      dispatch(showMessageBoxWithParamsAction(null, t('unexpectedError', 'description').s))
    }
  }
}

function sendOnboardRequest () {
  return axios.post(CAPCITO_ONBOARDING(null), null)
}

export function loadOnboardingInfo (options) {
  return async (dispatch, getState) => {
    try {
      return camelizeKeys(await loadOnboardingInfoRequest(options))
    } catch (e) {
      if (e.request.status !== 404) {
        console.log('error', e)
        const t = getState().i18n.get('app', 'shared', 'messages')
        dispatch(showMessageBoxWithParamsAction(null, t('unexpectedError', 'description').s))
      }
      return null
    }
  }
}

function loadOnboardingInfoRequest (options) {
  const params = {
    company_id: getCurrentClientCompany(),
    ...decamelizeKeys(options)
  }
  return axios.get(CAPCITO_ONBOARDING(params))
}

function processError (response) {
  console.log('error', response)
  return hideNavbarSpinnerAction()
}
