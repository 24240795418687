import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { routerActions } from 'react-router-redux'
import { Helmet } from 'react-helmet'

import Loader from 'shared/components/Loader/Loader'
import { isIpadResolution } from 'shared/helpers/utils'

import styles from './styles.scss'

const Navigation = (props) => {
  const backButtonHandler = props.backLink
    ? () => props.historyReplace(props.backLink)
    : () => props.historyGoBack()
  return (
    <div className={styles.navigationContainer}>
      {!isIpadResolution() && <Loader />}
      {isIpadResolution() && <Loader forceSmartphoneTopMargin />}
      <div className={styles.navigationHeader}>
        <div
          onClick={backButtonHandler}
          className={styles.backButton}
        >
          <span className='icon-arrow-left' />
        </div>
        <div className={styles.navigationTitle}>
          <div className={styles.navigationTitleText}>
            { props.title }
          </div>
        </div>
      </div>

      <div className={`${styles.navigationContent} truncate`}>
        <Helmet>
          <meta
            name='viewport'
            content='width=device-width, initial-scale=1.0, user-scalable=no'
          />
        </Helmet>
        { props.children }
      </div>
    </div>
  )
}

Navigation.propTypes = {
  children: PropTypes.node,
  historyGoBack: PropTypes.func,
  historyReplace: PropTypes.func,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  backLink: PropTypes.string
}

function mapDispatchToProps (dispatch) {
  return {
    historyGoBack: () => { dispatch(routerActions.go(-1)) },
    historyReplace: (path) => { dispatch(routerActions.replace(path)) }
  }
}

export default connect(null, mapDispatchToProps)(Navigation)
