import './PaymentStatusField.scss?global'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { reduxForm, Field, getFormValues, change, arrayPush, arrayRemove, touch } from 'redux-form'
import moment from 'moment'

// Components
import Input from 'shared/components/FormInput'
import DropDown from 'shared/components/FormDropdown'
import PlusButton from 'shared/components/PlusButton/PlusButton'
import Tabs from 'shared/components/FormTabs'
import FormatNumber from 'shared/components/FormatNumber/FormatNumber'

// Constants
import { PAYMENT_STATUS_NOT_PAID, PAYMENT_STATUS_PAID } from 'Payments/constants'

// Helpers
import { normalizeNegativeValue } from 'shared/helpers/formNormalizers'

class PaymentStatusField extends Component {
  static propTypes = {
    addNewPayment: PropTypes.func,
    addPaymentToDelete: PropTypes.func,
    changeFieldValue: PropTypes.func,
    initialize: PropTypes.func,
    initialValues: PropTypes.object,
    payments: PropTypes.array,
    paymentMethods: PropTypes.arrayOf(PropTypes.object),
    formValues: PropTypes.object,
    t: PropTypes.func,
    provideController: PropTypes.func,
    removeNewPayment: PropTypes.func,
    removePaymentToEdit: PropTypes.func,
    withoutTabs: PropTypes.bool,
    eventAmount: PropTypes.number,
    markFieldAsTouched: PropTypes.func
  }

  get paymentsSum () {
    const { formValues: { paymentsToAdd, paymentsToEdit } } = this.props
    const allPayments = [...paymentsToAdd, ...paymentsToEdit]
    const paymentsSum = allPayments.reduce((res, curr) => res + Number(curr.amount || 0), 0)

    return paymentsSum
  }

  get paymentStatusTabs () {
    const { t, changeFieldValue, formValues } = this.props

    return (
      <div className='form__item'>
        <Field
          name='paymentStatus'
          component={Tabs}
          config={[
            {label: t('tabs', 'paymentStatus', 'notPaid').s, action: changeFieldValue.bind(this, 'paymentStatus', PAYMENT_STATUS_NOT_PAID), active: formValues.paymentStatus === PAYMENT_STATUS_NOT_PAID},
            {label: t('tabs', 'paymentStatus', 'paid').s, action: changeFieldValue.bind(this, 'paymentStatus', PAYMENT_STATUS_PAID), active: formValues.paymentStatus === PAYMENT_STATUS_PAID}
          ]} />
      </div>
    )
  }

  _renderPaymentRow (fieldName, fieldsProps, index, removeFunction) {
    const { paymentMethods, t, addPaymentToDelete } = this.props
    const paymentId = fieldsProps.id || null
    const onRemoveClick = () => {
      if (paymentId) {
        addPaymentToDelete({ id: paymentId })
      }
      removeFunction(index)
    }

    return (
      <div className='form-rows__item payment-status-field' key={index}>
        <div className='form-rows__item__content payment-status-field__content'>
          <Field
            name={`${fieldName}[${index}].methodId`}
            component={DropDown}
            big
            options={paymentMethods}
            hint={t('props', 'paymentMethod').s}
            emptyOption={t('props', 'paymentMethod').s}
            error={!fieldsProps.methodId}
            touched
          />
          <div className='payment-status-field__two-inputs'>
            <Field name={`${fieldName}[${index}].date`} component={Input} type='date' error={!fieldsProps.date} placeholder={t('props', 'date').s} />
            <Field name={`${fieldName}[${index}].amount`} component={Input} type='float' normalize={normalizeNegativeValue} placeholder={t('props', 'amount').s} />
          </div>
          <div className='form-rows__item__right-button'>
            <PlusButton type='minus' onClick={onRemoveClick} />
          </div>
        </div>
      </div>
    )
  }

  get savedPaymentsList () {
    const { formValues: { paymentsToEdit }, removePaymentToEdit } = this.props

    return (
      paymentsToEdit.map((payment, index) => {
        const fieldName = `paymentsToEdit`
        return this._renderPaymentRow(fieldName, payment, index, removePaymentToEdit)
      })
    )
  }

  get newPaymentsList () {
    const { formValues: { paymentsToAdd }, removeNewPayment } = this.props

    return (
      paymentsToAdd.map((payment, index) => {
        const fieldName = `paymentsToAdd`
        return this._renderPaymentRow(fieldName, payment, index, removeNewPayment)
      })
    )
  }

  get addNewPaymentDetailButton () {
    const { t, eventAmount, addNewPayment } = this.props

    let onClick
    if (this.paymentsSum < eventAmount) {
      onClick = () => addNewPayment({ amount: eventAmount - this.paymentsSum, date: moment().format('YYYY-MM-DD') })
    } else {
      onClick = () => addNewPayment({ date: moment().format('YYYY-MM-DD') })
    }

    return (
      <div className='form__text-plus payment-status__add-button' onClick={onClick}>
        <h3>{t('buttons', 'addPaymentDetails').s}</h3>
        <PlusButton type='plus' />
      </div>
    )
  }

  get amountToPay () {
    const { eventAmount, t } = this.props

    const paymentsSum = this.paymentsSum
    if (paymentsSum < Number(eventAmount)) {
      return (
        <div className='payment-status__amount-to-pay'>
          {t('tabs', 'paymentStatus', 'remainingAmount').s}: <FormatNumber value={(Number(eventAmount) - paymentsSum)} />
        </div>
      )
    } else {
      return null
    }
  }

  get paymentWithDetailsSection () {
    return (
      <div className='form-rows form-rows--without-padding'>
        <div>
          { this.savedPaymentsList }
          { this.newPaymentsList }
        </div>
        { this.amountToPay }
        { this.addNewPaymentDetailButton }
      </div>
    )
  }

  get section () {
    const { formValues: { paymentStatus } } = this.props

    if (paymentStatus === PAYMENT_STATUS_PAID) {
      return this.paymentWithDetailsSection
    } else {
      return null
    }
  }

  _isValid () {
    const { formValues: { paymentStatus }, markFieldAsTouched } = this.props

    if (paymentStatus === PAYMENT_STATUS_PAID) {
      const { formValues: { paymentsToEdit, paymentsToAdd } } = this.props
      let result = true
      paymentsToEdit.forEach((payment, index) => {
        if (!payment.methodId) {
          markFieldAsTouched(`paymentsToEdit[${index}].methodId`)
          result = false
        }
        if (!payment.date) {
          markFieldAsTouched(`paymentsToEdit[${index}].date`)
          result = false
        }
      })

      paymentsToAdd.forEach((payment, index) => {
        if (!payment.methodId) {
          markFieldAsTouched(`paymentsToAdd[${index}].methodId`)
          result = false
        }
        if (!payment.date) {
          markFieldAsTouched(`paymentsToAdd[${index}].date`)
          result = false
        }
      })

      return result
    } else {
      return true
    }
  }

  componentDidMount () {
    this.props.provideController({
      isValid: () => this._isValid()
    })
  }

  componentDidUpdate (prevProps) {
    if (prevProps.formValues.paymentStatus === PAYMENT_STATUS_NOT_PAID &&
      this.props.formValues.paymentStatus === PAYMENT_STATUS_PAID &&
      this.props.formValues.paymentsToAdd[0] && !this.props.formValues.paymentsToAdd[0].amount &&
        this.props.eventAmount
    ) {
      this.props.changeFieldValue('paymentsToAdd[0].amount', this.props.eventAmount)
    }

    if (this.props.payments && this.props.payments !== prevProps.payments) {
      // Without it form doesn't reinitalize properly when there are payments.
      this.props.initialize(this.props.initialValues)
    }
  }

  render () {
    if (this.props.withoutTabs) {
      return (
        <div className='form__item payment-status-field__container'>
          { this.paymentWithDetailsSection }
        </div>
      )
    }
    return (
      <div className='form__item payment-status-field__container'>
        { this.paymentStatusTabs }
        { this.section }
      </div>
    )
  }
}

const formName = 'paymentStatus'
const selector = getFormValues(formName)
function mapStateToProps (state, ownProps) {
  return {
    initialValues: {
      paymentsToEdit: ownProps.payments || [],
      paymentsToAdd: ownProps.payments && ownProps.payments.length ? [] : [{ date: moment().format('YYYY-MM-DD') }],
      paymentStatus: ownProps.withoutTabs ? PAYMENT_STATUS_PAID : (ownProps.paymentStatus || PAYMENT_STATUS_NOT_PAID),
      paymentsToDelete: []
    },
    paymentMethods: state.userSettings.expensePaymentMethods,
    t: state.i18n.get('app', 'views', 'ExpenseView'),
    formValues: selector(state) || {}
  }
}

const mapDispatchToProps = (dispatch) => ({
  changeFieldValue: (fieldName, value) => dispatch(change(formName, fieldName, value)),
  addPaymentToEdit: (value) => dispatch(arrayPush(formName, 'paymentsToEdit', value)),
  addNewPayment: (value) => dispatch(arrayPush(formName, 'paymentsToAdd', value)),
  addPaymentToDelete: (value) => dispatch(arrayPush(formName, 'paymentsToDelete', value)),
  removePaymentToEdit: (index) => dispatch(arrayRemove(formName, 'paymentsToEdit', index)),
  removeNewPayment: (index) => dispatch(arrayRemove(formName, 'paymentsToAdd', index)),
  markFieldAsTouched: (fieldName) => dispatch(touch(formName, fieldName))
})

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: formName,
  enableReinitialize: true,
  destroyOnUnmount: false,
  keepDirtyOnReinitialize: true
})(PaymentStatusField))
