export const INVOICES_LIST_UPDATE = 'INVOICES_LIST_UPDATE'
export const INVOICES_LIST_CLEAN = 'INVOICES_LIST_CLEAN'
export const INVOICE_UPDATE_PROPS = 'INVOICE_UPDATE_PROPS'
export const INVOICE_CLEAR_PROPS = 'INVOICE_CLEAR_PROPS'

export const INVOICE_ROW_UPDATE = 'INVOICE_ROW_UPDATE'
export const INVOICE_ROW_ADD = 'INVOICE_ROW_ADD'
export const INVOICE_ROW_REMOVE = 'INVOICE_ROW_REMOVE'

export const INVOICE_SHOW_TOTALS_SPINNER = 'INVOICE_SHOW_TOTALS_SPINNER'
export const INVOICE_HIDE_TOTALS_SPINNER = 'INVOICE_HIDE_TOTALS_SPINNER'

export const INVOICE_DIRECT_PAYMENT_UPDATE = 'INVOICE_DIRECT_PAYMENT_UPDATE'
export const INVOICE_DIRECT_PAYMENT_CLEAN = 'INVOICE_DIRECT_PAYMENT_CLEAN'
