import styles from '../TaxPaymentReportsView.scss'

import PropTypes from 'prop-types'

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getFormValues } from 'redux-form'
import { isEqual } from 'lodash'

// Components
import Navigation from 'Navigation/Navigation'
import ListView from 'shared/components/ListView/ListView'
import Icon from 'shared/components/Icon'
import FormatDate from 'shared/components/FormatDate/FormatDate'
import FormatNumber from 'shared/components/FormatNumber/FormatNumber'
import Filters, { initialFilterValues } from '../components/Filters'
import { UniversalLink } from 'shared/components/LinkReplace'

// Actions
import { loadTaxPaymentReportsListAction, cleanReportsListAction } from 'Reports/actions/reports'

// Helpers
import getEmptyListOptions from 'Reports/helpers/emptyListOptions'

export function TaxPaymentReportsViewListItem (props) {
  const {date, amount, title, approved, path} = props.data
  return (
    <UniversalLink to={path} className={`${styles.listItem}`}>
      {
        approved
          ? <Icon className={styles.statusIcon} type='invoice-paid' />
          : <Icon className={styles.statusIcon} type='taxreport-notpaid' />
      }
      <h3 className={styles.title}>
        { title }
      </h3>
      <div className={styles.rightSide}>
        <div className={styles.rightSideInfo}>
          <h3 id='taxReportAmount'><FormatNumber value={amount} /></h3>
          <h5 className={styles.date}><FormatDate value={date} /></h5>
        </div>
        <span className='icon-arrow-right' />
      </div>
    </UniversalLink>
  )
}

TaxPaymentReportsViewListItem.propTypes = {
  data: PropTypes.object.isRequired
}

class TaxPaymentReportsView extends Component {
  static propTypes = {
    loadList: PropTypes.func,
    cleanList: PropTypes.func,
    reportsList: PropTypes.arrayOf(PropTypes.object),
    backLink: PropTypes.string,
    t: PropTypes.func,
    emptyListOptions: PropTypes.object,
    hasNextPage: PropTypes.bool,
    lastPageLoaded: PropTypes.number,
    filters: PropTypes.object
  };

  constructor (props) {
    super(props)
    this.extendList = this.extendList.bind(this)
  }

  componentDidMount () {
    this.props.loadList([], 1)
  }

  extendList () {
    const {hasNextPage, lastPageLoaded, loadList, reportsList} = this.props
    if (hasNextPage) {
      loadList(reportsList, lastPageLoaded + 1)
    }
  }

  componentWillUnmount () {
    this.props.cleanList()
  }

  componentDidUpdate (prevProps) {
    const { filters } = this.props
    if (prevProps.filters && !isEqual(prevProps.filters, filters)) {
      this.props.loadList([], 1)
    }
  }

  render () {
    const { backLink, reportsList, t, emptyListOptions, filters } = this.props

    return (
      <Navigation filterBlock backLink={backLink || '/overview'} title={t('navbar', 'title').s} className='tax-payment-reports-view' infinityScrollHandler={this.extendList}>
        <Filters canBeHidden={!reportsList.length} />
        <ListView
          timeRange={filters.orderBy === 'created_at'}
          data={reportsList}
          template={TaxPaymentReportsViewListItem}
          emptyListOptions={emptyListOptions}
        />
      </Navigation>
    )
  }
}

function mapStateToProps (state) {
  const emptyListOptions = getEmptyListOptions(
    state.i18n.get('app', 'views', 'Reports', 'shared'),
    state.userSettings.accountant
  )

  return {
    filters: getFormValues('taxReportsFilters')(state) || initialFilterValues,
    reportsList: state.reports.listData,
    hasNextPage: state.reports.hasNextPage,
    lastPageLoaded: state.reports.lastPageLoaded,
    t: state.i18n.get('app', 'views', 'Reports', 'TaxPayment', 'List'),
    emptyListOptions
  }
}

function mapDispatchToProps (dispatch) {
  return {
    loadList: (currentList, pageToLoad) => { dispatch(loadTaxPaymentReportsListAction(currentList, pageToLoad)) },
    cleanList: () => { dispatch(cleanReportsListAction()) }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TaxPaymentReportsView)
