import { connect } from 'react-redux'

// Views
import CustomersView from 'Counterparties/Customers/views/SelectableCustomersView/SelectableCustomersView'

// Actions
import { loadCustomersListAction, cleanCustomersListAction } from 'Counterparties/Customers/actions/customers'
import { change, getFormValues } from 'redux-form'
import { routerActions } from 'react-router-redux'

// Constants
import { defaultFilterValues } from 'Counterparties/Customers/components/Filters/Filters'

const projectSelector = getFormValues('project')
const customersListFiltersSelector = getFormValues('customersListFilters')

function mapStateToProps (state) {
  const project = projectSelector(state)
  return {
    cameFrom: 'project',
    customerId: project && project.customerId,
    customers: state.customers.listData,
    lastPageLoaded: state.customers.lastPageLoaded,
    hasNextPage: state.customers.hasNextPage,
    filters: customersListFiltersSelector(state) || defaultFilterValues,
    t: state.i18n.get('app', 'views', 'Customers', 'CustomersView')
  }
}

function mapDispatchToProps (dispatch) {
  return {
    loadList: (customers, filters, pageToLoad) => { dispatch(loadCustomersListAction(customers, filters, pageToLoad)) },
    cleanList: () => { dispatch(cleanCustomersListAction()) },
    updateCustomer: (field, customer) => {
      dispatch([
        change('project', 'customerId', customer.id),
        change('project', 'customerName', customer.name),
        routerActions.goBack()
      ])
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomersView)
