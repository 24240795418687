exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._3CsA_5yzxuVOHRonRHXbQ8 {\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  background: #fff;\n  font-size: 4rem;\n  text-align: center;\n  justify-content: center;\n  padding: 8rem;\n}\n\n._1mkf3OpJsNFYKrntO8Bn6E {\n  margin-top: auto;\n}\n\n._27UScdwouZHdD1_9K8kBDP {\n  margin-top: auto;\n}\n\n.IqXIUzeCT6KdOC32zy1mr {\n  text-decoration: underline;\n}\n\n", "", {"version":3,"sources":["/home/circleci/mobile_ui/src/User/shared/views/BankIDWaitingView/styles.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,cAAc;EACd,uBAAuB;EACvB,iBAAiB;EACjB,gBAAgB;EAChB,mBAAmB;EACnB,wBAAwB;EACxB,cAAc;CACf;;AAED;EACE,iBAAiB;CAClB;;AAED;EACE,iBAAiB;CAClB;;AAED;EACE,2BAA2B;CAC5B","file":"styles.scss","sourcesContent":[".container {\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  background: #fff;\n  font-size: 4rem;\n  text-align: center;\n  justify-content: center;\n  padding: 8rem;\n}\n\n.text {\n  margin-top: auto;\n}\n\n.button {\n  margin-top: auto;\n}\n\n.link {\n  text-decoration: underline;\n}\n\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"container": "_3CsA_5yzxuVOHRonRHXbQ8",
	"text": "_1mkf3OpJsNFYKrntO8Bn6E",
	"button": "_27UScdwouZHdD1_9K8kBDP",
	"link": "IqXIUzeCT6KdOC32zy1mr"
};