import './styles/index.scss?global'

import PropTypes from 'prop-types'

import React, { Component } from 'react'
import { isBoolean, indexOf, omit } from 'lodash'
// import FloatInput from './FloatInput'
import MaskedInput from 'react-input-mask'

import DateInput from './tablets/DateInput'
import Checkbox from 'shared/components/Checkbox/Checkbox'
import {isDesktop} from 'shared/helpers/utils'
import cs from 'classnames'

export default class Input extends Component {
  static propTypes = {
    placeholder: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
    type: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    view: PropTypes.string,
    notLabel: PropTypes.bool,
    readOnly: PropTypes.bool,
    onClick: PropTypes.func,
    error: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.string
    ]),
    maxValue: PropTypes.number,
    touched: PropTypes.bool,
    mask: PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.instanceOf(RegExp),
        PropTypes.string
      ])
    ),
    disabled: PropTypes.bool,
    focus: PropTypes.bool,
    onBlur: PropTypes.func,
    onKeyDown: PropTypes.func,
    onChange: PropTypes.func
  }

  constructor (props) {
    super(props)
    this._resetCursorPosition = this._resetCursorPosition.bind(this)
    this._onFloatInputBlur = this._onFloatInputBlur.bind(this)
  }

  componentDidUpdate () {
    if (this.props.focus) {
      this.refs.input.focus()
    }

    if (!isDesktop() && this.props.type === 'date') {
      // fix for iOS native datepicker (clear button) https://github.com/facebook/react/issues/8938
      this.refs.input.defaultValue = ''
    }
  }

  get shouldShowHint () {
    const { value, notLabel, view, type } = this.props
    if (view === 'vat-number') {
      return true
    }

    if (notLabel) {
      return false
    }

    if (type === 'float' || type === 'checkbox') {
      return true
    }

    if ((value || value === 0)) {
      return true
    }

    return false
  }

  shouldRenderCorners () {
    return this.props.type !== 'checkbox' && this.props.view !== 'filter'
  }

  isDateType (type) {
    const specialInputTypes = ['date', 'datetime-local']
    return specialInputTypes.indexOf(type) !== -1
  }

  isCheckboxType (type) {
    return type === 'checkbox'
  }

  setDatePlaceholder (type, value) {
    if (this.isDateType(type) && !value) {
      return 'input--date'
    } else {
      return ''
    }
  }

  isError () {
    const {error, touched} = this.props
    if ((isBoolean(touched) && touched && error) || (!isBoolean(touched) && error)) {
      return true
    }
  }

  _resetCursorPosition (e) {
    const len = e.target.value.length
    e.target.setSelectionRange(len, len)
  }

  _onFloatInputBlur (e) {
    const preformattedValue = e.target.value.replace(',', '.')
    const formattedValue = preformattedValue ? Number(preformattedValue).toFixed(2) : ''
    this.props.onBlur && this.props.onBlur(formattedValue)
  }

  _onFloatInputKeyPress (e) {
    const allowedSymbols = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', ',', '-']
    if (indexOf(allowedSymbols, e.key) === -1) {
      e.preventDefault()
    }
  }

  onNumberInputChange = (e) => {
    const {onChange = () => {}, type, maxValue} = this.props
    if (type === 'number' && maxValue) {
      let val = e.target.value
      if (Number(val) > Number(maxValue)) {
        val = maxValue
      }
      onChange(val)
    } else {
      onChange(e)
    }
  }

  omitWrongProps (props) {
    return omit(props, [
      'maxDate', 'error', 'initialValue', 'autofill', 'onUpdate', 'valid', 'notLabel',
      'invalid', 'dirty', 'pristine', 'active', 'touched', 'visited', 'autofilled', 'view',
      'asyncValidating', 'submitFailed', 'dispatch', 'submitting'
    ])
  }

  get input () {
    const { type, readOnly, mask, view, ...props } = this.props
    if (type === 'float') {
      return (
        <input
          autoCorrect='off'
          type='number'
          ref='input'
          disabled={readOnly}
          {...this.omitWrongProps(props)}
          onBlur={this._onFloatInputBlur}
          onKeyPress={this._onFloatInputKeyPress}
          placeholder='0.00'
        />
      )
    } else if (this.isCheckboxType(type)) {
      return <Checkbox {...props} />
    } else if (mask) {
      return (
        <MaskedInput
          {...this.omitWrongProps(props)}
          guide={false}
          mask={mask}
          maskPlaceholder={''}
          onChange={(e) => {
            if (props.normalize) e.target.value = props.normalize(e.target.value)
            props.onChange(e)
            return e
          }}
          onKeyUp={this._resetCursorPosition}
          onKeyDown={this._resetCursorPosition}
          onFocus={this._resetCursorPosition}
          onMouseUp={this._resetCursorPosition}
        />
      )
    } else if (isDesktop() && this.isDateType(type)) {
      return (
        <DateInput {...props} type={type} view={view} />
      )
    } else {
      return (
        <input
          placeholder={props.view === 'vat-number' ? '' : props.placeholder}
          autoCorrect='off'
          pattern={type === 'number' ? '\\d*' : null}
          type={type || 'text'}
          value={props.value || Number(props.value) === 0 ? props.value : ''}
          ref='input'
          disabled={readOnly}
          {...this.omitWrongProps(props)}
          onChange={this.onNumberInputChange}
        />
      )
    }
  }

  render () {
    const {type, label, view, placeholder, value, readOnly, onClick, disabled, error} = this.props
    const classNames = cs('input', this.setDatePlaceholder(type, value), {
      [`input--${view}`]: view,
      'input--readonly': readOnly,
      'input--error': this.isError(),
      'input--checkbox': this.isCheckboxType(type),
      'disable-feature': disabled
    })
    // FIXEM: span input__label - its placeholder, not label ?
    return (
      <div
        className={classNames}
        onClick={onClick}
        data-placeholder={placeholder}>

        {label && <div className='input__label-side'> {label} </div>}
        {this.input}

        <span className='input__label'>
          {this.shouldShowHint && placeholder}
          {this.isError() && <span className='input__error'> {error}</span>}
        </span>
        {this.shouldRenderCorners() && <div className='input__corners' />}
      </div>
    )
  }
}
