import PropTypes from 'prop-types'
import React, { Component } from 'react'

import Modal from 'shared/components/NewModal/NewModal'
import Button from 'shared/components/Button/Button'

export default class InvoiceChangeTypeModal extends Component {
  static propTypes = {
    t: PropTypes.func,
    onConfirm: PropTypes.func,
    onDeny: PropTypes.func
  }

  open () {
    this.modal.open()
  }

  close () {
    this.modal.close()
  }

  render () {
    const {t, onConfirm, onDeny} = this.props
    return (
      <Modal ref={(c) => { this.modal = c }} hideCloseButton>
        <div className='modal-with-buttons'>
          <div className='texts'>
            <h1><b>{t('errors', 'negativeAmount', 'title').s}</b></h1>
            <h2 style={{ whiteSpace: 'pre-wrap' }}>{t('errors', 'negativeAmount', 'text').s}</h2>
          </div>
          <div className='buttons'>
            <Button view='transparent-white' onClick={onConfirm}>{t('errors', 'negativeAmount', 'confirm').s}</Button>
            <Button view='transparent-white' onClick={onDeny}>{t('errors', 'negativeAmount', 'deny').s}</Button>
          </div>
        </div>
      </Modal>
    )
  }
}
