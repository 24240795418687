import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { reduxForm, destroy, getFormValues, Field, change } from 'redux-form'
import { values as _values } from 'lodash'

import cs from 'classnames'

// Components
import Tabs from '../../../../shared/components/FormTabs'

import Checkbox from 'shared/components/FormCheckbox'

import { setRuleFiltersAction } from 'Invoices/SupplierInvoices/actions/rules'
import ApproveRules from '../ApproveRules'

// Constants
import { ALL, NOT_PAID, PAID, APPROVED } from '../../../shared/constants/StatusTypes'

import styles from './styles.scss'

class SupplierInvoiceListFilters extends Component {
  static propTypes = {
    changeOrderType: PropTypes.func,
    changeStatus: PropTypes.func,
    filters: PropTypes.object,
    onCheckSelectAll: PropTypes.func,
    clearFilters: PropTypes.func,
    formFilled: PropTypes.bool,
    t: PropTypes.func
  };

  state = {
    filterState: 'normal'
  }

  constructor (props) {
    super(props)
    this.onTabChange = this.onTabChange.bind(this)
  }

  onTabChange (key) {
    this.props.changeStatus(key)
  }

  changeOrderType (type) {
    this.props.changeOrderType(type)
  }

  renderFilters () {
    const { filters: { status }, t, clearFilters, formFilled } = this.props

    if (status !== NOT_PAID) return null

    return <div>
      <div className='filter__main-part'>
        <ApproveRules />
      </div>

      <div className={cs('filter__bottom filter__bottom--without-right-padding', styles.filterBottom)}>
        <div className={styles.filterCheckbox}>
          <Checkbox white onChange={this.props.onCheckSelectAll} label={t('filters', 'selectAll').s} />
        </div>
        <div className={styles.filterArrow}>
          <div className='filter__open' onClick={() => this.setState({ filterState: 'open' })} >
            <span className='icon-arrow-pointer-down' />
          </div>
          <div className='filter__close-center' onClick={() => this.setState({ filterState: 'normal' })}>
            <span className='icon-arrow-pointer-down' />
          </div>
        </div>
        <div className={cs('filter__clear', styles.filterClear, {'filter__clear--hidden': !formFilled})} onClick={clearFilters}>
          <span className='icon-cross' />
          <span className='filter__clear__text'>{t('buttons', 'clearFilters').s}</span>
        </div>
      </div>
    </div>
  }

  render () {
    const { filters: { status }, t } = this.props
    const filterClassNames = cs([
      'filter', 'filter-wrap', 'filter--without-fixed-height',
      this.state.filterState === 'open' && 'filter--open',
      this.state.filterState === 'collapsed' && 'filter--collapsed'
    ])

    return (
      <div className={filterClassNames}>
        <div className='filter__top-part'>
          <Field name='status' component={Tabs} onChange={this.onTabChange} initValue={status} config={[
            {label: t('invoiceStatuses', ALL).s, value: ALL},
            {label: t('invoiceStatuses', NOT_PAID).s, value: NOT_PAID},
            {label: t('invoiceStatuses', APPROVED).s, value: APPROVED},
            {label: t('invoiceStatuses', PAID).s, value: PAID}
          ]} />
        </div>

        {this.renderFilters()}
      </div>
    )
  }
}

const formName = 'invoicesViewFilters'
function mapStateToProps (state) {
  const formValues = getFormValues('invoiceApproveRulesForm')(state) || {}
  return {
    t: state.i18n.get('app', 'views', 'Invoices', 'InvoicesView'),
    tabsCounters: state.invoices.tabsCounters,
    initialValues: {
      status: ALL,
      orderBy: 'document_date',
      orderType: 'desc',
      search: ''
    },
    formFilled: _values(formValues).some(x => x), // is some input filled,
    filters: getFormValues(formName)(state) || {}
  }
}

function mapDispatchToProps (dispatch) {
  return {
    clearFilters: () => dispatch([destroy('invoiceApproveRulesForm'), setRuleFiltersAction({})]),
    changeStatus: (value) => dispatch(change(formName, 'status', value)),
    changeOrderType: (value) => dispatch(change(formName, 'orderType', value))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: formName,
  destroyOnUnmount: false
})(SupplierInvoiceListFilters))
