import { omitBy, isNil } from 'lodash'
import { getFormValues } from 'redux-form'
import axios from 'api-client-connector'
import { getCurrentClientCompany } from 'api-client-connector/utils'

import { PAYMENT, PAYMENTS_CREATE } from 'Payments/constants/Api'
import { PAYMENT_STATUS_PAID, PAYMENT_STATUS_NOT_PAID, PAYMENT_OUT, PAYMENT_IN } from 'Payments/constants'

export function transformPaymentValuesForRequest (values, companyId, isExpense) {
  if (!values) {
    return {}
  }

  if (values.paymentStatus === PAYMENT_STATUS_PAID) {
    return {
      createPayments: values.paymentsToAdd && values.paymentsToAdd.map((payment) => transformPayment(payment, companyId, isExpense)),
      updatePayments: values.paymentsToEdit && values.paymentsToEdit.map((payment) => transformPayment(payment, companyId, isExpense)),
      deletePayments: values.paymentsToDelete && values.paymentsToDelete.map((v) => v.id)
    }
  } else if (values.paymentStatus === PAYMENT_STATUS_NOT_PAID && (values.paymentsToEdit.length || values.paymentsToDelete.length)) {
    return {
      deletePayments: [...values.paymentsToEdit.map(v => v.id), ...values.paymentsToDelete.map(v => v.id)]
    }
  } else {
    return {}
  }
}

export function transformPayment (payment, companyId, isExpense) {
  const mappedPayment = {
    payment_id: payment.id && Number(payment.id),
    amount: Number(payment.amount) || 0,
    date_of_payment: payment.date,
    payment_method_id: Number(payment.paymentMethodId),
    payment_type: isExpense ? PAYMENT_OUT : PAYMENT_IN,
    company_id: companyId
  }

  return omitBy(mappedPayment, isNil)
}

export function extractValuesFromState (state) {
  if (!state) {
    return {}
  }
  const values = getFormValues('paymentStatus')(state)
  if (!values) {
    return {}
  }
  return {
    paymentsToAdd: values.paymentsToAdd.map(mapArrayValue),
    paymentsToEdit: values.paymentsToEdit.map(mapArrayValue),
    paymentsToDelete: values.paymentsToDelete.map(mapArrayValue),
    paymentStatus: values.paymentStatus
  }
}

function mapArrayValue (obj) {
  const mappedValue = {
    ...obj,
    paymentMethodId: obj.methodId
  }

  return omitBy(mappedValue, isNil)
}

export function formatPaymentsFromResponse (event) {
  return event.payments.map((payment) => {
    return {
      id: payment.id,
      amount: payment.amount,
      date: payment.date_of_payment,
      methodId: payment.payment_method_id
    }
  })
}

export function getPaymentStatus (event) {
  if (event.payments.length) {
    return PAYMENT_STATUS_PAID
  } else {
    return PAYMENT_STATUS_NOT_PAID
  }
}

export async function pushPayments (payments, eventId, isExpense) {
  const currentClientCompany = Number(getCurrentClientCompany())
  const transformedPayments = transformPaymentValuesForRequest(payments, currentClientCompany, isExpense)
  let createRequests = []
  let updateRequests = []
  let deleteRequests = []

  if (transformedPayments.createPayments && transformedPayments.createPayments.length) {
    createRequests = transformedPayments.createPayments.map(payment => (
      axios.post(PAYMENTS_CREATE, { ...payment, source_document_id: eventId })
    ))
  }

  if (transformedPayments.updatePayments && transformedPayments.updatePayments.length) {
    updateRequests = transformedPayments.updatePayments.map(payment => (
      axios.patch(PAYMENT({ id: payment.payment_id }), payment)
    ))
  }

  if (transformedPayments.deletePayments && transformedPayments.deletePayments.length) {
    deleteRequests = transformedPayments.deletePayments.map(paymentId => (
      axios.delete(PAYMENT({ id: paymentId }))
    ))
  }

  await Promise.all([...createRequests, ...updateRequests, ...deleteRequests])
}
