import React from 'react'
import PropTypes from 'prop-types'
import { postMessageToAndroid, postMessageToIOS } from 'appBridge/bridge'
import { isIOS } from 'shared/helpers/utils'

const LinkReplaceOpenInFlutter = ({ ...props }) => {
  const message = `open_pdf_${props.to}`
  const onClick = () => isIOS() ? postMessageToIOS(message) : postMessageToAndroid(message)
  return (
    <div
      onClick={onClick}
      {...props}
    />
  )
}
LinkReplaceOpenInFlutter.propTypes = {
  to: PropTypes.string
}

export const UniversalLinkToFlutter = (props) => {
  return <LinkReplaceOpenInFlutter {...props} />
}

export default LinkReplaceOpenInFlutter
