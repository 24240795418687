import PropTypes from 'prop-types'

import React, { Component } from 'react'
import { isEqual } from 'lodash'

import { getTrimmedLink } from 'shared/helpers/linkUtils'

import Navigation from 'Navigation/Navigation'
import Filters from 'Counterparties/Suppliers/components/Filters/Filters'
import ListView from 'shared/components/ListView/ListView'
import SelectableCounterpartyItem from 'Counterparties/shared/components/SelectableCounterparty/SelectableCounterparty'

export class Suppliers extends Component {
  static propTypes = {
    loadList: PropTypes.func,
    cleanList: PropTypes.func,
    hasNextPage: PropTypes.bool,
    lastOffset: PropTypes.number,
    suppliers: PropTypes.arrayOf(PropTypes.object),
    filters: PropTypes.object,
    updateSupplier: PropTypes.func,
    supplierId: PropTypes.number
  }

  constructor (props) {
    super(props)
    this.selectSupplier = this.selectSupplier.bind(this)
    this.extendList = this.extendList.bind(this)
  }

  extendList () {
    const {hasNextPage, lastOffset, filters} = this.props
    if (hasNextPage) {
      this.props.loadList(filters, lastOffset + 20)
    }
  }

  componentDidMount () {
    const { filters } = this.props
    this.props.loadList(filters, 0)
  }

  componentDidUpdate (prevProps) {
    const { filters } = this.props
    if (prevProps.filters && !isEqual(prevProps.filters, filters)) {
      this.props.loadList(filters, 0)
    }
  }

  componentWillUnmount () {
    this.props.cleanList()
  }

  selectSupplier (supplier) {
    const {updateSupplier} = this.props
    updateSupplier(supplier)
  }

  render () {
    const {suppliers, supplierId} = this.props

    return (
      <div className='selectable-suppliers-list'>
        <ListView data={suppliers} template={SelectableCounterpartyItem(supplierId, this.selectSupplier)} />
      </div>
    )
  }
}

export default class SuppliersWithNavigation extends Component {
  static propTypes = {
    location: PropTypes.object,
    t: PropTypes.func,
    cameFrom: PropTypes.string
  }
  constructor (props) {
    super(props)
    this.extendList = this.extendList.bind(this)
  }

  extendList () {
    this.refs.suppliers.extendList()
  }

  render () {
    const {t, location: {pathname}, cameFrom} = this.props
    const actions = [
      {icon: 'icon-add', path: `/suppliers/new`, state: cameFrom}
    ]
    return (
      <Navigation title={t('navbar', 'title').s} actions={cameFrom && actions} hideFooter backLink={getTrimmedLink(pathname, 1)} filterBlock infinityScrollHandler={this.extendList}>
        <Filters />
        <Suppliers {...this.props} ref='suppliers' />
      </Navigation>
    )
  }
}
