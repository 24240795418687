import supplierInvoices from 'Invoices/SupplierInvoices/reducers/supplierInvoicesReducer'
import customerInvoices from 'Invoices/CustomerInvoices/reducers/invoicesReducer'
import { combineReducers } from 'redux'

const invoicesReducer = combineReducers({
  customerInvoices,
  supplierInvoices
})

export default function (state, action) {
  return invoicesReducer(state, action)
}
