import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Link } from 'react-router'

import PlusButton from 'shared/components/PlusButton/PlusButton'
import Button from 'shared/components/Button/Button'

export default class InvoiceCustomer extends Component {
  static propTypes = {
    location: PropTypes.object,
    saveFormToInvoice: PropTypes.func,
    removeCustomer: PropTypes.func,
    t: PropTypes.func,
    invoice: PropTypes.object
  }

  render () {
    const {invoice: {customer}, location: {pathname}, saveFormToInvoice, removeCustomer, t} = this.props
    return (
      <div className={`create-invoice__select-customer ${customer.name ? 'create-invoice__select-customer--filled' : ''}`}>
        <div className='hr'><span>{t('sections', 'customer').s}</span></div>
        <div className='create-invoice__select-customer__content'>
          {customer.name ? (
            <div className='create-invoice__select-customer__info'>
              <Link to={`${pathname}/customers`} onClick={saveFormToInvoice} className='create-invoice__select-customer__info__left'>
                <div>
                  <div>{t('customer', 'name').s}:</div>
                  <div><h3 className='truncate'>{customer.name}</h3></div>
                </div>
                <div>
                  <div>{t('customer', 'email').s}:</div>
                  <div><h3 className='truncate'>{customer.email}</h3></div>
                </div>
              </Link>
              <div className='create-invoice__select-customer__info__right'>
                <PlusButton type='minus' onClick={removeCustomer} />
              </div>
            </div>
          ) : (
            <Button view='transparent-black' link={`${pathname}/customers`} onClick={saveFormToInvoice}>{t('sections', 'customer').s}</Button>
          )}
        </div>
      </div>
    )
  }
}
