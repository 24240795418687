import uuid from 'uuid'
import Promise from 'bluebird'
import * as Sentry from '@sentry/browser'

export function isBridgeAvailable () {
  return Boolean(window.webkit) || Boolean(window.shoeboxAndroid)
}

export function isIOSBridge () {
  // TODO false positive in mobile site?
  return Boolean(window.webkit)
}

export function isAndroidBridge () {
  return Boolean(window.shoeboxAndroid)
}

export function isFlutterApp () {
  return navigator.userAgent.toString().includes('ShoeboxMobileAppV3')
}

// calling handler in Flutter webview is different from calling from native ios app
export function postMessageToIOS (message) {
  try {
    if (isFlutterApp() && isIOSBridge) {
      window.flutter_inappwebview.callHandler('observer', message)
    } else {
      window.webkit &&
        window.webkit.messageHandlers &&
          window.webkit.messageHandlers.observer &&
            window.webkit.messageHandlers.observer.postMessage(message)
    }
  } catch (e) {
    Sentry.withScope(scope => {
      scope.setExtra('message', message)
      Sentry.captureException(e)
    })
  }
}

export function postMessageToAndroid (message) {
  try {
    window.shoeboxAndroid &&
      window.shoeboxAndroid.postMessage &&
        window.shoeboxAndroid.postMessage(message)
  } catch (e) {
    Sentry.withScope(scope => {
      scope.setExtra('message', message)
      Sentry.captureException(e)
    })
  }
}

export function postAsyncMessage (message) {
  const requestId = uuid.v4()
  postMessageToIOS(`${message}_${requestId}`)
  postMessageToAndroid(`${message}_${requestId}`)

  const pendingPromise = Promise.pending()

  window.addBridgeRequest(requestId, pendingPromise)

  return pendingPromise.promise
}
