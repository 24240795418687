import { connect } from 'react-redux'

import Smartphones from 'MarketPlace/Capcito/views/CapcitoFrameView/containers/Smartphones'
import Tablets from 'MarketPlace/Capcito/views/CapcitoFrameView/containers/Tablets'

import { getCurrentDeviceView } from 'shared/helpers/utils'

const CapcitoFrameView = getCurrentDeviceView(Smartphones, Tablets)

const mapStateToProps = (state) => ({
  t: state.i18n.get('app', 'views', 'MarketPlace'),
  selectedCategory: state.marketplace.index.category
})

export default connect(mapStateToProps)(CapcitoFrameView)
