import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { reduxForm, Field } from 'redux-form'
import cs from 'classnames'

import Button from 'shared/components/Button/Button'
import Input from 'shared/components/FormInput'
import BackLink from 'User/shared/components/BackLink/BackLink'
import FullscreenSpinner from 'User/shared/containers/FullscreenSpinner/FullscreenSpinner'

import { default as resetPasswordAction } from 'User/ResetPassword/actions/forgotPassword'

import getGreyLabelOptions from 'GreyLabel/helpers/greyLabel'

import './index.scss?global'

// Request form
export class ForgotPasswordView extends React.Component {
  static propTypes = {
    t: PropTypes.func,
    resetPassword: PropTypes.func,
    handleSubmit: PropTypes.func.isRequired
  };

  constructor (props) {
    super(props)
    this.onSubmit = this.onSubmit.bind(this)
  }

  onSubmit (values) {
    const { resetPassword } = this.props
    resetPassword(values.email)
  }

  render () {
    const { t, handleSubmit } = this.props
    const { whiteLabel } = getGreyLabelOptions()
    return (
      <div className={cs('forgot-password', whiteLabel)}>
        <div className='forgot-password__title'>{t('title').s}</div>
        <form onSubmit={handleSubmit(this.onSubmit)} className='forgot-password__content'>
          <div className='forgot-password__content__text'>{t('text').s}</div>
          <div className='forgot-password__content__field'>
            <Field name='email' component={Input} view='white' placeholder={t('fields', 'email').s} notLabel />
          </div>
          <Button view='transparent-white' type='submit'>{t('buttons', 'reset').s}</Button>
        </form>
        <FullscreenSpinner />
        <BackLink link='/entry' text={t('back').s} />
      </div>
    )
  }
}

function mapStateToProps (state, ownProps) {
  return {
    initialValues: {
      email: ownProps.location.query.email || ''
    },
    t: state.i18n.get('app', 'views', 'ForgotPasswordView')
  }
}

function mapDispatchToProps (dispatch) {
  return {
    resetPassword: (username) => { dispatch(resetPasswordAction(username)) }
  }
}

function mergeProps (stateProps, dispatchProps, ownProps) {
  // For tests to override resetPassword with mocked function passed to ownProps.
  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps
  }
}

function validate (values) {
  let errors = {}
  if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = true
  }
  return errors
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(reduxForm({
  form: 'reset-password',
  validate
})(ForgotPasswordView))
