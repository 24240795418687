import { createReducer } from 'redux-act'

import { openPaymentsValidatedFieldsToStore } from 'Integrations/OpenPayments/actions'

const initialState = {
  validatedFields: { initialValues: {}, currentErrors: {} },
  banks: []
}

export default createReducer({
  [openPaymentsValidatedFieldsToStore]: (state, { banks, ...validatedFields }) => ({
    ...state,
    validatedFields,
    banks
  })
}, initialState)
