import { routerActions } from 'react-router-redux'
import { destroy } from 'redux-form'

// Actions
import { clearNewEventAction } from 'Events/shared/actions/event'
import { clearFilesAction } from 'FileUpload/actions/filesToUpload'
import { closeMessageBoxAction } from 'MessageBox/actions/messageBox'

const commonActionsForRedirect = [
  closeMessageBoxAction(),
  destroy('receipt-form'),
  destroy('paymentStatus'),
  clearNewEventAction(),
  clearFilesAction('')
]

export function redirectToNewRevenueViewAction () {
  return [...commonActionsForRedirect, routerActions.replace('/events/new/revenue?type=receipt')]
}

export function redirectToNewExpenseViewAction () {
  return [...commonActionsForRedirect, routerActions.replace('/events/new/expense?type=receipt')]
}
