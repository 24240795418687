import React from 'react'
import PropTypes from 'prop-types'

import Textarea from 'shared/components/Textarea/Textarea'

// Component injects props from redux-form Field component.
// TODO: when migration from redux-form-5 is finished, refactor Textarea component and remove this one.
const FormTextarea = ({input, meta, ...props}) =>
  <Textarea {...input} {...meta} {...props} />

FormTextarea.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object
}

export default FormTextarea
