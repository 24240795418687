export default function getMultipleActions (props, deleteAction) {
  const { counterparty, markAsActive, markAsInactive, t } = props

  let buttons = [
    { title: t('buttons', 'delete').s, onClick: deleteAction }
  ]

  if (counterparty.state === 'active') {
    buttons.push(
      { title: t('buttons', 'deactivate').s, onClick: () => { markAsInactive(counterparty) } }
    )
  } else {
    buttons.push(
      { title: t('buttons', 'activate').s, onClick: () => { markAsActive(counterparty) } }
    )
  }

  return buttons
}
