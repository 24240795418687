import '../styles/index.scss?global'

import Smartphones from 'More/views/MoreSupplierInvoicesView/containers/Smartphones'
import Tablets from 'More/views/MoreSupplierInvoicesView/containers/Tablets'

import {getCurrentDeviceView} from 'shared/helpers/utils'

const MoreSupplierInvoicesView = getCurrentDeviceView(Smartphones, Tablets)

export default MoreSupplierInvoicesView
