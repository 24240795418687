import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Field } from 'redux-form'
import { isBridgeAvailable } from 'appBridge/bridge'

// Components
import Button from 'shared/components/Button/Button'
import Textarea from 'shared/components/FormTextarea'
import PlusButton from 'shared/components/PlusButton/PlusButton'
import Input from 'shared/components/FormInput'
import AddContactModal from 'Events/Expense/components/AddContactModal/AddContactModal'
import Modal from 'shared/components/NewModal/NewModal'
import Switcher from 'shared/components/FormSwitcher'

export default class Representation extends Component {
  static propTypes = {
    expense: PropTypes.object,
    addContact: PropTypes.func,
    deleteContact: PropTypes.func,
    save: PropTypes.func,
    t: PropTypes.func,
    t_button: PropTypes.func,
    showMessageBox: PropTypes.func,
    openPhonebook: PropTypes.func,
    meta: PropTypes.object,
    handleSubmit: PropTypes.func
  }

  constructor (props) {
    super(props)
    this.state = {
      errors: {}
    }

    this.importMethodModal = {}
    this.contactModal = {}

    this.openContactModal = this.openContactModal.bind(this)
    this.saveRepresentation = this.saveRepresentation.bind(this)
    this.addContact = this.addContact.bind(this)
    this.openContactModalInWrapperMode = this.openContactModalInWrapperMode.bind(this)
  }

  openContactModal () {
    if (isBridgeAvailable()) {
      this.importMethodModal.open()
    } else {
      this.contactModal.open()
    }
  }

  closeContactModal () {
    this.contactModal.close()
  }

  editContact (index) {
    this.contactModal.edit(index)
  }

  deleteContact (index) {
    this.props.deleteContact(index)
  }

  addContact (contactInfo, index) {
    this.props.addContact(contactInfo, index)
    this.closeContactModal()
  }

  saveRepresentation (values) {
    const {expense, showMessageBox, t} = this.props
    if (expense.representationGuests.length < 2) {
      return showMessageBox('', t('errorText').s)
    }
    if (expense.representationGuests.some(g => !g.company)) {
      return
    }

    this.props.save({...values, representationGuests: expense.representationGuests})
  }

  openContactModalInWrapperMode () {
    // webkit behaves very strange if there're 2 modals or 1 modal is closing and 1 modal is opening
    // here's workaround
    this.importMethodModal.close()
    setTimeout(() => { this.contactModal.open() }, 400)
  }

  render () {
    const { expense, t, t_button, meta: { representationCause = {} }, handleSubmit } = this.props
    return (
      <div className='expense-representation f-column'>
        <div className='form f-column-top'>
          <div className='form__item'>
            <Field name='representationCause' component={Textarea} error={representationCause.touched && !representationCause.valid} placeholder={t('purposeHint').s} label={t('purpose').s} />
          </div>

          <div className='form__item form__switcher'>
            <div><h3>{t('withAlcohol').s}</h3></div>
            <div><Field name='withAlcohol' component={Switcher} dark /></div>
          </div>
          <div className='form__item form__switcher'>
            <div><h3>{t('internalRepresentation').s}</h3></div>
            <div><Field name='internalRepresentation' component={Switcher} dark /></div>
          </div>

          {expense.representationGuests.map((guest, index) => {
            return (
              <div className='form__item  form__input-plus' key={index}>
                <Input error={!guest.company && t('errors', 'shouldHaveCompany').s} onClick={() => { this.editContact(index) }} value={guest.name} readOnly />
                <PlusButton type='minus' onClick={() => { this.deleteContact(index) }} />
              </div>
            )
          })}

          <div className='form__text-plus'>
            <h3>{t('addGuest').s}</h3>
            <PlusButton type='plus' onClick={this.openContactModal} />
          </div>

        </div>
        <div className='f-column-bottom form-buttons'>
          <Button onClick={handleSubmit(this.saveRepresentation)} view='transparent-black'>{t_button('save').s}</Button>
          <Button view='transparent-red' goBackButton>{ t_button('cancel').s}</Button>
        </div>

        {/* this.closeModalBox is undefined? */}
        <Modal ref={(r) => { this.importMethodModal = r }} onClose={this.closeModalBox}>
          <div className='modal-with-buttons'>
            <div className='texts'>
              <h2>{t('importMethodModal', 'description').s}</h2>
            </div>
            <div className='buttons'>
              <Button view='transparent-white' onClick={this.openContactModalInWrapperMode}>{t('importMethodModal', 'addContact').s}</Button>
              <Button view='transparent-white' onClick={this.props.openPhonebook}>{t('importMethodModal', 'importFromPhonebook').s}</Button>
            </div>
          </div>
        </Modal>
        <AddContactModal ref={(r) => { this.contactModal = r }} addContact={this.addContact} t={t} />
      </div>
    )
  }
}
