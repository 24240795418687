import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getFormValues } from 'redux-form'
import { isEqual } from 'lodash'

import Navigation from 'Navigation/Navigation'
import ListView from 'shared/components/ListView/ListView'
import { VacanciesListItem } from 'Vacancies/components/ListItem'
import Filters from 'Vacancies/components/Filters'

import { loadVacancies, loadRoleAreas } from 'Vacancies/actions'

class VacanciesView extends Component {
  static propTypes = {
    filters: PropTypes.object,
    loadRoleAreas: PropTypes.func,
    loadVacancies: PropTypes.func,
    vacancies: PropTypes.array
  }

  componentDidMount () {
    this.props.loadVacancies()
    this.props.loadRoleAreas()
  }

  componentDidUpdate (prevProps) {
    const { filters, loadVacancies } = this.props
    if (!isEqual(prevProps.filters, filters)) {
      loadVacancies(filters)
    }
  }

  render () {
    const { vacancies } = this.props
    return (
      <div>
        <ListView data={vacancies} template={VacanciesListItem} emptyListOptions={{icon: 'icon-documents', showNotFound: !vacancies.length}} />
      </div>
    )
  }
}

function mapStateToProps (state) {
  return {
    t: state.i18n.get('app', 'views', 'VacanciesView'),
    vacancies: state.vacancies.list.data,
    filters: getFormValues('vacanciesListFilters')(state),
    offset: state.vacancies.list.offset,
    hasMoreVacancies: state.vacancies.list.hasMoreVacancies
  }
}

function mapDispatchToProps (dispatch) {
  return {
    loadVacancies: (filters, offset) => dispatch(loadVacancies(filters, offset)),
    loadRoleAreas: () => dispatch(loadRoleAreas())
  }
}

export class VacanciesViewWithNavigation extends Component {
  static propTypes = {
    filters: PropTypes.object,
    hasMoreVacancies: PropTypes.bool,
    loadVacancies: PropTypes.func,
    offset: PropTypes.number
  }

  extendList = () => {
    const { offset, filters, hasMoreVacancies, loadVacancies } = this.props
    if (hasMoreVacancies) {
      loadVacancies(filters, offset + 20)
    }
  }

  render () {
    return (
      <Navigation title={this.props.t('navbar', 'title').s} filterBlock infinityScrollHandler={this.extendList}>
        <Filters />
        <VacanciesView {...this.props} />
      </Navigation>
    )
  }
}

export const connectedVacanciesView = connect(mapStateToProps, mapDispatchToProps)(VacanciesView)
export default connect(mapStateToProps, mapDispatchToProps)(VacanciesViewWithNavigation)
