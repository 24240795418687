import { createAction } from 'redux-act'
import axios from 'api-client-connector'
import { camelizeKeys, decamelizeKeys } from 'humps'
import { getCurrentClientCompany } from 'api-client-connector/utils'
import { routerActions } from 'react-router-redux'
import { change } from 'redux-form'

import { hideNavbarSpinnerAction, showSlowNavbarSpinnerAction } from 'shared/actions/navbarSpinner'
import { showMessageBoxWithParamsAction } from 'MessageBox/actions/messageBox'
import { SALARIES_LIST, SALARY, SALARY_CALCULATOR } from 'Salaries/constants/api'

export const salariesToStore = createAction('Salaries list to store')
export const salaryToStore = createAction('Salary to store')
export const clearSalary = createAction('Clear salary state')
export const totalsToStore = createAction('Calculated totals to store')
export const calculate = createAction('Change calculate status')

export function getSalaries (filters = {}) {
  return async (dispatch, getState) => {
    dispatch(showSlowNavbarSpinnerAction())
    try {
      let response = await getSalariesRequest({
        company_id: Number(getCurrentClientCompany()),
        ...decamelizeKeys(filters)
      })
      dispatch(salariesToStore(camelizeKeys(response.data)))
    } catch (e) {
      processError(e, getState, dispatch)
    }
    dispatch(hideNavbarSpinnerAction())
  }
}

const getSalariesRequest = (params) => axios.get(SALARIES_LIST(params))

export function getSalary (id) {
  return async (dispatch, getState) => {
    dispatch(showSlowNavbarSpinnerAction())
    try {
      let response = await getSalaryRequest(id)
      dispatch(salaryToStore(camelizeKeys(response.data)))
    } catch (e) {
      processError(e, getState, dispatch)
    }
    dispatch(hideNavbarSpinnerAction())
  }
}

const getSalaryRequest = (id) => axios.get(SALARY(id))

export function saveSalary (salary, salaryId) {
  return async (dispatch, getState) => {
    dispatch(showSlowNavbarSpinnerAction())
    try {
      const paymentMethod = getState().userSettings.expensePaymentMethods.find(account => account.value === Number(salary.paymentAccountId))
      const body = {
        title: salary.title,
        projectId: salary.projectId ? Number(salary.projectId) : null,
        employeeId: Number(salary.employeeId),
        netSalary: Number(salary.netSalary),
        grossSalary: Number(salary.grossSalary),
        paymentAccount: { id: paymentMethod.value, accountNumber: paymentMethod.accountNumber.toString() },
        benefits: salary.benefits.map(benefit => ({ ...benefit, value: Number(benefit.value) }))
      }
      await saveSalaryRequest(decamelizeKeys(body), salaryId)
      dispatch(routerActions.push(`/salaries`))
    } catch (e) {
      processError(e, getState, dispatch)
    }
    dispatch(hideNavbarSpinnerAction())
  }
}

export function markAsPaid (salaryId) {
  return async (dispatch, getState) => {
    dispatch(showSlowNavbarSpinnerAction())
    try {
      await saveSalaryRequest(decamelizeKeys({ paid: true }), salaryId)
      dispatch(getSalary(salaryId))
    } catch (e) {
      processError(e, getState, dispatch)
    }
    dispatch(hideNavbarSpinnerAction())
  }
}

function saveSalaryRequest (body, id) {
  // on form page id is undefined for new salary, when coming back from adding benefit id eq 'new'
  return !id || id === 'new'
    ? axios.post(SALARIES_LIST({ company_id: Number(getCurrentClientCompany()) }).toString(), body)
    : axios.patch(SALARY(id).toString(), body)
}

export function calculateSalary (values) {
  return async (dispatch, getState) => {
    dispatch([
      showSlowNavbarSpinnerAction(),
      calculate(true)
    ])
    try {
      const body = {
        netSalary: Number(values.netSalary),
        grossSalary: Number(values.grossSalary),
        employeeId: Number(values.employeeId),
        benefits: values.benefits.map(benefit => ({...benefit, value: Number(benefit.value)}))
      }
      const { data: { net_salary, gross_salary, ...totals } } = await calculateRequest(decamelizeKeys(body))
      dispatch([
        totalsToStore(camelizeKeys(totals)),
        change('salary', 'netSalary', net_salary),
        change('salary', 'grossSalary', gross_salary)
      ])
    } catch (e) {
      processError(e, getState, dispatch)
    }
    dispatch([
      hideNavbarSpinnerAction(),
      calculate(false)
    ])
  }
}

const calculateRequest = (body) =>
  axios.put(SALARY_CALCULATOR({ company_id: Number(getCurrentClientCompany()) }).toString(), body)

function processError (error, getState, dispatch) {
  const t = getState().i18n.get('app', 'shared', 'messages')
  dispatch(showMessageBoxWithParamsAction(null, t('unexpectedError', 'description').s))
  console.log('error', error)
}
