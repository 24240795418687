import { get } from 'lodash'
import { setHeaders, selectClientCompany } from 'api-client-connector/utils'

export function hasLegacyAuthSession () {
  const state = JSON.parse(localStorage.getItem('redux'))
  const tokenPart = get(state, 'auth.headers.client')

  return Boolean(tokenPart)
}

export function transformLegacyAuthSessionIntoNew () {
  const state = JSON.parse(localStorage.getItem('redux'))
  const authHeaders = get(state, 'auth.headers')

  setHeaders(authHeaders)
  selectClientCompany(authHeaders['x-client-id'])
}
