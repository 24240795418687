import { connect } from 'react-redux'

import Smartphones from 'MarketPlace/Creditsafe/views/CreditsafeFrameView/containers/Smartphones'
import Tablets from 'MarketPlace/Creditsafe/views/CreditsafeFrameView/containers/Tablets'

import { getCurrentDeviceView } from 'shared/helpers/utils'

const CreditsafeFrameView = getCurrentDeviceView(Smartphones, Tablets)

const mapStateToProps = (state) => ({
  t: state.i18n.get('app', 'views', 'MarketPlace'),
  selectedCategory: state.marketplace.index.category
})

export default connect(mapStateToProps)(CreditsafeFrameView)
