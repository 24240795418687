import 'Navigation/styles/tablets.scss?global'

import PropTypes from 'prop-types'

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { routerActions } from 'react-router-redux'
import { Link } from 'react-router'
import cs from 'classnames'
import queryString from 'query-string'

import getGreyLabelOptions from 'GreyLabel/helpers/greyLabel'
import {setPreviousLinkAction, setActiveItemIdAction} from 'AppInfo/actions/appInfo'

import NavigationInfoBlock from 'Navigation/components/InfoBlock'
import MultipleActionsButton from 'Navigation/components/MultipleActionsButton/MultipleActionsButton'
import Loader from 'shared/components/Loader/Loader'
import Scrollbar from 'mrshoebox-ui-components/src/components/BaronScroll'

class IpadNavigation extends Component {
  constructor (props) {
    super(props)
    this.onBackClick = this.onBackClick.bind(this)
    this.scrollHandler = this.scrollHandler.bind(this)
  }

  static propTypes = {
    title: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
    historyGoBack: PropTypes.func,
    historyReplace: PropTypes.func,
    location: PropTypes.object,
    children: PropTypes.node,
    backLink: PropTypes.string,
    onlyLeftSide: PropTypes.bool,
    setPreviousLink: PropTypes.func,
    actions: PropTypes.arrayOf(PropTypes.object),
    setCurrentLink: PropTypes.bool,
    onClickBack: PropTypes.func,
    infinityScrollHandler: PropTypes.func,
    showSpinner: PropTypes.bool,
    filter: PropTypes.node,
    setActiveItemId: PropTypes.func,
    notBack: PropTypes.bool,
    className: PropTypes.string,
    multipleActions: PropTypes.arrayOf(PropTypes.object),
    withoutMaxWidth: PropTypes.bool,
    scrollHandler: PropTypes.func,
    onReachingTopHandler: PropTypes.func
  }

  componentWillMount () {
    const {onlyLeftSide} = this.props
    if (onlyLeftSide) {
      this.props.setActiveItemId('')
    }
  }

  componentWillUnmount () {
    const {setPreviousLink, location: {pathname, search, action}, setCurrentLink} = this.props
    if (setCurrentLink && (action === 'PUSH' || action === 'POP')) {
      setPreviousLink(pathname + search)
    }
  }

  onBackClick () {
    const { historyGoBack, historyReplace, onClickBack, backLink } = this.props
    if (onClickBack) onClickBack()
    if (backLink && typeof backLink === 'string') {
      historyReplace(backLink)
    } else if (window.previousUrl) {
      historyGoBack()
    } else {
      historyReplace('/')
    }
  }

  scrollHandler () {
    const {showSpinner, infinityScrollHandler, scrollHandler, onReachingTopHandler} = this.props
    if (scrollHandler) scrollHandler()
    const scrollableElement = document.querySelector('.scrollBlock__content--right')
    const spaceFromBottom = 50

    if (!showSpinner && (scrollableElement.offsetHeight + scrollableElement.scrollTop + spaceFromBottom) > scrollableElement.scrollHeight) {
      if (infinityScrollHandler) infinityScrollHandler()
    } else if (scrollableElement.scrollTop === 0) {
      onReachingTopHandler && onReachingTopHandler()
    }
  }

  render () {
    let leftSide
    let rightSide
    if (Array.isArray(this.props.children)) {
      [leftSide, ...rightSide] = this.props.children
    } else {
      leftSide = this.props.children
    }
    const {title, onlyLeftSide, actions, filter, notBack, className, location: {pathname}, multipleActions, withoutMaxWidth, showSpinner} = this.props

    const getAction = () => {
      if (multipleActions) {
        return (
          <MultipleActionsButton buttons={multipleActions} location={location} disabled={showSpinner} />
        )
      }

      let actionsLinks = []
      actions && actions.map((action, i) => {
        if (action.path) {
          const [pathname, query] = action.path.split('?')
          const parsedQuery = query ? queryString.parse(`?${query}`) : {}
          const actionLinkComponent = showSpinner
            ? <span className={`${action.icon} ipad-navigation-action-button-disabled`} />
            : <Link key={i} to={{pathname, query: parsedQuery, state: action.state}} onClick={action.onClick}>
              <span className={action.icon} onClick={action.callback} />
            </Link>
          actionsLinks.push(actionLinkComponent)
        } else if (action.nativeLink) {
          actionsLinks.push(
            <a key={i} href={action.nativeLink}>
              <span className={action.icon} onClick={action.callback} />
            </a>
          )
        } else {
          actionsLinks.push(
            <div key={i}>
              <span className={action.icon} onClick={action.callback}>{action.text}</span>
            </div>
          )
        }
      })

      if (!actions && onlyLeftSide && pathname !== '/settings') {
        let key = actionsLinks.length + 1
        actionsLinks.push(
          <Link key={key} to='/settings'>
            <span className='icon-gear' />
          </Link>
        )
      }

      return actionsLinks
    }

    const rightLogo = () => {
      if (getGreyLabelOptions().greyLabel) {
        return (
          <div className='ipad-navigation__right-logo-other'>
            <span className='icon-events' />
          </div>
        )
      } else {
        return (
          <div className='ipad-navigation__right-logo-base'>
            <span className='logo-mshoebox-gray' />
          </div>
        )
      }
    }

    const { whiteLabel } = getGreyLabelOptions()

    return (
      <div className={cs('ipad-navigation', whiteLabel && `ipad-navigation--${whiteLabel}`)}>
        <Loader />
        {onlyLeftSide ? this.props.children : leftSide}
        <div className={`ipad-navigation__content ${className || ''}`}>
          <div className='ipad-navigation__content__header'>
            <div className='ipad-navigation__content__header__left' onClick={this.onBackClick}>
              {!onlyLeftSide && !notBack ? <span className='icon-arrow-left' /> : null}
            </div>
            <div className='ipad-navigation__content__header__title'>
              <div className='truncate'>{title}</div>
            </div>
            <div className='ipad-navigation__content__header__right'>
              {getAction()}
            </div>
          </div>
          <NavigationInfoBlock />
          {filter && <div className='ipad-navigation__content__filter'>{filter}</div>}
          <Scrollbar rootSelector='desktop-scroll' contentClass='scrollBlock__content--right' onScroll={this.scrollHandler}>
            <div className={`ipad-navigation__content__middle ${!withoutMaxWidth ? 'ipad-navigation__content__middle--max-width' : ''}`}>{!onlyLeftSide ? rightSide : rightLogo()}</div>
          </Scrollbar>
        </div>
      </div>
    )
  }
}

function mapStateToProps (state) {
  return {
    showSpinner: state.navbarSpinner.value
  }
}

function mapDispatchToProps (dispatch) {
  return {
    setActiveItemId: (id) => { dispatch(setActiveItemIdAction(id)) },
    setPreviousLink: (link) => { dispatch(setPreviousLinkAction(link)) },
    historyGoBack: () => { dispatch(routerActions.go(-1)) },
    historyReplace: (path) => { dispatch(routerActions.replace(path)) }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(IpadNavigation)
