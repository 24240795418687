import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import IpadNavigation from 'Navigation/IpadNavigation'
import Navigation from 'Navigation/Navigation'
import Todo from 'Todo/views/TodoView/components/Todo'

export class TodoView extends Component {
  static propTypes = {
    t: PropTypes.func
  }

  render () {
    const {t} = this.props
    return (
      <IpadNavigation {...this.props} onlyLeftSide>
        <Navigation home title={t('title').s} backLink={'/'} >
          <Todo {...this.props} />
        </Navigation>
      </IpadNavigation>
    )
  }
}

function mapStateToProps (state) {
  return {
    t: state.i18n.get('app', 'views', 'Todo', 'TodoView')
  }
}

export default connect(mapStateToProps)(TodoView)
