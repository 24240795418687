export const outType = 10
export const moneyWithdrawal = 11
export const inType = 20
export const moneyDeposit = 21
export const cashRegisterReport = 22
export const invoice = 23
export const other = 30
export const transfer = 31
export const taxPayment = 32
export const mileageReport = 33
export const unknown = 99
export const isExpense = (type) => {
  return [outType, moneyWithdrawal].indexOf(Number(type)) !== -1
}
export const isRevenue = (type) => {
  return [inType, moneyDeposit, invoice].indexOf(Number(type)) !== -1
}
export const isOther = (type) => {
  return ([unknown, transfer, other, taxPayment, cashRegisterReport]).indexOf(Number(type)) !== -1
}

export const INVOICE_TYPE_INVOICE = '1'
export const INVOICE_TYPE_CREDIT_INVOICE = '2'
export const OTHER_PAPER_KIND = 0
export const INVOICE_PAPER_KIND = 1
export const RECEIPT_PAPER_KIND = 2
