import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import IpadNavigation from 'Navigation/IpadNavigation'
import {TimesheetView} from 'Timesheet/views/TimesheetView/containers/Smartphones'

import getLeftComponent from 'Navigation/helpers/linkAccordance'

import { cleanTimesheetAction, showDeleteTimesheetMessageBox } from 'Timesheet/actions/timesheetsList'

export class Timesheet extends Component {
  static propTypes = {
    params: PropTypes.object,
    t: PropTypes.func,
    previousLink: PropTypes.string,
    clearTimesheet: PropTypes.func,
    location: PropTypes.object,
    showDeleteTimesheetMessageBox: PropTypes.func
  }

  componentWillMount () {
    const {clearTimesheet, params: { timesheetId }} = this.props
    if (!timesheetId) {
      clearTimesheet()
    }
  }

  render () {
    const { t, params: { timesheetId }, previousLink, location: { query: { cameFrom } }, showDeleteTimesheetMessageBox } = this.props
    const RightSideList = getLeftComponent('timesheet', previousLink)
    const actions = timesheetId
      ? [{ icon: 'icon-trash', callback: showDeleteTimesheetMessageBox }]
      : null

    return (
      <IpadNavigation {...this.props} actions={actions} title={timesheetId ? t('new', 'editTitle').s : t('new', 'newTitle').s} notBack={!cameFrom}>
        <RightSideList {...this.props} />
        <TimesheetView {...this.props} />
      </IpadNavigation>
    )
  }
}

function mapStateToProps (state) {
  return {
    t: state.i18n.get('app', 'views', 'Timesheet'),
    previousLink: state.appInfo.previousLink
  }
}

function mapDispatchToProps (dispatch, ownProps) {
  const { params: { timesheetId } } = ownProps

  return {
    clearTimesheet: () => { dispatch(cleanTimesheetAction()) },
    showDeleteTimesheetMessageBox: () => { dispatch(showDeleteTimesheetMessageBox(timesheetId)) }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Timesheet)
