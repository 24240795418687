import { connect } from 'react-redux'
import { getFormValues } from 'redux-form'

import { loadCustomersListAction, cleanCustomersListAction } from 'Counterparties/Customers/actions/customers'
import { invoiceUpdatePropAction } from '../../actions/invoiceCreate'
import CustomersView from 'Counterparties/Customers/views/SelectableCustomersView/SelectableCustomersView'

// Constants
import { defaultFilterValues } from 'Counterparties/Customers/components/Filters/Filters'

function mapStateToProps (state) {
  return {
    cameFrom: 'invoice',
    customerId: state.invoices.customerInvoices.invoice.customer.id,
    customers: state.customers.listData,
    lastPageLoaded: state.customers.lastPageLoaded,
    hasNextPage: state.customers.hasNextPage,
    filters: getFormValues('customersListFilters')(state) || defaultFilterValues,
    t: state.i18n.get('app', 'views', 'Customers', 'CustomersView')
  }
}

function mapDispatchToProps (dispatch) {
  return {
    loadList: (customers, filters, pageToLoad) => { dispatch(loadCustomersListAction(customers, filters, pageToLoad)) },
    cleanList: () => { dispatch(cleanCustomersListAction()) },
    updateCustomer: (...props) => { dispatch(invoiceUpdatePropAction(...props)) }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomersView)
