import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'

import Input from 'shared/components/FormInput'
import Dropdown from 'shared/components/FormDropdown'
import Button from 'shared/components/Button/Button'

import './styles.scss?global'

const PayInvoiceForm = (props) => {
  const { params: { eventId }, handleSubmit, pay, paymentMethods, t, bankAccounts, refreshBankAccounts } = props
  const [isBankAccountDisabled, setIsBankAccountDisabled] = useState(false)
  const submitHandler = (values) => {
    const data = {
      ...values,
      supplierInvoiceId: Number(eventId),
      paymentMethodId: Number(values.paymentMethodId),
      bankAccountId: Number(values.bankAccountId)
    }
    pay(data)
  }

  const refreshBankAccountsHandler = () => {
    setIsBankAccountDisabled(true)
    refreshBankAccounts(() => setIsBankAccountDisabled(false))
  }
  return (
    <div className='pay-invoice-form'>
      <div>
        <div className='pay-invoice-form__bank-account-row'>
          <Field
            hint={t('fields', 'bankAccount').s}
            emptyOption={t('fields', 'bankAccount').s}
            component={Dropdown}
            name='bankAccountId'
            options={bankAccounts}
            className='pay-invoice-form__dropdown'
            disabled={isBankAccountDisabled}
          />
          <Button saveButton onClick={refreshBankAccountsHandler}>
            <span className='icon-circuit' />
          </Button>
        </div>
        <Field
          placeholder={t('fields', 'supplierName').s}
          component={Input}
          name='supplierName'
        />
        <Field
          placeholder={t('fields', 'invoiceNumber').s}
          component={Input}
          name='invoiceNumber'
          inputMode='numeric'
        />
        <Field
          placeholder={t('fields', 'giroNumber').s}
          component={Input}
          name='giroNumber'
          inputMode='numeric'
        />
        <Field
          hint={t('fields', 'giroType').s}
          emptyOption={t('fields', 'giroType').s}
          component={Dropdown}
          name='giroType'
          options={[{ value: 'bankgiro', label: 'bankgiro' }, { value: 'plusgiro', label: 'plusgiro' }]}
          className='pay-invoice-form__dropdown'
        />
        <Field
          placeholder={t('fields', 'amount').s}
          component={Input}
          name='amount'
          inputMode='decimal'
        />
        <Field
          hint={t('fields', 'paymentMethod').s}
          emptyOption={t('fields', 'paymentMethod').s}
          component={Dropdown}
          name='paymentMethodId'
          options={paymentMethods}
          className='pay-invoice-form__dropdown'
        />
      </div>
      <div className='pay-invoice-form__buttons'>
        <Button saveButton onClick={handleSubmit(submitHandler)}>{t('buttons', 'save').s}</Button>
        <Button view='transparent-red' goBackButton>{t('buttons', 'cancel').s}</Button>
      </div>
    </div>
  )
}

PayInvoiceForm.propTypes = {
  bankAccounts: PropTypes.array.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  params: PropTypes.object.isRequired,
  pay: PropTypes.func.isRequired,
  paymentMethods: PropTypes.array.isRequired,
  refreshBankAccounts: PropTypes.func.isRequired
}

const form = 'pay-invoice-form'

function validate (values) {
  let errors = {}

  for (const [key, value] of Object.entries(values)) {
    if (!value) errors[key] = true
  }

  if (values.giroNumber && values.giroNumber.length < 5) {
    errors.giroNumber = 'Must be greater than and equal to 5 digits'
  }

  if (values.invoiceNumber && values.invoiceNumber.length < 5) {
    errors.invoiceNumber = 'Must be greater than and equal to 5 digits'
  }

  return errors
}

const reduxFormHOC = reduxForm({
  form,
  validate,
  enableReinitialize: true
})

export default reduxFormHOC(PayInvoiceForm)
