import { bind } from 'redux-effects'
import { fetch } from 'redux-effects-fetch'
import URI from 'urijs'

// Actions
import { showNavbarSpinnerAction, hideNavbarSpinnerAction } from 'shared/actions/navbarSpinner'
import { routerActions } from 'react-router-redux'
import { showMessageBoxWithParamsAction } from 'MessageBox/actions/messageBox'

// Constants
import { RESET_PASSWORD, RESET_LINK_CHECK } from 'User/ResetPassword/constants/Api'

export function resetPasswordAction (values) {
  return (dispatch, getState) => {
    if (values.password !== values.passwordConfirmation) {
      const t = getState().i18n.get('app', 'views', 'ResetPasswordView', 'messages')
      return dispatch(showMessageBoxWithParamsAction(null, t('passwordsMismatch', 'description').s))
    } else {
      return dispatch([
        showNavbarSpinnerAction(),
        bind(sendResetPasswordRequest(values), afterPasswordReset)
      ])
    }
  }
}

function sendResetPasswordRequest (values) {
  const { password, passwordConfirmation, resetToken } = values
  return fetch(RESET_PASSWORD, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      user: {
        password,
        password_confirmation: passwordConfirmation
      },
      reset_token: resetToken
    })
  })
}

function afterPasswordReset (response) {
  return (dispatch, getState) => {
    const t = getState().i18n.get('app', 'views', 'ResetPasswordView', 'messages')
    const onClose = () => { dispatch(routerActions.replace('/entry')) }

    let actions = [hideNavbarSpinnerAction()]
    if (response.value.success) {
      actions.push(showMessageBoxWithParamsAction(t('resetPasswordDone', 'title').s, t('resetPasswordDone', 'description').s, onClose))
    } else {
      actions.push(showMessageBoxWithParamsAction(null, response.value.error, onClose))
    }

    dispatch(actions)
  }
}

export function checkResetLinkAction (resetToken) {
  return [
    showNavbarSpinnerAction(),
    bind(sendCheckResetLinkRequest(resetToken), processSuccessCheckResetLink, processErrorCheckResetLink)
  ]
}

function sendCheckResetLinkRequest (resetToken) {
  return fetch(URI(RESET_LINK_CHECK).addSearch({ reset_token: resetToken }))
}

function processSuccessCheckResetLink () {
  return hideNavbarSpinnerAction()
}

function processErrorCheckResetLink (response) {
  return (dispatch, getState) => {
    let t = getState().i18n.get('app', 'shared', 'messages')

    const actions = [hideNavbarSpinnerAction()]
    if (response.status === 400) {
      t = getState().i18n.get('app', 'views', 'ResetPasswordView', 'messages')
      const onClose = () => { dispatch(routerActions.replace('/entry')) }
      actions.push(showMessageBoxWithParamsAction(null, t('resetPasswordExpired', 'description').s, onClose))
    } else {
      actions.push(showMessageBoxWithParamsAction(null, t('unexpectedError', 'description').s))
    }

    return dispatch(actions)
  }
}
