import { createAction, createReducer } from 'redux-act'

// Actions
export const updateGuestsList = createAction('update representation guests list')

const reducer = createReducer({
  [updateGuestsList]: (state, payload) => payload
}, [])

export default reducer
