export function calculateVatAmount (amountWithVat, vatPercentage) {
  const amountWithVatNewValue = Number(amountWithVat) || 0
  const vatAmountNewValue = (amountWithVatNewValue * (Number(vatPercentage || 0) / 100) / (1 + Number(vatPercentage || 0) / 100)) || 0

  return vatAmountNewValue
}

export function guessVatPercentage (vatAmount, amountWithVat, vatPercents) {
  vatAmount = Number(vatAmount)
  amountWithVat = Number(amountWithVat)
  const newVatPercentage = Math.abs(100 * vatAmount / (amountWithVat - vatAmount))

  const percent = vatPercents.find(({ value }) => newVatPercentage === Number(value))
  if (!percent) {
    return 'custom'
  }
  return percent.value
}

export function onAmountChange (props, e) {
  const { formValues: { vat }, changeFormValue, formSectionName = '' } = props
  const newAmount = e.target.value
  if (vat !== 'custom' && vat !== '0') {
    const newVatAmountValue = calculateVatAmount(newAmount, vat)
    changeFormValue(getFieldName('vatAmount', formSectionName), formatNewValue(newVatAmountValue))
  }
}

export function onVatAmountChange (props, e) {
  const { vatPercents, formValues: { amountWithVat }, changeFormValue, formSectionName = '' } = props
  let newVatAmount = e.target.value
  changeFormValue(getFieldName('vat', formSectionName), guessVatPercentage(newVatAmount, amountWithVat, vatPercents))
}

export function onVatChange (props, e) {
  const { changeFormValue, formValues: { amountWithVat }, formSectionName = '' } = props
  const newVatValue = e.target.value
  if (newVatValue !== '0' && newVatValue !== 'custom') {
    const newVatAmount = calculateVatAmount(amountWithVat, newVatValue)
    changeFormValue(getFieldName('vatAmount', formSectionName), formatNewValue(newVatAmount))
  } else if (newVatValue === '0') {
    changeFormValue(getFieldName('vatAmount', formSectionName), 0)
  }
}

function formatNewValue (value) {
  if (value % 1 === 0) {
    return String(value)
  } else {
    return value.toFixed(2)
  }
}

const getFieldName = (fieldName, formSectionName) =>
  formSectionName ? `${formSectionName}.${fieldName}` : fieldName
