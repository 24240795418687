import PropTypes from 'prop-types'
import React, { Component } from 'react'

// Components
import Navigation from 'Navigation/Navigation'
import KeyFigureReport from 'Reports/components/KeyFigureReportBox/KeyFigureReportBox'
import KeyFiguresReportContainer from 'Reports/views/KeyFigures/containers/Common'

class KeyFiguresReportView extends Component {
  static propTypes = {
    t: PropTypes.func
  }

  render () {
    const { t } = this.props
    return (
      <Navigation hideFooter backLink='/overview' title={t('navbar', 'title').s} >
        <KeyFigureReport {...this.props} />
      </Navigation>
    )
  }
}

export default KeyFiguresReportContainer(KeyFiguresReportView)
