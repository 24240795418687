import './InvoicePdfPreview.scss?global'

import Smartphones from 'Invoices/CustomerInvoices/views/InvoicePdfPreview/containers/Smartphones'
import Tablets from 'Invoices/CustomerInvoices/views/InvoicePdfPreview/containers/Tablets'

import {getCurrentDeviceView} from 'shared/helpers/utils'

const InvoicePdfPreview = getCurrentDeviceView(Smartphones, Tablets)

export default InvoicePdfPreview
