exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._2xKoMy9ERMqKbcuPKjSt_P {\n  background: #fff;\n  display: flex;\n  flex-flow: column;\n  padding: 2rem 4rem;\n  border-top: 1px #ccc solid;\n}\n\n._2xKoMy9ERMqKbcuPKjSt_P .button {\n  margin-bottom: 2rem;\n}\n\n._2v_Mr8ftMNeLXi-GEPqBD3 {\n  display: flex;\n  flex-flow: row;\n  justify-content: space-between;\n  margin-bottom: 2rem;\n}\n\n._2skMusZPsngUcPpHyf9_45 {\n  font-weight: bold;\n  font-size: 3rem;\n}\n\n", "", {"version":3,"sources":["/home/circleci/mobile_ui/src/Invoices/SupplierInvoices/components/ApproveForPaymentsBlock/styles.scss"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,cAAc;EACd,kBAAkB;EAClB,mBAAmB;EACnB,2BAA2B;CAC5B;;AAED;EACE,oBAAoB;CACrB;;AAED;EACE,cAAc;EACd,eAAe;EACf,+BAA+B;EAC/B,oBAAoB;CACrB;;AAED;EACE,kBAAkB;EAClB,gBAAgB;CACjB","file":"styles.scss","sourcesContent":[".block {\n  background: #fff;\n  display: flex;\n  flex-flow: column;\n  padding: 2rem 4rem;\n  border-top: 1px #ccc solid;\n}\n\n.block :global(.button) {\n  margin-bottom: 2rem;\n}\n\n.sum {\n  display: flex;\n  flex-flow: row;\n  justify-content: space-between;\n  margin-bottom: 2rem;\n}\n\n.sumNumber {\n  font-weight: bold;\n  font-size: 3rem;\n}\n\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"block": "_2xKoMy9ERMqKbcuPKjSt_P",
	"sum": "_2v_Mr8ftMNeLXi-GEPqBD3",
	"sumNumber": "_2skMusZPsngUcPpHyf9_45"
};