import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'

// Components
import ContinueMessageBox from 'MessageBox/components/ContinueMessageBox'

const EventMessageBox = (props) => {
  const { t, ...restProps } = props

  return (
    <ContinueMessageBox
      title={t('title').s} description={t('description').s} {...restProps}
    />
  )
}
EventMessageBox.propTypes = {
  t: PropTypes.func
}

function mapStateToProps (state) {
  return {
    t: state.i18n.get('app', 'shared', 'messages', 'newEvent')
  }
}

export default connect(mapStateToProps)(EventMessageBox)
