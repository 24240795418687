import PropTypes from 'prop-types'
import { Component } from 'react'

import { connect } from 'react-redux'

export default function createClearFormComponent (cleanAction) {
  class ClearFormComponent extends Component {
    static propTypes = {
      dispatch: PropTypes.func,
      children: PropTypes.element
    };

    componentWillUnmount () {
      if (typeof cleanAction === 'function') {
        this.props.dispatch(cleanAction())
      } else {
        this.props.dispatch(cleanAction)
      }
    }

    render () {
      return this.props.children
    }
  }

  return connect()(ClearFormComponent)
}
