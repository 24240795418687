import './SortButton.scss?global'

import PropTypes from 'prop-types'

import React, { Component } from 'react'
import cs from 'classnames'

import getGreyLabelOptions from 'GreyLabel/helpers/greyLabel'

export default class SortButton extends Component {
  static propTypes = {
    onClick: PropTypes.func,
    type: PropTypes.string,
    value: PropTypes.string,
    disabled: PropTypes.bool
  }

  constructor (props) {
    super(props)
    this.state = {active: false}
    this.onClick = this.onClick.bind(this)
  }

  onClick () {
    const {onClick, disabled} = this.props
    onClick && typeof onClick === 'function' && !disabled && onClick()
  }

  componentWillReceiveProps (props) {
    const {type, value} = props
    this.setState({active: type === value})
  }

  componentWillMount () {
    const {type, value} = this.props
    this.setState({active: type === value})
  }

  render () {
    const {type, disabled} = this.props
    const {active} = this.state
    const { whiteLabel } = getGreyLabelOptions()
    const sortButtonClass = cs('events-filter-sort-button', whiteLabel)
    const activeButtonClass = cs('events-filter-sort-button--active', `active-${whiteLabel}`)
    const disabledButtonClass = disabled ? 'events-filter-sort-button--disabled' : ''
    return (
      <div className={`${sortButtonClass} ${(active && !disabled) ? activeButtonClass : ''} ${disabledButtonClass}`} onClick={this.onClick}>
        <span className={type === 'desc' ? 'icon-sort-desk' : 'icon-sort-ask'} />
      </div>
    )
  }
}
