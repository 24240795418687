import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import IpadNavigation from 'Navigation/IpadNavigation'
import {TimesheetsImportView as TimesheetsImport} from 'Timesheet/views/TimesheetsImportView/containers/Smartphones'
import Filters from 'Timesheet/views/TimesheetsView/Filters/Filters'

import getLeftComponent from 'Navigation/helpers/linkAccordance'

class TimesheetsImportView extends Component {
  static propTypes = {
    importItemsToForm: PropTypes.func,
    itemsForImport: PropTypes.arrayOf(PropTypes.object),
    backLink: PropTypes.string,
    t: PropTypes.func,
    previousLink: PropTypes.string
  }

  constructor (props) {
    super(props)
    this.extendList = this.extendList.bind(this)
  }

  extendList () {
    this.refs.timesheets.getWrappedInstance().extendList()
  }

  render () {
    const { importItemsToForm, itemsForImport, t, backLink, previousLink } = this.props
    const actions = [{ path: 'timesheets/new?cameFrom=invoice', icon: 'icon-add' }]

    const RightSideItems = getLeftComponent('selectableItemList', previousLink)

    const filterElement = <Filters onImportClick={() => { importItemsToForm(itemsForImport) }} />

    return (
      <IpadNavigation
        {...this.props}
        title={t('title').s} actions={actions}
        infinityScrollHandler={this.extendList}
        backLink={backLink}
        filter={filterElement}
      >
        <RightSideItems {...this.props} />
        <TimesheetsImport {...this.props} ref='timesheets' />
      </IpadNavigation>
    )
  }
}

function mapStateToProps (state) {
  return {
    previousLink: state.appInfo.previousLink,
    itemsForImport: state.importItems,
    t: state.i18n.get('app', 'views', 'Timesheet', 'import'),
    t_button: state.i18n.get('app', 'shared', 'buttons')
  }
}

export default connect(mapStateToProps)(TimesheetsImportView)
