import React from 'react'
import { Link } from 'react-router'

import FormatDate from 'shared/components/FormatDate/FormatDate'

export function DocumentsListItem (info) {
  const {companyId, id, title, date, category} = info.data
  return (
    <Link to={`/documents/${companyId}/${id}`} className='list-multiline-item'>
      <div className='list-multiline-item__row'>
        <div>
          <h4>{title}</h4>
          <h4>{category}</h4>
        </div>
        <div>
          <h5><FormatDate value={date} /></h5>
        </div>
      </div>
      <h4 className='list-multiline-item__arrow'><span className='icon-arrow-right' /></h4>
    </Link>
  )
}
