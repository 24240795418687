import React, { Component } from 'react'

import Navigation from 'Navigation/Navigation'
import DirectPayment from 'Invoices/CustomerInvoices/components/DirectPayment'
import DirectPaymentHOC from 'Invoices/CustomerInvoices/views/DirectPaymentView/containers/Common'

class DirectPaymentView extends Component {
  render () {
    return (
      <Navigation title={this.props.t('title').s} className='f-column'>
        <DirectPayment {...this.props} />
      </Navigation>
    )
  }
}

export default DirectPaymentHOC(DirectPaymentView)
