/* eslint-disable camelcase */
import PropTypes from 'prop-types'

import React, { PureComponent } from 'react'

// Components
import PieChart from 'shared/components/Chart/Pie'
import ListView from 'shared/components/ListView/ListView'
import PercentLineChart from 'Overview/FinanceVisualization/components/PercentLineChart'

export default class FinanceVisualizationCharts extends PureComponent {
  static propTypes = {
    totals: PropTypes.object,
    location: PropTypes.object,
    t: PropTypes.func,
    getColor: PropTypes.func,
    tabletMode: PropTypes.bool
  }

  transformPieData () {
    const {t, totals: {totalAmount, finance}} = this.props
    let categories = finance.slice(0, 9)
    const othersCategories = finance.slice(9)

    const otherCategoriesInfo = othersCategories.reduce((prev, current) => {
      return { categorySum: prev.categorySum + Math.abs(current.categorySum), actualSum: prev.actualSum + current.categorySum }
    }, {categorySum: 0, actualSum: 0})
    categories = [
      ...categories.map((e) => {
        return {sum: e.categorySum, percent: e.percent, name: e.categoryName}
      }),
      {
        sum: otherCategoriesInfo.categorySum,
        percent: Math.round(otherCategoriesInfo.actualSum / totalAmount * 100),
        name: t('otherCategory').toString()
      }
    ]
    return categories
  }

  render () {
    const {totals, t, location: { query: { chart_type = 'pie' } }, getColor, tabletMode} = this.props
    const renderChart = () => {
      if (totals.finance && totals.finance.length) {
        const data = this.transformPieData(totals.finance)
        if (!tabletMode) {
          return (
            <div>
              <div style={{display: chart_type === 'bars' ? 'none' : 'block'}}>
                <PieChart data={data} getColor={getColor} />
              </div>
              <div style={{display: chart_type === 'pie' ? 'none' : 'block'}}>
                <PercentLineChart {...this.props} />
              </div>
            </div>
          )
        } else {
          return (
            <PieChart data={data} getColor={getColor} />
          )
        }
      } else if (totals.finance && totals.finance.length === 0) {
        return <ListView data={[]} emptyListOptions={{icon: 'icon-events', title: t('reportIsNotAvailable').s}} />
      }
    }

    return (
      <div className='f-column'>
        {renderChart()}
      </div>
    )
  }
}
/* eslint-enable camelcase */
