import React from 'react'
import { Route } from 'react-router'
import { destroy } from 'redux-form'
import {requireAuthentication} from '../../../User/Auth/higherOrderComponents/AuthComponent'

import SuppliersView from '../views/SuppliersView/SuppliersView'
import SupplierView from '../views/SupplierView/SupplierView'

// Form-cleaning components
import createClearFormComponent from '../../../shared/factories/ClearForm'
import { clearSupplierInfoAction } from '../actions/suppliers'

const ClearSupplierForm = createClearFormComponent(clearSupplierInfoAction)
const destroySuppliersListFiltersForm = (dispatch) => dispatch(destroy('suppliersListFilters'))

const suppliersRoutes = (
  <Route onLeave={destroySuppliersListFiltersForm}>
    <Route path='/suppliers' component={requireAuthentication(SuppliersView)} />
    <Route component={ClearSupplierForm} >
      <Route path='/suppliers/:counterpartyId' component={requireAuthentication(SupplierView)} />
    </Route>
  </Route>
)

export default suppliersRoutes
