import { CUSTOMERS_LIST_UPDATE, CUSTOMERS_LIST_CLEAN, CUSTOMER_UPDATE_PROPS, CUSTOMER_CLEAR } from '../constants/ActionTypes'

const initialState = {
  lastPageLoaded: 0,
  hasNextPage: true,
  listData: [],
  customer: {}
}

export default function (state = initialState, action) {
  switch (action.type) {
    case CUSTOMERS_LIST_UPDATE:
      return {
        ...state,
        ...action.payload
      }
    case CUSTOMERS_LIST_CLEAN:
      const { customer } = state
      return {
        ...initialState,
        customer: customer
      }
    case CUSTOMER_UPDATE_PROPS:
      return {
        ...state,
        customer: {
          ...state.customer,
          ...action.payload
        }
      }
    case CUSTOMER_CLEAR:
      return {
        ...state,
        customer: initialState.customer
      }
    default:
      return state
  }
}
