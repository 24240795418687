import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Field } from 'redux-form'

// Components
import Input from 'shared/components/FormInput'
import InvoiceTypeButton from '../../InvoiceCreateView/components/partials/InvoiceTypeButton'
import Button from 'shared/components/Button/Button'
import FormatNumber from 'shared/components/FormatNumber/FormatNumber'
import InvoiceRow from './partials/InvoiceRow'
import DotsSpinner from 'shared/components/DotsSpinner'

// ListView
import CheckBoxList from 'CheckBoxList/components/CheckBoxList/CheckBoxList'

// Constants
import { rutPercent, rotPercent, ROT, RUT } from '../../../../shared/constants/RotRutTypes'

export default class InvoiceRotRut extends Component {
  static propTypes = {
    changeRotRutType: PropTypes.func,
    changeCustomAmount: PropTypes.func,
    changePercentage: PropTypes.func,
    formValues: PropTypes.object,
    initialValues: PropTypes.object,
    invoiceRows: PropTypes.arrayOf(PropTypes.object),
    recalculateTotals: PropTypes.func,
    recalcultatePercentage: PropTypes.func,
    saveRotRutObject: PropTypes.func,
    handleSubmit: PropTypes.func,
    t: PropTypes.func,
    isCalculating: PropTypes.bool
  }

  onCheck = () => {
    this.props.recalculateTotals()
  }

  onUncheck = () => {
    this.props.recalculateTotals()
  }

  onPercentageChange = (e, newValue) => {
    e.preventDefault()
    const { changeCustomAmount, changePercentage, changeRotRutType } = this.props
    changeCustomAmount('')
    const value = Number(newValue)
    changePercentage(newValue)
    if (value === rotPercent || value === rutPercent) {
      changeRotRutType(value === rotPercent ? ROT : RUT)
    } else {
      changeRotRutType(null)
    }
  }

  onPercentageBlur = (e, newValue) => {
    e.preventDefault()
    this.props.changePercentage(newValue)
    this.props.recalculateTotals()
  }

  onCustomAmountChange = (e, newValue) => {
    e.preventDefault()
    this.props.changeCustomAmount(this.handleValueChange(newValue))
    this.props.recalcultatePercentage(newValue)
  }

  onCustomAmountBlur = (e, newValue) => {
    e.preventDefault()
    this.props.changeCustomAmount(this.handleValueChange(newValue))
    this.props.recalculateTotals()
  }

  handleValueChange = (value) => {
    const { initialValues: { invoiceFinalTotalAmount } } = this.props
    let correctedValue

    if (Number(value) > 50000) {
      correctedValue = 50000
    } else if (Number(value) >= invoiceFinalTotalAmount && Number(value) <= 50000) {
      correctedValue = invoiceFinalTotalAmount
    } else {
      correctedValue = Number(value) > 0 ? Number(value) : ''
    }

    return correctedValue
  }

  onRotRutChange = (e, newValue) => {
    e.preventDefault()
    const { changeCustomAmount, changePercentage, changeRotRutType, recalculateTotals } = this.props
    changeCustomAmount('')
    changeRotRutType(newValue)
    let value
    if (newValue === ROT) {
      value = rotPercent
    } else {
      value = rutPercent
    }
    changePercentage(value)
    recalculateTotals()
  }

  render () {
    const {
      formValues: { rotRutAmount, invoiceFinalTotalAmount, rotRutType },
      invoiceRows,
      saveRotRutObject,
      handleSubmit,
      isCalculating,
      t
    } = this.props
    const isROT = rotRutType === ROT

    return (
      <div className='create-invoice rot-rut f-column'>
        <div className='f-column-top'>
          <div className='rot-rut__content'>
            <div className='rot-rut__content__left'>
              <div className='create-invoice__types-block'>
                <Field name='rotRutType' component={InvoiceTypeButton} onChange={this.onRotRutChange} radioValue={ROT} text='ROT' />
                <Field name='rotRutType' component={InvoiceTypeButton} onChange={this.onRotRutChange} radioValue={RUT} text='RUT' />
              </div>
              <div className='form'>
                <div className='form__item'>
                  <Field
                    name='percentage'
                    component={Input}
                    type='number'
                    onChange={this.onPercentageChange}
                    onBlur={this.onPercentageBlur}
                    placeholder={t('percentage').s} maxValue='100'
                  />
                </div>
                <div className='form__item'>
                  <Field
                    name='customAmount'
                    component={Input}
                    onChange={this.onCustomAmountChange}
                    onBlur={this.onCustomAmountBlur}
                    type='number'
                    placeholder={t('customTotal').s}
                  />
                </div>
                <div className='form__item'>
                  <Field name='personalNumber' component={Input} placeholder={t('personalNumber').s} />
                </div>
                {isROT && <div className='form__item'>
                  <Field name='buildingNumber' component={Input} placeholder={t('buildingNumber').s} />
                </div>}
              </div>
            </div>
            <div className='rot-rut__content__right'>
              <div className='rot-rut__rows'>
                {invoiceRows.length ? <div className='hr'><span>{t('specification', 'name').s}</span></div> : null}
                <div className='form-rows create-invoice__rows create-invoice__rows--clipped'>
                  <CheckBoxList
                    items={invoiceRows}
                    template={InvoiceRow}
                    shouldBeChecked={(item) => { return item.rotRut === true }}
                    withoutWrap
                    onCheck={this.onCheck}
                    onUncheck={this.onUncheck} />
                </div>
              </div>
            </div>
          </div>

          <div className='rot-rut__total-tab'>
            <div className='rot-rut__total-tab__wrap'>
              <div>
                <h4>{t('grandTotal').s}</h4>
                <h4>
                  { isCalculating ? <DotsSpinner /> : <FormatNumber value={invoiceFinalTotalAmount} /> }
                </h4>
              </div>
              <div>
                <h4>{t('rotRutTotal').s}</h4>
                <h4>
                  { isCalculating ? <DotsSpinner /> : <FormatNumber value={rotRutAmount} /> }
                </h4>
              </div>
            </div>
          </div>
        </div>

        <div className='f-column-bottom form-buttons'>
          <Button view='transparent-black' onClick={handleSubmit(() => { saveRotRutObject() })}>{t('buttons', 'save').s}</Button>
          <Button view='transparent-red' goBackButton>{t('buttons', 'cancel').s}</Button>
        </div>
      </div>
    )
  }
}
