import { connect } from 'react-redux'

// Views
import EventsImportView from 'Events/EventsImport/views/EventsImportView'

// Actions
import { importEventRowAction } from '../../../actions/imports'
import { routerActions } from 'react-router-redux'

// Helpers
import { getTrimmedLink } from 'shared/helpers/linkUtils'

// Constants
import {outType, inType} from 'Events/shared/constants/eventType'

function mapStateToProps (state, ownProps) {
  const {location: {pathname}} = ownProps

  return {
    cameFrom: 'invoice',
    backLink: getTrimmedLink(pathname, 1),
    requestParams: { eventType: `${outType},${inType}` }
  }
}

function mapDispatchToProps (dispatch) {
  return {
    importItemsToForm: (data) => { dispatch([importEventRowAction(data), routerActions.goBack()]) }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EventsImportView)
