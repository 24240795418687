import { useEffect, useState } from 'react'
import * as Sentry from '@sentry/browser'

import {
  checkActivationRequest,
  checkPaymentStatusRequest,
  createCreditsafeUserRequest
} from 'MarketPlace/Creditsafe/actions'

export function usePaymentStatus (query, t) {
  const [status, setStatus] = useState('processing')
  const [error, setError] = useState(null)

  useEffect(function () {
    let timeout = null

    if (!query.payment_intent) {
      setStatus('success')
      return
    }

    const checkPayment = async () => {
      try {
        let response = await checkPaymentStatusRequest({
          payment_intent: query.payment_intent,
          credit_report_purchase_id: parseInt(query.credit_report_purchase_id)
        })
        response = response.data
        if (response.success) {
          setStatus('success')
        } else if (response.transaction_status === 'processing') {
          timeout = setTimeout(checkPayment, 2000)
        } else if (response.transaction_status === 'payment_failed') {
          setError(t('paymentError').s)
        } else {
          Sentry.withScope(scope => {
            scope.setExtra('message', response)
            Sentry.captureException('Creditsafe failed on payment check')
          })
          setError(t('somethingWrong').s)
        }
      } catch (e) {
        Sentry.withScope(scope => {
          scope.setExtra('query', query)
          Sentry.captureException(e)
        })
        setError(t('somethingWrong').s)
      }
    }

    checkPayment()

    return () => clearTimeout(timeout)
  }, [query, t])

  return [status, error]
}

export function useAccountStatus () {
  const [hasAccount, setHasAccount] = useState(false)
  const [error, setError] = useState(null)

  useEffect(function checkUser () {
    let timeout = null

    const checkActivation = (response) => {
      try {
        if (response.success) {
          if (response.activated) {
            setHasAccount(true)
          } else {
            timeout = setTimeout(doCheckActivation, 3000)
          }
        } else {
          Sentry.withScope(scope => {
            scope.setExtra('message', response.data)
            Sentry.captureException('Creditsafe failed on user creation or activation check')
          })
          setError(response.message)
        }
      } catch (e) {
        Sentry.captureException(e)
        setError('Something went wrong')
      }
    }

    const doCheckActivation = async () => {
      const response = await checkActivationRequest()
      checkActivation(response.data)
    }
    const createUser = async () => {
      const response = await createCreditsafeUserRequest()
      checkActivation(response.data)
    }

    createUser()

    return () => clearTimeout(timeout)
  }, [])

  return [hasAccount, error]
}
