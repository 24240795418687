import { bind } from 'redux-effects'
import { createAction } from 'redux-actions'
import { routerActions } from 'react-router-redux'
import { findLast, isEmpty } from 'lodash'
import { getCurrentClientCompany } from 'api-client-connector/utils'
import { orgNumberMaskProps } from 'mrshoebox-ui-components/src/helpers/countrySpecific'

import { fetch2 as fetch } from 'shared/helpers/fetch'

import { COMPANY_INFO } from '../constants/Api'
import { USER_SETTINGS_ADD } from '../constants/UserSettings'

const updateUserSettings = createAction(USER_SETTINGS_ADD)

export function loadCurrentCompanyInfoAction () {
  return (dispatch) => {
    const currentCompanyId = getCurrentClientCompany()

    return dispatch(
      bind(fetch(COMPANY_INFO + currentCompanyId, { legacy_id: true }), processResponse)
    )
  }
}

function processResponse (response) {
  return updateUserSettings({ company: mapCompanyObject(response) })
}

export function mapCompanyObject (response) {
  return {
    phone: response.value.telephone,
    companyName: response.value.name,
    organizationNumber: response.value.org_number,
    vatNumber: response.value.VAT_number,
    taxNumber: response.value.tax_number,
    gdprAgreement: response.value.gdpr_agreement,
    ...transformAddresses(response.value.addresses)
  }
}

function transformAddresses (addresses) {
  const primaryAddress = findLast(addresses, { billable: true }) || addresses[0] || {}

  let result = {
    primaryAddress: {
      id: primaryAddress.id,
      city: primaryAddress.city,
      country: primaryAddress.country,
      street: primaryAddress.street,
      zip: primaryAddress.postal_code
    }
  }

  return result
}

export function updateCurrentCompanyInfoAction (values, afterSaveRedirectPath) {
  return (dispatch) => {
    const currentCompanyId = getCurrentClientCompany()

    return dispatch(
      bind(updateInfoRequest(currentCompanyId, values), afterUpdate.bind(this, afterSaveRedirectPath))
    )
  }
}

function afterUpdate (afterSaveRedirectPath) {
  if (!afterSaveRedirectPath) {
    return [loadCurrentCompanyInfoAction(), routerActions.goBack()]
  }

  const currentCompanyId = getCurrentClientCompany()
  return bind(
    fetch(COMPANY_INFO + currentCompanyId, { legacy_id: true }),
    saveDataAndPerformRedirect.bind(this, afterSaveRedirectPath)
  )
}

function saveDataAndPerformRedirect (afterSaveRedirectPath, response) {
  return (dispatch) => {
    dispatch(processResponse(response))

    dispatch(routerActions.goBack())
    setTimeout(() => {
      dispatch(routerActions.push(afterSaveRedirectPath))
    }, 40)
  }
}

export function updateInfoRequest (currentCompanyId, values) {
  const addresses = prepareAddressForUpdateRequest(values)
  return fetch(COMPANY_INFO + currentCompanyId, { legacy_id: true }, {
    method: 'PATCH',
    headers: {
      'content-type': 'application/json'
    },
    body: JSON.stringify({
      client_company: {
        name: values.companyName,
        org_number: orgNumberMaskProps.normalize(values.organizationNumber),
        tax_number: values.taxNumber,
        VAT_number: values.vatNumber,
        gdpr_agreement: values.gdprAgreement,
        addresses_attributes: addresses
      }
    })
  })
}

function prepareAddressForUpdateRequest (values) {
  let addresses = []

  if (isEmpty(values.primaryAddress)) {
    return addresses
  }

  addresses.push({
    id: values.primaryAddress.id,
    city: values.primaryAddress.city,
    country: values.primaryAddress.country,
    street: values.primaryAddress.street,
    postal_code: values.primaryAddress.zip,
    billable: true
  })

  return addresses
}
