import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { getTrimmedLink } from 'shared/helpers/linkUtils'
import { connect } from 'react-redux'

// Components
import Navigation from 'Navigation/Navigation'
import FullPDF from 'shared/components/FullPDF/FullPDF'

export class InvoicePdfPreview extends Component {
  static propTypes = {
    location: PropTypes.object
  }

  render () {
    const {location: { query: { path } }} = this.props
    return (
      <div className='invoice-pdf-preview' >
        <FullPDF path={path} />
      </div>
    )
  }
}

class InvoicePdfPreviewWithNavigation extends Component {
  static propTypes = {
    location: PropTypes.object,
    t: PropTypes.func
  }
  render () {
    const { location: { pathname }, t } = this.props

    return (
      <Navigation hideFooter backLink={getTrimmedLink(pathname, 1)} title={t('navbar', 'title').s}>
        <InvoicePdfPreview {...this.props} />
      </Navigation>
    )
  }
}

function mapStateToProps (state) {
  return {
    t: state.i18n.get('app', 'views', 'Invoices', 'InvoicePdfPreview')
  }
}

export const InvoicePdfPreviewView = connect(mapStateToProps)(InvoicePdfPreview)
export default connect(mapStateToProps)(InvoicePdfPreviewWithNavigation)
