import PropTypes from 'prop-types'
import React, { Component } from 'react'

import './Grid.scss?global'

export default class Grid extends Component {
  static propTypes = {
    children: PropTypes.node,
    type: PropTypes.string,
    className: PropTypes.string
  };

  render () {
    const {children, type, className} = this.props

    return (
      <div className={'grid ' + (type || 'flex') + ' ' + (className || '')}>{children}</div>
    )
  }
}
