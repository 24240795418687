import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import Navigation from 'Navigation/Navigation'
import PayInvoiceForm from 'Events/EventPay/components/PayInvoiceForm'

import { getValidatedFields, payInvoice, refreshBankAccounts } from 'Events/EventPay/actions/eventPay'

class EventPayView extends React.Component {
  static propTypes = {
    params: PropTypes.object.isRequired,
    validateFields: PropTypes.func.isRequired
  }

  componentDidMount () {
    const { validateFields, params: { eventId } } = this.props
    validateFields({ supplier_invoice_id: Number(eventId) })
  }

  render () {
    return (
      <PayInvoiceForm {...this.props} />
    )
  }
}

const EventPayViewWithNavigation = (props) => {
  return (
    <Navigation title={props.t('navbar', 'title').s} hideFooter notBottomSpace>
      <EventPayView {...props} />
    </Navigation>
  )
}

const mapStateToProps = (state) => {
  const { bankAccounts = [], ...initialValues } = state.events.invoicePaymentValidation.initialValues
  return (
    {
      initialValues: { ...initialValues, bankAccountId: null },
      paymentMethods: state.userSettings.expensePaymentMethods,
      bankAccounts: bankAccounts.map(a => ({ value: a.id, label: a.bankNumber })),
      t: state.i18n.get('app', 'views', 'EventPayView')
    }
  )
}

const mapDispatchToProps = (dispatch) => ({
  validateFields: (params) => dispatch(getValidatedFields(params)),
  refreshBankAccounts: (onSuccess) => dispatch(refreshBankAccounts(onSuccess)),
  pay: (values) => dispatch(payInvoice(values))
})

export const connectedEventPayView = connect(mapStateToProps, mapDispatchToProps)(EventPayView)
export default connect(mapStateToProps, mapDispatchToProps)(EventPayViewWithNavigation)
