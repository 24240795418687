import React from 'react'
import { Route } from 'react-router'

import {requireAuthentication} from 'Wizard/helpers/requireAuthentication'

import WizardProfile from 'Wizard/views/WizardProfile/WizardProfile'
import WizardNextActions from 'Wizard/views/WizardNextActions'
import WizardBaseInfo from 'Wizard/views/WizardBaseInfo/WizardBaseInfo'
import WizardOffers from 'Wizard/views/WizardOffers/WizardOffers'
import WizardAdministrator from 'Wizard/views/WizardAdministrator/WizardAdministrator'
import WizardTerms from 'Wizard/views/WizardTerms/WizardTerms'

import {
  InvoiceTutorialPage,
  ReceiptTutorialPage,
  TimesheetTutorialPage,
  DriverLogTutorialPage
} from 'Wizard/views/WizardTutorials'

const wizardRoutes = (
  <Route>
    <Route path='/wizard/profile' component={requireAuthentication(WizardProfile)} />
    <Route path='/wizard/next-actions' component={requireAuthentication(WizardNextActions)} />
    <Route path='/wizard/base-info' component={requireAuthentication(WizardBaseInfo)} />
    <Route path='/wizard/offers' component={requireAuthentication(WizardOffers)} />
    <Route path='/wizard/administrator' component={requireAuthentication(WizardAdministrator)} />
    <Route path='/wizard/terms' component={requireAuthentication(WizardTerms)} />

    <Route path='/wizard/tutorial/invoice' component={requireAuthentication(InvoiceTutorialPage)} />
    <Route path='/wizard/tutorial/receipt' component={requireAuthentication(ReceiptTutorialPage)} />
    <Route path='/wizard/tutorial/timesheet' component={requireAuthentication(TimesheetTutorialPage)} />
    <Route path='/wizard/tutorial/driverlog' component={requireAuthentication(DriverLogTutorialPage)} />
  </Route>
)

export default wizardRoutes
