import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import Button from 'shared/components/Button/Button'
import BackLink from 'User/shared/components/BackLink/BackLink'
import FullscreenSpinner from 'User/shared/containers/FullscreenSpinner/FullscreenSpinner'

import { isDesktop } from 'shared/helpers/utils'

import { Container, UpperContent, BottomContent } from '../../components/LoginContainer'

import { default as authenticateThroughBankID, onCancelAction } from 'User/Auth/actions/zignsecAuth'

import { getBackgroundPath } from 'User/shared/helpers'

const backgroundPath = getBackgroundPath()

class SelectLoginMethodView extends Component {
  static propTypes = {
    t: PropTypes.func,
    openBankIDApp: PropTypes.func,
    onCancel: PropTypes.func
  }

  get bankIDButtons () {
    const { t } = this.props

    if (isDesktop()) {
      return [
        <Button view='primary' svgIcon='bankid' link='/login_bankid' key='1'>{t('buttons', 'bankID').s}</Button>
      ]
    } else {
      return [
        <Button view='primary' svgIcon='bankid' onClick={this.props.openBankIDApp} key='1'>{t('buttons', 'mobileBankID').s}</Button>
      ]
    }
  }

  render () {
    const { t, onCancel } = this.props

    return (
      <Container background={backgroundPath}>
        <UpperContent>
          &nbsp;
        </UpperContent>
        <BottomContent>
          { this.bankIDButtons }
          <div className='hr2'><span>{t('labels', 'or').s}</span></div>
          <Button view='transparent-white' link='/login_password'>{t('fields', 'password').s}</Button>
        </BottomContent>
        <BackLink transparent link='/entry' text={t('back').s} />
        <FullscreenSpinner
          onCancelClick={onCancel}
        />
      </Container>
    )
  }
}

function mapStateToProps (state) {
  return {
    t: state.i18n.get('app', 'views', 'LoginView')
  }
}

function mapDispatchToProps (dispatch) {
  return {
    openBankIDApp: () => { dispatch(authenticateThroughBankID()) },
    onCancel: () => { dispatch(onCancelAction()) }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectLoginMethodView)
