import React from 'react'
import PropTypes from 'prop-types'
import { reduxForm, getFormMeta } from 'redux-form'
import { connect } from 'react-redux'
import { routerActions } from 'react-router-redux'

// Actions
import {
  addRepresentationContactAction,
  deleteRepresentationContactAction,
  saveRepresentationAction,
  prepareRepresentationAction
} from 'Events/Expense/actions/representation'

import { showMessageBoxWithParamsAction } from 'MessageBox/actions/messageBox'

export default function RepresentationContainer (Component) {
  class Representation extends React.Component {
    static propTypes = {
      addContact: PropTypes.func,
      prepareRepresentation: PropTypes.func
    }

    componentDidMount () {
      this.props.prepareRepresentation()
    }

    render () {
      return (
        <Component {...this.props} />
      )
    }
  }

  const formName = 'representation'

  function mapStateToProps (state) {
    const copyDefault = {representationGuests: [], representationCause: '', withAlcohol: false, internalRepresentation: false}
    return {
      expense: state.events.newEvent.representationCopy || copyDefault,
      initialValues: state.events.newEvent.representationCopy || copyDefault,
      t: state.i18n.get('app', 'views', 'ExpenseRepresentationView'),
      t_button: state.i18n.get('app', 'shared', 'buttons'),
      meta: getFormMeta(formName)(state)
    }
  }

  function mapDispatchToProps (dispatch, ownProps) {
    const phonebookPath = ownProps.location.pathname + '/import_contacts'
    return {
      prepareRepresentation: () => { dispatch(prepareRepresentationAction()) },
      addContact: (contactInfo, index) => { dispatch(addRepresentationContactAction(contactInfo, index)) },
      deleteContact: (index) => { dispatch(deleteRepresentationContactAction(index)) },
      save: (data) => { dispatch(saveRepresentationAction(data)) },
      showMessageBox: (title, message) => { dispatch(showMessageBoxWithParamsAction(title, message)) },
      openPhonebook: () => { dispatch(routerActions.push(phonebookPath)) }
    }
  }

  function validate (values) {
    let errors = {}
    if (!values.representationCause) {
      errors.representationCause = true
    }

    return errors
  }

  return connect(mapStateToProps, mapDispatchToProps)(reduxForm({
    form: formName,
    validate,
    destroyOnUnmount: false
  })(Representation))
}
