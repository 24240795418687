import './Filter.scss?global'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { reduxForm, getFormMeta, change, formValueSelector, Field } from 'redux-form'

// Components
import SearchField from 'shared/components/FormSearchField'
import Dropdown from 'shared/components/FormDropdown'
import SortButton from 'Events/Events/components/SortButton/SortButton'

// Actions
import { reloadDocumentsListAction } from '../../actions/documentsList'

class DocumentsViewFilters extends Component {
  static propTypes = {
    t: PropTypes.func,
    canBeHidden: PropTypes.bool,
    meta: PropTypes.object,
    changeOrderType: PropTypes.func,
    orderType: PropTypes.string
  }

  render () {
    const { t, canBeHidden, meta, changeOrderType, orderType } = this.props

    if (meta.search && !meta.search.visited && canBeHidden) {
      return <div />
    }

    return (
      <div className='filter filter--without-fixed-height document-filters'>
        <div>
          <div className='filter__multi-row filter__multi-row--small-margin'>
            <div>
              <Field
                name='orderBy'
                component={Dropdown}
                options={[
                  {label: t('orderBy', 'date').s, value: 'date_created'},
                  {label: t('orderBy', 'category').s, value: 'category'},
                  {label: t('orderBy', 'title').s, value: 'title'}
                ]}
              />
            </div>
            <div className='filter__multi-row'>
              <div>
                <SortButton type='desc' value={orderType} onClick={() => changeOrderType('desc')} />
              </div>
              <div>
                <SortButton type='asc' value={orderType} onClick={() => changeOrderType('asc')} />
              </div>
            </div>
          </div>
          <Field
            name='search'
            component={SearchField}
            changeAfterBlur
            placeholder={t('search', 'placeholder').s}
          />
        </div>
      </div>
    )
  }
}

export const initialFilterValues = {
  search: '',
  orderBy: 'date_created',
  orderType: 'desc'
}
const formName = 'documentsViewFilters'
const selector = formValueSelector(formName)
const metaSelector = getFormMeta(formName)

function mapStateToProps (state) {
  return {
    initialValues: initialFilterValues,
    t: state.i18n.get('app', 'views', 'DocumentsView', 'filters'),
    meta: metaSelector(state),
    orderType: selector(state, 'orderType')
  }
}

function mapDispatchToProps (dispatch) {
  return {
    reloadList: (filterValues) => dispatch(reloadDocumentsListAction(filterValues)),
    changeOrderType: (value) => dispatch(change(formName, 'orderType', value))
  }
}

const connectedDocumentFilters = connect(mapStateToProps, mapDispatchToProps)(DocumentsViewFilters)

export default reduxForm({
  form: formName,
  destroyOnUnmount: false
})(connectedDocumentFilters)
