import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Icon from 'shared/components/Icon'
import Button from 'shared/components/Button/Button'

import './styles.scss?global'

const InProgress = ({ t, closeHandler, description, buttonText, customHeader, iconName }) =>
  <div className='in-progress'>
    <Icon type={iconName || 'meditator-balls'} />
    {customHeader
      ? <h2>{customHeader}</h2>
      : <h1>{t('wait').s}<span>{t('time').s}</span></h1>
    }
    <h3>{description || ''}</h3>
    <Button view={'transparent-red'} onClick={closeHandler}>
      {buttonText}
    </Button>
  </div>

InProgress.propTypes = {
  t: PropTypes.func,
  closeHandler: PropTypes.func,
  description: PropTypes.string,
  buttonText: PropTypes.string,
  customHeader: PropTypes.string,
  iconName: PropTypes.string
}

function mapStateToProps (state) {
  return {
    t: state.i18n.get('app', 'views', 'MarketPlace', 'InProgress')
  }
}

export default connect(mapStateToProps)(InProgress)
