import './SelectableListItem.scss?global'

import PropTypes from 'prop-types'

import React, { Component } from 'react'

// Components
import Checkbox from 'shared/components/Checkbox/Checkbox'

// Helpers
import getGreyLabelOptions from 'GreyLabel/helpers/greyLabel'

export default class SelectableListItem extends Component {
  static propTypes = {
    children: PropTypes.node,
    data: PropTypes.object,
    onChange: PropTypes.func,
    id: PropTypes.number
  };

  constructor (props) {
    super(props)
    this.changeHandler = this.changeHandler.bind(this)
  }

  componentWillMount () {
    this.setState({checked: false})
  }

  changeHandler () {
    const {onChange, data} = this.props
    this.setState({checked: !this.state.checked}, function () {
      onChange(this.state.checked, data)
    })
  }

  componentWillReceiveProps (props) {
    const {data: {id}} = props
    const currentId = props.id
    if (currentId) this.setState({checked: currentId === id})
  }

  render () {
    const {children} = this.props
    const { whiteLabel } = getGreyLabelOptions()
    const selectableListItemClass = whiteLabel
      ? `selectable-list-item selectable-list-item__${whiteLabel}`
      : 'selectable-list-item'

    return (
      <div className={selectableListItemClass} onClick={this.changeHandler}>
        <div>
          <Checkbox value={this.state.checked} />
        </div>
        <div>{children}</div>
      </div>
    )
  }
}
