import React from 'react'
import PropTypes from 'prop-types'

import IpadNavigation from 'Navigation/IpadNavigation'
import More from 'More/views/MoreView/containers/Smartphones'
import { connectedKivraOnboarding as KivraOnboarding } from './Smartphones'

const KivraOnboardingView = ({ navBarTitle, location }) =>
  <IpadNavigation title={navBarTitle} location={location} withoutMaxWidth>
    <More notRightIcon isIpad />
    <KivraOnboarding />
  </IpadNavigation>

KivraOnboardingView.propTypes = {
  navBarTitle: PropTypes.string,
  location: PropTypes.object.isRequired
}

export default KivraOnboardingView
