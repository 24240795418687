
import React from 'react'
import axios from 'api-client-connector'
import { SubmissionError } from 'redux-form'
import { fetch2 as fetch } from 'shared/helpers/fetch'
import { bind } from 'redux-effects'
import { camelizeKeys, decamelizeKeys } from 'humps'
import {omitBy, isNil} from 'lodash'
import { routerActions } from 'react-router-redux'

import {
  COLLECTOR_VALIDATE, COLLECTOR_ONBOARDING, COLLECTOR_DIRECT_PAYMENT,
  COLLECTOR_APPLICATIONS, COLLECTOR_FACTORING, COLLECTOR_FACTORING_CREDIT
} from '../constants/api'

import { showSlowNavbarSpinnerAction, hideNavbarSpinnerAction } from 'shared/actions/navbarSpinner'
import { collectorFieldsToStore, collectorOnboardingStatus } from 'MarketPlace/shared/actions'

import { invoiceGetAction } from 'Invoices/CustomerInvoices/actions/invoiceCreate'
import {
  showElementInMessageBoxAction,
  closeMessageBoxAction,
  showMessageBoxWithParamsAction
} from 'MessageBox/actions/messageBox'

import { updateNotificationBadgesAction } from 'Notifications/actions/notificationBadges'

import InProgress from 'MarketPlace/shared/components/InProgress'

import { openCollectorPortal } from 'MarketPlace/Collector/helpers'
import {
  updateUserSettings,
  updateCurrentCompanyInfo,
  updateInvoiceSettings
} from 'MarketPlace/shared/helpers/updateSettings'

export function getValidatedFields () {
  return async (dispatch) => {
    dispatch(showSlowNavbarSpinnerAction())
    const response = await getValidatedFieldsRequest()
    const { initialValues, currentErrors } = processFields(response.data)
    dispatch(collectorFieldsToStore({ initialValues, currentErrors }))
    dispatch(hideNavbarSpinnerAction())

    return { initialValues, currentErrors }
  }
}

function getValidatedFieldsRequest () {
  return axios.post(COLLECTOR_VALIDATE, null)
}

function processFields (fields) {
  const fieldsDetails = camelizeKeys(fields)
    .map(({fieldData, fieldName}) => ({
      name: fieldName === 'bank_account_number' ? 'account_number' : fieldName,
      value: fieldData.value,
      errors: fieldData.errors
    }))

  const valueReducer = (result, field) => (camelizeKeys({ ...result, [field.name]: field.value }))
  const initialValues = fieldsDetails.reduce(valueReducer, {})
  const errorsReducer = (result, field) => (camelizeKeys({ ...result, [field.name]: field.errors.length ? field.errors : null }))
  const currentErrors = omitBy(fieldsDetails.reduce(errorsReducer, {}), isNil)

  return { initialValues, currentErrors }
}

export function sendOnboarding (settings) {
  return async (dispatch) => {
    dispatch(showSlowNavbarSpinnerAction())
    const { currentErrors } = await dispatch(updateSettingsAction(settings))

    if (!Object.keys(currentErrors).length) await dispatch(processOnboarding())
    dispatch(hideNavbarSpinnerAction())

    if (Object.keys(currentErrors).length) throw new SubmissionError(currentErrors)
  }
}

export function updateSettingsAction (settings) {
  return async (dispatch, getState) => {
    dispatch(showSlowNavbarSpinnerAction())

    let validationResult
    let errors = {}
    // have to make requests one by one to collect all errors
    // since Promise.all fails after first error
    try {
      const userSettingsResponse = await updateUserSettings(settings)
      const { data: { errors: userSettingsErrors } } = userSettingsResponse

      if (userSettingsErrors && Object.keys(userSettingsErrors).length) errors = userSettingsErrors
    } catch (e) {
      dispatch(processError(e))
    }

    try {
      await updateCurrentCompanyInfo(settings)
    } catch (e) {
      dispatch(processError(e))
      errors = {
        ...errors,
        ...camelizeKeys(e.response.data.errors)
      }
    }

    try {
      const invoiceSettings = getState().invoiceSettings
      await updateInvoiceSettings(settings, invoiceSettings)
      if (Object.keys(errors).length) return { currentErrors: camelizeKeys(errors) }
    } catch (e) {
      dispatch(processError(e))
      errors = {
        ...errors,
        ...e.response.data.errors_stack
      }
      if (Object.keys(errors).length) return { currentErrors: camelizeKeys(errors) }
    }

    validationResult = await dispatch(getValidatedFields())
    dispatch(hideNavbarSpinnerAction())
    return validationResult
  }
}

export function processOnboarding () {
  return async (dispatch, getState) => {
    try {
      const closeHandler = () => {
        dispatch([
          closeMessageBoxAction(),
          routerActions.push('/marketplace')
        ])
        openCollectorPortal()
      }
      const response = await sendOnboardRequest()
      if (response.status === 200) {
        const t = getState().i18n.get('app', 'views', 'Collector')
        dispatch([
          showElementInMessageBoxAction(
            <InProgress
              closeHandler={closeHandler}
              description={t('InProgress', 'applicationCreated').s}
              buttonText={t('InProgress', 'collectorBank').s}
            />,
            closeHandler
          ),
          updateNotificationBadgesAction()
        ])
      }
    } catch (e) {
      if (e.response.status === 422) {
        const t = getState().i18n.get('app', 'views', 'Collector', 'errors')
        dispatch(showMessageBoxWithParamsAction(null, t('applicationAlreadySent').s))
      } else {
        const t = getState().i18n.get('app', 'shared', 'messages')
        dispatch(showMessageBoxWithParamsAction(null, t('unexpectedError', 'description').s))
      }
    }
  }
}

export function sendOnboardRequest () {
  return axios.post(COLLECTOR_ONBOARDING, null)
}

export function getApplications () {
  return bind(getApplicationsRequest(), processApplications, processApplications)
}

export function getApplicationsRequest () {
  return fetch(COLLECTOR_APPLICATIONS, null, { method: 'GET' })
}

function processApplications (response) {
  return collectorOnboardingStatus(response.status === 200 && response.value.status ? response.value.status : null)
}

export function sellInvoice (invoiceId) {
  return async (dispatch, getState) => {
    dispatch([showSlowNavbarSpinnerAction(), closeMessageBoxAction()])
    try {
      const response = await sellInvoiceRequest(invoiceId)
      if (response.status === 200) {
        const t = getState().i18n.get('app', 'views', 'Collector')
        dispatch(showElementInMessageBoxAction(
          <InProgress
            closeHandler={() => dispatch(routerActions.goBack())}
            description={t('shared', 'statusDescription').s}
            buttonText={t('InProgress', 'confirm').s}
          />,
          () => dispatch(routerActions.goBack())
        ))
      }
    } catch (e) {
      if (e.response.status === 422) {
        const t = getState().i18n.get('app', 'views', 'Collector', 'errors')
        dispatch(showMessageBoxWithParamsAction(null, t('invoiceAlreadySold').s))
      } else {
        const t = getState().i18n.get('app', 'shared', 'messages')
        dispatch(showMessageBoxWithParamsAction(null, t('unexpectedError', 'description').s))
      }
    }
    dispatch(hideNavbarSpinnerAction())
  }
}

export function sendCreditInvoice (creditInvoiceId) {
  return async (dispatch, getState) => {
    dispatch([showSlowNavbarSpinnerAction(), closeMessageBoxAction()])
    try {
      const response = await sendCreditInvoiceRequest(creditInvoiceId)
      if (response.status === 200) {
        const t = getState().i18n.get('app', 'views', 'Collector')
        dispatch(showElementInMessageBoxAction(
          <InProgress
            closeHandler={() => dispatch(routerActions.goBack())}
            description={t('shared', 'statusDescription').s}
            buttonText={t('InProgress', 'confirm').s}
          />,
          () => dispatch(routerActions.goBack())
        ))
      }
    } catch (e) {
      const t = getState().i18n.get('app', 'shared', 'messages')
      dispatch(showMessageBoxWithParamsAction(null, t('unexpectedError', 'description').s))
    }
  }
}

function sellInvoiceRequest (invoiceId) {
  return axios.post(COLLECTOR_FACTORING({}), { invoice_id: invoiceId })
}

function sendCreditInvoiceRequest (creditInvoiceId) {
  return axios.post(COLLECTOR_FACTORING_CREDIT, { invoice_id: creditInvoiceId })
}

function reloadInvoice (invoiceId) {
  return (dispatch) => {
    dispatch([invoiceGetAction(invoiceId), closeMessageBoxAction()])
  }
}

export function loadCollectorInvoiceInfo (invoiceId) {
  return axios.get(COLLECTOR_FACTORING({ invoice_uid: invoiceId }))
}

export function registerDirectPayment (paymentData, invoiceId) {
  return async (dispatch, getState) => {
    dispatch(showSlowNavbarSpinnerAction())
    try {
      const response = await registerDirectPaymentRequest(paymentData, invoiceId)
      if (response.status === 202) {
        const t = getState().i18n.get('app', 'views', 'Invoices', 'DirectPaymentView')
        dispatch(showElementInMessageBoxAction(
          <InProgress
            closeHandler={() => dispatch(reloadInvoice(invoiceId))}
            description={t('successMessage').s}
            buttonText={t('InProgress', 'confirm').s}
          />,
          () => dispatch(reloadInvoice(invoiceId))
        ))
      }
    } catch (e) {
      const t = getState().i18n.get('app', 'shared', 'messages')
      dispatch([
        showMessageBoxWithParamsAction(null, t('unexpectedError', 'description').s),
        hideNavbarSpinnerAction()
      ])
    }
  }
}

function registerDirectPaymentRequest (paymentData, invoiceId) {
  return axios.post(
    COLLECTOR_DIRECT_PAYMENT({ invoice_uid: invoiceId }),
    {...decamelizeKeys(paymentData), amount: parseFloat(paymentData.amount)}
  )
}

function processError (response) {
  console.log('error', response)
  return hideNavbarSpinnerAction()
}
