import { createReducer } from 'redux-act'
import { creditsafeValidatedFieldsToStore } from 'MarketPlace/Creditsafe/actions'

const initialState = {
  validatedFields: { initialValues: {}, currentErrors: {} },
  onboardingStatus: null,
  redirectLink: null
}

export default createReducer({
  [creditsafeValidatedFieldsToStore]: (state, payload) => ({
    ...state,
    validatedFields: payload
  })
}, initialState)
