import Smartphones from 'Todo/views/TodoCollectorInvoicesView/containers/Smartphones'
import Tablets from 'Todo/views/TodoCollectorInvoicesView/containers/Tablets'

import { getCurrentDeviceView } from 'shared/helpers/utils'

import './styles.scss?global'

const TodoCollectorView = getCurrentDeviceView(Smartphones, Tablets)

export default TodoCollectorView
