import { createReducer } from 'redux-act'
import {
  collectorFieldsToStore,
  collectorOnboardingStatus
} from 'MarketPlace/shared/actions'

const initialState = {
  collectorBankConnected: false,
  missingInfoForm: { initialValues: {}, currentErrors: {} },
  onboardingStatus: null
}

export default createReducer({
  [collectorFieldsToStore]: (state, payload) => ({
    ...state,
    missingInfoForm: payload
  }),
  [collectorOnboardingStatus]: (state, payload) => ({
    ...state,
    onboardingStatus: payload
  })
}, initialState)
