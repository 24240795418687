import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Button from 'shared/components/Button/Button'
import KivraButton from 'Integrations/Kivra/components/KivraButton'

import { ONBOARDED } from 'MarketPlace/Collector/constants/onboardingStatuses'
import { NOT_TENANT, OTHER_ERRORS } from 'Invoices/CustomerInvoices/views/InvoiceSendView/constants/kivraValidationErrorCodes'
import { ORIGINAL_INVOICE_SENT_VIA_MAIL } from 'Invoices/CustomerInvoices/views/InvoiceSendView/constants/kivraValidationInfoCodes'

import './SendInvoiceBlock.scss?global'

export default class SendInvoiceBlock extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    sendInvoice: PropTypes.func.isRequired,
    redirectToFactoring: PropTypes.func.isRequired,
    invoiceId: PropTypes.string,
    onboardingStatus: PropTypes.string,
    redirectToOnboardingStatus: PropTypes.func.isRequired,
    isPrivatePerson: PropTypes.bool.isRequired,
    originalInvoiceWasSentViaMail: PropTypes.bool,
    showSendToCollectorText: PropTypes.bool,
    kivraValidation: PropTypes.object,
    sendWithKivraHandler: PropTypes.func.isRequired,
    collectorButtonIsVisible: PropTypes.bool,
    kivraButtonIsVisible: PropTypes.bool
  }

  sendToCollectorHandler = () => {
    const {
      redirectToFactoring, sendInvoice, onboardingStatus,
      invoiceId, redirectToOnboardingStatus
    } = this.props

    if (onboardingStatus === ONBOARDED) {
      sendInvoice(invoiceId)
    } else if (onboardingStatus === null) {
      redirectToFactoring()
    } else {
      redirectToOnboardingStatus()
    }
  }

  renderKivraBlock = () => {
    const { sendWithKivraHandler, kivraValidation: { errors, valid }, t, kivraButtonIsVisible, originalInvoiceWasSentViaMail } = this.props
    const errorsToDisplay = errors.filter(e => e.code !== NOT_TENANT)
    const isButtonDisabled = () => {
      if ((!valid && errorsToDisplay.length === 0 && !originalInvoiceWasSentViaMail) || (valid && !originalInvoiceWasSentViaMail)) {
        return false
      } else {
        return true
      }
    }
    if (kivraButtonIsVisible) {
      return (
        <React.Fragment>
          <KivraButton saveButton onClick={sendWithKivraHandler} disabled={isButtonDisabled()}>
            {t('buttons', 'sendWithKivra').s}
          </KivraButton>
          {errorsToDisplay.map((error, i) =>
            <span key={i} className='send-invoice-block__kivra-reason'>
              {error.code === OTHER_ERRORS ? error.message : t('errorList', 'errorCodes', error.code.toString()).s}
            </span>
          )}
          {originalInvoiceWasSentViaMail &&
            <span className='send-invoice-block__kivra-reason'>
              {t('infoList', 'infoCodes', ORIGINAL_INVOICE_SENT_VIA_MAIL).s}
            </span>
          }
        </React.Fragment>
      )
    }
  }

  renderCollectorButton = () => {
    const { showSendToCollectorText, collectorButtonIsVisible, t } = this.props
    const collectorButtonText = showSendToCollectorText ? t('buttons', 'sendToCollector').s : t('buttons', 'sellToCollector').s
    if (collectorButtonIsVisible) {
      return (
        <Button saveButton view='primary' onClick={this.sendToCollectorHandler}>
          {collectorButtonText}
        </Button>
      )
    }
  }

  render () {
    const { t, isPrivatePerson } = this.props
    if (isPrivatePerson) return null

    return (
      <div className='send-invoice-block'>
        <div className='hr hr-margin'>
          <span>{t('orDelimeter').s}</span>
        </div>
        {this.renderCollectorButton()}
        {this.renderKivraBlock()}
      </div>
    )
  }
}
