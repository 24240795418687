import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getFormValues, destroy } from 'redux-form'
import { get, isEqual } from 'lodash'

import { getSalaries, clearSalary } from 'Salaries/actions'
import Navigation from 'Navigation/Navigation'
import ListView from 'shared/components/ListView/ListView'
import { SalariesListItem } from 'Salaries/components/ListItem/ListItem'
import Filters, { defaultFilterValues } from 'Salaries/components/Filters'

class SalariesListView extends Component {
  static propTypes = {
    clearSalary: PropTypes.func.isRequired,
    destroySalaryForm: PropTypes.func.isRequired,
    filters: PropTypes.object.isRequired,
    loadList: PropTypes.func.isRequired,
    salaries: PropTypes.array.isRequired
  }

  componentDidMount () {
    this.props.loadList(this.props.filters)
  }

  componentDidUpdate (prevProps) {
    const { filters } = this.props
    if (prevProps.filters && !isEqual(prevProps.filters, filters)) {
      this.props.loadList(filters)
    }
  }

  clearSalary = () => {
    this.props.clearSalary()
    this.props.destroySalaryForm()
  }

  render () {
    const { salaries, filters, t } = this.props
    const actions = [
      { icon: 'icon-add', path: `/salaries/new`, onClick: this.clearSalary }
    ]
    return (
      <Navigation title={t('navbar', 'title').s} backLink='/more/salary' actions={actions} forceBackLink filterBlock className='salaries-view'>
        <Filters />
        <ListView data={salaries} template={SalariesListItem} emptyListOptions={{icon: 'icon-documents', showNotFound: Boolean(get(filters, ['search', 'value', 'length']))}} />
      </Navigation>
    )
  }
}

const mapStateToProps = (state) => {
  const employees = state.userSettings.employees
  const getEmployeeName = (employeeId, employees) => {
    const employee = employees.find(e => e.id === employeeId)
    return `${employee.firstName} ${employee.lastName}`
  }
  return (
    {
      salaries: state.salaries.list.map(salary => ({
        id: salary.id,
        name: getEmployeeName(salary.employeeId, employees),
        title: salary.title,
        netSalary: salary.netSalary,
        paymentDate: salary.paymentDate
      })) || [],
      filters: getFormValues('salariesListFilters')(state) || defaultFilterValues,
      t: state.i18n.get('app', 'views', 'Salaries', 'SalariesListView')
    }
  )
}

const mapDispatchToProps = (dispatch) => ({
  loadList: (filters) => dispatch(getSalaries(filters)),
  clearSalary: () => dispatch(clearSalary()),
  destroySalaryForm: () => dispatch(destroy('salary'))
})

export default connect(mapStateToProps, mapDispatchToProps)(SalariesListView)
