import { addFileToViewAction, removeFileAction } from 'FileUpload/actions/filesToUpload'
import FileRenderer from 'FileUpload/components/FileRenderer/FileRenderer'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import PlusButton from 'shared/components/PlusButton/PlusButton'
import { UPLOADS_BASE } from 'shared/constants/Api'
import './FilesPreviewList.scss?global'
import isMobile from 'ismobilejs'
import { isFlutterApp } from 'appBridge/bridge'
import { isIpadResolution } from 'shared/helpers/utils'

class FilesPreview extends Component {
  static propTypes = {
    files: PropTypes.array,
    removeFile: PropTypes.func,
    addFileToView: PropTypes.func,
    editMode: PropTypes.bool
  }

  getFileName (name) {
    return name.split('.')[0]
  }

  render () {
    const { addFileToView, editMode, files, removeFile, t } = this.props
    if (files && files.length) {
      return (
        <div className='files-preview'>
          {!editMode && (
            <div className='hr files-preview__title'>
              <span>{t('title').s}</span>
            </div>
          )}
          <div className='files-preview__files'>
            {files.map((file, i) => {
              const mime = (file.mime || file.file_mime || '') // because of bad design
              let isPreviewable = mime.startsWith('image') || (mime === 'application/pdf' && !isMobile.android.phone)

              // TODO combine with 847c818
              // Force Flutter app to open PDF by itself and not in webview browser
              if (mime === 'application/pdf' && isFlutterApp() && !isIpadResolution()) {
                isPreviewable = false
              }

              let onClick
              if (!isPreviewable && !editMode) {
                const url = file.fullURL || `${UPLOADS_BASE}/${file.path}/${file.file_name}`
                onClick = () => {
                  window.open(url, '_blank')
                }
              } else if (!isPreviewable && editMode) {
                onClick = () => {
                }
              } else {
                onClick = () => {
                  window.location.hash = 'file-preview'
                  addFileToView(file)
                }
              }

              return (
                <div key={i} className='files-preview__files__item' onClick={onClick}>
                  <div className='files-preview__files__item__img'>
                    {isPreviewable ? (
                      <FileRenderer previewMode file={file} />
                    ) : (
                      <span className='icon-bills-full' />
                    )}
                  </div>
                  <div className='files-preview__files__item__text'>
                    <h3>{this.getFileName(file.file_name || file.name)}</h3>
                  </div>
                  {!editMode ? (
                    <div className='files-preview__files__item__arrow'>
                      <h3><span className='icon-arrow-right' /></h3>
                    </div>
                  ) : (
                    <div className='files-preview__files__item__arrow'>
                      <PlusButton type='minus' onClick={e => {
                        e.stopPropagation()
                        removeFile(file)
                      }} />
                    </div>
                  )}
                </div>
              )
            })}
          </div>
        </div>
      )
    } else {
      return null
    }
  }
}

export default connect(
  state => ({
    t: state.i18n.get('app', 'components', 'FileUpload', 'FilesPreviewList')
  }),
  dispatch => ({
    removeFile: file => dispatch(removeFileAction(file)),
    addFileToView: file => dispatch(addFileToViewAction(file))
  })
)(FilesPreview)
