import React from 'react'
import PropTypes from 'prop-types'

class Frame extends React.Component {
  passAuthInfoToFrame = () => {
    this.frame.contentWindow.postMessage(this.props.message, this.props.url)
  }

  render () {
    const { url, message, ...props } = this.props
    const isMessageAndUrlProvided = Boolean(message && url)
    return (
      <iframe
        frameBorder='0'
        src={url}
        onLoad={isMessageAndUrlProvided ? this.passAuthInfoToFrame : null}
        ref={(c) => { this.frame = c }}
        {...props}
      />
    )
  }
}

Frame.propTypes = {
  url: PropTypes.string.isRequired,
  message: PropTypes.object
}

export default Frame
