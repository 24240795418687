import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { routerActions } from 'react-router-redux'

import { blockWelcomeModal } from 'MarketPlace/shared/actions'
import Collaboration from 'MarketPlace/shared/views/CollaborationView'
import Navigation from 'Navigation/Navigation'
import { AVTAL24 } from 'MarketPlace/shared/constants/frameUrls'

import { isIOS } from 'shared/helpers/utils'

class LawCollaborationView extends Component {
  static propTypes = {
    t: PropTypes.func,
    blockWelcomeModal: PropTypes.func.isRequired,
    cancelHandler: PropTypes.func.isRequired,
    openPortalInIframe: PropTypes.func.isRequired
  }

  componentDidMount () {
    this.props.blockWelcomeModal(true)
  }

  get collaborationConfig () {
    const { t } = this.props
    return {
      message: t('lawCollaboration', 'message').s,
      partnerOffer: t('lawCollaboration', 'offers').s,
      iconName: 'avtal24'
    }
  }

  openPortal = () => {
    const { openPortalInIframe } = this.props
    // All our iframes got a bug in iOS 13 https://stackoverflow.com/questions/61046560/site-dont-reset-scale-after-change-viewport-on-ios-13
    if (isIOS()) {
      window.open(AVTAL24, '_blank')
    } else {
      openPortalInIframe()
    }
  }

  render () {
    return (
      <Collaboration
        {...this.collaborationConfig}
        {...this.props}
        startHandler={this.openPortal}
      />
    )
  }
}

const LawCollaborationWithNavigation = (props) => {
  const navBarTitle = props.t('lawCollaboration', 'title').s

  return (
    <div className='law-collaboration-wrapper'>
      <Navigation hideFooter onClickBack={props.cancelHandler} title={navBarTitle} notBottomSpace >
        <LawCollaborationView {...props} />
      </Navigation>
    </div>
  )
}

LawCollaborationWithNavigation.propTypes = {
  cancelHandler: PropTypes.func.isRequired
}

const mapStateToProps = (state) => {
  return {
    t: state.i18n.get('app', 'views', 'MarketPlace')
  }
}

function mapDispatchToProps (dispatch) {
  return {
    blockWelcomeModal: (...args) => dispatch(blockWelcomeModal(...args)),
    cancelHandler: () => dispatch(routerActions.goBack()),
    openPortalInIframe: () => dispatch(routerActions.push('/marketplace/law/portal'))
  }
}

export const LawCollaboration = connect(mapStateToProps, mapDispatchToProps)(LawCollaborationView)
export default connect(mapStateToProps, mapDispatchToProps)(LawCollaborationWithNavigation)
