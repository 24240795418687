import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getFormValues } from 'redux-form'
import { get, isEqual } from 'lodash'
import { getCurrentClientCompany } from 'api-client-connector/utils'

import { loadSuppliersList, clearSuppliersListAction } from '../../../actions/suppliers'

import Navigation from 'Navigation/Navigation'
import Filters, { defaultFilterValues } from 'Counterparties/Suppliers/components/Filters/Filters'
import { SuppliersListItem } from 'Counterparties/Suppliers/components/SuppliersListItem/SuppliersListItem'
import ListView from 'shared/components/ListView/ListView'

const actions = [
  {icon: 'icon-add', path: '/suppliers/new'}
]

export class SuppliersView extends Component {
  static propTypes = {
    loadList: PropTypes.func,
    cleanList: PropTypes.func,
    hasNextPage: PropTypes.bool,
    lastOffset: PropTypes.number,
    suppliers: PropTypes.arrayOf(PropTypes.object),
    filters: PropTypes.object,
    t: PropTypes.func
  };

  constructor (props) {
    super(props)
    this.extendList = this.extendList.bind(this)
  }

  extendList () {
    const { hasNextPage, lastOffset, filters } = this.props
    if (hasNextPage) {
      this.props.loadList(filters, lastOffset + 20)
    }
  }

  componentDidMount () {
    const { filters } = this.props
    this.props.loadList(filters, 0)
  }

  componentDidUpdate (prevProps) {
    const { filters } = this.props
    if (prevProps.filters && !isEqual(prevProps.filters, filters)) {
      this.props.loadList(filters, 0)
    }
  }

  componentWillUnmount () {
    this.props.cleanList()
  }

  render () {
    const {t, suppliers, filters} = this.props
    return (
      <Navigation title={t('navbar', 'title').s} actions={actions} backLink='/more/supplier-invoices' forceBackLink filterBlock infinityScrollHandler={this.extendList}>
        <Filters placeholder={t('filters', 'search').s} canBeHidden={!suppliers.length} />
        <ListView data={suppliers} template={SuppliersListItem} emptyListOptions={{icon: 'icon-customers', showNotFound: Boolean(get(filters, ['search', 'value', 'length']))}} />
      </Navigation>
    )
  }
}

function mapStateToProps (state) {
  return {
    suppliers: state.suppliers.listData,
    lastOffset: state.suppliers.lastOffset,
    hasNextPage: state.suppliers.hasNextPage,
    filters: getFormValues('suppliersListFilters')(state) || defaultFilterValues,
    t: state.i18n.get('app', 'views', 'Suppliers', 'SuppliersView')
  }
}

function mapDispatchToProps (dispatch) {
  const companyId = getCurrentClientCompany()
  return {
    loadList: (filters, offset) => { dispatch(loadSuppliersList(filters, offset, companyId)) },
    cleanList: () => { dispatch(clearSuppliersListAction()) }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SuppliersView)
