import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'

// Components
import ConfirmationMessageBox from 'MessageBox/components/ConfirmationMessageBox/ConfirmationMessageBox'
import Navigation from 'Navigation/Navigation'
import SupplierForm from 'Counterparties/Suppliers/components/SupplierForm'

// Actions
import {
  saveSupplier,
  pushToExpenseInvoice,
  deleteSupplier,
  loadSupplierInfo,
  clearSupplierInfoAction
} from '../../../actions/suppliers'

// Helpers
import { getCurrentClientCompany } from 'api-client-connector/utils'

// HOCs
import { withCounterpartyMethods } from 'Counterparties/shared/HOCs/withCounterpartyMethods'

export class Supplier extends Component {
  static propTypes = {
    deleteCounterpartyHandler: PropTypes.func,
    provideController: PropTypes.func,
    t: PropTypes.func
  }

  render () {
    const {t, deleteCounterpartyHandler, provideController} = this.props
    return (
      <div className='f-column'>
        <SupplierForm {...this.props} />
        <ConfirmationMessageBox
          title={t('modal', 'title').s}
          onYesClick={deleteCounterpartyHandler}
          provideController={provideController} />
      </div>
    )
  }
}

const WrappedSupplier = withCounterpartyMethods(Supplier)

class SupplierWithNavigation extends Component {
  static propTypes = {
    counterparty: PropTypes.object,
    t: PropTypes.func
  }
  render () {
    const {counterparty, t} = this.props
    const actions = counterparty.id ? [{icon: 'icon-trash', callback: this.refs.supplier.showModalBox}] : null
    return (
      <Navigation title={counterparty.name || t('navbar', 'title').s} hideFooter actions={actions} className='f-column'>
        <WrappedSupplier {...this.props} ref='supplier' />
      </Navigation>
    )
  }
}

function mapStateToProps (state) {
  return {
    t: state.i18n.get('app', 'views', 'Suppliers', 'SupplierView'),
    counterparty: state.suppliers.supplier,
    initialValues: state.suppliers.supplier
  }
}

function mapDispatchToProps (dispatch, ownProps) {
  const companyId = Number(getCurrentClientCompany())
  let save
  if (ownProps.location.state === 'supplier-invoice') {
    save = (supplier) => { dispatch(pushToExpenseInvoice(supplier, companyId)) }
  } else {
    save = (supplier, supplierId) => { dispatch(saveSupplier(supplier, companyId, supplierId)) }
  }

  return {
    loadInfo: (id) => { dispatch(loadSupplierInfo(id, companyId)) },
    save,
    clearState: () => { dispatch(clearSupplierInfoAction()) },
    deleteCounterparty: (id) => { dispatch(deleteSupplier(id, companyId)) }
  }
}

export const SupplierView = connect(mapStateToProps, mapDispatchToProps, null, { withRef: true })(WrappedSupplier)
export default connect(mapStateToProps, mapDispatchToProps)(SupplierWithNavigation)
