import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styles from './index.scss'
import Frame from 'MarketPlace/shared/components/Frame'
import Navigation from 'Navigation/NavigationForIframe'

import { AVTAL24 } from 'MarketPlace/shared/constants/frameUrls'

export default class LawFrameView extends Component {
  static propTypes = {
    t: PropTypes.func,
    blockWelcomeModal: PropTypes.func.isRequired,
    selectedCategory: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.func
    ])
  }

  componentDidMount () {
    this.props.blockWelcomeModal(true)
  }

  render () {
    const { t, selectedCategory } = this.props

    return (
      <Navigation title={t('categories', selectedCategory).s} backLink='/marketplace'>
        <Frame
          className={styles.frame}
          url={AVTAL24}
        />
      </Navigation>
    )
  }
}
