import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { reverse } from 'lodash'
import { connect } from 'react-redux'
import { reduxForm, Field } from 'redux-form'
import './AmountBlock.scss?global'

// Components
import Input from 'shared/components/FormInput'
import DropDown from 'shared/components/FormDropdown'

// Helpers
import { onAmountChange, onVatAmountChange, onVatChange } from 'Events/shared/helpers/calculateAmount'

class AmountBlock extends Component {
  static propTypes = {
    eventType: PropTypes.string,
    formValues: PropTypes.object,

    vatPercents: PropTypes.arrayOf(PropTypes.object),
    currencies: PropTypes.arrayOf(PropTypes.object),
    t: PropTypes.func
  }

  constructor (props) {
    super(props)
    this.onAmountChange = this.onAmountChange.bind(this)
    this.onVatAmountChange = this.onVatAmountChange.bind(this)
    this.onVatChange = this.onVatChange.bind(this)
  }

  onAmountChange (e) {
    onAmountChange(this.props, e)
  }

  onVatAmountChange (e) {
    onVatAmountChange(this.props, e)
  }

  onVatChange (e) {
    onVatChange(this.props, e)
  }

  get blockForSingleAmount () {
    const {
      formValues: { amountWithVat, vatAmount, vat },
      vatPercents,
      eventType,
      t
    } = this.props

    return (
      <div className='receipt-amount'>
        <div className='hr'><span>{t('props', 'amount').s}</span></div>
        <div className='form receipt-amount__amounts'>
          <div className='form__item form__input-select form__input-select__wider-select'>
            <Field
              name='amountWithVat'
              component={Input}
              onChange={this.onAmountChange}
              type='float'
              placeholder={t('props', 'amountWithVat').s}
            />
            <Field
              name='currencyId'
              component={DropDown}
              big
              options={this.props.currencies || []}
              hint={t('props', 'currency').s}
            />
          </div>
          <div className='form__item form__input-select form__input-select__wider-select'>
            <Field
              name='vatAmount'
              component={Input}
              disabled={vat === '0' || eventType === 'revenue'}
              onChange={this.onVatAmountChange}
              type='float'
              placeholder={t('props', 'vatAmount').s} error={Number(vatAmount) > Number(amountWithVat)}
            />
            <Field
              name='vat'
              component={DropDown}
              big
              onChange={this.onVatChange}
              disabled={eventType === 'revenue'}
              options={reverse(vatPercents.slice()).concat([{label: t('props', 'customVat').s, value: 'custom'}])}
              hint={t('props', 'vat').s}
            />
          </div>
        </div>
      </div>
    )
  }

  get blockForSplitAmount () {
    const { t } = this.props

    return (
      <div className='receipt-amount'>
        <div className='hr'><span>{t('props', 'amount').s}</span></div>
        <div className='form receipt-amount__amounts'>
          <div className='form__item form__input-select form__input-select__wider-select'>
            <Field name='amountWithVat' component={Input} type='float' placeholder={t('props', 'amountWithVat').s} />
            <Field name='currencyId' component={DropDown} big options={this.props.currencies || []} hint={t('props', 'currency').s} />
          </div>
        </div>
      </div>
    )
  }

  render () {
    const { formValues: { eventRows } } = this.props
    if (eventRows.length) {
      return this.blockForSplitAmount
    } else {
      return this.blockForSingleAmount
    }
  }
}

function mapStateToProps (state) {
  return {
    vatPercents: (state.userSettings && state.userSettings.vatPercents) || [],
    currencies: (state.userSettings && state.userSettings.currencies) || [],
    t: state.i18n.get('app', 'views', 'ExpenseView'),
    initialValues: state.events.newEvent
  }
}

function validate (values) {
  let errors = {}

  if (!Number(values.amountWithVat)) {
    errors.amountWithVat = true
  }

  return errors
}

export default connect(mapStateToProps)(reduxForm({
  form: 'receipt-form',
  validate,
  destroyOnUnmount: false
})(AmountBlock))
