import { postMessageToAndroid, postMessageToIOS, isIOSBridge, isAndroidBridge } from 'appBridge/bridge'
import semverCompare from 'semver-compare'
import { getAuthHeaders, getCurrentClientCompany } from 'api-client-connector/utils'

export function removeTokenFromApp () {
  postMessageToIOS('remove_token')
  postMessageToAndroid('remove_token')
}

export function saveTokenForApp (appInfo) {
  const currentClientCompany = getCurrentClientCompany()
  const headersForRequests = {
    ...getAuthHeaders(),
    'x-client-id': currentClientCompany
  }
  const encodedHeaders = btoa(JSON.stringify(headersForRequests))

  if (isIOSBridge()) {
    const build = appInfo.wrapperBuild

    if (Number(build) > 57) {
      postMessageToIOS(`save_token_${encodedHeaders}`)
    } else {
      postMessageToIOS(`save_token_${encodedHeaders}_${currentClientCompany}`)
    }
  } else if (isAndroidBridge()) {
    const version = appInfo.wrapperVersion

    if (version && semverCompare(version, '1.2.16') > 0) {
      postMessageToAndroid(`save_token_${encodedHeaders}`)
    } else {
      postMessageToAndroid(`save_token_${encodedHeaders}_${currentClientCompany}`)
    }
  }
}

export function saveLanguageForApp (lang) {
  postMessageToIOS(`language_${lang}`)
}

export function updateBadgeCounter (number) {
  postMessageToIOS(`badge_${number}`)
}

export function shareFile (url) {
  postMessageToIOS(`share_file_${url}`)
  postMessageToAndroid(`share_file_${url}`)
}
