import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Promise from 'bluebird'
import { omit } from 'lodash'
import Mimoza from 'mimoza'

export default class FileInput extends Component {
  static propTypes = {
    onFilesSelected: PropTypes.func
  };

  constructor (props) {
    super(props)
    this.onChange = this.onChange.bind(this)
    this.transformName = this.transformName.bind(this)
  }

  transformName (name) {
    const extension = name.match(/(?:\.([^.]+))?$/)[1]
    if (extension === 'jpeg' || extension === 'jpg') {
      // iOS always returns image.jpg file
      // small workaround
      return Number(new Date()) + `.${extension}`
    } else {
      return name
    }
  }

  onChange (e) {
    const {onFilesSelected} = this.props
    const fileInputRef = this.refs.input

    if (window.File && window.FileReader && window.FileList) {
      const files = e.target.files
      var promises = []
      for (var i = 0; i < files.length; i++) {
        const file = files[i]
        promises.push(new Promise(resolve => {
          const reader = new FileReader()
          reader.onloadend = () => {
            resolve({
              name: this.transformName(file.name),
              mime: file.type || Mimoza.getMimeType(file.name),
              fileBase64: reader.result,
              file: file
            })
          }
          reader.readAsDataURL(file)
        }))
      }
      Promise.all(promises).then(values => {
        onFilesSelected(values)
      }).then(() => { fileInputRef.value = '' })
    } else return false
  }

  render () {
    const props = omit(this.props, ['onFilesSelected'])
    return (
      <input ref='input'
        type='file' multiple
        onChange={this.onChange} {...props}
      />
    )
  }
}
