import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'

// Components
import Navigation from 'Navigation/Navigation'
import TodoPDFReports from '../components/TodoUnreadPDFReports'

// Actions
import { loadFinancialReportsListAction } from 'Reports/actions/reports'

class TodoReportsView extends Component {
  static propTypes = {
    t: PropTypes.func,
    reportsList: PropTypes.arrayOf(PropTypes.object),
    loadList: PropTypes.func,
    hasNextPage: PropTypes.bool,
    lastPageLoaded: PropTypes.number
  }

  extendList = () => {
    const {hasNextPage, lastPageLoaded, loadList, reportsList} = this.props
    if (hasNextPage) {
      loadList(reportsList, lastPageLoaded + 1, { showUnread: true })
    }
  }

  render () {
    const { t } = this.props
    return (
      <Navigation title={t('navbar', 'title').s} infinityScrollHandler={this.extendList}>
        <TodoPDFReports {...this.props} />
      </Navigation>
    )
  }
}

function mapStateToProps (state) {
  return {
    t: state.i18n.get('app', 'views', 'Todo', 'TodoPDFReports'),
    reportsList: state.reports.listData,
    hasNextPage: state.reports.hasNextPage,
    lastPageLoaded: state.reports.lastPageLoaded
  }
}

function mapDispatchToProps (dispatch) {
  return {
    loadList: (...params) => { dispatch(loadFinancialReportsListAction(...params)) }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TodoReportsView)
