import React from 'react'
import { Route } from 'react-router'

import {requireAuthentication} from 'User/Auth/higherOrderComponents/AuthComponent'

import DocumentsView from 'Documents/views/DocumentsView/DocumentsView'
import DocumentView from 'Documents/views/DocumentView/DocumentView'
import DocumentShowView from 'Documents/views/DocumentShowView/DocumentShowView'

// Form-cleaning components
import createClearFormComponent from 'shared/factories/ClearForm'
import { clearReducerAction } from 'Documents/actions/document'
const ClearDocumentForm = createClearFormComponent(clearReducerAction)

const documentsRoutes = (
  <Route>
    <Route path='/documents' component={requireAuthentication(DocumentsView)} />
    <Route component={ClearDocumentForm} >
      <Route path='/documents/new/other' component={requireAuthentication(DocumentView)} />
      <Route path='/documents/new/statement' component={requireAuthentication(DocumentView)} />
      <Route path='/documents/:companyId/:documentId/edit' component={requireAuthentication(DocumentView)} />
    </Route>
    <Route path='/documents/:companyId/:documentId' component={requireAuthentication(DocumentShowView)} />
  </Route>
)

export default documentsRoutes
