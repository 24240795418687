import { createAction } from 'redux-act'
import { bind } from 'redux-effects'
import { camelizeKeys } from 'humps'

import { MARKETPLACE } from '../constants/Api'
import { fetch } from 'shared/helpers/fetch'

export const selectCategory = createAction('Select marketplace category')
export const addSentRequest = createAction('Add sent request to list')

export const listOfRequests = createAction('Get list of all requests which user created')

export const sendEmail = createAction('Send email for chosen category')
export const changeWelcomeModalState = createAction('Change welcome modal state')

export function getListOfRequestsAction () {
  return [
    bind(getListOfRequests(), afterSuccessFetch, processError)
  ]
}

export function sendEmailAction (category) {
  return bind(sendEmailRequest(category), () => addSentRequest({category, status: true}), processError)
}

function getListOfRequests () {
  return fetch(MARKETPLACE, null, {
    method: 'GET'
  })
}

function afterSuccessFetch (response) {
  const marketPlaceRequests = camelizeKeys(response.value)
  return (dispatch) => { return dispatch(listOfRequests(marketPlaceRequests)) }
}

function sendEmailRequest (category) {
  return fetch(MARKETPLACE, null, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: { category }
  })
}

function processError (response) {
  console.log('error', response)
}
