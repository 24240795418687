import { NOTIFICATIONS_UPDATE } from 'Notifications/constants/ActionTypes'

const initialState = {}

export default function (state = initialState, action) {
  switch (action.type) {
    case NOTIFICATIONS_UPDATE:
      return {
        ...state,
        ...action.payload
      }
    default:
      return state
  }
}
