import React from 'react'
import { Route } from 'react-router'

import { requireAuthentication } from 'User/Auth/higherOrderComponents/AuthComponent'

import ConversationsView from 'Messages/views/ConversationsView/ConversationsView'
import ConversationView from 'Messages/views/ConversationView/ConversationView'
import SelectRecipientView from 'Messages/views/SelectRecipientView/SelectRecipientView'

const messageRoutes = (
  <Route>
    <Route path='/messages' component={requireAuthentication(ConversationsView)} />
    <Route path='/messages/select_recipient' component={requireAuthentication(SelectRecipientView)} />
    <Route path='/messages/conversation/:conversationId' component={requireAuthentication(ConversationView)} />
  </Route>
)

export default messageRoutes
