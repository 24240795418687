/*eslint-disable */
import React from 'react'

import getGreyLabelOptions from 'GreyLabel/helpers/greyLabel'

export default class Terms extends React.Component {
  get mrShoeboxTerms () {
    return (
      <div className='registration-terms'>
        <div>
          <div>
            <p>
              <b>Allmänna villkor ”Entreprenören”</b></p>
            <p>Shoebox
              Solutions AB:s, 556859-7859, Kungstensgatan 60, 113 29 Stockholm
              (”<b>Mr ShoeBox</b>”), ambition med dessa '<b>Villkor</b>'
              är att på ett bra och rättvist sätt säkerställa våra egna
              intressen och våra intressenters möjligheter till en positiv
              upplevelse av de tjänster som vi erbjuder.
            </p>
            <p>Mr
              ShoeBox tillhandahåller en kommunikations- och arkiveringstjänst
              som möjliggör direkt kommunikation mellan en näringsidkare
              (”<b>Entreprenören</b>”) och den som utför bokföringstjänster
              på uppdrag av Entreprenören (”<b>Administratören</b>”) genom
              att tillåta dem att skicka och ta emot information som ligger till
              grund för Entreprenörens bokföring, samt rapporter för
              ekonomiska beslut ('<b>Tjänsten</b>'). Tjänsten
              möjliggör arkivering av det underlag som ligger till grund för
              bokföringen och andra dokument relaterade till Entreprenörens
              verksamhet. Genom Tjänsten möjliggörs även transparens mot
              intressenter såsom Skatteverket, Bolagsverket etc.
            </p>
            <p>Dessa
              Villkor gäller mellan Entreprenören och Mr ShoeBox för
              Entreprenörens användning av Tjänsten.
            </p>
            <p>Avtal
              tecknas separat mellan Entreprenören och dennes Administratör samt
            </p>
            <p>Tjänsten
              regleras av tillämpliga lagar, förordningar och
              myndighetsförskrifter som gäller för kommunikation, arkivering
              och lagring av information. Mr ShoeBox tar inte ansvar för att
              Tjänsten uppfyller de nationella och lokala krav som gäller på
              den plats där Entreprenören är verksam. Det är ytterst
              Entreprenörens ansvar att tillse att Entreprenören följer alla
              tillämpliga lagar och regler för sin verksamhet.
            </p>
            <p>Entreprenören
              godkänner att information och upplysningar som krävs enligt lag
              och annan information om dennes rättigheter och skyldigheter
              kan sändas till Entreprenören elektroniskt och att Entreprenörens
              elektroniska godkännande
              av dessa Villkor är juridiskt bindande.
            </p>
            <h1>TJÄNSTEN</h1>
            <ol>
              <li><h1>Allmänt</h1>
                <ol>
                  <li><h2>Med en ”Händelse” avses i dessa
                    Villkor en av Entreprenören registrerad affärshändelse.
                    Händelser som fått ett verifikationsnummer i systemet och
                    som ligger till grund för företagets resultat och balansräkning
                    betraktas som en Händelse. Sparade projekt, övriga dokument så
                    som registreringsbevis, upplysningar från Skatteverket m.m.
                    registreras inte som Händelser.
                  </h2>
                  </li>
                  <li><h2>Med Tjänsten kan Entreprenören ta emot
                    och skicka information i form av Händelser och underlag,
                    rapporter, kommentarer och meddelanden som ligger till grund för
                    Entreprenörens bokföring och rapporter. Kommunikationen sker
                    genom mobiltelefon, surfplatta eller dator och arkiveringen av
                    allt bildmaterial sker på av Mr ShoeBox angivna servrar.
                  </h2>
                  </li>
                  <li><h2>Mr ShoeBox kan när som helst ändra
                    Tjänstens innehåll eller funktioner efter eget gottfinnande i
                    syfte att förbättra Tjänsten eller om det fordras p.g.a.
                    lagändringar, myndigheters ändrade föreskrifter eller liknande.
                    Mr ShoeBox kommer i möjligaste mån publicera information om
                    eventuella förändringar i Tjänsten på Webbsidan (enligt
                    definition i avsnitt 2.1) eller vid inloggning i Tjänsten.
                  </h2>
                  </li>
                </ol>
              </li>
            </ol>
            <ol start={2}>
              <li><h1>Kontot och anslutning till Tjänsten</h1>
                <ol>
                  <li>
                    <h2>Entreprenören
                    får tillgång till Tjänsten via Mr ShoeBox mobilapplikation som
                    finns tillgänglig i AppStore och GooglePlay
                    (”<b>Applikationen</b>”) och webbsidan www.mrshoebox.com/admin
                    (”<b>Webbsidan</b>”). Den del av Tjänsten som möjliggör
                    kommunikation mellan Entreprenören och en Administratör kan
                    endast aktiveras och användas genom att Administratören
                    </h2>
                  </li>
                  <li><h2>Entreprenören aktiverar Tjänsten genom
                    att registrera ett konto via Webbsidan eller i Applikationen
                    (”<b>Kontot</b>”) och ange sådana nödvändiga uppgifter om
                    Entreprenören som krävs för registrering, samt lösenord och
                    användarnamn (”<b>Kontouppgifter</b>”). Vid registreringen av
                    Kontot är Entreprenören skyldig att ange fullständiga och
                    korrekta Kontouppgifter. Entreprenören är skyldig att uppdatera
                    Kontouppgifterna utan dröjsmål om dessa skulle komma att ändras.
                    Mr ShoeBox kan inte hållas ansvarig för brott mot dessa Villkor
                    som orsakas av att Entreprenören inte uppdaterat sina
                  </h2></li>
                  <li><h2>Genom att skapa ett Konto och godkänna
                    Villkoren försäkrar Entreprenören att denne läst igenom och
                    godkänt Villkoren samt att Entreprenören kommer följa dessa.
                    Entreprenörens godkännande utgör dock endast en förfrågan om
                    att ansluta sig till Tjänsten och är villkorat av att Mr ShoeBox
                    godkänner Entreprenören enl</h2></li>
                  <li><h2>Vid misstanke om att Entreprenören inte
                    är innehavare av Kontot äger Mr ShoeBox rätt att ställa frågor
                    och Entreprenören förbinder sig att i nödvändig utsträckning
                    bistå med sådan information som krävs för att Mr ShoeBox ska
                    kunna verifiera E</h2>
                  </li>
                  <li><h2>Mr ShoeBox
                    avgör efter eget gottfinnande om Entreprenören ska beredas
                    tillgång till Tjänsten. När Mr ShoeBox beslutat ge
                    Entreprenören tillgång till Tjänsten och bekräftar
                    Entreprenörens anslutning till Tjänsten via e-post, träder
                    dessa Villkor ikraft mellan Mr </h2>
                  </li>
                </ol>
              </li>
              <li><h1>Tekniska förutsättningar</h1>
                <ol>
                  <li><h2>De vid var tid gällande tekniska krav som
                    Tjänsten förutsätter för att kunna fungera finns angivna på
                    Webbsidan. Det är Entreprenörens ansvar att tillse att de
                    datorer, mobiltelefoner och surfplattor som används för att
                    använda Tjänsten vid var tid uppfyller dessa tekniska krav, Mr
                    ShoeBox tar inte ansvar för att vare sig Tjänsten eller dess
                    innehåll uppfyller dessa Villkor om Entreprenören använder
                    datorer, surfplattor eller mobiltelefoner som kringgått tekniska
                    skyd</h2>
                  </li>
                  <li><h2>Entreprenörens tillgång till Tjänsten
                    via dator, mobiltelefon och surfplatta är beroende av bl.a.
                    internet och andra tjänster som erhålls av tredje part. Dessa
                    tredje parter kan komma att debitera Entreprenören avgifter för
                    att få tillgång till Tjänsten. Entreprenören är ansvarig för
                    användandet och betalningen av dessa. Mr ShoeBox ansvarar inte på
                    något sätt för de avtal Entreprenören väljer att ingå för
                    </h2>
                  </li>
                </ol>
              </li>
              <li><h1>Förutsättningar för och begränsningar i
                an</h1>
                <ol>
                  <li><h2>Genom att godkänna Villkoren försäkrar
                    Entreprenören: (1) att Entreprenören och eventuella personer som
                    använder Tjänsten för Entreprenörens räkning är minst 18 år,
                    (2) att Entreprenören företräder sig själv och inte
                    representerar någon annan, och (3) att betalningar för Tjänsten
                    är förenliga med tillämpliga lagar och föreskrifter.
                  </h2>
                  </li>
                  <li><h2>Tjänsten får inte användas för
                    verksamhet som avser eller kan kopplas till (1) pornografi eller
                    narkotika, (2) prostitution eller sexdating (3) vapen, (4) spel,
                    vadslagning, lotterier, bingo och andra speltjänster, om dessa
                    kan antas vara förbjudna enligt lag eller i övrigt är tveksamma
                    från ett risk- eller renomméperspektiv. Entreprenören får inte
                    spara eller ladda upp Händelser eller kommunikation som avser
                    punkterna (1)-(5) ovan. Mr ShoeBox äger rätt att avgöra om
                    Entreprenörens användning av Tjänsten ska anses hänförlig
                    till någon av ovan angivna varor eller tjänster och därmed
                    bryter mot dessa Villkor.
                  </h2>
                  </li>
                  <li><h2>Entreprenören
                    får kostnadsfritt använda lagringsplatsen ”Dokument”
                    förutsatt att enskilda datafiler och/eller datafilerna
                    tillsammans inte överstiger den datamängd
                    som vid var tid finns angiven på Webbsidan. Om det finns önskemål
                    om en större lagringsmängd än den kostnadsfria kan Mr ShoeBox
                    debitera för detta enligt de vid var tid gällande priserna som
                    finns på www.mrshoebox.com/priser. Entreprenören kan fritt
                    radera och exportera informationen p</h2></li>
                </ol>
              </li>
              <li><h1>Entreprenörens användning av Tjänsten</h1>
                <ol>
                  <li><h2>Entreprenören förbinder sig att följa
                    Mr ShoeBox vid var tid gällande rutiner och
                    säkerhetsinstruktioner för utformning av Kontouppgifterna. Dessa
                    går att finna på Webbsidan.
                  </h2>
                  </li>
                  <li><h2>Vid användning av Tjänsten är
                    Entreprenören skyldig att följa gällande lagar, förordningar
                    och regler. Entreprenören ska, på Mr ShoeBox begäran, i skälig
                    utsträckning bistå med sådan hjälp som anses nödvändig för
                    att Mr ShoeBox ska kunna följa gällande lagar, förordningar och
                    regler kopplade till Tjänsten. Entreprenören ska omedelbart
                    meddela Mr ShoeBox i det fall Entreprenören får kännedom om,
                    eller skälig anledning att anta, att någon lagöverträdelse som
                    har samband med Tjänsten eller dess</h2>
                  </li>
                  <li><h2>Entreprenören ansvarar för att
                    Kontouppgifterna förvaras på ett säkert sätt och att obehöriga
                    personer inte kan ta del av dessa. Kontouppgifterna är personliga
                    och får inte överlåtas eller användas av annan än
                    Entreprenören eller av denne, och enkom på dennes ansvar, utsedd
                    behörig person.
                  </h2>
                  </li>
                  <li><h2>Entreprenören är skyldig att registrera
                    s.k. Sub-Entreprenörer för det fall det finns flera användare
                    hos Entreprenören som använder Tjänsten. Sub-Entreprenörer
                    registreras via Kontot på Webbsidan eller Applikationen. Flera
                    användare hos Entreprenören får inte dela på ett Konto.
                  </h2>
                  </li>
                  <li><h2>Entreprenören kontrollerar till fullo och
                    ensidigt vilken Administratör denne önskar tillgång till sina
                    Händelser och annan kommunikation. Entreprenören har den totala
                    kontrollen över informationen som sparats i Mr ShoeBox och i
                    vilken utsträckning Tjänsten används. Entreprenören avgör
                    själv om denne vill avsluta Tjänsten. Entreprenören äger rätt
                    att när som helst byta Administratör. För att undvika
                    eventuella missförstånd - Mr ShoeBox ansvarar inte för vilka
                    Administratörer Entreprenören väljer att använda.
                  </h2>
                  </li>
                </ol>
              </li>
              <li><h1>Immateriella rättigheter och licens</h1>
                <ol>
                  <li><h2>Innehållet i
                    Tjänsten, såsom text, varumärken, grafik, logotyper,
                    knappikoner, bilder samt sammanställningar av dessa och all
                    programvara som används i Tjänsten, tillhör Mr ShoeBox, Mr
                    ShoeBox licensgivare eller leverantörer. Dessa immateriella
                    rättigheter är skyddade av tillämpliga lagar, inklusive, och
                    utan begränsning, upphovsrättslagen och andra lagar som skyddar
                    immateriella rättigheter och äganderätt. Entreprenören
                    förbinder sig att följa alla sådana tillämpliga lagar och att
                    inte ändra eller ta bort notiser gällande upphovsrätt eller
                    äganderätt i så</h2>
                  </li>
                  <li><h2>Kontoinformation och Händelser som
                    Entreprenörer sparar och kommunicerar via Tjänsten omfattas inte
                    av sådana immateriella rättigheter som avses i punkten 6.1 ovan,
                    utan ägs av Entreprenören. Mr ShoeBox har dock en icke-exklusiv
                    rätt att använda innehåll i Tjänsten som genererats av
                    Entreprenören i den utsträckning det krävs för att fullgöra
                    Tjänsten eller övriga förpliktelser i förhållande till
                  </h2></li>
                  <li><h2>Mr ShoeBox upplåter till Entreprenören
                    en icke-exklusiv, icke-överlåtbar och i tiden begränsad rätt
                    att använda programvara som ingår Tjänsten. Mr ShoeBox behåller
                    äganderätten till alla kopior av programvaran som ingår i
                    Tjänsten, även efter det att den installerats av Entreprenören.
                    Mr ShoeBox har rätt att överlåta denna licens eller någon del
                    därav till tredje part. Mr ShoeBox uppdaterar kontinuerligt
                    programvaran för Tjänsten utan kostnad för Entreprenören annat
                    än sådana som redan existerar.
                  </h2></li>
                  <li><h2>Entreprenören får inte på något sätt:</h2></li>
                </ol>
              </li>
            </ol>
            <ul>
              <li><h2>Överlåta denna licens eller någon del
                  därav eller på annat sätt överföra eller underlicensiera
              </h2>
              </li>
              <li><h2>Överlåta, kopiera eller distribuera
              </h2>
              </li>
              <li><h2>Kringgå eventuella begränsningar i
                  Tjänsten som är av teknisk karaktär, processliknande karaktär
                  eller grafisk form eller liknande.
              </h2>
              </li>
              <li><h2>Dekompilera eller ändra Tjänsten
                  på något sätt.
              </h2>
              </li>
              <li><h2>Delge information till tredje part avseende
              </h2>
              </li>
            </ul>
            <ol start={7}>
              <li><h1>Kontot
              </h1>
                <ol>
                  <li><h2>Kontot är aktivt så länge som
                   </h2>
                  </li>
                </ol>
              </li>
              <li><h1>Priser</h1>
                <ol>
                  <li><h2>Tjänsten är kostnadsfri för
                    Entreprenören, förutom om Entreprenör tillsammans med
                    Administratören använder lagringsutrymmet ”Dokument” enligt
                    vad som anges i punkten 4.3 ovan.
                  </h2>
                  </li>
                  <li><h2>Mr ShoeBox förbehåller sig rätten att
                    ändra priserna för Tjänsten i enlighet med</h2>
                  </li>
                  <li><h2>Entreprenören är införstådd med och
                    accepterar att Administratörers priser för sina tjänster inte
                    regleras av dessa Villkor. Entreprenören kommer självmant
                    överens med Administratören om priset och betalningsvillkor för
                    Administratörens tjänster.
                  </h2>
                  </li>
                  <li><h2>Mr ShoeBox har inget ansvar för att ett
                    avtal upprättas mellan Administratören och Entreprenören. Detta
                    rekommenderas do</h2>
                  </li>
                </ol>
              </li>
              <li><h1>Tjänstens tillhandahållande</h1>
                <ol>
                  <li><h2>Målsättningen är att Tjänsten är
                    tillgänglig dygnet runt. Mr ShoeBox garanterar dock inte att
                    Tjänsten är fri från fel eller avbrott under denna tid.
                  </h2>
                  </li>
                  <li><h2>Mr ShoeBox utvecklar kontinuerligt
                    Tjänsten och kommer därav att underhålla och uppgradera
                    tjänsten från tid till annan. Detta kan tyvärr innebära
                    kortare avbrott, fel eller förseningar i Tjänsten. Inför varje
                    planerad uppgradering är Mr ShoeBox ambition att i god tid
                    meddela Entreprenören om detta.
                  </h2>
                  </li>
                  <li><h2>Tillgången till Tjänsten kräver tredje
                    parts delaktighet (mobiloperatörer, tillverkare av
                    mobiltelefoner, datorer, surfplattor o.s.v.). Mr ShoeBox tar inget
                    ansvar för brister i tredje parts</h2>
                  </li>
                </ol>
              </li>
              <li><h1>Reklamationer och skyldighet att påtala
                obehörig tillgång till Mr ShoeBox Tjänst
              </h1>
                <ol>
                  <li><h2>Entreprenören
                    är skyldig att omedelbart meddela Mr ShoeBox om fel eller brister
                    i Tjänsten eller andra brister av teknisk karaktär som kan
                    härledas till Tjänstens programvara. Sådan anmälan sker till
                    <a href='mailto:support@mrshoebox.com'>support@mrshoebox.com</a>.</h2>
                  </li>
                  <li><h2>Entreprenören är skyldig att omedelbart
                    meddela Mr ShoeBox via support@mrshoebox.com och att ändra
                    Kontouppgifterna om det finns anledning att anta att någon
                    obehörig fått tillgång till Entreprenö</h2>
                  </li>
                  <li><h2>Om
                    Entreprenören har fått kännedom om, eller misstänker att,
                    Tjänsten har missbrukats av utomstående part måste
                    Entreprenören omgående underrätta Mr ShoeBox efter att detta
                    upptäckts. Anmälan sker till <a href='mailto:support@mrshoebox.com'>support@mrshoebox.com</a>.
                  </h2>
                  </li>
                  <li><h2>Skulle Entreprenören meddela Mr ShoeBox
                    om sådan händelse som avses i 10.1 - 10.3 ovan, är
                    Entreprenören skyldig att samarbeta med Mr ShoeBox för att Mr
                    ShoeBox ska kunna utreda och å</h2>
                  </li>
                  <li><h2>Om det förligger fel eller brist i
                    Tjänsten, åtar sig Mr ShoeBox att, på begäran av
                    Entreprenören, inom skälig tid påbörja felsökning och
                    åtgärdande av Tjänsten. Detta innefattar bl.a. att Mr ShoeBox
                    ska försöka spåra informationsinnehållet i Händelser och
                    kommunikation i Tjänsten och om möjligt återställa detta, samt
                    meddela En</h2>
                  </li>
                </ol>
              </li>
              <li><h1>Arkivering av Händelser</h1>
                <ol>
                  <li><h2>Mr ShoeBox bevarar de Händelser som
                    kommunicerats mellan Entreprenören och Administratören på ett
                    säkert sätt i 10 år från den tidpunkt en Händelse sparats för
                    första gången. Detta åtagande gäller under förutsättning att
                    Entreprenörens Konto är aktiverat under hela den sammanhängande
                    perioden om 10 år. Med 'aktiverat' avses här att
                    Entreprenören regelbundet loggar in på Tjänsten via sitt Konto
                    och registrerar minst en Händelse en gång om året, se mer om
                    detta under punkten 15.1.
                  </h2>
                  </li>
                  <li><h2>För det fall Entreprenörens Konto
                    avslutas innan 10 år har förflutit kommer Entreprenören i
                    möjligaste mån erbjudas att ladda ner alla Händelser inom [3]
                    månader från
                    tidpunkten då Kontot avslutas eller blivit inaktivt. Det är dock
                    Entreprenörens ansvar att se till att denne har laddat ner sådana
                    Händelser Entreprenören önskar att spara elektroniskt i samband
                    med att Kontot avslutas eller inaktiveras. Händelser kommer sedan
                    att ra</h2>
                  </li>
                </ol>
              </li>
              <li><h1>Ansvar och ansvarsbegränsning</h1>
                <ol>
                  <li><h2>Mr ShoeBox ansvarar inte, under några
                    omständigheter för indirekt skada, förlust av information,
                    utebliven vinst, böter, förseningsavgifter, krav från tredje
                    parter (innefattande myndigheter) eller andra förluster som
                    åsamkas Entreprenören i samband med användningen av eller
                    utebliven användning av Tjänsten, med undantag för om Mr
                    ShoeBox agerat med uppsåt eller grovt vårdslöst. Mr ShoeBox
                    åtar sig inte ansvar för tredje parts handling eller
                    underlåtenhet.
                  </h2>
                  </li>
                  <li><h2>Mr ShoeBox ansvarar inte för
                    avtalsförhållandet mellan Entreprenören och Administratören.
                    Detta innebär att Mr ShoeBox inte ansvarar för brister eller
                    felaktigheter i eller vid utförandet av den tjänst som
                    Administratören tillhandahåller Entreprenören. Entreprenören
                    är införstådd med och accepterar att Mr ShoeBox inte ansvarar
                    för krav f</h2>
                  </li>
                  <li><h2>Mr ShoeBox ansvarar inte heller för
                    obehörig tillgång till eller användning av Kontouppgifter
                    lagrade på Mr ShoeBox servrar, såvida inte annat följer av
                    tvingande lag. Mr ShoeBox ansvarar inte heller för, i de fall
                    förekommande, förseningar som beror på tekniska fel eller andra
                    liknande orsaker.
                  </h2>
                  </li>
                  <li><h2>Mr ShoeBox ansvarar inte för att denna
                    typ av digital lagring av Händelser som Tjänsten innebär
                    uppfyller de krav som uppställs av lagar, förordningar och
                    myndighetsföreskrifter som ställs på bokföring och företag.
                    Entreprenören ansvarar för att lagring och arkivering av
                    ursprungshandlingar (t.ex. papperskvitton, fakturor,
                    deklarationsblanketter) uppfyller relevant lagstiftning och andra
                    från tid till annan gällande krav på Entreprenörens
                  </h2>
                  </li>
                  <li><h2>För det fall Tjänsten gör intrång i
                    tredje parts immateriella rättigheter ska Mr ShoeBox ersätta
                    Entreprenören för sådana krav från tredje part, i enlighet med
                    ansvarbegränsningarna i dessa Villkor, om de grundas på
                    Entreprenörens villkorsenliga användning av Tjänsten. Mr
                    ShoeBox har rätt, men ingen skyldighet, att på egen bekostnad
                    träda in i tvister mellan Entreprenören och tredje part som
                  </h2>
                  </li>
                  <li><h2>Entreprenören ansvarar för att all
                    användning av Tjänsten under Entreprenörens Konto, eller
                    Sub-Entreprenörer, sker i enlighet med dessa Villkor.
                    Entreprenören ska hålla Mr ShoeBox skadelös för alla krav som
                    riktas mot Mr ShoeBox eller skador som åsamkas Mr ShoeBox som en
                    följd av Entreprenö</h2>
                  </li>
                  <li><h2>Varken Mr ShoeBox eller Entreprenören kan
                    hållas ansvariga för underlåtenhet att fullgöra dessa Villkor
                    eller för skador som orsakas motparten som en följd av ändring
                    i lag, förordning eller myndighetsföreskrift, handling eller
                    underlåtenhet av myndighet, olycka, naturkatastrof, krig, strejk,
                    blockad, eller annan liknande omständighet som är utanför
                    parternas kontroll och som parterna skäligen inte kunnat undvika
                  </h2>
                  </li>
                  <li><h2>Om Mr ShoeBox får kännedom om att
                    obehörig har, eller har haft, tillgång till information på Mr
                    ShoeBox servrar ska Mr ShoeBox informera den eller de
                    Entreprenörer som blivit drabbade om detta samt vilken, om
                    möjligt, informati</h2>
                  </li>
                </ol>
              </li>
              <li><h1>Sekretess och lagring av information</h1>
                <ol>
                  <li><h2>Mr ShoeBox och Entreprenören ska iaktta
                    sekretess och förbinder sig att inte för utomstående avslöja
                    information om den andra parten som har märkts som
                    'konfidentiell' eller som annars kan antas utgöra
                    konfidentiell information, t.ex. företagshemligheter, annat än
                    för att fullgöra sina skyldigheter enligt dessa Villkor.
                    Entreprenören förbinder sig att hantera information relaterad
                    till Mr ShoeBox, Administratören eller andra intressenter som
                    använder Tjänsten på ett omsorgsfullt sätt. Mr ShoeBox tar
                    inte ansvar för på vilket sätt Entreprenören hanterar
                    information som är extraherad ur Tjänsten.
                  </h2>
                  </li>
                  <li><h2>Entreprenören äger all information
                    relaterad till Entreprenören, såsom Händelser, meddelanden,
                    kommentarer, dokument, körjournaler, tidrapporter,
                    resultaträkningar, balansräkningar och allt annat som
                    kommunicerats eller sparats mellan Entreprenören och
                    Administratören via Tjänsten. Mr ShoeBox äger inte rätt att
                    använda sådan information på annat sätt än vad som anges i
                    dessa Villkor och i den utsträckning som krävs för utförande
                  </h2>
                  </li>
                </ol>
              </li>
              <li><h1>Synpunkter på Tjänsten</h1>
                <ol>
                  <li><h2>Mr ShoeBox välkomnar Entreprenören att
                    lämna synpunkter på eller idéer om Tjänsten via e-post till
                    ideas@mrshoebox.com eller direkt till ansvariga på Mr ShoeBox. Mr
                    ShoeBox förbinder sig inte att i praktiken möta dessa önskemål
                    - även om de välkomnas och uppskattas innerligt. Mr ShoeBox
                    kommer inte heller ersätta Entreprenören, om inte annat
                    överenskommit, för dessa synpunkter, idéer eller liknande
                    oavsett om de senare används eller inte används av Mr ShoeBox i
                  </h2>
                  </li>
                  <li><h2>Entreprenören är medveten om och
                    accepterar att Entreprenören inte har någon rätt till sådana
                    ev. förbättringar av Tjänsten som Mr ShoeBox använder, och att
                    Mr ShoeBox ska förvärva all äganderätt till eventuella
                    förändringar av Tjänsten som påtalats av Entreprenören.
                    Entreprenören avsäger sig särskilt den ideella rätten till
                    </h2>
                  </li>
                </ol>
              </li>
              <li><h1>Avtalstid och uppsägning</h1>
                <ol>
                  <li><h2>Kontot är
                    aktivt så länge Entreprenören önskar, förutsatt att dessa
                    Villkor följs och Entreprenören använder Tjänsten genom att
                    logga in på Tjänsten via sitt Konto och laddar upp minst en (1)
                    Händelse per kalenderår.
                  </h2></li>
                  <li><h2>Det finns
                    ingen bindningstid för Tjänsten. Entreprenören kan när som
                    helst avsluta Kontot med omedelbar verkan genom att meddela Mr
                    ShoeBox därom via avtal@mrshoebox.se.
                  </h2></li>
                  <li><h2>För det fall Entreprenören inte skulle
                    ladda upp minst en Händelse varje kalenderår och Kontot därmed
                    skulle inaktiveras, eller om Entreprenören avslutar Kontot enligt
                    15.2 ovan, kommer Mr ShoeBox i möjligaste mån att skicka ett
                    meddelande till Entreprenören därom och ge Entreprenören
                    möjlighet att aktivera sitt Konto eller spara ner Händelser
                    innan Kontot avslutas. Mr ShoeBox kommer radera Entreprenörens
                    Händelser tre månader efter
                    att Kontot avslutas eller inaktiveras. Det är dock Entreprenörens
                    ansvar att se till att denne sparar ner de Händelser denne önskar
                  </h2></li>
                  <li><h2>Rätten till Händelserna och tillgången
                    till dessa ägs av Entreprenören och kommer därför finnas kvar
                    i Tjänsten så länge Entrepr</h2></li>
                  <li><h2>Om den Administratör som Entreprenören
                    använder slutar betala för Tjänsten så upphör möjligheten
                    för Administratören och Entreprenören att kommunicera via
                    Tjänsten och Administratören kommer inte längre få tillgång
                    till inskickade händelser och information. De Händelser och den
                    information som finns i Tjänsten fram till dagen då betalningen
                    upphörde kommer fortfarande att finnas tillgängligt för
                    Administratören under en begränsad tid så
                    länge som Entreprenören väljer att behålla Administratören.
                  </h2></li>
                  <li><h2>Mr ShoeBox äger rätt att omedelbart
                    avsluta Entreprenörens Konto om dessa Villkor inte efterlevs. Om
                    Villkoren och Kontot sägs upp med anledning av Entreprenörens
                    brott mot dessa Villkor får Entreprenören endast tillgång till
                    Händelser och annan lagrad information i Tjänsten på det sätt
                    och inom den tid som Mr ShoeBox bestämmer. Mr ShoeBox får även
                    i andra fall än ovanstående säga upp Villkoren och
                    Entreprenörens Konto till upphörande med tre (3) månaders
                    uppsägningstid, t.ex. om Mr ShoeBox lägger ner eller säljer sin
                    verksamhet eller kommer på obestånd. Mr ShoeBox ska då bereda
                    Entreprenören möjlighet att spara ner alla Händelser och annan
                    relevant kommunikation i T</h2></li>
                </ol>
              </li>
              <li><h1>Behandling av personuppgifter
              </h1>
                <ol>
                  <li><h2>Tjänsten innebär att Mr ShoeBox på
                    uppdrag av Entreprenören kan komma att behandla och lagra viss
                    information som Entreprenören laddar upp i Tjänsten
                    (”<b>Personuppgifter</b>”). Entreprenören är
                    personuppgiftsansvarig för behandlingen av Personuppgifter och
                    ansvarar således för att all behandling sker i enlighet med
                    bestämmelserna i personuppgiftslagen (1998:204). Entreprenören
                    bib</h2>
                  </li>
                  <li><h2>Entreprenören garanterar att
                    Entreprenören har rätt att behandla Personuppgifter i Tjänsten
                    och att denne har inhämtat samtycke från de som
                    Personuppgifterna avser eller annars har laglig grund att behandla
                    de</h2>
                  </li>
                  <li><h2>Entreprenören har, om tillämpligt,
                    meddelat ansvarig myndighet om behandling</h2>
                  </li>
                  <li><h2>Entreprenören följer all relevant lag,
                    förordning och myndighetsföreskrifter som gäller för
                    behandling av Personuppgifter vid</h2>
                  </li>
                  <li><h2>Mr ShoeBox är personuppgiftsbiträde för
                    Entreprenörens behandling av Personuppgifter och accepterar att
                    endast behandla Personuppgifter i enlighet med Entreprenörens
                    skäliga instruktioner, tillämplig lag, förordning och
                    myndighetsföreskrifter för beha</h2>
                  </li>
                  <li><h2>Mr ShoeBox har implementerat sådana
                    tekniska och organisatoriska säkerhetsåtgärder som avses i 31 §
                  </h2>
                  </li>
                  <li><h2>Mr ShoeBox kan komma att överföra
                    Personuppgifter till tredje parter och ska därvid ha rätt att
                    anlita underbiträden, under förutsättning att skriftliga avtal
                    upprättas mellan Mr ShoeBox och underbiträden.
                  </h2>
                  </li>
                </ol>
              </li>
              <li><h1>Meddelanden</h1>
                <ol>
                  <li><h2>Meddelanden mellan Mr ShoeBox och
                    Entreprenören avfattas på engelska eller svenska och sänds via
                    e-post till den av Entreprenören angivna kontaktpersonen (som
                    Entreprenören anger i Kontot). Meddelanden ska anses ha kommit
                    den andra parten tillhanda vid vederbörlig bekräftelse på
                    e-p</h2>
                  </li>
                </ol>
              </li>
            </ol>
            <ol start={18}>
              <li><h1>Överlåtelse av förpliktelser enligt
              </h1>
                <ol>
                  <li><h2>Entreprenören har inte rätt att överlåta
                    sitt Konto eller rättigheter eller skyldigheter kopplade till
                    Tjänsten till annan utan Mr ShoeBox föregående skriftliga
                    godkännande. Mr ShoeBox har rätt att helt eller delvis överlåta
                    rättigheter och skyldigheter enligt Villkoren till annan part
                  </h2>
                  </li>
                </ol>
              </li>
              <li><h1>Tillägg och ändringar i Villkoren</h1>
                <ol>
                  <li><h2>>Mr
                    ShoeBox har
                    rätt att ändra Villkoren. Sådana ändringar kommer vara på
                    svenska.
                  </h2></li>
                  <li><h2>Mr ShoeBox kommer att meddela
                    Entreprenören om eventuella förändringar i dessa Villkor via
                    e-post till den kontaktperson som Entreprenören anger i Kontot
                    eller på Hemsidan eller i Applikationen. Om Entreprenören inte
                    accepterar ändringar i Villkoren har denne rätt att omgående
                    avsluta Kontot enligt vad som anges i 15.2. Om Entreprenören inte
                    säger upp sitt Konto före den dag ändringarna träder i kraft,
                    anses Entreprenören ha godkänt dem.
                  </h2></li>
                  <li><h2>Mr ShoeBox har alltid rätt att med
                    omedelbar verkan vidta de ändringar som föranleds av lag,
                    förordning eller myndighetsbeslut.
                  </h2></li>
                  <li><h2>Svensk lag tillämpas på dessa Villkor.
                    Tvist mellan parterna ska i första hand lösas genom ett trevligt
                    möte på en trevlig plats och därefter genom förhandling mellan
                    parterna och i sista hand av allmän domst</h2></li>
                </ol>
              </li>
            </ol>
          </div>
        </div>
      </div>
    )
  }

  get digitalaForetagetTerms () {
    return (
      <div className='registration-terms blinfo'>
        <div>
          <div>
            <p><b>Allmänna villkor ”Entreprenören”</b></p>
            <p>
              Björn Lundén Information AB:s, 556293-9982, Näsviksvägen 23, 820 64 Näsviken
              (”Björn Lundén Information”), ambition med dessa "Villkor" är att på ett bra
              och rättvist sätt säkerställa våra egna intressen och våra intressenters möjligheter
              till en positiv upplevelse av de tjänster som vi erbjuder.
            </p>
            <p>
              Björn Lundén Information tillhandahåller en kommunikations- och arkiveringstjänst
              (Digitala företaget) som möjliggör direkt kommunikation mellan en näringsidkare
              (”Entreprenören”) och den som utför bokföringstjänster på uppdrag av Entreprenören
              (”Administratören”) genom att tillåta dem att skicka och ta emot information som
              ligger till grund för Entreprenörens bokföring, samt rapporter för ekonomiska beslut
              ("Tjänsten"). Tjänsten möjliggör arkivering av det underlag som ligger till grund för
              bokföringen och andra dokument relaterade till Entreprenörens verksamhet. Genom Tjänsten
              möjliggörs även transparens mot intressenter såsom Skatteverket, Bolagsverket etc.
            </p>
            <p>
              Dessa Villkor gäller mellan Entreprenören och Björn Lundén Information för Entreprenörens
              användning av Tjänsten.
            </p>
            <p>
              Avtal tecknas separat mellan Entreprenören och dennes Administratör samt mellan Björn
              Lundén Information och Administratören.
            </p>
            <p>
              Tjänsten regleras av tillämpliga lagar, förordningar och myndighetsföreskrifter som gäller för
              kommunikation, arkivering och lagring av information. Björn Lundén Information tar inte ansvar
              för att Tjänsten uppfyller de nationella och lokala krav som gäller på den plats där Entreprenören
              är verksam. Det är ytterst Entreprenörens ansvar att tillse att Entreprenören följer alla
              tillämpliga lagar och regler för sin verksamhet.
            </p>
            <p>
              Entreprenören godkänner att information och upplysningar som krävs enligt lag och annan information
              om dennes rättigheter och skyldigheter kan sändas till Entreprenören elektroniskt och att
              Entreprenörens elektroniska godkännande av dessa Villkor är juridiskt bindande.
            </p>
            <ol>
              <li>
                <h1>Allmänt om tjänsten</h1>
                <ol>
                  <li><h2>Med en ”Händelse” avses i dessa Villkor en av Entreprenören registrerad affärshändelse.
                    Händelser som fått en numrering i systemet och som ligger till grund för företagets resultat-
                    och balansräkning betraktas som en Händelse. Sparade projekt, övriga dokument så som
                    registreringsbevis, upplysningar från Skatteverket mm registreras inte som Händelser.</h2>
                  </li>
                  <li>
                    <h2>Med Tjänsten kan Entreprenören ta emot och skicka information i form av Händelser och
                      underlag, rapporter, kommentarer och meddelanden som ligger till grund för Entreprenörens
                      bokföring och rapporter. Kommunikationen sker genom mobiltelefon, surfplatta eller dator
                      och arkiveringen av allt bildmaterial sker på av Björn Lundén Information angivna servrar.</h2>
                  </li>
                  <li>
                    <h2>
                      Björn Lundén Information kan när som helst ändra Tjänstens innehåll eller funktioner efter
                      eget gottfinnande i syfte att förbättra Tjänsten eller om det fordras på grund av lagändringar,
                      myndigheters ändrade föreskrifter eller liknande. Björn Lundén Information kommer i möjligaste
                      mån publicera information om eventuella förändringar i Tjänsten på Webbsidan (enligt definition
                      i avsnitt 2.1) eller vid inloggning i Tjänsten.
                    </h2>
                  </li>
                </ol>
              </li>
              <li>
                <h1>Kontot och anslutning till Tjänsten</h1>
                <ol>
                  <li><h2>Entreprenören får tillgång till Tjänsten via Björn Lundén Informations mobilapplikation
                    som finns tillgänglig i AppStore och Google Play (”Applikationen”) och webbsidan
                    admin.digitalaforetaget.se (”Webbsidan”). Den del av Tjänsten som möjliggör kommunikation mellan
                    Entreprenören och en Administratör kan endast aktiveras genom Administratören.</h2>
                  </li>
                  <li><h2>Entreprenören aktiverar Tjänsten genom att registrera ett konto via Webbsidan eller i
                    Applikationen (”Kontot”) och ange sådana nödvändiga uppgifter om Entreprenören som krävs för
                    registrering, samt lösenord och användarnamn (”Kontouppgifter”). Vid registreringen av Kontot är
                    Entreprenören skyldig att ange fullständiga och korrekta Kontouppgifter. Entreprenören är skyldig
                    att uppdatera Kontouppgifterna utan dröjsmål om dessa skulle komma att ändras. Björn Lundén
                    Information kan inte hållas ansvarigt för brott mot dessa Villkor som orsakas av att Entreprenören
                    inte uppdaterat sina Kontouppgifter.</h2>
                  </li>
                  <li>
                    <h2>Genom att skapa ett Konto och godkänna Villkoren försäkrar Entreprenören att denne läst
                      igenom och godkänt Villkoren samt att Entreprenören kommer följa dessa. Entreprenörens
                      godkännande utgör dock endast en förfrågan om att ansluta sig till Tjänsten och är villkorat
                      av att Björn Lundén Information godkänner Entreprenören enligt punkten 2.5 nedan.
                    </h2>
                  </li>
                  <li>
                    <h2>Vid misstanke om att Entreprenören inte är innehavare av Kontot äger Björn Lundén Information
                      rätt att ställa frågor och Entreprenören förbinder sig att i nödvändig utsträckning bistå med
                      sådan information som krävs för att Björn Lundén Information ska kunna verifiera Entreprenörens
                      identitet.
                    </h2>
                  </li>
                  <li>
                    <h2>Björn Lundén Information avgör efter eget gottfinnande om Entreprenören ska beredas tillgång
                      till Tjänsten. När Björn Lundén Information beslutat ge Entreprenören tillgång till Tjänsten
                      och bekräftar Entreprenörens anslutning till Tjänsten via e-post, träder dessa Villkor i kraft
                      mellan Björn Lundén Information och Entreprenören.</h2>
                  </li>
                </ol>
              </li>
              <li>
                <h1>Tekniska förutsättningar</h1>
                <ol>
                  <li>
                    <h2>De vid var tid gällande tekniska krav som Tjänsten förutsätter för att kunna fungera finns
                      angivna på Webbsidan. Det är Entreprenörens ansvar att tillse att de datorer, mobiltelefoner
                      och surfplattor som används för att använda Tjänsten vid var tid uppfyller dessa tekniska krav,
                      Björn Lundén Information tar inte ansvar för att vare sig Tjänsten eller dess innehåll uppfyller
                      dessa Villkor om Entreprenören använder datorer, surfplattor eller mobiltelefoner som kringgått
                      tekniska skyddsåtgärder för Tjänsten.</h2>
                  </li>
                  <li>
                    <h2>Entreprenörens tillgång till Tjänsten via dator, mobiltelefon och surfplatta är beroende
                      av bland annat internet och andra tjänster som erhålls av tredje part. Dessa tredje parter kan
                      komma att debitera Entreprenören avgifter för att få tillgång till Tjänsten. Entreprenören är
                      ansvarig för användandet och betalningen av dessa. Björn Lundén Information ansvarar inte på
                      något sätt för de avtal Entreprenören väljer att ingå för användning av dessa.</h2>
                  </li>
                </ol>
              </li>
              <li>
                <h1>Förutsättningar för och begränsningar i användningen av Tjänsten</h1>
                <ol>
                  <li>
                    <h2>Genom att godkänna Villkoren försäkrar Entreprenören: (1) att Entreprenören och eventuella
                      personer som använder Tjänsten för Entreprenörens räkning är minst 18 år, (2) att Entreprenören
                      företräder sig själv och inte representerar någon annan.
                    </h2>
                  </li>
                  <li>
                    <h2>Tjänsten får inte användas för verksamhet som avser eller kan kopplas till (1) pornografi
                      eller narkotika, (2) prostitution eller sexdating, (3) vapen, (4) spel, vadslagning, lotterier,
                      bingo och andra speltjänster, om dessa kan antas vara förbjudna enligt lag eller i övrigt är
                      tveksamma från ett risk- eller renomméperspektiv. Entreprenören får inte spara eller ladda upp
                      Händelser eller kommunikation som avser punkterna (1)–(4) ovan. Björn Lundén Information äger
                      rätt att avgöra om Entreprenörens användning av Tjänsten ska anses hänförlig till någon av ovan
                      angivna varor eller tjänster och därmed bryter mot dessa Villkor.</h2>
                  </li>
                  <li>
                    <h2>Entreprenören får kostnadsfritt använda lagringsplatsen ”Dokument” förutsatt att enskilda
                      datafiler och/eller datafilerna tillsammans inte överstiger den datamängd som vid var tid
                      finns angiven på www.blinfo.se/digitalaforetaget. Om det finns önskemål om en större
                      lagringsmängd än den kostnadsfria kan Björn Lundén Information debitera för detta enligt de vid
                      var tid gällande priserna som finns på www.blinfo.se/digitalaforetaget. Entreprenören kan fritt
                      radera och exportera informationen på denna lagringsplats.</h2>
                  </li>
                </ol>
              </li>
              <li>
                <h1>Entreprenörens användning av Tjänsten</h1>
                <ol>
                  <li><h2>Entreprenören förbinder sig att följa Björn Lundén Informations vid var tid gällande rutiner
                    och säkerhetsinstruktioner för utformning av Kontouppgifterna. Dessa går att finna på Webbsidan.</h2>
                  </li>
                  <li><h2>Vid användning av Tjänsten är Entreprenören skyldig att följa gällande lagar, förordningar
                    och regler. Entreprenören ska, på Björn Lundén Informations begäran, i skälig utsträckning bistå
                    med sådan hjälp som anses nödvändig för att Björn Lundén Information ska kunna följa gällande
                    lagar, förordningar och regler kopplade till Tjänsten. Entreprenören ska omedelbart meddela Björn
                    Lundén Information i det fall Entreprenören får kännedom om, eller skälig anledning att anta, att
                    någon lagöverträdelse som har samband med Tjänsten eller dessa Villkor har skett.</h2>
                  </li>
                  <li><h2>Entreprenören ansvarar för att Kontouppgifterna förvaras på ett säkert sätt och att obehöriga
                    personer inte kan ta del av dessa. Kontouppgifterna är personliga och får inte överlåtas eller
                    användas av annan än Entreprenören eller av denne, och enkom på dennes ansvar, utsedd behörig person.</h2>
                  </li>
                  <li><h2>Entreprenören kontrollerar till fullo och ensidigt vilken Administratör denne önskar tillgång
                    till sina Händelser och annan kommunikation. Entreprenören har den totala kontrollen över
                    informationen som sparats hos Björn Lundén Information och i vilken utsträckning Tjänsten används.
                    Entreprenören avgör själv om denne vill avsluta Tjänsten. Entreprenören äger rätt att när som helst
                    byta Administratör. För att undvika eventuella missförstånd - Björn Lundén Information ansvarar inte
                    för vilken Administratör Entreprenören väljer att använda.</h2>
                  </li>
                </ol>
              </li>
              <li>
                <h1>Immateriella rättigheter och licens</h1>
                <ol>
                  <li><h2>Innehållet i Tjänsten, såsom text, varumärken, grafik, logotyper, knappikoner, bilder samt
                    sammanställningar av dessa och all programvara som används i Tjänsten, tillhör Björn Lundén
                    Information, Björn Lundén Informations licensgivare eller leverantörer. Dessa immateriella rättigheter är skyddade av tillämpliga lagar, inklusive, och utan begränsning, upphovsrättslagen och andra lagar som skyddar immateriella rättigheter och äganderätt. Entreprenören förbinder sig att följa alla sådana tillämpliga lagar och att inte ändra eller ta bort notiser gällande upphovsrätt eller äganderätt i sådana immateriella rättigheter.
                  </h2>
                  </li>
                  <li><h2>Kontoinformation och Händelser som Entreprenörer sparar och kommunicerar via Tjänsten omfattas
                    inte av sådana immateriella rättigheter som avses i punkten 6.1 ovan, utan ägs av Entreprenören.
                    Björn Lundén Information har dock en icke-exklusiv rätt att använda innehåll i Tjänsten som
                    genererats av Entreprenören i den utsträckning det krävs för att fullgöra Tjänsten eller övriga
                    förpliktelser i förhållande till Entreprenören.</h2>
                  </li>
                  <li><h2>Björn Lundén Information upplåter till Entreprenören en icke-exklusiv, icke-överlåtbar
                    och i tiden begränsad rätt att använda programvara som ingår Tjänsten. Björn Lundén Information
                    behåller äganderätten till alla kopior av programvaran som ingår i Tjänsten, även efter det att
                    den installerats av Entreprenören. Björn Lundén Information har rätt att överlåta denna licens
                    eller någon del därav till tredje part. Björn Lundén Information uppdaterar kontinuerligt
                    programvaran för Tjänsten utan kostnad för Entreprenören annat än sådana som redan existerar.</h2>
                  </li>
                  <li>
                    <h2>Entreprenören får inte på något sätt:</h2>
                    <ul>
                      <li>
                        Överlåta denna licens eller någon del därav eller på annat sätt överföra eller underlicensiera
                        rättigheterna enligt licensen, till någon tredje part.
                      </li>
                      <li>
                        Överlåta, kopiera eller distribuera Tjänsten.
                      </li>
                      <li>
                        Kringgå eventuella begränsningar i Tjänsten som är av teknisk karaktär, processliknande karaktär
                        eller grafisk form eller liknande.
                      </li>
                      <li>
                        Dekompilera eller ändra Tjänsten på något sätt.
                      </li>
                      <li>
                        Delge information till tredje part avseende Tjänstens utformning och funktion.
                      </li>
                    </ul>
                  </li>
                </ol>
              </li>
              <li>
                <h1>Kontot</h1>
                <ol>
                  <li><h2>Kontot är aktivt så länge som Entreprenören önskar, förutsatt att dessa Villkor efterlevs.</h2>
                  </li>
                </ol>
              </li>
              <li>
                <h1>Priser</h1>
                <ol>
                  <li><h2>Tjänsten är kostnadsfri för Entreprenören, förutom om Entreprenören tillsammans med
                    Administratören använder lagringsutrymmet ”Dokument” utöver vad som anges i punkten 4.3 ovan.</h2>
                  </li>
                  <li><h2>Björn Lundén Information förbehåller sig rätten att ändra priserna för Tjänsten i enlighet
                    med punkten 19.1 nedan.</h2>
                  </li>
                  <li><h2>Entreprenören är införstådd med och accepterar att Administratörers priser för sina tjänster
                      inte regleras av dessa Villkor. Entreprenören kommer självmant överens med Administratören om
                      pris och betalningsvillkor för Administratörens tjänster.</h2>
                  </li>
                  <li><h2>Björn Lundén Information har inget ansvar för att ett avtal upprättas mellan Administratören
                    och Entreprenören. Detta rekommenderas dock starkt av Björn Lundén Information.</h2>
                  </li>
                </ol>
              </li>
              <li>
                <h1>Tjänstens tillhandahållande</h1>
                <ol>
                  <li><h2>Målsättningen är att Tjänsten är tillgänglig dygnet runt. Björn Lundén Information garanterar
                    dock inte att Tjänsten är fri från fel eller avbrott under denna tid.</h2>
                  </li>
                  <li><h2>Björn Lundén Information utvecklar kontinuerligt Tjänsten och kommer därav att underhålla
                    och uppgradera tjänsten från tid till annan. Detta kan tyvärr innebära kortare avbrott, fel eller
                    förseningar i Tjänsten. Inför varje planerad uppgradering är Björn Lundén Information ambition att
                    i god tid meddela Entreprenören om detta.</h2>
                  </li>
                  <li><h2>Tillgången till Tjänsten kräver tredje parts delaktighet (mobiloperatörer, tillverkare av
                    mobiltelefoner, datorer, surfplattor o.s.v.). Björn Lundén Information tar inget ansvar för brister
                    i tredje parts varor eller tjänster.</h2>
                  </li>
                </ol>
              </li>
              <li>
                <h1>Reklamationer och skyldighet att påtala obehörig tillgång till Björn Lundén Information Tjänst</h1>
                <ol>
                  <li><h2>Entreprenören är skyldig att omedelbart meddela Björn Lundén Information om fel eller brister
                    i Tjänsten eller andra brister av teknisk karaktär som kan härledas till Tjänstens programvara.
                    Sådan anmälan sker till support@digitalaforetaget.se.</h2>
                  </li>
                  <li><h2>Entreprenören är skyldig att omedelbart meddela Björn Lundén Information via
                    support@digitalaforetaget.se och att ändra Kontouppgifterna om det finns anledning att anta att
                    någon obehörig fått tillgång till Entreprenörens Kontouppgifter.</h2>
                  </li>
                  <li><h2>Om Entreprenören har fått kännedom om, eller misstänker att, Tjänsten har missbrukats av
                    utomstående part måste Entreprenören omgående underrätta Björn Lundén Information efter att detta
                    upptäckts. Anmälan sker till support@digitalaforetaget.se.</h2>
                  </li>
                  <li><h2>Skulle Entreprenören meddela Björn Lundén Information om sådan händelse som avses i 10.1–10.3
                    ovan, är Entreprenören skyldig att samarbeta med Björn Lundén Information för att Björn Lundén
                    Information ska kunna utreda och åtgärda sådana händelser.</h2>
                  </li>
                  <li><h2>Om det föreligger fel eller brist i Tjänsten, åtar sig Björn Lundén Information att, på
                    begäran av Entreprenören, inom skälig tid påbörja felsökning och åtgärdande av Tjänsten. Detta
                    innefattar bl.a. att Björn Lundén Information ska försöka spåra informations-innehållet i Händelser
                    och kommunikation i Tjänsten och om möjligt återställa detta, samt meddela Entreprenören om
                    resultatet.</h2>
                  </li>
                </ol>
              </li>
              <li>
                <h1>Arkivering av Händelser</h1>
                <ol>
                  <li><h2>Björn Lundén Information bevarar de Händelser som kommunicerats mellan Entreprenören och
                    Administratören på ett säkert sätt i 10 år från den tidpunkt en Händelse sparats för första gången.
                    Detta åtagande gäller under förutsättning att Entreprenörens Konto är aktiverat under hela den
                    sammanhängande perioden om 10 år. Med "aktiverat" avses här att Entreprenören regelbundet loggar in
                    på Tjänsten via sitt Konto och registrerar minst en Händelse en gång om året, se mer om detta under
                    punkten 15.1.</h2>
                  </li>
                  <li><h2>För det fall Entreprenörens Konto avslutas eller blivit inaktivt innan 10 år har förflutit
                    kommer Entreprenören i möjligaste mån erbjudas att ladda ner alla Händelser inom 90 dagar från
                    tidpunkten då Kontot avslutas eller blivit inaktivt. Det är dock Entreprenörens ansvar att se till
                    att denne har laddat ner sådana Händelser Entreprenören önskar att spara elektroniskt i samband med
                    att Kontot avslutas. Händelser kommer sedan att raderas av Björn Lundén Information.</h2>
                  </li>
                </ol>
              </li>
              <li>
                <h1>Ansvar och ansvarsbegränsning</h1>
                <ol>
                  <li><h2>Björn Lundén Information ansvarar inte, under några omständigheter för indirekt skada,
                    förlust av information, utebliven vinst, böter, förseningsavgifter, krav från tredje parter
                    (innefattande myndigheter) eller andra förluster som åsamkas Entreprenören i samband med
                    användningen av eller utebliven användning av Tjänsten, med undantag för om Björn Lundén
                    Information agerat med uppsåt eller grovt vårdslöst. Björn Lundén Information åtar sig inte
                    ansvar för tredje parts handling eller underlåtenhet.</h2>
                  </li>
                  <li><h2>Björn Lundén Information ansvarar inte för avtalsförhållandet mellan Entreprenören och
                    Administratören. Detta innebär att Björn Lundén Information inte ansvarar för brister eller
                    felaktigheter i eller vid utförandet av den tjänst som Administratören tillhandahåller
                    Entreprenören. Entreprenören är införstådd med och accepterar att Björn Lundén Information inte
                    ansvarar för krav från Administratören.</h2>
                  </li>
                  <li><h2>Björn Lundén Information ansvarar inte heller för obehörig tillgång till eller användning
                    av Kontouppgifter lagrade på Björn Lundén Information servrar, såvida inte annat följer av
                    tvingande lag. Björn Lundén Information ansvarar inte heller för, i de fall förekommande,
                    förseningar som beror på tekniska fel eller andra liknande orsaker.</h2>
                  </li>
                  <li><h2>Björn Lundén Information ansvarar inte för att denna typ av digital lagring av Händelser
                    som Tjänsten innebär uppfyller de krav som uppställs av lagar, förordningar och
                    myndighetsföreskrifter som ställs på bokföring och företag. Entreprenören ansvarar för att
                    lagring och arkivering av ursprungshandlingar (t ex papperskvitton, fakturor,
                    deklarationsblanketter) uppfyller relevant lagstiftning och andra från tid till annan gällande
                    krav på Entreprenörens verksamhet.</h2>
                  </li>
                  <li><h2>För det fall Tjänsten gör intrång i tredje parts immateriella rättigheter ska Björn Lundén
                    Information ersätta Entreprenören för sådana krav från tredje part, i enlighet med
                    ansvarsbegränsningarna i dessa Villkor, om de grundas på Entreprenörens villkorsenliga användning
                    av Tjänsten. Björn Lundén Information har rätt, men ingen skyldighet, att på egen bekostnad träda
                    in i tvister mellan Entreprenören och tredje part som berör Tjänsten.</h2>
                  </li>
                  <li><h2>Entreprenören ansvarar för att all användning av Tjänsten under Entreprenörens Konto sker
                    i enlighet med dessa Villkor. Entreprenören ska hålla Björn Lundén Information skadelös för alla
                    krav som riktas mot Björn Lundén Information eller skador som åsamkas Björn Lundén Information
                    som en följd av Entreprenörens brott mot dessa Villkor.</h2>
                  </li>
                  <li><h2>Varken Björn Lundén Information eller Entreprenören kan hållas ansvariga för underlåtenhet
                    att fullgöra dessa Villkor eller för skador som orsakas motparten som en följd av ändring i lag,
                    förordning eller myndighetsföreskrift, handling eller underlåtenhet av myndighet, olycka,
                    naturkatastrof, krig, strejk, blockad, eller annan liknande omständighet som är utanför parternas
                    kontroll och som parterna skäligen inte kunnat undvika (så kallad Force Majeure).</h2>
                  </li>
                  <li><h2>Om Björn Lundén Information får kännedom om att obehörig har, eller har haft, tillgång till
                    information på Björn Lundén Information servrar ska Björn Lundén Information informera den eller
                    de Entreprenörer som blivit drabbade om detta samt vilken, om möjligt, information som
                    otillbörligen förmedlats.</h2>
                  </li>
                </ol>
              </li>
              <li>
                <h1>Sekretess och lagring av information</h1>
                <ol>
                  <li><h2>Björn Lundén Information och Entreprenören ska iaktta sekretess och förbinder sig att inte
                    för utomstående avslöja information om den andra parten som har märkts som "konfidentiell" eller
                    som annars kan antas utgöra konfidentiell information, t ex företagshemligheter, annat än för att
                    fullgöra sina skyldigheter enligt dessa Villkor. Entreprenören förbinder sig att hantera
                    information relaterad till Björn Lundén Information, Administratören eller andra intressenter
                    som använder Tjänsten på ett omsorgsfullt sätt. Björn Lundén Information tar inte ansvar för på
                    vilket sätt Entreprenören hanterar information som är extraherad ur Tjänsten.</h2>
                  </li>
                  <li><h2>Entreprenören äger all information relaterad till Entreprenören, såsom Händelser, meddelanden,
                    kommentarer, dokument, körjournaler, tidrapporter, resultaträkningar, balansräkningar och allt
                    annat som kommunicerats eller sparats mellan Entreprenören och Administratören via Tjänsten.
                    Björn Lundén Information äger inte rätt att använda sådan information på annat sätt än vad som
                    anges i dessa Villkor och i den utsträckning som krävs för utförande av Tjänsten.</h2>
                  </li>
                </ol>
              </li>
              <li>
                <h1>Synpunkter på Tjänsten</h1>
                <ol>
                  <li><h2>Björn Lundén Information välkomnar Entreprenören att lämna synpunkter på eller idéer om
                    Tjänsten via e-post till support@digitalaforetaget.se eller direkt till ansvariga på Björn Lundén
                    Information. Björn Lundén Information förbinder sig inte att i praktiken möta dessa önskemål -
                    även om de välkomnas och uppskattas innerligt. Björn Lundén Information kommer inte heller ersätta
                    Entreprenören, om inte annat överenskommit, för dessa synpunkter, idéer eller liknande oavsett om
                    de senare används eller inte används av Björn Lundén Information i något syfte.</h2>
                  </li>
                  <li><h2>Entreprenören är medveten om och accepterar att Entreprenören inte har någon rätt till
                    sådana ev. förbättringar av Tjänsten som Björn Lundén Information använder, och att Björn Lundén
                    Information ska förvärva all äganderätt till eventuella förändringar av Tjänsten som påtalats av
                    Entreprenören. Entreprenören avsäger sig särskilt den ideella rätten till sådana förändringar.</h2>
                  </li>
                </ol>
              </li>
              <li>
                <h1>Avtalstid och uppsägning</h1>
                <ol>
                  <li><h2>Kontot är aktivt så länge Entreprenören önskar, förutsatt att dessa Villkor följs och
                    Entreprenören använder Tjänsten genom att logga in på Tjänsten via sitt Konto och laddar upp minst
                    en (1) Händelse per kalenderår.</h2>
                  </li>
                  <li><h2>Det finns ingen bindningstid för Tjänsten. Entreprenören kan när som helst avsluta Kontot
                    med omedelbar verkan genom att meddela Björn Lundén Information därom via
                    support@digitalaforetaget.se.</h2>
                  </li>
                  <li><h2>För det fall Entreprenören inte skulle ladda upp minst en Händelse varje kalenderår och
                    Kontot därmed skulle inaktiveras, eller om Entreprenören avslutar Kontot enligt 15.2 ovan, kommer
                    Björn Lundén Information i möjligaste mån att skicka ett meddelande till Entreprenören därom och
                    ge Entreprenören möjlighet att spara ner Händelser innan Kontot avslutas. Björn Lundén Information
                    kommer att radera Entreprenörens Händelser 90 dagar efter att Kontot avslutas. Det är dock
                    Entreprenörens ansvar att se till att denne sparar ner de Händelser denne önskar att spara.</h2>
                  </li>
                  <li><h2>Rätten till Händelserna och tillgången till dessa ägs av Entreprenören och kommer därför
                    finnas kvar i Tjänsten så länge Entreprenörens Konto är aktivt.</h2>
                  </li>
                  <li><h2>Om den Administratör som Entreprenören använder slutar betala för Tjänsten så upphör
                    möjligheten för Administratören och Entreprenören att kommunicera via Tjänsten och Administratören
                    kommer inte längre få tillgång till inskickade händelser och information. De Händelser och den
                    information som finns i Tjänsten fram till dagen då betalningen upphörde kommer fortfarande att
                    finnas tillgängligt för Administratören under en begränsad tid så länge som Entreprenören väljer
                    att behålla Administratören.</h2>
                  </li>
                  <li><h2>Björn Lundén Information äger rätt att omedelbart avsluta Entreprenörens Konto om dessa
                    Villkor inte efterlevs. Om Villkoren och Kontot sägs upp med anledning av Entreprenörens brott
                    mot dessa Villkor får Entreprenören endast tillgång till Händelser och annan lagrad information
                    i Tjänsten på det sätt och inom den tid som Björn Lundén Information bestämmer. Björn Lundén
                    Information får även i andra fall än ovanstående säga upp Villkoren och Entreprenörens Konto till
                    upphörande med tre (3) månaders uppsägningstid, t ex om Björn Lundén Information lägger ner eller
                    säljer sin verksamhet eller kommer på obestånd. Björn Lundén Information ska då bereda
                    Entreprenören möjlighet att spara ner alla Händelser och annan relevant kommunikation i
                    Tjänsten innan Kontot upphör.</h2>
                  </li>
                </ol>
              </li>
              <li>
                <h1>Behandling av personuppgifter</h1>
                <ol>
                  <li><h2>Tjänsten innebär att Björn Lundén Information på uppdrag av Entreprenören kan komma att
                    behandla och lagra viss information som Entreprenören laddar upp i Tjänsten (”Personuppgifter”).
                    Entreprenören är personuppgiftsansvarig för behandlingen av Personuppgifter och ansvarar således
                    för att all behandling sker i enlighet med bestämmelserna i personuppgiftslagen (1998:204).
                    Entreprenören bibehåller äganderätt till Personuppgifter.</h2>
                  </li>
                  <li><h2>Entreprenören garanterar att Entreprenören har rätt att behandla Personuppgifter i Tjänsten
                    och att denne har inhämtat samtycke från de som Personuppgifterna avser eller annars har laglig
                    grund att behandla dessa Personuppgifter.</h2>
                  </li>
                  <li><h2>Entreprenören har, om tillämpligt, meddelat ansvarig myndighet om behandlingen av Personuppgifter.</h2>
                  </li>
                  <li><h2>Entreprenören följer all relevant lag, förordning och myndighetsföreskrifter som gäller för
                    behandling av Personuppgifter vid användning av Tjänsten.</h2>
                  </li>
                  <li><h2>Björn Lundén Information är personuppgiftsbiträde för Entreprenörens behandling av
                    Personuppgifter och accepterar att endast behandla Personuppgifter i enlighet med Entreprenörens
                    skäliga instruktioner, tillämplig lag, förordning och myndighetsföreskrifter för behandling av
                    personuppgifter.</h2>
                  </li>
                  <li><h2>Björn Lundén Information har implementerat sådana tekniska och organisatoriska
                    säkerhetsåtgärder som avses i 31 § personuppgiftslagen.</h2>
                  </li>
                  <li><h2>Björn Lundén Information kan komma att överföra Personuppgifter till tredje parter och ska
                    därvid ha rätt att anlita underbiträden, under förutsättning att skriftliga avtal upprättas mellan
                    Björn Lundén Information och underbiträden.</h2>
                  </li>
                </ol>
              </li>
              <li>
                <h1>Meddelanden</h1>
                <ol>
                  <li><h2>Meddelanden mellan Björn Lundén Information och Entreprenören avfattas på svenska och sänds
                    via e-post till den av Entreprenören angivna kontaktpersonen (som Entreprenören anger i Kontot).
                    Meddelanden ska anses ha kommit den andra parten tillhanda vid vederbörlig bekräftelse på
                    e-postmeddelandet eller senast påföljande dag.</h2>
                  </li>
                </ol>
              </li>
              <li>
                <h1>Överlåtelse av förpliktelser enligt Villkoren</h1>
                <ol>
                  <li><h2>Entreprenören har inte rätt att överlåta sitt Konto eller rättigheter eller skyldigheter
                    kopplade till Tjänsten till annan utan Björn Lundén Informations föregående skriftliga godkännande.
                    Björn Lundén Information har rätt att helt eller delvis överlåta rättigheter och skyldigheter
                    enligt Villkoren till annan part utan Entreprenörens samtycke.</h2>
                  </li>
                </ol>
              </li>
              <li>
                <h1>Tillägg och ändringar i Villkoren</h1>
                <ol>
                  <li><h2>Björn Lundén Information har rätt att ändra Villkoren. Sådana ändringar kommer vara påsvenska.</h2>
                  </li>
                  <li><h2>Björn Lundén Information kommer att meddela Entreprenören om eventuella förändringar i dessa
                    Villkor via e-post till den kontaktperson som Entreprenören anger i Kontot eller på Hemsidan eller
                    i Applikationen. Om Entreprenören inte accepterar ändringar i Villkoren har denne rätt att omgående
                    avsluta Kontot enligt vad som anges i 15.2. Om Entreprenören inte säger upp sitt Konto före den dag
                    ändringarna träder i kraft, anses Entreprenören ha godkänt dem.</h2>
                  </li>
                  <li><h2>Björn Lundén Information har alltid rätt att med omedelbar verkan vidta de ändringar som
                    föranleds av lag, förordning eller myndighetsbeslut.</h2>
                  </li>
                  <li><h2>Svensk lag tillämpas på dessa Villkor. Tvist mellan parterna ska i första hand lösas genom
                    ett trevligt möte på en trevlig plats och därefter genom förhandling mellan parterna och i sista
                    hand av allmän domstol i Stockholm, Sverige.</h2>
                  </li>
                </ol>
              </li>
            </ol>
          </div>
        </div>
      </div>
    )
  }

  render () {
    const greyLabelOptions = getGreyLabelOptions()
    if (greyLabelOptions.greyLabel === 'blinfo') {
      return this.digitalaForetagetTerms
    } else if (!greyLabelOptions.greyLabel) {
      return this.mrShoeboxTerms
    } else {
      return null
    }
  }
}
/* eslint-enable */
