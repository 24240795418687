import React from 'react'
import PropTypes from 'prop-types'

import Tabs from 'shared/components/Tabs/Tabs'

// Component injects props from redux-form Field component.
// TODO: when migration from redux-form-5 is finished, refactor Tabs component and remove this one.
const FormTabs = ({input, meta, ...props}) =>
  <Tabs {...input} {...meta} {...props} ref={props.customRef} />

FormTabs.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  customRef: PropTypes.object
}

export default FormTabs
