import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { routerActions } from 'react-router-redux'
import { reduxForm } from 'redux-form'

import { getValidatedFields, startOnboarding, createOnboardingRequest } from 'MarketPlace/Creditsafe/actions'

export function withHandlersAndTranslations (WrappedComponent) {
  class Component extends React.Component {
    async componentDidMount () {
      const { location: { query }, createOnboardingRequest } = this.props
      this.props.getValidatedFields()
      if (query.save_card_success === 'true') createOnboardingRequest()
    }

    render () {
      return <WrappedComponent {...this.props} />
    }
  }

  Component.propTypes = {
    createOnboardingRequest: PropTypes.func,
    getValidatedFields: PropTypes.func,
    location: PropTypes.object,
    redirectToCreditsafePortal: PropTypes.func
  }

  const mapStateToProps = (state) => {
    const { initialValues } = state.marketplace.creditsafe.validatedFields
    return {
      initialValues,
      t: state.i18n.get('app', 'views', 'MarketPlace', 'creditsafeOnboarding')
    }
  }

  const mapDispatchToProps = (dispatch) => ({
    goBack: () => dispatch(routerActions.go(-1)),
    getValidatedFields: () => dispatch(getValidatedFields()),
    startOnboarding: (values) => dispatch(startOnboarding(values)),
    createOnboardingRequest: () => dispatch(createOnboardingRequest())
  })

  const reduxFormHOC = reduxForm({
    form: 'creditsafe-onboarding-form',
    enableReinitialize: true
  })

  return connect(mapStateToProps, mapDispatchToProps)(reduxFormHOC(Component))
}
