import React, { Component } from 'react'
import PropTypes from 'prop-types'
import FormInput from 'shared/components/FormInput'
import Input from 'shared/components/Input/Input'
import AutoSuggest from 'react-autosuggest'

import styles from './styles.scss'

const renderInputComponent = (inputProps) => {
  // TODO: Remove when redux-form update is done. Need this for back compatibility with redux-form-5.
  // Remove conditions for onChange, onBlur in SuggestInput component too.
  return inputProps.input ? <FormInput {...inputProps} /> : <Input {...inputProps} />
}

class SuggestInput extends Component {
  static propTypes = {
    getSuggestions: PropTypes.func.isRequired,
    getInputValue: PropTypes.func.isRequired,
    renderSuggestion: PropTypes.func.isRequired,
    inputProps: PropTypes.object.isRequired,
    onSuggestionSelected: PropTypes.func,
    onSuggestionsFetchRequested: PropTypes.func,
    onSuggestionsClearRequested: PropTypes.func
  }

  static defaultProps = {
    onSuggestionsFetchRequested: () => {},
    onSuggestionsClearRequested: () => {}
  }

  renderSuggestion = (...args) => {
    return (
      <div>
        { this.props.renderSuggestion(...args) }
      </div>
    )
  }

  getSuggestions = () => {
    const { getSuggestions, inputProps: { value } } = this.props

    return getSuggestions(value)
  }

  get inputProps () {
    const { inputProps } = this.props
    const onChange = (event, { newValue }) => {
      inputProps.input ? inputProps.input.onChange(newValue) : inputProps.onChange(newValue)
    }
    const onBlur = (event, { newValue, highlightedSuggestion }) => {
      inputProps.input ? inputProps.input.onBlur(newValue, highlightedSuggestion) : inputProps.onBlur(newValue, highlightedSuggestion)
    }

    return {
      ...inputProps,
      value: inputProps.input ? inputProps.input.value || '' : inputProps.value || '',
      onChange,
      onBlur
    }
  }

  render () {
    return (
      <AutoSuggest
        theme={styles}
        suggestions={this.getSuggestions()}
        onSuggestionsFetchRequested={(...props) => { this.forceUpdate(); this.props.onSuggestionsFetchRequested(...props) }}
        onSuggestionSelected={this.props.onSuggestionSelected}
        getSuggestionValue={this.props.getInputValue}
        renderSuggestion={this.renderSuggestion}
        focusInputOnSuggestionClick={false}
        renderInputComponent={renderInputComponent}
        inputProps={this.inputProps}
        onSuggestionsClearRequested={this.props.onSuggestionsClearRequested}
      />
    )
  }
}

export default SuggestInput
