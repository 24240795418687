import PropTypes from 'prop-types'
import React from 'react'

// Components
import CheckButton from '../../../../../../shared/components/CheckButton/CheckButton'
import FormatNumber from '../../../../../../shared/components/FormatNumber/FormatNumber'

export default function InvoiceRow (props) {
  const { description, price, sumVat, sumExVat, currencyName, isChecked } = props.data
  const t = props.t('shared')

  return (
    <div className={`form-rows__item create-invoice__row create-invoice__row__info pointer ${props.index === 0 ? 'create-invoice__row--first' : ''}`}>
      <div className='create-invoice__row__info'>
        <h3>{description}&nbsp;</h3>
        <table>
          <tbody>
            <tr>
              <td>{t('specification', 'perUnit').s}:</td>
              <td><h3><FormatNumber value={price} /></h3></td>
            </tr>
            <tr>
              <td>{t('specification', 'vat').s}:</td>
              <td><h3><FormatNumber value={sumVat} />&nbsp;{currencyName}</h3></td>
            </tr>
            <tr>
              <td>{t('specification', 'total').s}:</td>
              <td><h3><FormatNumber value={sumExVat + sumVat} />&nbsp;{currencyName}</h3></td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className='form-rows__item__right-button'>
        <CheckButton value={isChecked} />
      </div>
    </div>
  )
}

InvoiceRow.prototype.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  index: PropTypes.number,
  t: PropTypes.func
}
