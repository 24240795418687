import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getFormValues } from 'redux-form'

import Info from 'Overview/PnlReports/components/Info/Info'
import Dates from 'Overview/Overview/components/Dates'

import { loadOverviewSettingsResultInfo, loadOverviewFiscalYearsAction } from 'Overview/actions/resultStats'

class CommonPnlReports extends Component {
  static propTypes = {
    dates: PropTypes.object,
    eventStatus: PropTypes.string,
    type: PropTypes.string,
    loadFiscalYears: PropTypes.func,
    saveDates: PropTypes.func,
    settings: PropTypes.object,
    tViews: PropTypes.func
  }

  componentDidMount () {
    this.props.loadFiscalYears()
  }

  render () {
    const {dates, eventStatus, tViews, settings, saveDates, type} = this.props
    return (
      <React.Fragment>
        <Dates settings={settings} type={type} onSaveClick={saveDates} />
        <Info t={tViews('PnlReports')} dates={dates} eventStatus={eventStatus} />
      </React.Fragment>
    )
  }
}

function mapStateToProps (state, ownProps) {
  const { location: { query: { type = 'result' } } } = ownProps
  const isResultType = type === 'result'
  const settings = ownProps.settings
  const values = getFormValues('overviewSettings')(state) || {}
  if (!isResultType) {
    const defaults = settings.forecast || {}
    settings.forecast = { from: values.from || defaults.from, to: values.to || defaults.to }
  }
  settings.type = type
  // show only approved for 'result'
  const eventStatus = isResultType ? '2' : (values.eventStatus || '0')
  return {
    dates: isResultType ? settings.result : settings.forecast,
    type,
    settings,
    eventStatus
  }
}

function mapDispatchToProps (dispatch, ownProps) {
  return {
    loadFiscalYears: () => { dispatch(loadOverviewFiscalYearsAction()) },
    saveDates: ownProps.type === 'forecast' ? () => {} : (data) => { dispatch(loadOverviewSettingsResultInfo(data)) }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CommonPnlReports)
