import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { reduxForm, Field, change, getFormMeta, formValueSelector, reset } from 'redux-form'
import classnames from 'classnames'

import SearchField from '../../../../shared/components/FormSearchField'
import Dropdown from '../../../../shared/components/FormDropdown'
import SortButton from '../../../../Events/Events/components/SortButton/SortButton'

export class SuppliersListFilters extends Component {
  static propTypes = {
    orderType: PropTypes.string,
    meta: PropTypes.object,
    canBeHidden: PropTypes.bool,
    dirty: PropTypes.bool,
    resetForm: PropTypes.func,
    t: PropTypes.func,
    changeOrderType: PropTypes.func
  }

  render () {
    const { orderType, canBeHidden, t, changeOrderType, meta, resetForm } = this.props

    if (meta.search && !meta.search.visited && canBeHidden) {
      return <div />
    }

    const filterClassNames = classnames([
      'filter', 'filter-wrap', 'filter--without-fixed-height', 'filter--open'
    ])

    return (
      <div className={filterClassNames}>
        <div className='filter__main-part'>
          <div className='filter__multi-row filter__multi-row--small-margin'>
            <div>
              <Field
                name='orderBy'
                component={Dropdown}
                options={[
                  {label: t('orderBy', 'name').s, value: 'name'},
                  {label: t('orderBy', 'supplierNumber').s, value: 'supplier_number'}
                ]}
              />
            </div>
            <div className='filter__multi-row'>
              <div>
                <SortButton type='desc' value={orderType} onClick={() => changeOrderType('desc')} />
              </div>
              <div>
                <SortButton type='asc' value={orderType} onClick={() => changeOrderType('asc')} />
              </div>
            </div>
          </div>
          <Field
            name='search'
            component={SearchField}
            changeAfterBlur
            placeholder={t('search').s}
          />
        </div>

        <div style={{height: '5rem'}} className='filter__bottom filter__bottom--without-right-padding'>
          <div className={`filter__clear ${this.props.dirty ? '' : 'filter__clear--hidden'}`} onClick={() => resetForm()}>
            <span className='icon-cross' />
            <span className='filter__clear__text'>{t('buttons', 'clearFilters').s}</span>
          </div>
        </div>
      </div>
    )
  }
}

export const defaultFilterValues = {
  search: '',
  orderBy: 'name',
  orderType: 'asc'
}

const formName = 'suppliersListFilters'
const mapStateToProps = (state) => ({
  t: state.i18n.get('app', 'views', 'Suppliers', 'SuppliersView', 'filters'),
  orderType: formValueSelector(formName)(state, 'orderType'),
  meta: getFormMeta(formName)(state)
})

const mapDispatchToProps = (dispatch) => ({
  changeOrderType: (value) => dispatch(change(formName, 'orderType', value)),
  resetForm: () => dispatch(reset(formName))
})

const connectedFilters = connect(mapStateToProps, mapDispatchToProps)(SuppliersListFilters)

export default reduxForm({
  form: formName,
  initialValues: defaultFilterValues,
  destroyOnUnmount: false
})(connectedFilters)
