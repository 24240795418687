import './AnnualReportView.scss?global'

import Smartphones from 'Reports/views/AnnualReport/AnnualReportView/containers/Smartphones'
import Tablets from 'Reports/views/AnnualReport/AnnualReportView/containers/Tablets'

import {getCurrentDeviceView} from 'shared/helpers/utils'

const AnnualReportView = getCurrentDeviceView(Smartphones, Tablets)

export default AnnualReportView
