import URI from 'urijs'

import {API_BASE} from 'shared/constants/Api'

export const EVENT_CREATE = (params) => { return URI(`${API_BASE}/api/v2/source_documents/`).addSearch(params) }
export const EVENT_UPDATE = ({id, ...params}) => { return URI(`${API_BASE}/api/v2/source_documents/${id}/`).addSearch(params) }
export const EVENTS_LIST = (params) => { return URI(`${API_BASE}/api/v2/source_documents/`).addSearch(params) }
export const EVENT_INFO = ({id, ...params}) => { return URI(`${API_BASE}/api/v2/source_documents/${id}/`).addSearch(params) }
export const EVENT_MESSAGES = ({...params}) => { return URI(`${API_BASE}/api/v2/comments/`).addSearch(params) }
export const EVENT_MESSAGES_UPDATE = () => { return URI(`${API_BASE}/api/v2/comments/`) }
export const EVENT_ROWS = `${API_BASE}/api/transaction_row/`
export const REPRESENTATION_GUESTS = `${API_BASE}/api/v2/representation_guests/`
export const EVENTS_AUTOCOMPLETE = `${API_BASE}/api/v2/source_documents/autocomplete/`
export const EVENT_EXTENDED_INFO = ({id}) => `${API_BASE}/api/v2/source_documents/${id}/extended_info/`

// transactions also
export const TRANSACTION_CREATE = (params) => { return URI(`${API_BASE}/api/v2/transactions/`).addSearch(params) }
export const TRANSACTIONS_LIST = (params) => { return URI(`${API_BASE}/api/v2/transactions/`).addSearch(params) }
export const TRANSACTION_UPDATE = ({id, ...params}) => { return URI(`${API_BASE}/api/v2/transactions/${id}/`).addSearch(params) }
