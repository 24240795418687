import React from 'react'
import PropTypes from 'prop-types'

import { withHandlersAndTranslations } from './Common'
import IpadNavigation from 'Navigation/IpadNavigation'
import More from 'More/views/MoreView/containers/Smartphones'
import { CreditsafeCollaboration } from 'MarketPlace/Creditsafe/views/CreditsafeCollaborationView/containers/Smartphones'

const CreditsafeCollaborationView = (props) => {
  const navBarTitle = props.t('creditsafeCollaboration', 'title').s
  return (
    <IpadNavigation title={navBarTitle} location={props.location} withoutMaxWidth>
      <More notRightIcon isIpad />
      <CreditsafeCollaboration {...props} />
    </IpadNavigation>
  )
}

CreditsafeCollaborationView.propTypes = {
  t: PropTypes.func,
  location: PropTypes.object.isRequired,
  navBarTitle: PropTypes.string
}

export default withHandlersAndTranslations(CreditsafeCollaborationView)
