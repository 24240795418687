import isMobile from 'ismobilejs'
import { browserHistory } from 'react-router'
import { isIOSBridge } from 'appBridge/bridge'

// Helpers
import getGreyLabelOptions from 'GreyLabel/helpers/greyLabel'

export function openBankIDApp (autostartURL, appInfo) {
  if (isMobile.android.device) {
    const params = autostartURL.split('///')[1]
    const link = `intent:///${params}#Intent;scheme=bankid;package=com.bankid.bus;end`
    window.location.href = link

    // app opening doesnt work without HTTPS on android -_-
    if (__DEV__) {
      browserHistory.push(`/bankid_wait?startURL=${encodeURIComponent(autostartURL)}`)
    }
  } else if (isIOSBridge() && Number(appInfo.wrapperBuild) >= 9990) {
    // change url from https://github.com/ShoeboxSolutionsAB/zignsec_connector/blob/075b27ca48b26333e6ecf492d14c79d89c5cecde/lib/zignsec_connector/bankidse/authenticate.rb#L24
    // universal link only for app, so redirects from site login will not open in the app
    window.location = autostartURL.replace('redirect=null', `redirect=${document.location.href}`)
  } else {
    // opened in desktop or mobile browser - opens desktop or mobile app
    window.location = autostartURL
  }
}

export function getBackgroundPath () {
  switch (getGreyLabelOptions().whiteLabel) {
    case 'slipp':
      return 'backgrounds/slipp-bg.jpg'
    default:
      return 'backgrounds/bg2.jpeg'
  }
}
