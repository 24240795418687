import React, {Component} from 'react'

import Customers from './Smartphones'
import IpadNavigation from 'Navigation/IpadNavigation'

export default class CustomersView extends Component {
  render () {
    return (
      <IpadNavigation {...this.props} onlyLeftSide setCurrentLink>
        <Customers {...this.props} />
      </IpadNavigation>
    )
  }
}
