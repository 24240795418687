import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import Icon from 'shared/components/Icon'
import Button from 'shared/components/Button/Button'

import * as onboardingStatuses from 'MarketPlace/Collector/constants/onboardingStatuses'

import { openCollectorPortal } from 'MarketPlace/Collector/helpers'
import './styles.scss?global'

class OnboardingStatus extends Component {
  static propTypes = {
    status: PropTypes.string.isRequired
  }

  getStatusIcon () {
    const { status } = this.props
    let icon
    switch (status) {
      case onboardingStatuses.OPEN:
        icon = 'open-status-v2'
        break
      case onboardingStatuses.AUDIT:
        icon = 'pending-status-v2'
        break
      case onboardingStatuses.APPROVED:
        icon = 'pending-status-v2'
        break
      case onboardingStatuses.DENIED:
        icon = 'cancelled-status-v2'
        break
      case onboardingStatuses.SIGNED:
        icon = 'pending-status-v2'
        break
      case onboardingStatuses.ONBOARDED:
        icon = 'closed-status-v2'
        break
      default:
        icon = 'pending-status-v2'
        break
    }
    return icon
  }

  render () {
    const { t, status } = this.props
    return (
      <div className='onboarding-status'>
        <div>
          <Icon type={this.getStatusIcon()} />
          <span>{t(`${status}`).s}</span>
        </div>
        <Button view={'transparent-white'} onClick={() => openCollectorPortal()}>Till Collectors portal</Button>
      </div>
    )
  }
}

function mapStateToProps (state) {
  return {
    t: state.i18n.get('app', 'views', 'Collector', 'OnboardingStatuses')
  }
}

export default connect(mapStateToProps)(OnboardingStatus)
