import './PreventLandscape.scss?global'
import React, {Component} from 'react'

import {isIpadResolution} from 'shared/helpers/utils'

export default class PreventLandscape extends Component {
  constructor (props) {
    super(props)
    this.state = {show: false}
  }
  touchHandler (e) {
    e.preventDefault()
  }
  isMobile () {
    const ua = navigator.userAgent.toLowerCase()
    const regexp = /android|iphone|ipod|iemobile|blackberry/g
    return regexp.exec(ua)
  }
  lockScroll () {
    window.addEventListener('touchmove', this.touchHandler, true)
  }
  unLockScroll () {
    window.removeEventListener('touchmove', this.touchHandler, true)
  }
  componentDidMount () {
    if (!isIpadResolution()) {
      window.addEventListener('orientationchange', () => {
        const orientation = Number(window.orientation)
        if (this.isMobile() && orientation && (orientation === 90 || orientation === -90)) {
          this.setState({show: true})
          this.lockScroll()
        } else {
          this.setState({show: false})
          this.unLockScroll()
        }
      }, false)
    }
  }
  render () {
    const {show} = this.state
    return (
      <div>
        {show && (
          <div id='landscape'>
            <span className='landscape__icon icon-rotate' />
            <div className='landscape__text'>Rotate Device to Portrait</div>
          </div>)}
      </div>
    )
  }
}
