import { bind } from 'redux-effects'
import { fetch2 as fetch } from 'shared/helpers/fetch'
import { createAction } from 'redux-actions'
import { showNavbarSpinnerAction, hideNavbarSpinnerAction } from 'shared/actions/navbarSpinner'

// Constants
import { EVENT_ROWS } from 'Events/shared/constants/Api'
import { EVENTS_LIST_UPDATE } from 'Events/shared/constants/Event'

// Helpers
import { getEventPlusMinusProperty } from 'Events/shared/helpers/eventType'

// Actions
const updateEventsList = createAction(EVENTS_LIST_UPDATE)

// TODO maybe delete it? Used only on the page /events/specific, but seems that the page
// is not used anywhere
export function loadEventRowsAction (requestParams, pageToLoad) {
  return (dispatch, getState) => {
    const list = pageToLoad === 1 ? [] : getState().events.data

    return dispatch([
      showNavbarSpinnerAction(),
      bind(fetchEventRows(requestParams, pageToLoad), updateList.bind(this, list))
    ])
  }
}

function fetchEventRows (requestParams, page) {
  const params = {
    p: page,
    project_id: requestParams.projectId,
    category_id: requestParams.categoryId,
    transaction_type: requestParams.eventType,
    date_from: requestParams.dateFrom,
    date_to: requestParams.dateTo,
    order_type: 'desc',
    order_by: 'transaction__user_transaction_id',
    'values[]': [
      'transaction__user_transaction_id',
      'transaction__date_of_transaction',
      'transaction__transaction_type',
      'transaction__id',
      'description',
      'amount',
      'debit_account__name',
      'credit_account__name'
    ]
  }
  return fetch(EVENT_ROWS, params)
}

function updateList (initialList, response) {
  const events = response.value.results
  const newList = initialList.concat(events.map((event) => ({
    id: event.transaction__id,
    title: event.description,
    amount: event.amount,
    number: event.transaction__user_transaction_id,
    expenseType: getEventPlusMinusProperty(event.transaction__transaction_type),
    date: event.transaction__date_of_transaction,
    category: event.credit_account__name || event.debit_account__name
  })))

  return [updateEventsList({data: newList, hasNextPage: response.value.has_next, lastPageLoaded: response.value.number}), hideNavbarSpinnerAction()]
}
