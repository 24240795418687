import React, {Component} from 'react'

import IpadNavigation from 'Navigation/IpadNavigation'
import TodoUnreadEvents from 'Todo/views/TodoUnreadEventsView/containers/Smartphones'

export default class TodoUnreadEventsView extends Component {
  render () {
    return (
      <IpadNavigation {...this.props} onlyLeftSide setCurrentLink>
        <TodoUnreadEvents {...this.props} />
      </IpadNavigation>
    )
  }
}
