import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import Navigation from 'Navigation/Navigation'
import KivraUserForm from 'Integrations/Kivra/components/KivraUserForm'

import { getValidateClientFunc, startIntegration } from 'Integrations/Kivra/actions'

const KivraOnboardingView = ({ validateClient, ...props }) => {
  useEffect(() => { validateClient() }, [validateClient])
  return (
    <KivraUserForm {...props} />
  )
}

KivraOnboardingView.propTypes = {
  validateClient: PropTypes.func.isRequired
}

const KivraOnboardingViewWithNavigation = (props) =>
  <Navigation hideFooter title='Kivra' notBottomSpace>
    <KivraOnboardingView {...props} />
  </Navigation>

const mapStateToProps = (state) => ({
  initialValues: state.integrations.kivra.validatedFields.initialValues,
  texts: {
    description: state.i18n.get('app', 'views', 'Integrations', 'Kivra', 'userRegistration', 'description').s,
    header: state.i18n.get('app', 'views', 'Integrations', 'Kivra', 'userRegistration', 'header').s,
    tFields: state.i18n.get('app', 'views', 'Integrations', 'Kivra', 'fields'),
    tButtons: state.i18n.get('app', 'views', 'Integrations', 'Kivra', 'userRegistration', 'buttons')
  }
})

const mapDispatchToProps = (dispatch) => ({
  validateClient: () => dispatch(getValidateClientFunc()),
  startIntegration: (values) => dispatch(startIntegration(values))
})

export const connectedKivraOnboarding = connect(mapStateToProps, mapDispatchToProps)(KivraOnboardingView)
export default connect(mapStateToProps, mapDispatchToProps)(KivraOnboardingViewWithNavigation)
