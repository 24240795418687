import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { omit } from 'lodash'
import { routerActions } from 'react-router-redux'
import { reduxForm, stopSubmit, touch } from 'redux-form'

import Navigation from 'Navigation/Navigation'
import OnboardingForm from 'MarketPlace/Capcito/views/CapcitoOnboardingView/components/OnboardingForm'

import { getValidatedFields, startOnboarding } from 'MarketPlace/Capcito/actions/capcito'

class CapcitoOnboardingView extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    tSettings: PropTypes.func.isRequired,
    sendOnboardingRequest: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
    getValidatedFields: PropTypes.func.isRequired,
    setErrors: PropTypes.func.isRequired,
    makeFieldsTouched: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired
  }

  componentDidMount () {
    this.props.getValidatedFields()
  }

  render () {
    const props = omit(this.props, 'getValidatedFields')
    return <OnboardingForm {...props} />
  }
}

const CapcitoOnboardingWithNavigation = (props) => {
  return (
    <Navigation hideFooter onClickBack={props.goBack} title={props.t('title').s} notBottomSpace >
      <CapcitoOnboardingView {...props} />
    </Navigation>
  )
}

CapcitoOnboardingWithNavigation.propTypes = {
  goBack: PropTypes.func.isRequired
}

const form = 'capcito-form'
const reduxFormHOC = reduxForm({
  form,
  enableReinitialize: true
})

const mapStateToProps = (state) => {
  const { initialValues } = state.marketplace.capcito.validatedFields
  return {
    initialValues,
    t: state.i18n.get('app', 'views', 'MarketPlace', 'capcitoOnboarding'),
    tSettings: state.i18n.get('app', 'views', 'MarketPlace', 'common', 'fields')
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    sendOnboardingRequest: (values) => dispatch(startOnboarding(values)),
    goBack: () => dispatch(routerActions.go(-1)),
    getValidatedFields: () => dispatch(getValidatedFields()),
    setErrors: (errors) => dispatch(stopSubmit(form, errors)),
    makeFieldsTouched: (errors) => dispatch(touch(form, ...Object.keys(errors)))
  }
}

export const CapcitoOnboarding = connect(mapStateToProps, mapDispatchToProps)(reduxFormHOC(CapcitoOnboardingView))
export default connect(mapStateToProps, mapDispatchToProps)(reduxFormHOC(CapcitoOnboardingWithNavigation))
