import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { reduxForm, Field } from 'redux-form'
import { connect } from 'react-redux'
import { isEmpty } from 'lodash'
import { createSelect } from 'shared/factories/createSelect'

// Components
import Navigation from 'Navigation/Navigation'
import Button from 'shared/components/Button/Button'
import DropDown from 'shared/components/FormDropdown'
import Input from 'shared/components/FormInput'
import ConfirmationMessageBox from 'MessageBox/components/ConfirmationMessageBox/ConfirmationMessageBox'

// Actions
import { setDefaultsAction, pushArticleAction, clearArticlePropsAction, loadArticleAction, deleteArticleAction, pushArticleForInvoiceAction } from 'Articles/actions/article'

// Constants
import { ARTICLE_TYPE_GOODS, ARTICLE_TYPE_SERVICE } from 'Articles/constants/ArticleTypes'
import { ARTICLE_UNIT_PIECE, ARTICLE_UNIT_HOUR } from 'Articles/constants/ArticleUnits'

// Helpers
import { normalizeNegativeValue } from 'shared/helpers/formNormalizers'

class ArticleForm extends Component {
  static propTypes = {
    article: PropTypes.object,
    t: PropTypes.func,
    pushInfo: PropTypes.func,
    handleSubmit: PropTypes.func,
    vatPercents: PropTypes.arrayOf(PropTypes.object),
    params: PropTypes.object
  }

  render () {
    const {
      article,
      t,
      handleSubmit,
      vatPercents,
      pushInfo
    } = this.props
    const articleTypeSelectValues = createSelect([ARTICLE_TYPE_GOODS, ARTICLE_TYPE_SERVICE], t('fields', 'selects', 'articleType'))
    const unitSelectValues = createSelect([ARTICLE_UNIT_PIECE, ARTICLE_UNIT_HOUR], t('fields', 'selects', 'unitName'))

    if (this.props.params.articleNumber !== 'new' && !this.props.article.id) {
      return null
    }

    if (this.props.params.articleNumber !== 'new' && !this.props.article.id) {
      return null
    }

    return (
      <div className='f-column'>
        <div className='form f-column-top'>
          <div className='form__item'>
            <Field
              name='name'
              placeholder={t('fields', 'name', 'title').s}
              component={Input}
            />
          </div>
        </div>
        <div className='form f-column-top'>
          <div className='form__item'>
            <Field
              name='articleNumber'
              placeholder={t('fields', 'articleNumber', 'title').s}
              component={Input}
            />
          </div>
        </div>
        <div className='form f-column-top'>
          <div className='form__item'>
            <Field
              name='price'
              placeholder={t('fields', 'price', 'title').s}
              component={Input}
              type='float'
              onChange={this.onPriceChange}
              normalize={normalizeNegativeValue}
            />
          </div>
        </div>
        <div className='form f-column-top'>
          <div className='form__item'>
            <Field
              name='articleType'
              big
              hint={t('fields', 'articleType', 'title').s}
              options={articleTypeSelectValues}
              component={DropDown}
            />
          </div>
        </div>
        <div className='form f-column-top'>
          <div className='form__item'>
            <Field
              name='unit'
              big
              hint={t('fields', 'unit', 'title').s}
              options={unitSelectValues}
              component={DropDown}
            />
          </div>
        </div>
        <div className='form f-column-top'>
          <div className='form__item'>
            <Field
              name='vatPercentage'
              big
              hint={t('fields', 'vatPercentage', 'title').s}
              options={vatPercents}
              component={DropDown}
            />
          </div>
        </div>
        <div className='f-column-bottom form-buttons'>
          <Button view='transparent-black' saveButton onClick={handleSubmit(pushInfo)}>{article.id ? t('buttons', 'update').s : t('buttons', 'save').s}</Button>
          <Button view='transparent-red' goBackButton>{t('buttons', 'cancel').s}</Button>
        </div>
      </div>
    )
  }
}

const fields = ['id', 'name', 'articleNumber', 'price', 'articleType', 'unit', 'vatPercentage']

function validate (values) {
  let errors = {}

  for (let key of fields) {
    if (!values[key] && key !== 'id') {
      errors[key] = true
    }
  }
  if (values.articleNumber && values.articleNumber.length > 18) {
    errors.articleNumber = true
  }

  return errors
}

const ArticleFormWrap = reduxForm({
  form: 'article',
  enableReinitialize: true,
  validate
})(ArticleForm)

export class Article extends Component {
  static propTypes = {
    article: PropTypes.object,
    params: PropTypes.object,
    loadArticle: PropTypes.func,
    setDefaults: PropTypes.func,
    deleteArticle: PropTypes.func,
    clearReducer: PropTypes.func,
    vatPercents: PropTypes.arrayOf(PropTypes.object)
  }

  componentDidMount () {
    const { vatPercents, params: { articleNumber }, article, loadArticle, setDefaults } = this.props
    if (articleNumber !== 'new' && isEmpty(article)) {
      loadArticle(articleNumber)
    } else if (articleNumber === 'new' && isEmpty(article)) {
      setDefaults(vatPercents)
    }
  }

  componentWillReceiveProps (props) {
    const newArticleNumber = props.params.articleNumber
    const newArticle = props.article
    const {params: { articleNumber }, loadArticle, clearReducer} = this.props

    if (newArticleNumber === 'new' && newArticleNumber !== articleNumber && !isEmpty(newArticle)) clearReducer()

    if (newArticleNumber !== 'new' && newArticleNumber !== articleNumber) {
      loadArticle(newArticleNumber)
    }
  }

  componentDidUpdate (prevProps) {
    const { articleNumber } = this.props.params
    const { article, setDefaults, vatPercents } = this.props
    if (articleNumber === 'new' && articleNumber !== prevProps.params.articleNumber && isEmpty(article)) setDefaults(vatPercents)
  }

  render () {
    const {deleteArticle} = this.props

    return (
      <div className='f-column' >
        <ArticleFormWrap {...this.props} />
        <ConfirmationMessageBox
          onYesClick={() => { deleteArticle() }}
          provideController={(c) => { this.modal = c }} />
      </div>
    )
  }
}

export class ArticleWithNavigation extends Component {
  static propTypes = {
    params: PropTypes.object,
    article: PropTypes.object,
    t: PropTypes.func
  }
  render () {
    const { article, t, params } = this.props
    const actions = article.id ? [{ icon: 'icon-trash', callback: () => { this.refs.article.modal.open() } }] : null
    const title = params.articleNumber !== 'new'
      ? (article.name || '')
      : t('navbar', 'title').s

    return (
      <Navigation title={title} actions={actions} hideFooter className='f-column'>
        <Article {...this.props} ref='article' />
      </Navigation>

    )
  }
}

function mapStateToProps (state) {
  return {
    vatPercents: (state.userSettings && state.userSettings.vatPercents) || [],
    article: state.articles.article,
    initialValues: state.articles.article,
    t: state.i18n.get('app', 'views', 'Articles', 'ArticleView')
  }
}

function mapDispatchToProps (dispatch, ownProps) {
  const { params: { articleNumber }, location: { query: { cameFrom } } } = ownProps

  let pushInfo
  if (cameFrom === 'invoice') {
    pushInfo = () => { dispatch(pushArticleForInvoiceAction(articleNumber)) }
  } else {
    pushInfo = () => { dispatch(pushArticleAction(articleNumber)) }
  }

  return {
    loadArticle: (id) => { dispatch(loadArticleAction(id)) },
    clearReducer: () => { dispatch(clearArticlePropsAction()) },
    pushInfo,
    setDefaults: (vatPercents) => { dispatch(setDefaultsAction(vatPercents)) },
    deleteArticle: () => { dispatch(deleteArticleAction(articleNumber)) }
  }
}

export const ArticleView = connect(mapStateToProps, mapDispatchToProps, null, { withRef: true })(Article)
export default connect(mapStateToProps, mapDispatchToProps)(ArticleWithNavigation)
