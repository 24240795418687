import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import Navigation from 'Navigation/Navigation'
import ListView from 'shared/components/ListView/ListView'

import InvoiceListItem from '../../../../shared/components/ListItem/ListItem'
import InvoiceListFilters from '../../../components/Filters/Filters'

import { extendListAction, reloadListAction } from '../../../actions/invoices'
import { invoiceClearAction } from '../../../actions/invoiceCreate'

export class InvoicesView extends Component {
  static propTypes = {
    invoices: PropTypes.arrayOf(PropTypes.object),
    extendList: PropTypes.func,
    reloadList: PropTypes.func,
    t: PropTypes.func,
    ipad: PropTypes.bool,
    invoiceClear: PropTypes.func
  };

  componentDidMount () {
    this.props.reloadList()
  }

  render () {
    const { invoices, extendList, t, ipad, invoiceClear } = this.props
    const actions = [
      {icon: 'icon-add', path: '/invoices/new', onClick: ipad ? invoiceClear : null}
    ]
    return (
      <Navigation title={t('navbar', 'title').s} actions={actions} backLink='/more/customer-invoices' forceBackLink filterBlock infinityScrollHandler={extendList} className='invoices-view'>
        <InvoiceListFilters />
        <ListView data={invoices} template={InvoiceListItem} emptyListOptions={{icon: 'icon-bills-full'}} />
      </Navigation>
    )
  }
}

function mapStateToProps (state) {
  return {
    invoices: state.invoices.customerInvoices.listData,
    t: state.i18n.get('app', 'views', 'Invoices', 'InvoicesView')
  }
}

function mapDispatchToProps (dispatch) {
  return {
    reloadList: () => { dispatch(reloadListAction()) },
    extendList: () => { dispatch(extendListAction()) },
    invoiceClear: () => { dispatch(invoiceClearAction()) }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InvoicesView)
