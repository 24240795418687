import {bind} from 'redux-effects'
import {createAction} from 'redux-actions'
import {fetch2 as fetch} from 'shared/helpers/fetch'
import { showNavbarSpinnerAction, hideNavbarSpinnerAction } from 'shared/actions/navbarSpinner'
import { isMrshoeboxRelease } from 'shared/helpers/releaseHelpers'
import { sortBy } from 'lodash'
import moment from 'moment'

import {
  OVERVIEW_SETTINGS_INFO_LOAD, OVERVIEW_SETTINGS_RESULT_INFO_LOAD,
  OVERVIEW_DATA_LOAD, OVERVIEW_TOTALS_SAVE
} from 'Overview/constants/ActionTypes'
import {
  RESULT_MONTHLY_AGGREGATE, RESULT_CASH_BANK_AGGREGATE, RESULT_AMOUNTS_AGGREGATE,
  RESULT_EXPENSES_LIST, RESULT_REVENUE_LIST, OVERVIEW_FISCAL_YEARS
} from 'Overview/constants/Api'
import {RESULT_TYPE} from 'Overview/constants/OverviewSettings'
import { loadLoanAction } from './loan'

const loadOverviewData = createAction(OVERVIEW_DATA_LOAD)
const updateOverviewChart = createAction(OVERVIEW_TOTALS_SAVE)
export const loadOverviewSettingsResultInfo = createAction(OVERVIEW_SETTINGS_RESULT_INFO_LOAD)
export const loadOverviewSettingsInfo = createAction(OVERVIEW_SETTINGS_INFO_LOAD)

export function getFiscalYears (processFunc) {
  return [
    showNavbarSpinnerAction(),
    bind(fetch(OVERVIEW_FISCAL_YEARS), getFiscalYearsProcess.bind(null, processFunc))
  ]
}

function getCurrentRangeFromFiscalYears (years) {
  let index
  let from
  let to
  years.forEach((year, i) => {
    if (moment(year.start, 'YYYY-MM-DD').isBefore(moment()) && moment(year.end, 'YYYY-MM-DD').isAfter(moment())) {
      index = i
    }
  })
  if (index || index === 0) {
    from = years[index].start
    to = moment().endOf('month').format('YYYY-MM-DD')
  } else {
    index = years.length - 1
    from = years[index].start
    to = years[index].end
  }
  return {index, from, to}
}

function getFiscalYearsProcess (processFunc, res) {
  const fiscalYears = res.value
  if (fiscalYears.length) {
    const actions = []
    const { index, from, to } = getCurrentRangeFromFiscalYears(fiscalYears)
    actions.push(loadOverviewSettingsResultInfo({fiscalYears, from, to, selectedYearIndex: index}))
    if (processFunc) actions.push(processFunc(from, to))
    return actions
  }
}

export function loadOverviewFiscalYearsAction () {
  return bind(fetch(OVERVIEW_FISCAL_YEARS), (res) => {
    return (dispatch) => {
      const fiscalYears = res.value
      const actions = []
      if (fiscalYears.length) {
        const { index, from, to } = getCurrentRangeFromFiscalYears(fiscalYears)
        actions.push(loadOverviewSettingsResultInfo({fiscalYears, from, to, selectedYearIndex: index}))
        actions.push(loadOverviewSettingsInfo({type: RESULT_TYPE}))
      } else {
        const from = moment().startOf('year').format('YYYY-MM-DD')
        const to = moment().endOf('year').format('YYYY-MM-DD')
        const defaultData = {
          income: 0,
          outcome: 0,
          difference: 0,
          detailedResults: [],
          cashBankResults: []
        }
        actions.push(loadOverviewData(defaultData))
        actions.push(hideNavbarSpinnerAction())
        actions.push(loadOverviewSettingsResultInfo({fiscalYears: [], from, to}))
        actions.push(loadOverviewSettingsInfo({type: RESULT_TYPE}))
      }
      return dispatch(actions)
    }
  })
}

export function getResultOverviewAction (formValues) {
  return (dispatch, getState) => {
    const fiscalYears = getState().overview.settings.result.fiscalYears
    if (!fiscalYears) {
      return dispatch([
        showNavbarSpinnerAction(),
        bind(fetch(OVERVIEW_FISCAL_YEARS), overviewFiscalYearsProcess),
        isMrshoeboxRelease() ? loadLoanAction() : null
      ])
    } else {
      const actions = []
      const data = formValues || getState().overview.settings.result
      let {selectedYearIndex, from, to} = data
      const dateParams = {from, to}
      if (fiscalYears.length) {
        actions.push(bind(fetch(RESULT_AMOUNTS_AGGREGATE, dateParams), loadResultInfoToState))
        actions.push(bind(fetch(RESULT_MONTHLY_AGGREGATE, dateParams), loadPeriodicResultInfoToState))
        actions.push(bind(fetch(RESULT_CASH_BANK_AGGREGATE, dateParams), loadCashBankInfoToState))
        actions.push(loadOverviewSettingsResultInfo({...dateParams, selectedYearIndex}))
        actions.push(loadOverviewSettingsInfo({type: RESULT_TYPE}))
      } else {
        const from = moment().startOf('year').format('YYYY-MM-DD')
        const to = moment().endOf('year').format('YYYY-MM-DD')
        const defaultData = {
          income: 0,
          outcome: 0,
          difference: 0,
          detailedResults: [],
          cashBankResults: []
        }
        actions.push(loadOverviewData(defaultData))
        actions.push(loadOverviewSettingsResultInfo({from, to}))
        actions.push(loadOverviewSettingsInfo({type: RESULT_TYPE}))
      }
      isMrshoeboxRelease() && loadLoanAction()
      return dispatch(actions)
    }
  }
}

function overviewFiscalYearsProcess (res) {
  return (dispatch) => {
    const fiscalYears = res.value
    const actions = []
    if (fiscalYears.length) {
      const { index, from, to } = getCurrentRangeFromFiscalYears(fiscalYears)
      const dateParams = {from, to}
      actions.push(loadOverviewSettingsResultInfo({fiscalYears, from, to, selectedYearIndex: index}))
      actions.push(loadOverviewSettingsInfo({type: RESULT_TYPE}))
      actions.push(bind(fetch(RESULT_AMOUNTS_AGGREGATE, dateParams), loadResultInfoToState))
      actions.push(bind(fetch(RESULT_MONTHLY_AGGREGATE, dateParams), loadPeriodicResultInfoToState))
      actions.push(bind(fetch(RESULT_CASH_BANK_AGGREGATE, dateParams), loadCashBankInfoToState))
    } else {
      const from = moment().startOf('year').format('YYYY-MM-DD')
      const to = moment().endOf('year').format('YYYY-MM-DD')
      const defaultData = {
        income: 0,
        outcome: 0,
        difference: 0,
        detailedResults: [],
        cashBankResults: []
      }
      actions.push(loadOverviewData(defaultData))
      actions.push(hideNavbarSpinnerAction())
      actions.push(loadOverviewSettingsResultInfo({fiscalYears: [], from, to}))
      actions.push(loadOverviewSettingsInfo({type: RESULT_TYPE}))
    }
    return dispatch(actions)
  }
}

function loadResultInfoToState (response) {
  const income = Number(response.value.revenue)
  const outcome = Number(response.value.expenses)
  const diff = income - outcome

  const data = {
    income,
    outcome,
    difference: diff
  }

  return [
    hideNavbarSpinnerAction(),
    loadOverviewData(data)
  ]
}

function loadCashBankInfoToState (response) {
  const cashBankResults = Object.keys(response.value).map((i) => {
    return { date: i, difference: Math.round(response.value[i] * 100) / 100 }
  })
  return loadOverviewData({cashBankResults})
}

export function prepareMonthlyResults (months) {
  let detailedResults = []
  months.forEach((result, i) => {
    let revenue
    let expense
    if (i === 0) {
      revenue = Number(result.revenue)
      expense = Number(result.expense)
    } else {
      revenue = Number(result.revenue) + Number(detailedResults[i - 1].revenue)
      expense = Number(result.expense) + Number(detailedResults[i - 1].expense)
    }
    detailedResults.push({
      date: result.month,
      revenue: Math.round(revenue * 100) / 100,
      expense: Math.round(expense * 100) / 100
    })
  })
  return detailedResults
}

function loadPeriodicResultInfoToState ({value = []}) {
  return loadOverviewData({detailedResults: prepareMonthlyResults(value), monthlyResults: value})
}

export function getFiscalYearsAndChartDataAction (type) {
  return getFiscalYears((from, to) => loadResultOverviewChartDataAction(type, {from, to}))
}

export function loadResultOverviewChartDataAction (resultType, filterValues, customMonth) {
  return (dispatch, getState) => {
    const chartSettings = filterValues || getState().overview.settings.result
    const {from, to} = chartSettings
    let customDates
    if (!filterValues && customMonth) {
      customDates = {}
      const date = moment(customMonth, 'YYYY-MM')
      customDates.from = date.startOf('month').format('YYYY-MM-DD')
      customDates.to = date.endOf('month').format('YYYY-MM-DD')
    }
    const options = {
      from: customDates ? customDates.from : from,
      to: customDates ? customDates.to : to,
      type: resultType
    }
    return dispatch([
      showNavbarSpinnerAction(),
      loadOverviewSettingsResultInfo({...chartSettings, customDates}),
      bind(fetchResultOverviewChartData(options), loadResultOverviewChartDataToState)
    ])
  }
}

function fetchResultOverviewChartData (options) {
  let endpoint
  if (options.type === 'expenses') {
    endpoint = RESULT_EXPENSES_LIST
  } else {
    endpoint = RESULT_REVENUE_LIST
  }
  return fetch(endpoint, { from: options.from, to: options.to })
}

function loadResultOverviewChartDataToState (response) {
  return (dispatch, getState) => {
    const t = getState().i18n.get('app', 'views', 'OverviewView', 'statuses')
    const totalAmount = response.value.reduce((prev, curr) => {
      return prev + parseFloat(curr.sum || 0)
    }, 0)
    const financeRows = response.value.map((item) => {
      return {
        percent: Math.round((parseFloat(item.sum || 0) / totalAmount) * 100),
        categoryName: item.name || String(t('untagged')),
        categorySum: Number(item.sum),
        categoryId: item.account_id
      }
    })
    const sortedFinanceRows = sortBy(financeRows, (item) => item.categorySum).reverse()

    const info = {
      finance: sortedFinanceRows,
      totalAmount
    }

    return dispatch([updateOverviewChart(info), hideNavbarSpinnerAction()])
  }
}
