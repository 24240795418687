import React from 'react'
import PropTypes from 'prop-types'

import SearchField from 'shared/components/SearchField/SearchField'

// Component injects props from redux-form Field component.
// TODO: when migration from redux-form-5 is finished, refactor SearchField component and remove this one.
const FormSearchField = ({input, ...props}) =>
  <SearchField {...input} {...props} />

FormSearchField.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object
}

export default FormSearchField
