import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import IpadNavigation from 'Navigation/IpadNavigation'
import DocumentsView from 'Documents/views/DocumentsView/containers/Smartphones'
import {DocumentShowView} from '../containers/Smartphones'

import getMultipleActions from 'Documents/views/DocumentShowView/helpers/getMultipleActions'

import { showDeleteDocumentMessageBox } from 'Documents/actions/document'

export class Document extends Component {
  static propTypes = {
    document: PropTypes.object,
    t: PropTypes.func
  }

  render () {
    const {t, document} = this.props
    const navBarTitle = document.id ? t('titles', 'existing').s : t('titles', 'new').s
    const multipleActions = getMultipleActions(this.props)

    return (
      <IpadNavigation {...this.props} title={navBarTitle} multipleActions={multipleActions}>
        <DocumentsView {...this.props} />
        <DocumentShowView {...this.props} ref='document' />
      </IpadNavigation>
    )
  }
}

function mapStateToProps (state) {
  return {
    document: state.documents.document,
    t: state.i18n.get('app', 'views', 'CreateDocumentView')
  }
}

function mapDispatchToProps (dispatch, ownProps) {
  const { companyId, documentId } = ownProps.params

  return {
    showDeleteDocumentMessageBox: () => { dispatch(showDeleteDocumentMessageBox(companyId, documentId)) }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Document)
