import React from 'react'
import PropTypes from 'prop-types'

import './styles.scss?global'

const FormLayout = (props) =>
  <div className={`integrations-form-layout ${props.className}`}>
    {props.children}
  </div>

FormLayout.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
}

export default FormLayout
