import { bind } from 'redux-effects'
import { fetch2 as fetch } from 'shared/helpers/fetch'
import { createAction } from 'redux-actions'
import { omitBy, isUndefined } from 'lodash'
import { routerActions } from 'react-router-redux'
import { getFormValues } from 'redux-form'
import { showMessageBoxWithParamsAction } from 'MessageBox/actions/messageBox'

// Constants
import { ARTICLE_UPDATE_PROPS, ARTICLE_CLEAN } from 'Articles/constants/ActionTypes'
import { ARTICLE_INFO } from 'Articles/constants/Api'
import { ARTICLE_TYPE_GOODS } from 'Articles/constants/ArticleTypes'
import { ARTICLE_UNIT_PIECE } from 'Articles/constants/ArticleUnits'

// Helpers
import { transformArticle } from 'Articles/actions/articles'

// Actions
import { showNavbarSpinnerAction, hideNavbarSpinnerAction } from 'shared/actions/navbarSpinner'
import { importArticleRowAction } from 'Invoices/CustomerInvoices/actions/imports'

const updateArticleProps = createAction(ARTICLE_UPDATE_PROPS)
export const clearArticlePropsAction = createAction(ARTICLE_CLEAN)

export function updateArticlePropsAction (changes) {
  const newChanges = omitBy({
    ...changes,
    vatPercentageName: changes.vatPercentage && `${changes.vatPercentage}%`
  }, isUndefined)

  return updateArticleProps(newChanges)
}

export function loadArticleAction (articleNumber) {
  return [
    showNavbarSpinnerAction(),
    bind(fetch(`${ARTICLE_INFO}${articleNumber}/`), updateArticlePropsAfterLoad)
  ]
}

function updateArticlePropsAfterLoad (response) {
  return [updateArticlePropsAction(transformArticle(response.value)), hideNavbarSpinnerAction()]
}

export function deleteArticleAction (articleNumber) {
  return [
    showNavbarSpinnerAction(),
    bind(deleteArticle(articleNumber), () => [routerActions.replace('/articles'), clearArticlePropsAction()])
  ]
}

function deleteArticle (articleNumber) {
  return fetch(`${ARTICLE_INFO}${articleNumber}/`, {}, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function pushArticleAction (articleNumber) {
  return (dispatch, getState) => {
    const article = getFormValues('article')(getState())

    return dispatch([
      showNavbarSpinnerAction(),
      bind(pushArticle(articleNumber, article), checkResponse.bind(this, afterSuccessfulPushActions), processDuplicate)
    ])
  }
}

function pushArticle (articleNumber, article) {
  const articleForRequest = {
    article: {
      inventory_number: article.articleNumber,
      name: article.name,
      unit: article.unit,
      price: article.price,
      article_type: article.articleType,
      VAT_percentage: article.vatPercentage
    }
  }
  const url = articleNumber === 'new' ? ARTICLE_INFO : ARTICLE_INFO + article.id
  const method = articleNumber === 'new' ? 'POST' : 'PATCH'

  return fetch(url, {}, {
    method: method,
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(articleForRequest)
  })
}

function checkResponse (onSuccess, response) {
  if (response.value.duplicate_entry) {
    return (dispatch, getState) => {
      const t = getState().i18n.get('app', 'views', 'Articles', 'ArticleView', 'messages')

      return dispatch([
        showMessageBoxWithParamsAction('', t('alreadyRegistered').s),
        hideNavbarSpinnerAction()
      ])
    }
  } else {
    return onSuccess(response)
  }
}

function afterSuccessfulPushActions () {
  return [hideNavbarSpinnerAction(), routerActions.replace('/articles'), clearArticlePropsAction()]
}

export function setDefaultsAction (vatPercents) {
  return updateArticlePropsAction({
    articleType: ARTICLE_TYPE_GOODS,
    unit: ARTICLE_UNIT_PIECE,
    vatPercentage: vatPercents ? vatPercents[0].value : '25'
  })
}

export function pushArticleForInvoiceAction (articleNumber) {
  return (dispatch, getState) => {
    const article = getFormValues('article')(getState())

    return dispatch([
      showNavbarSpinnerAction(),
      bind(pushArticle(articleNumber, article), checkResponse.bind(this, addArticleToInvoice), processDuplicate)
    ])
  }
}

function processDuplicate () {
  // TODO: add status code check
  return (dispatch, getState) => {
    const t = getState().i18n.get('app', 'views', 'Articles', 'ArticleView', 'messages')

    return dispatch([
      showMessageBoxWithParamsAction('', t('alreadyRegistered').s),
      hideNavbarSpinnerAction()
    ])
  }
}

function addArticleToInvoice (response) {
  return [
    importArticleRowAction([transformArticle(response.value.entity)]),
    hideNavbarSpinnerAction(),
    routerActions.go(-2),
    clearArticlePropsAction()
  ]
}
