import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'

import Navigation from 'Navigation/Navigation'
import ListView from 'shared/components/ListView/ListView'
import IntegrationsListItem from '../components/IntegrationsListItem'

import withRequests from './withRequestsHOC'

class TodoIntegrations extends Component {
  static propTypes = {
    integrations: PropTypes.array
  }

  render () {
    const { t, integrations } = this.props
    return (
      <div className='todo-onboarding-wrapper'>
        <Navigation title={t('navbar', 'title').s} backLink={'/todo'} notBottomSpace >
          <Helmet>
            <meta
              name='viewport'
              content='width=640, maximum-scale=1.0, user-scalable=no'
            />
          </Helmet>
          <ListView
            data={integrations}
            template={IntegrationsListItem}
            emptyListOptions={{icon: 'icon-integrations'}}
          />
        </Navigation>
      </div>
    )
  }
}

export default withRequests(TodoIntegrations)
