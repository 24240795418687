import PropTypes from 'prop-types'
import React, { Component } from 'react'
import axios from 'api-client-connector'
import FullPDF from 'shared/components/FullPDF/FullPDF'
import isMobile from 'ismobilejs'

import Button from 'shared/components/Button/Button'
import DotsSpinner from 'shared/components/DotsSpinner'

import { PNL_REPORT } from 'Overview/constants/Api'

import styles from './styles.scss'

export default class Info extends Component {
  static propTypes = {
    dates: PropTypes.object,
    eventStatus: PropTypes.string,
    t: PropTypes.func
  }

  state = {}

  componentDidMount () {
    const { dates: { from, to }, eventStatus } = this.props
    this.updatePdf(from, to, eventStatus)
  }

  componentDidUpdate (prevProps) {
    const { dates: { from, to }, eventStatus } = this.props
    const { dates: { from: prevFrom, to: prevTo }, eventStatus: prevEventStatus } = prevProps
    if (from !== prevFrom || to !== prevTo || eventStatus !== prevEventStatus) {
      this.updatePdf(from, to, eventStatus)
    }
  }

  updatePdf = async (from, to, eventStatus = 0) => {
    if (!from || !to) return null
    this.setState({ file: null })
    const url = await this.getReport(from, to, eventStatus)
    this.setState({ file: url })
  }

  getReport = (from, to, eventStatus) => {
    return axios.post(PNL_REPORT, {
      from,
      to,
      format: 'pdf',
      transaction_status: eventStatus
    }).then(res => res.data.pdf)
  }

  render () {
    const { t } = this.props

    if (!this.state.file) return <div className={styles.spinner}> <DotsSpinner /> </div>

    return (
      <div className={styles.pdf}>
        <Button view='transparent-black' nativeLink={this.state.file} target='_blank'>{t('downloadReport').s}</Button>
        <br />

        { !isMobile.android.phone && <FullPDF path={this.state.file} /> }
      </div>
    )
  }
}
