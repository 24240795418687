import { maxBy } from 'lodash'
import moment from 'moment'
import * as INVOICE_STATUSES from '../constants/StatusTypes'

export function calculateInvoiceFeeVatAmount (invoiceRows, invoiceFee) {
  const invoiceRowWithMaxVat = maxBy(invoiceRows, (row) => Number(row.sumExVat))
  const invoiceFeeVat = invoiceRowWithMaxVat ? Number(invoiceRowWithMaxVat.vatPercentage || 0) : 0
  const invoiceFeeVatAmount = invoiceFeeVat * invoiceFee / 100

  return invoiceFeeVatAmount
}

export function roundTotal (input) {
  const inputInCents = input * 100
  const sum = Math.floor(inputInCents / 100)
  const mod = inputInCents % 100

  if (mod < 50) {
    return sum
  } else {
    return sum + 1
  }
}

export function isOverdue (invoice) {
  return invoice.sentAt &&
    invoice.status !== INVOICE_STATUSES.PAID &&
    moment(invoice.dueDate).isBefore(moment().startOf('day'))
}

export function calculateTotals (invoice, formValues) {
  const invoiceFeeVatAmount = calculateInvoiceFeeVatAmount(invoice.invoiceRows, formValues.invoiceFee)

  const preTotal = invoice.preTotal
  const preTotalVat = invoice.preTotalVat
  const totalVat = ((formValues.noVat || formValues.euVat || formValues.invertedVat) ? 0 : invoiceFeeVatAmount + invoice.preTotalVat)
  const preTotalWithVat = invoice.preTotal + totalVat

  const rotRutTotal = (invoice.rotRutObject && invoice.rotRutObject.total) ? Number(invoice.rotRutObject.total) : 0
  const total = preTotalWithVat - Math.floor(invoice.discountAmount) +
    Number(formValues.invoiceFee) - Number(rotRutTotal)

  return {
    preTotal: preTotal,
    preTotalVat: preTotalVat,
    total: roundTotal(total),
    totalVat: totalVat,
    discountAmount: invoice.discountAmount,
    rotRutTotal: rotRutTotal,
    roundingAmount: roundTotal(total) - total
  }
}
