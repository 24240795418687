import URI from 'urijs'
import { bind } from 'redux-effects'
import { createAction } from 'redux-actions'
import { routerActions } from 'react-router-redux'
import { showNavbarSpinnerAction, hideNavbarSpinnerAction } from 'shared/actions/navbarSpinner'
import { fetch2 as fetch } from 'shared/helpers/fetch'

import { reloadListAction } from './invoices'

import { INVOICE_GET_PDF, INVOICE_COPY } from '../../shared/constants/Api'

import { isIpadResolution } from 'shared/helpers/utils'
import {INVOICE_DIRECT_PAYMENT_UPDATE, INVOICE_DIRECT_PAYMENT_CLEAN} from '../../shared/constants/ActionTypes'
import isMobile from 'ismobilejs'

const directPaymentUpdate = createAction(INVOICE_DIRECT_PAYMENT_UPDATE)
export const directPaymentClean = createAction(INVOICE_DIRECT_PAYMENT_CLEAN)

export function invoiceCopyAction () {
  return (dispatch, getState) => {
    const { invoices: { customerInvoices: { invoice: { id } } } } = getState()

    const afterSuccess = (response) => {
      return (dispatch) => {
        return dispatch([
          isIpadResolution() && reloadListAction(),
          hideNavbarSpinnerAction(),
          bind(routerActions.push(`/invoices/${response.value.entity.id}`), () => routerActions.push(`/invoices/edit/${response.value.entity.id}`))
        ])
      }
    }

    return dispatch([
      showNavbarSpinnerAction(),
      bind(fetch(INVOICE_COPY(id), {}, {
        method: 'POST'
      }), afterSuccess)
    ])
  }
}

// export function sendToOwnEmailAction () {
//   // TODO: looks like it's not being used?
//   return (dispatch, getState) => {
//     const {
//       userSettings: { user: { email } },
//       invoices: { customerInvoices: { invoice: { id } } }, i18n: { locale }
//     } = getState()
//     return dispatch([
//       showNavbarSpinnerAction(),
//       bind(sendInvoice(id, { email, locale }), afterMessageSend.bind(this, 'emailSuccess'))
//     ])
//   }
// }

export function invoicePdfPreviewAction () {
  return (dispatch, getState) => {
    const { invoices: { customerInvoices: { invoice: { id } } }, i18n: { locale } } = getState()
    const pathname = window.location.pathname

    return dispatch([
      showNavbarSpinnerAction(),
      bind(getInvoicePdfPreview(id, locale), showInvoicePdf.bind(null, pathname))
    ])
  }
}

function getInvoicePdfPreview (invoiceId, locale) {
  return fetch(INVOICE_GET_PDF(invoiceId), { locale })
}

function showInvoicePdf (initialPath, response) {
  const path = response.value.url
  if (isMobile.android.phone) {
    window.location.href = path
    return [
      hideNavbarSpinnerAction()
    ]
  } else {
    return [
      routerActions.push(URI(`${initialPath}/pdf`).addSearch({ path }).toString()),
      hideNavbarSpinnerAction()
    ]
  }
}

export function markAsPaidAction () {
  return (dispatch, getState) => {
    const { invoices: { customerInvoices: { invoice: { sourceDocumentId } } } } = getState()

    return dispatch([
      routerActions.push(`/events/edit/${sourceDocumentId}/revenue/?paymentEditMode=true`)
    ])
  }
}

export function directPaymentAction () {
  return (dispatch, getState) => {
    const { invoices: { customerInvoices: { invoice: { id, totals: { total } } } } } = getState()

    return dispatch([
      directPaymentUpdate({ amount: total }),
      routerActions.push(`/invoices/${id}/direct_payment`)
    ])
  }
}
