import './CheckBoxList.scss?global'

import PropTypes from 'prop-types'

import React, { Component } from 'react'
import { connect } from 'react-redux'

// Components
import ListView from 'shared/components/ListView/ListView'
import Checkbox from 'shared/components/Checkbox/Checkbox'

// Actions
import { initializeCheckBoxListAction, toggleCheckBoxListItemAction, destroyCheckBoxListAction } from 'CheckBoxList/actions/checkBoxList'

// Looks like a magic? Forget this!
// This component is A W E S O M E !
// Look at the tests for more details and start using it easily!

export class CheckBoxList extends Component {
  static propTypes = {
    items: PropTypes.arrayOf(PropTypes.object),
    template: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
    onItemClick: PropTypes.func,
    initializeCheckBoxList: PropTypes.func,
    destroyCheckBoxList: PropTypes.func,
    withoutWrap: PropTypes.bool,
    hasOnlyOneCheckedItem: PropTypes.bool
  };

  constructor (props) {
    super(props)
    this.listItemTemplate = this.listItemTemplate.bind(this)
  }

  componentDidMount () {
    this.props.initializeCheckBoxList()
  }

  componentWillUnmount () {
    this.props.destroyCheckBoxList()
  }

  render () {
    const { items, withoutWrap } = this.props
    return <ListView data={items} template={this.listItemTemplate} passIndex notMark emptyStyles={withoutWrap} />
  }

  // Private

  listItemTemplate (itemProps) {
    const Template = this.props.template
    const { onItemClick, withoutWrap } = this.props
    if (!withoutWrap) {
      return (
        <div className='checkbox-list-item' onClick={() => { onItemClick(itemProps.index) }}>
          <div>
            <Checkbox value={itemProps.data.isChecked} />
          </div>
          <div>
            <Template notLink {...itemProps} />
          </div>
        </div>
      )
    } else {
      return (
        <div onClick={() => { onItemClick(itemProps.index) }}>
          <Template notLink {...itemProps} />
        </div>
      )
    }
  }
}

function mapStateToProps (state) {
  return {
    items: state.checkBoxList.items
  }
}

function mapDispatchToProps (dispatch, ownProps) {
  const { onCheck, onUncheck, items, shouldBeChecked, hasOnlyOneCheckedItem } = ownProps

  return {
    initializeCheckBoxList: () => { dispatch(initializeCheckBoxListAction(items, shouldBeChecked)) },
    onItemClick: (index) => { dispatch(toggleCheckBoxListItemAction(index, onCheck, onUncheck, hasOnlyOneCheckedItem)) },
    destroyCheckBoxList: () => { dispatch(destroyCheckBoxListAction()) }
  }
}

const CheckBoxListWrapper = connect(mapStateToProps, mapDispatchToProps)(CheckBoxList)
CheckBoxListWrapper.defaultProps = {
  items: [],
  template: 'div',
  shouldBeChecked: () => { return false },
  onCheck: () => {},
  onUncheck: () => {},
  hasOnlyOneCheckedItem: false
}
export default CheckBoxListWrapper
