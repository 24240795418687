import './Textarea.scss?global'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import classnames from 'classnames'
import {omit} from 'lodash'

export default class Textarea extends Component {
  static propTypes = {
    placeholder: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
    label: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
    error: PropTypes.bool,
    white: PropTypes.bool,
    disabled: PropTypes.bool
  }

  omitWrongProps (props) {
    return omit(props, [
      'initialValue', 'autofill', 'onUpdate', 'valid', 'invalid', 'dirty',
      'pristine', 'active', 'touched', 'visited', 'autofilled',
      'asyncValidating', 'submitFailed', 'dispatch', 'submitting'
    ])
  }
  render () {
    const {placeholder, label, error, white, ...props} = this.props
    const divClassNames = classnames([
      'textarea',
      error && 'textarea--error',
      white && 'textarea--white'
    ])

    return (
      <div className={divClassNames}>
        <textarea {...this.omitWrongProps(props)} placeholder={placeholder} />
        <span className='textarea__label'>{label}</span>
      </div>
    )
  }
}
