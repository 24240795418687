import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import IpadNavigation from 'Navigation/IpadNavigation'
import Settings from 'User/Settings/views/SettingsView/containers/Smartphones'
import {CompanySettingsView as CompanySettings} from 'User/Settings/views/CompanySettingsView/containers/Smartphones'

class CompanySettingsView extends Component {
  static propTypes = {
    t: PropTypes.func
  }
  render () {
    const {t} = this.props
    return (
      <IpadNavigation {...this.props} title={t('title').s}>
        <Settings {...this.props} />
        <CompanySettings {...this.props} />
      </IpadNavigation>
    )
  }
}

function mapStateToProps (state) {
  return {
    t: state.i18n.get('app', 'views', 'CompanySettingsView')
  }
}

export default connect(mapStateToProps)(CompanySettingsView)
