import { isDesktop } from 'shared/helpers/utils'

import registerWithoutPersonalNumber from './bankID/withoutPersonalNumber'
import registerWithPersonalNumber from './bankID/withPersonalNumber'

export default function registerWithBankID (formData) {
  if (isDesktop()) {
    return registerWithPersonalNumber(formData)
  } else {
    return registerWithoutPersonalNumber(formData)
  }
}
