import React from 'react'

import Policy from 'Policy/decorators'

import CategoriesPie from 'WerkeysMainPage/components/CategoriesPie'
import Icon from 'shared/components/Icon'

import './MainPageView.scss?global'

export class MainPageView extends React.Component {
  render () {
    return (
      <div className='werkeys-main-page'>
        <div className='werkeys-main-page__content'>
          <Icon type='werkeys-logo' />
          <CategoriesPie />
        </div>
      </div>
    )
  }
}

export default Policy(MainPageView)
