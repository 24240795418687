import { createAction } from 'redux-actions'
import { NEW_EVENT_CLEAR } from 'Events/shared/constants/Event'
import * as eventType from 'Events/shared/constants/eventType'
import { pushEventAction as pushEventBaseAction } from 'Events/shared/actions/pushEvent'

const removeNewEventFromState = createAction(NEW_EVENT_CLEAR)

// TODO убрать isNewEvent
export function pushEventAction (isNewEvent, transfer, cameFrom) {
  const data = {
    ...transfer,
    type: eventType.transfer,
    title: 'Överföring',
    isInvoice: false
  }
  return pushEventBaseAction(data, cameFrom, 'bank-transfer-form')
}

export function clearNewExpenseForm () {
  return removeNewEventFromState()
}
