import React from 'react'
import axios from 'api-client-connector'
import { createAction } from 'redux-actions'
import { routerActions } from 'react-router-redux'
import { selectClientCompany, setHeaders, logout } from 'api-client-connector/utils'

import { showElementInMessageBoxAction } from 'MessageBox/actions/messageBox'
import loadAllSettingsAction from 'User/Settings/actions/settingsLoader'
import { updateNotificationBadgesAction } from 'Notifications/actions/notificationBadges'
import { showNavbarSpinnerAction, hideNavbarSpinnerAction } from 'shared/actions/navbarSpinner'

import { LOGIN_SUCCESS, USER_LOGOUT } from '../constants/ActionTypes'
import { USER_SETTINGS_ADD } from 'User/Settings/constants/UserSettings'
import { GET_TOKENS } from '../constants/Api'

// Bridges between iOS and Android apps
import { saveTokenForApp, removeTokenFromApp, updateBadgeCounter } from 'appBridge/notificators/app'

import WrongAppMessage from 'User/Auth/components/WrongAppMessage'

export const loginSuccess = createAction(LOGIN_SUCCESS)
const userLogout = createAction(USER_LOGOUT)
const updateUserSettings = createAction(USER_SETTINGS_ADD)

export default function loginAction (email, password) {
  return async (dispatch, getState) => {
    let error = ''
    dispatch(showNavbarSpinnerAction())
    try {
      const loginResponse = await loginRequest({ email, password })
      dispatch(saveTokens(loginResponse))
    } catch (e) {
      error = processError(e.response, dispatch, getState())
    }

    return { error }
  }
}

const loginRequest = (data) => axios.post(GET_TOKENS, data)

// TODO: refactor for shared usage
export function saveTokens (response) {
  return (dispatch, getState) => {
    if (response.data.data.client_companies.length > 1) {
      dispatch([
        updateUserSettings({
          mainPageHintsWereShown: response.data.data.tutorial_passed,
          companies: prepareCompaniesArray(response.data.data),
          user: prepareUserObject(response.data.data)
        }),
        hideNavbarSpinnerAction(),
        routerActions.push('/select_company?reloadOptions=true')
      ])
    } else if (response.data.data.client_companies.length === 1) {
      const company = response.data.data.client_companies[0]

      selectClientCompany(company.legacy_id)
      saveTokenForApp(getState().appInfo)

      dispatch(loadAllSettingsAction()).then(() => dispatch([
        loginSuccess(),
        updateUserSettings({
          mainPageHintsWereShown: response.data.data.tutorial_passed,
          companies: prepareCompaniesArray(response.data.data),
          user: prepareUserObject(response.data.data)
        }),
        updateNotificationBadgesAction()
      ]))
    } else {
      dispatch([
        hideNavbarSpinnerAction(),
        showElementInMessageBoxAction(<WrongAppMessage />)
      ])
    }
  }
}

function processError (response, dispatch, state) {
  const t = state.i18n.get('app', 'views', 'LoginView', 'errors')
  let error
  dispatch(hideNavbarSpinnerAction())

  if (response.status === 401) {
    error = response.data.reason
  } else {
    error = t('unexpectedError').s
  }

  return error
}

export function checkAuth () {
  return (dispatch, getState) => {
    const isLoggedIn = getState().auth.isLoggedIn
    if (!isLoggedIn) {
      return dispatch([routerActions.push('/entry')])
    }
  }
}

export function redirectToRoot () {
  return (dispatch, getState) => {
    const isLoggedIn = getState().auth.isLoggedIn
    if (isLoggedIn) {
      return dispatch([routerActions.replace('/')])
    }
  }
}

export function logoutAction () {
  removeTokenFromApp()
  updateBadgeCounter(0)
  logout()

  return [
    userLogout(),
    routerActions.push('/entry')
  ]
}

// iOS things
export function setTokenFromExtension (base64Token) {
  return (dispatch) => {
    try {
      const headers = JSON.parse(atob(base64Token))
      setHeaders(headers)
      selectClientCompany(headers['x-client-id'])

      return dispatch(loadAllSettingsAction()).then(() => dispatch(loginSuccess()))
    } catch (e) {
      return dispatch(userLogout())
    }
  }
}

export function prepareUserObject (data) {
  return {
    name: data.name,
    id: data.legacy_id,
    email: data.email,
    phone: data.phone,
    personalNumber: data.personal_number
  }
}

function prepareCompaniesArray (data) {
  return data.client_companies.map((company) => ({
    id: company.legacy_id,
    name: company.name,
    orgNumber: company.org_number
  }))
}

export function selectCompanyAction (data, reloadSettings = false) {
  return (dispatch, getState) => {
    selectClientCompany(data.companyId)
    saveTokenForApp(getState().appInfo)

    return Promise.all([
      reloadSettings && dispatch(loadAllSettingsAction()),
      reloadSettings && dispatch(updateNotificationBadgesAction())
    ]).then(() => dispatch([
      loginSuccess(),
      routerActions.push('/')
    ]))
  }
}
