import React, { Component } from 'react'
import { connect } from 'react-redux'
import { reduxForm, Field, change } from 'redux-form'
import PropTypes from 'prop-types'
import { getCurrentClientCompany } from 'api-client-connector/utils'
import { orgNumberValid, orgNumberMaskProps } from 'mrshoebox-ui-components/src/helpers/countrySpecific'

import Input from 'shared/components/FormInput'
import Button from 'shared/components/Button/Button'
import Textarea from 'shared/components/FormTextarea'
import CompanyNameInput from 'Counterparties/Suppliers/components/CompanyNameInput'
import OrgNumberInput from 'Counterparties/Suppliers/components/OrgNumberInput'

import { getLastSupplierNumber } from '../../actions/suppliers'

class SupplierForm extends Component {
  constructor (props) {
    super(props)
    this.state = { isExtraInfoShown: false }
    this.changeExtraFieldsVisibility = this.changeExtraFieldsVisibility.bind(this)
    this.prefillSupplierNumber = this.prefillSupplierNumber.bind(this)
  }

  static propTypes = {
    save: PropTypes.func,
    updateSupplierCompanyInfo: PropTypes.func,
    t: PropTypes.func,
    handleSubmit: PropTypes.func,
    params: PropTypes.object,
    changeSupplierNumber: PropTypes.func
  }

  componentDidMount () {
    const { params: { counterpartyId } } = this.props

    if (counterpartyId === 'new') {
      this.prefillSupplierNumber()
    }
  }

  componentDidUpdate (prevProps) {
    // to prefill supplier number on tablets.
    const { params: { counterpartyId } } = this.props
    const { params: { counterpartyId: prevCounterPartyId } } = prevProps
    if (counterpartyId === 'new' && counterpartyId !== prevCounterPartyId) {
      this.prefillSupplierNumber()
    }
  }

  async prefillSupplierNumber () {
    const lastSupplierNumber = await getLastSupplierNumber(Number(getCurrentClientCompany()))
    this.props.changeSupplierNumber(lastSupplierNumber + 1)
  }

  changeExtraFieldsVisibility () {
    this.setState({
      isExtraInfoShown: !this.state.isExtraInfoShown
    })
  }

  getExtraFields () {
    if (!this.state.isExtraInfoShown) {
      return null
    }

    const textAreaNames = ['notes', 'generalNotes']
    let fields = [
      'bankAccountNumber', 'bankName', 'city', 'companyEmail', 'companyPhone', 'contactEmail',
      'contactPerson', 'contactPhone', 'country', 'countryCode', 'creditAccount', 'currency',
      'debitAccount', 'generalNotes', 'invoiceAddress1', 'notes', 'postalNumber', 'paymentTerms',
      'vatNumber', 'vatType', 'webAddress'
    ]
    const inputs = fields.filter(field => !textAreaNames.includes(field))

    return (
      <div className='form f-column-top'>
        {inputs.map((name, i) =>
          <div className='form__item' key={i}>
            <Field name={name} component={Input} placeholder={this.props.t('fields', name).s} />
          </div>
        )}

        {textAreaNames.map((name, i) =>
          <div className='form__item' key={i}>
            <Field name={name} component={Textarea} placeholder={this.props.t('fields', name).s} />
          </div>
        )}
      </div>
    )
  }

  render () {
    const {
      t,
      handleSubmit,
      save,
      params: { counterpartyId }
    } = this.props

    const extraFieldsButtonText = this.state.isExtraInfoShown
      ? t('buttons', 'hideMore').s
      : t('buttons', 'showMore').s

    return (
      <div className='f-column'>
        <div className='form f-column-top'>
          <div className='form__item'>
            <Field name='supplierNumber' component={Input} placeholder={t('fields', 'supplierNumber').s} type='number' />
          </div>
          <div className='form__item'>
            <Field name='name' component={CompanyNameInput} placeholder={t('fields', 'name').s} />
          </div>
          <div className='form__item'>
            <Field name='orgNumber' component={OrgNumberInput} placeholder={t('fields', 'orgNumber').s} type='orgNumber' {...orgNumberMaskProps} />
          </div>
          <div className='form__item'>
            <Field name='bankgiroNumber' component={Input} placeholder={t('fields', 'bankgiro').s} />
          </div>
          <div className='form__item'>
            <Field name='plusgiroNumber' component={Input} placeholder={t('fields', 'plusgiro').s} />
          </div>
          <div className='form__item'>
            <Field name='bic' component={Input} placeholder={t('fields', 'bic').s} />
          </div>
          <div className='form__item'>
            <Field name='iban' component={Input} placeholder={t('fields', 'iban').s} />
          </div>
        </div>
        {this.getExtraFields()}
        <div className='f-column-bottom form-buttons'>
          <Button view='transparent-black' saveButton onClick={handleSubmit((values) => { save(values, counterpartyId) })}>{t('buttons', 'save').s}</Button>
          <Button view='transparent-black' onClick={this.changeExtraFieldsVisibility}>{extraFieldsButtonText}</Button>
          <Button view='transparent-red' goBackButton>{t('buttons', 'cancel').s}</Button>
        </div>
      </div>
    )
  }
}

function validate (values) {
  const errors = {}

  if (!values.name || values.name.length >= 60) {
    errors.name = true
  }
  if (values.orgNumber && !orgNumberValid(values.orgNumber)) {
    errors.orgNumber = true
  }

  return errors
}

const formName = 'supplier'
const mapDispatchToProps = (dispatch) => ({
  changeSupplierNumber: (value) => dispatch(change(formName, 'supplierNumber', value))
})

const connectedSupplierForm = connect(null, mapDispatchToProps)(SupplierForm)

export default reduxForm({
  form: formName,
  enableReinitialize: true,
  validate
})(connectedSupplierForm)
