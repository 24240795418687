import React, { Component } from 'react'

import IpadNavigation from 'Navigation/IpadNavigation'
import MoreSalary from 'More/views/MoreSalaryView/containers/Smartphones'

export default class MoreSalaryView extends Component {
  render () {
    return (
      <IpadNavigation {...this.props} onlyLeftSide>
        <MoreSalary {...this.props} notRightIcon />
      </IpadNavigation>
    )
  }
}
