import React, { Component } from 'react'
import PropTypes from 'prop-types'

import IpadNavigation from 'Navigation/IpadNavigation'
import More from 'More/views/MoreView/containers/Smartphones'
import { CreditsafeFrameView as CreditsafeFrame } from 'MarketPlace/Creditsafe/views/CreditsafeFrameView/containers/Smartphones'

export default class CreditsafeFrameView extends Component {
  static propTypes = {
    t: PropTypes.func,
    selectedCategory: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.func
    ])
  }

  render () {
    const { t, selectedCategory } = this.props

    return (
      <IpadNavigation
        {...this.props}
        title={t('categories', selectedCategory).s}
        withoutScroll
        withoutMaxWidth
        backLink='/marketplace'
      >
        <More {...this.props} notRightIcon isIpad />
        <CreditsafeFrame {...this.props} />
      </IpadNavigation>
    )
  }
}
