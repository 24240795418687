import './styles/index.scss?global'

import PropTypes from 'prop-types'

import React, { Component } from 'react'

const defaultTime = '00'
const minutesInHour = 60
const maxMinutes = '59'

export default class TimeInput extends Component {
  static propTypes = {
    readOnly: PropTypes.bool,
    onChange: PropTypes.func
  }

  constructor (props) {
    super(props)
    this.state = {hours: defaultTime, minutes: defaultTime, value: null} // value - expected hours
    this.onMinuteChange = this.onMinuteChange.bind(this)
    this.onMinuteBlur = this.onMinuteBlur.bind(this)
    this.onHourChange = this.onHourChange.bind(this)
    this.onHourBlur = this.onHourBlur.bind(this)
  }

  convertHoursToTimer (props = this.props) {
    if (props.value) {
      const numberedHours = Number(props.value)
      const hours = Math.floor(numberedHours)
      const minutes = Math.round((numberedHours % 1) * 60)

      let strHours = String(hours)
      let strMinutes = String(minutes)
      if (strHours.length === 1) {
        strHours = 0 + strHours
      }
      if (strMinutes.length === 1) {
        strMinutes = 0 + strMinutes
      }

      this.setState({ hours: strHours, minutes: strMinutes })
    } else {
      this.setState({hours: defaultTime, minutes: defaultTime})
    }
  }

  componentWillMount () {
    this.convertHoursToTimer()
  }

  componentWillReceiveProps (props) {
    this.convertHoursToTimer(props)
  }

  onMinuteChange (e) {
    const numberValue = Number(e.target.value)

    let endValue
    if (numberValue >= minutesInHour) {
      endValue = maxMinutes
    } else if (numberValue < 0) {
      endValue = defaultTime
    } else {
      endValue = e.target.value
    }

    this.setState({ minutes: endValue.replace(/\D+/g, '') })
  }

  onMinuteBlur () {
    const {onChange} = this.props
    if (!this.state.minutes) {
      this.setState({minutes: defaultTime}, () => {
        onChange(this.getMinutesValue())
      })
    } else {
      onChange(this.getMinutesValue())
    }
  }

  onHourChange (e) {
    const numberValue = Number(e.target.value)

    let endValue
    if (numberValue < 0) {
      endValue = defaultTime
    } else {
      endValue = e.target.value
    }

    this.setState({ hours: endValue.replace(/\D+/g, '') })
  }

  onHourBlur () {
    const {onChange} = this.props
    if (!this.state.hours) {
      this.setState({hours: defaultTime}, () => {
        onChange(this.getMinutesValue())
      })
    } else if (String(this.state.hours).length < 2) {
      this.setState({hours: `0${this.state.hours}`}, () => {
        onChange(this.getMinutesValue())
      })
    } else {
      onChange(this.getMinutesValue())
    }
  }

  getMinutesValue () {
    const {minutes, hours} = this.state
    return Number(hours) + (Number(minutes) / minutesInHour)
  }

  render () {
    const {minutes, hours} = this.state
    const {readOnly} = this.props

    return (
      <div className='time-input'>
        <input
          readOnly={readOnly}
          autoCorrect='off'
          onChange={this.onHourChange}
          onBlur={this.onHourBlur}
          type='tel'
          value={hours} />
        <div>:</div>
        <input
          readOnly={readOnly}
          autoCorrect='off'
          onChange={this.onMinuteChange}
          onBlur={this.onMinuteBlur}
          type='tel'
          value={minutes} />
        <div className='time-input__corners' />
      </div>
    )
  }
}
