import PropTypes from 'prop-types'
import React, { Component } from 'react'

// Components
import FormatNumber from 'shared/components/FormatNumber/FormatNumber'
import { RESULT_TYPE } from 'Overview/constants/OverviewSettings'

export default class FinanceVisualizationTopControl extends Component {
  static propTypes = {
    totals: PropTypes.object,
    t: PropTypes.func,
    openSettings: PropTypes.func,
    settings: PropTypes.object
  }

  render () {
    const { settings: {result, forecast, type}, openSettings, totals, t } = this.props
    let dates
    if (type === RESULT_TYPE) {
      dates = result.customDates || result
    } else {
      dates = forecast
    }
    return (
      <div className='finance-visualization__top'>
        <div className='finance-visualization__top__dates'>
          <div onClick={openSettings}><span className='icon-date-from' /><span>{dates.from}</span></div>
          <div onClick={openSettings}><span>{dates.to}</span><span className='icon-date-to' /></div>
        </div>
        <div className='finance-visualization__top__info'>
          <h4><b>{t('total').s}</b></h4>
          <h4><FormatNumber value={totals.totalAmount} notFraction /></h4>
        </div>
      </div>
    )
  }
}
