import React from 'react'
import { Route } from 'react-router'
import {requireAuthentication} from '../../../User/Auth/higherOrderComponents/AuthComponent'

// Main invoice form
import InvoicesView from '../../CustomerInvoices/views/InvoicesView/InvoicesView'
import SupplierInvoicesView from '../../SupplierInvoices/views/SupplierInvoicesView/SupplierInvoicesView'
import InvoiceCustomersView from '../../CustomerInvoices/views/InvoiceCustomersView/InvoiceCustomersView'
import InvoiceCreateView from '../../CustomerInvoices/views/InvoiceCreateView/InvoiceCreateView'
import InvoiceDiscountView from '../../CustomerInvoices/views/InvoiceDiscountView/InvoiceDiscountView'
import InvoiceRotRutView from '../../CustomerInvoices/views/InvoiceRotRutView/InvoiceRotRutView'

// Invoice row
import InvoiceRowView from '../../CustomerInvoices/views/InvoiceRow/InvoiceRowView/InvoiceRowView'

// Imports View
import InvoiceImportArticleView from '../../CustomerInvoices/views/InvoiceImports/ImportArticlesView/ImportArticlesView'
import InvoiceImportEvents from '../../CustomerInvoices/views/InvoiceImports/ImportEventsView/ImportEventsView'
import InvoiceImportTimesheetsView from '../../CustomerInvoices/views/InvoiceImports/ImportTimesheetsView/ImportTimesheetsView'
import InvoiceImportProjectView from '../../CustomerInvoices/views/InvoiceImports/ImportProjectView/ImportProjectView'
import InvoiceImportMileageReportView from '../../CustomerInvoices/views/InvoiceImports/ImportMileageReportView/ImportMileageReportView'

// Another views
import InvoicePreviewView from '../../CustomerInvoices/views/InvoicePreviewView/InvoicePreviewView'
import InvoicePdfPreview from '../../CustomerInvoices/views/InvoicePdfPreview/InvoicePdfPreview'
import EventView from 'Events/Event/views/EventView/EventView'
import DirectPaymentView from 'Invoices/CustomerInvoices/views/DirectPaymentView/DirectPaymentView'
import InvoiceSendView from 'Invoices/CustomerInvoices/views/InvoiceSendView/InvoiceSendView'

// Cleaning things
import { invoiceClearAction } from '../../CustomerInvoices/actions/invoiceCreate'

function clearInvoiceInfo (dispatch, prevState) {
  const prevRoute = prevState.routes[prevState.routes.length - 1].path
  if (prevRoute !== '/invoices/new/customers' && prevRoute !== '/invoices/edit/:id/customers' &&
    prevRoute !== '/invoices/new/articles' && prevRoute !== '/invoices/edit/:id/articles' &&
    prevRoute !== '/invoices/new/mileageReports' && prevRoute !== '/invoices/edit/:id/mileageReports' &&
    prevRoute !== '/invoices/:id/send_invoice'
  ) {
    dispatch(invoiceClearAction())
  }
}

const invoiceRoutes = (
  <Route>
    <Route path='/invoices' component={requireAuthentication(InvoicesView)} />
    <Route path='/supplier-invoices' component={requireAuthentication(SupplierInvoicesView)} />
    <Route path='/supplier-invoices/:eventId' component={requireAuthentication(EventView)} />

    <Route onLeave={clearInvoiceInfo}>
      <Route path='/invoices/new' component={requireAuthentication(InvoiceCreateView)} />
      <Route path='/invoices/new/customers' component={requireAuthentication(InvoiceCustomersView)} />
      <Route path='/invoices/new/discount' component={requireAuthentication(InvoiceDiscountView)} />

      <Route path='/invoices/new/articles' component={requireAuthentication(InvoiceImportArticleView)} />
      <Route path='/invoices/new/events' component={requireAuthentication(InvoiceImportEvents)} />
      <Route path='/invoices/new/timesheets' component={requireAuthentication(InvoiceImportTimesheetsView)} />
      <Route path='/invoices/new/projects' component={requireAuthentication(InvoiceImportProjectView)} />
      <Route path='/invoices/new/mileageReports' component={requireAuthentication(InvoiceImportMileageReportView)} />

      <Route path='/invoices/new/invoice_row/:index' component={requireAuthentication(InvoiceRowView)} />
      <Route path='/invoices/new/rot_rut' component={requireAuthentication(InvoiceRotRutView)} />
    </Route>

    <Route onLeave={clearInvoiceInfo}>
      <Route path='/invoices/:id' component={requireAuthentication(InvoicePreviewView)} />
      <Route path='/invoices/:id/send_invoice' component={requireAuthentication(InvoiceSendView)} />
      <Route path='/invoices/:id/direct_payment' component={requireAuthentication(DirectPaymentView)} />
      <Route path='/invoices/:id/pdf' component={requireAuthentication(InvoicePdfPreview)} />

      <Route path='/invoices/edit/:id' component={requireAuthentication(InvoiceCreateView)} />
      <Route path='/invoices/edit/:id/customers' component={requireAuthentication(InvoiceCustomersView)} />
      <Route path='/invoices/edit/:id/discount' component={requireAuthentication(InvoiceDiscountView)} />
      <Route path='/invoices/edit/:id/invoice_row/:index' component={requireAuthentication(InvoiceRowView)} />
      <Route path='/invoices/edit/:id/rot_rut' component={requireAuthentication(InvoiceRotRutView)} />

      <Route path='/invoices/edit/:id/articles' component={requireAuthentication(InvoiceImportArticleView)} />
      <Route path='/invoices/edit/:id/events' component={requireAuthentication(InvoiceImportEvents)} />
      <Route path='/invoices/edit/:id/timesheets' component={requireAuthentication(InvoiceImportTimesheetsView)} />
      <Route path='/invoices/edit/:id/projects' component={requireAuthentication(InvoiceImportProjectView)} />
      <Route path='/invoices/edit/:id/mileageReports' component={requireAuthentication(InvoiceImportMileageReportView)} />
    </Route>
  </Route>
)

export default invoiceRoutes
