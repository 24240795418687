import './WizardBaseInfo.scss?global'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import {reduxForm} from 'redux-form-5'
import cs from 'classnames'

import Navigation from 'Navigation/Navigation'
import InputRange from 'shared/components/InputRange/InputRange'
import InputSwitcher from 'shared/components/InputSwitcher/InputSwitcher'
import RadioButton from 'shared/components/RadioButton/RadioButton'
import Button from 'shared/components/Button/Button'

import {saveBaseInfoAction, setInfoAction} from 'Wizard/actions/wizard'

import {addHashForIos} from 'shared/helpers/utils'
import { scrollToContainerBottom } from 'shared/helpers/scrollUtils'
import getGreyLabelOptions from 'GreyLabel/helpers/greyLabel'

export class WizardBaseInfo extends Component {
  static propTypes = {
    fields: PropTypes.object,
    getPriceList: PropTypes.func,
    values: PropTypes.object,
    t: PropTypes.func,
    t_button: PropTypes.func,
    setInfo: PropTypes.func,
    location: PropTypes.object
  };

  constructor (props) {
    super(props)
    this.state = { showModalBox: false, accountFieldShow: !!props.fields.accountantMonthlyCost.initialValue }
    this.onChangeAccount = this.onChangeAccount.bind(this)
    this.save = this.save.bind(this)
  }
  scrollToBottom (val) {
    if (val) {
      setTimeout(() => {
        scrollToContainerBottom({ duration: 400 })
      }, 150)
    }
  }
  onChangeAccount (val) {
    const { fields: { accountantMonthlyCost } } = this.props
    this.setState({accountFieldShow: val}, function () {
      accountantMonthlyCost.onChange(0)
      this.scrollToBottom(val)
    })
  }

  save () {
    this.props.getPriceList(this.props.values)
  }

  get radioButtons () {
    const { t, fields: {vatFrequency} } = this.props
    return (
      <div className='wizard-base-info__radio-field'>
        <div className='wizard-base-info__radio-field__text'>{t('fields', 'vatFrequency', 'label').s}</div>
        <div className='wizard-base-info__radio-field__content'>
          <div>
            <span>{t('fields', 'vatFrequency', 'free').s}</span>
            <RadioButton {...vatFrequency} radioValue='free' />
          </div>
          <div>
            <span>{t('fields', 'vatFrequency', 'month').s}</span>
            <RadioButton {...vatFrequency} radioValue='month' />
          </div>
          <div>
            <span>{t('fields', 'vatFrequency', 'quarter').s}</span>
            <RadioButton {...vatFrequency} radioValue='quarter' />
          </div>
          <div>
            <span>{t('fields', 'vatFrequency', 'year').s}</span>
            <RadioButton {...vatFrequency} radioValue='year' />
          </div>
        </div>
      </div>
    )
  }

  saveCurrentStep () {
    addHashForIos()
    this.props.setInfo({currentStep: this.props.location.pathname})
  }

  get navigateActions () {
    return [{icon: 'icon-folding', path: '/?skipWizard=true', onClick: this.saveCurrentStep.bind(this)}]
  }

  formatTurnover (value) {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
  }

  render () {
    const {
      fields: {
        monthlyClientInvoices, monthlySupplierInvoices, monthlyReceipts,
        withEmployees, cashRegister, accountantMonthlyHours,
        accountantMonthlyCost, turnover
      },
      t,
      t_button
    } = this.props
    const { accountFieldShow } = this.state
    const { whiteLabel } = getGreyLabelOptions()
    return (
      <Navigation navigationWithMaxWidth className={cs('wizard', 'wizard-base-info', whiteLabel)} title={t('title').s} hideFooter actions={this.navigateActions} backLink='/wizard/next-actions' forceBackLink notBottomSpace>
        <div className='wizard-base-info__text'>
          <div>{t('greetingText').s}</div>
        </div>
        <div className='wizard-base-info__content'>
          <div className='wizard-base-info__field wizard-base-info__field--without-border'>
            <div className='wizard-base-info__field__text'>{`${t('fields', 'turnover').s} (${t('units', 'cost').s})`}</div>
            <div className='wizard-base-info__field__content'>
              <div><span className='icon-cost' /></div>
              <div><InputRange {...turnover} max='10000000' step='50000' displayedValueFormatter={this.formatTurnover} /></div>
            </div>
          </div>
          <div className='wizard-base-info__field wizard-base-info__field--without-border'>
            <div className='wizard-base-info__field__text'>{`${t('fields', 'invoicesToCustomers').s} (${t('units', 'perMonth').s})`}</div>
            <div className='wizard-base-info__field__content'>
              <div><span className='icon-bills' /></div>
              <div><InputRange {...monthlyClientInvoices} max='100' /></div>
            </div>
          </div>
          <div className='wizard-base-info__field wizard-base-info__field--without-border'>
            <div className='wizard-base-info__field__text'>{`${t('fields', 'supplierInvoices').s} (${t('units', 'perMonth').s})`}</div>
            <div className='wizard-base-info__field__content'>
              <div><span className='icon-invoice' /></div>
              <div><InputRange {...monthlySupplierInvoices} max='100' /></div>
            </div>
          </div>
          <div className='wizard-base-info__field wizard-base-info__field--without-border'>
            <div className='wizard-base-info__field__text'>{`${t('fields', 'numberOfReceipts').s} (${t('units', 'perMonth').s})`}</div>
            <div className='wizard-base-info__field__content'>
              <div><span className='icon-receipt' /></div>
              <div><InputRange {...monthlyReceipts} max='100' /></div>
            </div>
          </div>
          <div className='wizard-base-info__selects'>
            <div className='wizard-base-info__selects__item'>
              <div className='wizard-base-info__employees'><InputSwitcher {...withEmployees} /></div>
              <div>{t('fields', 'withEmployees').s}</div>
            </div>
          </div>
          <div className='wizard-base-info__selects'>
            <div className='wizard-base-info__selects__item'>
              <div><InputSwitcher {...cashRegister} /></div>
              <div>{t('fields', 'cash').s}</div>
            </div>
          </div>
          { this.radioButtons }
          <div className='wizard-base-info__selects'>
            <div className='wizard-base-info__selects__item'>
              <div><InputSwitcher initialValue={accountFieldShow} value={accountFieldShow} onChange={this.onChangeAccount} /></div>
              <div>{t('fields', 'accountingToday').s}</div>
            </div>
          </div>
          <div className={`wizard-base-info__field wizard-base-info__account ${accountFieldShow ? 'wizard-base-info__account--show' : ''}`}>
            <div className='wizard-base-info__field__text'>{t('fields', 'currentMonthlyCost').s}</div>
            <div className='wizard-base-info__field__content'>
              <div><span className='icon-cost' /></div>
              <div><InputRange {...accountantMonthlyCost} max='10000' step='100' /></div>
            </div>
          </div>
          <div className='wizard-base-info__field'>
            <div className='wizard-base-info__field__text'>{t('fields', 'currentMonthlyHours').s}</div>
            <div className='wizard-base-info__field__content'>
              <div><span className='icon-cost' /></div>
              <div><InputRange {...accountantMonthlyHours} max='100' step='1' /></div>
            </div>
          </div>
          <div className='wizard-base-info__save'>
            <Button view='transparent-white' className='wizard-base-info__save__button' onClick={this.save}>{t_button('seeResults').s}</Button>
          </div>
        </div>
      </Navigation>
    )
  }
}

function mapStateToProps (state) {
  return {
    info: state.wizard.newPrice,
    companyType: state.wizard.newPrice.companyType,
    wizardCategory: state.wizard.wizardCategory,
    initialValues: {
      documentsFrequency: state.wizard.newPrice.documentsFrequency,
      monthlyClientInvoices: state.wizard.newPrice.monthlyClientInvoices,
      monthlySupplierInvoices: state.wizard.newPrice.monthlySupplierInvoices,
      monthlyReceipts: state.wizard.newPrice.monthlyReceipts,
      cashRegister: state.wizard.newPrice.cashRegister,
      accountantMonthlyCost: state.wizard.newPrice.accountantMonthlyCost,
      accountantMonthlyHours: state.wizard.newPrice.accountantMonthlyHours,
      withEmployees: state.wizard.newPrice.withEmployees,
      vatFrequency: state.wizard.newPrice.vatFrequency,
      turnover: state.wizard.newPrice.turnover
    },
    t: state.i18n.get('app', 'views', 'Wizard', 'BaseInfo'),
    businessTypesT: state.i18n.get('app', 'views', 'Wizard', 'BusinessType', 'menu'),
    t_button: state.i18n.get('app', 'shared', 'buttons')
  }
}

function mapDispatchToProps (dispatch) {
  return {
    getPriceList: (data) => { dispatch(saveBaseInfoAction(data)) },
    setInfo: (data) => { dispatch(setInfoAction(data)) }
  }
}

export default reduxForm({
  reduxMountPoint: 'legacyForm',
  form: 'wizard-base-info',
  fields: [
    'documentsFrequency', 'monthlyClientInvoices', 'monthlySupplierInvoices',
    'monthlyReceipts', 'cashRegister', 'accountantMonthlyCost',
    'accountantMonthlyHours', 'withEmployees', 'vatFrequency', 'turnover'
  ]
}, mapStateToProps, mapDispatchToProps)(WizardBaseInfo)
