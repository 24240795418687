import PropTypes from 'prop-types'
import React, { Component } from 'react'

// Components
import IpadNavigation from 'Navigation/IpadNavigation'
import FilePreview from 'FileUpload/components/FilePreview/FilePreview'
import { ReportComment } from 'Reports/views/FinancialReport/FinancialReportView/components/ReportComment'

// Containers
import AnnualReportContainer from 'Reports/views/AnnualReport/AnnualReportView/containers/Common'

// Helpers
import getLeftComponent from 'Navigation/helpers/linkAccordance'

class AnnualReportsView extends Component {
  static propTypes = {
    report: PropTypes.object,
    navbarActions: PropTypes.object,
    previousLink: PropTypes.string,
    isCommentVisible: PropTypes.bool,
    changeCommentVisibility: PropTypes.func
  }

  render () {
    const { report: { files, reportPeriod, comment }, navbarActions, previousLink, isCommentVisible, changeCommentVisibility } = this.props
    const LeftSideComponent = getLeftComponent('annualReport', previousLink)

    return (
      <IpadNavigation {...this.props} title={reportPeriod} actions={navbarActions} className='annual-report-view'>
        <LeftSideComponent {...this.props} />
        { isCommentVisible && <ReportComment content={comment} hide={changeCommentVisibility} /> }
        { files && files.map((file, i) => {
          return <FilePreview key={i} file={file} />
        }) }
      </IpadNavigation>
    )
  }
}

export default AnnualReportContainer(AnnualReportsView)
