import PropTypes from 'prop-types'
import React, { Component } from 'react'

// Components
import Navigation from 'Navigation/Navigation'
import FilePreview from 'FileUpload/components/FilePreview/FilePreview'
import { ReportComment } from 'Reports/views/FinancialReport/FinancialReportView/components/ReportComment'

// Containers
import FinancialReportContainer from 'Reports/views/FinancialReport/FinancialReportView/containers/Common'

export class FinancialReportView extends Component {
  static propTypes = {
    report: PropTypes.object,
    navbarActions: PropTypes.object,
    isCommentVisible: PropTypes.bool,
    changeCommentVisibility: PropTypes.func
  }

  render () {
    const { report: { files, reportPeriod, comment }, navbarActions, isCommentVisible, changeCommentVisibility } = this.props
    return (
      <Navigation
        hideFooter
        notBack
        backLink='/reports/financial'
        title={reportPeriod}
        actions={navbarActions}
        className='financial-report-view'
      >
        { isCommentVisible && <ReportComment content={comment} hide={changeCommentVisibility} /> }
        { files && files.map((file, i) => {
          return <FilePreview key={i} file={file} />
        }) }
      </Navigation>
    )
  }
}

export default FinancialReportContainer(FinancialReportView)
