import 'User/Auth/styles/index.scss?global'

import PropTypes from 'prop-types'

import React from 'react'
import { Link, browserHistory } from 'react-router'
import { connect } from 'react-redux'
import { reduxForm, Field, SubmissionError } from 'redux-form'
import { routerActions } from 'react-router-redux'

import loginAction from 'User/Auth/actions/auth'
import { default as authenticateThroughBankID, onCancelAction } from 'User/Auth/actions/zignsecAuth'

import { INVALID_PASSWORD_ERROR, INVALID_EMAIL_ERROR } from 'User/Auth/constants/misc'

import { Container, UpperContent, BottomContent } from '../../components/LoginContainer'
import Button from 'shared/components/Button/Button'
import Input from 'shared/components/FormInput'
import FullscreenSpinner from 'User/shared/containers/FullscreenSpinner/FullscreenSpinner'
import BackLink from 'User/shared/components/BackLink/BackLink'

import { isDesktop } from 'shared/helpers/utils'

import { getBackgroundPath } from 'User/shared/helpers'
const backgroundPath = getBackgroundPath()

export class LoginViaPasswordView extends React.Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    isLoggedIn: PropTypes.bool,
    redirectTo: PropTypes.string,
    redirectToPage: PropTypes.func,
    t: PropTypes.func,
    error: PropTypes.string,
    doBankIDAuth: PropTypes.func,
    cancelBankIDAuth: PropTypes.func
  };

  constructor (props) {
    super(props)
    this.onSubmit = this.onSubmit.bind(this)
    this.state = { spinnerWithCancelButton: false }
  }

  componentDidUpdate () {
    const { isLoggedIn, redirectTo, redirectToPage } = this.props
    if (isLoggedIn) {
      redirectToPage(redirectTo)
    }
  }

  async onSubmit (values) {
    const { login, password } = values
    const { error } = await this.props.onSubmit(login, password)
    if (error) {
      throw new SubmissionError({ _error: error })
    }
  }

  get errorMessage () {
    const { error, t } = this.props

    if (!error) {
      return null
    }

    // TODO: should be generic message from backend for both cases, for security reasons.
    if (error === INVALID_PASSWORD_ERROR || error === INVALID_EMAIL_ERROR) {
      return (
        <span>
          { t('errors', 'incorrectPasswordOrEmail').s }
        </span>
      )
    } else {
      return error
    }
  }

  onBankIDButtonClick = () => {
    if (isDesktop()) {
      browserHistory.push('/login_bankid')
    } else {
      this.setState({ spinnerWithCancelButton: true })
      this.props.doBankIDAuth()
    }
  }

  onBankIDCancel = () => {
    this.props.cancelBankIDAuth()
    this.setState({ spinnerWithCancelButton: false })
  }

  render () {
    const { t, handleSubmit } = this.props
    return (
      <Container background={backgroundPath}>
        <UpperContent>
          &nbsp;
        </UpperContent>
        <BottomContent>
          <form onSubmit={handleSubmit(this.onSubmit)}>
            <div className='login__form__fields'>
              <Field name='login' component={Input} view='white' placeholder={t('fields', 'login').s} notLabel />
              <Field name='password' component={Input} view='white' type='password' placeholder={t('fields', 'password').s} notLabel />
              <div className='login__form__error'>
                { this.errorMessage }
              </div>
            </div>
            <div className='login__form__send'>
              <Button view='transparent-white' type='submit'>{t('loginButton').s}</Button>
              <Link to='/forgot_password'>{t('forgotPassword').s}</Link>
            </div>
          </form>
          <div className='hr2'><span>{t('labels', 'bankIdSuggestion').s}</span></div>
          <Button view='primary' svgIcon='bankid' onClick={this.onBankIDButtonClick}>{isDesktop() ? t('buttons', 'bankID').s : t('buttons', 'mobileBankID').s}</Button>
        </BottomContent>
        <BackLink transparent onClick={() => { browserHistory.goBack() }} text={t('back').s} />
        <FullscreenSpinner
          title={t('loggingIn').s}
          onCancelClick={this.state.spinnerWithCancelButton ? this.onBankIDCancel : null}
        />
      </Container>
    )
  }
}

function mapStateToProps (state) {
  return {
    isLoggedIn: state.auth.isLoggedIn,
    redirectTo: state.auth.redirectTo,
    showSpinner: state.auth.showSpinner,
    t: state.i18n.get('app', 'views', 'LoginView'),
    messageBoxText: state.i18n.get('app', 'shared', 'messages')
  }
}

function mapDispatchToProps (dispatch) {
  return {
    onSubmit: (...args) => dispatch(loginAction(...args)),
    redirectToPage: (page) => { dispatch(routerActions.push(page || '/')) },
    doBankIDAuth: () => { dispatch(authenticateThroughBankID()) },
    cancelBankIDAuth: () => { dispatch(onCancelAction()) }
  }
}

function validate (values) {
  let errors = {}
  if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.login)) {
    errors.login = true
  }
  if (!values.password) {
    errors.password = true
  }

  return errors
}

export default reduxForm({
  form: 'login',
  validate
})(connect(mapStateToProps, mapDispatchToProps)(LoginViaPasswordView))
