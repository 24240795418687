import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import { connect } from 'react-redux'

import Input from 'shared/components/Input/Input'
import PersonalNumberInput from 'shared/components/Input/PersonalNumber'

import SignUpBaseView from '../SignUpBaseView'

import { required, isCorrectPersonalNumber } from 'shared/helpers/formValidations'
import { isDesktop } from 'shared/helpers/utils'

import signUpWithBankID from 'User/Register/actions/bankIDSignup'

const InputWrapper = ({ input, meta, ...props }) => {
  console.log('props', props)
  return <Input {...input} {...meta} {...props} />
}
InputWrapper.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object
}
const PersonalNumberInputWrapper = ({ input, meta, ...props }) => {
  return <PersonalNumberInput {...input} {...meta} {...props} />
}
PersonalNumberInputWrapper.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object
}

const SignUpWithBankIDView = (props) => {
  const { t } = props
  const submitButtonProps = {
    view: 'primary',
    type: 'submit',
    svgIcon: 'bankid'
  }

  return (
    <SignUpBaseView submitButtonProps={submitButtonProps} onSubmit={props.onSubmit}>
      <Field
        name='email'
        component={InputWrapper}
        props={{ view: 'white', placeholder: t('fields', 'email').s, type: 'email', autoComplete: 'off' }}
        validate={[ required ]}
      />
      <Field
        name='telephone'
        component={InputWrapper}
        props={{ view: 'white', placeholder: t('fields', 'telephone').s, type: 'tel', autoComplete: 'off' }}
      />
      { isDesktop() ? <Field
        component={PersonalNumberInputWrapper}
        name='personalNumber'
        view='white'
        placeholder={t('fields', 'personalNumber').s}
        validate={[ required, isCorrectPersonalNumber ]}
      /> : null }
    </SignUpBaseView>
  )
}
SignUpWithBankIDView.propTypes = {
  t: PropTypes.func,
  onSubmit: PropTypes.func
}

function mapStateToProps (state) {
  return {
    t: state.i18n.get('app', 'views', 'SignUpView')
  }
}

function mapDispatchToProps (dispatch) {
  return {
    onSubmit: (...args) => { dispatch(signUpWithBankID(...args)) }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignUpWithBankIDView)
