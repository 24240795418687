import React from 'react'
import PropTypes from 'prop-types'

import { withHandlersAndTranslations } from './Common'
import Collaboration from 'MarketPlace/shared/views/CollaborationView'
import Navigation from 'Navigation/Navigation'
import Details from 'MarketPlace/Creditsafe/views/CreditsafeCollaborationView/components/CreditsafeDetailsModal'

import '../index.scss?global'

export class CreditsafeCollaboration extends React.Component {
  static propTypes = {
    t: PropTypes.func,
    cancelHandler: PropTypes.func.isRequired,
    redirectToOnboarding: PropTypes.func.isRequired
  }

  get collaborationConfig () {
    const { t } = this.props
    return {
      message: t('creditsafeCollaboration', 'message').s,
      partnerOffer: t('creditsafeCollaboration', 'offers').s,
      iconName: 'creditsafe',
      startButtonText: t('creditsafeCollaboration', 'startButton').s
    }
  }

  showDetailsModal = () => {
    this.detailsModal.open()
  }

  render () {
    return (
      <div className='creditsafe-collaboration'>
        <Collaboration
          {...this.collaborationConfig}
          {...this.props}
          startHandler={this.props.redirectToOnboarding}
          partnerBlockClass='creditsafe-collaboration-block'
          readMoreHandler={this.showDetailsModal}
        />
        <Details provideController={(node) => { this.detailsModal = node }} />
      </div>

    )
  }
}

const CreditsafeCollaborationWithNavigation = (props) => {
  const navBarTitle = props.t('creditsafeCollaboration', 'title').s

  return (
    <div className='creditsafe-collaboration-wrapper'>
      <Navigation hideFooter onClickBack={props.cancelHandler} title={navBarTitle} notBottomSpace >
        <CreditsafeCollaboration {...props} />
      </Navigation>
    </div>
  )
}

CreditsafeCollaborationWithNavigation.propTypes = {
  cancelHandler: PropTypes.func.isRequired
}

export default withHandlersAndTranslations(CreditsafeCollaborationWithNavigation)
