import ActionCable from 'actioncable'

export default class CableInteractor {
  initialize (options) {
    this.url = options.url
    this.connectionParams = options.connectionParams
    this.connected = options.connected
    this.received = options.received
    this._initialized = false
  }

  connect () {
    // this thing was added because of some race conditions on iOS
    if (this._initialized) {
      return
    }

    this.validateCableInteractor()

    this.cable = ActionCable.createConsumer(this.url)
    this.subscription = this.cable.subscriptions.create(
      this.connectionParams,
      {
        connected: this.connected,
        received: this.received
      }
    )

    this._initialized = true
  }

  disconnect () {
    this.cable.subscriptions.remove(this.subscription)
    this.subscription = undefined
    this.cable = undefined
    this._initialized = false
  }

  validateCableInteractor () {
    if (!this.url) {
      throw new Error('Url is not provided')
    }
    if (!this.connectionParams) {
      throw new Error('Connection params object is not provided')
    }
  }
}
