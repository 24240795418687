import { bind } from 'redux-effects'
import { createAction } from 'redux-actions'
import { getFormValues } from 'redux-form'
import { showNavbarSpinnerAction, hideNavbarSpinnerAction } from 'shared/actions/navbarSpinner'
import { fetch2 as fetch } from 'shared/helpers/fetch'
import { isEqual } from 'lodash'

import { getCurrencyName } from 'shared/helpers/addNamesForIdProps'

import { INVOICES_LIST, INVOICES_OVERDUE_AMOUNT } from '../../shared/constants/Api'
import { INVOICES_LIST_UPDATE, INVOICES_LIST_CLEAN } from '../../shared/constants/ActionTypes'

// Helpers
import { prepareInvoiceGetFields } from './invoiceCreate'

// Constants
import * as InvoiceStatuses from '../../shared/constants/StatusTypes'

export const invoicesListUpdate = createAction(INVOICES_LIST_UPDATE)
export const invoicesListCleanAction = createAction(INVOICES_LIST_CLEAN)

export function loadTabsCountersAction () {
  return bind(fetchCounters(), updateTabsCounters)
}

function fetchCounters () {
  return fetch(INVOICES_OVERDUE_AMOUNT)
}

function updateTabsCounters (response) {
  return invoicesListUpdate({
    tabsCounters: {
      amountOfOverdue: response.value.amount_of_overdue
    }
  })
}

export function reloadListAction (filters) {
  return (dispatch, getState) => {
    const { userSettings } = getState()

    return dispatch([
      showNavbarSpinnerAction(),
      bind(fetchInvoicesList(filters, 1), updateInvoiceListData.bind(null, [], userSettings, filters, true))
    ])
  }
}

export function extendListAction (customFilters) {
  return (dispatch, getState) => {
    const {
      userSettings,
      invoices: { customerInvoices: { hasNextPage, lastPageLoaded, listData } }
    } = getState()

    let filters = getFormValues('invoicesViewFilters')(getState())

    if (customFilters) filters = customFilters

    if (hasNextPage) {
      return dispatch([
        showNavbarSpinnerAction(),
        bind(fetchInvoicesList(filters, lastPageLoaded + 1), updateInvoiceListData.bind(null, listData, userSettings, filters, true))
      ])
    } else {
      return Promise.resolve() // redux-mock-store needs that
    }
  }
}

export function fetchInvoicesList (filters, pageToLoad) {
  const newFilters = processFilters(filters)
  return fetch(INVOICES_LIST, { ...newFilters, p: pageToLoad, order_type: 'desc' })
}

function processFilters (filters = {}) {
  let processedFilters = {}
  if (filters.status !== InvoiceStatuses.ALL && filters.status !== InvoiceStatuses.HAS_COLLECTOR_STATUS) {
    processedFilters = {
      'filter[status]': filters.status
    }
  } else if (filters.status === InvoiceStatuses.HAS_COLLECTOR_STATUS) {
    processedFilters = {
      'filter[has_collector_status]': true
    }
  }

  if (filters.ids) {
    let idsQueryString = ''
    filters.ids.forEach(id => { idsQueryString += `${id},` })
    processedFilters['filter[ids]'] = idsQueryString.slice(0, -1)
  }

  if (filters.all) processedFilters['all'] = true
  return processedFilters
}

function updateInvoiceListData (initialList, settings, filtersBeforeResponseReceived, withCustomFilters, response) {
  return (dispatch, getState) => {
    const currentFilters = getFormValues('invoicesViewFilters')(getState())
    if (!isEqual(currentFilters, filtersBeforeResponseReceived) && !withCustomFilters) {
      return
    }

    const invoices = response.value.data.map((invoice) => prepareInvoiceGetFields(invoice, settings))
    const extendedInvoices = invoices.map((invoice) => {
      return {
        ...invoice,
        currency: getCurrencyName(settings, invoice.currencyId)
      }
    })

    return dispatch([
      hideNavbarSpinnerAction(),
      invoicesListUpdate({
        listData: initialList.concat(extendedInvoices),
        hasNextPage: response.value.current_page < response.value.overall_pages,
        lastPageLoaded: response.value.current_page
      })
    ])
  }
}
