import PropTypes from 'prop-types'
import React, { Component } from 'react'
import cs from 'classnames'

import getGreyLabelOptions from 'GreyLabel/helpers/greyLabel'

export default class InvoiceTypeButton extends Component {
  static propTypes = {
    text: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
    onChange: PropTypes.func,
    value: PropTypes.string,
    radioValue: PropTypes.string.isRequired,
    input: PropTypes.object
  }

  constructor (props) {
    super(props)
    this.onClick = this.onClick.bind(this)
  }

  onClick () {
    const { input: { onChange }, radioValue } = this.props
    if (onChange) onChange(radioValue)
  }

  render () {
    const { radioValue, input: { value }, text } = this.props
    const checked = value === radioValue
    const { whiteLabel } = getGreyLabelOptions()
    const buttonClass = cs('create-invoice__type-button', whiteLabel)
    return (
      <div className={buttonClass} onClick={this.onClick}>
        <div>{text}</div>
        <div className={`create-invoice__type-button__radio ${checked ? 'create-invoice__type-button__radio--active' : ''}`}><span className='icon-ok' /></div>
      </div>
    )
  }
}
