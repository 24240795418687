import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import IpadNavigation from 'Navigation/IpadNavigation'
import More from 'More/views/MoreView/containers/Smartphones'
import { PensionCollaboration } from './Smartphones'

class PensionCollaborationView extends Component {
  static propTypes = {
    t: PropTypes.func,
    location: PropTypes.object.isRequired,
    startHandler: PropTypes.func.isRequired,
    cancelHandler: PropTypes.func.isRequired
  }

  render () {
    const { t, location } = this.props
    const navBarTitle = t('pensionCollaboration', 'title').s

    return (
      <IpadNavigation title={navBarTitle} location={location} withoutMaxWidth>
        <More notRightIcon isIpad />
        <PensionCollaboration
          {...this.collaborationConfig}
          {...this.props}
        />
      </IpadNavigation>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    t: state.i18n.get('app', 'views', 'MarketPlace')
  }
}

export default connect(mapStateToProps)(PensionCollaborationView)
