import {
  EVENTS_LIST_RELOADING, EVENTS_LIST_UPDATE, EVENTS_LIST_CLEAR,
  EVENTS_LOADING, EVENT_SHOW, EVENT_CLEAR,
  NEW_EVENT_UPDATE_PROPS, NEW_EVENT_CLEAR, EVENT_MESSAGES_LOAD,
  REPRESENTATION_ID
} from 'Events/shared/constants/Event'

import { invoicePaymentValidationToStore, bankAccountsToStore } from 'Events/EventPay/actions/eventPay'

import {
  NEW_EVENT_COPY_REPRESENTATION_DATA,
  NEW_EVENT_PUSH_REPRESENTATION_CONTACT,
  NEW_EVENT_EDIT_REPRESENTATION_CONTACT,
  NEW_EVENT_DELETE_REPRESENTATION_CONTACT,
  NEW_EVENT_SAVE_REPRESENTATION,
  NEW_EVENT_ADD_EVENT_ROW,
  NEW_EVENT_EDIT_EVENT_ROW,
  NEW_EVENT_EDIT_EVENT_ROWS_FIELDS
} from 'Events/Expense/constants/expense'

import moment from 'moment'
import { currencyId, eventDefaultVat } from 'mrshoebox-ui-components/src/helpers/countrySpecific'

const initialState = {
  isProcessing: false,
  lastOffset: 0,
  hasNextPage: true,
  data: [],
  currentEvent: null,
  newEvent: {
    files: [],
    eventRows: [],
    date: moment().format('YYYY-MM-DD'),
    vat: eventDefaultVat,
    currencyId,
    amount: '',
    representationCause: '',
    representationGuests: [],
    withAlcohol: false,
    internalRepresentation: false,
    supplier: {}
  },
  invoicePaymentValidation: {
    initialValues: { giroType: 'bankgiro' },
    currentErrors: {}
  }
}

export default function (state = initialState, action) {
  switch (action.type) {
    case EVENTS_LIST_RELOADING:
      return {
        ...state,
        lastOffset: 0
      }
    case EVENTS_LIST_UPDATE:
      return {
        ...state,
        data: action.payload.data,
        hasNextPage: action.payload.hasNextPage,
        lastOffset: action.payload.lastOffset
      }
    case EVENTS_LIST_CLEAR:
      return {
        ...state,
        data: initialState.data,
        isProcessing: initialState.isProcessing,
        lastOffset: initialState.lastOffset,
        hasNextPage: initialState.hasNextPage
      }
    case EVENTS_LOADING:
      return {
        ...state,
        currentEvent: null
      }
    case EVENT_SHOW:
      return {
        ...state,
        currentEvent: action.payload
      }
    case EVENT_CLEAR:
      return {
        ...state,
        currentEvent: null
      }
    case NEW_EVENT_UPDATE_PROPS:
      return {
        ...state,
        newEvent: {
          ...state.newEvent,
          ...action.payload
        }
      }
    case NEW_EVENT_CLEAR:
      return {
        ...state,
        newEvent: initialState.newEvent
      }
    case EVENT_MESSAGES_LOAD:
      return {
        ...state,
        currentEvent: {
          messages: action.payload
        }
      }
    /* falls through */
    case NEW_EVENT_COPY_REPRESENTATION_DATA:
      return {
        ...state,
        newEvent: {
          ...state.newEvent,
          representationCopy: action.payload
        }
      }
    case NEW_EVENT_PUSH_REPRESENTATION_CONTACT:
      return {
        ...state,
        newEvent: {
          ...state.newEvent,
          representationCopy: {
            ...state.newEvent.representationCopy,
            representationGuests: [
              ...state.newEvent.representationCopy.representationGuests,
              ...action.payload
            ]
          }
        }
      }
    case NEW_EVENT_DELETE_REPRESENTATION_CONTACT:
      return {
        ...state,
        newEvent: {
          ...state.newEvent,
          representationCopy: {
            ...state.newEvent.representationCopy,
            representationGuests: [
              ...state.newEvent.representationCopy.representationGuests.slice(0, action.payload),
              ...state.newEvent.representationCopy.representationGuests.slice(action.payload + 1)
            ]
          }
        }
      }
    case NEW_EVENT_EDIT_REPRESENTATION_CONTACT:
      return {
        ...state,
        newEvent: {
          ...state.newEvent,
          representationCopy: {
            ...state.newEvent.representationCopy,
            representationGuests: [
              ...state.newEvent.representationCopy.representationGuests.slice(0, action.payload.index),
              ...action.payload.data,
              ...state.newEvent.representationCopy.representationGuests.slice(action.payload.index + 1)
            ]
          }
        }
      }
    case NEW_EVENT_SAVE_REPRESENTATION:
      return {
        ...state,
        newEvent: {
          ...state.newEvent,
          representationCause: action.payload.representationCause,
          withAlcohol: action.payload.withAlcohol,
          internalRepresentation: action.payload.internalRepresentation,
          representationGuests: action.payload.representationGuests,
          categoryId: REPRESENTATION_ID
        }
      }
    case NEW_EVENT_ADD_EVENT_ROW:
      return {
        ...state,
        newEvent: {
          ...state.newEvent,
          eventRows: [
            ...state.newEvent.eventRows,
            action.payload
          ]
        }
      }
    case NEW_EVENT_EDIT_EVENT_ROW:
      return {
        ...state,
        newEvent: {
          ...state.newEvent,
          eventRows: [
            ...state.newEvent.eventRows.slice(0, action.payload.index),
            action.payload.data,
            ...state.newEvent.eventRows.slice(action.payload.index + 1)
          ]
        }
      }
    case NEW_EVENT_EDIT_EVENT_ROWS_FIELDS:
      const changedEventRows = state.newEvent.eventRows.map((elem) => {
        return {...elem, ...action.payload}
      })
      return {
        ...state,
        newEvent: {
          ...state.newEvent,
          eventRows: changedEventRows
        }
      }
    case invoicePaymentValidationToStore.toString():
      return {
        ...state,
        invoicePaymentValidation: {
          initialValues: { ...state.invoicePaymentValidation.initialValues, ...action.payload.initialValues },
          currentErrors: action.payload.currentErrors
        }
      }
    case bankAccountsToStore.toString():
      return {
        ...state,
        invoicePaymentValidation: {
          ...state.invoicePaymentValidation,
          initialValues: {
            ...state.invoicePaymentValidation.initialValues,
            bankAccounts: action.payload
          }
        }
      }
    default:
      return state
  }
}
