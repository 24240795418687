import React from 'react'
import { Route } from 'react-router'

// HOCs
import {requireAuthentication} from 'User/Auth/higherOrderComponents/AuthComponent'

// Shared modules
import MileagesView from 'mrshoebox-ui-components/src/modules/DriverLogs/views/DriverLogsListingView'
import MileageView from 'mrshoebox-ui-components/src/modules/DriverLogs/views/DriverLogEditView'
import MileageShowView from 'mrshoebox-ui-components/src/modules/DriverLogs/views/DriverLogView'

import Navigation from 'Navigation/Navigation'

// Actions
import { reloadDriverLogsListAction, extendDriverLogsListAction } from 'MileageReports/actions/mileageReports'
import {
  clearMileageReportFormAction,
  getMileageReportInfoAction,
  saveMileageReportAction,
  saveMileageReportForInvoiceAction,
  deleteMileageReportAction,
  getLastMileageReportAction
} from 'MileageReports/actions/mileageReport'

// Form-cleaning components
import createClearFormComponent from 'shared/factories/ClearForm'
const ClearMileageReportForm = createClearFormComponent(clearMileageReportFormAction)

const injectProps = (Component) => (props) => <Component
  {...props}
  reloadListAction={reloadDriverLogsListAction()}
  extendListAction={extendDriverLogsListAction()}
  loadDriverLogAction={getMileageReportInfoAction}
  saveDriverLog={saveMileageReportAction}
  saveDriverLogToInvoiceAction={saveMileageReportForInvoiceAction}
  deleteDriverLogAction={deleteMileageReportAction}
  getLastMileageReport={getLastMileageReportAction}
  navigationComponent={Navigation}
/>

const mileageRoutes = (
  <Route>
    <Route path='/driver_logs' component={requireAuthentication(injectProps(MileagesView))} />
    <Route component={ClearMileageReportForm}>
      <Route path='/driver_logs/new' component={requireAuthentication(injectProps(MileageView))} />
      <Route path='/driver_logs/:id/edit' component={requireAuthentication(injectProps(MileageView))} />
      <Route path='/driver_logs/:id' component={requireAuthentication(injectProps(MileageShowView))} />
    </Route>
  </Route>
)

export default mileageRoutes
