import './styles/index.scss?global'

import PropTypes from 'prop-types'

import React, { Component } from 'react'
import classnames from 'classnames'

export default class PlusButton extends Component {
  static propTypes = {
    type: PropTypes.string.isRequired,
    fullWidth: PropTypes.bool
  }

  render () {
    const {type, fullWidth, ...props} = this.props
    const classNames = classnames([
      'plus-button',
      'plus-button--' + type,
      fullWidth ? 'plus-button--fullWidth' : ''
    ])
    return (
      <div className={classNames} {...props}>
        { type === 'plus' && <span className='icon-plus-thin' /> }
        { type === 'minus' && <span className='icon-minus-thin' /> }
        { type === 'arrow' && <div className='plus-button__arrow' /> }
      </div>
    )
  }
}
