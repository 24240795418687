import React from 'react'
import PropTypes from 'prop-types'
import Icon from 'shared/components/Icon'

import './styles.scss?global'

const NotActivatedContent = (props) =>
  <div className='not-activated-modal__info'>
    <Icon type='meditator-balls' />
    <div className='market-place__notification'>!</div>
    <h3>{props.t('notActivated', 'stayTuned').s}</h3>
  </div>

NotActivatedContent.propTypes = {
  t: PropTypes.func
}

export default NotActivatedContent
