import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { reduxForm, Field, FieldArray } from 'redux-form'

import Dropdown from 'shared/components/Dropdown/Dropdown'
import Input from 'shared/components/Input/Input'
import PlusButton from 'shared/components/PlusButton/PlusButton'
import Button from 'shared/components/Button/Button'
import DotsSpinner from 'shared/components/DotsSpinner'
import FormatNumber from 'shared/components/FormatNumber/FormatNumber'

import './styles.scss?global'

class SalaryForm extends Component {
  static propTypes = {
    employeesOptions: PropTypes.array.isRequired,
    projectsOptions: PropTypes.array.isRequired,
    paymentOptions: PropTypes.array.isRequired,
    addBenefitHandler: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
    save: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    changeBenefits: PropTypes.func.isRequired,
    calculatedTotals: PropTypes.object.isRequired,
    tButtons: PropTypes.func.isRequired,
    cancelHandler: PropTypes.func.isRequired,
    calculateSalaryHandler: PropTypes.func.isRequired,
    isCalculating: PropTypes.bool.isRequired
  }

  onEmployeeChange = (e) => {
    const { employeesOptions, changeBenefits } = this.props
    const benefits = employeesOptions.find(option => option.value === Number(e.target.value)).benefits
    changeBenefits(benefits)
  }

  onSubmit = (values) => {
    this.props.save(values, this.props.id)
  }

  render () {
    const {
      t, tButtons, employeesOptions, projectsOptions, paymentOptions,
      addBenefitHandler, id, handleSubmit, calculatedTotals,
      calculateSalaryHandler, isCalculating
    } = this.props
    return (
      <div className='form f-column-top salary-form'>
        <div className='salary-form__fields'>
          <div className='form__item'>
            <Field
              name='employeeId'
              component={FormDropdown}
              hint={t('fields', 'person').s}
              big
              emptyOption={t('fields', 'person').s}
              options={employeesOptions}
              onChange={this.onEmployeeChange}
            />
          </div>
          <div className='form__item'>
            <Field
              name='projectId'
              component={FormDropdown}
              hint={t('fields', 'project').s}
              big
              emptyOption={t('fields', 'project').s}
              options={projectsOptions}
              disabled
            />
          </div>
          <div className='form__item'>
            <Field
              name='title'
              component={FormInput}
              placeholder={t('fields', 'title').s}
            />
          </div>
          <h3>{t('salaryOptions').s}</h3>
          <div className='salary-form__section salary-form__row'>
            <Field
              name='netSalary'
              component={FormInput}
              placeholder={t('fields', 'netSalary').s}
              type='number'
              inputMode='decimal'
              onBlur={calculateSalaryHandler}
            />
            <Field
              name='grossSalary'
              component={FormInput}
              placeholder={t('fields', 'grossSalary').s}
              disabled
              type='number'
              inputMode='decimal'
            />
          </div>
          <h3>{t('benefits').s}</h3>
          <div className='salary-form__section'>
            <PlusButton
              view='transparent-black'
              type='plus'
              onClick={addBenefitHandler}
              fullWidth
            />
            <FieldArray name='benefits' component={renderBenefits} t={t} onBlur={calculateSalaryHandler} />
          </div>
          <div className='salary-form__section'>
            <h3>{t('outcome').s}</h3>
            <div className='salary-form__outcome-table'>
              <div className='salary-form__outcome-row'>
                <span>{t('employerTax').s}:</span>
                <span>{isCalculating ? <DotsSpinner /> : <FormatNumber value={calculatedTotals.employerTax} withSymbol />}</span>
              </div>
              <div className='salary-form__outcome-row'>
                <span>{t('totalEmployeeTax').s}:</span>
                <span>{isCalculating ? <DotsSpinner /> : <FormatNumber value={calculatedTotals.totalEmployeeTax} withSymbol />}</span>
              </div>
              <div className='salary-form__outcome-row'>
                <span>{t('totalSalaryCost').s}:</span>
                <span>{isCalculating ? <DotsSpinner /> : <FormatNumber value={calculatedTotals.companyTotalSalaryCost} withSymbol />}</span>
              </div>
            </div>
          </div>
          <div className='form__item'>
            <Field
              name='paymentAccountId'
              component={FormDropdown}
              hint={t('fields', 'paymentMethod').s}
              big
              emptyOption={t('fields', 'paymentMethod').s}
              options={paymentOptions}
            />
          </div>
        </div>
        <div className='f-column-bottom form-buttons'>
          <Button view='transparent-black' saveButton onClick={handleSubmit(this.onSubmit)}>{ id && id !== 'new' ? tButtons('update').s : tButtons('save').s}</Button>
          <Button view='transparent-red' onClick={this.props.cancelHandler}>{tButtons('cancel').s}</Button>
        </div>
      </div>
    )
  }
}

const renderBenefits = ({ fields, t, ...props }) =>
  fields.map((benefit, index) =>
    <div className='salary-form__section salary-form__benefit-row'>
      <span>{fields.get(index).title}</span>
      <Field
        name={`${benefit}.value`}
        component={FormInput}
        placeholder={t('fields', 'benefitValue').s}
        {...props}
      />
      <PlusButton
        view='transparent-black'
        type='minus'
        onClick={() => fields.remove(index)}
      />
    </div>
  )

const FormDropdown = ({ input, meta, ...props }) =>
  <Dropdown {...input} {...meta} {...props} />

FormDropdown.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object
}

const FormInput = ({ input, meta, ...props }) =>
  <Input {...input} {...meta} {...props} />

FormInput.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object
}

const validate = (values) => {
  const errors = {}

  if (!values.employeeId) {
    errors.employeeId = true
  }

  if (!values.paymentAccountId) {
    errors.paymentAccountId = true
  }

  if (!values.netSalary) {
    errors.netSalary = true
  }
  return errors
}

export default reduxForm({
  form: 'salary',
  validate,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  destroyOnUnmount: false,
  onSubmitSuccess: (result, dispatch, props) => props.onSuccess()
})(SalaryForm)
