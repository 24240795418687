// Temporary solution
// Need to refactor FileUpload architecture
// TODO

import URI from 'urijs'

export function convertFromNewApi (files) {
  if (files) {
    return files.map((file) => {
      const uri = file.url ? URI(file.url) : ''
      const name = uri ? decodeURIComponent(uri.filename()) : ''

      return {
        id: 'some-id', // make FileUpload work properly in invoice settings TODO: remove
        mime: file.mime,
        name,
        fullURL: file.url
      }
    })
  } else {
    return []
  }
}
