import {connect} from 'react-redux'

// Views
import TimesheetsImportView from 'Timesheet/views/TimesheetsImportView/TimesheetsImportView'

// Actions
import { addTimeReportsAction } from 'Projects/actions/project'
import { routerActions } from 'react-router-redux'

function mapDispatchToProps (dispatch, ownProps) {
  return Object.assign({}, ownProps, {
    importItemsToForm: (reports) => { dispatch([addTimeReportsAction(reports), routerActions.goBack()]) }
  })
}

export default connect(null, mapDispatchToProps)(TimesheetsImportView)
