import './FurtherActionsModal.scss?global'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'

// Components
import Button from 'shared/components/Button/Button'
import Modal from 'shared/components/NewModal/NewModal'
import Spinner from 'shared/components/Spinner/Spinner'

// Actions
import { redirectToEventsList as redirectToEventsListAction } from 'Events/shared/actions/successMessageBox'
import { redirectToNewRevenueViewAction, redirectToNewExpenseViewAction } from 'OCR/actions'

class AfterUploadModal extends Component {
  static propTypes = {
    eventType: PropTypes.string,
    t: PropTypes.func,
    provideController: PropTypes.object,
    uploadingInProgress: PropTypes.bool,
    redirectToNewExpenseView: PropTypes.func,
    redirectToNewRevenueView: PropTypes.func,
    redirectToEventsList: PropTypes.func
  }

  constructor (props) {
    super(props)
    this._handleClick = this._handleClick.bind(this)
  }

  componentDidMount () {
    const modalInstance = this.modal
    this.props.provideController({
      open: () => modalInstance.open(),
      close: () => modalInstance.close()
    })
  }

  _handleClick (action) {
    return () => {
      action()
      this.modal.close()
    }
  }

  get buttons () {
    const { eventType, t, redirectToNewExpenseView, redirectToNewRevenueView, redirectToEventsList } = this.props
    let buttons = []
    if (eventType === 'expense') {
      buttons.push(<Button view='dark' onClick={this._handleClick(redirectToNewExpenseView)}>{t('addAnotherExpense').s}</Button>)
    } else if (eventType === 'revenue') {
      buttons.push(<Button view='dark' onClick={this._handleClick(redirectToNewRevenueView)}>{t('addAnotherRevenue').s}</Button>)
    }
    buttons.push(<Button view='dark' onClick={this._handleClick(redirectToEventsList)}>{t('viewAll').s}</Button>)

    return (
      <div className='after-upload-modal__buttons'>
        { buttons }
      </div>
    )
  }

  get spinner () {
    return <Spinner dark />
  }

  render () {
    const { uploadingInProgress } = this.props

    return (
      <Modal ref={(c) => { this.modal = c }} hideCloseButton>
        <div className='after-upload-modal'>
          { uploadingInProgress ? this.spinner : this.buttons }
        </div>
      </Modal>
    )
  }
}

function mapStateToProps (state) {
  return {
    t: state.i18n.get('app', 'components', 'AfterUploadModal'),
    uploadingInProgress: state.navbarSpinner.value
  }
}

function mapDispatchToProps (dispatch) {
  return {
    redirectToNewExpenseView: () => { dispatch(redirectToNewExpenseViewAction()) },
    redirectToNewRevenueView: () => { dispatch(redirectToNewRevenueViewAction()) },
    redirectToEventsList: () => { dispatch(redirectToEventsListAction()) }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AfterUploadModal)
