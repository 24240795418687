import PropTypes from 'prop-types'
import React, { Component } from 'react'
import URI from 'urijs'
import { Link } from 'react-router'

// Components
import FormatNumber from 'shared/components/FormatNumber/FormatNumber'

export default class PercentLineChart extends Component {
  static propTypes = {
    project: PropTypes.object,
    params: PropTypes.object,
    location: PropTypes.object,
    getColor: PropTypes.func,
    vat: PropTypes.bool
  }

  render () {
    const {project: {revenues}, params: {projectId}, location: {pathname}, getColor, vat} = this.props

    const render = (data, i) => {
      let {categoryId, categorySum, categoryVatSum, percentForAmount, percentForAmountWithVat, categoryName} = data
      let percent
      const ComponentToUse = categoryId ? Link : 'div'

      if (!vat) {
        categorySum = categorySum - categoryVatSum
        percent = percentForAmount
      } else {
        percent = percentForAmountWithVat
      }

      const financeType = this.props.location.pathname.indexOf('income') !== -1 ? 'revenue' : 'expenses'
      const link = URI(`/projects/${projectId}/finance_details_by_account`).addSearch({accountId: categoryId, projectId, financeType, backLink: pathname}).toString()

      return (
        <ComponentToUse to={link} className='finance-visualization__bar' key={i}>
          <div className='finance-visualization__bar__info'>
            <div className='finance-visualization__bar__line'>
              <span style={{width: `${percent}%`, backgroundColor: getColor(i)}} />
              <h6>{parseInt(percent, 10) || '< 1'}%</h6>
            </div>
            <div className='finance-visualization__bar__info'>
              <div className='truncate'>{categoryName}</div>
              <div><FormatNumber value={categorySum} notFraction /></div>
            </div>
          </div>
          <h4><span className={categoryId ? 'icon-arrow-right' : ''} /></h4>
        </ComponentToUse>
      )
    }

    return (
      <div className='finance-visualization__bar-list'>
        {revenues.map((item, i) => {
          return render(item, i)
        })}
      </div>
    )
  }
}
