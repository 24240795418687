import React from 'react'
import { Route } from 'react-router'

import { requireAuthentication } from 'User/Auth/higherOrderComponents/AuthComponent'

import VacanciesView from 'Vacancies/views/VacanciesView'
import VacancyView from 'Vacancies/views/VacancyView'

const vacanciesRoutes = (
  <Route>
    <Route path='/vacancies' component={requireAuthentication(VacanciesView)} />
    <Route path='/vacancies/:vacancyId' component={requireAuthentication(VacancyView)} />
  </Route>
)

export default vacanciesRoutes
