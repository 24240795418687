import React from 'react'
import { Route } from 'react-router'
import { destroy } from 'redux-form'

import { requireAuthentication } from 'User/Auth/higherOrderComponents/AuthComponent'

import SalariesListView from 'Salaries/views/SalariesListView'
import SalaryEditView from 'Salaries/views/SalaryEditView'
import SalaryPreviewView from 'Salaries/views/SalaryPreviewView'
import SalaryAddBenefitView from 'Salaries/views/SalaryAddBenefitView'

import { clearSalary } from 'Salaries/actions'

const destroySalaryForm = (dispatch) => dispatch([destroy('salary'), clearSalary()])
const salariesRoutes = (
  <Route onLeave={destroySalaryForm}>
    <Route path='/salaries' component={requireAuthentication(SalariesListView)} />
    <Route path='/salaries/new' component={requireAuthentication(SalaryEditView)} />
    <Route path='/salaries/:salaryId' component={requireAuthentication(SalaryPreviewView)} />
    <Route path='/salaries/:salaryId/edit' component={requireAuthentication(SalaryEditView)} />
    <Route path='/salaries/:salaryId/add-benefit' component={requireAuthentication(SalaryAddBenefitView)} />
  </Route>
)

export default salariesRoutes
