import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { reduxForm, Field } from 'redux-form'

// Components
import Navigation from 'Navigation/Navigation'
import Input from 'shared/components/FormInput'
import { WrappedPersonalNumberInput as PersonalNumberInput } from 'shared/components/Input/PersonalNumber'
import Button from 'shared/components/Button/Button'

// Actions
import { updateAccountSettingsAction, getAccountSettingsAction } from 'User/Settings/actions/accountSettings'

// Helpers
import { personalNumberValid } from 'mrshoebox-ui-components/src/helpers/countrySpecific'

export class AccountSettings extends Component {
  static propTypes = {
    updateAccountSettings: PropTypes.func,
    getAccountSettings: PropTypes.func,
    handleSubmit: PropTypes.func,
    t: PropTypes.func,
    t_buttons: PropTypes.func
  }

  constructor (props) {
    super(props)
    this.onSubmit = this.onSubmit.bind(this)
  }

  componentWillMount () {
    this.props.getAccountSettings()
  }

  onSubmit (values) {
    const { updateAccountSettings } = this.props
    updateAccountSettings(values)
  }

  render () {
    const { t, t_buttons, handleSubmit } = this.props
    return (
      <div className='account-settings f-column'>
        <div className='account-settings__form f-column-top'>
          <Field name='name' component={Input} placeholder={t('props', 'name').s} />
          <Field name='phone' component={Input} placeholder={t('props', 'telephone').s} type='tel' />
          <Field name='email' component={Input} placeholder={t('props', 'email').s} type='email' />
          <Field name='personalNumber' component={PersonalNumberInput} placeholder={t('props', 'personalNumber').s} />
        </div>
        <div className='f-column-bottom'>
          <Button saveButton view='transparent-black' onClick={handleSubmit(this.onSubmit)}>{t_buttons('save').s}</Button>
        </div>
      </div>
    )
  }
}

class AccountSettingsWithNavigation extends Component {
  static propTypes = {
    t: PropTypes.func
  }
  render () {
    const {t} = this.props
    return (
      <Navigation hideFooter title={t('title').s} className='f-column'>
        <AccountSettings {...this.props} />
      </Navigation>
    )
  }
}

function mapStateToProps (state) {
  return {
    initialValues: state.userSettings.user,
    t: state.i18n.get('app', 'views', 'AccountSettingsView'),
    t_buttons: state.i18n.get('app', 'shared', 'buttons')
  }
}

function mapDispatchToProps (dispatch) {
  return {
    updateAccountSettings: (userSettings) => { dispatch(updateAccountSettingsAction(userSettings)) },
    getAccountSettings: () => { dispatch(getAccountSettingsAction()) }
  }
}

function validate (values) {
  let errors = {}
  if (!values.name) {
    errors.name = true
  }
  if (!values.email) {
    errors.email = true
  }
  if (values.personalNumber && !personalNumberValid(values.personalNumber)) {
    errors.personalNumber = true
  }

  return errors
}

export const AccountSettingsView = connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: 'account-settings',
  validate
})(AccountSettings))

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: 'account-settings',
  validate
})(AccountSettingsWithNavigation))
