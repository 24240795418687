import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'

import Navigation from 'Navigation/Navigation'
import Input from 'shared/components/Input/Input'
import Icon from 'shared/components/Icon'
import ButtonsBlock from 'MarketPlace/shared/components/ButtonsBlock'
import { withHandlersAndTranslations } from './Common'

import { orgNumberMaskProps } from 'mrshoebox-ui-components/src/helpers/countrySpecific'

import '../index.scss?global'

const CreditsafeOnboarding = (props) =>
  <Navigation hideFooter title={props.t('title').s} notBottomSpace >
    <CreditsafeOnboardingForm {...props} />
  </Navigation>

CreditsafeOnboarding.propTypes = {
  getVadivdatedFields: PropTypes.func.isRequired
}

export const CreditsafeOnboardingForm = ({ t, goBack, startOnboarding, handleSubmit }) =>
  <div className='creditsafe-onboarding'>
    <div className='creditsafe-onboarding__content'>
      <Icon className='creditsafe__logo' type='creditsafe' />
      <div className='creditsafe__instruction-title'>
        {t('instruction', 'title').s}
      </div>
      <ol>
        <div className='creditsafe__step'>
          <Icon className='creditsafe__step-icon' type='creditsafe-icon-1' />
          <div className='creditsafe__instruction'>{t('instruction', 'firstStep').s}</div>
        </div>
        <div className='creditsafe__step'>
          <Icon className='creditsafe__step-icon' type='creditsafe-icon-2' />
          <div className='creditsafe__instruction'>{t('instruction', 'secondStep').s}</div>
        </div>
        <div className='creditsafe__step'>
          <Icon className='creditsafe__step-icon' type='creditsafe-icon-3' />
          <div className='creditsafe__instruction'>{t('instruction', 'thirdStep').s}</div>
        </div>
      </ol>
    </div>
    <div className='creditsafe-onboarding__form-wrapper'>
      <div className='creditsafe-onboarding__white-triangle' />
      <div className='creditsafe-onboarding__form'>
        <div className='creditsafe-onboarding__form-fields'>
          <Field
            placeholder={t('fields', 'userName').s}
            component={InputWrapper}
            name='name'
          />
          <Field
            placeholder={t('fields', 'email').s}
            component={InputWrapper}
            name='email'
            type='email'
          />
          <Field
            placeholder={t('fields', 'mobilePhone').s}
            component={InputWrapper}
            name='mobilePhone'
            type='tel'
          />
          <Field
            placeholder={t('fields', 'orgNumber').s}
            component={InputWrapper}
            name='orgNumber'
            type='number'
            {...orgNumberMaskProps}
          />
        </div>
        <ButtonsBlock
          startButtonText={t('buttons', 'accept').s}
          startHandler={handleSubmit(startOnboarding)}
          cancelButtonText={t('buttons', 'close').s}
          cancelButtonView='transparent-red'
          cancelHandler={goBack}
        />
      </div>
    </div>
  </div>

CreditsafeOnboardingForm.propTypes = {
  goBack: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  startOnboarding: PropTypes.func,
  handleSubmit: PropTypes.func
}

// TODO: Need to make one wrapper for all places where its used.
const InputWrapper = ({input, meta, ...props}) =>
  <Input {...input} {...meta} {...props} />

InputWrapper.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object
}

export default withHandlersAndTranslations(CreditsafeOnboarding)
