import * as eventTypes from 'Events/shared/constants/eventType'

export default function (event, t) {
  if (event.eventType === eventTypes.moneyWithdrawal) {
    return t('documentTypes', 'withdrawal').s
  } else if (event.eventType === eventTypes.transfer) {
    return t('documentTypes', 'accountTransfer').s
  } else if (event.isInvoice && event.invoiceType === eventTypes.INVOICE_TYPE_CREDIT_INVOICE) {
    return t('documentTypes', 'creditInvoice').s
  } else if (event.isInvoice) {
    return t('documentTypes', 'invoice').s
  } else {
    return t('documentTypes', 'receipt').s
  }
}
