import React from 'react'
import PropTypes from 'prop-types'

import InputSwitcher from 'shared/components/InputSwitcher/InputSwitcher'

// Component injects props from redux-form Field component.
// TODO: when migration from redux-form-5 is finished, refactor InputSwitcher component and remove this one.
const FormSwitcher = ({input, ...props}) =>
  <InputSwitcher {...input} {...props} />

FormSwitcher.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  customRef: PropTypes.object
}

export default FormSwitcher
