import './BalanceReports.scss?global'

import Smartphones from 'Overview/BalanceReports/containers/Smartphones'
import Tablets from 'Overview/BalanceReports/containers/Tablets'

import {getCurrentDeviceView} from 'shared/helpers/utils'

const BalanceReportsView = getCurrentDeviceView(Smartphones, Tablets)

export default BalanceReportsView
