import * as Sentry from '@sentry/browser'
import { USER_SETTINGS_ADD } from '../constants/UserSettings'

const initialState = { lang: 'sv' }

export default function (state = initialState, action) {
  if (action.type === USER_SETTINGS_ADD) {
    const newState = { ...state, ...action.payload }
    Sentry.setUser(newState.user)
    return newState
  }
  return state
}
