import React from 'react'

import OpenPaymentsOnboarding from 'Integrations/views/OpenPaymentsOnboardingView'

export default class TodoIntegrationView extends React.Component {
  render () {
    return (
      <OpenPaymentsOnboarding {...this.props} />
    )
  }
}
