import { LOGIN_SUCCESS, GET_DATA_FOR_REMOTE_MOBILE_AUTH } from '../constants/ActionTypes'

export const initialState = {
  isLoggedIn: false,
  loginViaRemoteMobileData: {
    token: '',
    result: {
      autoStartToken: '',
      orderRef: '',
      qrCodeLink: '',
      qrStartToken: ''
    }
  }
}

// TODO: refactor
export default function (state = initialState, action) {
  // fall through
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true
      }
    case GET_DATA_FOR_REMOTE_MOBILE_AUTH:
      const newData = { ...state, ...action.payload }
      return newData
    default:
      return state
  }
}
