import React, { Component } from 'react'
import PropTypes from 'prop-types'

import FactoringMore from 'MarketPlace/Collector/views/FactoringMore/FactoringMore'
import FactoringHOC from 'MarketPlace/Collector/views/shared/FactoringHOC'
import Navigation from 'Navigation/Navigation'

class FactoringMoreView extends Component {
  static propTypes = {
    t: PropTypes.func,
    cancelHandler: PropTypes.func.isRequired
  }

  render () {
    const { t } = this.props
    const navBarTitle = t('FactoringMore', 'navBarTitle').s
    return (
      <div className='factoring-wrapper'>
        <Navigation hideFooter backLink={'/marketplace'} title={navBarTitle} notBottomSpace >
          <FactoringMore {...this.props} />
        </Navigation>
      </div>
    )
  }
}

export default FactoringHOC(FactoringMoreView)
