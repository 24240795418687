import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Navigation from 'Navigation/Navigation'
import Message from 'mrshoebox-ui-components/src/modules/Messaging/components/Message'
import MessageForm from 'Messages/components/MessageForm'
import Common from './Common'
import {
  scrollToContainerBottom,
  scrollTo
} from 'shared/helpers/scrollUtils'

import '../ConversationView.scss?global'

class ChatWithNavigationContainer extends Component {
  static propTypes = {
    companyName: PropTypes.string,
    loadMoreMessagesHandler: PropTypes.func
  }

  render () {
    const { companyName } = this.props
    return (
      <Navigation hideFooter className='f-column' title={companyName || ''} notBottomSpace backLink='/messages' onReachingTopHandler={this.props.loadMoreMessagesHandler}>
        <Chat {...this.props} />
      </Navigation>
    )
  }
}

export class Chat extends Component {
  static propTypes = {
    params: PropTypes.object,
    readOnly: PropTypes.bool,
    messages: PropTypes.array,
    sendMessage: PropTypes.func.isRequired,
    loadConversation: PropTypes.func.isRequired,
    clearCurrentConversation: PropTypes.func,
    firstMessage: PropTypes.instanceOf(Element),
    firstMessageTop: PropTypes.number,
    changeScrollingToLastSeenState: PropTypes.func,
    isScrollingToLastSeen: PropTypes.bool
  }

  state = {
    refreshConversationTimerId: null
  }

  scrollToLastSeenMessage = (previousFirstMessage, previousTop) => {
    const newElementTopPosition = previousFirstMessage.getBoundingClientRect().top
    const newScrollPosition = newElementTopPosition - previousTop

    if (newElementTopPosition !== previousTop) {
      scrollTo(newScrollPosition, { duration: 0 })
      this.props.changeScrollingToLastSeenState()
    }
  }

  sendMessage (message) {
    const { sendMessage, params: { conversationId } } = this.props
    message && sendMessage(conversationId, message)
  }

  refreshConversation (conversationId) {
    const { loadConversation } = this.props
    const timerId = setInterval(() => loadConversation(conversationId), 5000)

    this.setState({ refreshConversationTimerId: timerId })
  }

  componentDidMount () {
    const { params: { conversationId }, loadConversation } = this.props

    loadConversation(conversationId)
    this.refreshConversation(conversationId)
  }

  componentDidUpdate (prevProps) {
    const { params: { conversationId }, loadConversation, clearCurrentConversation } = this.props
    if ((conversationId !== prevProps.params.conversationId) && conversationId) {
      clearCurrentConversation()
      clearInterval(this.state.refreshConversationTimerId)
      loadConversation(conversationId)
      this.refreshConversation(conversationId)
    }

    const { messages } = this.props
    const { messages: previousMessages } = prevProps

    const lastMessage = messages[messages.length - 1] || {}
    const previousLastMessage = previousMessages[previousMessages.length - 1] || {}

    if (messages.length && !previousMessages.length) scrollToContainerBottom()

    if (
      lastMessage && previousLastMessage &&
      lastMessage.createdAt > previousLastMessage.createdAt
    ) scrollToContainerBottom()

    if (this.props.isScrollingToLastSeen) {
      this.scrollToLastSeenMessage(this.props.firstMessage, this.props.firstMessageTop)
    }
  }

  componentWillUnmount () {
    clearInterval(this.state.refreshConversationTimerId)
    this.props.clearCurrentConversation()
  }

  renderMessages () {
    const { messages, params: { conversationId } } = this.props
    return messages.map((message) =>
      <Message
        key={String(message.messageId)}
        text={message.messageBody}
        time={message.createdAt}
        isSender={message.isSender}
        conversationId={conversationId}
        name={message.name}
      />
    )
  }

  render () {
    const { readOnly } = this.props
    return (
      <div className='chat'>
        <div className='chat__messages'>
          {this.renderMessages()}
        </div>
        {!readOnly && (
          <MessageForm
            onSubmit={(values) => this.sendMessage(values.newMessage)}
            name='newMessage'
          />
        )}
      </div>
    )
  }
}

export default Common(ChatWithNavigationContainer)
