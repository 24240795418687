import { bind } from 'redux-effects'
import { camelizeKeys } from 'humps'

import { showNavbarSpinnerAction, hideNavbarSpinnerAction } from 'shared/actions/navbarSpinner'

import { fetchTodoItems } from 'Todo/actions/todo'
import { reloadListAction } from 'Invoices/CustomerInvoices/actions/invoices'

export function loadUnreadCollectorInvoicesAction () {
  return [
    showNavbarSpinnerAction(),
    bind(fetchTodoItems(), loadUnreadCollectorInvoices)
  ]
}

function loadUnreadCollectorInvoices (response) {
  const notifications = camelizeKeys(response.value)
  const filters = { ids: notifications.collectorInvoicesIds, all: true }
  return bind(reloadListAction(filters, hideNavbarSpinnerAction()))
}
