import './styles/index.scss?global'

import Smartphones from 'Invoices/CustomerInvoices/views/InvoiceRotRutView/containers/Smartphones'
import Tablets from 'Invoices/CustomerInvoices/views/InvoiceRotRutView/containers/Tablets'

import {getCurrentDeviceView} from 'shared/helpers/utils'

const InvoiceRotRutView = getCurrentDeviceView(Smartphones, Tablets)

export default InvoiceRotRutView
