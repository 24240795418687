import './SearchField.scss?global'

import PropTypes from 'prop-types'

import React, { Component } from 'react'
import { omit } from 'lodash'

export default class SearchField extends Component {
  static propTypes = {
    placeholder: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.func
    ]),
    value: PropTypes.string,
    onKeyUp: PropTypes.func,
    changeAfterBlur: PropTypes.bool,
    onChange: PropTypes.func,
    onBlur: PropTypes.func
  }

  constructor (props) {
    super(props)
    this.state = {value: ''}
    this.onKeyUpHandler = this.onKeyUpHandler.bind(this)
    this.onChange = this.onChange.bind(this)
    this.onBlur = this.onBlur.bind(this)
  }

  onKeyUpHandler (e) {
    typeof this.props.onKeyUp === 'function' && this.props.onKeyUp(e)
    if (Number(e.keyCode) === 13) {
      e.target.blur()
    }
  }

  componentDidMount () {
    if (this.props.value !== this.state.value) {
      this.setState({ value: this.props.value })
    }
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.value !== this.state.value) {
      this.setState({ value: nextProps.value })
    }
  }

  onChange (e) {
    const {changeAfterBlur, onChange} = this.props
    this.setState({value: e.target.value})
    if (!changeAfterBlur) {
      if (onChange) onChange(e)
    }
  }

  onBlur () {
    const {onChange, changeAfterBlur, onBlur} = this.props
    if (changeAfterBlur && onChange) {
      onChange(this.state.value)
    } else if (onBlur) {
      onBlur(this.state.value)
    }
  }

  omitWrongProps (props) {
    return omit(props, [
      'initialValue', 'autofill', 'onUpdate', 'valid', 'changeAfterBlur', 'asyncValidating', 'dispatch',
      'invalid', 'dirty', 'pristine', 'active', 'touched', 'visited', 'autofilled', 'submitFailed'
    ])
  }

  render () {
    const {placeholder} = this.props
    return (
      <div className='search-field'>
        <input
          autoCorrect='off'
          autoCapitalize='off'
          type='text'
          {...this.omitWrongProps(this.props)}
          value={this.state.value}
          placeholder=''
          onKeyUp={this.onKeyUpHandler}
          onChange={this.onChange}
          onBlur={this.onBlur} />
        <div className={`search-field__placeholder ${this.state.value ? 'search-field__placeholder--hide' : ''}`}>
          <span className='icon-loupe' />
          <span>{placeholder}</span>
        </div>
      </div>
    )
  }
}
