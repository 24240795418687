import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { routerActions } from 'react-router-redux'
import { isEmpty } from 'lodash'

import { sendMessageAction, sendReminderAction } from '../../../actions/invoiceSend'
import { invoiceGetAction, invoiceClearAction } from '../../../actions/invoiceCreate'

import { showElementInMessageBoxAction } from 'MessageBox/actions/messageBox'
import { ROT } from 'Invoices/shared/constants/RotRutTypes'

import ErrorsList from 'Invoices/CustomerInvoices/components/ErrorsList/ErrorsList'

export default function InvoiceSendContainer (Component) {
  class InvoiceSend extends React.Component {
    static propTypes = {
      clearInvoice: PropTypes.func.isRequired,
      goBack: PropTypes.func.isRequired,
      invoice: PropTypes.object.isRequired,
      invoiceNumber: PropTypes.number,
      loadInvoice: PropTypes.func.isRequired,
      location: PropTypes.object.isRequired,
      params: PropTypes.object,
      sendMessage: PropTypes.func.isRequired,
      showModal: PropTypes.func.isRequired,
      sendReminder: PropTypes.func.isRequired
    }

    componentDidMount () {
      const { params: { id }, loadInvoice } = this.props
      loadInvoice(id)
    }

    componentWillUnmount () {
      this.props.clearInvoice()
    }

    get errorList () {
      const { invoice, t } = this.props
      let errors = []

      const fields = ['invoiceNumber', 'invoiceDate', 'dueDate']
      fields.forEach((field) => {
        if (!invoice[field]) errors.push(t('fields', field).s)
      })

      if (isEmpty(invoice.customer)) {
        errors.push(t('fields', 'customer').s)
      }

      if (!invoice.invoiceRows.length) errors.push(t('fields', 'specification').s)

      if (!(invoice.customer && invoice.customer.email)) errors.push(t('fields', 'customerEmail').s)

      if ((invoice.euVat || invoice.invertedVat) && !invoice.customerVatNumber) {
        errors.push(t('fields', 'customerVatNumber').s)
      }

      if (!isEmpty(invoice.rotRutObject)) {
        const { personalNumber, buildingNumber, rotRutType } = invoice.rotRutObject
        const isROT = rotRutType === ROT

        if (!personalNumber) errors.push(t('fields', 'personalNumber').s)
        if (isROT && !buildingNumber) errors.push(t('fields', 'buildingNumber').s)
      }

      return errors
    }

    sendAction (action, actionPayload) {
      const { t } = this.props
      if (this.errorList.length) {
        this.props.showModal(
          <ErrorsList errors={this.errorList} title={t('errorList', 'title').s} description={t('errorList', 'description').s} />
        )
      } else {
        action && typeof action === 'function' && action(actionPayload)
      }
    }

    get formProps () {
      const { t, sendMessage, sendReminder, location: { query } } = this.props

      if (!query.reminder) {
        return {
          title: t('sendMessage').s,
          sendEmailHandler: (values) => this.sendAction(sendMessage, values)
        }
      } else {
        return {
          title: t('sendReminder').s,
          sendEmailHandler: (values) => this.sendAction(sendReminder, values)
        }
      }
    }
    render () {
      return (
        <Component {...this.formProps} {...this.props} />
      )
    }
  }

  const mapStateToProps = (state) => ({
    t: state.i18n.get('app', 'views', 'Invoices', 'InvoiceSendView'),
    invoice: state.invoices.customerInvoices.invoice
  })

  const mapDispatchToProps = (dispatch) => ({
    clearInvoice: () => dispatch(invoiceClearAction()),
    goBack: () => dispatch(routerActions.goBack()),
    loadInvoice: (id) => dispatch(invoiceGetAction(id)),
    sendMessage: (message) => dispatch(sendMessageAction(message)),
    sendReminder: (message) => dispatch(sendReminderAction(message)),
    showModal: (element) => dispatch(showElementInMessageBoxAction(element))
  })

  return connect(mapStateToProps, mapDispatchToProps)(InvoiceSend)
}
