import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import cs from 'classnames'

import styles from './styles.scss'

// Components
import Icon from 'shared/components/Icon'

// Helpers
import getGreyLabelOptions from 'GreyLabel/helpers/greyLabel'

class Container extends PureComponent {
  static propTypes = {
    children: PropTypes.node,
    background: PropTypes.string
  }

  get logo () {
    const { logoClass } = getGreyLabelOptions()

    return <Icon className={styles[logoClass]} type={logoClass} />
  }

  render () {
    const background = this.props.background
    const dynamicStyles = background && { backgroundImage: `url(/${background})` }

    return (
      <div className={styles.container} style={dynamicStyles}>
        <div className={styles.logo}>
          { this.logo }
        </div>
        { this.props.children }
      </div>
    )
  }
}

class UpperContent extends PureComponent {
  static propTypes = {
    children: PropTypes.node,
    withoutShadow: PropTypes.bool,
    navyText: PropTypes.bool
  }

  render () {
    const classNames = cs(styles.upper, {
      [styles.withoutShadow]: this.props.withoutShadow,
      [styles.navyText]: this.props.navyText
    })

    return (
      <div className={classNames}>
        { this.props.children }
      </div>
    )
  }
}

class BottomContent extends PureComponent {
  static propTypes = {
    children: PropTypes.node,
    notTransparent: PropTypes.bool
  }

  render () {
    const { whiteLabel } = getGreyLabelOptions()
    const classNames = cs(styles.bottom, { [styles.notTransparent]: this.props.notTransparent }, styles[whiteLabel])

    return (
      <div className={classNames}>
        { this.props.children }
      </div>
    )
  }
}

export {
  Container,
  UpperContent,
  BottomContent
}
