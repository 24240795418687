import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { isEqual } from 'lodash'
import { getFormValues } from 'redux-form'

// Actions
import { reloadListAction, extendListAction } from 'Articles/actions/articles'
import { addItemAction, removeItemAction, removeAllImportItems } from 'ImportItems/actions/importItems'

export default function ArticlesImportContainer (Component) {
  class ArticlesImport extends React.Component {
    static propTypes = {
      reloadList: PropTypes.func,
      clearItems: PropTypes.func,
      filters: PropTypes.object
    }

    componentDidMount () {
      this.props.reloadList()
    }

    componentDidUpdate (prevProps) {
      const { filters } = this.props
      if (prevProps.filters && !isEqual(prevProps.filters, filters)) {
        this.props.reloadList()
      }
    }

    componentWillUnmount () {
      this.props.clearItems()
    }

    render () {
      return (
        <Component {...this.props} />
      )
    }
  }

  function mapStateToProps (state) {
    return {
      previousLink: state.appInfo.previousLink,
      itemsToImport: state.importItems,
      filters: getFormValues('articlesViewFilters')(state),
      articles: state.articles.listData,
      t: state.i18n.get('app', 'views', 'Articles', 'ArticlesView')
    }
  }

  function mapDispatchToProps (dispatch) {
    return {
      onCheck: (data) => { dispatch(addItemAction(data)) },
      onUncheck: (data) => { dispatch(removeItemAction(data)) },
      clearItems: () => { dispatch(removeAllImportItems()) },
      reloadList: () => { dispatch(reloadListAction()) },
      extendList: () => { dispatch(extendListAction()) }
    }
  }

  return connect(mapStateToProps, mapDispatchToProps)(ArticlesImport)
}
