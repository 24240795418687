import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'

import Navigation from 'Navigation/Navigation'
import CollectorLayout from 'MarketPlace/Collector/components/CollectorLayout'
import OnboardingStatus from 'MarketPlace/Collector/views/OnboardingStatus'

import withRequests from './withRequestsHOC'

class TodoOnboardingStatus extends Component {
  static propTypes = {
    status: PropTypes.string.isRequired
  }

  render () {
    return (
      <div className='todo-onboarding-wrapper'>
        <Navigation title={this.props.t('navbar', 'title').s} backLink={'/todo'} notBottomSpace >
          <Helmet>
            <meta
              name='viewport'
              content='width=640, maximum-scale=1.0, user-scalable=no'
            />
          </Helmet>
          <CollectorLayout>
            <OnboardingStatus status={this.props.status} />
          </CollectorLayout>
        </Navigation>
      </div>
    )
  }
}

export default withRequests(TodoOnboardingStatus)
