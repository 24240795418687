/*eslint-disable */
import React from 'react'

import getGreyLabelOptions from 'GreyLabel/helpers/greyLabel'

export default class GdprTerms extends React.Component {
  get mrShoeboxTerms () {
    return (
      <div className='registration-terms'>
      </div>
    )
  }

  get digitalaForetagetTerms () {
    return (
      <div className='registration-terms blinfo'>
        <div>
          <p><b> Personuppgiftsbiträdesavtal med Björn Lundén Information AB </b></p>
        </div>
        <div>
          <p>Enligt GDPR kommer Björn Lundén Information att agera som personuppgiftsbiträde till er som kund. Dels genom att vi lagrar er data om ni använder någon av våra molntjänster, dels genom att vi agerar som biträde om vi fjärrstyr er dator eller tar in filer från er för åtgärd i vår support. För att reglera våra åtaganden enligt GDPR behövs därför ett personuppgiftsbiträdesavtal med er som kund.</p>
        </div>
        <br />
        <div className='text-center'>
          <a href="https://gdprsign.blinfo.se/avtal.pdf"> https://gdprsign.blinfo.se/avtal.pdf </a>
        </div>
        <br />
      </div>
    )
  }

  render () {
    const greyLabelOptions = getGreyLabelOptions()
    // if (greyLabelOptions.greyLabel === 'blinfo') {
      return this.digitalaForetagetTerms
    // } else if (!greyLabelOptions.greyLabel) {
    //   return this.mrShoeboxTerms
    // } else {
    //   return null
    // }
  }
}
/* eslint-enable */
