import React, {Component} from 'react'

import IpadNavigation from 'Navigation/IpadNavigation'
import ProjectsView from 'Projects/views/ProjectsView/containers/Smartphones'

export default class TodoMessagesView extends Component {
  render () {
    return (
      <IpadNavigation {...this.props} onlyLeftSide setCurrentLink>
        <ProjectsView {...this.props} />
      </IpadNavigation>
    )
  }
}
