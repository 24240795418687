import {bind} from 'redux-effects'
import {createAction} from 'redux-actions'
import { getFormValues } from 'redux-form'

import {fetch2} from 'shared/helpers/fetch'
import {showNavbarSpinnerAction, hideNavbarSpinnerAction} from 'shared/actions/navbarSpinner'

import { REPORTS_LIST_UPDATE, REPORTS_LIST_CLEAN } from '../constants/ActionTypes'
import { FINANCIAL_REPORTS, TAX_REPORT, ANNUAL_REPORTS } from '../constants/Api'

import { initialFilterValues as taxPaymentReportDefaultsFilters } from 'Reports/views/TaxPaymentReport/TaxPaymentReportsView/components/Filters'
import { initialFilterValues as pdfReportsDefaultsFilters } from 'Reports/views/FinancialReport/FinancialReportsView/components/Filters'
import { initialFilterValues as annualReportsDefaultsFilters } from 'Reports/views/AnnualReport/AnnualReportsView/components/Filters'

const updateReportsList = createAction(REPORTS_LIST_UPDATE)
export const cleanReportsListAction = createAction(REPORTS_LIST_CLEAN)

export function loadTaxPaymentReportsListAction (currentList, pageToLoad, additionalOpts = {}, backLink) {
  return (dispatch, getState) => {
    const filtersFromForm = getFormValues('taxReportsFilters')(getState()) || taxPaymentReportDefaultsFilters
    const filters = {
      ...filtersFromForm,
      ...additionalOpts
    }

    return dispatch([
      showNavbarSpinnerAction(),
      bind(fetchTaxReports(filters, pageToLoad), processTaxPaymentReportsList.bind(null, currentList, backLink), processError)
    ])
  }
}

function fetchTaxReports (filters, pageToLoad) {
  const requestParams = {
    p: pageToLoad,
    paid: filters.isPaid,
    sort: `${filters.orderBy} ${filters.orderType}`,
    'filter[comment]': filters.search
  }

  return fetch2(TAX_REPORT, requestParams)
}

export function loadFinancialReportsListAction (currentList, pageToLoad, additionalOpts = {}) {
  return (dispatch, getState) => {
    const filters = getFormValues('pdfReportsFilters')(getState()) || pdfReportsDefaultsFilters

    return dispatch([
      showNavbarSpinnerAction(),
      bind(loadFinancialReports(filters, pageToLoad, additionalOpts), processFinancialReportsList.bind(null, currentList), processError)
    ])
  }
}

function loadFinancialReports (filters, pageToLoad, additionalOpts) {
  const params = {
    sort: `${filters.orderBy} ${filters.orderType}`,
    'filter[title]': filters.search,
    p: pageToLoad
  }
  if (additionalOpts.showUnread) {
    params['filter[reporter_has_read]'] = false
  }

  return fetch2(FINANCIAL_REPORTS, params)
}

export function loadAnnualReportsListAction (currentList, pageToLoad, additionalOpts = {}) {
  return (dispatch, getState) => {
    const filters = getFormValues('annualReportsFilters')(getState()) || annualReportsDefaultsFilters

    return dispatch([
      showNavbarSpinnerAction(),
      bind(loadAnnualReports(filters, pageToLoad, additionalOpts), processAnnualReportsList.bind(null, currentList), processError)
    ])
  }
}

function loadAnnualReports (filters, pageToLoad, additionalOpts) {
  const params = {
    sort: `${filters.orderBy} ${filters.orderType}`,
    'filter[title]': filters.search,
    p: pageToLoad
  }
  if (additionalOpts.showUnread) {
    params['filter[reporter_has_read]'] = false
  }

  return fetch2(ANNUAL_REPORTS, params)
}

function processTaxPaymentReportsList (currentList = [], backLink, response) {
  console.log(response)
  const reportsInResponse = response.value.data
  const mappedReports = reportsInResponse.map((report) => {
    const reportId = report.id
    return {
      approved: report.paid_at !== null,
      date: report.created_at,
      title: report.comment,
      id: report.id,
      amount: report.amount,
      path: backLink ? `${backLink}/${reportId}` : `/reports/tax_payment/${reportId}`
    }
  })
  const hasNextPage = response.value.overall_pages > response.value.current_page

  return [updateReportsList({listData: currentList.concat(mappedReports), lastPageLoaded: response.value.current_page, hasNextPage}), hideNavbarSpinnerAction()]
}

function processFinancialReportsList (currentList, response) {
  console.log(response)
  const reportsInResponse = response.value.data
  const mappedReports = reportsInResponse.map((report) => {
    return {
      id: report.id,
      date: report.created_at,
      period: report.title,
      path: `/reports/financial/${report.id}`
    }
  })
  return [
    updateReportsList({
      listData: currentList.concat(mappedReports),
      lastPageLoaded: response.value.current_page,
      hasNextPage: response.value.current_page < response.value.overall_pages
    }),
    hideNavbarSpinnerAction()
  ]
}

function processAnnualReportsList (currentList, response) {
  console.log(response)
  const reportsInResponse = response.value.data
  const mappedReports = reportsInResponse.map((report) => {
    return {
      id: report.id,
      date: report.created_at,
      period: report.title,
      path: `/reports/annual/${report.id}`
    }
  })
  return [
    updateReportsList({
      listData: currentList.concat(mappedReports),
      lastPageLoaded: response.value.current_page,
      hasNextPage: response.value.current_page < response.value.overall_pages
    }),
    hideNavbarSpinnerAction()
  ]
}

function processError (response) {
  console.log('something went wrong on fetching reports :(', response)
}

export function fetchUnpaidTaxReports () {
  return fetch2(TAX_REPORT, { paid: false, all: true })
}

export function fetchUnreadPDFReports () {
  return fetch2(FINANCIAL_REPORTS, { 'filter[reporter_has_read]': false, all: true })
}

export function fetchUnreadAnnualReports () {
  return fetch2(ANNUAL_REPORTS, { 'filter[reporter_has_read]': false, all: true })
}
