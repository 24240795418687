import PropTypes from 'prop-types'
import React, { Component } from 'react'
import {connect} from 'react-redux'

// Actions
import { setNotificationVisibleAction } from 'AppInfo/actions/appInfo'

export class NavigationInfoBlock extends Component {
  static propTypes = {
    internetIsLost: PropTypes.bool,
    tMessage: PropTypes.func,
    onClose: PropTypes.func,
    setNotificationVisible: PropTypes.func,
    notificationVisible: PropTypes.bool
  }

  constructor (props) {
    super(props)
    this.state = {isClosed: null}
    this.onClose = this.onClose.bind(this)
  }

  onClose () {
    this.setState({isClosed: true})
    this.props.setNotificationVisible(false)
    if (this.props.onClose) {
      this.props.onClose()
    }
  }

  render () {
    const {internetIsLost, tMessage, notificationVisible} = this.props
    if (internetIsLost && this.state.isClosed !== true && notificationVisible !== false) {
      return (
        <div className='navigation-notification'>
          <div>
            <h3>{tMessage('internetConnectionLost', 'description').s}</h3>
            <span className='icon-close-round' onClick={this.onClose} />
          </div>
        </div>
      )
    } else {
      return null
    }
  }
}

function mapStateToProps (state) {
  return {
    tMessage: state.i18n.get('app', 'shared', 'messages'),
    internetIsLost: state.appInfo.internetIsLost,
    notificationVisible: state.appInfo.notificationVisible
  }
}

function mapDispatchToProps (dispatch) {
  return {
    setNotificationVisible: (val) => { dispatch(setNotificationVisibleAction(val)) }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NavigationInfoBlock)
