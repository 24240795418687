import React, {Component} from 'react'

import Articles from 'Articles/views/ArticlesView/containers/Smartphones'
import IpadNavigation from 'Navigation/IpadNavigation'

export default class ArticlesView extends Component {
  render () {
    return (
      <IpadNavigation {...this.props} onlyLeftSide setCurrentLink>
        <Articles {...this.props} />
      </IpadNavigation>
    )
  }
}
