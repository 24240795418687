import { createAction } from 'redux-actions'

import { showSlowNavbarSpinnerAction, hideNavbarSpinnerAction } from 'shared/actions/navbarSpinner'
import { showMessageBoxWithParamsAction } from 'MessageBox/actions/messageBox'

import { getContactsFromPhonebook } from 'appBridge/getters'

import { ADD_PHONEBOOK_CONTACTS, CLEAR_PHONEBOOK_CONTACTS, MARK_CONTACT_AS_CHECKED, MARK_CONTACT_AS_UNCHECKED } from 'appBridge/PhoneBook/constants/ActionTypes'

const addContacts = createAction(ADD_PHONEBOOK_CONTACTS)
export const clearContactsAction = createAction(CLEAR_PHONEBOOK_CONTACTS)
export const markContactAsCheckedAction = createAction(MARK_CONTACT_AS_CHECKED)
export const markContactAsUncheckedAction = createAction(MARK_CONTACT_AS_UNCHECKED)

export function loadContactsListAction () {
  // const fakeList = []
  // for (let i = 0; i < 5000; i++) {
  //   fakeList.push({ name: `foo ${i}`, id: i, isChecked: false })
  // }
  //
  // return addContacts(fakeList)
  return (dispatch, getState) => {
    dispatch(showSlowNavbarSpinnerAction())
    getContactsFromPhonebook()
      .then((contacts) => {
        const mappedContacts = contacts.map((contact, index) => {
          return {
            name: contact.name,
            company: contact.organization,
            index,
            isChecked: false
          }
        })

        dispatch([addContacts(mappedContacts), hideNavbarSpinnerAction()])
      })
      .catch(() => {
        const t = getState().i18n.get('app', 'shared', 'messages', 'unexpectedError')
        dispatch([
          hideNavbarSpinnerAction(),
          showMessageBoxWithParamsAction(t('title').s, t('description').s)
        ])
      })
  }
}
