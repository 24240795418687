import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { routerActions } from 'react-router-redux'

// Actions
import {loadProjectRevenuesAction, projectInfoClearAction} from 'Projects/actions/project'

// Constants
import chartColors from 'Overview/constants/ChartColors'

import {scaleOrdinal} from 'd3-scale'

export default function FinanceVisualizationContainer (Component) {
  class FinanceVisualization extends React.Component {
    static propTypes = {
      getProjectRevenues: PropTypes.func,
      projectInfoClear: PropTypes.func
    }

    constructor (props) {
      super(props)
      this.state = {vat: true}
      this.getColor = scaleOrdinal(chartColors)
      this.onVATModeChange = this.onVATModeChange.bind(this)
    }

    componentDidMount () {
      this.props.getProjectRevenues(Number(this.state.vat))
    }

    componentWillUnmount () {
      this.props.projectInfoClear()
    }

    onVATModeChange (value) {
      this.setState({vat: value})
    }

    render () {
      return (
        <Component {...this.props} getColor={this.getColor} vat={this.state.vat} onVATModeChange={this.onVATModeChange} />
      )
    }
  }

  function mapStateToProps (state) {
    return {
      project: state.project,
      t: state.i18n.get('app', 'views', 'ProjectView', 'overview')
    }
  }

  function mapDispatchToProps (dispatch, ownProps) {
    const { params: { projectId }, location: { pathname } } = ownProps
    const page = ownProps.route.path.split('/').pop()
    return {
      changeChartType: (chartType) => { dispatch(routerActions.replace(`${pathname}?chart_type=${chartType}`)) },
      getProjectRevenues: () => { dispatch(loadProjectRevenuesAction(page, projectId)) },
      projectInfoClear: () => { dispatch(projectInfoClearAction()) }
    }
  }

  return connect(mapStateToProps, mapDispatchToProps)(FinanceVisualization)
}
