import './styles/index.scss?global'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import cs from 'classnames'

import {isIpadResolution} from 'shared/helpers/utils'

import getGreyLabelOptions from 'GreyLabel/helpers/greyLabel'

export default class Modal extends Component {
  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
    onClose: PropTypes.func,
    showOpen: PropTypes.bool,
    hideCloseButton: PropTypes.bool,
    leftMenu: PropTypes.bool,
    customClass: PropTypes.string,
    small: PropTypes.bool
  }

  constructor (props) {
    super(props)
    this.state = { visible: props.showOpen }
    this.onCloseClick = this.onCloseClick.bind(this)
  }

  setLeftNavigationOverBlocks (nextVisible, currentVisible) {
    if (isIpadResolution() && this.props.leftMenu && nextVisible !== currentVisible) {
      const nav = document.getElementsByClassName('navigation')
      if (!nav.length) {
        return
      }
      const toggleClass = () => {
        [].forEach.call(nav, (elem) => {
          elem.classList.toggle('navigation--over', nextVisible)
        })
      }
      if (nextVisible === false) {
        setTimeout(() => {
          toggleClass()
        }, 350)
      } else {
        toggleClass()
      }
    }
  }

  fixScrollBlockElement (nextVisible, currentVisible) {
    const scrollBlock = document.getElementsByClassName('scrollBlock__content')
    if (!scrollBlock.length) {
      return
    }
    const toggleClass = () => {
      [].forEach.call(scrollBlock, (elem) => {
        elem.classList.toggle('scrollBlock__content--no-smooth', nextVisible)
      })
    }
    if (nextVisible !== currentVisible) {
      if (nextVisible === false) {
        setTimeout(() => {
          toggleClass()
        }, 350)
      } else {
        toggleClass()
      }
    }
  }

  open () {
    this.setState({ visible: true })
  }

  close () {
    this.setState({ visible: false })
  }

  componentWillUpdate (nextProps, nextState) {
    const nextVisible = nextState.visible
    const currentVisible = this.state.visible

    this.fixScrollBlockElement(nextVisible, currentVisible)
    this.setLeftNavigationOverBlocks(nextVisible, currentVisible)
  }

  onCloseClick () {
    const { onClose } = this.props
    this.setState({ visible: false }, () => {
      onClose && onClose()
    })
  }

  render () {
    const { children, hideCloseButton, customClass, small } = this.props
    const { visible } = this.state
    const { mainTextColor, whiteLabel } = getGreyLabelOptions()
    const modalClass = cs(
      'new-modal',
      whiteLabel,
      visible && 'new-modal-visible',
      small && 'new-modal-small',
      customClass
    )
    return (
      <div className={modalClass}>
        <div className={`new-modal-content ${mainTextColor ? whiteLabel : ''}`}>
          { children }
          { !hideCloseButton &&
            <div className={`new-modal-close-button ${mainTextColor ? whiteLabel : ''}`}>
              <span className='icon-close-round' onClick={this.onCloseClick} />
            </div>
          }
        </div>
      </div>
    )
  }
}
