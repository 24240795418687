import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { findDOMNode } from 'react-dom'
import PinchZoom from 'vanilla-pinch'

class ZoomableContent extends Component {
  static propTypes = {
    children: PropTypes.element
  };

  componentDidMount () {
    this.zoomable = new PinchZoom(findDOMNode(this))
    this.zoomable.enable()
  }

  componentWillUnmount () {
    this.zoomable.disable()
  }

  render () {
    return <div>
      { this.props.children }
    </div>
  }
}

export default ZoomableContent
