import React from 'react'
import { Route } from 'react-router'

import {requireAuthentication} from 'User/Auth/higherOrderComponents/AuthComponent'

import FileView from 'FileUpload/views/FileView/FileView'

const fileRoutes = (
  <Route>
    <Route path='/file' component={requireAuthentication(FileView)} />
  </Route>
)

export default fileRoutes
