import { bind } from 'redux-effects'
import { fetch2 as fetch } from '../../../shared/helpers/fetch'
import { change } from 'redux-form'

import { updateSuggestionList } from '../../../shared/components/SuggestionInput/Async/reducer'
import { COMPANY_NAMES_AUTOCOMPLETE, CUSTOMER_COMPANY_INFO } from '../constants/Api'

export function loadCompanyNameSuggestions (value) {
  return (dispatch) => {
    return dispatch(bind(getCompanyNameSuggestions(value), updateSuggestions))
  }
}

export function updateCustomerCompanyInfo (key) {
  return bind(getCompanyInfo(key), updateCustomerInfo)
}

function getCompanyNameSuggestions (name) {
  return fetch(COMPANY_NAMES_AUTOCOMPLETE({ name }))
}

function getCompanyInfo (key) {
  return fetch(CUSTOMER_COMPANY_INFO({ key }))
}

function updateCustomerInfo (response) {
  return [
    change('customer', 'orgNumber', response.value.org_number),
    change('customer', 'vatNumber', response.value.vat_number),
    change('customer', 'address', response.value.address),
    change('customer', 'zipcode', response.value.zip_code),
    change('customer', 'city', response.value.town),
    change('customer', 'country', response.value.country)
  ]
}

function updateSuggestions (response) {
  return updateSuggestionList({
    key: 'customerCompanyName',
    data: response.value.map((s) => ({
      name: s.name,
      overviewKey: s.overview_key
    }))
  })
}
