import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import IpadNavigation from 'Navigation/IpadNavigation'
import {InvoiceDiscountView as InvoiceDiscount} from './Smartphones'

// Helpers
import getLeftComponent from 'Navigation/helpers/linkAccordance'
import { getTrimmedLink } from 'shared/helpers/linkUtils'

export class InvoiceDiscountView extends Component {
  static propTypes = {
    t: PropTypes.func,
    location: PropTypes.object,
    previousLink: PropTypes.string
  }

  render () {
    const {t, location: {pathname}, previousLink} = this.props
    const Events = getLeftComponent('invoice', previousLink)

    return (
      <IpadNavigation {...this.props} backLink={getTrimmedLink(pathname, 1)} title={t('title').s}>
        <Events {...this.props} />
        <InvoiceDiscount {...this.props} />
      </IpadNavigation>
    )
  }
}

function mapStateToProps (state) {
  return {
    t: state.i18n.get('app', 'views', 'Invoices', 'InvoiceDiscountView'),
    previousLink: state.appInfo.previousLink
  }
}

export default connect(mapStateToProps)(InvoiceDiscountView)
