import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import IpadNavigation from 'Navigation/IpadNavigation'
import { SupplierView } from './Smartphones'

import getLeftComponent from 'Navigation/helpers/linkAccordance'

export class Supplier extends Component {
  static propTypes = {
    supplier: PropTypes.object,
    t: PropTypes.func,
    previousLink: PropTypes.string
  }

  render () {
    const {supplier, previousLink, t} = this.props
    const RightSideItems = getLeftComponent('createSupplier', previousLink)
    const actions = supplier.id ? [{icon: 'icon-trash', callback: this.refs.supplier.getWrappedInstance().showModalBox}] : null
    return (
      <IpadNavigation {...this.props} title={supplier.name || t('navbar', 'title').s} actions={actions} backLink>
        <RightSideItems {...this.props} />
        <SupplierView {...this.props} ref='supplier' />
      </IpadNavigation>
    )
  }
}

function mapStateToProps (state) {
  return {
    t: state.i18n.get('app', 'views', 'Suppliers', 'SupplierView'),
    supplier: state.suppliers.supplier,
    previousLink: state.appInfo.previousLink
  }
}

export default connect(mapStateToProps)(Supplier)
