import React, { Component } from 'react'
import { connect } from 'react-redux'

import IpadNavigation from 'Navigation/IpadNavigation'
import Salaries from 'Salaries/views/SalariesListView/containers/Smartphones'
import { AddBenefitView as AddBenefit } from 'Salaries/views/SalaryAddBenefitView/containers/Smartphones'

class AddBenefitView extends Component {
  render () {
    return (
      <IpadNavigation {...this.props} title={this.props.t('navbar', 'title').s}>
        <Salaries />
        <AddBenefit {...this.props} />
      </IpadNavigation>
    )
  }
}

const mapStateToProps = (state) => ({
  t: state.i18n.get('app', 'views', 'Salaries', 'AddBenefitView')
})

export default connect(mapStateToProps)(AddBenefitView)
