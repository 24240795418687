import './FileListUpload.scss?global'

import PropTypes from 'prop-types'

import React, { Component } from 'react'
import {connect} from 'react-redux'

import semverCompare from 'semver-compare'
import { isIOSBridge, isAndroidBridge, postAsyncMessage } from 'appBridge/bridge'

import FileInput from 'FileUpload/components/FileInput/FileInput'
import FileList from 'FileUpload/components/FileList/FileList'
import FilePreviewList from 'FileUpload/components/FilesPreviewList/FilesPreviewList'
import Input from 'shared/components/Input/Input'
import PlusButton from 'shared/components/PlusButton/PlusButton'
import Button from 'shared/components/Button/Button'

import { loadFileFromExtension, addFilesAction, loadCurrentFilesAction, clearFilesAction, saveCurrentPathAction, forceDeleteFilesAction } from 'FileUpload/actions/filesToUpload'
import getGreyLabelOptions from 'GreyLabel/helpers/greyLabel'

export class FileListWithUploader extends Component {
  static propTypes = {
    files: PropTypes.arrayOf(PropTypes.object),
    newFiles: PropTypes.arrayOf(PropTypes.object),
    currentFiles: PropTypes.arrayOf(PropTypes.object),
    addFileToState: PropTypes.func,
    loadCurrentFiles: PropTypes.func,
    editMode: PropTypes.bool,
    clearFiles: PropTypes.func,
    forceDeleteFiles: PropTypes.func,
    saveCurrentPath: PropTypes.func,
    onlyOne: PropTypes.bool,
    hint: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    t: PropTypes.func,
    fileInputId: PropTypes.string,
    disabled: PropTypes.bool,
    error: PropTypes.bool,
    wrapperVersion: PropTypes.string,
    loadFileFromExtension: PropTypes.func,
    path: PropTypes.string
  };

  static defaultProps = {
    fileInputId: 'file-uploader'
  }

  componentDidUpdate (prevProps) {
    const { files, forceDeleteFiles, path, saveCurrentPath } = this.props
    if (files.length === 0 && files.length !== prevProps.files.length) {
      forceDeleteFiles()
    } else {
      this.initialUploadFiles()
    }

    if (path !== window.location.pathname) {
      // In some cases after reseting file uploader state user keeps working with component.
      // But when user leaves page and returns - state resets on mount because of undefined path.
      // This fixes it.
      saveCurrentPath(window.location.pathname)
    }
  }

  componentDidMount () {
    this.initialUploadFiles()
  }

  componentWillMount () {
    if (this.props.path !== window.location.pathname) {
      this.props.forceDeleteFiles()
      // Need to reset uploader state before user starts to work with another document.
      // Because sometimes there are cases where user leaves page without clearing state. (leaving to add supplier, representation, etc.)
    }
    this.props.saveCurrentPath(window.location.pathname)
  }

  componentWillUnmount () {
    this.props.clearFiles(window.location.pathname)
  }

  initialUploadFiles () {
    const {files, currentFiles, loadCurrentFiles, editMode} = this.props
    if (files.length > 0 && currentFiles.length === 0 && !editMode) {
      loadCurrentFiles(files)
    }
  }

  get fileUploader () {
    const { t, fileInputId, error, wrapperVersion, onlyOne, hint, loadFileFromExtension } = this.props
    const withCaptureButton = wrapperVersion &&
      ((isIOSBridge() && semverCompare(wrapperVersion, '1.2.15') >= 0) || (isAndroidBridge() && semverCompare(wrapperVersion, '1.2.14') >= 0))

    if (onlyOne) {
      return (
        <label htmlFor={fileInputId}>
          <div className='form__input-plus'>
            <Input value={t('value').s} placeholder={hint || t('hint').s} error={error} readOnly />
            <PlusButton type='plus' />
          </div>
          <FileInput onFilesSelected={this.props.addFileToState} id={fileInputId} />
        </label>
      )
    }

    return (
      <div className='file-upload__buttons'>
        { withCaptureButton
          ? <Button onClick={() => { postAsyncMessage('document_detection').then(({ mime, image }) => loadFileFromExtension(image, mime, false)) }} icon='icon-camera' view={error ? 'transparent-red' : ''}>{t('capture').s}</Button>
          : null }
        <div className='file-input-wrapper'>
          <Button icon='icon-share' view={error ? 'transparent-red' : 'transparent-black'}>
            <span>{t('upload').s}</span>
          </Button>
          <FileInput onFilesSelected={this.props.addFileToState} id={fileInputId} />
        </div>
      </div>
    )
  }

  render () {
    const {onlyOne, newFiles, currentFiles, disabled, t} = this.props
    const allFiles = (currentFiles || []).concat(newFiles)
    const loadingOff = onlyOne && allFiles.length >= 1

    const FileListComponent = onlyOne ? FileList : FilePreviewList
    const { mainTextColor, whiteLabel } = getGreyLabelOptions()
    const fileUploadClass = `file-upload ${mainTextColor ? `file-upload--${whiteLabel}` : ''}`
    return (
      <div className={`${fileUploadClass} ${disabled ? 'disable-feature' : ''}`}>
        { !onlyOne ? <div className='hr'><span>{t('title').s}</span></div> : null }
        <FileListComponent editMode files={allFiles} />
        { !loadingOff && this.fileUploader }
        { !onlyOne ? <div className='hr' /> : null }
      </div>
    )
  }
}

function mapStateToProps (state) {
  return {
    wrapperVersion: state.appInfo.wrapperVersion,
    editMode: state.filesToUpload.editMode,
    newFiles: state.filesToUpload.newFiles.filter((f) => !f.imageForOcr),
    currentFiles: state.filesToUpload.currentFiles.filter((f) => !f.imageForOcr),
    path: state.filesToUpload.path,
    t: state.i18n.get('app', 'components', 'FileListWithUploader')
  }
}

function mapDispatchToProps (dispatch) {
  return {
    loadFileFromExtension: (...args) => { dispatch(loadFileFromExtension(...args)) },
    addFileToState: (newFiles) => { dispatch(addFilesAction(newFiles)) },
    loadCurrentFiles: (files) => { dispatch(loadCurrentFilesAction(files)) },
    saveCurrentPath: (path) => { dispatch(saveCurrentPathAction(path)) },
    clearFiles: (path) => { dispatch(clearFilesAction(path)) },
    forceDeleteFiles: () => dispatch(forceDeleteFilesAction())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FileListWithUploader)
