import React from 'react'
import PropTypes from 'prop-types'

import IpadNavigation from 'Navigation/IpadNavigation'
import More from 'More/views/MoreView/containers/Smartphones'
import { connectedKivraTenant as KivraTenant } from './Smartphones'

const KivraTenantView = (props) => {
  const invoiceId = props.location.query.cameFromInvoiceId
  const backLink = invoiceId ? `/invoices/${invoiceId}` : '/integrations'
  return (
    <IpadNavigation {...props} withoutMaxWidth backLink={backLink}>
      <More notRightIcon isIpad />
      <KivraTenant {...props} />
    </IpadNavigation>
  )
}

KivraTenantView.propTypes = {
  navBarTitle: PropTypes.string,
  location: PropTypes.object.isRequired
}

export default KivraTenantView
