import {
  DOCUMENTS_LIST_UPDATE,
  DOCUMENT_LOAD_TO_STATE, DOCUMENT_UPDATE_FIELDS, DOCUMENT_REMOVE_FROM_STATE
} from '../constants/ActionTypes'

const initialState = {
  lastPageLoaded: 0,
  hasNextPage: true,
  documents: [],
  document: {}
}

export default function (state = initialState, action) {
  switch (action.type) {
    case DOCUMENTS_LIST_UPDATE:
      return {
        ...state,
        documents: action.payload.documents,
        hasNextPage: action.payload.hasNextPage,
        lastPageLoaded: action.payload.lastPageLoaded
      }
    case DOCUMENT_LOAD_TO_STATE:
      return {
        ...state,
        document: action.payload
      }
    case DOCUMENT_UPDATE_FIELDS:
      return {
        ...state,
        document: {
          ...state.document,
          ...action.payload
        }
      }
    case DOCUMENT_REMOVE_FROM_STATE:
      return {
        ...state,
        document: {}
      }
    default:
      return state
  }
}
