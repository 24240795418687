import React from 'react'
import PropTypes from 'prop-types'

import Button from 'shared/components/Button/Button'
import './FactoringButtons.scss?global'

const FactoringButtons = ({ startHandler, cancelHandler, t, cancelButtonView }) =>
  <div className='factoring__buttons'>
    <Button view='primary' onClick={startHandler}>{t('shared', 'button', 'start').s}</Button>
    <Button view={cancelButtonView} onClick={cancelHandler}>{t('shared', 'button', 'cancel').s}</Button>
  </div>

FactoringButtons.propTypes = {
  t: PropTypes.func.isRequired,
  startHandler: PropTypes.func.isRequired,
  cancelHandler: PropTypes.func.isRequired,
  cancelButtonView: PropTypes.string.isRequired
}

export default FactoringButtons
