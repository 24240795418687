import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { omit } from 'lodash'
import SuggestionInput from 'shared/components/SuggestionInput/Async'

export default class CompanyNameInput extends Component {
  updateSuggestions = ({ value }) => {
    if (value.length < 3) return
    this.props.updateSuggestions(value)
  }

  onSelectSuggestion = (event, { suggestion }) => {
    this.props.onSelectSuggestion(suggestion)
  }

  renderSuggestion = (suggestion) => {
    return suggestion.name
  }

  render () {
    const inputProps = omit(this.props, ['onSelectSuggestion', 'updateSuggestions'])
    return <SuggestionInput
      name={this.props.name}
      dataKey='name'
      renderSuggestion={this.renderSuggestion}
      inputProps={inputProps}
      onSuggestionSelected={this.onSelectSuggestion}
      onSuggestionsFetchRequested={this.updateSuggestions}
    />
  }
}

CompanyNameInput.propTypes = {
  updateSuggestions: PropTypes.func,
  onSelectSuggestion: PropTypes.func,
  name: PropTypes.string
}
