import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import ReactDateTime from 'react-datetime'

import moment from 'moment'
import cs from 'classnames'

class DateInput extends Component {
  static propTypes = {
    type: PropTypes.string,
    lang: PropTypes.string,
    maxDate: PropTypes.object,
    minDate: PropTypes.object,
    view: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    name: PropTypes.string
  }

  constructor (props) {
    super(props)
    this.onChange = this.onChange.bind(this)
    this.getParsedValue = this.getParsedValue.bind(this)
  }

  getCurrentDateValue (type) {
    if (type === 'datetime-local') {
      return 'YYYY-MM-DDTHH:mm:ss'
    } else {
      return 'YYYY-MM-DD'
    }
  }

  isTimePickerVisible (type) {
    if (type === 'datetime-local') {
      return 'HH:mm'
    } else {
      return false
    }
  }

  onChange (selectedDate) {
    const { type, onChange } = this.props
    const value = selectedDate.format(this.getCurrentDateValue(type))
    onChange(value)
  }

  getParsedValue (receivedValue) {
    const { type } = this.props
    let value = ''
    if (receivedValue) {
      value = moment(receivedValue, this.getCurrentDateValue(type))
    }
    return value
  }

  render () {
    const {value, type, lang, name, maxDate, minDate, view} = this.props
    const params = {
      inputProps: { readOnly: true, name },
      value: this.getParsedValue(value),
      onChange: this.onChange,
      closeOnSelect: true,
      timeFormat: this.isTimePickerVisible(type),
      locale: lang,
      dateFormat: 'YYYY-MM-DD'
    }

    if (maxDate) {
      params.isValidDate = (current) => {
        return maxDate.isAfter(current)
      }
    }

    if (minDate) {
      params.isValidDate = (current) => {
        return current.isAfter(minDate)
      }
    }

    return (
      <div className={cs('input__datepicker', `input--${view}`)}>
        <ReactDateTime {...params} />
      </div>
    )
  }
}

function mapStateToProps (state) {
  return {
    lang: state.i18n.locale
  }
}

export default connect(mapStateToProps)(DateInput)
