import React from 'react'
import { showElementInMessageBoxAction, closeMessageBoxAction, setMessageBoxProps } from 'MessageBox/actions/messageBox'
import { routerActions } from 'react-router-redux'

import EventMessageBox from 'Events/shared/components/EventMessageBox/EventMessageBox'

// Bridges between iOS and Android apps
import { closeExtensionWindow } from 'appBridge/notificators/extension'

import { delay } from 'shared/helpers/utils'

export function redirectToEventsList () {
  return (dispatch) => {
    const pathname = window.location.pathname
    if (pathname === '/') {
      return dispatch(routerActions.push('/events'))
    } else {
      return dispatch(routerActions.replace('/events'))
    }
  }
}

function redirectToEvent (idForUrl) {
  // without delays, router doesn't change
  // TODO: refactor using promises
  return async (dispatch) => {
    dispatch(routerActions.go(-2))
    await delay(100)
    dispatch(redirectToEventsList())
    await delay(100)
    dispatch(routerActions.push(`/events/${idForUrl}`))
  }
}

export function showSuccessMessageBoxAction (eventId, idForUrl, repeatButtonText, additionalHandler) {
  return (dispatch) => {
    const onClose = () => {
      closeExtensionWindow()
      dispatch([redirectToEvent(idForUrl), closeMessageBoxAction()])
    }

    const onRepeat = () => {
      closeExtensionWindow()
      dispatch(closeMessageBoxAction())
      additionalHandler()
    }

    return dispatch([
      setMessageBoxProps({ onClose }),
      showElementInMessageBoxAction(
        <EventMessageBox
          eventId={eventId}
          onClose={onClose}
          onRepeat={repeatButtonText && onRepeat}
          repeatButtonText={repeatButtonText}
        />, onClose
      )
    ])
  }
}
