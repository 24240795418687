import {connect} from 'react-redux'

// Components
import MessageBox from '../components/MessageBox/MessageBox'

// Actions
import { closeMessageBoxAction } from 'MessageBox/actions/messageBox'

function mapStateToProps (state) {
  return {
    showMessageBox: state.messageBox.showMessageBox,
    title: state.messageBox.title,
    text: state.messageBox.text,
    element: state.messageBox.element,
    onCloseFromState: state.messageBox.onClose
  }
}

function mergeProps (stateProps, dispatchProps, ownProps) {
  let onClose

  if (typeof ownProps.onClose === 'function') {
    onClose = ownProps.onClose
  } else if (typeof stateProps.onCloseFromState === 'function') {
    onClose = () => {
      dispatchProps.dispatch(closeMessageBoxAction())
      stateProps.onCloseFromState()
    }
  } else {
    onClose = () => { dispatchProps.dispatch(closeMessageBoxAction()) }
  }

  return {
    ...stateProps,
    ...ownProps,
    onClose: onClose
  }
}

export default connect(mapStateToProps, null, mergeProps)(MessageBox)
