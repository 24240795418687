import { API_BASE } from 'shared/constants/Api'

export const INVOICES_LIST = `${API_BASE}/api/v2/invoices/`
export const INVOICES_OVERDUE_AMOUNT = `${API_BASE}/api/v2/invoices/amount_of_overdue/`
export const INVOICE_NEXT_NUMBER = `${API_BASE}/api/v2/invoices/next_invoice_number/`
export const INVOICE_SAVE = `${API_BASE}/api/v2/invoices/`
export const INVOICE_GET = (id) => `${API_BASE}/api/v2/invoices/${id}`
export const INVOICE_GET_PDF = (id) => `${API_BASE}/api/v2/invoices/${id}/pdf`
export const INVOICE_SEND_MESSAGE = (id) => `${API_BASE}/api/v2/invoices/${id}/send`
export const INVOICE_MARK_AS_PAID = (id) => `${API_BASE}/api/v2/invoices/${id}/pay`
export const INVOICE_GET_TOTALS = `${API_BASE}/api/v2/invoices/calculate`
export const INVOICE_SEND_REMINDER = (id) => `${API_BASE}/api/v2/invoices/${id}/remind`
export const INVOICE_COPY = (id) => `${API_BASE}/api/v2/invoices/${id}/copy`
