import { bind } from 'redux-effects'
import { createAction } from 'redux-actions'
import {fetch} from 'shared/helpers/fetch'

// Constants
import { EVENT_MESSAGES, EVENT_MESSAGES_UPDATE } from 'Events/shared/constants/Api'
import { EVENT_MESSAGES_LOAD } from 'Events/shared/constants/Event'

import { updateNotificationBadgesAction } from 'Notifications/actions/notificationBadges'

// Redux actions
const eventMessagesLoad = createAction(EVENT_MESSAGES_LOAD)

export function loadEventMessagesAction (id) {
  return bind(fetchEventMessages(id), processEventMessages)
}

function fetchEventMessages (id) {
  return fetch(EVENT_MESSAGES, { source_document_id: id }, {
    method: 'GET'
  })
}

function processEventMessages (response) {
  return [eventMessagesLoad(response.value)]
}

export function pushNewEventMessageAction (comment, userId, eventId) {
  return bind(pushNewEventMessage(comment, userId, eventId), processPushNewMessage.bind(this, eventId))
}

function pushNewEventMessage (comment, userId, eventId) {
  const data = {
    comment: comment,
    sender_id: userId,
    source_document_id: Number(eventId)
  }
  return fetch(EVENT_MESSAGES, {}, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: data
  })
}

function processPushNewMessage (eventId) {
  return loadEventMessagesAction(eventId)
}

export function markAsReadMessagesAction (unreadMessages) {
  return bind(patchEventMessages(unreadMessages), updateNotificationBadgesAction)
}

function patchEventMessages (unreadMessages) {
  let readMessages = changeMessagesStatus(unreadMessages)
  return fetch(EVENT_MESSAGES_UPDATE, {}, {
    method: 'PATCH',
    header: {
      'Content-Type': 'application/json'
    },
    body: readMessages
  })
}

function changeMessagesStatus (unreadMessages) {
  return unreadMessages.map(unreadMessage => ({ id: unreadMessage.id, reporter_has_read: true }))
}
