exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._3OGYaBkdz6u1TXUjpkrGck {\n  margin: 0 auto;\n  margin-top: 20px;\n  width: 95%;\n}\n\n.-B1JJaD7RPRNkW_YyOpWI {\n  margin-top: 20px;\n  display: flex;\n  justify-content: center;\n}\n\n", "", {"version":3,"sources":["/home/circleci/mobile_ui/src/Overview/VatReports/components/Info/styles.scss"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,iBAAiB;EACjB,WAAW;CACZ;;AAED;EACE,iBAAiB;EACjB,cAAc;EACd,wBAAwB;CACzB","file":"styles.scss","sourcesContent":[".pdf {\n  margin: 0 auto;\n  margin-top: 20px;\n  width: 95%;\n}\n\n.spinner {\n  margin-top: 20px;\n  display: flex;\n  justify-content: center;\n}\n\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"pdf": "_3OGYaBkdz6u1TXUjpkrGck",
	"spinner": "-B1JJaD7RPRNkW_YyOpWI"
};