import React from 'react'
import { connect } from 'react-redux'
import { reduxForm, formValueSelector, change } from 'redux-form'
import { isEmpty } from 'lodash'

// Actions
import { recalculateTotalsAction, recalculatePercentageAction, saveRotRutObjectAction } from '../../../actions/rotRut'

// Constants
import { ROT } from '../../../../shared/constants/RotRutTypes'

function validate (values) {
  let errors = {}
  if (values.personalNumber && (values.personalNumber.length > 11 || !(/^[\d\-]+$/.test(values.personalNumber || '')))) {
    errors.personalNumber = true
  }

  return errors
}

export default function InvoiceRotRutContainer (Component) {
  class InvoiceRotRut extends React.Component {
    render () {
      return (
        <Component {...this.props} navbarTitle={this.navbarTitle} />
      )
    }
  }

  const formName = 'rotRut'
  const selector = formValueSelector(formName)
  function mapStateToProps (state) {
    const { invoices: { customerInvoices: { invoice: { totals: { total } } } } } = state

    const rotRutObject = state.invoices.customerInvoices.invoice.rotRutObject
    const defaultRotRutValues = {
      rotRutType: ROT,
      percentage: 30,
      invoiceFinalTotalAmount: total
    }

    return {
      invoiceRows: state.invoices.customerInvoices.invoice.invoiceRows,
      isCalculating: state.invoices.customerInvoices.totalsAreCalculating,
      initialValues: isEmpty(rotRutObject) ? defaultRotRutValues : rotRutObject,
      t: state.i18n.get('app', 'views', 'Invoices', 'InvoiceRotRutView'),
      previousLink: state.appInfo.previousLink,
      formValues: selector(state, 'rotRutAmount', 'invoiceFinalTotalAmount', 'rotRutType')
    }
  }

  function mapDispatchToProps (dispatch) {
    return {
      recalculateTotals: () => dispatch(recalculateTotalsAction()),
      recalcultatePercentage: (newTotal) => dispatch(recalculatePercentageAction(newTotal)),
      saveRotRutObject: () => dispatch(saveRotRutObjectAction()),
      changeRotRutType: (value) => dispatch(change(formName, 'rotRutType', value)),
      changeCustomAmount: (value) => dispatch(change(formName, 'customAmount', value)),
      changePercentage: (value) => dispatch(change(formName, 'percentage', value))
    }
  }

  return connect(mapStateToProps, mapDispatchToProps)(reduxForm({
    form: formName,
    validate
  })(InvoiceRotRut))
}
