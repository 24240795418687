import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { get, isEqual } from 'lodash'
import { getFormValues } from 'redux-form'

// Components
import Navigation from 'Navigation/Navigation'
import ListView from 'shared/components/ListView/ListView'
import ArticlesListItem from 'Articles/components/ListItem/ListItem'
import Filters from 'Articles/components/Filters/Filters'

// Actions
import { reloadListAction, extendListAction } from 'Articles/actions/articles'

export class Articles extends Component {
  static propTypes = {
    articles: PropTypes.arrayOf(PropTypes.object),
    location: PropTypes.object,
    reloadList: PropTypes.func,
    listViewTemplate: PropTypes.func,
    filters: PropTypes.object,
    notMarkList: PropTypes.bool
  }

  static defaultProps = {
    listViewTemplate: ArticlesListItem
  }

  componentDidMount () {
    this.props.reloadList()
  }

  componentDidUpdate (prevProps) {
    const { filters } = this.props
    if (prevProps.filters && !isEqual(prevProps.filters, filters)) {
      this.props.reloadList()
    }
  }

  render () {
    const { articles, listViewTemplate, location: { pathname }, filters, notMarkList } = this.props

    return (
      <ListView data={articles} template={listViewTemplate} pathname={pathname} notMark={notMarkList} emptyListOptions={{icon: 'icon-articles', showNotFound: Boolean(get(filters, ['search']))}} />
    )
  }
}

export class ArticlesWithNavigation extends Component {
  static propTypes = {
    articles: PropTypes.arrayOf(PropTypes.object),
    extendList: PropTypes.func,
    backLink: PropTypes.string,
    t: PropTypes.func
  }

  static defaultProps = {
    backLink: '/more/customer-invoices'
  }

  render () {
    const { t, articles, extendList, backLink } = this.props
    const actions = [
      {icon: 'icon-add', path: '/articles/new'}
    ]

    return (
      <Navigation actions={actions} backLink={backLink} title={t('navbar', 'title').s} forceBackLink filterBlock infinityScrollHandler={extendList}>
        <Filters canBeHidden={!articles.length} />
        <Articles {...this.props} />
      </Navigation>
    )
  }
}

function mapStateToProps (state) {
  return {
    filters: getFormValues('articlesViewFilters')(state),
    articles: state.articles.listData,
    t: state.i18n.get('app', 'views', 'Articles', 'ArticlesView')
  }
}

function mapDispatchToProps (dispatch) {
  return {
    reloadList: () => { dispatch(reloadListAction()) },
    extendList: () => { dispatch(extendListAction()) }
  }
}

export const ArticlesView = connect(mapStateToProps, mapDispatchToProps)(Articles)

export default connect(mapStateToProps, mapDispatchToProps)(ArticlesWithNavigation)
