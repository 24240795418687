import { browserHistory } from 'react-router'
import ZignsecInteractor from 'User/shared/interactors/zignsecInteractor'
import uuid from 'uuid/v4'
// import { personalNumberMaskProps } from 'mrshoebox-ui-components/src/helpers/countrySpecific'

import { showMessageBoxWithParamsAction } from 'MessageBox/actions/messageBox'

import { saveTokens } from 'User/Auth/actions/auth'
import { setHeaders } from 'api-client-connector/utils'

import getGreyLabelOptions from 'GreyLabel/helpers/greyLabel'

import { showNavbarSpinnerAction, hideNavbarSpinnerAction } from 'shared/actions/navbarSpinner'
import { createAction } from 'redux-act'
import { GET_DATA_FOR_REMOTE_MOBILE_AUTH } from '../constants/ActionTypes'

export const dataForRemoteAuth = createAction(GET_DATA_FOR_REMOTE_MOBILE_AUTH)
// auth from desktop through remote mobile app
export function authorizeRemoteMobile () {
  return async (dispatch, getState) => {
    dispatch(showNavbarSpinnerAction())
    const t = getState().i18n.get('app', 'views', 'LoginView')
    const token = uuid()
    if (!token) {
      dispatch(hideNavbarSpinnerAction())
      dispatch(showMessageBoxWithParamsAction(
        '',
        t('errors', 'personalNumberIsNotRegistered').s,
        () => browserHistory.replace('/login_password')
      ))
    }

    ZignsecInteractor.initialize({
      connectionParams: {
        channel: 'Zignsec::Bankidse::AuthChannel',
        token,
        target: 'https://mage.mrshoebox.com/',
        white_label: getGreyLabelOptions().whiteLabel
      },
      remoteMobileAuth: true,
      onSuccess: maybePerformAuthentication.bind(this, dispatch)
    })
    ZignsecInteractor.startBankIDAuth()
  }
}

// auth from desktop or mobile app
export default function authorize () {
  return async (dispatch) => {
    dispatch(showNavbarSpinnerAction())

    const token = uuid()
    ZignsecInteractor.initialize({
      connectionParams: {
        channel: 'Zignsec::Bankidse::AuthChannel',
        token,
        target: 'https://mage.mrshoebox.com/',
        white_label: getGreyLabelOptions().whiteLabel
      },
      onSuccess: maybePerformAuthentication.bind(this, dispatch)
    })
    ZignsecInteractor.startBankIDAuth()
  }
}

export function saveQrCode (response) {
  return async (dispatch) => {
    if (response) {
      dispatch(dataForRemoteAuth({
        loginViaRemoteMobileData: {
          result: {
            qrCodeLink: response
          }
        }
      }
      ))
    }
  }
}

function maybePerformAuthentication (dispatch, data) {
  if (data.data && data.auth_headers) {
    const response = {
      data: data
    }
    setHeaders(data.auth_headers)
    dispatch(saveTokens(response))
  }
}

export function onCancelAction () {
  ZignsecInteractor.disconnect()
  browserHistory.go(-1)
  return [
    hideNavbarSpinnerAction()
  ]
}
