import React, { Component } from 'react'

import IpadNavigation from 'Navigation/IpadNavigation'
import Conversations from 'Messages/views/ConversationsView/containers/Smartphones'

export default class ConversationsView extends Component {
  render () {
    return (
      <IpadNavigation {...this.props} onlyLeftSide>
        <Conversations {...this.props} />
      </IpadNavigation>
    )
  }
}
