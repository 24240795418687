import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getFormValues } from 'redux-form'
import { isEqual } from 'lodash'

// Components
import Navigation from 'Navigation/Navigation'
import ListView from 'shared/components/ListView/ListView'
import Filters, { initialFilterValues } from '../components/Filters'
import AnnualReportsViewListItem from '../templates/ListItem'

// Actions
import { loadAnnualReportsListAction, cleanReportsListAction } from 'Reports/actions/reports'

// Helpers
import getEmptyListOptions from 'Reports/helpers/emptyListOptions'

class AnnualReportsView extends Component {
  static propTypes = {
    reportsList: PropTypes.arrayOf(PropTypes.object),
    loadList: PropTypes.func,
    cleanList: PropTypes.func,
    t: PropTypes.func,
    emptyListOptions: PropTypes.object,
    hasNextPage: PropTypes.bool,
    lastPageLoaded: PropTypes.number,
    filters: PropTypes.object
  };

  constructor (props) {
    super(props)
    this.extendList = this.extendList.bind(this)
  }

  componentDidMount () {
    this.props.loadList([], 1)
  }

  componentDidUpdate (prevProps) {
    const { filters } = this.props
    if (prevProps.filters && !isEqual(prevProps.filters, filters)) {
      this.props.loadList([], 1)
    }
  }

  extendList () {
    const {hasNextPage, lastPageLoaded, loadList, reportsList} = this.props
    if (hasNextPage) {
      loadList(reportsList, lastPageLoaded + 1)
    }
  }

  componentWillUnmount () {
    this.props.cleanList()
  }

  render () {
    const { t, emptyListOptions } = this.props
    return (
      <Navigation filterBlock backLink='/overview' title={t('navbar', 'title').s} className='annual-reports-view' infinityScrollHandler={this.extendList}>
        <Filters canBeHidden={!this.props.reportsList.length} />
        <ListView data={this.props.reportsList} template={AnnualReportsViewListItem} emptyListOptions={emptyListOptions} />
      </Navigation>
    )
  }
}

function mapStateToProps (state) {
  const emptyListOptions = getEmptyListOptions(
    state.i18n.get('app', 'views', 'Reports', 'shared'),
    state.userSettings.accountant
  )

  return {
    filters: getFormValues('annualReportsFilters')(state) || initialFilterValues,
    reportsList: state.reports.listData,
    hasNextPage: state.reports.hasNextPage,
    lastPageLoaded: state.reports.lastPageLoaded,
    t: state.i18n.get('app', 'views', 'Reports', 'Annual', 'List'),
    emptyListOptions
  }
}

function mapDispatchToProps (dispatch) {
  return {
    loadList: (...params) => { dispatch(loadAnnualReportsListAction(...params)) },
    cleanList: () => { dispatch(cleanReportsListAction()) }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AnnualReportsView)
