import React from 'react'
import { connect } from 'react-redux'

import IpadNavigation from 'Navigation/IpadNavigation'
import { connectedOpenPaymentsOnboarding as OpenPaymentsOnboarding } from 'Integrations/views/OpenPaymentsOnboardingView/containers/Smartphones'
import TodoIntegrations from 'Todo/views/TodoIntegrationsView/containers/Smartphones'

class TodoIntegrationView extends React.Component {
  render () {
    return (
      <IpadNavigation notBack {...this.props}>
        <TodoIntegrations {...this.props} />
        <OpenPaymentsOnboarding {...this.props} />
      </IpadNavigation>
    )
  }
}

const mapStateToProps = (state) => ({
  title: state.i18n.get('app', 'views', 'Todo', 'TodoIntegration', 'navbar', 'title').s
})

export default connect(mapStateToProps)(TodoIntegrationView)
