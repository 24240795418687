import './PnlReports.scss?global'

import Smartphones from 'Overview/PnlReports/containers/Smartphones'
import Tablets from 'Overview/PnlReports/containers/Tablets'

import {getCurrentDeviceView} from 'shared/helpers/utils'

const PnlReportsView = getCurrentDeviceView(Smartphones, Tablets)

export default PnlReportsView
