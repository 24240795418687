import React from 'react'

import TodoPDFReportsView from 'Todo/views/TodoUnreadPDFReports/containers/Smartphones'
import PDFReportsView from 'Reports/views/FinancialReport/FinancialReportsView/containers/Smartphones'

import TodoAnnualReportsView from 'Todo/views/TodoUnreadAnnualReports/containers/Smartphones'
import AnnualReportsView from 'Reports/views/AnnualReport/AnnualReportsView/containers/Smartphones'

import TodoTaxReportsView from 'Todo/views/TodoTaxReportsView/containers/Smartphones'
import TaxPaymentReportsView from 'Reports/views/TaxPaymentReport/TaxPaymentReportsView/containers/Smartphones'

import UnreadEventsView from 'Todo/views/TodoUnreadEventsView/containers/Smartphones'
import TodoErrorEventsView from 'Todo/views/TodoErrorEventsView/containers/Smartphones'
import EventsView from 'Events/Events/views/EventsView/containers/Smartphones'
import FinanceDetailsByAccountView from 'Overview/FinanceVisualization/views/FinanceDetailsByAccountView/containers/Smartphones'

import ConversationsView from 'Messages/views/ConversationsView/containers/Smartphones'

import InvoicesView from 'Invoices/CustomerInvoices/views/InvoicesView/containers/Smartphones'
import SupplierInvoicesView from 'Invoices/SupplierInvoices/views/SupplierInvoicesView/containers/Smartphones'
import CustomersView from 'Counterparties/Customers/views/CustomersView/containers/Smartphones'
import SuppliersView from 'Counterparties/Suppliers/views/SuppliersView/containers/Smartphones'

import ProjectsView from 'Projects/views/ProjectsView/containers/Smartphones'
import ProjectFinanceDetailsByAccountView from 'Projects/views/ProjectView/FinanceDetailsByAccount/containers/Smartphones'

import Timesheets from 'Timesheet/views/TimesheetsView/containers/Smartphones'

import MileagesView from 'mrshoebox-ui-components/src/modules/DriverLogs/views/DriverLogsListingView/containers/Smartphones'
import ArticlesView from 'Articles/views/ArticlesView/containers/Smartphones'

import SettingsView from 'User/Settings/views/SettingsView/containers/Smartphones'

import TodoCollectorInvoices from 'Todo/views/TodoCollectorInvoicesView/containers/Smartphones'

const linkAccordance = {
  report: {
    getComponent: (link) => {
      if (link && link.indexOf('todo') !== -1) {
        return <TodoTaxReportsView basePathForLink='/todo/tax_payment' />
      } else {
        return <TaxPaymentReportsView />
      }
    }
  },
  event: {
    getComponent: (link) => {
      if (link && link.indexOf('unread_events') !== -1) {
        return UnreadEventsView
      } else if (link && link.indexOf('todo') !== -1) {
        return TodoErrorEventsView
      } else if (link && link.indexOf('finance_details_by_account') !== -1) {
        return ProjectFinanceDetailsByAccountView
      } else if (link && link.indexOf('projects') !== -1) {
        return ProjectsView
      } else if (link && link.indexOf('overview') !== -1) {
        return FinanceDetailsByAccountView
      } else if (link && link.indexOf('supplier-invoices') !== -1) {
        return SupplierInvoicesView
      } else if (link && link.indexOf('invoice') !== -1) {
        return InvoicesView
      } else {
        return EventsView
      }
    }
  },
  article: {
    getComponent: (link) => {
      if (link && link.indexOf('invoice') !== -1) {
        return InvoicesView
      } else {
        return ArticlesView
      }
    }
  },
  createCustomer: {
    getComponent: (link) => {
      if (link && link.indexOf('invoices') !== -1) {
        return InvoicesView
      } else {
        return CustomersView
      }
    }
  },
  createSupplier: {
    getComponent: (link) => {
      if (link && link.indexOf('supplier-invoices') !== -1) {
        return SupplierInvoicesView
      } else {
        return SuppliersView
      }
    }
  },
  timesheet: {
    getComponent: (link) => {
      if (link && link.indexOf('projects') !== -1) {
        return ProjectsView
      } else if (link && link.indexOf('invoice') !== -1) {
        return InvoicesView
      } else {
        return Timesheets
      }
    }
  },
  mileage: {
    getComponent: (link) => {
      if (link && link.indexOf('projects') !== -1) {
        return ProjectsView
      } else if (link && link.indexOf('invoice') !== -1) {
        return InvoicesView
      } else {
        return MileagesView
      }
    }
  },
  selectableItemList: {
    getComponent: (link) => {
      if (link && link.indexOf('supplier-invoices') !== -1) {
        return SupplierInvoicesView
      } else if (link && link.indexOf('messages') !== -1) {
        return (props) => <ConversationsView {...props} shouldLoadList={false} />
      } else if (link && link.indexOf('events') !== -1) {
        return EventsView
      } else if (link && link.indexOf('invoices') !== -1) {
        return InvoicesView
      } else {
        return ProjectsView
      }
    }
  },
  invoiceSettings: {
    getComponent: (link) => {
      if (link && link.indexOf('settings')) {
        return SettingsView
      } else {
        return InvoicesView
      }
    }
  },
  invoice: {
    getComponent: (link) => {
      if (link && link.indexOf('todo') !== -1) {
        return TodoCollectorInvoices
      } else if (link && link.indexOf('invoices') !== -1) {
        return InvoicesView
      } else {
        return EventsView
      }
    }
  },
  pdfReport: {
    getComponent: (link) => {
      if (link && link.indexOf('todo') !== -1) {
        return TodoPDFReportsView
      } else {
        return PDFReportsView
      }
    }
  },
  annualReport: {
    getComponent: (link) => {
      if (link && link.indexOf('todo') !== -1) {
        return TodoAnnualReportsView
      } else {
        return AnnualReportsView
      }
    }
  }
}

export default function getLeftComponent (view, link) {
  return linkAccordance[view].getComponent(link)
}
