import PropTypes from 'prop-types'
import React, { Component } from 'react'

// Components
import IpadNavigation from 'Navigation/IpadNavigation'
import InvoiceRotRut from '../components/InvoiceRotRut'

// Containers
import InvoiceRotRutContainer from './Common'

// Helpers
import getLeftComponent from 'Navigation/helpers/linkAccordance'

export class InvoiceRotRutView extends Component {
  static propTypes = {
    t: PropTypes.func,
    previousLink: PropTypes.string
  }

  render () {
    const {t, previousLink} = this.props
    const Events = getLeftComponent('invoice', previousLink)
    return (
      <IpadNavigation {...this.props} withoutMaxWidth title={t('title').s}>
        <Events {...this.props} />
        <InvoiceRotRut {...this.props} />
      </IpadNavigation>
    )
  }
}

export default InvoiceRotRutContainer(InvoiceRotRutView)
