import { showMessageBoxWithParamsAction } from 'MessageBox/actions/messageBox'
import { hideNavbarSpinnerAction } from 'shared/actions/navbarSpinner'
import { routerActions } from 'react-router-redux'

import { get } from 'lodash'

// Bridges between iOS and Android apps
import { closeExtensionWindow } from 'appBridge/notificators/extension'

// Actions
import { setInternetIsLostAction } from 'AppInfo/actions/appInfo'

// Constants
const HTTP_CODES_WHITELIST = [401, 404, 409, 422]

export default function () {
  return next => action => {
    if (action.type === 'EFFECT_COMPOSE' && action.meta && action.meta.steps && action.meta.steps[0] && action.meta.steps[0].length === 2) {
      const modifiedAction = {
        ...action,
        meta: {
          ...action.steps,
          steps: createSteps(action.meta.steps)
        }
      }
      return next(modifiedAction)
    }
    return next(action)
  }
}

function createSteps (initialSteps) {
  return [
    [
      createSuccessStep.bind(null, initialSteps[0][0]),
      createOnErrorStep.bind(null, initialSteps[0][1])
    ]
  ]
}

function createSuccessStep (initialStep, res) {
  return (dispatch, getState) => {
    const internetConnectionIsLost = getState().appInfo.internetIsLost
    if (internetConnectionIsLost === true) {
      dispatch([setInternetIsLostAction(false)])
    }
    return dispatch(initialStep(res))
  }
}

function createOnErrorStep (initialStep, response) {
  return (dispatch, getState) => {
    const t = getState().i18n.get('app', 'shared', 'messages')
    let actions = [hideNavbarSpinnerAction()]

    const connectionIsLost = response instanceof Error && window.navigator.onLine === false

    const whiteListedStatus = HTTP_CODES_WHITELIST.indexOf(response.status) !== -1

    let message
    const errors = get(response, 'value.errors', [])
    if (errors.full_messages && errors.full_messages.join) {
      // ident-service
      message = errors.full_messages.join('')
    } else if (errors && errors.join) {
      // other services
      message = errors.join('')
    }

    if ((response.status === 401 || response.status === 403) && window.location.pathname.indexOf('login') === -1) {
      actions.push(routerActions.replace('/logout'))
    } else if (response.status === 502) {
      actions.push(showMessageBoxWithParamsAction(null, message || t('badGateway', 'description').s, closeExtensionWindow))
    } else if (!connectionIsLost && !whiteListedStatus) {
      actions.push(showMessageBoxWithParamsAction(null, message || t('unexpectedError', 'description').s, closeExtensionWindow))
    }

    if (initialStep) {
      actions.push(initialStep(response))
    }

    if (connectionIsLost) {
      console.error('Connection is lost')
      actions.push(setInternetIsLostAction(connectionIsLost))
    }

    return dispatch(actions)
  }
}
