import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { isEqual } from 'lodash'
import { getFormValues } from 'redux-form'

// Components
import Navigation from 'Navigation/Navigation'
import ListView from 'shared/components/ListView/ListView'
import SelectableListItem from 'shared/components/SelectableListItem/SelectableListItem'
import ListItem from 'Events/Events/components/ListItem/ListItem'
import Filters from 'Events/Events/components/Filter/Filter'
import NewEntry from 'NewEntry/components/NewEntry'

// Actions
import { addItemAction, removeItemAction, removeAllImportItems } from 'ImportItems/actions/importItems'
import { reloadEventsAction, extendEventsAction, clearEventsListAction } from 'Events/shared/actions/events'

export function EventsImportListItem ({onCheckboxChange, ...props}) {
  return (
    <SelectableListItem {...props} onChange={onCheckboxChange} >
      <ListItem {...props} notLink />
    </SelectableListItem>
  )
}

EventsImportListItem.propTypes = {
  onCheckboxChange: PropTypes.func
}

class EventsImport extends Component {
  static propTypes = {
    onCheck: PropTypes.func,
    onUncheck: PropTypes.func,
    extendListData: PropTypes.func,
    clearItems: PropTypes.func,
    hasNextPage: PropTypes.bool,
    lastOffset: PropTypes.number,
    listData: PropTypes.arrayOf(PropTypes.object),
    itemsToImport: PropTypes.arrayOf(PropTypes.object),
    one: PropTypes.bool,
    filters: PropTypes.object,
    reloadList: PropTypes.func
  }

  constructor (props) {
    super(props)
    this.onChangeHandler = this.onChangeHandler.bind(this)
    this.extendList = this.extendList.bind(this)
  }

  onChangeHandler (checked, data) {
    const { onCheck, onUncheck, clearItems, one } = this.props
    if (one) {
      clearItems()
      if (checked) onCheck(data)
    } else {
      checked ? onCheck(data) : onUncheck(data)
    }
  }

  extendList () {
    const {hasNextPage, lastOffset, listData, extendListData} = this.props

    if (hasNextPage) {
      extendListData(listData, lastOffset + 20, false)
    }
  }

  componentDidMount () {
    this.props.extendListData([], 0, true)
  }

  componentDidUpdate (props) {
    const {filters} = this.props
    if (props.filters && isEqual(props.filters, filters) === false) {
      this.props.reloadList(this.props.filters)
    }
  }

  componentWillUnmount () {
    this.props.clearItems()
  }

  getCheckedListIds (items, one) {
    if (one && items) {
      return items.id
    } else if (!one && items) {
      return null
    }
  }

  render () {
    const {one, listData} = this.props
    const itemsToImport = one ? this.props.itemsToImport[0] : this.props.itemsToImport
    const checkedId = this.getCheckedListIds(itemsToImport, one)
    return (
      <div className='events-view'>
        <ListView data={listData} template={EventsImportListItem} id={checkedId} onCheckboxChange={this.onChangeHandler} />
      </div>
    )
  }
}

class EventsImportWihNavigation extends Component {
  static propTypes = {
    t: PropTypes.func,
    importItemsToForm: PropTypes.func,
    itemsToImport: PropTypes.arrayOf(PropTypes.object),
    one: PropTypes.bool,
    backLink: PropTypes.string,
    cameFrom: PropTypes.string
  }
  constructor (props) {
    super(props)
    this.extendList = this.extendList.bind(this)
    this.onScroll = this.onScroll.bind(this)
  }

  extendList () {
    this.refs.eventsImport.extendList()
  }

  onScroll () {
    this.refs.filters.onCollapseFilter()
  }

  render () {
    const {t, one, importItemsToForm, backLink, cameFrom} = this.props
    const itemsToImport = one ? this.props.itemsToImport[0] : this.props.itemsToImport
    const actions = [
      {icon: 'icon-add', callback: () => { this.newEntry.makeVisible() }}
    ]
    return (
      <Navigation hideFooter filterBlock filterClass='events-nav-filter' backLink={backLink} title={t('title').s} actions={cameFrom && actions} scrollHandler={this.onScroll} infinityScrollHandler={this.extendList}>
        <Filters ref='filters' onImportClick={() => { importItemsToForm(itemsToImport) }} />
        <EventsImport {...this.props} ref='eventsImport' />
        { cameFrom && <NewEntry
          provideController={(c) => { this.newEntry = c }}
          cameFrom={cameFrom}
        /> }
      </Navigation>
    )
  }
}

function mapStateToProps (state) {
  return {
    filters: getFormValues('eventsViewFilters')(state),
    itemsToImport: state.importItems,
    listData: state.events.data,
    lastOffset: state.events.lastOffset,
    hasNextPage: state.events.hasNextPage,
    t: state.i18n.get('app', 'views', 'EventsImport')
  }
}

function mapDispatchToProps (dispatch, ownProps) {
  const filtersForEvents = {
    orderBy: 'document_date',
    orderType: 'desc',
    ...ownProps.requestParams
  }

  return {
    reloadList: (filters) => { dispatch(reloadEventsAction(filters, true)) },
    onCheck: (data) => { dispatch(addItemAction(data)) },
    onUncheck: (data) => { dispatch(removeItemAction(data)) },
    clearItems: () => { dispatch([removeAllImportItems(), clearEventsListAction()]) },
    extendListData: (currentList, offset, isInitialRequest) => { dispatch(extendEventsAction(currentList, filtersForEvents, offset, false, isInitialRequest)) }
  }
}

export const EventsImportView = connect(mapStateToProps, mapDispatchToProps, null, { withRef: true })(EventsImport)

export default connect(mapStateToProps, mapDispatchToProps)(EventsImportWihNavigation)
