import PropTypes from 'prop-types'
import React, { Component } from 'react'

// Components
import IpadNavigation from 'Navigation/IpadNavigation'
import InvoicePreview from '../components/InvoicePreview'

// Helpers
import getMultipleActions from '../helpers/getMultipleActions'
import getLeftComponent from '../../../../../Navigation/helpers/linkAccordance'

// Container
import InvoicePreviewContainer from './Common'

export class InvoicePreviewView extends Component {
  static propTypes = {
    invoice: PropTypes.object,
    t: PropTypes.func,
    previousLink: PropTypes.string
  }

  render () {
    const {invoice, t, previousLink} = this.props
    const multipleActions = invoice && getMultipleActions(this.props)
    const Events = getLeftComponent('invoice', previousLink)
    return (
      <IpadNavigation {...this.props} title={`${t('navbar', 'title').s} #${invoice.invoiceNumber || ''}`} withoutMaxWidth multipleActions={multipleActions} notBack>
        <Events {...this.props} />
        <InvoicePreview {...this.props} ref='invoice' />
      </IpadNavigation>
    )
  }
}

export default InvoicePreviewContainer(InvoicePreviewView)
