import React from 'react'
import { connect } from 'react-redux'

import CompanyNameInput from 'Counterparties/shared/components/CompanyNameInput'
import { loadCompanyNameSuggestions, updateCustomerCompanyInfo } from '../../actions/companyNames'

const ConnectedCompanyNameInput = (props) =>
  <CompanyNameInput {...props} name='customerCompanyName' />

const mapDispatchToProps = (dispatch) => ({
  updateSuggestions: (...props) => { dispatch(loadCompanyNameSuggestions(...props)) },
  onSelectSuggestion: (suggestion) => { dispatch(updateCustomerCompanyInfo(suggestion.overviewKey)) }
})

export default connect(null, mapDispatchToProps)(ConnectedCompanyNameInput)
