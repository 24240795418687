import '../styles/index.scss?global'

import PropTypes from 'prop-types'

import React from 'react'
import {connect} from 'react-redux'

import ComboBox from 'Entry/components/ComboBox'
import Button from 'shared/components/Button/Button'
import LangSwitchModal from 'Entry/components/LangSwitchModal/LangSwitchModal'

import getGreyLabelOptions from 'GreyLabel/helpers/greyLabel'

export class EntryView extends React.Component {
  static propTypes = {
    t: PropTypes.func
  };

  constructor (props) {
    super(props)
    this.showLangSwitchModal = this.showLangSwitchModal.bind(this)
  }

  showLangSwitchModal () {
    this.langSwitchModal.open()
  }

  get blinfoMenu () {
    const { t } = this.props
    return (
      <div className='entry__menu entry__menu--one-field'>
        <div className='entry__menu__field'>
          <Button link='/login'>{t('buttons', 'login').s}</Button>
        </div>
      </div>
    )
  }

  get defaultMenu () {
    const { t } = this.props

    return (
      <div className='entry__menu'>
        <div className='entry__menu__field entry__menu__field--button'>
          {/* <Button view='primary' link='/sign_up' >{t('buttons', 'createAccount').s}</Button> */}
          <Button view='primary' link='/sign_up_password' >{t('buttons', 'createAccount').s}</Button>
        </div>
        {/* <div className='entry__menu__field'> */}
        {/* <Button view='transparent-white' link='/forgot_password'>{t('buttons', 'forgotPassword').s}</Button> */}
        {/* </div> */}
        <div className='entry__menu__field'>
          <div className='entry__menu__break'>
            <span>{t('alreadyRegistered').s}</span>
          </div>
        </div>
        <div className='entry__menu__field entry__menu__field--button'>
          <Button view='transparent-white' link='/login'>{t('buttons', 'login').s}</Button>
        </div>
      </div>
    )
  }

  renderMenu () {
    switch (getGreyLabelOptions().greyLabel) {
      // case 'blinfo':
      //   return this.blinfoMenu
      default:
        return this.defaultMenu
    }
  }

  renderLangSwitch () {
    if (getGreyLabelOptions().whiteLabel === 'werkeys') {
      return
    }

    return (
      <div className='entry__lang-switch' onClick={this.showLangSwitchModal}>
        <h5>{this.props.t('buttons', 'langSwitch').s}</h5>
        <span className='icon-lang-switch' />
      </div>
    )
  }

  render () {
    const { whiteLabel } = getGreyLabelOptions()
    return (
      <div className={`entry ${whiteLabel}`}>
        <div className='entry__content'>
          { this.renderLangSwitch() }
          <ComboBox />
        </div>
        { this.renderMenu() }
        <LangSwitchModal provideController={(c) => { this.langSwitchModal = c }} />
      </div>
    )
  }
}

function mapStateToProps (state) {
  return {
    t: state.i18n.get('app', 'views', 'Entry')
  }
}

export default connect(mapStateToProps)(EntryView)
