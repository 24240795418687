import { getCurrentClientCompany } from 'api-client-connector/utils'
import { bind } from 'redux-effects'
import { createAction } from 'redux-actions'
import { PROJECTS_LIST_UPDATE } from '../constants/ActionTypes'
import { PROJECTS_LIST } from '../constants/Api'

import { fetch } from 'shared/helpers/fetch'

// Actions
import { showNavbarSpinnerAction, hideNavbarSpinnerAction } from 'shared/actions/navbarSpinner'

// Constants
import { STATUS_INACTIVE, STATUS_ACTIVE } from 'Projects/containers/Filter/Filter'

const updateProjectsList = createAction(PROJECTS_LIST_UPDATE)

const PROJECTS_PER_PAGE = 25

// FIXME: какой смысл в пагинации, если в userSettings.projects и так загружаются все проекты?
// for search: addProjectsToSettings, updateProjectsInSettingsAction
export function extendProjectsListAction (currentList, filters, pageToLoad) {
  return [
    showNavbarSpinnerAction(),
    bind(fetchProjectsList(filters, pageToLoad), processProjectsList.bind(null, currentList, pageToLoad), processError)
  ]
}

export function fetchProjectsList (filters, pageToLoad = 1) {
  const companyId = getCurrentClientCompany()
  const newFilters = makeFiltersForRequest(filters, pageToLoad)
  return fetch(PROJECTS_LIST, { ...newFilters, company_id: companyId }, {
    method: 'GET'
  })
}

export function fetchProjectsByTransaction (transactionId) {
  // transactionId - it is event.transactions[0].id
  return fetchProjectsList({ transactionId, limit: 0 })
}

export function fetchProjectsByTimesheet (timesheetId) {
  return fetchProjectsList({ timesheetId, limit: 0 })
}

export function fetchProjectsByMileageReport (driverlogId) {
  return fetchProjectsList({ driverlogId, limit: 0 })
}

function processProjectsList (oldList, pageToLoad, response) {
  console.log('processing projects', response)
  const loadedProjects = response.value.projects.map((project) => {
    return {
      id: project.id,
      title: project.name,
      startDate: project.start_date,
      active: project.active,
      latestUpdate: project.latest_update // FIXME этого поля нет
    }
  })
  let updatedList = oldList.concat(loadedProjects)

  return [
    updateProjectsList({
      projects: updatedList,
      hasNextPage: (response.value.number_of_projects / PROJECTS_PER_PAGE) > pageToLoad,
      lastPageLoaded: pageToLoad
    }), hideNavbarSpinnerAction()
  ]
}

function processError (response) {
  console.log(`projectList error`, response)
}

function makeFiltersForRequest (filters, page) {
  let newFilters = {}
  newFilters.name__icontains = filters.search || null
  newFilters.order_by = filters.orderBy || null
  newFilters.order_type = filters.orderType || null
  newFilters.limit = filters.limit !== undefined ? filters.limit : PROJECTS_PER_PAGE
  newFilters.offset = (page - 1) * newFilters.limit

  newFilters.timesheet_ids = filters.timesheetId
  newFilters.driverlog_ids = filters.driverlogId
  newFilters.transaction_ids = filters.transactionId

  if (filters.status === STATUS_ACTIVE) newFilters.active = true
  if (filters.status === STATUS_INACTIVE) newFilters.active = false

  return newFilters
}
