import { isExpense, isRevenue } from 'Events/shared/constants/eventType'

export function getEventPlusMinusProperty (type) {
  if (isExpense(type)) {
    return 'minus'
  } else if (isRevenue(type)) {
    return 'plus'
  } else {
    return 'other'
  }
}
