import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { reduxForm, Field, formValueSelector, change } from 'redux-form'
import { haveCompanyRegistrationInfo } from 'mrshoebox-ui-components/src/helpers/countrySpecific'

// Components
import Navigation from 'Navigation/Navigation'
import Input from 'shared/components/FormInput'
import Button from 'shared/components/Button/Button'
import FileListUpload from 'FileUpload/containers/FileListUpload'
import DropDown from 'shared/components/FormDropdown'
import TextArea from 'shared/components/FormTextarea'

// Actions
import { setDefaultSettingsDataAction, updateInvoiceSettingsAction } from 'User/Settings/actions/invoiceSettings'

// Helpers
import { createSelect } from 'shared/factories/createSelect'
import Policy from 'Policy/decorators'

// Contants
import * as AccountTypes from 'User/Settings/constants/AccountTypes'

export class InvoiceSettings extends Component {
  static propTypes = {
    changeAccountNumber: PropTypes.func,
    updateInvoiceSettings: PropTypes.func,
    formValues: PropTypes.object,
    setDefaultSettingsData: PropTypes.func,
    t: PropTypes.func,
    t_buttons: PropTypes.func,
    logo: PropTypes.arrayOf(PropTypes.object),
    redirectIfNotPermitted: PropTypes.func,
    handleSubmit: PropTypes.func
  }

  constructor (props) {
    super(props)
    this.onSubmit = this.onSubmit.bind(this)
    this.getAccountNumberInputMask = this.getAccountNumberInputMask.bind(this)
  }

  componentWillMount () {
    this.props.setDefaultSettingsData()
    this.props.redirectIfNotPermitted('invoice-settings')
  }

  onSubmit (values) {
    const { updateInvoiceSettings } = this.props
    updateInvoiceSettings(values)
  }

  componentDidUpdate (prevProps) {
    const prevAccountType = prevProps.formValues.accountType
    const { formValues: { accountType, accountNumber }, changeAccountNumber } = this.props

    if (prevAccountType !== accountType) {
      changeAccountNumber(accountNumber)
    }
  }

  getAccountNumberInputMask (accountType, value = '') {
    const createNumberMask = (value) => {
      return value.split('').map(() => /\d/).concat([/\d/])
    }

    const numbers = value.replace(/[-_]/g, '')
    switch (accountType) {
      case AccountTypes.BANKGIRO:
        if (numbers.length <= 7) {
          return [/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/]
        } else {
          return [/\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
        }
      case AccountTypes.PLUSGIRO:
        if (numbers.length >= 2) {
          // const length = numbers.length
          // const mask = new Array(length + 1)
          // mask[length] = /\d/
          // mask[length - 1] = '-'
          // mask.fill(/\d/, 0, length - 1)
          const mask = createNumberMask(numbers)
          mask.splice(mask.length - 2, 0, '-')

          return mask
        } else {
          return createNumberMask(value)
        }
      default:
        return createNumberMask(value)
    }
  }

  render () {
    const {
      t, t_buttons,
      formValues: { accountType, accountNumber },
      logo,
      handleSubmit
    } = this.props
    const accountTypesList = createSelect([AccountTypes.BANKGIRO, AccountTypes.PLUSGIRO, AccountTypes.BANK_ACCOUNT], t('selects', 'accountType'))

    return (
      <div className='invoice-settings f-column'>
        <div className='form invoice-settings__form f-column-top'>
          <Field name='accountType' component={DropDown} big emptyOption={t('emptyOptions', 'accountType').s} options={accountTypesList} hint={t('fields', 'accountType').s} />
          <Field name='accountNumber' component={Input} placeholder={t('fields', 'accountNumber').s} type='tel' mask={this.getAccountNumberInputMask(accountType, accountNumber)} key={accountType} />
          {/* TODO: commented for TREP-671. Remove when needed again. <Input placeholder={t('fields', 'lateFeePercentage').s} {...defaultLateFeePercentage} type='float' maxValue='99.99' /> */}
          <Field name='defaultReminderFee' component={Input} placeholder={t('fields', 'reminderFee').s} type='float' />
          <Field name='defaultInvoiceFee' component={Input} placeholder={t('fields', 'invoiceFee').s} type='float' />
          <Field name='defaultInvoiceDays' component={Input} placeholder={t('fields', 'defaultInvoiceDays').s} type='number' />
          <Field name='iban' component={Input} placeholder={t('fields', 'iban').s} />
          <Field name='swift' component={Input} placeholder={t('fields', 'swift').s} />
          { haveCompanyRegistrationInfo && <Field name='companyRegistrationInfo' component={TextArea} placeholder={t('fields', 'companyRegistrationInfo').s} /> }
          <div className='form__item'>
            <FileListUpload files={logo || []} hint={t('fields', 'logo').s} onlyOne />
          </div>
        </div>
        <div className='f-column-bottom'>
          <Button saveButton view='transparent-black' onClick={handleSubmit(this.onSubmit)}>{t_buttons('save').s}</Button>
        </div>
      </div>
    )
  }
}

class InvoiceSettingsWithNavigation extends Component {
  static propTypes = {
    t: PropTypes.func
  }
  render () {
    const {t} = this.props

    return (
      <Navigation hideFooter title={t('title').s} className='f-column'>
        <InvoiceSettings {...this.props} />
      </Navigation>
    )
  }
}

const formName = 'invoice-settings'
const selector = formValueSelector(formName)

function mapStateToProps (state) {
  return {
    initialValues: {
      accountType: state.invoiceSettings.account_type,
      accountNumber: state.invoiceSettings.account_number,
      organizationNumber: state.invoiceSettings.organization_number,
      defaultReminderFee: state.invoiceSettings.default_reminder_fee,
      defaultInvoiceFee: state.invoiceSettings.default_invoice_fee,
      defaultLateFeePercentage: state.invoiceSettings.default_late_fee_percentage,
      defaultInvoiceDays: state.invoiceSettings.default_invoice_days,
      companyRegistrationInfo: state.invoiceSettings.companyRegistrationInfo,
      iban: state.invoiceSettings.iban,
      swift: state.invoiceSettings.swift,
      isNewSettings: state.invoiceSettings.isNewSettings
    },
    logo: state.invoiceSettings.logo,
    t: state.i18n.get('app', 'views', 'InvoiceSettingsView'),
    t_buttons: state.i18n.get('app', 'shared', 'buttons'),
    formValues: selector(state, 'accountNumber', 'accountType')
  }
}

function mapDispatchToProps (dispatch, ownProps) {
  return {
    setDefaultSettingsData: () => { dispatch(setDefaultSettingsDataAction()) },
    updateInvoiceSettings: (data) => { dispatch(updateInvoiceSettingsAction(data, ownProps.location.query.redirectPath)) },
    changeAccountNumber: (value) => dispatch(change(formName, 'accountNumber', value))
  }
}

function mergeProps (stateProps, dispatchProps, ownProps) {
  // For tests to override updateInvoiceSettings with mocked function passed to ownProps.
  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps
  }
}

function validate (values) {
  let errors = {}
  if (!values.accountType) {
    errors.accountType = true
  }
  if (!values.accountNumber) {
    errors.accountNumber = true
  }

  return errors
}

export const InvoiceSettingsView = connect(mapStateToProps, mapDispatchToProps, mergeProps)(reduxForm({
  form: formName,
  validate
})(Policy(InvoiceSettings)))

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(reduxForm({
  form: 'invoice-settings',
  validate
})(Policy(InvoiceSettingsWithNavigation)))
