import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router'
import { formatCurrency } from 'mrshoebox-ui-components/src/helpers/countrySpecific'

import { isMrshoeboxRelease } from 'shared/helpers/releaseHelpers'
import { isIOS } from 'shared/helpers/utils'
import { loadOnboardingInfo } from 'MarketPlace/Capcito/actions/capcito'
import { fetchLoanInfo } from 'Overview/actions/loan'

import './index.scss?global'

function LoanInfoBlock (props) {
  const { loadCapcitoOnboardingInfo, loadLoanInfo, t } = props
  const [capcitoState, setLoanInfoState] = useState(
    {
      loan: { existing: 0, limit: 0 },
      isCapcitoActive: false,
      redirectLink: null
    }
  )
  useEffect(() => {
    async function fetchLoanData () {
      if (isMrshoeboxRelease() && !capcitoState.redirectLink) {
        const onboardingStatusResponse = await loadCapcitoOnboardingInfo()
        const loanResponse = await loadLoanInfo()

        setLoanInfoState({
          loan: loanResponse.data,
          isCapcitoActive: Boolean(onboardingStatusResponse),
          redirectLink: onboardingStatusResponse.data.redirectLink
        })
      }
    }
    fetchLoanData()
  }, [capcitoState, loadCapcitoOnboardingInfo, loadLoanInfo])

  const openCapcitoPortalInNewTab = () => {
    window.open(capcitoState.redirectLink, '_blank')
    setLoanInfoState({...capcitoState, redirectLink: null})
  }

  const loanPath = capcitoState.isCapcitoActive
    ? '/marketplace/capcito/portal'
    : '/marketplace/capcito'

  const loanValues = {
    existingLoan: formatCurrency(capcitoState.isCapcitoActive ? capcitoState.loan.existing : capcitoState.loan.limit, { withSymbol: true }),
    loanLimit: formatCurrency(capcitoState.isCapcitoActive ? capcitoState.loan.limit : '?', { withSymbol: true })
  }
  const className = 'loan-info-block__link  loan-info-block__link--mrshoebox'
  const extraProps = isIOS() && capcitoState.isCapcitoActive
    ? { onClick: openCapcitoPortalInNewTab }
    : { to: loanPath }
  return (
    <div className='loan-info-block'>
      <Link className={className} {...extraProps} >
        <span className='icon-loan' />
        <h3>{t('existingLoan').s}</h3>
        <span className='loan-link__loan-value'>{loanValues.existingLoan}</span>
      </Link>
      <Link className={className} {...extraProps} >
        <span className='icon-loan2' />
        <h3>{t('loanLimit').s}</h3>
        <span className='loan-link__loan-value'>{loanValues.loanLimit}</span>
      </Link>
    </div>
  )
}

LoanInfoBlock.propTypes = {
  loadLoanInfo: PropTypes.func,
  loadCapcitoOnboardingInfo: PropTypes.func
}

function mapStateToProps (state) {
  return {
    t: state.i18n.get('app', 'views', 'Invoices', 'InvoicesView', 'LoanInfoBlock')
  }
}

function mapDispatchToProps (dispatch) {
  return {
    loadCapcitoOnboardingInfo: () => dispatch(loadOnboardingInfo({refreshRedirectLink: true})),
    loadLoanInfo: () => dispatch(fetchLoanInfo())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoanInfoBlock)
