import './styles.scss?global'

import Smartphones from 'Integrations/views/IntegrationsView/containers/Smartphones'
import Tablets from 'Integrations/views/IntegrationsView/containers/Tablets'

import { getCurrentDeviceView } from 'shared/helpers/utils'

const IntegrationsView = getCurrentDeviceView(Smartphones, Tablets)

export default IntegrationsView
