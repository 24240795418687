import PropTypes from 'prop-types'
import React, { Component } from 'react'

import IpadNavigation from 'Navigation/IpadNavigation'
import { Chat } from 'Messages/views/ConversationView/containers/Smartphones'
import ConversationsView from 'Messages/views/ConversationsView/containers/Smartphones'
import Common from './Common'

export class ConversationView extends Component {
  static propTypes = {
    companyName: PropTypes.string,
    message: PropTypes.object,
    previousLink: PropTypes.string,
    loadMoreMessagesHandler: PropTypes.func
  }

  render () {
    const { loadMoreMessagesHandler, companyName } = this.props

    return (
      <IpadNavigation
        title={companyName || ''}
        notBack
        onReachingTopHandler={loadMoreMessagesHandler}
        withoutMaxWidth
        {...this.props}
      >
        <ConversationsView {...this.props} />
        <Chat {...this.props} />
      </IpadNavigation>
    )
  }
}

export default Common(ConversationView)
