import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { get, debounce } from 'lodash'

import SuggestInput from 'shared/components/SuggestionInput'

class AsyncSuggestionInput extends Component {
  static propTypes = {
    dataKey: PropTypes.string.isRequired,
    renderSuggestion: PropTypes.func.isRequired,
    inputProps: PropTypes.object.isRequired,
    onSuggestionsFetchRequested: PropTypes.func.isRequired,
    onSuggestionSelected: PropTypes.func,
    suggestions: PropTypes.arrayOf(PropTypes.object)
  }

  getInputValue = (suggestion) => {
    return suggestion[this.props.dataKey]
  }

  componentDidMount () {
    this.updateSuggestions = debounce(this.props.onSuggestionsFetchRequested, 250)
  }

  render () {
    return (
      <SuggestInput
        onSuggestionsFetchRequested={this.updateSuggestions}
        getSuggestions={() => this.props.suggestions}
        getInputValue={this.getInputValue}
        renderSuggestion={this.props.renderSuggestion}
        inputProps={this.props.inputProps}
        onSuggestionSelected={this.props.onSuggestionSelected}
      />
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    suggestions: get(state, `suggestions.${ownProps.name}`, [])
  }
}

export default connect(mapStateToProps)(AsyncSuggestionInput)
