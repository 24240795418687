import React from 'react'
import PropTypes from 'prop-types'
import styles from './styles.scss'
import { UniversalLink } from 'shared/components/LinkReplace'

export default function IntegrationsListItem (props) {
  const { value, title, path } = props.data
  return (
    <UniversalLink to={path} className={`list-single-item ${styles.listItem}`}>
      <h3 className='truncate'>
        {title}
      </h3>
      <h3>
        {value}
      </h3>
      <h3><span className='icon-arrow-right' /></h3>
    </UniversalLink>
  )
}

IntegrationsListItem.propTypes = {
  data: PropTypes.object.isRequired
}
