import './FullPDF.scss?global'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { findDOMNode } from 'react-dom'
import { range } from 'lodash'
import { connect } from 'react-redux'
import PinchZoom from 'vanilla-pinch'
import cs from 'classnames'
import isMobile from 'ismobilejs'
import { isFlutterApp } from 'appBridge/bridge'
import { isIpadResolution } from 'shared/helpers/utils'

let Document = null
let Page = null

export class FullPDF extends Component {
  static propTypes = {
    previewMode: PropTypes.bool,
    path: PropTypes.string.isRequired,
    t: PropTypes.func,
    zoomable: PropTypes.bool,
    pdfId: PropTypes.string
  };

  constructor (props) {
    super(props)
    this.state = {
      isLoaded: false,
      pages: 0,
      width: 0
    }
    this._pinchZoom = null
    this._onPdfCompleted = this._onPdfCompleted.bind(this)
  }

  get fileOptions () {
    const { path } = this.props

    return {
      file: path
    }
  }

  componentDidMount () {
    import(
      /* webpackChunkName: "pdf-lib" */
      'react-pdf/dist/entry.webpack'
    ).then((file) => { // eslint-disable-line
      Document = file.Document
      Page = file.Page
      this.forceUpdate()
    })
    this.setState({ width: findDOMNode(this).getBoundingClientRect().width })
  }

  componentDidUpdate () {
    const { zoomable } = this.props
    const { isLoaded } = this.state
    if (zoomable && isLoaded && !this._pinchZoom) {
      this._pinchZoom = new PinchZoom(findDOMNode(this))
    }
  }

  componentWillUnmount () {
    this._pinchZoom && this._pinchZoom.disable()
  }

  componentWillReceiveProps (nextProps) {
    if (this.props.pdfId !== nextProps.pdfId) {
      this.setState({
        isLoaded: false
      })
    }
  }

  _onPdfCompleted (document) {
    const pages = document.numPages
    this.setState({
      isLoaded: true,
      pages
    })
  }

  renderRemainingPages () {
    return range(2, this.state.pages + 1).map((number) => {
      return this.renderPage(number)
    })
  }

  renderPage (number) {
    const classNames = cs('pdf-view', {
      'pdf-view_with-border': this.state.isLoaded && !this.props.previewMode
    })
    return <Page className={classNames} width={this.state.width} pageNumber={number} key={number} />
  }

  render () {
    const { path, t, previewMode } = this.props
    const loading = <div>{t('loading').s}</div>

    // To trigger native Flutter rendered of App v3 we need to path straight link to the file. Also, old hack for old Java app to open file in native Android reader app
    if (isMobile.android.phone || (isFlutterApp() && !isIpadResolution())) {
      if (previewMode) return <div />
      return <a className={'pdf-view__open-link'} href={path}>Öppna fil</a>
    }

    if (!Document || !path) {
      return loading
    }

    return (
      <div>
        <Document
          loading={loading}
          {...this.fileOptions}
          onLoadSuccess={this._onPdfCompleted}
        >
          { this.renderPage(1) }
          { (!previewMode && this.state.pages > 1) ? this.renderRemainingPages() : null }
        </Document>
      </div>
    )
  }
}

function mapStateToProps (state) {
  return {
    t: state.i18n.get('app', 'shared', 'labels'),
    pdfId: (state.appInfo.activeItemId || '').toString()
  }
}

export default connect(mapStateToProps)(FullPDF)
