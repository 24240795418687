import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import moment from 'moment'

import { sendMessageAction, getConversationAction, clearCurrentConversation, loadMoreOldMessagesAction } from 'Messages/actions/messages'

export default function Common (WrappedComponent) {
  class WrappedChat extends Component {
    static propTypes = {
      params: PropTypes.object,
      sendMessage: PropTypes.func.isRequired,
      offset: PropTypes.number,
      loadMoreOldMessages: PropTypes.func.isRequired,
      messages: PropTypes.array,
      hasMoreMessages: PropTypes.bool
    }

    constructor (props) {
      super(props)
      this.state = {
        firstMessage: null,
        firstMessageTop: null,
        isScrollingToLastSeen: false
      }
      this.loadMoreMessagesHandler = this.loadMoreMessagesHandler.bind(this)
      this.setLastSeenMessage = this.setLastSeenMessage.bind(this)
      this.changeScrollingToLastSeenState = this.changeScrollingToLastSeenState.bind(this)
    }

    loadMoreMessagesHandler () {
      const { offset, loadMoreOldMessages, hasMoreMessages, params: { conversationId } } = this.props

      if (hasMoreMessages) {
        this.setLastSeenMessage()
        loadMoreOldMessages(conversationId, offset + 20)
      }
    }

    setLastSeenMessage () {
      const firstMessage = document.querySelectorAll('.chat__messages>div:first-child')[0]
      const firstMessageTop = firstMessage.getBoundingClientRect().top
      this.setState({ firstMessage, firstMessageTop, isScrollingToLastSeen: true })
    }

    changeScrollingToLastSeenState () {
      this.setState({ isScrollingToLastSeen: false })
    }

    render () {
      return (
        <WrappedComponent
          {...this.props}
          loadMoreMessagesHandler={this.loadMoreMessagesHandler}
          changeScrollingToLastSeenState={this.changeScrollingToLastSeenState}
          firstMessage={this.state.firstMessage}
          firstMessageTop={this.state.firstMessageTop}
          isScrollingToLastSeen={this.state.isScrollingToLastSeen}
        />
      )
    }
  }

  function mapStateToProps (state) {
    const { currentConversation: { data: { accountingCompanyName, messages = [], interlocutors, readOnly }, offset, hasMoreMessages } } = state.messages
    const findInterlocutor = (message) => interlocutors.find(i => i.interlocutorId === message.interlocutorId) || {}
    return {
      messages: messages.map(message => ({
        ...message,
        isSender: findInterlocutor(message).userId === state.userSettings.user.id,
        createdAt: moment.utc(message.createdAt).local().format('YYYY-MM-DD, HH:mm:ss'),
        name: findInterlocutor(message).userName
      })),
      offset,
      hasMoreMessages,
      companyName: accountingCompanyName,
      readOnly
    }
  }

  function mapDispatchToProps (dispatch) {
    return {
      sendMessage: (conversationId, message) => dispatch(sendMessageAction(conversationId, message)),
      loadConversation: (conversationId) => dispatch(getConversationAction(conversationId)),
      loadMoreOldMessages: (conversationId, offset) => dispatch(loadMoreOldMessagesAction(conversationId, offset)),
      clearCurrentConversation: () => dispatch(clearCurrentConversation())
    }
  }

  return connect(mapStateToProps, mapDispatchToProps)(WrappedChat)
}
