import './index.scss?global'

import PropTypes from 'prop-types'
import React, { Component } from 'react'
import cs from 'classnames'

import SettingsModalList from '../SettingsList/SettingsList'
import { RESULT_TYPE } from 'Overview/constants/OverviewSettings'

import getGreyLabelOptions from 'GreyLabel/helpers/greyLabel'

export default class Dates extends Component {
  static propTypes = {
    settings: PropTypes.object,
    onSaveClick: PropTypes.func
  }

  openSettings = () => {
    this.settingsModal.open()
  }

  render () {
    const {settings: {result, forecast, type}, onSaveClick} = this.props
    const dates = type === RESULT_TYPE ? result : forecast
    const { whiteLabel } = getGreyLabelOptions()
    return (
      <div>
        <div className={cs('overview-view__charts__dates', whiteLabel)}>
          <div>
            <div className={cs('date-from', whiteLabel)} onClick={this.openSettings}>
              <span className='icon-date-from' />
              <span>{dates.from}</span>
            </div>
            <div className={cs('date-to', whiteLabel)} onClick={this.openSettings}>
              <span>{dates.to}</span>
              <span className='icon-date-to' />
            </div>
          </div>
        </div>
        <SettingsModalList type={type} onSaveClick={onSaveClick} provideController={(c) => { this.settingsModal = c }} />
      </div>
    )
  }
}
