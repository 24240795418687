import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import IpadNavigation from 'Navigation/IpadNavigation'
import {InvoiceSettingsView as InvoiceSettings} from 'User/Settings/views/InvoiceSettingsView/containers/Smartphones'

import getLeftComponent from 'Navigation/helpers/linkAccordance'

class InvoiceSettingsView extends Component {
  static propTypes = {
    t: PropTypes.func,
    previousLink: PropTypes.string
  }
  render () {
    const { t, previousLink } = this.props
    const RightSideItems = getLeftComponent('invoiceSettings', previousLink)

    return (
      <IpadNavigation {...this.props} title={t('title').s}>
        <RightSideItems {...this.props} />
        <InvoiceSettings {...this.props} />
      </IpadNavigation>
    )
  }
}

function mapStateToProps (state) {
  return {
    t: state.i18n.get('app', 'views', 'InvoiceSettingsView'),
    previousLink: state.appInfo.previousLink
  }
}

export default connect(mapStateToProps)(InvoiceSettingsView)
