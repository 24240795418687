import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getFormValues } from 'redux-form'
import { isEqual } from 'lodash'
import { Helmet } from 'react-helmet'

import Navigation from 'Navigation/Navigation'
import ListView from 'shared/components/ListView/ListView'
import { SupplierInvoiceListItem, SupplierInvoiceListCheckboxItem } from '../../../../shared/components/ListItem/ListItem'
import SuppliersInvoicesFilters from '../../../components/Filters/SuppliersFilters'
import ApproveForPaymentsBlock from '../../../components/ApproveForPaymentsBlock'

// Constants
import { NOT_PAID, APPROVED } from '../../../../shared/constants/StatusTypes'

import {
  loadSupplierInvoices,
  extendSupplierInvoices,
  changeApproveSelectionForAll,
  selectForApprove,
  unselectForApprove
} from '../../../actions/supplierInvoices'

export class SupplierInvoicesView extends Component {
  static propTypes = {
    invoices: PropTypes.arrayOf(PropTypes.object),
    extendList: PropTypes.func,
    filters: PropTypes.object,
    ruleFilter: PropTypes.object,
    reloadList: PropTypes.func,
    toggleApproveForAll: PropTypes.func,
    selectForApprove: PropTypes.func,
    unselectForApprove: PropTypes.func,
    t: PropTypes.func
  };

  componentDidMount () {
    const { filters, ruleFilter } = this.props
    if (filters.orderBy) {
      this.props.reloadList(filters, ruleFilter)
    }
  }

  componentDidUpdate (prevProps) {
    const { filters, ruleFilter } = this.props
    const changed = (filters, newFilters) => filters && !isEqual(filters, newFilters)
    if (changed(prevProps.filters, filters) || changed(prevProps.ruleFilter, ruleFilter)) {
      this.props.reloadList(filters, ruleFilter)
    }
  }

  onCheckHandler = (isChecked, data) => {
    isChecked ? this.props.selectForApprove(data) : this.props.unselectForApprove(data)
  }

  extendList = () => {
    const { filters, ruleFilter, extendList } = this.props
    extendList(filters, ruleFilter)
  }

  handleCheckSelectAllForApprove = (checked) => {
    this.props.toggleApproveForAll(checked)
  }

  render () {
    const { filters, invoices, t } = this.props
    const actions = [
      {icon: 'icon-add', path: '/events/new/expense?type=invoice'}
    ]
    const isNotPaidTab = filters.status === NOT_PAID
    let invoicesList = invoices
    // to show payment sum
    if (filters.status === APPROVED) {
      invoicesList = invoicesList.map(i => ({ ...i, checkedForAction: true }))
    }
    const showApproveBlock = (isNotPaidTab && invoicesList.some(i => i.checkedForAction)) ||
          filters.status === APPROVED
    const template = (isNotPaidTab ? SupplierInvoiceListCheckboxItem : SupplierInvoiceListItem)
    return (
      <Navigation title={t('navbar', 'title').s} actions={actions} backLink='/more/supplier-invoices' forceBackLink filterBlock customFooter infinityScrollHandler={this.extendList} className='invoices-view'>
        <SuppliersInvoicesFilters onCheckSelectAll={this.handleCheckSelectAllForApprove} />
        <Helmet>
          <meta
            name='viewport'
            content='width=640, maximum-scale=1.0, user-scalable=no'
          />
        </Helmet>
        <ListView data={invoicesList} template={template} emptyListOptions={{icon: 'icon-bills-full'}} onCheckboxChange={this.onCheckHandler} />
        <div>
          {showApproveBlock && <ApproveForPaymentsBlock invoices={invoicesList} withButtons={isNotPaidTab} />}
        </div>
      </Navigation>
    )
  }
}

function mapStateToProps (state) {
  return {
    invoices: state.invoices.supplierInvoices.listData,
    t: state.i18n.get('app', 'views', 'Invoices', 'InvoicesView'),
    ruleFilter: state.invoices.supplierInvoices.approveRuleFilters,
    filters: getFormValues('invoicesViewFilters')(state) || {}
  }
}

function mapDispatchToProps (dispatch) {
  return {
    reloadList: (...args) => { dispatch(loadSupplierInvoices(...args)) },
    extendList: (...args) => { dispatch(extendSupplierInvoices(...args)) },
    toggleApproveForAll: (...args) => { dispatch(changeApproveSelectionForAll(...args)) },
    selectForApprove: (...args) => { dispatch(selectForApprove(...args)) },
    unselectForApprove: (...args) => { dispatch(unselectForApprove(...args)) }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SupplierInvoicesView)
