import URI from 'urijs'

// Constants
import { UPLOADS_BASE } from 'shared/constants/Api'

export default function getMultipleActions (props) {
  function getMailtoContext () {
    const {
      document: { category, title, files = [] },
      t
    } = props

    const fileUrls = files.map((f) => `${UPLOADS_BASE}/${f.path}/${f.file_name}`).join('\n')
    const subject = t('email', 'subject').s
    const body = `${t('email', 'title').s}: ${title}\n` +
      `${t('email', 'category').s}: ${category}\n\n` +
      `${t('email', 'files').s}:\n` +
      fileUrls

    return 'mailto:?' + URI.buildQuery({ subject, body }, false, false)
  }

  const { t, location: { pathname } } = props
  const mailtoLink = getMailtoContext()

  return [
    { title: t('modal', 'edit').s, link: pathname + '/edit' },
    { title: t('modal', 'sendByMail').s, nativeLink: mailtoLink },
    { title: t('buttons', 'delete').s, onClick: props.showDeleteDocumentMessageBox }
  ]
}
