import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'

// Actions
import { getForecastData } from 'Overview/actions/stats'
import { getResultOverviewAction } from 'Overview/actions/resultStats'

// Decorators
import Policy from 'Policy/decorators'

export default function OverviewViewContainer (Component) {
  class Overview extends React.Component {
    static propTypes = {
      getForecastData: PropTypes.func,
      getResultData: PropTypes.func,
      location: PropTypes.object,
      redirectIfNotPermitted: PropTypes.func
    }
    componentWillMount () {
      const { location: { query: { type = 'result' } }, redirectIfNotPermitted } = this.props
      this.loadOverviewData()
      redirectIfNotPermitted(`overview-${type}`)
    }
    loadOverviewData = (settingsModalValues) => {
      const {location: { query: { type = 'result' } }} = this.props
      this.getData(type, settingsModalValues)
    }
    getData = (type, data) => {
      if (type === 'forecast') {
        this.props.getForecastData(data)
      } else {
        this.props.getResultData(data)
      }
    }

    render () {
      return (
        <Component {...this.props} loadOverviewData={this.loadOverviewData} />
      )
    }
  }

  function mapDispatchToProps (dispatch) {
    return {
      getForecastData: (data) => { dispatch(getForecastData(data)) },
      getResultData: (data) => { dispatch(getResultOverviewAction(data)) }
    }
  }

  function mapStateToProps (state) {
    const { settings, results } = state.overview
    return {
      settings,
      results,
      t: state.i18n.get('app', 'views', 'OverviewView'),
      tViews: state.i18n.get('app', 'views')
    }
  }

  return Policy(connect(mapStateToProps, mapDispatchToProps)(Overview))
}
