import { connect } from 'react-redux'
import { getFormValues } from 'redux-form'
import { routerActions } from 'react-router-redux'

import { loadSuppliersList, clearSuppliersListAction } from 'Counterparties/Suppliers/actions/suppliers'
import { loadDataToExpenseViewAction } from 'Events/Expense/actions/newExpense'
import SuppliersView from 'Counterparties/Suppliers/views/SelectableSuppliersView/SelectableSuppliersView'

// Constants
import { defaultFilterValues } from 'Counterparties/Suppliers/components/Filters/Filters'
import { getCurrentClientCompany } from 'api-client-connector/utils'

function mapStateToProps (state) {
  return {
    cameFrom: 'supplier-invoice',
    supplierId: state.events.newEvent.supplier.id,
    suppliers: state.suppliers.listData,
    lastOffset: state.suppliers.lastOffset,
    hasNextPage: state.suppliers.hasNextPage,
    filters: getFormValues('suppliersListFilters')(state) || defaultFilterValues,
    t: state.i18n.get('app', 'views', 'Suppliers', 'SuppliersView')
  }
}

function mapDispatchToProps (dispatch) {
  const companyId = getCurrentClientCompany()
  return {
    loadList: (filters, offset) => { dispatch(loadSuppliersList(filters, offset, companyId)) },
    cleanList: () => { dispatch(clearSuppliersListAction()) },
    updateSupplier: (supplier) => {
      dispatch([
        loadDataToExpenseViewAction({supplier}),
        routerActions.goBack()
      ])
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SuppliersView)
