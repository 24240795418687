import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { routerActions } from 'react-router-redux'

import Navigation from 'Navigation/Navigation'
import OpenPaymentsForm from 'Integrations/OpenPayments/components/OpenPaymentsForm'
import InProgress from 'MarketPlace/shared/components/InProgress'

import { createConsent, getValidatedFields } from 'Integrations/OpenPayments/actions'
import {
  showElementInMessageBoxAction,
  closeMessageBoxAction
} from 'MessageBox/actions/messageBox'

const OpenPaymentsOnboardingView = (props) => {
  const { getValidatedFields, location: { query }, openSuccessModal, closeModal, tModal, redirectToIntegrations, goTo } = props

  useEffect(() => { getValidatedFields() }, [getValidatedFields])
  useEffect(() => {
    const closeHandler = () => {
      closeModal()
      query.cameFrom ? goTo(query.cameFrom) : redirectToIntegrations()
    }
    if (query.success === 'true') {
      openSuccessModal(tModal, closeHandler)
    }
  }, [query.success, query.cameFrom, openSuccessModal, tModal, closeModal, redirectToIntegrations, goTo])
  return (
    <OpenPaymentsForm {...props} />
  )
}

OpenPaymentsOnboardingView.propTypes = {
  getValidatedFields: PropTypes.func.isRequired,
  goTo: PropTypes.func.isRequired,
  redirectToIntegrations: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  openSuccessModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  tModal: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired
}

const OpenPaymentsOnboardingViewWithNavigation = ({ title, ...props }) =>
  <Navigation hideFooter title={title} notBottomSpace>
    <OpenPaymentsOnboardingView {...props} />
  </Navigation>

OpenPaymentsOnboardingViewWithNavigation.propTypes = {
  texts: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired
}

const mapStateToProps = (state) => {
  const { initialValues } = state.integrations.openPayments.validatedFields
  return ({
    initialValues,
    isLoading: Object.keys(initialValues).length === 0,
    banksOptions: state.integrations.openPayments.banks.map(bank => ({ label: bank.bankName, value: bank.bic })),
    texts: {
      header: state.i18n.get('app', 'views', 'Integrations', 'OpenPayments', 'form', 'header').s,
      instructionHeader: state.i18n.get('app', 'views', 'Integrations', 'OpenPayments', 'form', 'instructionHeader').s,
      tFields: state.i18n.get('app', 'views', 'Integrations', 'OpenPayments', 'form', 'fields'),
      submitButton: state.i18n.get('app', 'views', 'Integrations', 'OpenPayments', 'form', 'completeRegistration').s,
      description: state.i18n.get('app', 'views', 'Integrations', 'OpenPayments', 'form', 'description').s
    },
    tModal: state.i18n.get('app', 'views', 'Integrations', 'OpenPayments', 'successModal'),
    title: state.i18n.get('app', 'views', 'Integrations', 'OpenPayments', 'navBar', 'title').s
  })
}

const mapDispatchToProps = (dispatch) => ({
  createConsent: (values) => dispatch(createConsent(values)),
  getValidatedFields: () => dispatch(getValidatedFields()),
  openSuccessModal: (t, closeHandler) => dispatch(showElementInMessageBoxAction(
    <InProgress
      closeHandler={closeHandler}
      buttonText={t('button').s}
      customHeader={t('integrationSuccessful').s}
      iconName='open-payments-logo'
    />,
    closeHandler
  )),
  closeModal: () => dispatch(closeMessageBoxAction()),
  redirectToIntegrations: () => dispatch(routerActions.push('/integrations')),
  goTo: (path) => dispatch(routerActions.push(path))
})

export const connectedOpenPaymentsOnboarding = connect(mapStateToProps, mapDispatchToProps)(OpenPaymentsOnboardingView)
export default connect(mapStateToProps, mapDispatchToProps)(OpenPaymentsOnboardingViewWithNavigation)
