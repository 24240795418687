import '../ReceiptBaseView.scss?global'

import PropTypes from 'prop-types'

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { reduxForm, Field, getFormValues, change, SubmissionError } from 'redux-form'
import { routerActions } from 'react-router-redux'
import { Link } from 'react-router'
import { result, find, pick, includes } from 'lodash'
import moment from 'moment'

// Actions
import { clearNewEventAction } from 'Events/shared/actions/event'
import { calculateAndSetVatAmountAction, updateNewExpensePropsAction } from 'Events/shared/actions/receipt'
import { editEventRowsFieldsAction } from 'Events/shared/actions/splitAmount'
import { loadDataToExpenseViewAction } from 'Events/Expense/actions/newExpense'

// Components
import Navigation from 'Navigation/Navigation'
import Input from 'shared/components/FormInput'
import Button from 'shared/components/Button/Button'
import DropDown from 'shared/components/FormDropdown'
import FileListUpload from 'FileUpload/containers/FileListUpload'
import FormatNumber from 'shared/components/FormatNumber/FormatNumber'
import AutocompleteButton from 'OCR/components/AutocompleteButton/AutocompleteButton'
import PaymentStatusField from 'Payments/components/PaymentStatusField/PaymentStatusField'
import PaymentAddForm from 'Payments/components/PaymentAddForm/PaymentAddForm'
import AmountBlock from '../components/AmountBlock'
import SplitAmountBlock from '../components/SplitAmountBlock'
import EventTitleInput from 'Events/shared/components/EventTitleInput'
import EventSupplier from 'Events/Expense/components/EventSupplier/EventSupplier'

// Helpers
import { findValueInObjectByProp, isIpadResolution } from 'shared/helpers/utils'
import { createSelect } from 'shared/factories/createSelect'
import { scrollTo } from 'shared/helpers/scrollUtils'

// Constants
import { INVOICE_TYPE_INVOICE, INVOICE_TYPE_CREDIT_INVOICE } from 'Events/shared/constants/eventType'

export class ReceiptBase extends Component {
  static propTypes = {
    changeFormValue: PropTypes.func,
    location: PropTypes.object,
    event: PropTypes.object,
    settings: PropTypes.object,
    formValues: PropTypes.object,
    pushReceipt: PropTypes.func,
    clearEvent: PropTypes.func,
    redirectToRepresentation: PropTypes.func,
    t: PropTypes.func,
    t_buttons: PropTypes.func,
    paymentMethods: PropTypes.arrayOf(PropTypes.object),
    categories: PropTypes.arrayOf(PropTypes.object),
    calculateAndSetVatAmount: PropTypes.func,
    type: PropTypes.string,
    handleSubmit: PropTypes.func,
    invoiceMode: PropTypes.bool,
    paymentEditMode: PropTypes.bool,
    params: PropTypes.object,
    removeSupplier: PropTypes.func
  }

  static defaultProps = {
    invoiceMode: false,
    formValues: { eventRows: [] }
  }

  constructor (props) {
    super(props)
    this.categoryChangeHandler = this.categoryChangeHandler.bind(this)
    this.saveReceipt = this.saveReceipt.bind(this)
  }

  scrollTop = () => {
    const scrollableElement = isIpadResolution()
      ? document.querySelector('.desktop-scroll>.scrollBlock__content')
      : document.querySelector('.scrollBlock__content')
    scrollableElement.scrollTop = 0
  }

  renderRepresentationList (pathname, list, category) {
    const { t, settings: { representationCategoryIds }, formValues: { eventRows } } = this.props
    const hasRepresentationInEvent = includes(representationCategoryIds, Number(category)) ||
          eventRows.some((r) => includes(representationCategoryIds, Number(r.categoryId)))
    if (list.length && hasRepresentationInEvent) {
      return (
        <div>
          <div className='hr'><span>{t('info', 'representation').s}</span></div>
          <div className='receipt-info-list receipt-info-list--label'>
            {list.map((item, index) => {
              return (
                <Link to={`${pathname}/representation`} key={index} className='receipt-info-list__item-container'>
                  <div className='receipt-info-list__item'>
                    <div>
                      <div><h3 className='truncate'>{item.name}</h3></div>
                      <div><h3 className='truncate'>{item.company}</h3></div>
                    </div>
                    <div>
                      <div>{t('representation', 'name').s}</div>
                      <div>{t('representation', 'company').s}</div>
                    </div>
                  </div>
                  <h4 className='icon-edit'><span className='icon-pen' /></h4>
                </Link>
              )
            })}
          </div>
        </div>
      )
    } else {
      return null
    }
  }

  renderRowsList (pathname, list) {
    const { t } = this.props
    if (list.length) {
      return (
        <div>
          <div className='hr'><span>{t('info', 'eventRows').s}</span></div>
          <div className='receipt-info-list'>
            {list.map((item, index) => {
              return (
                <Link to={`${pathname}/amount/split?cameFrom=form`} key={index} className='receipt-info-list__item-container'>
                  <div className='receipt-info-list__item'>
                    <div>
                      <div><h3 className='truncate'>{`${item.description} ${item.category || ''}`}</h3></div>
                      <div><h3>{Number(item.amountWithVat).toFixed(2)}</h3></div>
                    </div>
                    <div>
                      <div>{item.project || ''}</div>
                      <div>{Number(item.vatAmount).toFixed(2)}</div>
                    </div>
                  </div>
                  <h4 className='icon-edit'><span className='icon-pen' /></h4>
                </Link>
              )
            })}
          </div>
        </div>
      )
    } else {
      return null
    }
  }

  totalCalculation (eventRows) {
    const totalIncludingVat = eventRows.reduce((sum, row) => {
      return sum + Number(row.amountWithVat)
    }, 0)
    const totalVatAmount = eventRows.reduce((sum, row) => {
      const vatAmount = Number(row.amountWithVat) * (Number(row.vat || 0)) / (100 + Number(row.vat || 0))
      return sum + vatAmount
    }, 0)
    const totalExcludingVat = (totalIncludingVat - totalVatAmount)
    return {totalIncludingVat, totalVatAmount, totalExcludingVat}
  }

  renderTotal (eventRows) {
    if (eventRows.length) {
      const {t} = this.props
      const total = this.totalCalculation(eventRows)
      return (
        <div>
          <div className='hr'><span>{t('info', 'total').s}</span></div>
          <div className='receipt__total-tab'>
            <div className='receipt__total-tab__wrap'>
              <div>
                <div>{t('totals', 'totalExcludeVat').s}</div>
                <div><FormatNumber value={total.totalExcludingVat} /></div>
              </div>
              <div>
                <div>{t('totals', 'totalIncludingVat').s}</div>
                <div><FormatNumber value={total.totalIncludingVat} /></div>
              </div>
              <div>
                <div>{t('totals', 'vatAmount').s}</div>
                <div><FormatNumber value={total.totalVatAmount} /></div>
              </div>
            </div>
          </div>
        </div>
      )
    } else {
      return null
    }
  }

  onSplitAmountCategoryChange = (categoryId, oldCategoryId) => {
    const {
      location: { pathname },
      redirectToRepresentation,
      event: { representationGuests },
      settings: { representationCategoryIds },
      formValues: { eventRows },
      changeFormValue
    } = this.props
    if (!eventRows.length) return

    const representationRows = eventRows.filter((r) => includes(representationCategoryIds, Number(r.categoryId)))
    const changedToRepresentation = includes(representationCategoryIds, categoryId)
    const changedFromRepresentation = !changedToRepresentation &&
          includes(representationCategoryIds, oldCategoryId)

    if (changedToRepresentation) {
      const representationNotRenderedYet = representationRows.length === 0
      if (representationNotRenderedYet) {
        redirectToRepresentation(pathname)
      }
    } else {
      const lastReprCategoryRemoved = representationRows.length === 1 && changedFromRepresentation
      if (lastReprCategoryRemoved && representationGuests.length > 0) {
        changeFormValue('representationCause', '')
        changeFormValue('representationGuests', [])
      }
    }
  }

  categoryChangeHandler (event) {
    this.props.changeFormValue('categoryId', event)
    const categoryId = Number(event.target.value)
    const {
      location: { pathname },
      redirectToRepresentation,
      calculateAndSetVatAmount,
      settings: { representationCategoryIds },
      formValues: { amountWithVat },
      categories,
      type
    } = this.props
    const vat = result(find(categories, { value: categoryId }), 'vat')
    if (type === 'revenue') {
      calculateAndSetVatAmount(amountWithVat, vat)
    }
    if (includes(representationCategoryIds, categoryId)) {
      redirectToRepresentation(pathname)
    }
  }

  get eventRowsTotals () {
    const { event: { eventRows } } = this.props

    const totalIncludingVat = eventRows.reduce((sum, row) => {
      return sum + Number(row.amountWithVat)
    }, 0).toFixed(2)

    const totalVatAmount = eventRows.reduce((sum, row) => {
      const vatAmount = Number(row.amountWithVat) * (Number(row.vat || 0)) / (100 + Number(row.vat || 0))
      return sum + vatAmount
    }, 0).toFixed(2)

    const totalExcludingVat = (totalIncludingVat - totalVatAmount).toFixed(2)

    return {totalIncludingVat, totalVatAmount, totalExcludingVat}
  }

  get humanizedAmount () {
    const {event: {amountWithVat, currencyId, vatAmount, eventRows}, settings} = this.props
    const currency = findValueInObjectByProp(settings.currencies || [], {value: Number(currencyId)}, 'label')

    if (eventRows.length) {
      const total = this.eventRowsTotals
      return `${currency} ${total.totalIncludingVat} (${total.totalExcludingVat})`
    } else if (amountWithVat && (vatAmount || vatAmount === 0)) {
      return `${currency} ${Number(amountWithVat).toFixed(2)} (${Number(amountWithVat - vatAmount).toFixed(2)})`
    } else {
      return ''
    }
  }

  checkDefaultCategory (props) {
    const { event: { eventRows }, formValues: { categoryId }, changeFormValue } = props
    if (eventRows.length) {
      this.categoryDisabled = true
      if (categoryId) changeFormValue('categoryId', '')
    }
  }

  componentWillUpdate (props) {
    this.checkDefaultCategory(props)
  }

  componentDidMount () {
    // if user selected representation, but clicked back instead of save
    const { event: { representationCause }, formValues: { categoryId }, settings: { representationCategoryIds }, changeFormValue } = this.props

    if (includes(representationCategoryIds, Number(categoryId)) && !representationCause) {
      changeFormValue('categoryId', undefined)
    }
  }

  saveReceipt (values) {
    const { formValues: { eventRows, amountWithVat }, pushReceipt, event, invoiceMode } = this.props

    if ((this.paymentStatusSection && !this.paymentStatusSection.isValid())) {
      return
    }

    let isValid = true
    let totalAmountFromRows = 0
    let errors = {}

    eventRows.forEach((row) => {
      totalAmountFromRows += Number(row.amountWithVat)
      if (Math.abs(Number(row.vatAmount)) > Math.abs(Number(row.amountWithVat))) {
        isValid = false
      }
    })

    if (eventRows.length && totalAmountFromRows.toFixed(2) !== Number(amountWithVat).toFixed(2)) {
      errors.amountWithVat = 'error'
      isValid = false
    }

    if (isValid) {
      // scrollTop needed only for expense, after pressing Repeat button in success message modal
      pushReceipt({...event, ...values, isInvoice: invoiceMode}, () => this.scrollTop())
    } else {
      setTimeout(() => {
        // perform after re-render
        let errorElement = document.querySelector('.input--error')
        if (!errorElement) {
          errorElement = document.querySelector('.dropdown--error')
        }
        if (errorElement) {
          scrollTo(errorElement.offsetTop)
        }
      }, 100)
      throw new SubmissionError(errors)
    }
  }

  render () {
    const {
      event, t, t_buttons, settings, clearEvent,
      formValues: { amountWithVat, eventRows, categoryId },
      location: { pathname, state }, categories, handleSubmit,
      paymentMethods,
      invoiceMode,
      type,
      paymentEditMode,
      params,
      removeSupplier
    } = this.props

    const showInvoiceFields = type === 'expense' && invoiceMode
    const backLink = state && state.from ? state.from : '/'

    if (paymentEditMode) {
      return (
        <PaymentAddForm
          eventId={params.id}
        />
      )
    }

    const invoiceTypes = createSelect([INVOICE_TYPE_INVOICE, INVOICE_TYPE_CREDIT_INVOICE], t('selects', 'invoiceType'))
    const projectsOptions = settings.projects.filter(p => p.active || p.value === event.projectId)
    const dateInputPlaceholder = showInvoiceFields ? t('props', 'invoiceDate').s : t('props', 'date').s

    return (
      <div className='receipt f-column'>
        <FileListUpload files={event.files} />
        <div className='form'>
          {
            invoiceMode && (
              <div className='form__item'>
                <Field name='invoiceType' component={DropDown} big options={invoiceTypes} hint={t('props', 'invoiceType').s} />
              </div>
            )
          }
          {
            !event.id && !invoiceMode && settings.withOCR && (
              <div className='form__item form__item-small-padding-bottom'>
                <AutocompleteButton eventType={type} saveFunction={this.saveReceipt} />
              </div>
            )
          }
          <div className='form__item'>
            <Field
              name='title'
              component={EventTitleInput}
              placeholder={t('props', 'title').s}
            />
          </div>
          {
            showInvoiceFields && (
              <EventSupplier
                supplierName={event.supplier.name}
                pathname={pathname}
                t={t}
                removeSupplier={removeSupplier}
              />
            )
          }
          {
            showInvoiceFields && (
              <div className='form__item'>
                <Field name='invoiceNumber' component={Input} placeholder={t('props', 'invoiceNumber').s} />
              </div>
            )
          }
          <div className='form__item'>
            <div className='receipt__dates'>
              <Field name='date' component={Input} placeholder={dateInputPlaceholder} type='date' maxDate={moment()} />
              {showInvoiceFields && <Field name='invoiceDueDate' component={Input} placeholder={t('props', 'invoiceDueDate').s} type='date' />}
            </div>
          </div>
          {
            showInvoiceFields && (
              <React.Fragment>
                <div className='form__item'>
                  <Field name='OCRnumber' component={Input} placeholder={t('props', 'OCRnumber').s} inputMode='numeric' />
                </div>
                <div className='form__item'>
                  <Field name='bgNumber' component={Input} placeholder={t('props', 'bgNumber').s} inputMode='numeric' />
                </div>
                <div className='form__item'>
                  <Field name='pgNumber' component={Input} placeholder={t('props', 'pgNumber').s} inputMode='numeric' />
                </div>
              </React.Fragment>
            )
          }
        </div>
        <AmountBlock eventType={type} formValues={this.props.formValues} changeFormValue={this.props.changeFormValue} />
        <SplitAmountBlock
          total={amountWithVat}
          categories={categories}
          readOnlyVatDropDown={type === 'revenue'}
          onCategoryChange={this.onSplitAmountCategoryChange}
          changeFormValue={this.props.changeFormValue}
        />
        <div className='form'>
          { !invoiceMode && (
            <div className='form__item'>
              <Field
                name='paymentMethodId'
                component={DropDown}
                emptyOption={t('props', 'paymentMethod').s}
                big
                options={paymentMethods}
                hint={t('props', 'paymentMethod').s}
              />
            </div>
          ) }
          {
            !eventRows.length && (
              <div className='form__item'>
                <Field
                  name='categoryId'
                  component={DropDown}
                  emptyOption={t('props', 'category').s}
                  big
                  options={categories}
                  onChange={this.categoryChangeHandler}
                  disabled={this.categoryDisabled}
                  hint={t('props', 'category').s}
                />
              </div>
            )
          }
          {
            !eventRows.length && (
              <div className='form__item'>
                <Field
                  name='projectId'
                  component={DropDown}
                  emptyOption={t('props', 'project').s}
                  big
                  options={projectsOptions}
                  disabled={this.categoryDisabled}
                  hint={t('props', 'project').s}
                />
              </div>
            )
          }
        </div>
        <div className='form f-column-top'>
          { invoiceMode && (
            <PaymentStatusField
              payments={event.payments}
              paymentStatus={event.paymentStatus}
              provideController={(c) => { this.paymentStatusSection = c }}
              eventAmount={amountWithVat}
            />
          )}
        </div>

        {this.renderRepresentationList(pathname, event.representationGuests, categoryId)}

        <div className='f-column-bottom form-buttons'>
          <Button saveButton className='receipt__save' onClick={handleSubmit(this.saveReceipt)} view='transparent-black'>{event.id ? t_buttons('update').s : t_buttons('save').s}</Button>
          <Button view='transparent-red' onClick={() => { clearEvent(backLink) }}>{t_buttons('cancel').s}</Button>
        </div>
      </div>
    )
  }
}

class ReceiptBaseWithNavigation extends Component {
  static propTypes = {
    event: PropTypes.object,
    clearEvent: PropTypes.func,
    defaultNavbarTitle: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
    location: PropTypes.object
  }
  render () {
    const { event, clearEvent, location: { state } } = this.props
    const backLink = state && state.from ? state.from : '/'
    const navBarTitle = event.id ? event.title : this.props.defaultNavbarTitle

    return (
      <Navigation hideFooter backLink={backLink} onClickBack={clearEvent} title={navBarTitle} className='f-column'>
        <ReceiptBaseView {...this.props} />
      </Navigation>
    )
  }
}

const fields = [
  'id',
  'isInvoice',
  'invoiceType',
  'invoiceNumber',
  'title',
  'date',
  'invoiceDueDate',
  'categoryId',
  'projectId',
  'paymentMethodId',
  'isPaid',
  'paymentDate',
  'amountWithVat',
  'vatAmount',
  'currencyId',
  'vat',
  'withMinusSign',
  'OCRnumber',
  'bgNumber',
  'pgNumber',

  'eventRows[].id',
  'eventRows[].uuid',
  'eventRows[].category',
  'eventRows[].vatAmount',
  'eventRows[].amountWithVat',
  'eventRows[].categoryId',
  'eventRows[].vat',
  'eventRows[].projectId'
]

function getInitialValues (state, ownProps) {
  let values = Object.assign({}, pick(state.events.newEvent, fields))
  if (ownProps.invoiceMode) {
    values.isInvoice = true
  }
  if (ownProps.invoiceMode && !values.invoiceType) {
    values.invoiceType = INVOICE_TYPE_INVOICE
  }
  values.eventRows = state.events.newEvent.eventRows

  return values
}

const formName = 'receipt-form'

function mapStateToProps (state, ownProps) {
  const { categories, paymentMethods, defaultNavbarTitle } = ownProps
  return {
    initialValues: getInitialValues(state, ownProps),
    event: state.events.newEvent,
    settings: state.userSettings,
    filesToUpload: state.filesToUpload,
    t: state.i18n.get('app', 'views', 'ExpenseView'),
    t_buttons: state.i18n.get('app', 'shared', 'buttons'),
    categories,
    paymentMethods,
    defaultNavbarTitle,
    formValues: getFormValues(formName)(state)
  }
}

function mapDispatchToProps (dispatch, ownProps) {
  const { pushAction, location: { query: { cameFrom } } } = ownProps
  const isNewEvent = !ownProps.params.id
  return {
    pushReceipt: (event, scrollTopHandler) => { dispatch(pushAction(isNewEvent, event, cameFrom, scrollTopHandler)) },
    clearEvent: (backLink) => { dispatch([clearNewEventAction(), backLink ? routerActions.push(backLink) : null]) },
    redirectToRepresentation: (path) => dispatch(routerActions.push(`${path}/representation`)),
    calculateAndSetVatAmount: (...props) => dispatch(calculateAndSetVatAmountAction(...props)),
    updateNewExpenseProps: (...props) => dispatch(updateNewExpensePropsAction(...props)),
    editEventRowsFields: (params) => dispatch(editEventRowsFieldsAction(params)),
    removeSupplier: () => { dispatch(loadDataToExpenseViewAction({supplier: {}})) },
    changeFormValue: (fieldName, value) => dispatch(change(formName, fieldName, value))
  }
}

function validate (values) {
  let errors = {}
  const invoiceDueDate = moment(values.invoiceDueDate, 'YYYY-MM-DD')
  const invoiceDate = moment(values.date, 'YYYY-MM-DD')

  if (Math.abs(Number(values.vatAmount)) > Math.abs(Number(values.amountWithVat))) {
    errors.vatAmount = true
  }
  if (!values.date) {
    errors.date = true
  }

  if (invoiceDueDate.isBefore(invoiceDate)) {
    errors.invoiceDueDate = true
  }

  return errors
}

export const ReceiptBaseView = connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: formName,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  destroyOnUnmount: false,
  validate
})(ReceiptBase))

export default connect(mapStateToProps, mapDispatchToProps)(ReceiptBaseWithNavigation)
