import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'

import { checkAuth } from 'User/Auth/actions/auth'
import { checkPermissionWithRedirect } from 'Policy/actions'

export function requireAuthentication (Component) {
  class AuthComponent extends React.Component {
    static propTypes = {
      location: PropTypes.object.isRequired,
      dispatch: PropTypes.func.isRequired,
      isAuthenticated: PropTypes.bool
    };

    componentWillMount () {
      const { dispatch, isAuthenticated, location: { pathname } } = this.props

      if (!isAuthenticated) {
        dispatch(checkAuth()) // TODO: rename, this function perform redirect
      } else {
        dispatch(checkPermissionWithRedirect(pathname))
      }
    }

    render () {
      // render the component that requires auth (passed to this wrapper)
      return (
        <Component {...this.props} />
      )
    }
  }

  const mapStateToProps = (state) => ({
    settings: state.userSettings,
    invoiceSettings: state.invoiceSettings,
    isAuthenticated: state.auth.isLoggedIn
  })

  return connect(mapStateToProps)(AuthComponent)
}
