import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { reduxForm, Field } from 'redux-form'
import moment from 'moment'

// Actions
import { pushEventAction } from 'Events/Withdrawal/actions/withdrawal'

// Components
import Navigation from 'Navigation/Navigation'
import Input from 'shared/components/FormInput'
import Button from 'shared/components/Button/Button'
import DropDown from 'shared/components/FormDropdown'
import FileListUpload from 'FileUpload/containers/FileListUpload'
import EventTitleInput from 'Events/shared/components/EventTitleInput'

class Expense extends Component {
  static propTypes = {
    expense: PropTypes.object,
    pushExpense: PropTypes.func,
    t: PropTypes.func,
    settings: PropTypes.object,
    filesToUpload: PropTypes.object,
    handleSubmit: PropTypes.func,
    params: PropTypes.object
  };

  render () {
    const {
      t, expense,
      settings: { projects, expensePaymentMethods },
      pushExpense, filesToUpload, handleSubmit,
      params
    } = this.props

    return (
      <div className='f-column'>
        <FileListUpload files={expense.files || []} />
        <div className='form f-column-top'>
          <div className='form__item'>
            <Field name='title' component={EventTitleInput} placeholder={t('props', 'title').s} />
          </div>
          <div className='form__item'>
            <Field name='date' component={Input} placeholder={t('props', 'date').s} type='date' maxDate={moment()} />
          </div>
          <div className='form__item'>
            <Field name='amountWithVat' component={Input} placeholder={t('props', 'amount').s} type='float' />
          </div>
          <div className='form__item'>
            <Field
              name='projectId'
              component={DropDown}
              big
              emptyOption={t('emptyOptions', 'project').s}
              options={projects}
              hint={t('props', 'project').s}
            />
          </div>
          <div className='form__item'>
            <Field
              name='paymentMethodId'
              component={DropDown}
              big
              emptyOption={t('emptyOptions', 'paymentMethod').s}
              options={expensePaymentMethods}
              hint={t('props', 'paymentMethod').s}
            />
          </div>
        </div>
        <div className='f-column-bottom form-buttons'>
          <Button saveButton onClick={handleSubmit((values) => { pushExpense(values, filesToUpload) })} view='transparent-black'>
            {params.id ? t('buttons', 'update').s : t('buttons', 'save').s}
          </Button>
          <Button goBackButton view='transparent-red'>{t('buttons', 'cancel').s}</Button>
        </div>
      </div>
    )
  }
}

class ExpenseWithNavigation extends Component {
  static propTypes = {
    expense: PropTypes.object,
    t: PropTypes.func
  }
  render () {
    const {t, expense} = this.props
    const backLink = expense.id ? `/events/${expense.id}` : '/events'
    const navBarTitle = expense.id ? expense.title : t('navbar', 'title').s
    return (
      <Navigation hideFooter backLink={backLink} title={navBarTitle} className='f-column'>
        <Expense {...this.props} />
      </Navigation>
    )
  }
}

function mapStateToProps (state) {
  return {
    settings: state.userSettings,
    filesToUpload: state.filesToUpload,
    expense: state.events.newEvent,
    initialValues: state.events.newEvent,
    t: state.i18n.get('app', 'views', 'EventFormView', 'Withdrawal', 'Create')
  }
}

function mapDispatchToProps (dispatch, ownProps) {
  const isNewEvent = !ownProps.params.id
  return {
    pushExpense: (expense) => { dispatch(pushEventAction(isNewEvent, expense)) }
  }
}

function validate (values) {
  let errors = {}
  if (!values.date) {
    errors.date = true
  }

  return errors
}

export const ExpenseView = connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: 'withdrawal',
  validate,
  enableReinitialize: true
})(Expense))

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: 'withdrawal',
  validate,
  enableReinitialize: true
}, mapStateToProps, mapDispatchToProps)(ExpenseWithNavigation))
