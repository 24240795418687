import { bind } from 'redux-effects'
import { fetch2 as fetch } from 'shared/helpers/fetch'
import { camelizeKeys } from 'humps'
import { getCurrentClientCompany } from 'api-client-connector/utils'

// TODO: temporary fix. temporary because it's not good to load info about user twice (after sign in via form, then now)
import { getAccountSettingsAction } from './accountSettings'

import getUserSettingsAction, { addToUserSettings } from 'User/Settings/actions/userSettings'
import { setDefaultSettingsDataAction as getInvoiceSettingsAction } from 'User/Settings/actions/invoiceSettings'

import { fetchProjectsList } from 'Projects/actions/projectsList'

import { EMPLOYEES_LIST } from 'Employees/constants/api'

export default function loadAllSettings () {
  return (dispatch) => {
    let actionsToPerform = [
      getAccountSettingsAction(),
      getUserSettingsAction(),
      updateProjectsInSettingsAction(),
      getInvoiceSettingsAction(),
      updateEmployeesInSettingsAction()
    ]

    return dispatch(actionsToPerform)
  }
}

export function updateProjectsInSettingsAction () {
  return bind(fetchProjectsList({ limit: 0 }), addProjectsToSettings)
}

function addProjectsToSettings (response) {
  return addToUserSettings({
    // TODO: maybe rename to id, name, active (here and in places which using this)
    projects: response.value.projects.map((p) => ({
      value: p.id,
      label: p.name,
      active: p.active
    }))
  })
}

export function updateEmployeesInSettingsAction () {
  return bind(fetch(EMPLOYEES_LIST({ company_id: Number(getCurrentClientCompany()), filled_objects: 'benefits' })), addEmployeesToSettings)
}

function addEmployeesToSettings (response) {
  const employees = camelizeKeys(response.value.employees)
  return addToUserSettings({
    employees: employees.map((e) => ({
      firstName: e.firstName,
      lastName: e.lastName,
      id: e.id,
      benefits: e.benefits
    }))
  })
}
