import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import './ContinueMessageBox.scss?global'

// Components
import Button from 'shared/components/Button/Button'

class ContinueMessageBox extends Component {
  static propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    buttonT: PropTypes.func,
    eventId: PropTypes.string,
    onClose: PropTypes.func,
    onRepeat: PropTypes.func,
    repeatButtonText: PropTypes.string
  };

  render () {
    const { title, description, eventId, buttonT, onClose, onRepeat, repeatButtonText } = this.props
    return (
      <div className='continue-message-box'>
        <div className='continue-message-box__invisible' />
        <h1><b>{ title }</b></h1>
        <h2>{ description }</h2>
        <br />
        <h1><b>{ eventId }</b></h1>
        <div className='continue-message-box__buttons'>
          { onRepeat && <Button saveButton view='transparent-black' onClick={onRepeat}>{ repeatButtonText }</Button> }
          <Button view='transparent-black' onClick={onClose}>{ onRepeat ? buttonT('close').s : buttonT('continue').s }</Button>
        </div>
      </div>
    )
  }
}

function mapStateToProps (state) {
  return {
    buttonT: state.i18n.get('app', 'shared', 'buttons')
  }
}

export default connect(mapStateToProps)(ContinueMessageBox)
