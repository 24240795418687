exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._3vdO9-KG81QcTeaABdmIIf {\n  cursor: pointer;\n  text-align: center;\n  font-weight: 600;\n  margin-bottom: 4rem;\n}\n\n._3vdO9-KG81QcTeaABdmIIf>span {\n  margin-left: 5px;\n  font-size: 2.3rem;\n  display: inline-block;\n  position: relative;\n  top: 0.1rem;\n}\n\n._1dhpeJRarkDUgXU_WC11-F {\n  display: flex;\n  justify-content: space-between;\n}\n\n._1dhpeJRarkDUgXU_WC11-F>* {\n  width: 50%;\n}\n\n._1dhpeJRarkDUgXU_WC11-F>*:first-child {\n  margin-right: 4rem;\n}\n\n._1dhpeJRarkDUgXU_WC11-F .custom-checkbox .custom-checkbox-text,\n._1dhpeJRarkDUgXU_WC11-F button {\n  font-size: 2.7rem !important;\n}\n\n._2kEpc45ac4kGc6M70yiOva {\n  color: #FF0049;\n  text-align: center;\n  display: inline-block;\n  margin-bottom: 3rem;\n  width: 100%;\n}\n\n", "", {"version":3,"sources":["/home/circleci/mobile_ui/src/Invoices/SupplierInvoices/components/ApproveRules/ApproveRuleForm/styles.scss"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,iBAAiB;EACjB,oBAAoB;CACrB;;AAED;EACE,iBAAiB;EACjB,kBAAkB;EAClB,sBAAsB;EACtB,mBAAmB;EACnB,YAAY;CACb;;AAED;EACE,cAAc;EACd,+BAA+B;CAChC;;AAED;EACE,WAAW;CACZ;;AAED;EACE,mBAAmB;CACpB;;AAED;;EAEE,6BAA6B;CAC9B;;AAED;EACE,eAAe;EACf,mBAAmB;EACnB,sBAAsB;EACtB,oBAAoB;EACpB,YAAY;CACb","file":"styles.scss","sourcesContent":[".listLink {\n  cursor: pointer;\n  text-align: center;\n  font-weight: 600;\n  margin-bottom: 4rem;\n}\n\n.listLink>span {\n  margin-left: 5px;\n  font-size: 2.3rem;\n  display: inline-block;\n  position: relative;\n  top: 0.1rem;\n}\n\n.submitRow {\n  display: flex;\n  justify-content: space-between;\n}\n\n.submitRow>* {\n  width: 50%;\n}\n\n.submitRow>*:first-child {\n  margin-right: 4rem;\n}\n\n.submitRow :global(.custom-checkbox .custom-checkbox-text),\n.submitRow button {\n  font-size: 2.7rem !important;\n}\n\n.error {\n  color: #FF0049;\n  text-align: center;\n  display: inline-block;\n  margin-bottom: 3rem;\n  width: 100%;\n}\n\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"listLink": "_3vdO9-KG81QcTeaABdmIIf",
	"submitRow": "_1dhpeJRarkDUgXU_WC11-F",
	"error": "_2kEpc45ac4kGc6M70yiOva"
};