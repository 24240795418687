import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { routerActions } from 'react-router-redux'

import PhoneBookView from 'appBridge/PhoneBook/views/PhoneBookView/PhoneBookView'

import { addRepresentationContactsAction } from 'Events/Expense/actions/representation'

function ContactsImportForRepresentation (props) {
  return <PhoneBookView {...props} onSubmit={props.saveContacts} />
}

ContactsImportForRepresentation.propTypes = {
  saveContacts: PropTypes.func
}

function mapDispatchToProps (dispatch) {
  return {
    saveContacts: (contacts) => { dispatch([addRepresentationContactsAction(contacts), routerActions.goBack()]) }
  }
}

export default connect(null, mapDispatchToProps)(ContactsImportForRepresentation)
