import './styles/index.scss?global'

import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { reduxForm, Field, reset } from 'redux-form'

import Button from 'shared/components/Button/Button'
import DropDown from 'shared/components/FormDropdown'
import Spinner from 'shared/components/Spinner/Spinner'
import Modal from 'shared/components/NewModal/NewModal'

import { setLanguage, saveLanguage, whitelabelLanguage } from 'I18N/actions/i18n'

export class LangSwitchModal extends Component {
  static propTypes = {
    t: PropTypes.func,
    setLanguage: PropTypes.func,
    saveLanguage: PropTypes.func,
    resetForm: PropTypes.func,
    provideController: PropTypes.func,
    showSpinner: PropTypes.bool,
    leftMenu: PropTypes.bool,
    handleSubmit: PropTypes.func,
    initialValues: PropTypes.object
  }

  static defaultProps = {
    t: () => () => {}
  }

  constructor (props) {
    super(props)
    this.state = {
      shouldSetOldLang: true
    }
    props.provideController && props.provideController({
      open: () => { this.modal.open() },
      close: () => { this.modal.close() }
    })
    this.onClose = this.onClose.bind(this)
    this.onDropdownChange = this.onDropdownChange.bind(this)
    this.saveLanguage = this.saveLanguage.bind(this)
  }

  onClose () {
    this.state.shouldSetOldLang && this.props.setLanguage(this.props.initialValues.lang)
    this.setState({ shouldSetOldLang: true })

    const { resetForm } = this.props
    resetForm()

    this.modal.close()
  }

  onDropdownChange (e) {
    this.props.setLanguage(e.target.value)
  }

  saveLanguage (values) {
    this.setState({ shouldSetOldLang: false }, () => {
      this.props.saveLanguage(values.lang)
      this.onClose()
    })
  }

  selectValues () {
    const langs = {
      en: 'English',
      sv: 'Svenska',
      sk: 'Slovenská'
    }
    return ['en', whitelabelLanguage()].map(value => ({ value, label: langs[value] }))
  }

  render () {
    const { t, showSpinner, leftMenu, handleSubmit } = this.props
    const languages = this.selectValues()
    return (
      <Modal ref={(c) => { this.modal = c }} onClose={this.onClose} hideCloseButton={showSpinner} leftMenu={leftMenu}>
        <div className='lang-switch-modal__content'>
          <div className='lang-switch-modal__content__top'>
            <div>
              <h1><b>{t('title').s}</b></h1>
              { showSpinner ? <Spinner dark /> : null }
              <Field
                name='lang'
                component={DropDown}
                disabled={showSpinner}
                onChange={this.onDropdownChange}
                options={languages}
              />
            </div>
          </div>
          <div className='lang-switch-modal__content__bottom'>
            {
              showSpinner
                ? null
                : <Button view='transparent-black' onClick={handleSubmit(this.saveLanguage)}>{t('button').s}</Button>
            }
          </div>
        </div>
      </Modal>
    )
  }
}

const formName = 'lang-switch'

function mapStateToProps (state) {
  return {
    initialValues: {
      lang: state.userSettings.lang || state.i18n.locale
    },
    showSpinner: state.navbarSpinner.value,
    t: state.i18n.get('app', 'views', 'Entry', 'components', 'LangSwitchModal')
  }
}

function mapDispatchToProps (dispatch) {
  return {
    setLanguage: (lang) => dispatch(setLanguage(lang)),
    saveLanguage: (lang) => dispatch(saveLanguage(lang)),
    resetForm: () => dispatch(reset(formName))
  }
}

const wrappedLangSwitch = reduxForm({
  form: formName,
  enableReinitialize: true
})(LangSwitchModal)

export default connect(mapStateToProps, mapDispatchToProps)(wrappedLangSwitch)
