import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { routerActions } from 'react-router-redux'

import SalariesList from 'Salaries/views/SalariesListView/containers/Smartphones'
import { SalaryPreviewView } from './Smartphones'
import { markAsPaid } from 'Salaries/actions'

import IpadNavigation from 'Navigation/IpadNavigation'

class SalaryPreview extends Component {
  static propTypes = {
    isPaid: PropTypes.bool,
    t: PropTypes.func,
    title: PropTypes.string,
    markAsPaid: PropTypes.func,
    params: PropTypes.object,
    redirectToEditView: PropTypes.func
  }
  render () {
    const { t, redirectToEditView, markAsPaid, params: { salaryId }, isPaid } = this.props
    const multipleActions = [
      { title: t('buttons', 'edit').s, onClick: () => redirectToEditView(salaryId) },
      { title: t('buttons', 'markAsPaid').s, onClick: () => markAsPaid(salaryId) }
    ]
    return (
      <IpadNavigation title={this.props.title} multipleActions={!isPaid && multipleActions} {...this.props}>
        <SalariesList {...this.props} />
        <SalaryPreviewView {...this.props} />
      </IpadNavigation>
    )
  }
}

const mapStateToProps = (state) => ({
  title: state.salaries.salary.title,
  t: state.i18n.get('app', 'views', 'Salaries', 'SalaryPreviewView'),
  isPaid: state.salaries.salary.paid
})

const mapDispatchToProps = (dispatch) => ({
  redirectToEditView: (salaryId) => dispatch(routerActions.push(`/salaries/${salaryId}/edit`)),
  markAsPaid: (salaryId) => dispatch(markAsPaid(salaryId))
})

export default connect(mapStateToProps, mapDispatchToProps)(SalaryPreview)
