import PropTypes from 'prop-types'
import React, { Component } from 'react'

// Components
import Navigation from 'Navigation/Navigation'
import InvoiceRotRut from '../components/InvoiceRotRut'

// Containers
import InvoiceRotRutContainer from './Common'

class InvoiceRotRutView extends Component {
  static propTypes = {
    t: PropTypes.func
  }
  render () {
    const {t} = this.props
    return (
      <Navigation title={t('title').s} hideFooter className='f-column'>
        <InvoiceRotRut {...this.props} />
      </Navigation>
    )
  }
}

export default InvoiceRotRutContainer(InvoiceRotRutView)
