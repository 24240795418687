import './KeyFigureReportBox.scss?global'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { isEmpty } from 'lodash'
import cs from 'classnames'

// Components
import FormatNumber from 'shared/components/FormatNumber/FormatNumber'
import Dates from 'Overview/Overview/components/Dates'

// Helpers
import getGreyLabelOptions from 'GreyLabel/helpers/greyLabel'

export default class KeyFigureReportBox extends Component {
  static propTypes = {
    report: PropTypes.object,
    t: PropTypes.func,
    settings: PropTypes.object,
    getKeyFigures: PropTypes.func
  }
  render () {
    const { report, t, settings, getKeyFigures } = this.props
    const { whiteLabel } = getGreyLabelOptions()
    const keyFigureItemClass = cs('key-figure-report__item', whiteLabel)
    if (!isEmpty(report)) {
      return (
        <div className='key-figure-report'>
          <Dates settings={settings} onSaveClick={getKeyFigures} />
          <h3 className='key-figure-report__title'>{t('title').s}</h3>

          <div className={keyFigureItemClass}>
            <div className='key-figure-report__header'>
              <span className='icon-profit' />
            </div>
            <div className='key-figure-report__row'>
              <div>{t('fields', 'result').s}</div>
              <div><FormatNumber value={report.profit} /></div>
            </div>
          </div>

          <div className={keyFigureItemClass}>
            <div className='key-figure-report__header'>
              <span className='icon-solvency' />
            </div>
            <div className='key-figure-report__row'>
              <div>{t('fields', 'solvency').s}</div>
              <div><FormatNumber value={report.solvency} />%</div>
            </div>
            <div className='key-figure-report__row'>
              <div>{t('fields', 'profitMargin').s}</div>
              <div><FormatNumber value={report.profitMargin} />%</div>
            </div>
            <div className='key-figure-report__row'>
              <div>{t('fields', 'cashLiquidity').s}</div>
              <div><FormatNumber value={report.cashLiquidity} />%</div>
            </div>
          </div>
        </div>
      )
    } else {
      return null
    }
  }
}
