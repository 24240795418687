import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import IpadNavigation from 'Navigation/IpadNavigation'
import DocumentsView from 'Documents/views/DocumentsView/containers/Smartphones'
import {DocumentView} from 'Documents/views/DocumentView/containers/Smartphones'

export class Document extends Component {
  static propTypes = {
    document: PropTypes.object,
    t: PropTypes.func,
    location: PropTypes.object
  }

  render () {
    const {t, document} = this.props
    const isStatement = this.props.location.pathname.split('/')[3] === 'statement'
    const navBarTitle = document.id ? t('titles', 'existing').s : isStatement ? t('titles', 'statement').s : t('titles', 'new').s

    return (
      <IpadNavigation notBack {...this.props} title={navBarTitle}>
        <DocumentsView {...this.props} />
        <DocumentView {...this.props} />
      </IpadNavigation>
    )
  }
}

function mapStateToProps (state) {
  return {
    document: state.documents.document,
    t: state.i18n.get('app', 'views', 'CreateDocumentView')
  }
}

export default connect(mapStateToProps)(Document)
