import './RevenueAndCosts.scss?global'

import Smartphones from 'Overview/RevenueAndCosts/containers/Smartphones'
import Tablets from 'Overview/RevenueAndCosts/containers/Tablets'

import {getCurrentDeviceView} from 'shared/helpers/utils'

const RevenueAndCostsView = getCurrentDeviceView(Smartphones, Tablets)

export default RevenueAndCostsView
