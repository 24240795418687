import React, {Component} from 'react'

import Invoices from './Smartphones'
import IpadNavigation from 'Navigation/IpadNavigation'

export default class EventsView extends Component {
  render () {
    return (
      <IpadNavigation {...this.props} onlyLeftSide setCurrentLink>
        <Invoices {...this.props} />
      </IpadNavigation>
    )
  }
}
