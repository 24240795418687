import {connect} from 'react-redux'

// Views
import ArticleImportView from 'Articles/views/ArticlesImportView/ArticlesImportView'

// Actions
import { importArticleRowAction } from '../../../actions/imports'
import { routerActions } from 'react-router-redux'

function mapDispatchToProps (dispatch) {
  return {
    importItemsToForm: (article) => { dispatch([importArticleRowAction(article), routerActions.goBack()]) }
  }
}

export default connect(null, mapDispatchToProps)(ArticleImportView)
