import { browserHistory } from 'react-router'
import { ZIGNSEC_CABLE } from 'User/Auth/constants/Api'
import CableInteractor from 'shared/interactors/cableInteractor'
import { openBankIDApp } from 'User/shared/helpers'

// Actions
import { showMessageBoxWithParamsAction } from 'MessageBox/actions/messageBox'
import { hideNavbarSpinnerAction } from 'shared/actions/navbarSpinner'

import { store } from 'main'
import { saveQrCode } from 'User/Auth/actions/zignsecAuth'

class ZignsecInteractor {
  initialize (options) {
    this.onSuccess = options.onSuccess
    // TODO: maybe redirectToBankIDWaitView and remoteMobileAuth is for similar purposes
    this.redirectToBankIDWaitView = options.redirectToBankIDWaitView || false
    this.remoteMobileAuth = options.remoteMobileAuth || false

    this.cable = new CableInteractor()
    this.cable.initialize({
      url: ZIGNSEC_CABLE,
      connectionParams: options.connectionParams,
      received: this.processReceivedMessage.bind(this),
      connected: this.afterConnect.bind(this)
    })

    // internal variables
    this.checkOnly = options.connectionParams.check_only
    this.orderRef = undefined
    this.timerId = undefined
    this.autostartUrlRequested = false
  }

  processReceivedMessage (data) {
    this.maybeOpenBankIDApp(data)
    this.maybeProcessSuccess(data)
    this.maybeHandleError(data)
  }

  afterConnect () {
    if (!this.autostartUrlRequested) {
      this.cable.subscription.perform('autostart_url')
      this.autostartUrlRequested = true
    }
  }

  maybeOpenBankIDApp (data) {
    if (!data.autostart_url) {
      return
    }

    this.orderRef = data.order_ref
    this.startBankIDStatusCheck()

    if (this.redirectToBankIDWaitView) {
      browserHistory.push(`/bankid_wait?startURL=${encodeURIComponent(data.autostart_url)}`)
      return
    }
    if (this.remoteMobileAuth) {
      saveQrCodeLink(store.dispatch, data)
    } else openBankIDApp(data.autostart_url, store.getState().appInfo)
  }

  maybeProcessSuccess (data) {
    if (data.status !== 'SUCCESS') {
      return
    }

    this.onSuccess(data)
    this.disconnect()
  }

  maybeHandleError (data) {
    if (data.status === 'ERROR') {
      this.cable.disconnect()
      this.cable = undefined
      clearInterval(this.timerId)
    }
    maybeHandleError(store.getState().i18n.get('app'), store.dispatch, data)
  }

  startBankIDStatusCheck () {
    this.timerId = setInterval(this.getBankIDStatusRequest.bind(this), 2000)
  }

  getBankIDStatusRequest () {
    this.cable.subscription.perform('check', {
      order_ref: this.orderRef,
      check_only: this.checkOnly
    })
  }

  startBankIDAuth () {
    this.cable.connect()
  }

  disconnect () {
    this.cable.disconnect()
    this.cable = undefined
    this.orderRef = undefined
    this.checkOnly = undefined
    this.autostartUrlRequested = false

    clearInterval(this.timerId)
    this.timerId = undefined
  }
}

const zignsecInteractorInstance = new ZignsecInteractor()

function maybeHandleError (t, dispatch, data) {
  if (data && !data.reason) {
    return
  }
  if (data && data.success) {
    return
  }

  let translatedError
  if (data.reason === 'user is not registered') {
    translatedError = t('views', 'LoginView', 'errors', 'personalNumberIsNotRegistered').s
  } else {
    translatedError = t('views', 'LoginView', 'errors', 'unexpectedError').s
  }
  dispatch(hideNavbarSpinnerAction())
  dispatch(showMessageBoxWithParamsAction('', translatedError, () => {
    browserHistory.replace('/login')
  }))
  if (ZignsecInteractor) {
    ZignsecInteractor.disconnect()
  }
}

function saveQrCodeLink (dispatch, data) {
  if (data.qr_link) {
    dispatch(saveQrCode(data.qr_link))
  }
}

export default zignsecInteractorInstance
