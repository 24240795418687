import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import SuggestInput from 'shared/components/SuggestionInput'

const renderSuggestion = (suggestion) => {
  return `${suggestion.company}`
}

const getInputValue = (suggestion) => suggestion.company

class CompanyInput extends Component {
  static propTypes = {
    guests: PropTypes.arrayOf(PropTypes.object),
    input: PropTypes.object
  }

  getSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase()
    const inputLength = inputValue.length

    return this.props.guests.filter(g =>
      g.company.toLowerCase().slice(0, inputLength) === inputValue
    )
  }

  render () {
    return (
      <SuggestInput
        getSuggestions={this.getSuggestions}
        getInputValue={getInputValue}
        renderSuggestion={renderSuggestion}
        inputProps={this.props.input}
      />
    )
  }
}

const mapStateToProps = (state) => ({
  guests: state.representationGuests
})

export default connect(mapStateToProps)(CompanyInput)
