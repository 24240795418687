import { bind } from 'redux-effects'
import { routerActions } from 'react-router-redux'
import axios from 'api-client-connector'
import { decamelizeKeys } from 'humps'

import { showNavbarSpinnerAction, hideNavbarSpinnerAction, showSlowNavbarSpinnerAction } from 'shared/actions/navbarSpinner'
import { showMessageBoxWithParamsAction } from 'MessageBox/actions/messageBox'
import { reloadListAction } from './invoices'

import { INVOICE_SEND_REMINDER, INVOICE_SEND_MESSAGE } from '../../shared/constants/Api'
import { KIVRA_VALIDATE_INVOICE_SENDING, KIVRA_SEND_INVOICE } from 'Integrations/Kivra/constants/api'

import { isIpadResolution } from 'shared/helpers/utils'
import { fetch2 as fetch } from 'shared/helpers/fetch'

export function sendMessageAction ({ email, message }) {
  return (dispatch, getState) => {
    const { invoices: { customerInvoices: { invoice: { id } } }, i18n: { locale } } = getState()

    return dispatch([
      showNavbarSpinnerAction(),
      bind(sendInvoice(id, { email, message, locale }), afterMessageSend.bind(this, 'emailSuccess'))
    ])
  }
}

export function sendReminderAction (data) {
  return (dispatch, getState) => {
    const { invoices: { customerInvoices: { invoice: { id } } }, i18n: { locale } } = getState()

    return dispatch([
      showNavbarSpinnerAction(),
      bind(sendReminderRequest(id, locale, data), afterMessageSend.bind(this, 'reminderSuccess'))
    ])
  }
}

function sendReminderRequest (id, locale, data) {
  return fetch(INVOICE_SEND_REMINDER(id), {}, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      locale,
      email: data.email,
      message: data.message,
      include_reminder_fee: data.reminderFee,
      include_late_fee: data.lateFee
    })
  })
}

function afterMessageSend (modalMessageType, response) {
  console.log(response)
  return (dispatch, getState) => {
    const t = getState().i18n.get('app', 'views', 'Invoices', 'InvoiceSendView', 'messages', modalMessageType)
    return dispatch([
      isIpadResolution() && reloadListAction(),
      hideNavbarSpinnerAction(),
      showMessageBoxWithParamsAction(t('title').s, t('description').s),
      routerActions.goBack()
    ])
  }
}

function sendInvoice (invoiceId, payload) {
  return fetch(INVOICE_SEND_MESSAGE(invoiceId), {}, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload)
  })
}

export const validateKivraInvoiceSending = (invoiceId) => axios.post(KIVRA_VALIDATE_INVOICE_SENDING, decamelizeKeys({ invoiceId }))

export function sendInvoiceWithKivra (data) {
  return async (dispatch, getState) => {
    try {
      const t = getState().i18n.get('app', 'views', 'Invoices', 'InvoiceSendView', 'messages', 'sendWithKivraSuccess')
      dispatch(showSlowNavbarSpinnerAction())
      await sendInvoiceWithKivraRequest(data)
      return dispatch([
        isIpadResolution() && reloadListAction(),
        hideNavbarSpinnerAction(),
        showMessageBoxWithParamsAction(t('title').s, t('description').s),
        routerActions.push(`/invoices/${data.invoiceId}`)
      ])
    } catch (e) {
      const tShared = getState().i18n.get('app', 'shared', 'messages')
      dispatch([
        showMessageBoxWithParamsAction(null, tShared('unexpectedError', 'description').s),
        hideNavbarSpinnerAction()
      ])
    }
  }
}

const sendInvoiceWithKivraRequest = (data) => axios.post(KIVRA_SEND_INVOICE, decamelizeKeys(data))
