import PropTypes from 'prop-types'
import React, { Component } from 'react'

import './Overlay.scss?global'

export default class Overlay extends Component {
  static propTypes = {
    children: PropTypes.node,
    center: PropTypes.bool
  }

  render () {
    return (
      <div className={'overlay ' + (this.props.center && 'center')}>
        <div className='overlay-content'>
          {this.props.children}
        </div>
      </div>
    )
  }
}
