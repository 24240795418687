import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'

// Components
import IpadNavigation from 'Navigation/IpadNavigation'
import Navigation from 'Navigation/Navigation'
import KeyFigureReport from 'Reports/components/KeyFigureReportBox/KeyFigureReportBox'
import OverviewList from 'Overview/Overview/components/List/List'

// Containers
import KeyFiguresReportContainer from 'Reports/views/KeyFigures/containers/Common'

class LeftSide extends Component {
  static propTypes = {
    t: PropTypes.func
  }
  render () {
    const {t} = this.props
    return (
      <Navigation title={t('navbar', 'title').s} className='overview-view'>
        <OverviewList {...this.props} />
      </Navigation>
    )
  }
}

function mapStateToProps (state) {
  return {
    t: state.i18n.get('app', 'views', 'OverviewView')
  }
}

const LeftSideView = connect(mapStateToProps)(LeftSide)

class KeyFigureReportView extends Component {
  static propTypes = {
    t: PropTypes.func
  }

  render () {
    const {t} = this.props
    return (
      <IpadNavigation notBack {...this.props} title={t('navbar', 'title').s}>
        <LeftSideView {...this.props} />
        <KeyFigureReport {...this.props} />
      </IpadNavigation>
    )
  }
}

export default KeyFiguresReportContainer(KeyFigureReportView)
