import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import IpadNavigation from 'Navigation/IpadNavigation'
import {CustomerView} from './Smartphones'

import getLeftComponent from 'Navigation/helpers/linkAccordance'

import {
  markAsActive,
  markAsInactive
} from '../../../actions/customer'

import getMultipleActions from '../../../helpers/getMultipleActions'

export class Customer extends Component {
  static propTypes = {
    counterparty: PropTypes.object,
    t: PropTypes.func,
    previousLink: PropTypes.string
  }

  render () {
    const {counterparty, previousLink, t} = this.props
    const RightSideItems = getLeftComponent('createCustomer', previousLink)
    const deleteAction = counterparty.id && this.refs.customer.getWrappedInstance().showModalBox
    const actions = counterparty.id && getMultipleActions(this.props, deleteAction)
    return (
      <IpadNavigation {...this.props} title={counterparty.name || t('navbar', 'title').s} multipleActions={actions} backLink>
        <RightSideItems {...this.props} />
        <CustomerView {...this.props} ref='customer' />
      </IpadNavigation>
    )
  }
}

function mapStateToProps (state) {
  return {
    t: state.i18n.get('app', 'views', 'Customers', 'CustomerView'),
    counterparty: state.customers.customer,
    previousLink: state.appInfo.previousLink
  }
}

function mapDispatchToProps (dispatch) {
  return {
    markAsActive: (customer) => { dispatch(markAsActive(customer)) },
    markAsInactive: (customer) => { dispatch(markAsInactive(customer)) }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Customer)
