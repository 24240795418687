import './styles/smartphones.scss?global'

import PropTypes from 'prop-types'

import React, { Component } from 'react'
import { Link } from 'react-router'
import {connect} from 'react-redux'
import { routerActions } from 'react-router-redux'
import queryString from 'query-string'

import Scrollbar from 'mrshoebox-ui-components/src/components/BaronScroll'

// Components
import NewEntry from 'NewEntry/components/NewEntry'
import NavigationInfoBlock from 'Navigation/components/InfoBlock'
import MultipleActionsButton from 'Navigation/components/MultipleActionsButton/MultipleActionsButton'
import Loader from 'shared/components/Loader/Loader'

// Helpers
import getGreyLabelOptions from 'GreyLabel/helpers/greyLabel'
import { getTrimmedLink } from 'shared/helpers/linkUtils'
import { isIpadResolution } from 'shared/helpers/utils'
import { getTodoLink } from 'MainPage/helpers'

// Decorators
import Policy from 'Policy/decorators'

export function setActiveLink (entity, pathname = window.location.pathname, activeClass = 'navigation__footer__link--active') {
  if (entity === 'more' && isMoreEntity()) {
    return activeClass
  } else if (entity === 'overview' && isOverviewEntity()) {
    return activeClass
  } else if (pathname.slice(1).startsWith(entity)) {
    return activeClass
  } else {
    return ''
  }
}

function isMoreEntity () {
  const entitiesFromMore = ['invoices', 'customers', 'articles', 'driver_logs', 'documents', 'timesheets', 'messages', 'projects', 'more', 'settings']

  return pathnameStartsWithOneOf(entitiesFromMore)
}

function isOverviewEntity () {
  const entitiesFromOverview = ['overview', 'reports']

  return pathnameStartsWithOneOf(entitiesFromOverview)
}

function pathnameStartsWithOneOf (words) {
  let result = false
  const pathname = window.location.pathname.slice(1)
  words.forEach((word) => {
    if (pathname.startsWith(word)) {
      result = true
    }
  })

  return result
}

export class Footer extends Component {
  static propTypes = {
    t: PropTypes.func,
    openNewEntry: PropTypes.func,
    unreadMessagesNum: PropTypes.number,
    userSettings: PropTypes.object,
    getFeaturePermissionProps: PropTypes.func
  };

  getTodoLink () {
    return getTodoLink(this.props)
  }

  render () {
    const { openNewEntry, t, getFeaturePermissionProps, unreadMessagesNum } = this.props

    return (
      <div className='navigation__footer'>
        <div className='navigation__footer__content'>
          <Link to={this.getTodoLink()} className={setActiveLink('todo')}>
            <span className='icon-todo' data-badge={unreadMessagesNum} />
            <h5>{t('buttons', 'todo').s}</h5>
          </Link>
          <Link to='/events' {...getFeaturePermissionProps('events', setActiveLink('events'))}>
            <span className='icon-events' />
            <h5>{t('buttons', 'events').s}</h5>
          </Link>
          <div className='navigation__footer__round' onClick={openNewEntry} {...getFeaturePermissionProps('events', 'navigation__footer__round', 'navigation-plus-button-disable')}><span /></div>
          <Link to='/overview' className={setActiveLink('overview')}>
            <span className='icon-overview' />
            <h5>{t('buttons', 'overview').s}</h5>
          </Link>
          <Link to='/more' className={setActiveLink('more')}>
            <span className='icon-more' />
            <h5>{t('buttons', 'more').s}</h5>
          </Link>
        </div>
      </div>
    )
  }
}

export class Navigation extends Component {
  static propTypes = {
    children: PropTypes.node,
    title: PropTypes.oneOfType([PropTypes.func, PropTypes.string]).isRequired,
    home: PropTypes.bool,
    backLink: PropTypes.string,
    hideFooter: PropTypes.bool,
    customFooter: PropTypes.bool,
    actions: PropTypes.arrayOf(PropTypes.object),
    className: PropTypes.string,
    onClickBack: PropTypes.func,
    showSpinner: PropTypes.bool,
    notBottomSpace: PropTypes.bool,
    filterBlock: PropTypes.bool,
    infinityScrollHandler: PropTypes.func,
    scrollHandler: PropTypes.func,
    hideBackLink: PropTypes.bool,
    forceBackLink: PropTypes.bool,
    location: PropTypes.object,
    historyReplace: PropTypes.func,
    historyGoBack: PropTypes.func,
    filterClass: PropTypes.string,
    multipleActions: PropTypes.arrayOf(PropTypes.object),
    navigationWithMaxWidth: PropTypes.bool,
    onReachingTopHandler: PropTypes.func
  }

  constructor (props) {
    super(props)
    this.greyLabelOptions = getGreyLabelOptions()
    this.state = {contentHeight: 0, filterHeight: 0, filterHeightShouldChange: false}
    this.goToHome = this.goToHome.bind(this)
    this.onAddNewEntry = this.onAddNewEntry.bind(this)
    this.scrollHandler = this.scrollHandler.bind(this)
  }

  static defaultProps = {
    location: { pathname: '/foo' }
  }

  setTitle (title = this.props.title) {
    document.title = `${title} | ${this.greyLabelOptions.title}`
  }

  componentDidMount () {
    this.setTitle()
    window.scrollTo(0, 0)
  }

  componentWillReceiveProps (props) {
    if (props.title !== this.props.title) {
      this.setTitle(props.title)
    }
  }

  onAddNewEntry () {
    this.newEntry.makeVisible()
  }

  scrollHandler () {
    const {showSpinner, infinityScrollHandler, scrollHandler, onReachingTopHandler} = this.props
    if (scrollHandler) scrollHandler()
    const scrollableElement = document.querySelector('.scrollBlock__content')
    const spaceFromBottom = 50
    if (!showSpinner && (scrollableElement.offsetHeight + scrollableElement.scrollTop + spaceFromBottom) > scrollableElement.scrollHeight) {
      if (infinityScrollHandler) infinityScrollHandler()
    } else if (scrollableElement.scrollTop === 0) {
      onReachingTopHandler && onReachingTopHandler()
    }
  }

  get content () {
    const {children, filterBlock, customFooter, className, filterClass} = this.props
    let filterNode
    let childrenNote
    let customFooterBlock = null
    if (filterBlock) {
      const [fixedFilter, ...otherChildren] = children
      filterNode = fixedFilter
      childrenNote = otherChildren
    }
    if (customFooter) {
      customFooterBlock = children[children.length - 1]
      childrenNote = childrenNote.slice(0, -1)
    }
    return (
      <div className='navigation-content'>
        <NavigationInfoBlock />
        {filterBlock && <div className={`navigation-filter ${filterClass || ''}`} >
          { filterNode }
        </div>}
        <Scrollbar
          rootSelector='mobile-scroll'
          onScroll={this.scrollHandler}
        >
          {!filterBlock && <div className={`navigation-content-wrap ${className || ''}`}>{children}</div>}
          {filterBlock && <div className={`navigation-content-wrap ${className || ''}`}>{childrenNote}</div>}
        </Scrollbar>
        {customFooterBlock}
      </div >
    )
  }

  goToHome () {
    this.props.historyReplace('/')
  }

  get backLink () {
    const { home, backLink, forceBackLink, hideBackLink, onClickBack, location: { pathname }, historyGoBack, historyReplace } = this.props

    if (backLink && forceBackLink) {
      return <Link onClick={onClickBack} to={backLink} className='navigation__header__back'><span className='icon-arrow-left' /></Link>
    }

    if (hideBackLink) {
      return null
    } else if (home) {
      return <div onClick={this.goToHome}><span className='icon-arrow-left' /></div>
    } else if (window.previousUrl) {
      // TODO: fix
      // won't work properly if user opened an app with link different than '/'
      return <div onClick={() => { historyGoBack() }} className='navigation__header__back'><span className='icon-arrow-left' /></div>
    } else if (backLink) {
      return <div onClick={() => { historyReplace(backLink) }} className='navigation__header__back'><span className='icon-arrow-left' /></div>
    } else if (!backLink && onClickBack) {
      return <div onClick={onClickBack} className='navigation__header__back'><span className='icon-arrow-left' /></div>
    } else {
      const link = getTrimmedLink(pathname, 1)
      return <div onClick={() => { historyReplace(link) }}><span className='icon-arrow-left' /></div>
    }
  }

  render () {
    const {title, actions, hideFooter, notBottomSpace, multipleActions, navigationWithMaxWidth, location, showSpinner} = this.props
    const actionsLinks = actions && actions.map(function (action, key) {
      const content = (
        <div className={showSpinner ? 'navigation-action-button-disabled' : ''}>
          {action.icon && <span className={action.icon} />}
          <span>{!action.icon && action.text && action.text}</span>
        </div>
      )

      if (action.path) {
        const [pathname, query] = action.path.split('?')
        const parsedQuery = query ? queryString.parse(`?${query}`) : {}
        const actionComponent = showSpinner
          ? content
          : <Link to={{pathname, query: parsedQuery, state: action.state}} key={key} onClick={action.onClick}>{content}</Link>
        return actionComponent
      } else if (action.nativeLink) {
        return <a key={key} onClick={action.onClick} href={action.nativeLink}>{content}</a>
      } else {
        return (<div key={key} onClick={action.callback}>{content}</div>)
      }
    })
    const getNavigationClasses = () => {
      let extraClass = ''
      const nav = document.getElementsByClassName('navigation')[0]
      if (nav && nav.classList && nav.classList.length && nav.classList.contains('navigation--over')) {
        extraClass = ' navigation--over'
      }
      const notBottomSpaceClass = notBottomSpace ? ' navigation--notspace' : ''
      const hideFooterClass = hideFooter ? ' navigation--notfooter' : ''
      const maxWidthClass = navigationWithMaxWidth ? ' navigation--max-width' : ''
      const { whiteLabel } = getGreyLabelOptions()
      const whiteLabelModifier = whiteLabel ? ` ${whiteLabel}` : ''

      return `navigation${notBottomSpaceClass}${hideFooterClass}${extraClass}${maxWidthClass}${whiteLabelModifier}`
    }
    return (
      <div className={getNavigationClasses()}>
        {!isIpadResolution() && <Loader />}
        {isIpadResolution() && window.location.pathname.indexOf('wizard') !== -1 && <Loader forceSmartphoneTopMargin />}

        <div className='navigation__header'>
          <div>
            <div className='navigation__header__left-links'>
              {this.backLink}
            </div>
            <div className='navigation__header__title'>
              <h2 className='truncate'>{title}</h2>
            </div>
            <div className='navigation__header__right-links'>
              { multipleActions ? <MultipleActionsButton buttons={multipleActions} location={location} disabled={showSpinner} /> : actionsLinks }
            </div>
          </div>
        </div>

        {this.content}

        {!hideFooter ? <Footer openNewEntry={this.onAddNewEntry} {...this.props} /> : null}

        {!hideFooter ? <NewEntry provideController={newEntry => { this.newEntry = newEntry }} /> : null}

      </div>
    )
  }
}

function mapStateToProps (state) {
  return {
    unreadMessagesNum: state.notifications.unreadMessagesNum || null,
    showSpinner: state.navbarSpinner.value,
    wizardStep: state.wizard.currentStep,
    userSettings: state.userSettings,
    t: state.i18n.get('app', 'components', 'Navigation')
  }
}

function mapDispatchToProps (dispatch) {
  return {
    historyGoBack: () => { dispatch(routerActions.go(-1)) },
    historyPush: (path) => { dispatch(routerActions.push(path)) },
    historyReplace: (path) => { dispatch(routerActions.replace(path)) }
  }
}

const navigationWithPolicyHelpers = Policy(connect(mapStateToProps, mapDispatchToProps)(Navigation))
export default navigationWithPolicyHelpers
