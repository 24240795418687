import { COLLECTOR_PORTAL_DEV, COLLECTOR_PORTAL_PROD } from 'MarketPlace/Collector/constants/collectorPortal'

export function openCollectorPortal () {
  const portalUrl = process.env.APP_ENV === 'production'
    ? COLLECTOR_PORTAL_PROD
    : COLLECTOR_PORTAL_DEV
  window.open(portalUrl, '_blank')
}

export function isCollectorEnabled () {
  // TODO: Enable when collector will be needed again.
  return false
}
