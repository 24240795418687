export default {
  POWER: 1,
  WORKSPACES: 2,
  COMMUNITY: 3,
  BEST_PRACTICE: 4,
  INSPIRATION: 5,
  JOBS: 6,
  SEND_INVOICE: 7,
  ACCOUNTING: 8
}
