import { createReducer } from 'redux-act'
import { capcitoValidatedFieldsToStore, capcitoRedirectLinkToStore } from '../../Capcito/actions/capcito'

const initialState = {
  validatedFields: { initialValues: {}, currentErrors: {} },
  onboardingStatus: null,
  redirectLink: null
}

export default createReducer({
  [capcitoValidatedFieldsToStore]: (state, payload) => ({
    ...state,
    validatedFields: payload
  }),
  [capcitoRedirectLinkToStore]: (state, payload) => ({
    ...state,
    redirectLink: payload
  })
}, initialState)
