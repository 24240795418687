import { createAction } from 'redux-act'
import axios from 'api-client-connector'
import { camelizeKeys } from 'humps'
import { omitBy, isNil } from 'lodash'
import React from 'react'

// components
import InProgress from 'MarketPlace/shared/components/InProgress'

// actions
import { hideNavbarSpinnerAction, showSlowNavbarSpinnerAction } from 'shared/actions/navbarSpinner'
import {
  showElementInMessageBoxAction,
  closeMessageBoxAction
} from 'MessageBox/actions/messageBox'

// helpers
import { openBankIDApp } from 'User/shared/helpers'
import { openLink } from 'Integrations/OpenPayments/helpers'

// constantes
import { OPEN_PAYMENTS_VALIDATE, OPEN_PAYMENTS_CONSENT } from 'Integrations/OpenPayments/constants/api'

export const openPaymentsValidatedFieldsToStore = createAction('Validated fields to store')

export function getValidatedFields () {
  return async (dispatch) => {
    dispatch(showSlowNavbarSpinnerAction())
    let response = await getValidatedFieldsRequest()
    const data = processFields(response.data)
    dispatch([
      openPaymentsValidatedFieldsToStore(data),
      dispatch(hideNavbarSpinnerAction())
    ])
    return data
  }
}

function processFields (fields) {
  // TODO: share this for capcito/collector/creditsafe/kivra
  const fieldsDetails = camelizeKeys(fields)
    .map(({fieldData, fieldName}) => ({
      name: fieldName,
      value: fieldData.value,
      errors: fieldData.errors
    }))

  const valueReducer = (result, field) => (camelizeKeys({ ...result, [field.name]: field.value }))
  const { banks, ...initialValues } = fieldsDetails.reduce(valueReducer, {})
  const errorsReducer = (result, field) => (camelizeKeys({ ...result, [field.name]: field.errors.length ? field.errors : null }))
  const currentErrors = omitBy(fieldsDetails.reduce(errorsReducer, {}), isNil)

  return { initialValues: { bic: '', ...initialValues }, currentErrors, banks }
}

const getValidatedFieldsRequest = () => axios.post(OPEN_PAYMENTS_VALIDATE, null)

export function createConsent (data) {
  return async (dispatch, getState) => {
    dispatch(showSlowNavbarSpinnerAction())
    try {
      const { data: { redirect_link } } = await createConsentRequest(data)
      const closeHandler = () => {
        dispatch(closeMessageBoxAction())
        if (redirect_link.split('///')[0] === 'bankid:') {
          openBankIDApp(redirect_link, getState().appInfo)
        } else {
          openLink(redirect_link)
        }
      }
      const t = getState().i18n.get('app', 'views', 'Integrations', 'OpenPayments')
      dispatch(
        showElementInMessageBoxAction(
          <InProgress
            closeHandler={closeHandler}
            description={t('inProgress', 'customHeader').s}
            buttonText={t('inProgress', 'button').s}
            iconName='open-payments-logo'
          />,
          closeHandler
        )
      )
    } catch (e) {
      console.log('error', e)
    }
    dispatch(hideNavbarSpinnerAction())
  }
}

const createConsentRequest = (data) => axios.post(OPEN_PAYMENTS_CONSENT, data)
