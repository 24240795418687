import '../TaxPaymentReportView.scss?global'

import PropTypes from 'prop-types'

import React, { Component } from 'react'
import {connect} from 'react-redux'
import { getTrimmedLink } from 'shared/helpers/linkUtils'

// Components
import Navigation from 'Navigation/Navigation'
import Button from 'shared/components/Button/Button'
import FilePreview from 'FileUpload/components/FilePreview/FilePreview'
import FormatNumber from 'shared/components/FormatNumber/FormatNumber'

// Actions
import { loadTaxPaymentReportAction, removeReportFromStateAction } from 'Reports/actions/report'

export class TaxPaymentReport extends Component {
  static propTypes = {
    report: PropTypes.object,
    routeParams: PropTypes.object,
    removeReport: PropTypes.func,
    location: PropTypes.object,
    t: PropTypes.func
  }

  componentDidMount () {
    this.loadReportData()
  }
  componentWillReceiveProps (props) {
    const { routeParams: {taxPaymentReportId} } = this.props
    if (props.routeParams.taxPaymentReportId !== taxPaymentReportId) {
      this.loadReportData(props)
    }
  }

  loadReportData (props = this.props) {
    const { loadReport, routeParams: {taxPaymentReportId} } = props
    loadReport(taxPaymentReportId)
  }
  componentWillUnmount () {
    this.props.removeReport()
  }
  renderStatus () {
    const { report: { amount, payDue, paidAt }, t } = this.props
    if (amount > 0 && !paidAt) {
      return <span className='tax-payment-report__unpaid'>{t('status', 'notPaid').s}{payDue}</span>
    } else if (paidAt) {
      return <span className='tax-payment-report__paid'>{t('status', 'paid').s}</span>
    } else if (amount <= 0) {
      return <span>{t('status', 'notPayable').s}</span>
    }
  }

  renderActionButton () {
    const { report: { paidAt }, location: {pathname}, t } = this.props
    if (!paidAt) {
      return (
        <div className='f-column-bottom form-buttons'>
          <Button view='transparent-black' link={`${pathname}/approve`}>{t('buttons', 'pay').s}</Button>
        </div>
      )
    }
  }

  render () {
    const { report, t } = this.props
    return (
      <div className='tax-payment-report f-column'>
        <div className='f-column-top'>
          <h2 className='info-title'>{report.title}</h2>
          <table className='info'>
            <tbody>
              <tr className='info__title'>
                <td colSpan='2'>
                  <table>
                    <tbody>
                      <tr>
                        <td>{t('fields', 'status').s}</td>
                        <td>{this.renderStatus()}</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td colSpan='2' className='info__items'>
                  <table>
                    <tbody>
                      <tr>
                        <td>{t('fields', 'totalAmountVat').s}</td>
                        <td id='taxReportAmount'><FormatNumber value={report.amount} /></td>
                      </tr>
                      <tr>
                        <td>{t('fields', 'reportDate').s}</td>
                        <td id='taxReportDate'>{report.createdAt}</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              { report.comment ? <tr className='info__comment'>
                <td id='taxReportComment'>
                  { report.comment }
                </td>
              </tr> : null }
              <tr>
                <td colSpan='2'>
                  <table className='info__files'>
                    <tbody>
                      { report.files && report.files.map((file, i) => {
                        return <tr key={i}><td><FilePreview key={i} file={file} /></td></tr>
                      }) }
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        {this.renderActionButton()}
      </div>
    )
  }
}

class TaxPaymentReportNavigationWrap extends Component {
  static propTypes = {
    location: PropTypes.object,
    t: PropTypes.func
  }
  render () {
    const { t, location: { pathname } } = this.props
    return (
      <Navigation hideFooter backLink={getTrimmedLink(pathname, 1)} title={t('navbar', 'title').s}>
        <TaxPaymentReport {...this.props} />
      </Navigation>
    )
  }
}

function mapStateToProps (state) {
  return {
    report: state.reports.currentReport,
    t: state.i18n.get('app', 'views', 'Reports', 'TaxPayment', 'Report')
  }
}

function mapDispatchToProps (dispatch) {
  return {
    loadReport: (id) => { dispatch(loadTaxPaymentReportAction(id)) },
    removeReport: () => { dispatch(removeReportFromStateAction()) }
  }
}

export const TaxPaymentReportView = connect(mapStateToProps, mapDispatchToProps)(TaxPaymentReport)

export default connect(mapStateToProps, mapDispatchToProps)(TaxPaymentReportNavigationWrap)
