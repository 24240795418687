import axios from 'api-client-connector'
import { camelizeKeys } from 'humps'
import { createAction } from 'redux-act'
import { getCurrentClientCompany } from 'api-client-connector/utils'
import { omitBy, isNil } from 'lodash'
import { SubmissionError } from 'redux-form'
import { routerActions } from 'react-router-redux'
import { orgNumberMaskProps } from 'mrshoebox-ui-components/src/helpers/countrySpecific'

import { hideNavbarSpinnerAction, showSlowNavbarSpinnerAction } from 'shared/actions/navbarSpinner'
import {
  CREDITSAFE_ONBOARDING,
  CREDITSAFE_VALIDATE,
  CREDITSAFE_CHECK_ACTIVATION,
  CREDITSAFE_USERS,
  CREDITSAFE_REPORTS,
  CREDITSAFE_CHECK_PAYMENT_STATUS
} from 'MarketPlace/Creditsafe/constants/api'
import { UPDATE_USER_INFO, COMPANY_INFO } from 'User/Settings/constants/Api'

import { showMessageBoxWithParamsAction } from 'MessageBox/actions/messageBox'
import { redirectToStripe } from 'MarketPlace/shared/actions'

export const creditsafeValidatedFieldsToStore = createAction('Creditsafe validated fields to store')

export function getValidatedFields () {
  return async (dispatch) => {
    dispatch(showSlowNavbarSpinnerAction())
    const response = await getValidatedFieldsRequest()
    const { initialValues, currentErrors } = processFields(response.data)
    dispatch(creditsafeValidatedFieldsToStore({ initialValues, currentErrors }))
    dispatch(hideNavbarSpinnerAction())

    return { initialValues, currentErrors }
  }
}

function processFields (fields) {
  // TODO: share this for capcito/collector and creditsafe
  const fieldsDetails = camelizeKeys(fields)
    .map(({fieldData, fieldName}) => ({
      name: fieldName,
      value: fieldData.value,
      errors: fieldData.errors
    }))

  const valueReducer = (result, field) => (camelizeKeys({ ...result, [field.name]: field.value }))
  const initialValues = fieldsDetails.reduce(valueReducer, {})
  const errorsReducer = (result, field) => (camelizeKeys({ ...result, [field.name]: field.errors.length ? field.errors : null }))
  const currentErrors = omitBy(fieldsDetails.reduce(errorsReducer, {}), isNil)

  return { initialValues, currentErrors }
}

export function startOnboarding (settings) {
  return async (dispatch) => {
    dispatch(showSlowNavbarSpinnerAction())
    const { currentErrors } = await dispatch(updateSettingsAction(settings))
    if (!Object.keys(currentErrors).length) await redirectToStripe()
    dispatch(hideNavbarSpinnerAction())
    if (Object.keys(currentErrors).length) throw new SubmissionError(currentErrors)
  }
}

function updateSettingsAction (settings) {
  return async (dispatch) => {
    dispatch(showSlowNavbarSpinnerAction())

    let validationResult
    let errors = {}

    try {
      const userSettingsResponse = await updateUserSettings(settings)
      const { data: { errors: userSettingsErrors } } = userSettingsResponse

      if (userSettingsErrors && Object.keys(userSettingsErrors).length) errors = userSettingsErrors
    } catch (e) {
      dispatch(processError(e))
    }

    try {
      await updateCurrentCompanyInfo(settings)
    } catch (e) {
      dispatch(processError(e))
      errors = {
        ...errors,
        ...camelizeKeys(e.response.data.errors)
      }
      if (Object.keys(errors).length) return { currentErrors: camelizeKeys(errors) }
    }

    validationResult = await dispatch(getValidatedFields())
    dispatch(hideNavbarSpinnerAction())
    return validationResult
  }
}

function updateUserSettings (values) {
  const userData = {
    name: values.name,
    email: values.email,
    phone: values.mobilePhone
  }

  return axios.patch(UPDATE_USER_INFO, userData)
}

function updateCurrentCompanyInfo ({ street, postalCode, country, city, ...settings }) {
  // TODO: share with other marketplace modules.
  let companyData = {
    client_company: {
      name: settings.companyName,
      org_number: orgNumberMaskProps.normalize(settings.orgNumber)
    }
  }

  if (settings.addressId) {
    companyData.client_company.addresses_attributes[0].id = settings.addressId
  }

  if (street && postalCode && country && city) {
    companyData.addresses_attributes = [
      {
        street,
        postal_code: postalCode,
        country,
        city,
        billable: true
      }
    ]
  }

  const currentCompanyId = getCurrentClientCompany()

  return axios.patch(COMPANY_INFO + currentCompanyId + '?legacy_id=true', companyData)
}

export function createOnboardingRequest () {
  return async (dispatch, getState) => {
    try {
      await sendOnboardRequest()
      dispatch(routerActions.push('/marketplace/creditsafe/portal'))
    } catch (e) {
      const t = getState().i18n.get('app', 'shared', 'messages')
      dispatch(showMessageBoxWithParamsAction(null, t('unexpectedError', 'description').s))
    }
  }
}

export const getValidatedFieldsRequest = (data) =>
  axios.post(CREDITSAFE_VALIDATE, data)

// TODO: not needed anymore?
export function getCreditsafeOnboardingInfo () {
  return async (dispatch, getState) => {
    dispatch(showSlowNavbarSpinnerAction())
    try {
      const { data } = await getCreditsafeOnboardingInfoRequest()
      dispatch(hideNavbarSpinnerAction())
      return camelizeKeys(data)
    } catch (e) {
      const t = getState().i18n.get('app', 'shared', 'messages')
      dispatch([
        showMessageBoxWithParamsAction(null, t('unexpectedError', 'description').s),
        dispatch(hideNavbarSpinnerAction())
      ])
    }
  }
}

const sendOnboardRequest = () =>
  axios.post(CREDITSAFE_ONBOARDING(null), null)

const getCreditsafeOnboardingInfoRequest = () =>
  axios.get(CREDITSAFE_ONBOARDING({company_id: getCurrentClientCompany()}))

export const checkActivationRequest = () => {
  return axios.post(CREDITSAFE_CHECK_ACTIVATION)
}

export const checkPaymentStatusRequest = (data) => {
  return axios.post(CREDITSAFE_CHECK_PAYMENT_STATUS, data)
}

export const createCreditsafeUserRequest = () => {
  return axios.post(CREDITSAFE_USERS)
}

export const runCreditsafeReportRequest = ({ orgNumber }) => {
  return axios.post(CREDITSAFE_REPORTS, {
    organization_number: orgNumber
  })
}

function processError (response) {
  console.log('error', response)
  return hideNavbarSpinnerAction()
}
