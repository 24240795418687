import React from 'react'
import PropTypes from 'prop-types'

import './index.scss?global'

const OnboardingLayout = ({customClass, isFullParentHeight, children}) =>
  <div className={`onboarding-layout ${customClass}`} style={{height: isFullParentHeight ? '100%' : null}}>
    {children}
  </div>

OnboardingLayout.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node),
  customClass: PropTypes.string,
  isFullParentHeight: PropTypes.bool
}

export default OnboardingLayout
