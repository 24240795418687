import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router'

export const EmployeesListItem = (props) => {
  const { id, name } = props.data
  return (
    <div className='list-multiline-item'>
      <Link to={`/employees/${id}`} className='list-multiline-item'>
        <div className='list-multiline-item__row'>
          <div>
            <h3 style={{textAlign: 'center'}}>{name}</h3>
          </div>
        </div>
        <h4 className='list-multiline-item__arrow'><span className='icon-arrow-right' /></h4>
      </Link>
    </div>
  )
}

EmployeesListItem.propTypes = {
  data: PropTypes.object.isRequired
}
