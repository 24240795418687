import { PROJECTS_LIST_UPDATE } from '../constants/ActionTypes'

const initialState = {
  lastPageLoaded: 0,
  hasNextPage: true,
  projects: [],
  currentProject: null
}

export default function (state = initialState, action) {
  switch (action.type) {
    case PROJECTS_LIST_UPDATE:
      return {
        ...state,
        projects: action.payload.projects,
        hasNextPage: action.payload.hasNextPage,
        lastPageLoaded: action.payload.lastPageLoaded
      }
    default:
      return state
  }
}
