import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Link } from 'react-router'

// Components
import Navigation from 'Navigation/Navigation'
import ListView from 'shared/components/ListView/ListView'

export function ReportsViewListItem (props) {
  const {title, path} = props.data
  return (
    <Link to={path} className='list-single-item'>
      <h3>{title}</h3>
      <h4><span className='icon-arrow-right' /></h4>
    </Link>
  )
}

ReportsViewListItem.propTypes = {
  data: PropTypes.object.isRequired
}

export default class ReportsView extends Component {
  render () {
    const list = [
      {title: 'Tax Payment Reports', path: '/reports/tax_payment'},
      {title: 'Financial Reports', path: '/reports/financial'},
      {title: 'Key Figure Reports', path: '/reports/key_figure'}
    ]

    return (
      <Navigation backLink='/' title='Reports'>
        <ListView data={list} template={ReportsViewListItem} />
      </Navigation>
    )
  }
}
