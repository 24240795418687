import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { reduxForm, Field } from 'redux-form'

import { createSelect } from 'shared/factories/createSelect'
import { CUSTOMER_TYPE_COMPANY, CUSTOMER_TYPE_INDIVIDUAL } from '../../constants/CustomerTypes'
import Button from 'shared/components/Button/Button'
import DropDown from 'shared/components/FormDropdown'
import Input from 'shared/components/FormInput'
import CompanyNameInput from 'Counterparties/Customers/components/CompanyNameInput'
import { WrappedPersonalNumberInput as PersonalNumberInput } from 'shared/components/Input/PersonalNumber'

import { orgNumberValid, haveTaxNumber, personalNumberValid } from 'mrshoebox-ui-components/src/helpers/countrySpecific'

export class CustomerForm extends Component {
  static propTypes = {
    pushInfo: PropTypes.func,
    t: PropTypes.func,
    handleSubmit: PropTypes.func,
    customerType: PropTypes.string
  }
  render () {
    const {
      t,
      handleSubmit,
      customerType
    } = this.props
    const customerTypesList = createSelect([CUSTOMER_TYPE_COMPANY, CUSTOMER_TYPE_INDIVIDUAL], t('selects', 'customerType'))

    return (
      <div className='f-column'>
        <div className='form f-column-top'>
          <div className='form__item'>
            <Field
              name='customerType'
              component={DropDown}
              big
              emptyOption={t('emptyOptions', 'customerType').s}
              hint={t('fields', 'customerType').s}
              options={customerTypesList}
            />
          </div>
          <div className='form__item'>
            { customerType === CUSTOMER_TYPE_COMPANY
              ? (<Field name='name' component={CompanyNameInput} placeholder={t('fields', 'name').s} />)
              : (<Field name='name' component={Input} placeholder={t('fields', 'name').s} />)
            }
          </div>
          { customerType === CUSTOMER_TYPE_COMPANY &&
            (<div className='form__item'>
              <Field
                name='orgNumber'
                component={Input}
                placeholder={t('fields', 'orgNumber').s}
                type='orgNumber'
              />
            </div>)
          }
          { customerType === CUSTOMER_TYPE_COMPANY &&
            (<div className='form__item'>
              <Field
                name='vatNumber'
                component={Input}
                placeholder={t('fields', 'vatNumber').s}
              />
            </div>)
          }
          { customerType === CUSTOMER_TYPE_INDIVIDUAL &&
            (<div className='form__item'>
              <Field
                name='personalNumber'
                component={PersonalNumberInput}
                placeholder={t('fields', 'personalNumber').s}
              />
            </div>)
          }
          { customerType === CUSTOMER_TYPE_COMPANY && haveTaxNumber &&
            (<div className='form__item'>
              <Field name='taxNumber' component={Input} placeholder={t('fields', 'taxNumber').s} />
            </div>)
          }
          <div className='form__item'>
            <Field name='email' component={Input} placeholder={t('fields', 'email').s} type='email' />
          </div>
          <div className='form__item'>
            <Field name='email2' component={Input} placeholder={t('fields', 'additionalEmail').s} type='email' />
          </div>
          <div className='form__item'>
            <Field name='contactPerson' component={Input} placeholder={t('fields', 'contactPerson').s} />
          </div>
          <div className='form__item'>
            <Field name='address' component={Input} placeholder={t('fields', 'address').s} />
          </div>
          <div className='form__item'>
            <Field name='zipcode' component={Input} placeholder={t('fields', 'zipcode').s} />
          </div>
          <div className='form__item'>
            <Field name='city' component={Input} placeholder={t('fields', 'city').s} />
          </div>
          <div className='form__item'>
            <Field name='country' component={Input} placeholder={t('fields', 'country').s} />
          </div>
          <div className='form__item'>
            <Field name='daysForPayment' component={Input} placeholder={t('fields', 'daysForPayment').s} type='number' />
          </div>
        </div>
        <div className='f-column-bottom form-buttons'>
          <Button view='transparent-black' saveButton onClick={handleSubmit(this.props.pushInfo)}>{t('buttons', 'save').s}</Button>
          <Button view='transparent-red' goBackButton>{t('buttons', 'cancel').s}</Button>
        </div>
      </div>
    )
  }
}

function validate (values) {
  const errors = {}

  if (!values.email || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = true
  }
  if (!values.name || values.name.length >= 60) {
    errors.name = true
  }
  if (values.customerType === CUSTOMER_TYPE_COMPANY && values.orgNumber &&
    !orgNumberValid(values.orgNumber)) {
    errors.orgNumber = true
  }
  if (!values.customerType) {
    errors.customerType = true
  }

  if (values.personalNumber && !personalNumberValid(values.personalNumber)) {
    errors.personalNumber = true
  }

  return errors
}

export default reduxForm({
  form: 'customer',
  enableReinitialize: true,
  validate
})(CustomerForm)
