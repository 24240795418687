import { combineReducers } from 'redux'

import list from 'Vacancies/reducers/list'
import vacancy from 'Vacancies/reducers/vacancy'

const vacanciesReducer = combineReducers({
  list,
  vacancy
})

export default function (state, action) {
  return vacanciesReducer(state, action)
}
