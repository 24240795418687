import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Link } from 'react-router'
import { connect } from 'react-redux'

// Components
import Modal from 'shared/components/NewModal/NewModal'
import Button from 'shared/components/Button/Button'
import ListView from 'shared/components/ListView/ListView'
import FormatNumber from 'shared/components/FormatNumber/FormatNumber'
import FormatDate from 'shared/components/FormatDate/FormatDate'

// Helpers
import { setTime } from 'shared/helpers/utils'

function EventsListItem (projectId, props) {
  const { date, category, amount, vatAmount, eventId } = props.data
  return (
    <Link to={`/events/${eventId}?backLink=/projects/${projectId}?type=specification`} className='list-multiline-item'>
      <div className='list-multiline-item__row'>
        <div>
          <h4>{category}</h4>
          <h4><FormatNumber value={amount} /></h4>
        </div>
        <div>
          <h5>{date}</h5>
          <h5>VAT: { vatAmount ? <FormatNumber value={vatAmount} /> : null }</h5>
        </div>
      </div>
      <h4 className='list-multiline-item__arrow'><span className='icon-arrow-right' /></h4>
    </Link>
  )
}

EventsListItem.propTypes = {
  data: PropTypes.object.isRequired
}

// TODO использовать то же, что и в invoice import?
function TimesheetListItem (projectId, props) {
  const { startTime, stopTime, title, minutes, id } = props.data
  return (
    <Link to={`/timesheets/${id}/edit?backLink=/projects/${projectId}?type=specification`} className='list-multiline-item'>
      <div className='list-multiline-item__row'>
        <div>
          <h4>Start <FormatDate value={startTime} format='YYYY-MM-DD HH:mm' /></h4>
          <h4>{title}</h4>
        </div>
        <div>
          <h5>Stop <FormatDate value={stopTime} format='YYYY-MM-DD HH:mm' /></h5>
          <h5>{setTime(minutes, 'minutes', 'HHs:MMs')}</h5>
        </div>
      </div>
      <h4 className='list-multiline-item__arrow'><span className='icon-arrow-right' /></h4>
    </Link>
  )
}

TimesheetListItem.propTypes = {
  data: PropTypes.object.isRequired
}

// TODO использовать то же, что и в invoice import?
function MileageListItem (projectId, props) {
  const { distance, id } = props.data
  return (
    <Link to={`/driver_logs/${id}?backLink=/projects/${projectId}?type=specification`} className='list-single-item'>
      <h3>{distance}&nbsp;km</h3>
      <h4><span className='icon-arrow-right' /></h4>
    </Link>
  )
}

MileageListItem.propTypes = {
  data: PropTypes.object.isRequired
}

class SpecificationView extends Component {
  static propTypes = {
    project: PropTypes.object,
    params: PropTypes.object,
    t: PropTypes.func
  };

  constructor (props) {
    super(props)
    this.state = {
      activeSpecificationView: 'events'
    }
    this.onAddEvent = this.onAddEvent.bind(this)
    this.onChangeSpecificationView = this.onChangeSpecificationView.bind(this)
  }

  onAddEvent () {
    this.modal.open()
  }

  onChangeSpecificationView (key) {
    this.setState({activeSpecificationView: key})
  }

  render () {
    const {activeSpecificationView} = this.state
    const { project, t } = this.props

    const renderView = function () {
      var view
      switch (activeSpecificationView) {
        case 'events':
          view = <ListView data={project.eventRows} template={(data) => EventsListItem(project.id, data)} notMark />
          break
        case 'time':
          view = <ListView data={project.timeReports} template={(data) => TimesheetListItem(project.id, data)} notMark />
          break
        case 'mileage':
          view = <ListView data={project.mileageReports} template={(data) => MileageListItem(project.id, data)} notMark />
          break
      }

      return view
    }

    const isActive = function (key) {
      return (activeSpecificationView === key) ? 'specification-filter__active' : ''
    }

    return (
      <div>
        <div className='specification-filter'>
          <div>
            <span className='icon-add-round' onClick={this.onAddEvent} />
          </div>
          <div>
            <span className={`icon-album-view ${isActive('events')}`} onClick={() => { this.onChangeSpecificationView('events') }} />
          </div>
          <div>
            <span className={`icon-watch ${isActive('time')}`} onClick={() => { this.onChangeSpecificationView('time') }} />
          </div>
          <div>
            <span className={`icon-car ${isActive('mileage')}`} onClick={() => { this.onChangeSpecificationView('mileage') }} />
          </div>
        </div>

        {renderView()}

        <Modal ref={(c) => { this.modal = c }}>
          <div className='modal-with-buttons'>
            <div className='texts'>
              <h1><b>{t('modalTitle').s}</b></h1>
              <h2>{t('modalDescription').s}</h2>
            </div>
            <div className='buttons'>
              <Button view='transparent-white' link={`/projects/${this.props.params.projectId}/import/events`}>{t('events').s}</Button>
              <Button view='transparent-white' link={`/projects/${this.props.params.projectId}/import/timesheets`}>{t('timeReport').s}</Button>
              <Button view='transparent-white' link={`/projects/${this.props.params.projectId}/import/mileage`}>{t('mileageReport').s}</Button>
            </div>
          </div>
        </Modal>
      </div>
    )
  }
}

function mapStateToProps (state) {
  return {
    project: state.project,
    t: state.i18n.get('app', 'views', 'ProjectView', 'specification')
  }
}

export default connect(mapStateToProps)(SpecificationView)
