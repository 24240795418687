import Smartphones from 'Employees/views/BenefitsView/containers/Smartphones'
import Tablets from 'Employees/views/BenefitsView/containers/Tablets'

import './styles.scss?global'

import { getCurrentDeviceView } from 'shared/helpers/utils'

const BenefitsView = getCurrentDeviceView(Smartphones, Tablets)

export default BenefitsView
