import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { routerActions } from 'react-router-redux'

import { checkAuth } from 'User/Auth/actions/auth'
import loadAllSettings from 'User/Settings/actions/settingsLoader'

export function requireAuthentication (Component) {
  class AuthComponent extends React.Component {
    static propTypes = {
      location: PropTypes.object.isRequired,
      dispatch: PropTypes.func.isRequired,
      userSettings: PropTypes.object,
      isAuthenticated: PropTypes.bool
    };

    componentWillMount () {
      const { dispatch, location: { pathname, query: {access_token} } } = this.props
      dispatch(checkAuth(pathname, access_token))
    }

    componentWillReceiveProps (props) {
      const { dispatch } = this.props
      const {isAuthenticated, userSettings} = props
      if (isAuthenticated && !userSettings.loaded) {
        dispatch(loadAllSettings())
      }
    }

    renderComponent () {
      const { userSettings, dispatch } = this.props
      if (!userSettings.loaded) {
        return <div />
      } else if (userSettings.loaded && !userSettings.accountant) {
        return <Component {...this.props} />
      } else {
        dispatch(routerActions.push('/'))
        return <div />
      }
    }

    render () {
      return this.renderComponent()
    }
  }

  const mapStateToProps = (state) => ({
    userSettings: state.userSettings,
    isAuthenticated: state.auth.isLoggedIn
  })

  return connect(mapStateToProps)(AuthComponent)
}
