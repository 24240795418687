import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'

// Components
import Navigation from 'Navigation/Navigation'
import ListView from 'shared/components/ListView/ListView'
import ListItem from 'Events/Events/components/ListItem/ListItem'

// Actions
import { loadFinanceDetailsByAccountAction } from 'Projects/actions/project'

class FinanceDetailsByAccountView extends Component {
  static propTypes = {
    events: PropTypes.arrayOf(PropTypes.object),
    loadEvents: PropTypes.func,
    hasNextPage: PropTypes.bool,
    lastOffset: PropTypes.number,
    location: PropTypes.object,
    t: PropTypes.func
  }

  constructor (props) {
    super(props)
    this.extendList = this.extendList.bind(this)
  }

  componentDidMount () {
    this.props.loadEvents(0)
  }

  extendList () {
    const { loadEvents, lastOffset, hasNextPage } = this.props

    if (hasNextPage) {
      loadEvents(lastOffset)
    }
  }

  render () {
    const { events, t } = this.props

    return (
      <Navigation title={t('navbar', 'title').s} className='events-view' hideFooter infinityScrollHandler={this.extendList}>
        <ListView data={events} template={ListItem} query={{backLink: this.props.location.pathname + this.props.location.search}} />
      </Navigation>
    )
  }
}

function mapStateToProps (state) {
  return {
    hasNextPage: state.events.hasNextPage,
    lastOffset: state.events.lastOffset,
    events: state.events.data,
    t: state.i18n.get('app', 'views', 'EventsView')
  }
}

function mapDispatchToProps (dispatch, ownProps) {
  const { location: { query: { financeType, accountId } }, params: { projectId } } = ownProps
  return {
    loadEvents: (lastOffset) => { dispatch(loadFinanceDetailsByAccountAction(lastOffset, { financeType, accountId, projectId })) }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FinanceDetailsByAccountView)
