import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { loadClientOverviewInfo } from 'Overview/actions/clientOverview'

import ClientOverview from 'Overview/ClientOverview/components/ClientOverview'
import Navigation from 'Navigation/Navigation'

class ClientOverviewView extends Component {
  static propTypes = {
    loadData: PropTypes.func.isRequired,
    myEconomy: PropTypes.object,
    myInvoices: PropTypes.object,
    invoiceStatus: PropTypes.object
  }

  componentDidMount () {
    this.props.loadData()
  }

  render () {
    return <ClientOverview {...this.props} />
  }
}

const ClientOverviewWithNavigation = props =>
  <Navigation title={props.t('title').s} >
    <ClientOverviewView {...props} />
  </Navigation>

const mapStateToProps = (state) => {
  const { economy, invoices, invoicesStatuses } = state.overview.clientOverview
  const t = state.i18n.get('app', 'views', 'ClientOverview')
  const calculatePercent = (total, value) => (value / total) * 100
  const totalInvoicesInAnyStatus = Object.keys(invoicesStatuses).reduce(
    (total, current) => total + invoicesStatuses[current], 0
  )

  return {
    t,
    myEconomy: {
      totalLimit: economy.totalLimit,
      balanceSettlementAccount: economy.debtAmount,
      chartData: [
        {
          name: t('myEconomy', 'unusedLimit').s,
          percent: calculatePercent(economy.totalLimit, economy.unusedLimit),
          sum: economy.unusedLimit
        },
        {
          name: t('myEconomy', 'usedLimit').s,
          percent: calculatePercent(economy.totalLimit, economy.usedLimit),
          sum: economy.usedLimit
        }
      ]
    },
    myInvoices: {
      activeInvoices: invoices.activeInvoices,
      activeCreditInvoices: invoices.activeCreditInvoices,
      chartData: [
        {
          name: t('myInvoices', 'sold').s,
          percent: calculatePercent(invoices.activeInvoices, invoices.sold),
          sum: invoices.sold
        },
        {
          name: t('myInvoices', 'soldWithRegress').s,
          percent: calculatePercent(invoices.activeInvoices, invoices.soldWithRegress),
          sum: invoices.soldWithRegress
        },
        {
          name: t('myInvoices', 'repurchased').s,
          percent: calculatePercent(invoices.activeInvoices, invoices.repurchased),
          sum: invoices.repurchased
        },
        {
          name: t('myInvoices', 'service').s,
          percent: calculatePercent(invoices.activeInvoices, invoices.service),
          sum: invoices.service
        }
      ]
    },
    invoiceStatus: {
      chartData: [
        {
          name: t('invoicesStatuses', 'notOverdue').s,
          percent: calculatePercent(totalInvoicesInAnyStatus, invoicesStatuses.notOverdue),
          sum: invoicesStatuses.notOverdue
        },
        {
          name: t('invoicesStatuses', 'due').s,
          percent: calculatePercent(totalInvoicesInAnyStatus, invoicesStatuses.due),
          sum: invoicesStatuses.due
        },
        {
          name: t('invoicesStatuses', 'reminder').s,
          percent: calculatePercent(totalInvoicesInAnyStatus, invoicesStatuses.reminder),
          sum: invoicesStatuses.reminder
        },
        {
          name: t('invoicesStatuses', 'debtCollection').s,
          percent: calculatePercent(totalInvoicesInAnyStatus, invoicesStatuses.debtCollection),
          sum: invoicesStatuses.debtCollection
        }
      ]
    }
  }
}

const mapDispatchToProps = (dispatch) => ({
  loadData: () => dispatch(loadClientOverviewInfo())
})

export const ClientOverviewContainer = connect(mapStateToProps, mapDispatchToProps)(ClientOverviewView)
export default connect(mapStateToProps, mapDispatchToProps)(ClientOverviewWithNavigation)
