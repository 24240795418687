import Smartphones from 'Todo/views/TodoOnboardingStatusView/containers/Smartphones'
import Tablets from 'Todo/views/TodoOnboardingStatusView/containers/Tablets'

import { getCurrentDeviceView } from 'shared/helpers/utils'

import './styles.scss?global'

const TodoOnboardingStatusView = getCurrentDeviceView(Smartphones, Tablets)

export default TodoOnboardingStatusView
