import { showMessageBoxWithParamsAction } from '../../MessageBox/actions/messageBox'
import { checkPermissionByURL } from 'Policy/helpers'
import getGreyLabelOptions from 'GreyLabel/helpers/greyLabel'

// Actions
import { routerActions } from 'react-router-redux'

export function showNotPermittedMessageAction () {
  return (dispatch, getState) => {
    const t = getState().i18n.get('app', 'policy', 'notPermitted')
    const description = t('description').replace('%whitelabel%', getGreyLabelOptions().title)

    return dispatch(showMessageBoxWithParamsAction(t('title').s, description))
  }
}

export function checkPermissionWithRedirect (pathname) {
  return (dispatch, getState) => {
    const subscriptions = getState().userSettings.activeSubscriptions
    if (!subscriptions) {
      return dispatch(showUnexpectedErrorAction())
    }

    const status = checkPermissionByURL(pathname, subscriptions)
    if (!status) {
      return dispatch([routerActions.replace('/'), showNotPermittedMessageAction()])
    }
  }
}

function showUnexpectedErrorAction () {
  return (dispatch, getState) => {
    const tMessage = getState().i18n.get('app', 'shared', 'messages')
    return dispatch(showMessageBoxWithParamsAction(tMessage('unexpectedError', 'description').s))
  }
}
