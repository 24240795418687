import { createAction } from 'redux-act'
import axios from 'api-client-connector'
import { getCurrentClientCompany } from 'api-client-connector/utils'
import { camelizeKeys, decamelizeKeys } from 'humps'
import { routerActions } from 'react-router-redux'
import { getFormValues } from 'redux-form'

import { hideNavbarSpinnerAction, showSlowNavbarSpinnerAction } from 'shared/actions/navbarSpinner'
import { showMessageBoxWithParamsAction, closeMessageBoxAction } from 'MessageBox/actions/messageBox'
import { updateEmployeesInSettingsAction } from 'User/Settings/actions/settingsLoader'

import { EMPLOYEES_LIST, EMPLOYEE, BENEFITS_LIST, BENEFIT } from '../constants/api'

export const employeesToStore = createAction('Employees list to store')
export const employeeToStore = createAction('Employee to store')
export const benefitsToStore = createAction('Benefits to store')
export const clearEmployee = createAction('Clear employee')
export const deletedBenefitIdToStore = createAction('Deleted benefit id to store')

export function getEmployees (filters = {}) {
  return async (dispatch, getState) => {
    dispatch(showSlowNavbarSpinnerAction())
    try {
      let response = await getEmployeesRequest({
        company_id: Number(getCurrentClientCompany()),
        ...decamelizeKeys(filters)
      })
      dispatch([
        employeesToStore(camelizeKeys(response.data)),
        hideNavbarSpinnerAction()
      ])
    } catch (e) {
      processError(e, getState, dispatch)
    }
  }
}

const getEmployeesRequest = (params) => axios.get(EMPLOYEES_LIST(params))

export function getEmployee (id) {
  return async (dispatch, getState) => {
    dispatch(showSlowNavbarSpinnerAction())
    try {
      let response = await getEmployeeRequest(id)

      dispatch([
        employeeToStore(camelizeKeys({...response.data, deletedBenefitsIds: []})),
        hideNavbarSpinnerAction()
      ])
    } catch (e) {
      processError(e, getState, dispatch)
    }
  }
}

const getEmployeeRequest = (id) => axios.get(EMPLOYEE(id))

export function saveEmployee (employee, employeeId) {
  return async (dispatch, getState) => {
    dispatch(showSlowNavbarSpinnerAction())
    try {
      let body = {
        ...employee,
        companyId: Number(getCurrentClientCompany()),
        skattalalbelKolumn: Number(employee.skattalalbelKolumn),
        skattetabell: Number(employee.skattetabell),
        employerContribution: Number(employee.employerContribution),
        address_line_1: employee.addressLine1
      }
      delete body.address_line1
      delete body.deletedBenefitsIds
      const formBenefits = employee.benefits
        .filter(benefit => benefit.title && benefit.value)
        .map(benefit => ({ ...benefit, value: Number(benefit.value) }))
      if (!employee.id) {
        // saving new benefits in new employee body
        body.benefits = formBenefits
        saveEmployeeRequest(decamelizeKeys(body), employeeId)
      } else {
        // API doesn't save edited/added benefits on employee PATCH, so
        // have to save benefits in separate requests if editing already existing employee.
        const employeeResponse = await saveEmployeeRequest(decamelizeKeys(body), employeeId)
        const saveRequests = formBenefits
          .map(benefit => saveBenefitRequest(benefit, benefit.id, employeeResponse.data.id))
        const deleteRequests = getState().employees.employee.deletedBenefitsIds
          .map(deletedBenefitId => deleteBenefitRequest(deletedBenefitId, employeeResponse.data.id))
        await Promise.all([...saveRequests, deleteRequests])
      }

      dispatch([
        routerActions.push(`/employees`),
        updateEmployeesInSettingsAction()
      ])
    } catch (e) {
      processError(e, getState, dispatch)
    }
    hideNavbarSpinnerAction()
  }
}

function saveEmployeeRequest (body, id) {
  return id === 'new'
    ? axios.post(EMPLOYEES_LIST({}).toString(), body)
    : axios.patch(EMPLOYEE(id).toString(), body)
}

export function getBenefits (employeeId) {
  return async (dispatch, getState) => {
    dispatch(showSlowNavbarSpinnerAction())
    try {
      let response = await getBenefitsRequest(employeeId)
      const notSavedBenefits = getFormValues('employee')(getState()).benefits.filter(b => !b.id)

      const benefits = [
        ...response.data.benefits,
        ...notSavedBenefits
      ]
      dispatch([
        benefitsToStore(camelizeKeys(benefits)),
        hideNavbarSpinnerAction()
      ])
    } catch (e) {
      processError(e, getState, dispatch)
    }
  }
}

const getBenefitsRequest = (employeeId) => axios.get(BENEFITS_LIST(employeeId))

export function saveBenefit (benefit, employeeId) {
  const body = {
    title: benefit.title,
    value: Number(benefit.value)
  }
  return async (dispatch, getState) => {
    dispatch(showSlowNavbarSpinnerAction())
    try {
      await saveBenefitRequest(body, benefit.id, employeeId)
      dispatch([
        hideNavbarSpinnerAction(),
        closeMessageBoxAction(),
        getBenefits(employeeId)
      ])
    } catch (e) {
      processError(e, getState, dispatch)
    }
  }
}

function saveBenefitRequest (benefit, benefitId, employeeId) {
  const body = {
    title: benefit.title,
    value: Number(benefit.value)
  }
  return benefitId
    ? axios.patch(BENEFIT(employeeId, benefitId).toString(), body)
    : axios.post(BENEFITS_LIST(employeeId).toString(), body)
}

function deleteBenefitRequest (benefitId, employeeId) {
  return axios.delete(BENEFIT(employeeId, benefitId).toString())
}

function processError (error, getState, dispatch) {
  const t = getState().i18n.get('app', 'shared', 'messages')
  dispatch(showMessageBoxWithParamsAction(null, t('unexpectedError', 'description').s))
  console.log('error', error)
}
