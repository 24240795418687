import { omit } from 'lodash'
import { bind } from 'redux-effects'
import { routerActions } from 'react-router-redux'
import { createAction } from 'redux-actions'
import { getFormValues, change } from 'redux-form'

import { INVOICE_UPDATE_PROPS } from '../../shared/constants/ActionTypes'

import { getTotalsRequest, showTotalsSpinner, hideTotalsSpinner } from './invoiceCreate'

const updateInvoiceProps = createAction(INVOICE_UPDATE_PROPS)

export function recalculateTotalsAction () {
  return (dispatch, getState) => {
    const newInvoiceRows = getState().checkBoxList.items.map((r) => ({ // TODO: not to use that
      ...r,
      rotRut: r.isChecked
    }))
    const invoice = getState().invoices.customerInvoices.invoice
    const formValues = getFormValues('invoice-form')(getState())
    const rotRutObject = getFormValues('rotRut')(getState())
    return dispatch(
      [
        showTotalsSpinner(),
        bind(
          getTotalsRequest({ ...invoice, ...formValues, invoiceRows: newInvoiceRows, rotRutObject }),
          (response) => {
            return [
              hideTotalsSpinner(),
              change('rotRut', 'rotRutAmount', response.value.rot_rut_deduction),
              change('rotRut', 'invoiceFinalTotalAmount', response.value.total)
            ]
          }
        )
      ]
    )
  }
}

export function recalculatePercentageAction (newTotal) {
  return (dispatch, getState) => {
    const rowsWithRotRutSum = getState().checkBoxList.items
      .filter(r => r.isChecked)
      .reduce((sum, r) => r.sumVat + r.sumExVat + sum, 0)

    if (rowsWithRotRutSum) {
      const newPercentage = (Number(newTotal) / rowsWithRotRutSum * 100.0).toFixed(2)

      return dispatch([
        change('rotRut', 'percentage', newPercentage)
      ])
    }
  }
}

export function saveRotRutObjectAction () {
  return (dispatch, getState) => {
    const {
      checkBoxList: { items }
    } = getState()

    const updatedInvoiceRows = getUpdatedInvoiceRowsList(items)
    const rotRutObject = getFormValues('rotRut')(getState())
    return dispatch([
      updateInvoiceProps({
        invoiceRows: updatedInvoiceRows,
        rotRutObject: {
          ...rotRutObject,
          total: Number(rotRutObject.total).toFixed(2)
        }
      }),
      routerActions.goBack()
    ])
  }
}

function getUpdatedInvoiceRowsList (checkBoxListItems) {
  return checkBoxListItems.map((item) => {
    const omittedItemInfo = omit(item, 'isChecked')
    if (item.isChecked) {
      return {
        ...omittedItemInfo,
        rotRut: true
      }
    } else {
      return {
        ...omittedItemInfo,
        rotRut: false
      }
    }
  })
}
