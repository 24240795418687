import PropTypes from 'prop-types'
import React, { Component } from 'react'

// Constants
import {INVOICE, CREDIT_INVOICE} from '../../../../shared/constants/InvoiceTypes'
import {
  COLLECTOR_INVOICE_PENDING, COLLECTOR_INVOICE_OPEN,
  COLLECTOR_INVOICE_CLOSED, COLLECTOR_INVOICE_CANCELLED
} from '../../../../shared/constants/CollectorInvoiceStatuses'
import * as INVOICE_UNITS from '../../../../shared/constants/UnitTypes'

// Components
import FormatDate from 'shared/components/FormatDate/FormatDate'
import FormatNumber from 'shared/components/FormatNumber/FormatNumber'
import Modal from 'shared/components/NewModal/NewModal'
import Icon from 'shared/components/Icon/'
import CollectorInvoiceStatusInfo from 'MarketPlace/Collector/components/CollectorInvoiceStatusInfo'

// Helpers
import { isOverdue } from '../../../../shared/helpers'
import { getListItemIcon } from '../../../../shared/components/ListItem/ListItem'
import getGreyLabelOptions from 'GreyLabel/helpers/greyLabel'

export default class InvoicePreview extends Component {
  static propTypes = {
    invoice: PropTypes.object,
    t: PropTypes.func
  }

  constructor (props) {
    super(props)
    this.state = { modalType: 'buttons' }
    this.sendAction = this.sendAction.bind(this)
    this.closeModalBox = this.closeModalBox.bind(this)
  }

  get invoiceStatus () {
    const { invoice: { status, sentAt, paidAt }, t } = this.props

    if (isOverdue(this.props.invoice)) {
      return (
        <div id='invoiceStatus'>{t('invoiceStatuses', 'title').s}: {t('invoiceStatuses', 'overdue').s}</div>
      )
    } else if (!sentAt && !paidAt) {
      return (
        <div id='invoiceStatus'>{t('invoiceStatuses', 'title').s}: {t('invoiceStatuses', 'draft').s}</div>
      )
    } else {
      return (
        <div id='invoiceStatus'>{t('invoiceStatuses', 'title').s}: {t('invoiceStatuses', status).s}</div>
      )
    }
  }

  get invoiceType () {
    const { invoice: {invoiceType}, t } = this.props
    switch (invoiceType) {
      case INVOICE:
        return (
          <div><span className='event-preview__icon-revenue icon-arrow-top' />{t('selects', 'invoiceType', INVOICE).s} </div>
        )
      case CREDIT_INVOICE:
        return (
          <div><span className='event-preview__icon-expense icon-arrow-top' />{t('selects', 'invoiceType', CREDIT_INVOICE).s}</div>
        )
      default:
        return null
    }
  }

  get collectorStatus () {
    const { invoice: { collectorStatus }, t } = this.props
    switch (collectorStatus) {
      case COLLECTOR_INVOICE_PENDING:
        return (
          <div>
            <Icon type='pending-status-v2' />
            <span>{t('invoiceCollectorStatuses', 'pending').s}</span>
          </div>
        )
      case COLLECTOR_INVOICE_OPEN:
        return (
          <div>
            <Icon type='open-status-v2' />
            <span>{t('invoiceCollectorStatuses', 'open').s}</span>
          </div>
        )
      case COLLECTOR_INVOICE_CLOSED:
        return (
          <div>
            <Icon type='closed-status-v2' />
            <span>{t('invoiceCollectorStatuses', 'closed').s}</span>
          </div>
        )
      case COLLECTOR_INVOICE_CANCELLED:
        return (
          <div>
            <Icon type='cancelled-status-v2' />
            <span>{t('invoiceCollectorStatuses', 'cancelled').s}</span>
          </div>
        )
      default:
        return null
    }
  }

  get modalContext () {
    const { modalType } = this.state
    switch (modalType) {
      case 'collector-invoice-status':
        return <CollectorInvoiceStatusInfo>{this.collectorStatus}</CollectorInvoiceStatusInfo>
      default:
        return null
    }
  }

  closeModalBox () {
    this.refs.modal.close()
    setTimeout(() => {
      this.setState({ modalType: 'buttons' })
    }, 750)
  }

  openModalWithType (modalType) {
    this.setState({ modalType }, () => { this.showModalBox() })
  }

  showModalBox () {
    this.refs.modal.open()
  }

  sendAction (action, actionPayload) {
    if (this.errorList.length) {
      this.setState({ modalType: 'errors' })
    } else {
      this.closeModalBox()
      action && typeof action === 'function' && action(actionPayload)
    }
  }

  getRowWhiteLabelClasses () {
    const { whiteLabel } = getGreyLabelOptions()

    const titleClass = whiteLabel ? `event-preview__row__title ${whiteLabel}` : 'event-preview__row__title'
    const contentClass = whiteLabel ? `event-preview__row__content ${whiteLabel}` : 'event-preview__row__content'
    const eventPreviewRowsClass = whiteLabel ? `event-preview__rows ${whiteLabel}` : 'event-preview__rows'

    return { titleClass, contentClass, eventPreviewRowsClass }
  }

  renderRowsList (rows) {
    const { t } = this.props
    const { titleClass, contentClass, eventPreviewRowsClass } = this.getRowWhiteLabelClasses()
    if (rows.length) {
      return (
        <div className={eventPreviewRowsClass}>
          <div className='hr'><span>{t('fields', 'specification').s}</span></div>
          <div className='event-preview__rows__list'>
            {rows.map((item, index) => {
              let amountOfUnits = item.amountOfUnits
              let price = item.price

              if (item.unit === INVOICE_UNITS.HOUR) {
                amountOfUnits = `${parseInt(item.amountOfUnits)}:${Math.round((item.amountOfUnits % 1) * 60)}`
              }

              if (item.unit === INVOICE_UNITS.KM) {
                amountOfUnits = item.amountOfUnits / 10
                price = item.price * 10
              }

              return (
                <div className='event-preview__row' key={index}>
                  <div className={titleClass}><h3>{item.description}</h3></div>
                  <div className={contentClass}>
                    <table>
                      <tbody>
                        <tr>
                          <td>{t('specification', 'perUnit').s}:</td>
                          <td><FormatNumber value={price} /></td>
                        </tr>
                        <tr>
                          <td>{t('units', String(item.unit)).s}:</td>
                          <td>{amountOfUnits}</td>
                        </tr>
                        <tr>
                          <td>{t('specification', 'vat').s}:</td>
                          <td><FormatNumber value={item.sumVat} />&nbsp;{item.currencyName}</td>
                        </tr>
                        <tr>
                          <td>{t('specification', 'total').s}:</td>
                          <td><FormatNumber value={Number(item.sumExVat) + Number(item.sumVat)} />&nbsp;{item.currencyName}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      )
    } else {
      return null
    }
  }

  get invoiceMessage () {
    const { invoice: { message }, t } = this.props
    if (!message) {
      return null
    }

    return (
      <div className='invoice-message'>
        <div className='hr'><span>{t('fields', 'message').s}</span></div>
        <div className='invoice-message__text'><b>{message}</b></div>
      </div>
    )
  }

  render () {
    const {invoice, t} = this.props
    const { currencyName } = invoice

    const totalMultiplier = invoice.invoiceType === CREDIT_INVOICE ? -1 : 1
    const { whiteLabel } = getGreyLabelOptions()
    const staticSectionClass = whiteLabel ? `event-preview__static-section ${whiteLabel}` : 'event-preview__static-section'
    const singleFieldClass = whiteLabel ? `event-preview__single-field ${whiteLabel}` : 'event-preview__single-field'
    if (!invoice.invoiceNumber) {
      return (<div />)
    } else {
      return (
        <div className='event-preview invoice-preview'>

          <div className={staticSectionClass}>
            <div className='event-preview__title'>
              <h2>{`#${invoice.invoiceNumber}`}</h2>
            </div>

            <div className='event-preview__status'>
              <div className='event-preview__status__left'>{ this.invoiceType }</div>
              <div className='event-preview__status__right'>
                { this.invoiceStatus }
                <Icon className='event-preview__status__right__svg-icon' type={getListItemIcon(invoice)} />
              </div>
            </div>
            { invoice.collectorStatus &&
              <div className='event-preview__status event-preview__status_collector' onClick={() => this.openModalWithType('collector-invoice-status')}>
                <div className='event-preview__status__left'>{ this.collectorStatus }</div>
                <div className='event-preview__status__right'>
                  <span className='icon-arrow-right' />
                </div>
              </div>
            }
          </div>

          <div className='event-preview__content'>
            <div className='event-preview__content__left'>
              <div className={singleFieldClass}>
                <h5>{t('fields', 'customerName').s}</h5>
                <h3 id='customerName' className='truncate'>{invoice.customer.name}</h3>
              </div>

              <div className='event-preview__fields'>
                <div>
                  <h3><FormatDate value={invoice.invoiceDate} /></h3>
                  <h5 className='event-preview__fields__item__label'>{t('fields', 'invoiceDate').s}</h5>
                </div>
                <div>
                  <h3><FormatDate value={invoice.dueDate} /></h3>
                  <h5 className='event-preview__fields__item__label'>{t('fields', 'dueDate').s}</h5>
                </div>
                <div>
                  <h3><FormatNumber value={invoice.invoiceFee} /> {currencyName}</h3>
                  <h5 className='event-preview__fields__item__label'>{t('fields', 'invoiceFee').s}</h5>
                </div>
                {/* <div>
                  <h3><FormatNumber value={invoice.lateFeePercentage} />%</h3>
                  <h5 className='event-preview__fields__item__label'>{t('fields', 'lateFeePercentage').s}</h5>
                </div> */}
                <div>
                  <h3><FormatNumber value={-invoice.discountAmount} /> {currencyName}</h3>
                  <h5 className='event-preview__fields__item__label'>{t('fields', 'discountAmount').s}</h5>
                </div>
                <div>
                  <h3><FormatNumber value={invoice.reminderFee} /></h3>
                  <h5 className='event-preview__fields__item__label'>{t('fields', 'reminderFee').s}</h5>
                </div>
              </div>
            </div>
            <div className='event-preview__content__right'>
              {this.renderRowsList(invoice.invoiceRows)}
              {this.invoiceMessage}
            </div>
          </div>

          <div className='event-preview__total'>
            <table>
              <tbody>
                <tr>
                  <td>{t('fields', 'total').s}:</td>
                  <td><FormatNumber value={Number(invoice.totals.preTotal) * totalMultiplier} /> {currencyName}</td>
                </tr>
                <tr>
                  <td>{t('fields', 'invoiceFee').s}:</td>
                  <td><FormatNumber value={Number(invoice.invoiceFee) * totalMultiplier} /> {currencyName}</td>
                </tr>
                <tr>
                  <td>{t('fields', 'totalVat').s}:</td>
                  <td><FormatNumber value={Number(invoice.totals.totalVat) * totalMultiplier} /> {currencyName}</td>
                </tr>
                {
                  invoice.totals.discountAmount ? <tr>
                    <td>{t('fields', 'discountAmount').s}:</td>
                    <td><FormatNumber value={-Number(invoice.totals.discountAmount) * totalMultiplier} /> {currencyName}</td>
                  </tr> : null
                }
                { invoice.totals.rotRut ? <tr>
                  <td>{t('fields', 'rotRut').s}:</td>
                  <td><FormatNumber value={Number(-invoice.totals.rotRut) * totalMultiplier} /> {currencyName}</td>
                </tr> : null }
                { invoice.totals.roundingAmount ? <tr>
                  <td>{t('fields', 'roundingAmount').s}:</td>
                  <td><FormatNumber value={invoice.totals.roundingAmount * totalMultiplier} /> {currencyName}</td>
                </tr> : null }
                <tr>
                  <td>{t('fields', 'grandTotal').s}:</td>
                  <td><FormatNumber value={invoice.totals.total * totalMultiplier} /> {currencyName}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <Modal ref='modal' onClose={this.closeModalBox}>
            { this.modalContext }
          </Modal>
        </div>
      )
    }
  }
}
