import { createReducer } from 'redux-act'
import { vacanciesToStore, roleAreasToStore, extendVacanciesList } from 'Vacancies/actions'

const initialState = {
  offset: 0,
  total: 0,
  hasMoreVacancies: false,
  data: [],
  roleAreas: []
}

export default createReducer({
  [vacanciesToStore]: (state, payload) => ({
    ...state,
    data: payload.data,
    offset: payload.offset,
    total: payload.total,
    hasMoreVacancies: payload.hasMoreVacancies
  }),
  [extendVacanciesList]: (state, payload) => ({
    ...state,
    data: [...state.data, ...payload.data],
    offset: payload.offset,
    total: payload.total,
    hasMoreVacancies: payload.hasMoreVacancies
  }),
  [roleAreasToStore]: (state, payload) => ({
    ...state,
    roleAreas: payload
  })
}, initialState)
