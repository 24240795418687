import PropTypes from 'prop-types'
import React from 'react'
import { Provider } from 'react-redux'
import { Router, createRoutes } from 'react-router'
import ReactGA from 'react-ga'
import { Helmet } from 'react-helmet'

import Initializer from 'containers/Initializer'

import Favicon from 'shared/components/Favicon/Favicon'
import NotificationBadges from 'Notifications/components/NotificationBadges/NotificationBadges'
import FileView from 'FileUpload/views/FileView/FileView'
import PreventLandscape from 'shared/components/PreventLandscape/PreventLandscape'
import MessageBox from 'MessageBox/containers/MessageBoxContainer'

import getGreyLabelOptions from 'GreyLabel/helpers/greyLabel'

import {isIOS, isIpadResolution} from 'shared/helpers/utils'
import { isFlutterApp } from 'appBridge/bridge'

export default class Root extends React.Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    routes: PropTypes.element.isRequired,
    store: PropTypes.object.isRequired
  };

  constructor (props) {
    super(props)
    document.title = getGreyLabelOptions().title
    this.mixStoreToRoutes = this.mixStoreToRoutes.bind(this)
  }

  logPageView () {
    if (__PROD__) {
      ReactGA.set({ page: window.location.pathname })
      ReactGA.pageview(window.location.pathname)
    }
  }

  mixStoreToRoutes (routes) {
    const { store } = this.props
    return routes && routes.map(route => ({
      ...route,
      childRoutes: this.mixStoreToRoutes(route.childRoutes),
      onLeave: route.onLeave && function (prevState) {
        route.onLeave(store.dispatch, prevState)
      }
    }))
  }

  get content () {
    const routes = this.mixStoreToRoutes(createRoutes(this.props.routes))

    return (
      <Router history={this.props.history} onUpdate={this.logPageView} routes={routes} />
    )
  }

  get devTools () {
    if (__DEBUG__) {
      if (__DEBUG_NEW_WINDOW__) {
        if (!window.devToolsExtension) {
          require('containers/createDevToolsWindow').default(this.props.store)
        } else {
          window.devToolsExtension.open()
        }
      } else if (!window.devToolsExtension) {
        return null
      }
    }
  }

  get notifications () {
    return <NotificationBadges />
  }

  get headContent () {
    let viewport = ''
    if (isIpadResolution()) {
      viewport = 'width=device-width, initial-scale=1.0, user-scalable=no'
    } else {
      viewport = 'width=640, maximum-scale=1.0, user-scalable=no'
    }

    // pass the responsibility for viewport scaling to the Flutter iOS app
    if (isFlutterApp() && isIOS() && !isIpadResolution()) {
      viewport = 'width=640, maximum-scale=1.0, user-scalable=yes'
    }
    return <Helmet>
      <meta
        name='viewport'
        content={viewport}
      />
    </Helmet>
  }

  render () {
    return (
      <Provider store={this.props.store}>
        <Initializer>
          <div>
            {this.headContent}
            {this.content}
            {this.devTools}
            {this.notifications}
            <Favicon />
            <PreventLandscape />
            <MessageBox />
            <FileView /> {/* because othervise on back button previous component will be reloaded */}
          </div>
        </Initializer>
      </Provider>
    )
  }
}
