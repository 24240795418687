import PropTypes from 'prop-types'
import React from 'react'
import {connect} from 'react-redux'
import { routerActions } from 'react-router-redux'

// Actions
import { loadEventAction, clearEventAction, makeVoidEventAction } from 'Events/shared/actions/event'
// TODO: rename and move to another file
import { loadDataToExpenseViewAction } from 'Events/Expense/actions/newExpense'
import {
  showElementInMessageBoxAction,
  closeMessageBoxAction
} from 'MessageBox/actions/messageBox'
import { loadIntegrations } from 'Integrations/Integrations/actions'

// Constants
import { OPEN_PAYMENTS_TYPE } from 'Integrations/Integrations/constants/integrationsTypes'
import * as eventTypes from 'Events/shared/constants/eventType'

// Components
// TODO: InProgress Modal should be renamed and moved to app common components.
import InProgress from 'MarketPlace/shared/components/InProgress'

export default function EventContainer (Component) {
  class Event extends React.Component {
    static propTypes = {
      event: PropTypes.object,
      getInfo: PropTypes.func,
      onExit: PropTypes.func,
      loadIntegrations: PropTypes.func,
      location: PropTypes.object.isRequired,
      openPaymentSuccessModal: PropTypes.func.isRequired,
      params: PropTypes.object.isRequired
    }

    componentDidMount () {
      const { getInfo, params: { eventId }, location: { query }, t, openPaymentSuccessModal } = this.props
      getInfo(eventId)

      if (query.payment_success) {
        openPaymentSuccessModal(
          t('paymentSuccessModal', query.payment_success === 'true' ? 'success' : 'failure')
        )
      }
    }

    componentDidUpdate (prevProps) {
      const event = this.props.event ? this.props.event : {}
      const prevEvent = prevProps.event ? prevProps.event : {}
      if (event.id !== prevEvent.id && event.isInvoice && event.eventType === eventTypes.outType) {
        this.props.loadIntegrations()
      }
    }

    componentWillUnmount () {
      this.props.onExit()
    }

    render () {
      return (
        <Component {...this.props} />
      )
    }
  }

  function mapStateToProps (state) {
    return {
      settings: state.userSettings,
      event: state.events.currentEvent,
      t: state.i18n.get('app', 'views', 'EventView'),
      previousLink: state.appInfo.previousLink,
      isOpenPaymentsIntegrationActive: Boolean(state.integrations.integrationsList.find(i => i.type === OPEN_PAYMENTS_TYPE && i.isActive))
    }
  }

  function mapDispatchToProps (dispatch) {
    return {
      getInfo: (id) => { dispatch(loadEventAction(id)) },
      onExit: () => { dispatch(clearEventAction()) },
      goToEventEdit: (data, link) => { dispatch([loadDataToExpenseViewAction(data), routerActions.push(link)]) },
      goToPaymentValidation: (eventId) => dispatch(routerActions.push(`/events/${eventId}/pay`)),
      closeModal: () => dispatch(closeMessageBoxAction()),
      makeAvoid: (id) => { dispatch(makeVoidEventAction(id)) },
      openPaymentSuccessModal: (t) => dispatch(showElementInMessageBoxAction(
        <InProgress
          closeHandler={() => dispatch(closeMessageBoxAction())}
          buttonText={t('button').s}
          customHeader={t('info').s}
          iconName='open-payments-logo'
        />
      )),
      loadIntegrations: () => dispatch(loadIntegrations()),
      openRedirectToIntegrationModal: (t, closeHandler) => dispatch(showElementInMessageBoxAction(
        <InProgress
          closeHandler={closeHandler}
          buttonText={t('ok').s}
          customHeader={t('description').s}
          iconName='open-payments-logo'
        />
      ))
    }
  }

  function mergeProps (stateProps, dispatchProps, ownProps) {
    const { openRedirectToIntegrationModal, closeModal } = dispatchProps
    const { t } = stateProps
    const { location: { pathname }, dispatch } = ownProps
    const goToOpenPaymentsIntegration = () => dispatch(routerActions.push(`/integrations/open-payments?cameFrom=${pathname}`))
    return {
      ...ownProps,
      ...stateProps,
      ...dispatchProps,
      openRedirectToIntegrationModal: () => openRedirectToIntegrationModal(
        t('redirectToIntegrationModal'),
        () => { closeModal(); goToOpenPaymentsIntegration() }
      )
    }
  }

  return connect(mapStateToProps, mapDispatchToProps, mergeProps)(Event)
}
