import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { find } from 'lodash'
import { reduxForm, Field, change, formValueSelector } from 'redux-form'
import { connect } from 'react-redux'
import URI from 'urijs'

// Actions
import { loadDocumentAction, pushDocumentAction, fillFieldsForNewStatementAction, clearReducerAction } from 'Documents/actions/document'

// Components
import Navigation from 'Navigation/Navigation'
import Input from 'shared/components/FormInput'
import Button from 'shared/components/Button/Button'
import Dropdown from 'shared/components/FormDropdown'
import FileListUpload from 'FileUpload/containers/FileListUpload'

// Constants
import { UPLOADS_BASE } from 'shared/constants/Api'

class DocumentForm extends Component {
  static propTypes = {
    document: PropTypes.object,
    filesToUpload: PropTypes.object,
    pushDocument: PropTypes.func,
    t: PropTypes.func,
    documentCategories: PropTypes.arrayOf(PropTypes.object),
    handleSubmit: PropTypes.func,
    changeFilesNotPresent: PropTypes.func,
    filesNotPresent: PropTypes.bool
  }

  componentWillReceiveProps (props) {
    if (props.filesToUpload.newFiles.length && props.filesNotPresent) {
      props.changeFilesNotPresent(false)
    }
  }

  render () {
    const { t, handleSubmit, filesNotPresent, documentCategories, pushDocument, document, filesToUpload } = this.props
    return (
      <div className='f-column'>
        <FileListUpload files={document.files || []} error={filesNotPresent} />
        <div className='form f-column-top'>
          <div className='form__item'>
            <Field
              name='categoryId'
              component={Dropdown}
              big
              disabled={this.isStatement}
              mptyOption={t('emptyOptions', 'category').s}
              options={documentCategories}
              hint={t('props', 'category').s}
            />
          </div>
          <div className='form__item'>
            <Field name='title' component={Input} placeholder={t('props', 'title').s} />
          </div>
        </div>
        <div className='f-column-bottom form-buttons'>
          <Button saveButton onClick={handleSubmit((values) => pushDocument(values, filesToUpload))} view='transparent-black'>{t('buttons', 'save').s}</Button>
          <Button goBackButton view='transparent-red'>{t('buttons', 'cancel').s}</Button>
        </div>
      </div>
    )
  }
}

function validate (values) {
  let errors = {}
  const requiredFields = ['title', 'categoryId']
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = true
    }
  })

  return errors
}

const connectedDocumentForm = connect(mapStateToProps, mapDispatchToProps)(DocumentForm)

export const DocumentFormWrap = reduxForm({
  validate,
  form: 'document',
  enableReinitialize: true
})(connectedDocumentForm)

class Document extends Component {
  static propTypes = {
    document: PropTypes.object,
    filesToUpload: PropTypes.object,
    getInfo: PropTypes.func,
    params: PropTypes.object,
    fillFieldsForNewStatement: PropTypes.func,
    t: PropTypes.func,
    documentCategories: PropTypes.arrayOf(PropTypes.object),
    location: PropTypes.object,
    clearDocumentState: PropTypes.func
  }

  constructor (props) {
    super(props)
    this.isStatement = props.location.pathname.split('/')[3] === 'statement'
  }

  componentDidMount () {
    this.loadInitialData()
  }

  componentWillReceiveProps (props) {
    const newParams = props.params
    const { params: { documentId }, getInfo } = this.props
    if (newParams.documentId !== documentId) {
      getInfo(newParams.companyId, newParams.documentId)
    }
  }

  componentWillUnmount () {
    this.props.clearDocumentState()
  }

  loadInitialData () {
    const { params: { companyId, documentId }, getInfo, fillFieldsForNewStatement } = this.props
    if (this.isStatement) {
      fillFieldsForNewStatement()
    } else if (documentId) {
      getInfo(companyId, documentId)
    }
  }

  getMailtoContext (document) {
    const {categoryId, title} = document
    const { documentCategories, filesToUpload: { currentFiles }, t } = this.props
    if (categoryId) {
      const documentCategory = find(documentCategories, { value: categoryId })
      const fileUrls = (currentFiles || []).map((f) => `${UPLOADS_BASE}/${f.path}/${f.file_name}`).join('\n')
      const subject = t('email', 'subject').s
      const body = `${t('email', 'title').s}: ${title}\n` +
        `${t('email', 'category').s}: ${documentCategory.label}\n\n` +
        `${t('email', 'files').s}:\n` +
        fileUrls

      return 'mailto:?' + URI.buildQuery({ subject, body }, false, false)
    }
  }

  render () {
    return (
      <div className='f-column'>
        <DocumentFormWrap {...this.props} />
      </div>
    )
  }
}

class DocumentWithNavigation extends Component {
  static propTypes = {
    t: PropTypes.func,
    location: PropTypes.object
  }

  render () {
    const { t } = this.props
    const isStatement = this.props.location.pathname.split('/')[3] === 'statement'
    const navBarTitle = document.id ? t('titles', 'existing').s : isStatement ? t('titles', 'statement').s : t('titles', 'new').s
    return (
      <Navigation hideFooter title={navBarTitle} className='f-column'>
        <Document {...this.props} ref='document' />
      </Navigation>
    )
  }
}

const selector = formValueSelector('document')
function mapStateToProps (state) {
  return {
    initialValues: state.documents.document,
    document: state.documents.document,
    documentCategories: state.userSettings.documentCategories,
    filesToUpload: state.filesToUpload,
    settings: state.userSettings,
    t: state.i18n.get('app', 'views', 'CreateDocumentView'),
    filesNotPresent: selector(state, 'filesNotPresent')
  }
}

function mapDispatchToProps (dispatch) {
  return {
    getInfo: (companyId, id) => dispatch(loadDocumentAction(companyId, id)),
    pushDocument: (document, files) => dispatch(pushDocumentAction(document, files)),
    fillFieldsForNewStatement: () => dispatch(fillFieldsForNewStatementAction()),
    changeFilesNotPresent: (value) => dispatch(change('document', 'filesNotPresent', value)),
    clearDocumentState: () => dispatch(clearReducerAction())
  }
}

export const DocumentView = connect(mapStateToProps, mapDispatchToProps)(Document)
export default connect(mapStateToProps, mapDispatchToProps)(DocumentWithNavigation)
