import './styles/index.scss?global'

import Smartphones from 'Events/Event/views/EventView/containers/Smartphones'
import Tablets from 'Events/Event/views/EventView/containers/Tablets'

import {getCurrentDeviceView} from 'shared/helpers/utils'

const EventView = getCurrentDeviceView(Smartphones, Tablets)

export default EventView
