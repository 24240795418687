import * as eventType from 'Events/shared/constants/eventType'
import { pushEventAction } from 'Events/shared/actions/pushEvent'

// TODO убрать isNewEvent
export function pushDepositAction (isNewEvent, deposit, cameFrom) {
  const data = {
    ...deposit,
    type: eventType.moneyDeposit,
    title: 'Egen insättning',
    isInvoice: false,
    vatAmount: 0, // for eventRows
    amountWithVat: deposit.amountWithoutVat // FIXME for eventRows, should change input
  }
  // проверить - есть ли deposit.categoryId(иначе будет лишнее поле в транзакции)
  // deposit.vat_amount = 0
  return pushEventAction(data, cameFrom, 'deposit')
}
