import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { browserHistory } from 'react-router'
import { routerActions } from 'react-router-redux'
import { connect } from 'react-redux'

// Actions
import { onCancelAction, authorizeRemoteMobile } from 'User/Auth/actions/zignsecAuth'

// Components
import Input from 'shared/components/Input/Input'
import PersonalNumberInput from 'shared/components/Input/PersonalNumber'
import BackLink from 'User/shared/components/BackLink/BackLink'
import FullscreenSpinner from 'User/shared/containers/FullscreenSpinner/FullscreenSpinner'
import { Container, UpperContent } from '../../components/LoginContainer'

// Helpers
import { getBackgroundPath } from 'User/shared/helpers'

const InputWrapper = ({ input, meta, ...props }) => {
  return <Input {...input} {...meta} {...props} />
}
InputWrapper.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object
}
const PersonalNumberInputWrapper = ({ input, meta, ...props }) => {
  return <PersonalNumberInput {...input} {...meta} {...props} />
}
PersonalNumberInputWrapper.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object
}

const backgroundPath = getBackgroundPath()

class LoginViaBankIDView extends PureComponent {
  constructor (props) {
    super(props)
    this.imageRef = React.createRef()
  }

  static propTypes = {
    t: PropTypes.func,
    isLoggedIn: PropTypes.bool,
    loginViaRemoteMobileData: PropTypes.object,
    redirectTo: PropTypes.string,
    redirectToPage: PropTypes.func,
    onLoad: PropTypes.func,
    onCancel: PropTypes.func
  }

  componentDidMount () {
    this.props.onLoad()
  }

  componentDidUpdate () {
    const { isLoggedIn, redirectTo, redirectToPage } = this.props
    if (isLoggedIn) {
      redirectToPage(redirectTo)
    }
    const {loginViaRemoteMobileData} = this.props
    const intervalInMilliseconds = 2000
    const qrCodeLink = loginViaRemoteMobileData.result.qrCodeLink
    let t = 0
    this.interval = setInterval(() => {
      this.imageRef.current.src = qrCodeLink + '&t=' + t
      t++
    }, intervalInMilliseconds)
  }

  componentWillUnmount () {
    clearInterval(this.interval)
  }

  render () {
    const { t } = this.props
    return (
      <Container background={backgroundPath} >
        <UpperContent>
          &nbsp;
        </UpperContent>
        <BackLink onClick={() => { browserHistory.go(-1) }} text={t('back').s} />
        <FullscreenSpinner
          title={t('bankIDWait').s}
          onCancelClick={this.props.onCancel}
        >
          <img ref={this.imageRef} />
        </FullscreenSpinner>
      </Container>
    )
  }
}

function mapStateToProps (state) {
  return {
    isLoggedIn: state.auth.isLoggedIn,
    loginViaRemoteMobileData: state.auth.loginViaRemoteMobileData,
    redirectTo: state.auth.redirectTo,
    t: state.i18n.get('app', 'views', 'LoginView')
  }
}

function mapDispatchToProps (dispatch) {
  return {
    redirectToPage: (page) => { dispatch(routerActions.push(page || '/')) },
    onLoad: () => {
      dispatch(authorizeRemoteMobile())
    },
    onCancel: () => { dispatch(onCancelAction()) }
  }
}

const reduxHOC = connect(mapStateToProps, mapDispatchToProps)

export default reduxHOC(LoginViaBankIDView)
