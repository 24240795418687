import React, { Component } from 'react'
import PropTypes from 'prop-types'

import IpadNavigation from 'Navigation/IpadNavigation'
import TodoCollectorInvoicesView from './Smartphones'
import CommonContainer from './Common'

class TodoCollectorInvoices extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    location: PropTypes.string.isRequired
  }

  render () {
    return (
      <IpadNavigation onlyLeftSide location={this.props.location} setCurrentLink>
        <TodoCollectorInvoicesView {...this.props} />
      </IpadNavigation>
    )
  }
}

export default CommonContainer(TodoCollectorInvoices)
