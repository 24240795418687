import React, { Component } from 'react'

import Invoices from 'Invoices/CustomerInvoices/views/InvoicesView/containers/Smartphones'
import IpadNavigation from 'Navigation/IpadNavigation'
import DirectPaymentHOC from 'Invoices/CustomerInvoices/views/DirectPaymentView/containers/Common'
import DirectPayment from 'Invoices/CustomerInvoices/components/DirectPayment'

class DirectPaymentView extends Component {
  render () {
    return (
      <IpadNavigation title={this.props.t('title').s} {...this.props}>
        <Invoices />
        <DirectPayment {...this.props} />
      </IpadNavigation>
    )
  }
}

export default DirectPaymentHOC(DirectPaymentView)
