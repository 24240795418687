import './styles/index.scss?global'

import PropTypes from 'prop-types'

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router'
import Icon from 'shared/components/Icon'
import cs from 'classnames'
import { omit } from 'lodash'
import queryString from 'query-string'

import getGreyLabelOptions from 'GreyLabel/helpers/greyLabel'

export class Button extends Component {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.func,
      PropTypes.node
    ]).isRequired,
    link: PropTypes.string,
    target: PropTypes.string,
    view: PropTypes.string,
    type: PropTypes.string,
    onClick: PropTypes.func,
    replaceLink: PropTypes.string,
    nativeLink: PropTypes.string,
    goBackButton: PropTypes.bool,
    saveButton: PropTypes.bool,
    state: PropTypes.object,
    hasPendingRequest: PropTypes.bool,
    icon: PropTypes.string,
    svgIcon: PropTypes.string,
    disabled: PropTypes.bool
  };

  static contextTypes = {
    router: PropTypes.object
  };

  constructor (props) {
    super(props)
    this.state = { disabled: false }
  }

  componentDidMount () {
    this._isMounted = true
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  componentWillReceiveProps (nextProps) {
    if (!this.props.saveButton) {
      return
    }

    if (this.props.hasPendingRequest && !nextProps.hasPendingRequest) {
      setTimeout(() => {
        this._isMounted && this.setState({ disabled: false })
      }, 500)
    } else if (this.props.hasPendingRequest || nextProps.hasPendingRequest) {
      this.setState({ disabled: true })
    }
  }

  getButtonStyles (view) {
    const { whiteLabel } = getGreyLabelOptions()

    const buttonClass = cs(
      'button', view && `button--${view}`,
      whiteLabel && `button--${view}-${whiteLabel}`,
      (this.state.disabled || this.props.disabled) && 'button_disabled'
    )

    return buttonClass
  }

  renderButton () {
    const {children, view, link, state, type, onClick, replaceLink, nativeLink, goBackButton, icon, target, svgIcon, ...props} = this.props
    const button = () => {
      // TODO: remove disabled={this.state.disabled || this.props.disabled} construction
      const buttonProps = omit(props, ['hasPendingRequest', 'dispatch', 'saveButton'])
      const classNames = this.getButtonStyles(view)

      return (
        <button onClick={onClick} type={type || 'button'} {...buttonProps} className={classNames} disabled={this.state.disabled || this.props.disabled}>
          { icon ? <span className={'button__icon ' + icon} /> : null }
          { svgIcon ? <Icon className='button__icon button__svg-icon' type={svgIcon} /> : null }
          <span>{children}</span>
        </button>
      )
    }
    if (link) {
      const [pathname, query] = link.split('?')
      const parsedQuery = query ? queryString.parse(`?${query}`) : {}
      return (
        <Link to={{ pathname, query: parsedQuery, state }} className='button-link'>
          {button()}
        </Link>
      )
    } else if (nativeLink) {
      return (
        <a href={nativeLink} className='button-link' target={target}>
          {button()}
        </a>
      )
    } else if (replaceLink) {
      return <div className='button-link' onClick={() => { this.context.router.replace(replaceLink) }}>{button()}</div>
    } else if (goBackButton) {
      return <div className='button-link' onClick={() => { this.context.router.go(-1) }}>{button()}</div>
    } else {
      return button()
    }
  }
  render () {
    return this.renderButton()
  }
}

function mapStateToProps (state) {
  return {
    hasPendingRequest: state.navbarSpinner.value
  }
}

export default connect(mapStateToProps)(Button)
