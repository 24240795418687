import PropTypes from 'prop-types'
import React from 'react'
import {connect} from 'react-redux'
import URI from 'urijs'

import { isEmpty } from 'lodash'

// Actions
import { loadAnnualReportAction, removeReportFromStateAction } from 'Reports/actions/report'

// Constants
import { UPLOADS_BASE } from 'shared/constants/Api'

// Bridges
import { shareFile } from 'appBridge/notificators/app'
import { isBridgeAvailable } from 'appBridge/bridge'

export default function AnnualReportContainer (Component) {
  class AnnualReport extends React.Component {
    static propTypes = {
      report: PropTypes.object,
      routeParams: PropTypes.object,
      loadReport: PropTypes.func,
      removeReport: PropTypes.func,
      t: PropTypes.func
    }

    constructor (props) {
      super(props)
      this.state = { isCommentVisible: false }
      this.changeCommentVisibility = this.changeCommentVisibility.bind(this)
    }

    componentDidMount () {
      this.loadReportData()
    }

    componentWillUnmount () {
      this.props.removeReport()
    }

    componentWillReceiveProps (nextProps) {
      const { routeParams: {annualReportId} } = this.props
      const nextAnnualReportId = nextProps.routeParams.annualReportId
      if (nextAnnualReportId !== annualReportId) {
        this.loadReportData(nextAnnualReportId)
      }
    }

    loadReportData (nextAnnualReportId) {
      const { loadReport, report, routeParams: {annualReportId} } = this.props
      if (isEmpty(report)) {
        loadReport(annualReportId)
      } else if (nextAnnualReportId) {
        loadReport(nextAnnualReportId)
      }
    }

    get mailtoContext () {
      const { report: { file = {}, reportPeriod }, t } = this.props
      const fileUrl = `${UPLOADS_BASE}/${file.path}/${file.file_name}`
      const subject = t('email', 'subject').s
      const body = `${t('email', 'body').replace('%name%', reportPeriod)}\n\n` +
        `${t('email', 'attachments').s}\n${fileUrl}`

      return 'mailto:?' + URI.buildQuery({ subject, body }, false, false)
    }

    get navbarActions () {
      const { report: { file = {}, comment } } = this.props
      let actions = []
      if (isBridgeAvailable()) {
        const fileUrl = `${UPLOADS_BASE}/${file.path}/${file.file_name}`
        !isEmpty(file) && actions.push({ icon: 'icon-share', callback: () => { shareFile(fileUrl) } })
      } else {
        !isEmpty(file) && actions.push({ icon: 'icon-share', nativeLink: this.mailtoContext })
      }

      comment && actions.push({icon: 'icon-messages-filled', callback: () => this.changeCommentVisibility()})

      return actions
    }

    changeCommentVisibility () {
      this.setState({ isCommentVisible: !this.state.isCommentVisible })
    }

    render () {
      const props = {
        navbarActions: this.navbarActions,
        mailtoContext: this.mailtoContext,
        isCommentVisible: this.state.isCommentVisible,
        changeCommentVisibility: () => this.changeCommentVisibility()
      }

      return (
        <Component {...this.props} {...props} />
      )
    }
  }

  function mapStateToProps (state) {
    return {
      previousLink: state.appInfo.previousLink,
      report: state.reports.currentReport,
      t: state.i18n.get('app', 'views', 'Reports', 'Annual', 'Report')
    }
  }

  function mapDispatchToProps (dispatch) {
    return {
      loadReport: (reportId) => { dispatch(loadAnnualReportAction(reportId)) },
      removeReport: () => { dispatch(removeReportFromStateAction()) }
    }
  }

  return connect(mapStateToProps, mapDispatchToProps)(AnnualReport)
}
