import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { reduxForm, Field } from 'redux-form'
import moment from 'moment'

import Button from 'shared/components/Button/Button'
import Textarea from 'shared/components/Textarea/Textarea'
import Input from 'shared/components/Input/Input'

import { registerDirectPayment } from 'MarketPlace/Collector/actions'

class DirectPayment extends React.Component {
  render () {
    const { handleSubmit, t } = this.props
    return (
      <form onSubmit={handleSubmit} className='f-column'>
        <div className='form f-column-top'>
          <span className='form__item'>{t('description').s}</span>
          <div className='form__item form__input-select payment-add__amount-field'>
            <Field
              name='paymentDate'
              component={InputWrapper}
              placeholder={t('paymentDate').s}
              type='date'
            />
            <Field
              name='amount'
              component={InputWrapper}
              placeholder={t('amount').s}
              type='float'
            />
          </div>
          <div className='form__item'>
            <Field
              name='comment'
              component={TextareaWrapper}
              label={t('comment').s}
            />
          </div>
          <div className='form__item'>
            <Button
              saveButton
              type='submit'
              view='transparent-black'
            >
              {t('registerPayment').s}
            </Button>
          </div>
        </div>
      </form>
    )
  }
}

DirectPayment.propTypes = {
  handleSubmit: PropTypes.func.isRequired
}

const InputWrapper = (props) => {
  return <Input
    {...props.input}
    {...props.meta}
    {...props}
  />
}

InputWrapper.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object
}

const TextareaWrapper = (props) => {
  return <Textarea
    {...props.input}
    {...props.meta}
    {...props}
  />
}

TextareaWrapper.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object
}

const mapStateToProps = (state) => ({
  t: state.i18n.get('app', 'views', 'Invoices', 'DirectPaymentView'),
  initialValues: {
    paymentDate: moment().format('YYYY-MM-DD'),
    amount: state.invoices.customerInvoices.directPayment.amount,
    comment: ''
  }
})

function validate (values) {
  let errors = {}
  if (!parseFloat(values.amount)) {
    errors.amount = true
  }
  if (!values.paymentDate) {
    errors.date = true
  }

  return errors
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onSubmit: (paymentInfo) => dispatch(registerDirectPayment(paymentInfo, ownProps.params.id))
  }
}

const reduxHOC = connect(mapStateToProps, mapDispatchToProps)
const reduxFormHOC = reduxForm({
  form: 'directPayment',
  validate
})

export default reduxHOC(reduxFormHOC(DirectPayment))
