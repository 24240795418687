import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getFormValues } from 'redux-form'
import { get, isEqual } from 'lodash'

import { getEmployees } from 'Employees/actions'
import Navigation from 'Navigation/Navigation'
import ListView from 'shared/components/ListView/ListView'
import { EmployeesListItem } from 'Employees/components/ListItem'
import Filters, { defaultFilterValues } from 'Employees/components/Filters'

class EmployeesListView extends Component {
  static propTypes = {
    loadList: PropTypes.func.isRequired,
    employees: PropTypes.array.isRequired,
    filters: PropTypes.object.isRequired
  }

  componentDidMount () {
    this.props.loadList(this.props.filters)
  }

  componentDidUpdate (prevProps) {
    const { filters } = this.props
    if (prevProps.filters && !isEqual(prevProps.filters, filters)) {
      this.props.loadList(filters)
    }
  }

  render () {
    const { employees, filters, t } = this.props
    const actions = [
      {icon: 'icon-add', path: `/employees/new`}
    ]
    return (
      <Navigation title={t('navbar', 'title').s} backLink='/more/salary' actions={actions} forceBackLink filterBlock>
        <Filters />
        <ListView data={employees} template={EmployeesListItem} emptyListOptions={{icon: 'icon-documents', showNotFound: Boolean(get(filters, ['search', 'value', 'length']))}} />
      </Navigation>
    )
  }
}

const mapStateToProps = (state) => ({
  employees: state.employees.list.map(({ firstName, lastName, id }) =>
    ({ name: `${firstName} ${lastName}`, id })
  ),
  filters: getFormValues('employeesListFilters')(state) || defaultFilterValues,
  t: state.i18n.get('app', 'views', 'Employees', 'EmployeesListView')
})

const mapDispatchToProps = (dispatch) => ({
  loadList: (filters) => dispatch(getEmployees(filters))
})

export default connect(mapStateToProps, mapDispatchToProps)(EmployeesListView)
