import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'
import cs from 'classnames'

import Policy from 'Policy/decorators'

import getGreyLabelOptions from 'GreyLabel/helpers/greyLabel'

const MoreLinks = ({ linksConfig, getFeaturePermissionProps }) => {
  const { whiteLabel } = getGreyLabelOptions()
  const linkClass = cs('more__link', whiteLabel)
  return (
    <div className='more__links'>
      {linksConfig.map((menu, index) => {
        return (
          <div key={index} {...getFeaturePermissionProps(menu.featureName, linkClass)}>
            <Link to={menu.path} className={linkClass} key={index}>
              <span className={menu.icon} />
              <h3>{menu.title}</h3>
            </Link>
          </div>
        )
      })}
    </div>
  )
}

MoreLinks.propTypes = {
  getFeaturePermissionProps: PropTypes.func,
  linksConfig: PropTypes.array
}

export default Policy(MoreLinks)
