import React from 'react'

import IpadNavigation from 'Navigation/IpadNavigation'
import Vacancies from 'Vacancies/views/VacanciesView/containers/Smartphones'

const VacanciesView = (props) =>
  <IpadNavigation {...props} onlyLeftSide>
    <Vacancies {...props} />
  </IpadNavigation>

export default VacanciesView
