import getGreylabelOptions from 'GreyLabel/helpers/greyLabel'

export function appendWhiteLabelSettings () {
  const { mainTextColor, mobileHeaderBarColor } = getGreylabelOptions()

  if (mainTextColor) document.body.style.color = mainTextColor

  if (mobileHeaderBarColor) {
    document.getElementsByName('theme-color')[0].setAttribute('content', mobileHeaderBarColor)
  }
}
