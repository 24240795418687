import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { formValueSelector } from 'redux-form'

// Components
import ConfirmationMessageBox from 'MessageBox/components/ConfirmationMessageBox/ConfirmationMessageBox'
import Navigation from 'Navigation/Navigation'
import CustomerForm from 'Counterparties/Customers/components/CustomerForm'

// Actions
import {
  loadCustomerInfoAction,
  pushCustomerInfoAction,
  clearCustomerInfoAction,
  deleteCustomerAction,
  pushCustomerInfoForInvoiceAction,
  pushCustomerInfoForProjectAction,
  markAsActive,
  markAsInactive
} from '../../../actions/customer'

import getMultipleActions from '../../../helpers/getMultipleActions'

// HOCs
import { withCounterpartyMethods } from 'Counterparties/shared/HOCs/withCounterpartyMethods'

export class Customer extends Component {
  static propTypes = {
    deleteCounterpartyHandler: PropTypes.func,
    provideController: PropTypes.func,
    t: PropTypes.func
  }

  render () {
    const { t, deleteCounterpartyHandler, provideController } = this.props
    return (
      <div className='f-column'>
        <CustomerForm {...this.props} />
        <ConfirmationMessageBox
          title={t('modal', 'title').s}
          onYesClick={deleteCounterpartyHandler}
          provideController={provideController} />
      </div>
    )
  }
}
const WrappedCustomer = withCounterpartyMethods(Customer)

class CustomerWithNavigation extends Component {
  static propTypes = {
    counterparty: PropTypes.object,
    t: PropTypes.func
  }
  render () {
    const { counterparty, t } = this.props
    const deleteAction = counterparty.id && this.refs.customer.showModalBox
    const actions = counterparty.id && getMultipleActions(this.props, deleteAction)
    return (
      <Navigation title={counterparty.name || t('navbar', 'title').s} hideFooter multipleActions={actions} className='f-column'>
        <WrappedCustomer {...this.props} ref='customer' />
      </Navigation>
    )
  }
}

function mapStateToProps (state) {
  return {
    t: state.i18n.get('app', 'views', 'Customers', 'CustomerView'),
    counterparty: state.customers.customer,
    initialValues: state.customers.customer,
    customerType: formValueSelector('customer')(state, 'customerType')
  }
}

function mapDispatchToProps (dispatch, ownProps) {
  let pushInfo

  if (ownProps.location.state === 'invoice') {
    pushInfo = (customerInfo) => { dispatch(pushCustomerInfoForInvoiceAction(customerInfo)) }
  } else if (ownProps.location.state === 'project') {
    pushInfo = (customerInfo) => { dispatch(pushCustomerInfoForProjectAction(customerInfo)) }
  } else {
    pushInfo = (customerInfo) => { dispatch(pushCustomerInfoAction(customerInfo)) }
  }

  return {
    loadInfo: (id) => { dispatch(loadCustomerInfoAction(id)) },
    pushInfo: pushInfo,
    clearState: () => { dispatch(clearCustomerInfoAction()) },
    deleteCounterparty: (id) => { dispatch(deleteCustomerAction(id)) },
    markAsActive: (customer) => { dispatch(markAsActive(customer)) },
    markAsInactive: (customer) => { dispatch(markAsInactive(customer)) }
  }
}

export const CustomerView = connect(mapStateToProps, mapDispatchToProps, null, { withRef: true })(WrappedCustomer)
export default connect(mapStateToProps, mapDispatchToProps)(CustomerWithNavigation)
