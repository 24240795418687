import PropTypes from 'prop-types'
import React, { Component } from 'react'
import {connect} from 'react-redux'
import { compact } from 'lodash'

// Components
import Navigation from 'Navigation/Navigation'
import List from 'shared/components/ListItems/ListItems'
import LangSwitchModal from 'Entry/components/LangSwitchModal/LangSwitchModal'

import Policy from 'Policy/decorators'

// Helpers
import getGreyLabelOptions from 'GreyLabel/helpers/greyLabel'

class SettingsView extends Component {
  static propTypes = {
    t: PropTypes.func,
    checkPermission: PropTypes.func,
    companies: PropTypes.arrayOf(PropTypes.object)
  }

  render () {
    const { t, checkPermission, companies = [] } = this.props

    const FirstGroup = compact([
      {icon: 'icon-sliders', title: t('items', 'account').s, path: '/settings/account', id: 'account'},
      {icon: 'icon-pen', title: t('items', 'company').s, path: '/settings/company', id: 'company'},
      checkPermission('invoice-settings') && {icon: 'icon-notepad', title: t('items', 'invoice').s, path: '/settings/invoice', id: 'invoice'},
      {icon: 'icon-lang-switch', title: t('items', 'langSwitch').s, onClick: () => { this.langSwitchModal.open() }},
      getGreyLabelOptions().greyLabel === 'blinfo' && {icon: 'icon-circuit', title: t('items', 'support').s, nativeLink: true, path: 'http://forum.digitalaforetaget.se/org/digitala-foretaget/', target: '_blank'},
      companies.length > 1 && {icon: 'icon-customers', title: t('items', 'selectCompany').s, path: '/select_company?reloadOptions=true'},
      {icon: 'icon-logout', title: t('items', 'logOut').s, path: '/logout', highlighted: true}
    ])

    return (
      <Navigation title={t('title').s}>
        <List data={FirstGroup} />
        <LangSwitchModal provideController={(c) => { this.langSwitchModal = c }} leftMenu />
      </Navigation>
    )
  }
}

function mapStateToProps (state) {
  return {
    t: state.i18n.get('app', 'views', 'SettingsView'),
    companies: state.userSettings.companies
  }
}

export default Policy(connect(mapStateToProps)(SettingsView))
