import PropTypes from 'prop-types'
import React, { Component } from 'react'

// Container
import InvoicePreviewContainer from './Common'

// Components
import Navigation from 'Navigation/Navigation'
import InvoicePreview from '../components/InvoicePreview'

// Helpers
import getMultipleActions from '../helpers/getMultipleActions'

class InvoicePreviewView extends Component {
  static propTypes = {
    invoice: PropTypes.object,
    t: PropTypes.func
  }

  showModalBox () {
    this.refs.invoice.showModalBox()
  }
  render () {
    const { invoice, t } = this.props
    const multipleActions = invoice && getMultipleActions(this.props)

    return (
      <Navigation hideFooter title={`${t('navbar', 'title').s} #${invoice.invoiceNumber || ''}`} multipleActions={multipleActions} backLink='/invoices'>
        <InvoicePreview {...this.props} ref='invoice' />
      </Navigation>
    )
  }
}

export default InvoicePreviewContainer(InvoicePreviewView)
