import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import IpadNavigation from 'Navigation/IpadNavigation'
import { MarketPlaceView } from './Smartphones'
import More from 'More/views/MoreView/containers/Smartphones'

export class CreateMessageView extends Component {
  static propTypes = {
    t: PropTypes.func
  }

  render () {
    const { t } = this.props
    return (
      <IpadNavigation {...this.props} title={t('title').s}>
        <More {...this.props} notRightIcon isIpad />
        <MarketPlaceView {...this.props} />
      </IpadNavigation>
    )
  }
}

function mapStateToProps (state) {
  return {
    t: state.i18n.get('app', 'views', 'MarketPlace')
  }
}

export default connect(mapStateToProps)(CreateMessageView)
