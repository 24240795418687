import { bind } from 'redux-effects'
import { routerActions } from 'react-router-redux'
import { createAction } from 'redux-actions'

import { showNavbarSpinnerAction, hideNavbarSpinnerAction } from 'shared/actions/navbarSpinner'
import { importMileageReportsAction } from 'Invoices/CustomerInvoices/actions/imports'
import { updateProjectDriverlog } from 'Projects/actions/project'
import { fetchProjectsByMileageReport } from 'Projects/actions/projectsList'

import { MILEAGE_REPORT_CLEAR_FORM } from '../constants/ActionTypes'
import { MILEAGE_REPORT_CREATE, MILEAGE_REPORT_INFO, MILEAGE_GET_LAST_ITEM } from '../constants/Api'

import {fetch} from 'shared/helpers/fetch'

// Shared helpers
import { DRIVER_LOGS_REPORT_LOAD_SUCCESS } from 'mrshoebox-ui-components/src/modules/DriverLogs/constants/ActionTypes'
import responseSerializer from 'mrshoebox-ui-components/src/modules/DriverLogs/helpers/responseSerializer'
import createRequestParams from 'mrshoebox-ui-components/src/modules/DriverLogs/helpers/requestPayloadCreator'

const loadDriverLog = createAction(DRIVER_LOGS_REPORT_LOAD_SUCCESS)
export const clearMileageReportFormAction = createAction(MILEAGE_REPORT_CLEAR_FORM)

export function getLastMileageReportAction () {
  return [bind(fetch(MILEAGE_GET_LAST_ITEM, {}), getLastMileageReportProcess)]
}

function getLastMileageReportProcess ({value}) {
  const odometerStart = value.data && value.data[0] ? value.data[0].odometer.stop : null
  return loadDriverLog({odometerStart})
}

export function saveMileageReportAction (data, shouldRedirectToReceipt = false) {
  return [
    showNavbarSpinnerAction(),
    bind(pushMileageReport(data), processSuccess.bind(this, shouldRedirectToReceipt, !data.id))
  ]
}

function pushMileageReport (formData) {
  const request = formData.id ? pushMileageReportChanges(formData) : pushNewMileageReport(formData)
  return (dispatch, getState) => (
    dispatch(request).then(response => {
      // for id of newly created driverlog
      const driverlogId = response.value.id
      const initialData = getState().driverLogs.currentReport

      const request = updateProjectDriverlog(driverlogId, initialData, formData)
      // return 'response' for futher process
      return dispatch(bind(request, () => () => response))
    })
  )
}

function pushNewMileageReport (mileageReport) {
  const data = createRequestParams(mileageReport)
  return fetch(MILEAGE_REPORT_CREATE, {}, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
}

function pushMileageReportChanges (mileageReport) {
  const data = createRequestParams(mileageReport)
  return fetch(MILEAGE_REPORT_INFO, { id: mileageReport.id }, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
}

function processSuccess (shouldRedirectToReceipt, isNewRecord, response) {
  console.log(response)
  return (dispatch) => {
    dispatch([
      hideNavbarSpinnerAction(),
      routerActions.goBack(),
      clearMileageReportFormAction()
    ])

    // timeout because of no promises in browserHistory =(
    if (shouldRedirectToReceipt) {
      setTimeout(() => {
        dispatch(routerActions.push('/events/new/expense?type=receipt'))
      }, 200)
    } else if (!shouldRedirectToReceipt && isNewRecord) {
      setTimeout(() => {
        dispatch(routerActions.push(`/driver_logs/${response.value.id}`))
      }, 200)
    }
  }
}

export function getMileageReportInfoAction (id) {
  return [
    showNavbarSpinnerAction(),
    bind([fetchMileageReportInfo(id), fetchProjectsByMileageReport(id)], mapInfoFromResponse)
  ]
}

function fetchMileageReportInfo (id) {
  return fetch(MILEAGE_REPORT_INFO, { id: id })
}

function mapInfoFromResponse (response) {
  const [driverlogResponse, projectsResponse] = response
  return (dispatch, getState) => {
    const userSettings = getState().userSettings
    driverlogResponse.value.project_id = projectsResponse.value.projects[0] && projectsResponse.value.projects[0].id

    return dispatch([
      hideNavbarSpinnerAction(),
      loadDriverLog(responseSerializer(userSettings, driverlogResponse.value))
    ])
  }
}

export function saveMileageReportForInvoiceAction (mileageReport) {
  return [showNavbarSpinnerAction(), bind(pushMileageReport(mileageReport), saveToInvoice)]
}

function saveToInvoice (response) {
  const mileageReport = {
    id: response.value.id,
    distance: Number(response.value.distance),
    date: response.value.date,
    amountPerMile: response.value.compensation_per_km * 10
  }
  return [
    hideNavbarSpinnerAction(),
    routerActions.go(-2),
    importMileageReportsAction([mileageReport])
  ]
}

export function deleteMileageReportAction (id) {
  return [
    showNavbarSpinnerAction(),
    bind(fetch(MILEAGE_REPORT_INFO, { id }, { method: 'DELETE' }), processSuccess.bind(this, false, false))
  ]
}
