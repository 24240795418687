import React from 'react'
import { Route } from 'react-router'

import { requireAuthentication } from 'User/Auth/higherOrderComponents/AuthComponent'

import EmployeesListView from 'Employees/views/EmployeesListView'
import EmployeeView from 'Employees/views/EmployeeView'
import BenefitsView from 'Employees/views/BenefitsView'

import { clearEmployee } from 'Employees/actions'

function clearState (dispatch) {
  dispatch([
    clearEmployee()
  ])
}

const employeesRoutes = (
  <Route onLeave={clearState}>
    <Route path='/employees' component={requireAuthentication(EmployeesListView)} />
    <Route path='/employees/:employeeId' component={requireAuthentication(EmployeeView)} />
    <Route path='/employees/:employeeId/benefits' component={requireAuthentication(BenefitsView)} />
  </Route>
)

export default employeesRoutes
