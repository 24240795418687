import { bind } from 'redux-effects'
import axios from 'api-client-connector'
import { createAction } from 'redux-actions'
import { fetch2 as fetch } from 'shared/helpers/fetch'

import { LOAN_INFO } from 'Overview/constants/Api'
import { OVERVIEW_LOAN_LOAD } from 'Overview/constants/ActionTypes'
import { showMessageBoxWithParamsAction } from 'MessageBox/actions/messageBox'
// TODO: we need to move loan fetch logic to Capcito module or shared folder
// its being used in supplier invoices and overview now.
const loadLoanInfo = createAction(OVERVIEW_LOAN_LOAD)

export function loadLoanAction () {
  return bind(fetch(LOAN_INFO), loadLoanInfoToState, processError)
}

export function fetchLoanInfo () {
  return async () => {
    try {
      return await axios.get(LOAN_INFO)
    } catch (e) {
      processError()
    }
  }
}

function loadLoanInfoToState (response) {
  return (dispatch) => {
    return dispatch(loadLoanInfo(response.value))
  }
}

function processError () {
  return (dispatch, getState) => {
    const t = getState().i18n.get('app', 'shared', 'messages')
    return dispatch(showMessageBoxWithParamsAction(null, t('unexpectedError', 'description').s))
  }
}
