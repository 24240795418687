import React from 'react'
import {bind} from 'redux-effects'
import {createAction} from 'redux-actions'
import { routerActions } from 'react-router-redux'
import { getFormValues, change } from 'redux-form'
import { omitBy, isNil, isEqual } from 'lodash'
import moment from 'moment'

import {fetch} from 'shared/helpers/fetch'
import { showNavbarSpinnerAction, hideNavbarSpinnerAction } from 'shared/actions/navbarSpinner'
import { showElementInMessageBoxAction, closeMessageBoxAction } from 'MessageBox/actions/messageBox'
import { importTimesheetsRowAction } from 'Invoices/CustomerInvoices/actions/imports'
import { updateProjectTimesheet } from 'Projects/actions/project'
import { ConfirmationMessageBoxContent } from 'MessageBox/components/ConfirmationMessageBox/ConfirmationMessageBox'

import {TIMESHEETS_LIST_UPDATE, TIMESHEETS_LIST_CLEAN, TIMESHEET_PROP_UPDATE, TIMESHEET_PROP_CLEAN, TIMESHEET_SET_TIMER_STATE} from '../constants/Timesheet'
import {TIMESHEETS_LIST, TIMESHEET_INFO, TIMESHEET_CREATE} from '../constants/Api'
import { filterStatus } from 'Timesheet/views/TimesheetsView/Filters/Filters'
import { fetchProjectsByTimesheet } from 'Projects/actions/projectsList'

// Helpers
import {getDifferentFromDates} from 'shared/helpers/utils'

const updateTimesheetsList = createAction(TIMESHEETS_LIST_UPDATE)
export const cleanTimesheetsListAction = createAction(TIMESHEETS_LIST_CLEAN)
const updateTimesheetProp = createAction(TIMESHEET_PROP_UPDATE)
const clearTimesheetProp = createAction(TIMESHEET_PROP_CLEAN)
export const setTimerStateAction = createAction(TIMESHEET_SET_TIMER_STATE)

export function reloadTimesheetsListAction () {
  return (dispatch, getState) => {
    const filters = getFormValues('timesheetsViewFilters')(getState())
    return dispatch([
      showNavbarSpinnerAction(),
      bind(fetchTimesheets(filters), processTimesheets.bind(null, [], 0, filters), processError)
    ])
  }
}

export function extendTimesheetsListAction (filters) {
  return (dispatch, getState) => {
    const { timesheets } = getState()
    const { lastPageLoaded, hasNextPage, listData } = timesheets

    if (!filters) {
      filters = getFormValues('timesheetsViewFilters')(getState())
    }

    if (hasNextPage) {
      return dispatch([
        showNavbarSpinnerAction(),
        bind(fetchTimesheets(filters, lastPageLoaded + 1), processTimesheets.bind(this, listData, lastPageLoaded, filters), processError)
      ])
    }
  }
}

function processTimesheets (oldList, lastPageLoaded, filtersBeforeResponseReceived, responses) {
  const [response, projectsRes] = responses
  const projects = projectsRes.value.projects || []

  console.log('processing Timesheets', response)

  return (dispatch, getState) => {
    if (!isEqual(filtersBeforeResponseReceived, getFormValues('timesheetsViewFilters')(getState()))) {
      return
    }

    let reports = response.value.data
    const loadedTimesheets = reports.map((timesheet) => {
      return {
        id: timesheet.id,
        minutes: timesheet.duration,
        title: timesheet.title ? timesheet.title : ' ',
        startTime: moment.utc(timesheet.started_at_utc).local(),
        stopTime: timesheet.finished_at_utc && moment.utc(timesheet.finished_at_utc).local(),
        amountPerHour: timesheet.price_per_hour,
        exported: timesheet.exported,
        projectName: (projects.filter(p => p.timesheet_ids.includes(timesheet.id))[0] || {}).name || ''
      }
    })

    const newList = oldList.concat(loadedTimesheets)
    return dispatch([
      updateTimesheetsList({
        listData: newList,
        hasNextPage: reports.length >= 20,
        lastPageLoaded: lastPageLoaded + 1
      }),
      hideNavbarSpinnerAction()
    ])
  }
}

export function updateTimesheetTimesAction (start, stop) {
  let data = omitBy({start, stop}, isNil)
  if (data.start && data.stop) {
    data.minutes = getDifferentFromDates(data.stop, data.start, 'minutes').toFixed(0)
  }

  const keysToUpdate = ['start', 'stop']
  keysToUpdate.forEach((key) => {
    // Needed for updating form values
    if (data[key]) {
      data[key] = moment(data[key]).startOf('minute').format('YYYY-MM-DDTHH:mm:ss')
    }
  })

  return Object.keys(data).map((key) => {
    return change('timesheet', key, data[key])
  })
}

function calcStopDate (start, minutes) {
  const withoutSeconds = Math.floor(minutes)
  const newEndDate = moment(start, 'YYYY-MM-DDTHH:mm').add(withoutSeconds, 'minutes')
    .startOf('minute').format('YYYY-MM-DDTHH:mm:ss')
  return newEndDate
}

export function updateTimesheetDatesAction (start, minutes) {
  if (!start) return []

  const newEndDate = calcStopDate(start, minutes)
  return change('timesheet', 'stop', newEndDate)
}

export function loadTimesheetAction (id) {
  return [
    showNavbarSpinnerAction(),
    fetchTimesheet(id)
  ]
}

export function bulkUpdateTimesheetsAction (data) {
  return fetch(TIMESHEET_CREATE, {}, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ time_records: data })
  })
}

export function pushTimesheetAction (data) {
  return [
    showNavbarSpinnerAction(),
    bind(pushTimesheet(data), proccessPushTimesheet, processError)
  ]
}

function pushTimesheet (data) {
  const { title, start, stop, projectId, price, id, exported } = data

  const timesheet = {
    started_at_utc: moment(start).utc().startOf('minute').format('YYYY-MM-DDTHH:mm:ss'),
    price_per_hour: price,
    project_id: projectId,
    exported: exported,
    title
  }
  if (stop) timesheet.finished_at_utc = moment(stop).utc().startOf('minute').format('YYYY-MM-DDTHH:mm:ss')

  let request
  if (id) {
    request = fetch(TIMESHEET_INFO, { id }, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ time_record: timesheet })
    })
  } else {
    request = fetch(TIMESHEET_CREATE, {}, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ time_record: timesheet })
    })
  }
  return bind(request, afterPushTimesheet.bind(null, data), processError)
}

function afterPushTimesheet (formData, response) {
  return (dispatch, getState) => {
    // for id of newly created timesheet
    const timesheetId = response.value.id
    const initialData = getState().timesheets.newTimesheet

    const request = updateProjectTimesheet(timesheetId, initialData, formData)
    // return 'response' for futher process in addTimesheetToInvoice and so on
    return dispatch(bind(request, () => () => response))
  }
}

function proccessPushTimesheet () {
  return (dispatch) => {
    return dispatch([
      hideNavbarSpinnerAction(),
      routerActions.goBack()
    ])
  }
}

export function pushTimesheetForInvoiceAction (data) {
  return [
    showNavbarSpinnerAction(),
    bind(pushTimesheet(data), addTimesheetToInvoice, processError)
  ]
}

function addTimesheetToInvoice (response) {
  const timesheet = {
    id: response.value.id,
    minutes: response.value.duration,
    title: response.value.title ? response.value.title : ' ',
    startTime: moment.utc(response.value.started_at_utc).local(),
    stopTime: response.value.finished_at_utc && moment.utc(response.value.finished_at_utc).local(),
    exported: response.exported,
    amountPerHour: response.value.price_per_hour
  }

  return [importTimesheetsRowAction([timesheet]), hideNavbarSpinnerAction()]
}

export function cleanTimesheetAction () {
  return clearTimesheetProp()
}

export function fetchTimesheets (filters, pageToLoad = 1) {
  const newFilters = processFilters(filters)
  return bind(fetch(TIMESHEETS_LIST, {...newFilters, p: pageToLoad}, {
    method: 'GET'
  }), fetchTimesheetsProjects, processError)
}

function fetchTimesheetsProjects (timesheetsRes) {
  return [
    () => timesheetsRes,
    fetchProjectsByTimesheet(timesheetsRes.value.data.map(t => t.id).join(','))
  ]
}

function fetchTimesheet (id) {
  return bind([
    fetch(TIMESHEET_INFO, {id}, {
      method: 'GET'
    }),
    fetchProjectsByTimesheet(id)
  ], processTimesheet, processError)
}

function processTimesheet (response) {
  const [timesheetResponse, projectsResponse] = response
  const timesheet = timesheetResponse.value
  const data = {
    id: timesheet.id,
    minutes: Math.floor(Number(timesheet.duration)),
    title: timesheet.title,
    start: timesheet.started_at_utc && moment.utc(timesheet.started_at_utc).local().format('YYYY-MM-DDTHH:mm:ss'),
    stop: timesheet.finished_at_utc && moment.utc(timesheet.finished_at_utc).local().format('YYYY-MM-DDTHH:mm:ss'),
    exported: timesheet.exported,
    price: timesheet.price_per_hour,
    projectId: projectsResponse.value.projects[0] && projectsResponse.value.projects[0].id
  }
  return [hideNavbarSpinnerAction(), updateTimesheetProp(data)]
}

export function processFilters (filters = {}) {
  let urlParams = {
    sort: `${filters.orderBy || 'started_at_utc'} ${filters.orderType || 'desc'}`,
    all: filters.all
  }
  if (filters.tab && filters.tab !== filterStatus.all) {
    urlParams['filter[finished]'] = filters.tab === filterStatus.ended
  }
  if (filters.search) {
    Object.assign(urlParams, { 'filter[title]': filters.search })
  }
  if (filters.exported) {
    Object.assign(urlParams, { 'filter[exported]': filters.exported })
  }

  return urlParams
}

function processError (response) {
  console.error('timesheets-processError', response)
}

function deleteTimesheetAction (id) {
  return [
    showNavbarSpinnerAction(),
    bind(deleteTimesheet(id), () => [
      hideNavbarSpinnerAction(),
      cleanTimesheetAction(),
      routerActions.push('/timesheets')
    ])]
}

function deleteTimesheet (id) {
  return fetch(TIMESHEET_INFO, { id }, {
    method: 'DELETE'
  })
}

export function showDeleteTimesheetMessageBox (id) {
  return (dispatch, getState) => {
    const t = getState().i18n.get('app', 'shared', 'messages', 'confirmationMessage')

    const element = (
      <ConfirmationMessageBoxContent
        t={t}
        onYesClick={() => { dispatch([closeMessageBoxAction(), deleteTimesheetAction(id)]) }}
        onNoClick={() => { dispatch(closeMessageBoxAction()) }}
      />
    )

    return dispatch(showElementInMessageBoxAction(element, () => { dispatch(closeMessageBoxAction()) }))
  }
}
