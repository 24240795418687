import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getFormValues, SubmissionError } from 'redux-form'

import Navigation from 'Navigation/Navigation'
import Modal from 'shared/components/NewModal/NewModal'
import KivraTenantForm from 'Integrations/Kivra/components/KivraTenantForm'
import KivraRegister from 'Invoices/CustomerInvoices/components/KivraRegister'

import { getValidateTenantFunc, registerTenant, saveDataAndRedirectToStripe } from 'Integrations/Kivra/actions'

const KivraTenantView = ({ validateTenant, registerTenant, location, startIntegration, values, ...props }) => {
  const saveCardSuccess = location.query.save_card_success
  const cameFromInvoiceId = location.query.cameFromInvoiceId
  const modalRef = useRef(null)
  const showRegisterModal = () => {
    let errors = {}
    if (!values.acceptedGdprAgreement) errors.acceptedGdprAgreement = true
    if (!values.acceptedGeneralAgreement) errors.acceptedGeneralAgreement = true

    if (Object.keys(errors).length) {
      throw new SubmissionError(errors)
    } else {
      modalRef.current && modalRef.current.open()
    }
  }
  const hideRegisterModal = () => modalRef.current && modalRef.current.close()
  const onSubmit = () => {
    hideRegisterModal()
    startIntegration(values)
  }

  useEffect(() => { validateTenant() }, [validateTenant])
  useEffect(() => {
    const registrationData = {
      acceptedGdprAgreement: true,
      acceptedGeneralAgreement: true,
      email: props.initialValues.userEmail
    }
    if (saveCardSuccess === 'true' && props.initialValues.userEmail) registerTenant(registrationData, cameFromInvoiceId)
  }, [registerTenant, saveCardSuccess, props.initialValues.userEmail, cameFromInvoiceId])
  return (
    <React.Fragment>
      <Modal ref={modalRef} onClose={hideRegisterModal} small>
        <KivraRegister onSubmit={onSubmit} onCancel={hideRegisterModal} />
      </Modal>
      <KivraTenantForm onSubmit={showRegisterModal} {...props} />
    </React.Fragment>
  )
}

KivraTenantView.propTypes = {
  initialValues: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  registerTenant: PropTypes.func.isRequired,
  startIntegration: PropTypes.func.isRequired,
  validateTenant: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired
}

const KivraTenantViewWithNavigation = (props) => {
  const invoiceId = props.location.query.cameFromInvoiceId
  const backLink = invoiceId ? `/invoices/${invoiceId}` : '/integrations'
  return (
    <Navigation hideFooter title='Kivra' notBottomSpace backLink={backLink} forceBackLink>
      <KivraTenantView {...props} />
    </Navigation>
  )
}

KivraTenantViewWithNavigation.propTypes = {
  location: PropTypes.object
}

const mapStateToProps = (state) => ({
  initialValues: state.integrations.kivra.validatedFields.initialValues,
  values: getFormValues('kivra-tenant-form')(state),
  texts: {
    description: state.i18n.get('app', 'views', 'Integrations', 'Kivra', 'tenantRegistration', 'description').s,
    header: state.i18n.get('app', 'views', 'Integrations', 'Kivra', 'tenantRegistration', 'header').s,
    tFields: state.i18n.get('app', 'views', 'Integrations', 'Kivra', 'fields'),
    tButtons: state.i18n.get('app', 'views', 'Integrations', 'Kivra', 'tenantRegistration', 'buttons'),
    tTerms: state.i18n.get('app', 'views', 'Integrations', 'Kivra', 'tenantRegistration', 'terms'),
    tCheckbox: state.i18n.get('app', 'views', 'Integrations', 'Kivra', 'tenantRegistration', 'checkboxDescriptions')
  }
})

const mapDispatchToProps = (dispatch) => ({
  validateTenant: () => dispatch(getValidateTenantFunc()),
  startIntegration: (values) => dispatch(saveDataAndRedirectToStripe(values)),
  registerTenant: (values, cameFromInvoiceId) => dispatch(registerTenant(values, cameFromInvoiceId))
})

export const connectedKivraTenant = connect(mapStateToProps, mapDispatchToProps)(KivraTenantView)
export default connect(mapStateToProps, mapDispatchToProps)(KivraTenantViewWithNavigation)
