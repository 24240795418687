import React from 'react'
import { Route } from 'react-router'

import { requireAuthentication } from 'User/Auth/higherOrderComponents/AuthComponent'

import IntegrationsView from 'Integrations/views/IntegrationsView'
import KivraOnboardingView from 'Integrations/views/KivraOnboardingView'
import KivraTenantView from 'Integrations/views/KivraTenantView'
import OpenPaymentsOnboardingView from 'Integrations/views/OpenPaymentsOnboardingView'

const moreRoutes = (
  <Route>
    <Route path='/integrations' component={requireAuthentication(IntegrationsView)} />
    <Route path='/integrations/kivra' component={requireAuthentication(KivraOnboardingView)} />
    <Route path='/integrations/kivra-tenant' component={requireAuthentication(KivraTenantView)} />
    <Route path='/integrations/open-payments' component={requireAuthentication(OpenPaymentsOnboardingView)} />
  </Route>
)

export default moreRoutes
