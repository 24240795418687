import { connect } from 'react-redux'

import Smartphones from './containers/Smartphones'
import Tablets from './containers/Tablets'

import { blockWelcomeModal } from 'MarketPlace/shared/actions'
import { getCurrentDeviceView } from 'shared/helpers/utils'

const LawFrameView = getCurrentDeviceView(Smartphones, Tablets)

const mapStateToProps = (state) => ({
  t: state.i18n.get('app', 'views', 'MarketPlace'),
  selectedCategory: state.marketplace.index.category
})
const mapDispatchToProps = dispatch => ({
  blockWelcomeModal: (...args) => dispatch(blockWelcomeModal(...args))
})

export default connect(mapStateToProps, mapDispatchToProps)(LawFrameView)
