import PropTypes from 'prop-types'
import React, { Component } from 'react'
import {UPLOADS_BASE} from 'shared/constants/Api'

// Wrappers
import FullPDF from 'shared/components/FullPDF/FullPDF'
import ZoomableContent from 'FileUpload/components/ZoomableContent/ZoomableContent'

class FileRenderer extends Component {
  static propTypes = {
    previewMode: PropTypes.bool,
    file: PropTypes.object,
    zoomable: PropTypes.bool
  };

  get renderedFile () {
    const { zoomable, file, previewMode } = this.props

    if (file.fullURL) {
      if (file.mime === 'application/pdf') {
        return <FullPDF previewMode={previewMode} path={file.fullURL} zoomable={zoomable} />
      } else {
        return <img src={file.fullURL} />
      }
    }

    if (file.file_mime === 'application/pdf') {
      return <FullPDF previewMode={previewMode} path={`${UPLOADS_BASE}/${file.path}/${file.file_name}`} zoomable={zoomable} />
    } else if (file.mime === 'application/pdf') { // because of FileInput's realisation TODO
      return <FullPDF previewMode={previewMode} path={file.fileBase64} zoomable={zoomable} />
    } else if (file.path) {
      return <img src={`${UPLOADS_BASE}/${file.path}/${file.file_name}`} />
    } else if (file.fileBase64) {
      return <img src={file.fileBase64} />
    }
  }

  render () {
    const {file, zoomable} = this.props

    if (!file) {
      return null
    }

    if (zoomable && file.file_mime !== 'application/pdf' && file.mime !== 'application/pdf') {
      return (
        <ZoomableContent>
          { this.renderedFile }
        </ZoomableContent>
      )
    } else {
      return this.renderedFile
    }
  }
}

export default FileRenderer
