import '../styles/index.scss?global'

import Smartphones from 'More/views/MoreCustomerInvoicesView/containers/Smartphones'
import Tablets from 'More/views/MoreCustomerInvoicesView/containers/Tablets'

import { getCurrentDeviceView } from 'shared/helpers/utils'

const MoreCustomerInvoicesView = getCurrentDeviceView(Smartphones, Tablets)

export default MoreCustomerInvoicesView
