import './styles/index.scss?global'

import PropTypes from 'prop-types'

import React, { Component } from 'react'
import { Link } from 'react-router'
import queryString from 'query-string'

import getGreyLabelOptions from 'GreyLabel/helpers/greyLabel'

class MultipleActionsButton extends Component {
  static propTypes = {
    buttons: PropTypes.arrayOf(PropTypes.object),
    disabled: PropTypes.bool,
    location: PropTypes.object
  }

  constructor (props) {
    super(props)
    this.onClick = this.onClick.bind(this)
    this.onItemClick = this.onItemClick.bind(this)
    this.state = {
      actionsAreVisible: false
    }
  }

  onClick () {
    if (!this.props.disabled) {
      this.setState({ actionsAreVisible: !this.state.actionsAreVisible })
    }
  }

  onItemClick (func) {
    this.onClick()
    if (func) func()
  }

  get actionButtons () {
    const className = 'multiple-actions-button__list__button'
    const buttonElements = this.props.buttons.map((buttonProps, index) => {
      if (buttonProps.link) {
        const { location } = this.props
        const [linkPathname, query] = buttonProps.link.split('?')
        const parsedQuery = query ? queryString.parse(`?${query}`) : {}
        return (
          <Link
            key={index}
            className={className}
            to={{ pathname: linkPathname, query: parsedQuery, state: { from: location.pathname } }}
            onClick={() => this.onItemClick()}
          >
            <b>{ buttonProps.title }</b>
          </Link>
        )
      } else if (buttonProps.nativeLink) {
        return (
          <a key={index} href={buttonProps.nativeLink} className={className} onClick={() => this.onItemClick(buttonProps.onClick)}>
            <b>{ buttonProps.title }</b>
          </a>
        )
      } else {
        return (
          <div key={index} className={className} onClick={() => this.onItemClick(buttonProps.onClick)}>
            <b>{ buttonProps.title }</b>
          </div>
        )
      }
    })

    return (
      <div className='multiple-actions-button__list'>
        { buttonElements }
      </div>
    )
  }

  render () {
    const additionalClasses = `multipleActions--${getGreyLabelOptions().greyLabel}`
    const className = `multiple-actions-button ${this.props.disabled ? 'disabled' : ''}
      ${this.state.actionsAreVisible ? 'multiple-actions-button--active' : ''} ${additionalClasses}`
    return (
      <div className={className}>
        <span className='icon-more' onClick={this.onClick} />
        { this.actionButtons }
      </div>
    )
  }
}

export default MultipleActionsButton
