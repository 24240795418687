import React from 'react'
import PropTypes from 'prop-types'

import './ReportComment.scss?global'

export const ReportComment = ({ content, hide }) =>
  <div className='report-comment' onClick={hide}>
    {content}
  </div>

ReportComment.propTypes = {
  content: PropTypes.string,
  hide: PropTypes.func
}
