import './WizardAdministrator.scss?global'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { routerActions } from 'react-router-redux'
import { Link } from 'react-router'
import cs from 'classnames'
import { get } from 'lodash'

import getGreyLabelOptions from 'GreyLabel/helpers/greyLabel'
import {addHashForIos} from 'shared/helpers/utils'

import { connect } from 'react-redux'

import {getPriceAction, requestCallAction, setInfoAction, updateNewPriceAction} from 'Wizard/actions/wizard'

import Navigation from 'Navigation/Navigation'
import InputSwitcher from 'shared/components/InputSwitcher/InputSwitcher'
import Input from 'shared/components/Input/Input'
import Button from 'shared/components/Button/Button'
import FormatNumber from 'shared/components/FormatNumber/FormatNumber'

export class WizardAdministrator extends Component {
  static propTypes = {
    price: PropTypes.object,
    getPrice: PropTypes.func,
    redirectToTerms: PropTypes.func,
    accountingCompanyId: PropTypes.string,
    requestCall: PropTypes.func,
    afterCallTextShow: PropTypes.bool,
    t: PropTypes.func,
    setInfo: PropTypes.func,
    t_button: PropTypes.func,
    location: PropTypes.object,
    updateNewPrice: PropTypes.func
  };

  constructor (props) {
    super(props)
    this.state = {showPhone: false, menu: 1, phoneNumber: ''}
    this.buttonHandler = this.buttonHandler.bind(this)
  }

  componentDidMount () {
    const {accountingCompanyId} = this.props
    this.props.getPrice(accountingCompanyId)
  }

  componentWillUpdate (props) {
    const {setInfo} = this.props
    if (props.afterCallTextShow === true) {
      setTimeout(function () {
        setInfo({afterCallTextShow: false})
      }, 7000)
    }
  }

  buttonHandler () {
    if (!this.state.showPhone) {
      return this.props.redirectToTerms()
    } else {
      const {requestCall, updateNewPrice} = this.props
      requestCall()
      updateNewPrice({ requestCall: true }, true)
      this.setState({showPhone: false, showArrow: false, hidePhoneBlock: true})
    }
  }

  createMarkupDescription () {
    const {price} = this.props
    return {__html: price.contract}
  }

  saveCurrentStep () {
    addHashForIos()
    this.props.setInfo({currentStep: this.props.location.pathname})
  }

  get navigateActions () {
    return [{icon: 'icon-folding', path: '/?skipWizard=true', onClick: this.saveCurrentStep.bind(this)}]
  }

  render () {
    const {price, afterCallTextShow, t, t_button} = this.props

    const ringButtonClasses = cs(
      'wizard-administrator__top-save',
      !afterCallTextShow && this.state.phoneNumber.length && 'wizard-administrator__top-save--visible'
    )

    const accountingPrice = get(price, 'company.packet.annual_accounting_price', null)
    const incomeDeclaration = get(price, 'company.packet.income_declaration_price', null)
    const annualReport = get(price, 'company.packet.annual_report_price', null)
    const { whiteLabel } = getGreyLabelOptions()
    return (
      <Navigation navigationWithMaxWidth className={cs('wizard', 'wizard-administrator', whiteLabel)} title={t('title').s} actions={this.navigateActions} hideFooter backLink='/wizard/offers' forceBackLink notBottomSpace>
        <div className='wizard-administrator__logo'>
          {price.company.logo_href && (<img src={price.company.logo_href} />)}
        </div>
        <div className='wizard-administrator__title'>{price.company.name}</div>
        <div className='wizard-administrator__description'>
          { price.description }
        </div>
        <div className='wizard-administrator__text'>
          {(price.company.packet && price.company.packet.total_price) ? t('price').replace('%price%', price.company.packet.total_price) : t('uponRequest').s}
        </div>
        {!this.state.hidePhoneBlock && (<div className='wizard-administrator__call-switcher'>
          <div><InputSwitcher dark value={this.state.showPhone} onChange={(val) => this.setState({showPhone: val, showArrow: false})} /></div>
          <div>{t('callingText').s}</div>
        </div>)}
        {this.state.showPhone && (<div className='wizard-administrator__phone'>
          <Input placeholder={t('phoneNumber').s} type='tel' ref='phone' value={this.state.phoneNumber} onChange={(e) => this.setState({phoneNumber: e.target.value})} onFocus={() => this.setState({showArrow: true})} />
        </div>)}
        <div className={ringButtonClasses}>
          <div className={`wizard-administrator__phone-send ${this.state.showArrow ? 'wizard-administrator__phone-send--active' : ''}`}>
            <span className='icon-check-mark' />
          </div>
          <Button view='dark' className='wizard-administrator__button' onClick={this.buttonHandler}>{this.state.showPhone ? t_button('callMe').s : t_button('selectOffer').s}</Button>
        </div>
        {afterCallTextShow && <div className='wizard-administrator__top-call-text'>{t('afterRequestCallText').s}</div>}
        <div className='wizard-administrator__content'>
          <div>
            <div className='wizard-administrator__menu'>
              <div className={this.state.menu === 1 ? 'wizard-administrator__menu__active' : ''} onClick={() => this.setState({menu: 1})}>{t('menu', 'includes').s}</div>
              <div className={this.state.menu === 2 ? 'wizard-administrator__menu__active' : ''} onClick={() => this.setState({menu: 2})}>{t('menu', 'additional').s}</div>
              <div className={this.state.menu === 3 ? 'wizard-administrator__menu__active' : ''} onClick={() => this.setState({menu: 3})}>{t('menu', 'terms').s}</div>
            </div>
            {this.state.menu === 1 && (<div className='wizard-administrator__contract'>
              <ul>
                <li>{t('includedOptions', 'verifications').replace('%number%', price.company.packet && price.company.packet.amount)}</li>
                <li>{t('includedOptions', 'bookkeeping').s}</li>
                <li>{t('includedOptions', 'payroll').s}</li>
                <li>{t('includedOptions', 'incomeStatement').s}</li>
                <li>{t('includedOptions', 'taxStatement').s}</li>
                <li>{t('includedOptions', 'reminders').s}</li>
              </ul>
            </div>)}
            {this.state.menu === 2 && (
              <div>
                <div className='wizard-administrator__list'>
                  <div className='wizard-administrator__list__title'>
                    <div>{t('standardOptions').s}</div>
                    <div>{t('pricePerItem').s}</div>
                  </div>
                  <div className='wizard-administrator__list__list'>
                    <div>
                      <div>{t('annualAccounting').s}</div>
                      <div className='wizard-administrator__list__price'>
                        <div>{accountingPrice !== null ? <span><FormatNumber value={accountingPrice} withSymbol /></span> : t('uponRequestShort').s}</div>
                        <div>{accountingPrice !== null ? t('plusVat').s : ''}</div>
                      </div>
                    </div>
                    <div>
                      <div>{t('incomeDeclaration').s}</div>
                      <div className='wizard-administrator__list__price'>
                        <div>{incomeDeclaration !== null ? <span><FormatNumber value={incomeDeclaration} withSymbol /></span> : t('uponRequestShort').s}</div>
                        <div>{incomeDeclaration !== null ? t('plusVat').s : ''}</div>
                      </div>
                    </div>
                    <div>
                      <div>{t('annualReport').s}</div>
                      <div className='wizard-administrator__list__price'>
                        <div>{annualReport !== null ? <span><FormatNumber value={annualReport} withSymbol /></span> : t('uponRequestShort').s}</div>
                        <div>{annualReport !== null ? t('plusVat').s : ''}</div>
                      </div>
                    </div>
                    {price.standard_options && price.standard_options.map(function (option, index) {
                      return (
                        <div key={index}>
                          <div>{option.name}</div>
                          <div className='wizard-administrator__list__price'>
                            <div>{option.price !== null ? <span><FormatNumber value={option.price} withSymbol /></span> : t('uponRequestShort').s}</div>
                            <div>{option.price !== null ? t('plusVat').s : ''}</div>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
                {
                  price.additional_options.length && (
                    <div className='wizard-administrator__list'>
                      <div className='wizard-administrator__list__title'>
                        <div>{t('additionalOptions').s}</div>
                        <div>{t('pricePerItem').s}</div>
                      </div>
                      <div className='wizard-administrator__list__list'>
                        {price.additional_options.map(function (option, index) {
                          return (
                            <div key={index}>
                              <div>{option.name}</div>
                              <div className='wizard-administrator__list__price'>
                                <div><FormatNumber value={option.price} withSymbol /></div>
                                <div>{t('plusVat').s}</div>
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  )
                }
              </div>)}
            {this.state.menu === 3 && (<div className='wizard-administrator__contract' dangerouslySetInnerHTML={this.createMarkupDescription()} />)}
            <div className='wizard-administrator__bottom-save'>
              <Link to='/wizard/terms'>
                <Button view='white'>{t_button('startCooperate').s }</Button>
              </Link>
            </div>
          </div>
        </div>
      </Navigation>
    )
  }
}

function mapStateToProps (state) {
  return {
    verifications: state.wizard.newPrice.monthlyClientInvoices + state.wizard.newPrice.monthlySupplierInvoices + state.wizard.newPrice.monthlyReceipts,
    accountingCompanyId: state.wizard.accountingCompanyId,
    price: state.wizard.currentPrice,
    afterCallTextShow: state.wizard.afterCallTextShow,
    t: state.i18n.get('app', 'views', 'Wizard', 'Administrator'),
    t_button: state.i18n.get('app', 'shared', 'buttons')
  }
}

function mapDispatchToProps (dispatch) {
  return {
    updateNewPrice: (...props) => { dispatch(updateNewPriceAction(...props)) },
    getPrice: (name) => { dispatch(getPriceAction(name)) },
    requestCall: (...props) => { dispatch(requestCallAction(...props)) },
    setInfo: (data) => { dispatch(setInfoAction(data)) },
    redirectToTerms: () => { dispatch(routerActions.push('/wizard/terms')) }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WizardAdministrator)
