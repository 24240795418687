exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".GcP0qIMNcNJzaiqb8coH0 {\n  display: flex;\n  justify-content: space-between;\n}\n\n", "", {"version":3,"sources":["/home/circleci/mobile_ui/src/Reports/views/AnnualReport/AnnualReportsView/templates/ListItem/ListItem.scss"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,+BAA+B;CAChC","file":"ListItem.scss","sourcesContent":[".listItem {\n  display: flex;\n  justify-content: space-between;\n}\n\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"listItem": "GcP0qIMNcNJzaiqb8coH0"
};