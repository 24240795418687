import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { reduxForm, Field } from 'redux-form'

// Components
import Navigation from 'Navigation/Navigation'
import Input from 'shared/components/FormInput'
import Button from 'shared/components/Button/Button'
import DropDown from 'shared/components/FormDropdown'

import { getTrimmedLink } from 'shared/helpers/linkUtils'

// Actions
import { approveTaxPaymentAction } from 'Reports/actions/report'

export class TaxPaymentApproveForm extends Component {
  static propTypes = {
    params: PropTypes.object,
    location: PropTypes.object,
    approve: PropTypes.func,
    paymentMethods: PropTypes.arrayOf(PropTypes.object),
    handleSubmit: PropTypes.func,
    t: PropTypes.func
  }

  constructor (props) {
    super(props)
    this.approveTaxReport = this.approveTaxReport.bind(this)
  }

  approveTaxReport (values) {
    const { params: { taxPaymentReportId }, location: { pathname }, approve } = this.props
    approve(taxPaymentReportId, values, getTrimmedLink(pathname, 1))
  }

  render () {
    const { paymentMethods, t, handleSubmit } = this.props
    return (
      <div className='f-column'>
        <div className='form f-column-top'>
          <div className='form__item'>
            <Field
              name='paymentDate'
              component={Input}
              placeholder={t('fields', 'paymentDate', 'title').s}
              type='date'
            />
          </div>
          <div className='form__item'>
            <Field
              name='paymentMethodId'
              component={DropDown}
              big
              emptyOption={t('fields', 'paymentMethod', 'title').s}
              options={paymentMethods}
              hint={t('fields', 'paymentMethod', 'title').s}
            />
          </div>
        </div>
        <div className='f-column-bottom form-buttons'>
          <Button
            saveButton
            view='transparent-black'
            onClick={handleSubmit(this.approveTaxReport)}
          >
            {t('approve').s}
          </Button>
        </div>
      </div>
    )
  }
}

class TaxPaymentApproveFormNavigationWrap extends Component {
  static propTypes = {
    t: PropTypes.func
  }
  render () {
    const { t } = this.props
    return (
      <Navigation hideFooter title={t('title').s} className='tax-payment-approve f-column'>
        <TaxPaymentApproveForm {...this.props} />
      </Navigation>
    )
  }
}

const formName = 'tax-report-approve-form'
function mapStateToProps (state) {
  return {
    paymentMethods: state.userSettings.expensePaymentMethods,
    t: state.i18n.get('app', 'views', 'Reports', 'TaxPaymentReport', 'TaxPaymentApproveForm')
  }
}

function mapDispatchToProps (dispatch) {
  return {
    approve: (reportId, data) => {
      dispatch(approveTaxPaymentAction(reportId, data))
    }
  }
}

function validate (values) {
  let errors = {}
  if (!values.paymentDate) {
    errors.paymentDate = true
  }
  if (!values.paymentMethodId) {
    errors.paymentMethodId = true
  }

  return errors
}

export const TaxPaymentApproveFormView = connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: formName,
    validate
  })(TaxPaymentApproveForm))

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: formName,
    validate
  })(TaxPaymentApproveFormNavigationWrap))
