import PropTypes from 'prop-types'
import React, { Component } from 'react'
import {connect} from 'react-redux'
import { getFormValues } from 'redux-form'
import { get, isEqual } from 'lodash'

// Components
import Navigation from 'Navigation/Navigation'
import Filters, { initialFilterValues } from 'Projects/containers/Filter/Filter'
import ListView from 'shared/components/ListView/ListView'
import {ProjectsListItem} from 'Projects/containers/ListViewWrapper/ListViewWrapper'

// Actions
import { extendProjectsListAction } from 'Projects/actions/projectsList'
import { projectInfoClearAction } from 'Projects/actions/project'

class ProjectsView extends Component {
  static propTypes = {
    filters: PropTypes.object,
    loadMore: PropTypes.func,
    clearProjectInfo: PropTypes.func,
    projects: PropTypes.arrayOf(PropTypes.object),
    hasNextPage: PropTypes.bool,
    lastPageLoaded: PropTypes.number,
    t: PropTypes.func
  }

  constructor (props) {
    super(props)
    this.extendList = this.extendList.bind(this)
  }

  extendList () {
    const {hasNextPage, lastPageLoaded, projects} = this.props
    if (hasNextPage) {
      this.props.loadMore(projects, this.props.filters, lastPageLoaded + 1)
    }
  }

  componentDidMount () {
    this.props.loadMore([], this.props.filters, 1)
  }

  componentDidUpdate (prevProps) {
    const { filters } = this.props
    if (prevProps.filters && !isEqual(prevProps.filters, filters)) {
      this.props.loadMore([], filters, 1)
    }
  }

  render () {
    const {t, projects, filters, clearProjectInfo} = this.props
    const actions = [
      { path: '/projects/new', icon: 'icon-add', onClick: clearProjectInfo }
    ]
    return (
      <Navigation title={t('navbar', 'title').s} backLink='/more' forceBackLink actions={actions} filterBlock infinityScrollHandler={this.extendList}>
        <Filters ref='filters' canBeHidden={!projects.length} />
        <ListView data={projects} template={ProjectsListItem} emptyListOptions={{icon: 'icon-tags', showNotFound: Boolean(get(filters, ['search', 'value', 'length']))}} />
      </Navigation>
    )
  }
}

function mapStateToProps (state) {
  return {
    projects: state.projects.projects,
    lastPageLoaded: state.projects.lastPageLoaded,
    hasNextPage: state.projects.hasNextPage,
    filters: getFormValues('projectsViewFilters')(state) || initialFilterValues,
    t: state.i18n.get('app', 'views', 'ProjectsView')
  }
}

function mapDispatchToProps (dispatch) {
  return {
    loadMore: (list, filters, pageToLoad) => dispatch(extendProjectsListAction(list, filters, pageToLoad)),
    clearProjectInfo: () => { dispatch(projectInfoClearAction()) }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectsView)
