import URI from 'urijs'

import {API_BASE} from 'shared/constants/Api'

// Forecast
export const OVERVIEW_REVENUES_LIST = ({from, to, excludeVat, transactionStatus}) => { return URI(`${API_BASE}/api/v2/financial-presenter/forecast/aggregate/details/revenue/`).addSearch({ from, to, exclude_vat: excludeVat, transaction_status: transactionStatus }) }
export const OVERVIEW_EXPENSE_LIST = ({from, to, excludeVat, transactionStatus}) => { return URI(`${API_BASE}/api/v2/financial-presenter/forecast/aggregate/details/expenses/`).addSearch({ from, to, exclude_vat: excludeVat, transaction_status: transactionStatus }) }
export const FORECAST_MONTHLY_AGGREGATE = `${API_BASE}/api/v2/financial-presenter/forecast/aggregate/monthly/`
export const FORECAST_YEARLY_AGGREGATE = `${API_BASE}/api/v2/financial-presenter/forecast/aggregate/annual/`

// Result
export const RESULT_MONTHLY_AGGREGATE = `${API_BASE}/api/v2/financial-presenter/result/aggregate/monthly/`
export const RESULT_CASH_BANK_AGGREGATE = `${API_BASE}/api/v2/financial-presenter/result/aggregate/monthly_cash_assets/`
// export const RESULT_YEARLY_AGGREGATE = `${API_BASE}/api/v2/financial-presenter/result/aggregate/annual/`
export const RESULT_AMOUNTS_AGGREGATE = `${API_BASE}/api/v2/financial-presenter/result/aggregate/amounts/`
export const RESULT_EXPENSES_LIST = `${API_BASE}/api/v2/financial-presenter/result/aggregate/expenses_by_account_number/`
export const RESULT_REVENUE_LIST = `${API_BASE}/api/v2/financial-presenter/result/aggregate/revenue_by_account_number/`

export const OVERVIEW_FISCAL_YEARS = `${API_BASE}/api/v2/financial-presenter/fiscal_years/`

export const LOAN_INFO = `${API_BASE}/api/v2/marketplace/capcito/loan_info/`
export const CLIENT_SUMMARY = `${API_BASE}/api/v2/marketplace/collector_bank/factoring/client_summary/`

export const PNL_REPORT = `${API_BASE}/api/v2/financial-presenter/pnl_reports/`
export const BALANCE_REPORT = `${API_BASE}/api/v2/financial-presenter/balance_reports/`
export const VAT_REPORT = `${API_BASE}/api/v2/financial-presenter/vat_reports/`
