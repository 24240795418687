import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { routerActions } from 'react-router-redux'

import IpadNavigation from 'Navigation/IpadNavigation'
import {TransferView} from 'Events/BankTransfer/views/BankTransferFormView/containers/Smartphones'

import getLeftComponent from 'Navigation/helpers/linkAccordance'

import { clearNewEventAction } from 'Events/shared/actions/event'

export class Transfer extends Component {
  static propTypes = {
    previousLink: PropTypes.string,
    event: PropTypes.object,
    clearEvent: PropTypes.func,
    pageTitle: PropTypes.string
  }

  render () {
    const {previousLink, event, pageTitle, clearEvent} = this.props
    const Events = getLeftComponent('event', previousLink)
    const backLink = event.id ? `/events/${event.id}` : '/'

    return (
      <IpadNavigation {...this.props} title={pageTitle} backLink={backLink} onClickBack={clearEvent}>
        <Events {...this.props} />
        <TransferView {...this.props} />
      </IpadNavigation>
    )
  }
}

function mapDispatchToProps (dispatch) {
  return {
    clearEvent: () => { dispatch([clearNewEventAction(), routerActions.goBack()]) }
  }
}

function mapStateToProps (state) {
  return {
    event: state.events.newEvent,
    previousLink: state.appInfo.previousLink,
    t: state.i18n.get('app', 'views', 'ExpenseView'),
    pageTitle: state.i18n.get('app', 'views', 'BankTransferView', 'navbar', 'title').s
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Transfer)
