import './WizardTerms.scss?global'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import cs from 'classnames'

import Navigation from 'Navigation/Navigation'
import Button from 'shared/components/Button/Button'
import InputSwitcher from 'shared/components/InputSwitcher/InputSwitcher'

import {saveNewPriceAction, setInfoAction} from 'Wizard/actions/wizard'

import {addHashForIos} from 'shared/helpers/utils'
import getGreyLabelOptions from 'GreyLabel/helpers/greyLabel'

export class WizardTerms extends Component {
  static propTypes = {
    saveNewPrice: PropTypes.func,
    t: PropTypes.func,
    t_button: PropTypes.func,
    setInfo: PropTypes.func,
    location: PropTypes.object
  };

  constructor (props) {
    super(props)
    this.buttonHandler = this.buttonHandler.bind(this)
  }

  componentWillMount () {
    this.setState({termsAgree: false})
  }

  buttonHandler () {
    if (this.state.termsAgree) {
      this.props.saveNewPrice()
    }
  }

  saveCurrentStep () {
    addHashForIos()
    this.props.setInfo({currentStep: this.props.location.pathname})
  }

  get navigateActions () {
    return [{icon: 'icon-folding', path: '/?skipWizard=true', onClick: this.saveCurrentStep.bind(this)}]
  }

  render () {
    const {t, t_button} = this.props
    const { whiteLabel } = getGreyLabelOptions()
    return (
      <Navigation navigationWithMaxWidth className={cs('wizard', 'wizard-terms', whiteLabel, 'f-column')} title={t('title').s} actions={this.navigateActions} hideFooter backLink='/wizard/administrator' forceBackLink>
        <div className='f-column-top'>
          <div className='wizard-terms__title'>
            <p>{t('selectText').s}</p>
          </div>
          <div className='wizard-terms__list'>
            <div>
              <div><span className='wizard-terms__list__round'>1</span></div>
              <div>{t('step1').s}</div>
            </div>
            <div>
              <div><span className='wizard-terms__list__round'>2</span></div>
              <div>{t('step2').s}</div>
            </div>
            <div>
              <div><span className='wizard-terms__list__round'>3</span></div>
              <div>{t('step3').s}</div>
            </div>
          </div>
          {false && (<div className='wizard-terms__condition'>Se alla villkor</div>)}
          <div className='wizard-terms-info__select'>
            <div><InputSwitcher dark value={this.state.termsAgree} onChange={(val) => this.setState({termsAgree: val})} /></div>
            <div>{t('agreement').s}</div>
          </div>
        </div>
        <div className='wizard-terms__save f-column-bottom'>
          <Button
            className='wizard-terms__save__button'
            disabled={!this.state.termsAgree}
            onClick={this.buttonHandler}
            view='transparent-black'
          >
            {t_button('startNow').s}
          </Button>
        </div>
      </Navigation>
    )
  }
}

function mapStateToProps (state) {
  return {
    t: state.i18n.get('app', 'views', 'Wizard', 'Terms'),
    t_button: state.i18n.get('app', 'shared', 'buttons')
  }
}

function mapDispatchToProps (dispatch) {
  return {
    saveNewPrice: () => { dispatch(saveNewPriceAction()) },
    setInfo: (data) => { dispatch(setInfoAction(data)) }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WizardTerms)
