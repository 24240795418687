import { UPDATE_TODO_ITEMS, UPDATE_UNREAD_COLLECTOR_INVOICES_IDS } from '../constants/ActionTypes'

const initialState = {
  items: [],
  unreadCollectorInvoicesIds: []
}

export default function (state = initialState, action) {
  switch (action.type) {
    case UPDATE_TODO_ITEMS:
      return {
        ...state,
        items: action.payload
      }
    case UPDATE_UNREAD_COLLECTOR_INVOICES_IDS:
      return {
        ...state,
        unreadCollectorInvoicesIds: action.payload
      }
    default:
      return state
  }
}
