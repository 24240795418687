import { createAction } from 'redux-act'
import axios from 'api-client-connector'
import queryString from 'query-string'
import { getCurrentClientCompany } from 'api-client-connector/utils'

import { API_BASE } from 'shared/constants/Api'

export const collectorFieldsToStore = createAction('Collector validated fields to store')
export const collectorOnboardingStatus = createAction('Collector onboarding status to store')

export const blockWelcomeModal = createAction('Block welcome modal')

export async function redirectToStripe () {
  const addSaveCardStatusQuery = (status) => {
    let params = queryString.parse(window.location.search)
    params.save_card_success = status
    return `?${queryString.stringify(params)}`
  }

  const { data } = await axios({
    method: 'post',
    url: `${API_BASE}/api/v2/billing/client-company/session`,
    data: {
      client_company_id: Number(getCurrentClientCompany()),
      success_url: window.location.href.split('?')[0] + addSaveCardStatusQuery(true),
      cancel_url: window.location.href.split('?')[0] + addSaveCardStatusQuery(false),
      setup_payment_session: true
    }
  })
  const sessionId = data.checkout_session
  const stripe = window.Stripe(data.public_key)

  stripe.redirectToCheckout({
    sessionId: sessionId
  }) // TODO: trigger error modal on fail
}
