import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { reduxForm, Field } from 'redux-form'

import Input from 'shared/components/Input/Input'
import Dropdown from 'shared/components/Dropdown/Dropdown'
import Checkbox from 'shared/components/Checkbox/Checkbox'
import Button from 'shared/components/Button/Button'

import { personalNumberMaskProps } from 'mrshoebox-ui-components/src/helpers/countrySpecific'

import './styles.scss?global'

class EmployeeForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    save: PropTypes.func.isRequired,
    params: PropTypes.object.isRequired,
    goToBenefits: PropTypes.func.isRequired
  }

  render () {
    const { t, handleSubmit, save, params: { employeeId }, goToBenefits } = this.props
    return (
      <div className='form f-column-top employee-form'>
        <div className='employee-form__section'>
          <h3>{t('personalInfo').s}</h3>
          <div className='form__item'>
            <Field
              name='firstName'
              component={FormInput}
              placeholder={t('fields', 'firstName').s}
            />
          </div>
          <div className='form__item'>
            <Field
              name='lastName'
              component={FormInput}
              placeholder={t('fields', 'lastName').s}
            />
          </div>
          <div className='form__item'>
            <Field
              name='personalNumber'
              component={FormInput}
              placeholder={t('fields', 'personalNumber').s}
              normalize={personalNumberMaskProps.normalize}
            />
          </div>
          <div className='form__item'>
            <Field
              name='telephoneNumber'
              component={FormInput}
              placeholder={t('fields', 'telephoneNumber').s}
            />
          </div>
          <div className='form__item'>
            <Field
              name='email'
              component={FormInput}
              placeholder={t('fields', 'email').s}
            />
          </div>
          <div className='form__item'>
            <Field
              name='addressLine1'
              component={FormInput}
              placeholder={t('fields', 'addressLine1').s}
            />
          </div>
          <div className='employee-form__row'>
            <Field
              name='areaCode'
              component={FormInput}
              placeholder={t('fields', 'areaCode').s}
            />
            <Field
              name='postalNumber'
              component={FormInput}
              placeholder={t('fields', 'postalNumber').s}
            />
          </div>
        </div>
        <div className='employee-form__row employee-form__section'>
          <h3>{t('church').s}</h3>
          <Field
            name='churchTaxApplicable'
            component={FormCheckbox}
          />
        </div>
        <div className='employee-form__section'>
          <h3>{t('fields', 'taxTable').s}</h3>
          <div className='form__item'>
            <Field
              name='skattetabell'
              component={FormInput}
              placeholder={t('fields', 'taxTable').s}
              type='number'
            />
          </div>
          <div className='form__item'>
            <Field
              name='skattalalbelKolumn'
              component={FormInput}
              placeholder={t('fields', 'column').s}
              type='number'
            />
          </div>
        </div>
        <div className='employee-form__section'>
          <h3>{t('fields', 'employerContribution').s}</h3>
          <div className='form__item'>
            <Field
              name='employerContribution'
              component={FormDropdown}
              hint={t('fields', 'employerContribution').s}
              big
              emptyOption={t('fields', 'employerContribution').s}
              options={[
                { label: '0', value: '0' },
                { label: '10.21', value: '10.21' },
                { label: '31.42', value: '31.42' }
              ]}
              className='employee-form__employer-contribution'
            />
          </div>
        </div>
        <div className='f-column-bottom form-buttons'>
          <Button view='transparent-black' onClick={() => goToBenefits(employeeId)}>
            {t('buttons', 'benefits').s}
          </Button>
          <Button view='transparent-black' saveButton onClick={handleSubmit((values) => save(values, employeeId))}>
            {employeeId !== 'new' ? t('buttons', 'update').s : t('buttons', 'save').s}
          </Button>
        </div>
      </div>
    )
  }
}

const FormInput = ({ input, meta, ...props }) =>
  <Input {...input} {...meta} {...props} />

FormInput.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object
}

const FormCheckbox = ({ input, meta, ...props }) =>
  <Checkbox {...input} {...meta} {...props} />

FormCheckbox.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object
}

const FormDropdown = ({ input, meta, ...props }) =>
  <Dropdown {...input} {...meta} {...props} />

FormDropdown.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object
}

const validate = values => {
  let errors = {}

  if (!values.skattetabell) {
    errors.skattetabell = true
  }

  if (!values.skattalalbelKolumn) {
    errors.skattalalbelKolumn = true
  }

  if (Number(values.skattetabell) < 29 || Number(values.skattetabell) > 40) {
    errors.skattetabell = 'Should be greater than or equal to 29 or less than or equal to 40'
  }

  if (Number(values.skattalalbelKolumn) < 1 || Number(values.skattalalbelKolumn) > 6) {
    errors.skattalalbelKolumn = 'Should be greater than or equal to 1 and less than or equal to 6'
  }

  return errors
}

export default reduxForm({
  form: 'employee',
  validate,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  destroyOnUnmount: false,
  onSubmitSuccess: (result, dispatch, props) => { props.clearEmployee(); props.destroy() }
})(EmployeeForm)
