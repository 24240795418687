import { createAction } from 'redux-actions'
import axios from 'api-client-connector'
import { camelizeKeys } from 'humps'

import { CLIENT_OVERVIEW_LOAD } from '../constants/ActionTypes'
import { CLIENT_SUMMARY } from '../constants/Api'

import { hideNavbarSpinnerAction, showSlowNavbarSpinnerAction } from 'shared/actions/navbarSpinner'
import { showMessageBoxWithParamsAction } from 'MessageBox/actions/messageBox'

const clientOverviewToStore = createAction(CLIENT_OVERVIEW_LOAD)

export function loadClientOverviewInfo () {
  return async (dispatch, getState) => {
    dispatch(showSlowNavbarSpinnerAction())
    try {
      const response = await axios.get(CLIENT_SUMMARY)
      const clientSummary = prepareClientSummary(camelizeKeys(response.data))
      dispatch([
        hideNavbarSpinnerAction(),
        clientOverviewToStore(clientSummary)
      ])
    } catch (e) {
      console.log('error', e)
      const t = getState().i18n.get('app', 'shared', 'messages')
      dispatch(hideNavbarSpinnerAction())
      dispatch(showMessageBoxWithParamsAction(null, t('unexpectedError', 'description').s))
    }
  }
}

function prepareClientSummary (data) {
  return {
    economy: {
      usedLimit: data.financedAmount,
      unusedLimit: data.buyLimitAmount - data.financedAmount,
      debtAmount: data.debtAmount,
      totalLimit: data.buyLimitAmount
    },
    invoices: {
      sold: data.buyAmount,
      soldWithRegress: data.recourseAmount,
      repurchased: data.recoursedAmount,
      service: data.serviceAmount,
      activeCreditInvoices: data.creditedInvoicesAmount,
      activeInvoices: data.invoicesAmount
    },
    invoicesStatuses: {
      notOverdue: data.invoicesAmount,
      due: data.expiredInvoicesAmount,
      reminder: data.expiredInvoicesWithRemindersSentAmount,
      debtCollection: data.debtCollectionAmount
    }
  }
}
