exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._16FZbj7sJbjfv3gsfTYShL {\n  display: flex;\n  align-items: center;\n}\n\n._2XSH_t7dCFYVYRSvM6gRIC {\n  width: 6px;\n  height: 6px;\n  border-radius: 100%;\n  background-color: #0d3a52;\n  margin-right: 0.5rem;\n  animation-name: vUXwO5Y_q4R2RAs4lnvLG;\n  animation-duration: 2s;\n  animation-iteration-count: infinite;\n}\n\n.z5GaZxXUXU_pAhegXZ-qY {\n}\n\n._2terEUP_ZtBbO9vdRRjIuq {\n  animation-delay: .4s;\n}\n\n._3y6l6V0xBlzXEachjYxzlr {\n  animation-delay: .8s;\n}\n\n.vlSbF7Na7uvMTEA_luXFn {\n  animation-delay: 1.2s;\n}\n\n._1mW5oLORBLbV69ay1wHalu {\n  animation-delay: 1.6s;\n}\n\n@keyframes vUXwO5Y_q4R2RAs4lnvLG {\n  0% {\n    background-color: #00ceff;\n  }\n\n  70%, 100% {\n    background-color: #0d3a52;\n  }\n}\n\n", "", {"version":3,"sources":["/home/circleci/mobile_ui/src/shared/components/DotsSpinner/styles.scss"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,oBAAoB;CACrB;;AAED;EACE,WAAW;EACX,YAAY;EACZ,oBAAoB;EACpB,0BAA0B;EAC1B,qBAAqB;EACrB,sCAA6B;EAC7B,uBAAuB;EACvB,oCAAoC;CACrC;;AAED;CAEC;;AAED;EAEE,qBAAqB;CACtB;;AAED;EAEE,qBAAqB;CACtB;;AAED;EAEE,sBAAsB;CACvB;;AAED;EAEE,sBAAsB;CACvB;;AAED;EACE;IACE,0BAA0B;GAC3B;;EAED;IACE,0BAA0B;GAC3B;CACF","file":"styles.scss","sourcesContent":[".container {\n  display: flex;\n  align-items: center;\n}\n\n.dot {\n  width: 6px;\n  height: 6px;\n  border-radius: 100%;\n  background-color: #0d3a52;\n  margin-right: 0.5rem;\n  animation-name: change-color;\n  animation-duration: 2s;\n  animation-iteration-count: infinite;\n}\n\n.dot1 {\n  composes: dot;\n}\n\n.dot2 {\n  composes: dot;\n  animation-delay: .4s;\n}\n\n.dot3 {\n  composes: dot;\n  animation-delay: .8s;\n}\n\n.dot4 {\n  composes: dot;\n  animation-delay: 1.2s;\n}\n\n.dot5 {\n  composes: dot;\n  animation-delay: 1.6s;\n}\n\n@keyframes change-color {\n  0% {\n    background-color: #00ceff;\n  }\n\n  70%, 100% {\n    background-color: #0d3a52;\n  }\n}\n\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"container": "_16FZbj7sJbjfv3gsfTYShL",
	"dot": "_2XSH_t7dCFYVYRSvM6gRIC",
	"change-color": "vUXwO5Y_q4R2RAs4lnvLG",
	"dot1": "z5GaZxXUXU_pAhegXZ-qY _2XSH_t7dCFYVYRSvM6gRIC",
	"dot2": "_2terEUP_ZtBbO9vdRRjIuq _2XSH_t7dCFYVYRSvM6gRIC",
	"dot3": "_3y6l6V0xBlzXEachjYxzlr _2XSH_t7dCFYVYRSvM6gRIC",
	"dot4": "vlSbF7Na7uvMTEA_luXFn _2XSH_t7dCFYVYRSvM6gRIC",
	"dot5": "_1mW5oLORBLbV69ay1wHalu _2XSH_t7dCFYVYRSvM6gRIC"
};