import './CompanySettingsView.scss?global'

import Smartphones from 'User/Settings/views/CompanySettingsView/containers/Smartphones'
import Tablets from 'User/Settings/views/CompanySettingsView/containers/Tablets'

import {getCurrentDeviceView} from 'shared/helpers/utils'

const CompanySettingsView = getCurrentDeviceView(Smartphones, Tablets)

export default CompanySettingsView
