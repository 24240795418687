import React from 'react'
import { browserHistory, Link } from 'react-router'
import PropTypes from 'prop-types'
import { isIpadResolution } from '../../helpers/utils'

const LinkReplace = ({ to, ...props }) => {
  const onClick = () => browserHistory.replace(to)

  return (
    <div
      onClick={onClick}
      {...props}
    />
  )
}
LinkReplace.propTypes = {
  to: PropTypes.string
}

const ComponentForUniversalLink = isIpadResolution() ? LinkReplace : Link
export const UniversalLink = (props) => {
  return <ComponentForUniversalLink {...props} />
}

export default LinkReplace
