import { outType, moneyWithdrawal, taxPayment, inType, moneyDeposit } from 'Events/shared/constants/eventType'

export function getAmountWithVat (event) {
  if (event.eventRows.length === 0) {
    return event.amountWithVat
  } else {
    return event.eventRows.reduce((prev, row) => {
      return prev + row.amountWithVat
    }, 0)
  }
}

export function getCategory (event, SDTypeId) {
  let category
  if ([outType, moneyWithdrawal, taxPayment].find(item => item === SDTypeId)) {
    category = event.transactions[0] ? event.transactions[0].debit_account : null
  } else if ([inType, moneyDeposit].find(item => item === SDTypeId)) {
    category = event.transactions[0] ? event.transactions[0].credit_account : null
  }

  return category
}

export function getPaymentMethod (event, SDTypeId) {
  let paymentMethod

  if ([outType, moneyWithdrawal, taxPayment].find(item => item === SDTypeId)) {
    paymentMethod = event.transactions[0] ? event.transactions[0].credit_account : null
  } else if ([inType, moneyDeposit].find(item => item === SDTypeId)) {
    paymentMethod = event.transactions[0] ? event.transactions[0].debit_account : null
  }

  return paymentMethod
}

export function getVatPercentage (event) {
  let vatPercentage

  event.transactions.forEach(transaction => {
    vatPercentage = getVatPercentageForRow(transaction)
  })

  return vatPercentage
}

export function getVatPercentageForRow (transaction) {
  let vatPercentage

  if (transaction.vat && transaction.vat.percentage) {
    vatPercentage = transaction.vat.percentage
  }

  if (!vatPercentage) {
    vatPercentage = 0
  }

  if (transaction.debit_account && transaction.debit_account.vat_percentage) {
    vatPercentage = transaction.debit_account.vat_percentage
  }

  if (transaction.credit_account && transaction.credit_account.vat_percentage) {
    vatPercentage = transaction.credit_account.vat_percentage
  }
  return vatPercentage
}
