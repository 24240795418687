import axios from 'api-client-connector'
import { getCurrentClientCompany } from 'api-client-connector/utils'
import { routerActions } from 'react-router-redux'
import { find, compact, flatten } from 'lodash'
import { reset, change } from 'redux-form'
import uuidv4 from 'uuid/v4'
import { decamelizeKeys } from 'humps'

import { showSlowNavbarSpinnerAction, hideNavbarSpinnerAction } from 'shared/actions/navbarSpinner'

import { extractValuesFromState, pushPayments } from 'Payments/helpers'
import { importEventRowAction } from 'Invoices/CustomerInvoices/actions/imports'
import { forceDeleteFilesAction } from 'FileUpload/actions/filesToUpload'
import { uploadFiles } from 'FileUpload/actions/uploadToFileStorage'

import { showSuccessMessageBoxAction } from 'Events/shared/actions/successMessageBox'

import { EVENT_CREATE, EVENT_UPDATE, EVENT_EXTENDED_INFO } from 'Events/shared/constants/Api'
import { STATUS_UNPROCESSED } from 'Events/shared/constants/EventStatus'
import * as eventType from 'Events/shared/constants/eventType'
import { removeNilFields, removeNilEventRowsFields } from 'Events/shared/helpers/changeDataForRequest'
import { clearForm } from 'Events/shared/helpers/eventForm'
import { updateProjectTransaction } from 'Projects/actions/project'
import { fetchRawEvent } from './event'

export function pushEventAction (event, cameFrom, formName, scrollTopHandler) {
  return async (dispatch, getState) => {
    dispatch(showSlowNavbarSpinnerAction())
    const currentUserId = getState().userSettings.user.id
    const supplier = getState().events.newEvent.supplier
    const isNewEvent = !event.id
    let payments = null

    try {
      const preparedEvent = prepareEventData(event, isNewEvent, currentUserId, supplier)

      const eventResponse = await pushEvent(event.id, preparedEvent)

      if (event.isInvoice && event.type === eventType.outType) {
        await pushExtendedInfo(
          eventResponse.data.id,
          decamelizeKeys({
            ...event.extendedInfo,
            bgNumber: event.bgNumber ? event.bgNumber.toString() : '',
            pgNumber: event.pgNumber ? event.pgNumber.toString() : ''
          })
        )
      }

      // transform for afterEventPushActions
      const ids = {
        value: {
          id: eventResponse.data.id,
          user_document_id: eventResponse.data.user_document_id
        }
      }
      const sourceDocId = ids.value.id
      // TODO мб если payments пустой, то и так все норм будет
      if (event.type === eventType.inType || event.type === eventType.outType) {
        payments = extractValuesFromState(getState())
        await pushPayments(payments, sourceDocId, eventType.isExpense(event.type))
      }

      // newEvent.transactions is exists specially for this - for bind with projects
      const loadedEvent = await fetchRawEvent(ids.value.id)
      const initialEvent = getState().events.newEvent
      await dispatch(pushProjects(initialEvent, preparedEvent, loadedEvent.data))

      dispatch(afterEventPushActions(formName, event, payments, isNewEvent, cameFrom, ids, scrollTopHandler))
    } catch (e) {
      if (process.env.NODE_ENV === 'test') {
        // without this some tests is hard to debug(e.g. that 'expect' about response in axiosMock)
        throw e
      } else {
        dispatch(processError(e))
      }
    }
  }
}

function prepareEventData (event, isNewEvent, currentUserId, supplier) {
  const currentClientCompany = Number(getCurrentClientCompany())
  const transactions = removeNilEventRowsFields(prepareTransactions(event))

  let data = {
    title: event.title,
    type: event.type,
    document_date: event.date,
    currency_id: event.currencyId && Number(event.currencyId),
    paper_kind: event.isInvoice ? eventType.INVOICE_PAPER_KIND : eventType.RECEIPT_PAPER_KIND,
    status: STATUS_UNPROCESSED,
    employee_id: currentUserId,
    transactions,
    invoice_ocr_number: event.OCRnumber
      ? event.OCRnumber.toString()
      : event.isInvoice ? '' : null // API doesn't like empty string for receipt/other paper_kinds. But empty string is needed if user wants to clear field value for invoice.
  }

  if (event.type === eventType.transfer) {
    data = {
      ...data,
      total_amount: Number(event.amount)
    }
  }

  if (event.type === eventType.outType || event.type === eventType.inType) {
    data = {
      ...data,
      is_credit: event.invoiceType === eventType.INVOICE_TYPE_CREDIT_INVOICE
    }
  }

  if (event.type === eventType.outType) {
    data = {
      ...data,
      date_of_payment: event.paymentDate && event.paymentDate,
      representation: transformRepresentationValuesForRequest(event, event.id)
    }
  }

  if (eventType.isExpense(event.type) && event.isInvoice) {
    data = {
      ...data,
      supplier_id: supplier.id ? supplier.id : null,
      invoice_due_date: event.invoiceDueDate ? event.invoiceDueDate : null,
      invoice_number: event.invoiceNumber ? event.invoiceNumber : null,
      invoice_approved_for_payment: event.invoiceApprovedForPayment !== undefined ? event.invoiceApprovedForPayment : null
    }
  }

  if (isNewEvent) {
    data = { ...data, company: { id: currentClientCompany } }
  }

  let filteredData = removeNilFields(data)

  if (eventType.isExpense(event.type) && event.isInvoice) {
    filteredData = {
      ...filteredData,
      supplier_id: data.supplier_id,
      invoice_due_date: data.invoice_due_date,
      invoice_number: data.invoice_number
    }
  }

  return filteredData
}

export function pushEvent (eventId, eventData) {
  let response
  if (!eventId) {
    response = axios.post(EVENT_CREATE({}).toString(), eventData)
  } else {
    response = axios.patch(EVENT_UPDATE({ id: eventId }).toString(), eventData)
  }
  return response
}

function pushExtendedInfo (eventId, data) {
  return axios.put(EVENT_EXTENDED_INFO({ id: eventId }).toString(), data)
}

function prepareTransactions (event) {
  if (event.type === eventType.transfer) {
    return [{
      id: event.transactionId,
      uuid: event.transactionUUID || uuidv4(),
      amount: Number(event.amount),
      credit_account_id: event.accountFromId ? Number(event.accountFromId) : null,
      debit_account_id: event.accountToId ? Number(event.accountToId) : null
    }]
  }

  const isExpenseEvent = eventType.isExpense(event.type)
  const makeRow = (data) => {
    let debitAccountId = isExpenseEvent ? data.categoryId : data.paymentMethodId
    let creditAccountId = isExpenseEvent ? data.paymentMethodId : data.categoryId
    if (event.type === eventType.outType) {
      // bug TREP-899, for in and out type we should get account from event, not from row.
      // TODO: what about other types?
      creditAccountId = event.paymentMethodId
    }
    if (event.type === eventType.inType) {
      debitAccountId = event.paymentMethodId
    }
    return {
      id: data.id,
      uuid: data.uuid || uuidv4(),
      amount: data.amountWithVat ? Number(data.amountWithVat) : null,
      vat_amount: data.vatAmount && Number(data.vatAmount),
      vat: { percentage: data.vat && data.vat !== 'custom' ? Number(data.vat) : 0 },
      description: data.description,
      debit_account_id: debitAccountId ? Number(debitAccountId) : null,
      credit_account_id: creditAccountId ? Number(creditAccountId) : null,
      project: data.projectId ? { id: Number(data.projectId) } : {},
      projectId: data.projectId ? Number(data.projectId) : null
    }
  }

  if (!event.eventRows || event.eventRows.length === 0) {
    return [makeRow({ ...event, description: event.title, id: null, uuid: null })] // event as one transaction
  }

  return event.eventRows.map(makeRow)
}

function pushProjects (initialSd, formSd, savedSd) {
  const initialTransactions = (initialSd.transactions || []).filter(t => t.projectId)
  const formTransactions = (formSd.transactions || [])
  const savedTransactions = (savedSd.transactions || [])

  // transaction is deleted
  const deleted = initialTransactions
    .filter(t => !formTransactions.find(formT => formT.id === t.id))
    .map(t => updateProjectTransaction(Number(t.id), t, {}))

  const changedAndCreated = formTransactions.map(formT => {
    const initial = initialTransactions.find(t => t.id === formT.id)
    const saved = savedTransactions.find(t => t.uuid === formT.uuid)
    // project is changed to another project
    if (initial) {
      if (initial.projectId !== formT.projectId) {
        return updateProjectTransaction(Number(initial.id), initial, formT)
      }
    } else if (formT.projectId) { // transaction with project is created
      return updateProjectTransaction(Number(saved.id), {}, formT)
    }
  })

  return [...deleted, ...compact(flatten(changedAndCreated))]
}

export function updateFilesInEvent (formName, event, cameFrom, isNewEvent, response, scrollTopHandler) {
  return (dispatch, getState) => {
    const files = getState().filesToUpload

    const userEventId = response.value.user_document_id
    const eventIdForUrl = response.value.id

    const afterUploadActions = () => {
      let actions = [
        forceDeleteFilesAction(),
        clearForm(),
        reset(formName)
      ]

      if (event.isInvoice) {
        actions.push(reset('paymentStatus'))
        if (event.type === eventType.outType) actions.push(reset('representation'))
      }

      const haveFilesForOCR = Boolean(find(files.newFiles, { imageForOcr: true }))
      let routerGoingTo = null

      if (cameFrom === 'supplier-invoices-list') {
        // not redirect back
      } else if (cameFrom === 'invoice') {
        actions.push(importEventRowAction([{ id: response.value.id }]))
        routerGoingTo = -3
      } else if (cameFrom === 'splitAmount' && !isNewEvent) {
        routerGoingTo = -2
      } else if (!haveFilesForOCR && isNewEvent) {
        // TODO - maybe for revenue this also makes sense?
        if (event.type === eventType.outType) {
          const repeatButtonText = getState().i18n.get(
            'app', 'views', 'EventMessageBox', event.isInvoice ? 'addAnotherInvoice' : 'addAnotherReceipt'
          ).s
          actions.push(showSuccessMessageBoxAction(userEventId.toString(), eventIdForUrl, repeatButtonText, scrollTopHandler))
        } else {
          actions.push(showSuccessMessageBoxAction(userEventId.toString(), eventIdForUrl))
        }
      } else if (!haveFilesForOCR) {
        routerGoingTo = -1
      }

      actions.push(hideNavbarSpinnerAction())
      if (routerGoingTo) {
        actions.push(routerActions.go(routerGoingTo))
      }

      return actions
    }

    return dispatch(uploadFiles(files, 'source_document', userEventId, eventIdForUrl, afterUploadActions))
  }
}

export function afterEventPushActions (formName, event, payments, isNewEvent, cameFrom, response, scrollTopHandler) {
  // Suppose that user has bad connection. He created transaction, but can't upload an image
  // App will inform that image can't be uploaded
  // But transaction will be already saved
  // So, if user clicks on save button again, app will create one more transaction
  // Changing id in form solves the problem
  let actions = [
    change(formName, 'id', response.value.id),
    updateFilesInEvent(formName, event, cameFrom, isNewEvent, response, scrollTopHandler)
  ]

  return actions
}

function transformRepresentationValuesForRequest (expense, eventId) {
  const cause = expense.representationCause || ''
  const guests = expense.representationGuests || []
  const currentClientCompany = Number(getCurrentClientCompany())

  if (!(cause && guests.length)) {
    return
  }

  return {
    id: expense.representationId,
    cause,
    representation_guests: guests.map((guest) => ({
      name: guest.name,
      company: guest.company,
      id: guest.id,
      owner_company_id: currentClientCompany
    })),
    with_alcohol: expense.withAlcohol,
    source_document_id: eventId,
    type: Number(!expense.internalRepresentation)
  }
}

function processError (error) {
  console.error('error', error)
  return hideNavbarSpinnerAction()
}
