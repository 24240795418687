import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'

import Icon from 'shared/components/Icon'
import Input from 'shared/components/Input/Input'
import Dropdown from 'shared/components/Dropdown/Dropdown'
import Button from 'shared/components/Button/Button'
import FormLayout from 'Integrations/shared/FormLayout'
import Spinner from 'shared/components/Spinner/Spinner'

import './styles.scss?global'

const OpenPaymentsForm = (props) => {
  const submitHandler = async (values) => {
    const result = await props.getValidatedFields()
    if (!Object.keys(result.currentErrors).length) await props.createConsent({ bic: values.bic, iban: values.iban })
  }
  return (
    <FormLayout className='open-payments-form'>
      <Icon type='open-payments-logo' className='open-payments-form__logo' />
      <h2 className='open-payments-form__header'>{props.texts.header}</h2>
      <h3 className='open-payments-form__instruction-header'>{props.texts.instructionHeader}</h3>
      <h4 className='open-payments-form__description'>{props.texts.description}</h4>
      <div className='integration-form'>
        {props.isLoading
          ? <div className='open-payments-form__spinner-wrapper'><Spinner dark /></div>
          : <React.Fragment>
            <div>
              <Field
                placeholder={props.texts.tFields('iban').s}
                component={InputWrapper}
                name='iban'
              />
              <Field
                hint={props.texts.tFields('bank').s}
                emptyOption={props.texts.tFields('bank').s}
                component={DropdownWrapper}
                name='bic'
                options={props.banksOptions}
                className='open-payments-form__dropdown'
              />
              <Field
                placeholder={props.texts.tFields('currency').s}
                component={InputWrapper}
                name='currency'
                disabled
              />
              <Field
                placeholder={props.texts.tFields('countryCode').s}
                component={InputWrapper}
                name='countryCode'
                disabled
              />
            </div>
            <Button onClick={props.handleSubmit(submitHandler)}>{props.texts.submitButton}</Button>
          </React.Fragment>
        }
      </div>
    </FormLayout>
  )
}

const InputWrapper = ({input, meta, ...props}) =>
  <Input {...input} {...meta} {...props} />

InputWrapper.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object
}

const DropdownWrapper = ({input, meta, ...props}) =>
  <Dropdown {...input} {...meta} {...props} />

DropdownWrapper.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object
}

OpenPaymentsForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  createConsent: PropTypes.func.isRequired,
  getValidatedFields: PropTypes.func.isRequired,
  banksOptions: PropTypes.array.isRequired,
  texts: PropTypes.shape({
    tFields: PropTypes.func.isRequired,
    submitButton: PropTypes.string.isRequired,
    header: PropTypes.string.isRequired,
    instructionHeader: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired
  }),
  isLoading: PropTypes.bool.isRequired
}

const validate = (values) => {
  let errors = {}

  if (!values.iban) {
    errors.iban = true
  }

  if (!values.bic) {
    errors.bic = true
  }
  return errors
}

const form = 'openPayments-form'
const reduxFormHOC = reduxForm({
  form,
  validate,
  enableReinitialize: true
})

export default reduxFormHOC(OpenPaymentsForm)
