import React from 'react'
import PropTypes from 'prop-types'

import Icon from 'shared/components/Icon'

import './styles.scss?global'

const KivraModalLayout = ({ content, buttons }) =>
  <div className='kivra-modal-layout'>
    <div className='kivra-modal-layout__content'>
      <Icon type='kivra' className='kivra-modal-layout__icon' />
      {content}
    </div>
    <div className='kivra-modal-layout__buttons'>
      {buttons}
    </div>
  </div>

KivraModalLayout.propTypes = {
  content: PropTypes.node,
  buttons: PropTypes.node
}

export default KivraModalLayout
