import '../BankTransferFormView.scss?global'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { reduxForm, Field, formValueSelector } from 'redux-form'
import { routerActions } from 'react-router-redux'
import { filter } from 'lodash'
import moment from 'moment'

// Actions
import { pushEventAction } from 'Events/BankTransfer/actions/transfer'
import { clearNewEventAction } from 'Events/shared/actions/event'

// Components
import Navigation from 'Navigation/Navigation'
import Input from 'shared/components/FormInput'
import Button from 'shared/components/Button/Button'
import DropDown from 'shared/components/FormDropdown'

import FileListUpload from 'FileUpload/containers/FileListUpload'

class Transfer extends Component {
  static propTypes = {
    accountFromId: PropTypes.string,
    accountToId: PropTypes.string,
    event: PropTypes.object,
    pushEvent: PropTypes.func,
    clearEvent: PropTypes.func,
    t: PropTypes.func,
    settings: PropTypes.object,
    handleSubmit: PropTypes.func,
    params: PropTypes.object
  }

  get availableAccountsFrom () {
    const { settings: { expensePaymentMethods = [] }, accountToId } = this.props
    return filter(expensePaymentMethods, (a) => { return a.value !== Number(accountToId) })
  }

  get availableAccountsTo () {
    const { settings: { expensePaymentMethods = [] }, accountFromId } = this.props
    return filter(expensePaymentMethods, (a) => { return a.value !== Number(accountFromId) })
  }

  render () {
    const {
      event, t,
      settings: { currencies },
      pushEvent,
      clearEvent,
      handleSubmit,
      params
    } = this.props

    return (
      <div className='bank-transfer f-column'>
        <FileListUpload files={event.files} />
        <div className='form f-column-top'>
          <div className='form__item'>
            <Field name='date' component={Input} placeholder={t('props', 'date').s} type='date' maxDate={moment()} />
          </div>
          <div className='form__item form__input-select'>
            <Field name='amount' component={Input} placeholder={t('props', 'amount').s} type='float' />
            <Field name='currencyId' component={DropDown} big options={currencies} />
          </div>
          <div className='form__item'>
            <Field name='accountFromId' component={DropDown} emptyOption={t('emptyOptions', 'accountFrom').s} big options={this.availableAccountsFrom} hint={t('props', 'accountFrom').s} />
          </div>
          <div className='form__item'>
            <Field name='accountToId' component={DropDown} emptyOption={t('emptyOptions', 'accountTo').s} big options={this.availableAccountsTo} hint={t('props', 'accountTo').s} />
          </div>
        </div>
        <div className='f-column-bottom form-buttons'>
          <Button saveButton onClick={handleSubmit(pushEvent)} view='transparent-black'>{params.id ? t('buttons', 'update').s : t('buttons', 'save').s}</Button>
          <Button view='transparent-red' onClick={clearEvent}>{t('buttons', 'cancel').s}</Button>
        </div>
      </div>
    )
  }
}

class TransferWithNavigation extends Component {
  static propTypes = {
    event: PropTypes.object,
    clearEvent: PropTypes.func,
    pageTitle: PropTypes.string
  }
  render () {
    const {event, pageTitle, clearEvent} = this.props
    const backLink = event.id ? `/events/${event.id}` : '/'
    return (
      <Navigation hideFooter backLink={backLink} onClickBack={clearEvent} title={pageTitle} className='f-column'>
        <Transfer {...this.props} />
      </Navigation>
    )
  }
}

const formName = 'bank-transfer-form'

function mapStateToProps (state) {
  return {
    initialValues: state.events.newEvent,
    event: state.events.newEvent,
    settings: state.userSettings,
    filesToUpload: state.filesToUpload,
    t: state.i18n.get('app', 'views', 'ExpenseView'),
    pageTitle: state.i18n.get('app', 'views', 'BankTransferView', 'navbar', 'title').s,
    accountFromId: formValueSelector(formName)(state, 'accountFromId'),
    accountToId: formValueSelector(formName)(state, 'accountToId')
  }
}

function mapDispatchToProps (dispatch, ownProps) {
  const isNewEvent = !ownProps.params.id
  const { location: { query: { cameFrom } } } = ownProps
  return {
    pushEvent: (event) => { dispatch(pushEventAction(isNewEvent, event, cameFrom)) },
    clearEvent: () => { dispatch([clearNewEventAction(), routerActions.goBack()]) }
  }
}

function validate (values) {
  let errors = {}
  if (!values.date) {
    errors.date = true
  }

  return errors
}

const TransferForm = reduxForm({
  form: formName,
  enableReinitialize: true,
  validate
})(Transfer)

const TransferFormWithNavigation = reduxForm({
  form: formName,
  enableReinitialize: true,
  validate
})(TransferWithNavigation)

export const TransferView = connect(mapStateToProps, mapDispatchToProps)(TransferForm)
export default connect(mapStateToProps, mapDispatchToProps)(TransferFormWithNavigation)
