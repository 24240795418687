import { COLLECTOR_INVOICE_CANCELLED, COLLECTOR_INVOICE_OPEN } from 'Invoices/shared/constants/CollectorInvoiceStatuses'
import { INVOICE } from 'Invoices/shared/constants/InvoiceTypes'
import { NOT_PAID, PARTIALLY_PAID } from 'Invoices/shared/constants/StatusTypes'
import { SENT_VIA_KIVRA } from 'Invoices/CustomerInvoices/views/InvoiceSendView/constants/kivraValidationErrorCodes'
import { isOverdue } from 'Invoices/shared/helpers'
import { isCollectorEnabled } from 'MarketPlace/Collector/helpers'

export default function getMultipleActions (props) {
  const {
    invoice: { status, sentAt, collectorStatus, invoiceType },
    redirectToEditView,
    redirectToCreditNote,
    redirectToDirectPayment,
    redirectToSendInvoice,
    showPreview,
    markAsPaid,
    copyInvoice,
    t
  } = props

  let buttons = []
  const sentViaKivra = props.invoice.kivraValidation.errors.find(e => e.code === SENT_VIA_KIVRA)

  if (!collectorStatus) buttons.push({ title: t('buttons', 'preview').s, onClick: showPreview })

  buttons.push({ title: t('buttons', 'copy').s, onClick: copyInvoice })

  if (!sentViaKivra && (!collectorStatus || collectorStatus === COLLECTOR_INVOICE_CANCELLED)) {
    if (!sentAt) buttons.push({ title: t('buttons', 'edit').s, onClick: redirectToEditView })
    buttons.push(
      { title: !sentAt ? t('buttons', 'send').s : t('buttons', 'sendAgain').s, onClick: () => redirectToSendInvoice({}) }
    )
  }

  if (sentAt) buttons.push({ title: t('buttons', 'creditNote').s, onClick: redirectToCreditNote })

  if (sentAt && (status === NOT_PAID || status === PARTIALLY_PAID)) {
    buttons.push(
      { title: t('buttons', 'markAsPaid').s, onClick: markAsPaid }
    )
  }

  if (isOverdue(props.invoice) && !sentViaKivra) {
    buttons.push(
      { title: t('buttons', 'remind').s, onClick: () => redirectToSendInvoice({ isReminder: true }) }
    )
  }

  if (isCollectorEnabled()) {
    if (collectorStatus === COLLECTOR_INVOICE_OPEN && invoiceType === INVOICE) {
      buttons.push(
        { title: t('buttons', 'directPayment').s, onClick: redirectToDirectPayment }
      )
    }
  }

  return buttons
}
