import PropTypes from 'prop-types'
import React, { Component } from 'react'

import moment from 'moment'

import getGreyLabelOptions from 'GreyLabel/helpers/greyLabel'

export default class TimeRange extends Component {
  constructor (props) {
    super(props)
    this.resetPeriods()
  }
  static propTypes = {
    data: PropTypes.arrayOf(PropTypes.object),
    template: PropTypes.func,
    t: PropTypes.func,
    onClickItem: PropTypes.func,
    getSelectedClassName: PropTypes.func
  }

  componentWillUpdate () {
    this.resetPeriods()
  }

  resetPeriods () {
    this.periods = {
      futureDates: [],
      today: null,
      lastWeek: null,
      lastMonth: null,
      lastYear: null,
      previousYears: []
    }
  }

  isFutureDate (date) {
    return date.isAfter(moment())
  }

  isToday (date) {
    return date.isSame(moment(), 'day')
  }

  isLastWeek (date) {
    const startOfWeek = moment().startOf('isoWeek').format('YYYY-MM-DD')
    return date.isAfter(startOfWeek) || date.isSame(startOfWeek, 'day')
  }

  isLastMonth (date) {
    const startOfMonth = moment().startOf('month').format('YYYY-MM-DD')
    return date.isAfter(startOfMonth) || date.isSame(startOfMonth, 'day')
  }

  isLastYear (date) {
    const startOfYear = moment().startOf('year').format('YYYY-MM-DD')
    return date.isAfter(startOfYear) || date.isSame(startOfYear, 'day')
  }

  isPreviousYears (date) {
    return date.isBefore(moment(), 'year')
  }

  hasDelimiter (itemDate) {
    if (itemDate) {
      const t = this.props.t('components', 'ListView', 'timeLabels')
      const tMonths = this.props.t('shared', 'months')
      const date = moment(itemDate, 'YYYY-MM-DD')

      if (this.isFutureDate(date)) {
        const futureDate = date.format('YYYY-MM')
        if (this.periods.futureDates.indexOf(futureDate) === -1) {
          this.periods.futureDates.push(futureDate)
          return `${tMonths(String(Number(futureDate.slice(-2)))).toUpperCase()} ${futureDate.slice(0, 4)}`
        } else {
          return false
        }
      } else if (this.isToday(date)) {
        if (this.periods.today === null) {
          this.periods.today = date
          return t('today').s
        } else {
          return false
        }
      } else if (this.isLastWeek(date)) {
        if (this.periods.lastWeek === null) {
          this.periods.lastWeek = date
          return t('thisWeek').s
        } else {
          return false
        }
      } else if (this.isLastMonth(date)) {
        if (this.periods.lastMonth === null) {
          this.periods.lastMonth = date
          return t('thisMonth').s
        } else {
          return false
        }
      } else if (this.isLastYear(date)) {
        if (this.periods.lastYear === null) {
          this.periods.lastYear = date
          return t('thisYear').s
        } else {
          return false
        }
      } else if (this.isPreviousYears(date)) {
        const year = date.format('YYYY')
        if (this.periods.previousYears.indexOf(year) === -1) {
          this.periods.previousYears.push(year)
          return year
        } else {
          return false
        }
      }
    }
  }

  getStrikedClassName (strike) {
    return strike ? 'list-view__item_canceled' : ''
  }

  renderList () {
    const {data, onClickItem, getSelectedClassName, template, ...props} = this.props
    const t = this.props.t('components', 'ListView', 'timeLabels')
    const { whiteLabel } = getGreyLabelOptions()
    const listItem = (item, key) => {
      return (
        <div onClick={() => { onClickItem(item) }} className={`list-view__item ${getSelectedClassName(item)} ${this.getStrikedClassName(item.strike)}`} key={key}>
          {React.createElement(template, {data: item, t, ...props})}
        </div>
      )
    }
    return (
      <div>
        {data.map((item, key) => {
          const delimiter = this.hasDelimiter(item.date)
          if (delimiter) {
            return [
              <div key={`delimeter-${key}`} className={
                `list-view__delimiter ${whiteLabel ? `list-view__delimiter--${whiteLabel}` : ''}`}
              >
                {delimiter}
              </div>,
              listItem(item, key)
            ]
          } else {
            return (
              listItem(item, key)
            )
          }
        })}
      </div>
    )
  }

  render () {
    return (
      <div>
        {this.renderList()}
      </div>
    )
  }
}
