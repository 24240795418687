import React from 'react'
import PropTypes from 'prop-types'

import Navigation from 'Navigation/Navigation'
import SendMessageForm from 'Invoices/CustomerInvoices/components/SendMessageForm/SendMessageForm'

import CommonHOC from './Common'

class SendInvoice extends React.Component {
  static propTypes = {
    invoiceNumber: PropTypes.number,
    invoice: PropTypes.object.isRequired
  }

  render () {
    const { t, invoice: { invoiceNumber } } = this.props
    return (
      <Navigation title={`${t('title').s} #${invoiceNumber || ''}`}>
        <SendMessageForm {...this.props} />
      </Navigation>
    )
  }
}

export default CommonHOC(SendInvoice)
