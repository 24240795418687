import PropTypes from 'prop-types'
import React from 'react'
import { formatCurrency } from 'mrshoebox-ui-components/src/helpers/countrySpecific'

export default function FormatNumber (props) {
  const { notFraction, value, withSymbol } = props
  const formattedNumber = formatCurrency(value, { notFraction, withSymbol })

  return <span>{formattedNumber}</span>
}

FormatNumber.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  withSymbol: PropTypes.bool,
  notFraction: PropTypes.bool
}

FormatNumber.defaultProps = {
  value: 0
}
