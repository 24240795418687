import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { reduxForm, Field, change, formValueSelector, getFormMeta, reset } from 'redux-form'
import classnames from 'classnames'

import SearchField from '../../../../shared/components/FormSearchField'
import Dropdown from '../../../../shared/components/FormDropdown'
import Tabs from '../../../../shared/components/FormTabs'
import SortButton from '../../../../Events/Events/components/SortButton/SortButton'

import { CUSTOMER_TYPE_COMPANY, CUSTOMER_TYPE_INDIVIDUAL } from '../../constants/CustomerTypes'

export class CustomersListFilters extends Component {
  static propTypes = {
    customerType: PropTypes.string,
    orderType: PropTypes.string,
    meta: PropTypes.object,
    canBeHidden: PropTypes.bool,
    dirty: PropTypes.bool,
    resetForm: PropTypes.func,
    t: PropTypes.func,
    changeOrderType: PropTypes.func
  }

  constructor (props) {
    super(props)
    this.state = { filterState: 'normal' }
  }

  render () {
    const { customerType, orderType, canBeHidden, t, changeOrderType, meta } = this.props
    if (meta.search && !meta.search.visited && canBeHidden && customerType === 'all') {
      return <div />
    }

    const filterClassNames = classnames([
      'filter', 'filter-wrap', 'filter--without-fixed-height',
      this.state.filterState === 'open' && 'filter--open',
      this.state.filterState === 'collapsed' && 'filter--collapsed'
    ])

    return (
      <div className={filterClassNames}>
        <div>
          <div className='filter__top-part'>
            <Field
              name='customerType'
              component={Tabs}
              className='filter__top-part__tabs'
              initValue={customerType}
              config={[
                {label: t('selects', 'customerType', 'all').s, value: 'all'},
                {label: t('selects', 'customerType', String(CUSTOMER_TYPE_COMPANY)).s, value: CUSTOMER_TYPE_COMPANY},
                {label: t('selects', 'customerType', String(CUSTOMER_TYPE_INDIVIDUAL)).s, value: CUSTOMER_TYPE_INDIVIDUAL}
              ]}
            />
          </div>
        </div>

        <div className='filter__main-part'>
          <div className='filter__multi-row filter__multi-row--small-margin'>
            <div>
              <Field
                name='orderBy'
                component={Dropdown}
                options={[
                  {label: t('orderBy', 'name').s, value: 'name'}
                ]}
              />
            </div>
            <div className='filter__multi-row'>
              <div>
                <SortButton type='desc' value={orderType} onClick={() => changeOrderType('desc')} />
              </div>
              <div>
                <SortButton type='asc' value={orderType} onClick={() => changeOrderType('asc')} />
              </div>
            </div>
          </div>
          <div className='filter__multi-row filter__multi-row--small-margin'>
            <div>
              <Field
                name='state'
                component={Dropdown}
                options={[
                  {label: t('state', 'all').s, value: ''},
                  {label: t('state', 'active').s, value: 'active'},
                  {label: t('state', 'inactive').s, value: 'inactive'}
                ]}
              />
            </div>
            <div className='filter__multi-row' />
          </div>
          <Field
            name='search'
            component={SearchField}
            changeAfterBlur
            placeholder={t('search').s}
          />
        </div>

        <div className='filter__bottom filter__bottom--without-right-padding'>
          <div className={`filter__clear ${this.props.dirty ? '' : 'filter__clear--hidden'}`} onClick={() => this.props.resetForm()}>
            <span className='icon-cross' />
            <span className='filter__clear__text'>{t('buttons', 'clearFilters').s}</span>
          </div>
          <div className='filter__open'>
            <span className='icon-arrow-pointer-down' onClick={() => this.setState({ filterState: 'open' })} />
          </div>
          <div className='filter__close-center ' onClick={() => this.setState({ filterState: 'normal' })}>
            <span className='icon-arrow-pointer-down' />
          </div>
        </div>
      </div>
    )
  }
}

export const defaultFilterValues = {
  search: '',
  orderBy: 'name',
  orderType: 'asc',
  customerType: 'all',
  state: 'active'
}

const formName = 'customersListFilters'
const selector = formValueSelector(formName)
const metaSelector = getFormMeta(formName)

const mapStateToProps = (state) => ({
  t: state.i18n.get('app', 'views', 'Customers', 'CustomersView', 'filters'),
  customerType: selector(state, 'customerType'),
  orderType: selector(state, 'orderType'),
  meta: metaSelector(state)
})

const mapDispatchToProps = (dispatch) => ({
  changeOrderType: (value) => dispatch(change(formName, 'orderType', value)),
  resetForm: () => dispatch(reset(formName))
})

const connectedFilters = connect(mapStateToProps, mapDispatchToProps)(CustomersListFilters)

export default reduxForm({
  form: formName,
  initialValues: defaultFilterValues,
  destroyOnUnmount: false
})(connectedFilters)
