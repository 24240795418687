import {bind} from 'redux-effects'
import {createAction} from 'redux-actions'
import { routerActions } from 'react-router-redux'
import moment from 'moment'
import { camelizeKeys } from 'humps'

import {fetch2} from 'shared/helpers/fetch'
import {showNavbarSpinnerAction, hideNavbarSpinnerAction} from 'shared/actions/navbarSpinner'
import { convertFromNewApi } from 'FileUpload/helpers/apiAdapter'
import { updateNotificationBadgesAction } from 'Notifications/actions/notificationBadges'
import { getFiscalYears, loadOverviewSettingsResultInfo } from 'Overview/actions/resultStats'

import { REPORT_LOAD_TO_STATE, REPORT_REMOVE_FROM_STATE } from 'Reports/constants/ActionTypes'
import { KEY_FIGURE_REPORT, TAX_REPORT, FINANCIAL_REPORTS, ANNUAL_REPORTS } from 'Reports/constants/Api'

const loadReportToState = createAction(REPORT_LOAD_TO_STATE)
export const removeReportFromStateAction = createAction(REPORT_REMOVE_FROM_STATE)

export function loadTaxPaymentReportAction (reportId) {
  return [showNavbarSpinnerAction(), bind(fetch2(TAX_REPORT + reportId), processTaxPaymentReport, processError)]
}

export function loadFinancialReportAction (reportId) {
  return [
    showNavbarSpinnerAction(),
    bind(fetchFinancialReport(reportId), processFinancialReport, processError)
  ]
}

function fetchFinancialReport (id) {
  return fetch2(FINANCIAL_REPORTS + id)
}

function processFinancialReport (response) {
  return [
    loadReportToState({
      files: convertFromNewApi(response.value.files),
      comment: response.value.comment
    }),
    hideNavbarSpinnerAction(),
    !response.value.reporter_has_read && bind(markPDFReportAsRead(response.value.id), updateNotificationBadgesAction)
  ]
}

export function loadAnnualReportAction (reportId) {
  return [
    showNavbarSpinnerAction(),
    bind(fetchAnnualReport(reportId), processAnnualReport, processError)
  ]
}

function fetchAnnualReport (id) {
  return fetch2(ANNUAL_REPORTS + id)
}

function processAnnualReport (response) {
  return [
    loadReportToState({
      files: convertFromNewApi(response.value.files),
      comment: response.value.comment
    }),
    hideNavbarSpinnerAction(),
    !response.value.reporter_has_read && bind(markAnnualReportAsRead(response.value.id), updateNotificationBadgesAction)
  ]
}

export function loadFiscalYearsAndReport () {
  return getFiscalYears((from, to) => loadKeyFigureReportAction({from, to}, true))
}

export function loadKeyFigureReportAction (data, withoutSettingsSave) {
  const { from, to, selectedYearIndex } = data
  return [
    showNavbarSpinnerAction(),
    !withoutSettingsSave ? loadOverviewSettingsResultInfo({from, to, selectedYearIndex}) : null,
    bind(fetchKeyFigureReport(from, to), processKeyFigureReport, processError)
  ]
}

function fetchKeyFigureReport (from, to) {
  return fetch2(KEY_FIGURE_REPORT, {from, to})
}

function processTaxPaymentReport (response) {
  console.log(response)
  const data = camelizeKeys(response.value)
  data.dueDateMinusMonth = moment(response.value.pay_due).subtract('months', 1)
  data.files = convertFromNewApi(response.value.files)

  let actions = [hideNavbarSpinnerAction(), loadReportToState(data)]
  if (!data.reporterHasRead) {
    actions = actions.concat([readTaxPayment(data.id)])
  }

  return actions
}

function processKeyFigureReport (response) {
  console.log(response)
  const report = response.value
  return [hideNavbarSpinnerAction(), loadReportToState({
    profit: Number(report.profit),
    solvency: Number(report.solvency),
    profitMargin: Number(report.margin),
    cashLiquidity: report.cash_liquidity

    // period: '2016-11-13',
    // profit: 230809,
    // profitMargin: 22.50,
    // totalAssets: 1230000,
    // totalCapital: -870100,
    // totalDebts: 430000,
    // totalDebtTaxes: 23000,
    // solvency: 36
  })]
}

export function approveTaxPaymentAction (reportId, data) {
  return [
    showNavbarSpinnerAction(),
    bind(approveTaxPayment(reportId, data), processTaxPaymentApprove, processError)
  ]
}

function approveTaxPayment (reportId, data) {
  const dataForRequest = {
    tax_payment_report: {
      paid_at: data.paymentDate,
      payment_account_id: data.paymentMethodId
    }
  }

  return fetch2(TAX_REPORT + reportId + '/pay', {}, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(dataForRequest)
  })
}

function processTaxPaymentApprove () {
  return [hideNavbarSpinnerAction(), routerActions.goBack(), updateNotificationBadgesAction()]
}

function readTaxPayment (reportId) {
  return fetch2(TAX_REPORT + reportId, {}, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ tax_payment_report: { read: true } })
  })
}

function processError (response) {
  console.log('¯\\_(ツ)_/¯ on report fetching', response)
  return () => {} // don't send undefined to redux store
}

function markAnnualReportAsRead (id) {
  return fetch2(ANNUAL_REPORTS + id, {}, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ fiscal_report: { reporter_has_read: true } })
  })
}

function markPDFReportAsRead (id) {
  return fetch2(FINANCIAL_REPORTS + id, {}, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ fiscal_report: { reporter_has_read: true } })
  })
}
