import React, {Component} from 'react'

import Documents from 'Documents/views/DocumentsView/containers/Smartphones'
import IpadNavigation from 'Navigation/IpadNavigation'

export default class DocumentsView extends Component {
  render () {
    return (
      <IpadNavigation {...this.props} onlyLeftSide>
        <Documents {...this.props} />
      </IpadNavigation>
    )
  }
}
