import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Link } from 'react-router'

import PlusButton from 'shared/components/PlusButton/PlusButton'
import Button from 'shared/components/Button/Button'
import './EventSupplier.scss?global'

export default class EventSupplier extends Component {
  static propTypes = {
    pathname: PropTypes.string,
    saveFormToEvent: PropTypes.func,
    removeSupplier: PropTypes.func,
    t: PropTypes.func,
    supplierName: PropTypes.string
  }

  render () {
    const {supplierName, pathname, saveFormToEvent, removeSupplier, t} = this.props
    return (
      <div className={`create-invoice__select-supplier ${supplierName ? 'create-invoice__select-supplier--filled' : ''}`}>
        <div className='create-invoice__select-supplier__content'>
          {supplierName ? (
            <div className='create-invoice__select-supplier__info'>
              <Link to={`${pathname}/suppliers`} onClick={saveFormToEvent} className='create-invoice__select-supplier__info__left'>
                <div>
                  <div>{t('supplierSection', 'name').s}:</div>
                  <div><h3 className='truncate'>{supplierName}</h3></div>
                </div>
              </Link>
              <div />
              <div className='create-invoice__select-supplier__info__right'>
                <PlusButton type='minus' onClick={removeSupplier} />
              </div>
            </div>
          ) : (
            <Button view='transparent-black' link={`${pathname}/suppliers`} onClick={saveFormToEvent}>{t('supplierSection', 'supplier').s}</Button>
          )}
        </div>
      </div>
    )
  }
}
