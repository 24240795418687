import {WIZARD_SET_INFO, WIZARD_UPDATE_NEW_PRICE, WIZARD_CLEAR_INFO, WIZARD_SET_DEFAULT_NEW_PRICE} from 'Wizard/constants/ActionTypes'

export const initialState = {
  priceList: [],
  currentPrice: {
    company: {},
    additional_items: [],
    standard_options: [],
    additional_options: []
  },
  newPrice: {
    documentsFrequency: 'month',
    monthlyClientInvoices: 0,
    monthlySupplierInvoices: 0,
    monthlyReceipts: 0,
    cashRegister: false,
    accountantMonthlyCost: 0,
    accountantMonthlyHours: 0,
    withEmployees: false,
    vatFrequency: 'month',
    requestCall: false,
    turnover: 0
  }
}

export default function (state = initialState, action) {
  switch (action.type) {
    case WIZARD_SET_INFO:
      return {
        ...state,
        ...action.payload
      }
    case WIZARD_UPDATE_NEW_PRICE:
      return {
        ...state,
        newPrice: {
          ...state.newPrice,
          ...action.payload
        }
      }
    case WIZARD_SET_DEFAULT_NEW_PRICE:
      return {
        ...state,
        newPrice: {
          ...initialState.newPrice,
          companyType: state.newPrice.companyType
        }
      }
    case WIZARD_CLEAR_INFO:
      return {
        ...initialState
      }
    default:
      return state
  }
}
