import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { reduxForm, Field } from 'redux-form'

// Components
import Navigation from 'Navigation/Navigation'
import Input from 'shared/components/FormInput'
import DropDown from 'shared/components/FormDropdown'
import Button from 'shared/components/Button/Button'

// Actions
import { updateInvoiceRowAction } from '../../../../actions/imports'

export class InvoiceRow extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func,
    vatPercents: PropTypes.arrayOf(PropTypes.object),
    t: PropTypes.func,
    updateInvoiceRow: PropTypes.func,
    params: PropTypes.object
  }

  constructor (props) {
    super(props)
    this.saveInvoiceRow = this.saveInvoiceRow.bind(this)
  }

  saveInvoiceRow (values) {
    const { params: { index }, updateInvoiceRow } = this.props
    updateInvoiceRow(values, index)
  }

  render () {
    const { t, vatPercents, handleSubmit } = this.props
    return (
      <div className='invoice-row f-column'>
        <div className='form f-column-top'>
          <div className='form__item'>
            <Field name='description' component={Input} placeholder={t('fields', 'description', 'title').s} />
          </div>
          <div className='form__item'>
            <Field name='price' component={Input} placeholder={t('fields', 'price', 'title').s} type='float' />
          </div>
          <div className='form__item'>
            <Field name='vatPercentage' component={DropDown} big options={vatPercents} hint={t('fields', 'vatPercentage', 'title').s} />
          </div>
        </div>
        <div className='f-column-bottom form-buttons'>
          <Button view='transparent-black' onClick={handleSubmit(this.saveInvoiceRow)}>{t('buttons', 'save').s}</Button>
          <Button view='transparent-red' goBackButton>{t('buttons', 'cancel').s}</Button>
        </div>
      </div>
    )
  }
}

export class InvoiceRowWithNavigation extends Component {
  static propTypes = {
    t: PropTypes.func
  }

  render () {
    const {t} = this.props
    return (
      <Navigation title={t('navbar', 'title').s} hideFooter className='f-column'>
        <InvoiceRow {...this.props} />
      </Navigation>
    )
  }
}

function mapStateToProps (state, ownProps) {
  const { invoices: { customerInvoices: { invoice: { invoiceRows } } } } = state
  const { params: { index } } = ownProps
  const initialValues = invoiceRows[Number(index)]

  return {
    t: state.i18n.get('app', 'views', 'Invoices', 'InvoiceRowView'),
    vatPercents: (state.userSettings && state.userSettings.vatPercents) || [],
    initialValues
  }
}

function mapDispatchToProps (dispatch) {
  return {
    updateInvoiceRow: (data, index) => { dispatch(updateInvoiceRowAction(data, index, true)) }
  }
}

const formName = 'invoiceRowForm'
export const InvoiceRowView = reduxForm({
  form: formName
})(connect(mapStateToProps, mapDispatchToProps)(InvoiceRow))

export default reduxForm({
  form: formName
})(connect(mapStateToProps, mapDispatchToProps)(InvoiceRowWithNavigation))
