import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { reduxForm, Field, getFormValues, getFormSyncErrors, getFormMeta } from 'redux-form'
import { isEmpty } from 'lodash'

// Components
import Navigation from 'Navigation/Navigation'
import Button from 'shared/components/Button/Button'
import Input from 'shared/components/FormInput'

// Actions
import { projectSaveAction, loadProjectInfoAction, projectInfoClearAction } from 'Projects/actions/project'
import { routerActions } from 'react-router-redux'

class ProjectCreate extends Component {
  static propTypes = {
    formErrors: PropTypes.object,
    formValues: PropTypes.object,
    meta: PropTypes.object,
    params: PropTypes.object,
    project: PropTypes.object,
    pushProject: PropTypes.func,
    getProjectInfo: PropTypes.func,
    handleSubmit: PropTypes.func,
    t: PropTypes.func,
    redirectToCustomersSelect: PropTypes.func
  }

  constructor (props) {
    super(props)
    this.saveProject = this.saveProject.bind(this)
  }

  componentDidMount () {
    const { projectId } = this.props.params
    if (projectId && isEmpty(this.props.project)) {
      this.props.getProjectInfo(projectId)
    }
  }

  saveProject (values) {
    const { project, pushProject } = this.props
    pushProject(project.id, {...project, ...values})
  }

  render () {
    const {
      t, formErrors, formValues: { customerName },
      handleSubmit, meta, redirectToCustomersSelect
    } = this.props

    return (
      <div className='f-column' >
        <div className='form f-column-top'>
          <div className='form__item'>
            <Field name='name' component={Input} placeholder={t('props', 'name').s} />
          </div>
          <div className='form__item'>
            <Field
              name='customerName'
              component={Input}
              placeholder={t('props', 'customer').s}
              touched={meta.customerName && meta.customerName.touched}
              error={formErrors.customerName}
              value={customerName}
              onClick={redirectToCustomersSelect}
            />
          </div>
          <div className='form__item'>
            <Field name='startDate' component={Input} placeholder={t('props', 'start').s} type='date' />
          </div>
        </div>
        <div className='f-column-bottom form-buttons'>
          <Button saveButton view='transparent-black' onClick={handleSubmit(this.saveProject)}>{t('buttons', 'save').s}</Button>
          <Button view='transparent-red' goBackButton>{t('buttons', 'cancel').s}</Button>
        </div>
      </div>
    )
  }
}

class ProjectCreateWithNavigation extends Component {
  static propTypes = {
    params: PropTypes.object,
    t: PropTypes.func
  }
  render () {
    const { t, params: { projectId } } = this.props

    return (
      <Navigation title={projectId ? t('title', 'update').s : t('title', 'create').s} hideFooter className='f-column' >
        <ProjectCreate {...this.props} />
      </Navigation>
    )
  }
}

const formName = 'project'
const formValuesSelector = getFormValues(formName)
const formErrorsSelector = getFormSyncErrors(formName)
const metaSelector = getFormMeta(formName)

function mapStateToProps (state) {
  return {
    project: state.project,
    initialValues: state.project,
    t: state.i18n.get('app', 'views', 'ProjectView', 'new'),
    formValues: formValuesSelector(state) || {},
    formErrors: formErrorsSelector(state),
    meta: metaSelector(state) || {}
  }
}

function mapDispatchToProps (dispatch, ownProps) {
  const path = ownProps.location.pathname
  return {
    getProjectInfo: (projectId) => { dispatch(loadProjectInfoAction(projectId)) },
    pushProject: (id, data) => { dispatch(projectSaveAction(id, data, true)) },
    projectInfoClear: () => { dispatch(projectInfoClearAction()) },
    redirectToCustomersSelect: () => { dispatch(routerActions.push(path + '/customer')) }
  }
}

function validate (values) {
  let errors = {}

  if (!values.name) {
    errors.name = true
  }
  if (!values.customerId) {
    errors.customerId = true
    errors.customerName = true // because this field visible in form
  }
  if (!values.startDate) {
    errors.startDate = true
  }
  return errors
}

export const ProjectCreateView = connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: formName,
  validate,
  destroyOnUnmount: false
})(ProjectCreate))

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: formName,
  validate,
  destroyOnUnmount: false
})(ProjectCreateWithNavigation))
