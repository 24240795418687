import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { routerActions } from 'react-router-redux'

import { blockWelcomeModal } from 'MarketPlace/shared/actions'
import Collaboration from 'MarketPlace/shared/views/CollaborationView'
import Navigation from 'Navigation/Navigation'

class FactoringInfoView extends Component {
  static propTypes = {
    t: PropTypes.func,
    blockWelcomeModal: PropTypes.func.isRequired,
    cancelHandler: PropTypes.func.isRequired,
    startHandler: PropTypes.func.isRequired
  }

  get collaborationConfig () {
    const { t } = this.props
    return {
      message: t('factoringInfo', 'message').s,
      partnerOffer: t('factoringInfo', 'offers').s,
      iconName: 'cl_bank_logo'
    }
  }

  componentDidMount () {
    this.props.blockWelcomeModal(true)
  }

  render () {
    return (
      <Collaboration
        {...this.collaborationConfig}
        {...this.props}
        partnerBlockClass='factoring-collaboration'
        readMoreLink={'/marketplace/factoring/more'}
      />

    )
  }
}

const FactoringInfoWithNavigation = (props) => {
  const { t, cancelHandler } = props
  const navBarTitle = t('factoringInfo', 'title').s
  return (
    <div className='factoring-wrapper'>
      <Navigation hideFooter onClickBack={cancelHandler} title={navBarTitle} notBottomSpace >
        <FactoringInfoView {...props} />
      </Navigation>
    </div>
  )
}

FactoringInfoWithNavigation.propTypes = {
  cancelHandler: PropTypes.func.isRequired
}

function mapStateToProps (state) {
  return {
    t: state.i18n.get('app', 'views', 'MarketPlace')
  }
}

function mapDispatchToProps (dispatch) {
  return {
    blockWelcomeModal: (...args) => dispatch(blockWelcomeModal(...args)),
    cancelHandler: () => dispatch(routerActions.goBack()),
    startHandler: () => dispatch(routerActions.push('/marketplace/factoring/form'))
  }
}

export const FactoringInfo = connect(mapStateToProps, mapDispatchToProps)(FactoringInfoView)
export default connect(mapStateToProps, mapDispatchToProps)(FactoringInfoWithNavigation)
