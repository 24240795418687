import React from 'react'
import { Route } from 'react-router'
import { destroy } from 'redux-form'

import {requireAuthentication} from 'User/Auth/higherOrderComponents/AuthComponent'
import addSpinnerControl from 'shared/higherOrderComponents/addSpinnerControl'

import ProjectsView from 'Projects/views/ProjectsView/ProjectsView'
import ProjectView from 'Projects/views/ProjectView/Preview/ProjectPreviewView'
import ProjectCreateView from 'Projects/views/ProjectView/Create/ProjectCreateView'
import ProjectSelectCustomerView from 'Projects/views/ProjectView/Create/SelectCustomer/SelectCustomer'
import FinanceVisualizationView from 'Projects/views/ProjectView/FinanceVisualization/FinanceVisualizationView'
import FinanceDetailsByAccountView from 'Projects/views/ProjectView/FinanceDetailsByAccount'
import TotalsVisualizationView from 'Projects/views/ProjectView/TotalsVisualization/TotalsVisualizationView'
import EventView from 'Events/Event/views/EventView/EventView'
import TimesheetView from 'Timesheet/views/TimesheetView/TimesheetView'
import MileageView from 'mrshoebox-ui-components/src/modules/DriverLogs/views/DriverLogView/' // TODO: check
import ProjectSpecificationView from 'Projects/views/ProjectView/Specification/Specification'
import EventsImportViewForProject from 'Projects/views/ProjectView/Specification/EventsImportView/EventsImportView'
import TimesheetImportViewForProject from 'Projects/views/ProjectView/Specification/TimesheetsImportView/TimesheetsImportView'
import MileageImportViewForProject from 'Projects/views/ProjectView/Specification/MileageImportView/MileageImportView'

// Form-cleaning components
import { projectInfoClearAction } from 'Projects/actions/project'

function clearProjectInfo (dispatch, prevState) {
  const prevRoute = prevState.routes[prevState.routes.length - 1].path
  if (prevRoute !== '/projects/:projectId/edit/customer' && prevRoute !== '/projects/new/customer') {
    dispatch(projectInfoClearAction())
  }
}

function clearProjectFormInfo (dispatch, prevState) {
  const prevRoute = prevState.routes[prevState.routes.length - 1].path
  if (prevRoute !== '/projects/:projectId/edit/customer' && prevRoute !== '/projects/new/customer') {
    dispatch(destroy('project'))
  }
}

const projectRoutes = (
  <Route>
    <Route path='/projects' component={requireAuthentication(ProjectsView)} />
    <Route onLeave={clearProjectInfo}>
      <Route onLeave={clearProjectFormInfo}>
        <Route path='/projects/new' component={requireAuthentication(ProjectCreateView)} />
        <Route path='/projects/new/customer' component={requireAuthentication(ProjectSelectCustomerView)} />
        <Route path='/projects/:projectId/edit' component={requireAuthentication(ProjectCreateView)} />
        <Route path='/projects/:projectId/edit/customer' component={requireAuthentication(ProjectSelectCustomerView)} />
      </Route>

      <Route path='/projects/:projectId' component={requireAuthentication(ProjectView)} />
      <Route path='/projects/:projectId/incomechart' component={requireAuthentication(addSpinnerControl(FinanceVisualizationView))} />
      <Route path='/projects/:projectId/outcomechart' component={requireAuthentication(addSpinnerControl(FinanceVisualizationView))} />
      <Route path='/projects/:projectId/finance_details_by_account' component={requireAuthentication(FinanceDetailsByAccountView)} />
      <Route path='/projects/:projectId/totalchart' component={requireAuthentication(addSpinnerControl(TotalsVisualizationView))} />
      <Route path='/projects/:projectId/events/:eventId' component={requireAuthentication(EventView)} />
      <Route path='/projects/:projectId/timesheet/:timesheetId' component={requireAuthentication(TimesheetView)} />
      <Route path='/projects/:projectId/mileage/:mileageId' component={requireAuthentication(MileageView)} />

      <Route path='/projects/:projectId/specification' component={requireAuthentication(ProjectSpecificationView)} />
      <Route path='/projects/:projectId/import/events' component={requireAuthentication(EventsImportViewForProject)} />
      <Route path='/projects/:projectId/import/timesheets' component={requireAuthentication(TimesheetImportViewForProject)} />
      <Route path='/projects/:projectId/import/mileage' component={requireAuthentication(MileageImportViewForProject)} />
    </Route>
  </Route>
)

export default projectRoutes
