import { change } from 'redux-form'
import { createAction } from 'redux-actions'

// Constants
import { NEW_EVENT_UPDATE_PROPS } from 'Events/shared/constants/Event'

// Helpers
import { calculateVatAmount } from 'Events/shared/helpers/calculateAmount'

const updateNewExpenseProps = createAction(NEW_EVENT_UPDATE_PROPS)

export function calculateAndSetVatAmountAction (amountWithVat, vat) {
  const vatAmount = Number(calculateVatAmount(amountWithVat, vat)).toFixed(2)

  return [
    change('receipt-form', 'vat', vat),
    change('receipt-form', 'vatAmount', vatAmount)
  ]
}

export function updateNewExpensePropsAction (changes) {
  return updateNewExpenseProps(changes)
}
