import 'MainPage/styles/index.scss?global'

import PropTypes from 'prop-types'

import React, { Component } from 'react'
import { Link } from 'react-router'
import {connect} from 'react-redux'
import cs from 'classnames'

// Helpers
import getGreyLabelOptions from 'GreyLabel/helpers/greyLabel'
import { getTodoLink } from 'MainPage/helpers'

// Components
import NewEntry from 'NewEntry/components/NewEntry'
import OverlayHints from 'MainPage/components/OverlayHints/OverlayHints'
import NavigationInfoBlock from 'Navigation/components/InfoBlock'

// Decorators
import Policy from 'Policy/decorators'

export class MainPageView extends Component {
  static propTypes = {
    userSettings: PropTypes.object,
    mainPageHintsWereShown: PropTypes.bool,
    unreadMessagesNum: PropTypes.number,
    t: PropTypes.func,
    getFeaturePermissionProps: PropTypes.func
  };

  constructor (props) {
    super(props)
    this.state = {overlayMode: false, mainText: ''}
    this.handleClick = this.handleClick.bind(this)
    this.onAddNewEntry = this.onAddNewEntry.bind(this)
  }
  componentDidMount () {
    document.title = getGreyLabelOptions().title
    this.setState({overlayMode: this.overlayHintsVisible()})
    this.setMainText()
  }
  onAddNewEntry () {
    this.newEntry.makeVisible()
  }
  getTodoLink () {
    return getTodoLink(this.props)
  }

  overlayHintsVisible (value) {
    let {userSettings, mainPageHintsWereShown} = this.props
    const hintWereShown = value || mainPageHintsWereShown
    return userSettings.loaded && !userSettings.company.organizationNumber && !hintWereShown
  }

  handleClick (e) {
    const {overlayMode} = this.state
    if (overlayMode) {
      e.preventDefault()
    }
  }

  componentWillReceiveProps (props) {
    const {mainPageHintsWereShown} = props
    const firstNotification = !this.props.mainPageHintsWereShown && mainPageHintsWereShown
    this.setState({overlayMode: this.overlayHintsVisible(mainPageHintsWereShown)})
    this.setMainText(props, firstNotification)
  }

  setMainText (props = this.props, firstNotification) {
    const { userSettings: { company }, t } = props

    if (!company) {
      return
    }

    if (firstNotification) {
      setTimeout(() => {
        this.setState({mainText: (
          <div className='mainpage__small-text'>{t('firstNotificationText').s}</div>
        )})
      }, 2000)
      return
    }

    if (!company.companyName) {
      this.setState({mainText: (
        <div className='mainpage__small-text'>{t('firstNotificationText').s}</div>
      )})
      return
    }

    this.setState({mainText: (<div className='mainpage__small-text'>{t('title').s}</div>)})
  }

  render () {
    const {t, getFeaturePermissionProps, unreadMessagesNum} = this.props
    const {overlayMode, mainText} = this.state

    const { whiteLabel } = getGreyLabelOptions()

    return (
      <div className={`mainpage ${overlayMode ? 'mainpage--overlay' : ''}`}>
        <NavigationInfoBlock />
        <div className={cs('mainpage__content', whiteLabel)}>
          <div className='mainpage__content__logo'><span className={getGreyLabelOptions().logoClass} /></div>
          {mainText}
        </div>
        <div className={cs('mainpage__menu', whiteLabel && `mainpage__menu--${whiteLabel}`)}>
          <OverlayHints active={overlayMode} />
          <div className={cs('mainpage__menu__plus', whiteLabel && `mainpage__menu__plus--${whiteLabel}`)} onClick={!overlayMode && this.onAddNewEntry} {...getFeaturePermissionProps('events', cs('mainpage__menu__plus', whiteLabel && `mainpage__menu__plus--${whiteLabel}`), 'main-page-plus-button-disable')}>
            <div className={cs('mainpage__menu__plus__round', whiteLabel && `mainpage__menu__plus__round--${whiteLabel}`)} />
          </div>
          <div className={cs('mainpage__menu__row', whiteLabel && `mainpage__menu__row--${whiteLabel}`)}>
            <div>
              <Link to={this.getTodoLink()} className='mainpage__menu__todo' onClick={this.handleClick}>
                <span className='icon-todo' data-badge={unreadMessagesNum} />
                <div>{t('menu', 'todo').s}</div>
              </Link>
            </div>
            <div {...getFeaturePermissionProps('events')}>
              <Link to='/events' className='mainpage__menu__events' onClick={this.handleClick}>
                <span className='icon-events' />
                <div>{t('menu', 'events').s}</div>
              </Link>
            </div>
          </div>
          <div className={cs('mainpage__menu__row', whiteLabel && `mainpage__menu__row--${whiteLabel}`)}>
            <div>
              <Link to='/overview' className='mainpage__menu__overview' onClick={this.handleClick}>
                <span className='icon-overview' />
                <div>{t('menu', 'overview').s}</div>
              </Link>
            </div>
            <div>
              <Link to='/more' className='mainpage__menu__more' onClick={this.handleClick}>
                <span className='icon-more' />
                <div>{t('menu', 'more').s}</div>
              </Link>
            </div>
          </div>
        </div>
        <div className='mainpage__after_block'>
          { !__PROD__ ? RELEASE_NAME : null }
        </div>
        <NewEntry provideController={newEntry => { this.newEntry = newEntry }} />
      </div>
    )
  }
}

function mapStateToProps (state) {
  return {
    wizardStep: state.wizard.currentStep,
    mainPageHintsWereShown: state.userSettings.mainPageHintsWereShown,
    userSettings: state.userSettings,
    unreadMessagesNum: state.notifications.unreadMessagesNum || null,
    t: state.i18n.get('app', 'views', 'MainPage')
  }
}

export default Policy(connect(mapStateToProps)(MainPageView))
