import React from 'react'
import PropTypes from 'prop-types'

import Button from 'shared/components/Button/Button'

import './vacancies.scss?global'

const Vacancy = (props) =>
  <div className='event-preview'>
    <div className='event-preview__static-section'>
      <div className='event-preview__title'>
        <h3><b>{props.title}</b></h3>
      </div>
      <DataRow leftSideContent={props.t('role').s} rightSideContent={props.role.title} />
      <DataRow leftSideContent={props.t('roleArea').s} rightSideContent={props.role.roleAreaTitle} />
      <DataRow leftSideContent={props.t('startDate').s} rightSideContent={props.startDate} />
      <DataRow leftSideContent={props.t('endDate').s} rightSideContent={props.endDate} />
      <DataRow leftSideContent={props.t('extent').s} rightSideContent={props.extent ? `${props.extent}%` : ''} />
      <DataRow leftSideContent={props.t('city').s} rightSideContent={props.city.name} />
      <DataRow leftSideContent={props.t('country').s} rightSideContent={props.country.name} />
      <DataRow leftSideContent={props.t('tenderEndDate').s} rightSideContent={props.tenderEndDate} />
      <DataRow leftSideContent={props.t('contactPerson').s} rightSideContent={
        <div>
          {props.contactPerson.name}
          <a href={`tel:${props.contactPhoneNumber}`}> {props.contactPhoneNumber}</a>
        </div>} />
      <DataRow leftSideContent={props.t('linkId').s} rightSideContent={props.linkId} />
      <br />
      <h3><b> Övergripande uppdragsbeskrivning</b></h3>
      <br />
      <div className='vacancy-text' dangerouslySetInnerHTML={{ __html: props.assignmentDescription }} />
      <br />
      <TextBlock title='Skallkrav' data={props.tenderInviteShallDemands} />
      <TextBlock title='Börkrav' data={props.tenderInviteShouldDemands} />
      <TextBlock title='Övriga krav' data={props.tenderInviteOtherDemands} />
      <TextBlock title='Personliga egenskaper' data={props.tenderInviteIndividualQualities} />

      <div>{ props.otherInfo ? (
        <div>
          <h3><b>Övrig information</b></h3>
          <br />
          <div className='vacancy-text' dangerouslySetInnerHTML={{ __html: props.otherInfo }} />
        </div>
      ) : ''}</div>

      <br />
      <a href={`mailto:cv@keyman.se?subject=KST${props.linkId}/${props.role.title}/${props.title}`} >
        <Button view='transparent-black'>OFFERERA PÅ DETTA UPPDRAG VIA E-POST</Button>
      </a>
      <br /><br /><br />
    </div>
  </div>

Vacancy.propTypes = {
  contactPerson: PropTypes.shape({
    name: PropTypes.string
  }),
  contactPhoneNumber: PropTypes.string,
  country: PropTypes.shape({
    name: PropTypes.string
  }),
  city: PropTypes.shape({
    name: PropTypes.string
  }),
  extent: PropTypes.number,
  linkId: PropTypes.number,
  role: PropTypes.shape({
    title: PropTypes.string,
    roleAreaTitle: PropTypes.string
  }),
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  title: PropTypes.string,
  tenderEndDate: PropTypes.string,
  assignmentDescription: PropTypes.string,
  otherInfo: PropTypes.string,
  tenderInviteIndividualQualities: PropTypes.arrayOf((
    PropTypes.shape({
      tenderInviteIndividualQuality: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        demand: PropTypes.string
      })
    })
  )),
  tenderInviteShallDemands: PropTypes.arrayOf(
    PropTypes.shape({
      tenderInviteShallDemand: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        demand: PropTypes.string
      })
    })
  ),
  tenderInviteShouldDemands: PropTypes.arrayOf(
    PropTypes.shape({
      tenderInviteShouldDemand: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        demand: PropTypes.string
      })
    })
  ),
  tenderInviteOtherDemands: PropTypes.arrayOf(
    PropTypes.shape({
      tenderInviteOtherDemand: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        demand: PropTypes.string
      })
    })
  )
}

Vacancy.defaultProps = {
  role: {},
  city: {},
  country: {},
  contactPerson: {}
}

const DataRow = ({leftSideContent, rightSideContent}) =>
  <div className='event-preview__single-field'>
    <h5>{leftSideContent}</h5>
    <h4>{rightSideContent}</h4>
  </div>

DataRow.propTypes = {
  leftSideContent: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  rightSideContent: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node])
}

const TextBlock = (props) =>
  <div>{ props.data.length ? (
    <div>
      <h3><b>{props.title}</b></h3>
      <ul className='vacancy-text'>{
        props.data.map((item) =>
          <li key={item.id}>{item.name}</li>
        )
      }</ul>
    </div>
  ) : ''}</div>

TextBlock.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string
    })
  ),
  title: PropTypes.string
}
export default Vacancy
