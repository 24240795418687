import React, { Component } from 'react'
import { Link } from 'react-router'
import PropTypes from 'prop-types'
import cs from 'classnames'

import ButtonsBlock from 'MarketPlace/shared/components/ButtonsBlock'
import Icon from 'shared/components/Icon'

import './index.scss?global'

export default class Collaboration extends Component {
  static propTypes = {
    startHandler: PropTypes.func.isRequired,
    cancelHandler: PropTypes.func.isRequired,
    readMoreLink: PropTypes.string,
    readMoreHandler: PropTypes.func,
    message: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.func
    ]),
    partnerOffer: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.func
    ]),
    iconName: PropTypes.string.isRequired,
    iconType: PropTypes.string,
    partnerBlockClass: PropTypes.string,
    startButtonText: PropTypes.string
  }

  get bottomBlockClass () {
    return cs(
      'collaboration__block',
      'collaboration__block_bottom',
      this.props.partnerBlockClass
    )
  }

  renderPartnerIcon () {
    const { iconType, iconName } = this.props
    const className = `collaboration__logo ${iconName}`
    if (iconType === 'png') {
      return <img src={`/icons/${iconName}.${iconType}`} className={className} />
    }

    return <Icon className={className} type={iconName} />
  }

  render () {
    const { t, readMoreLink, readMoreHandler, message, partnerOffer, startButtonText } = this.props
    return (
      <div className='collaboration'>
        <h1 className='collaboration__message'>{message}</h1>
        <div className='collaboration__blocks-wrapper'>
          <div className='collaboration__block collaboration__block_top'>
            <Icon className='collaboration__whiteLabel-logo' type='logo-mshoebox' />
            <div className='collaboration__block-text collaboration__block-text_top'>{t('collaboration', 'with').s}</div>
          </div>
          <div className={this.bottomBlockClass}>
            {this.renderPartnerIcon()}
            <div className='collaboration__block-text collaboration__block-text_bottom'>{partnerOffer}</div>
          </div>
          {
            (readMoreLink || readMoreHandler) &&
            <Link
              to={readMoreLink}
              onClick={readMoreHandler}
              className='collaboration__link'
            >
              {t('collaboration', 'link').s}
            </Link>
          }
        </div>
        <ButtonsBlock
          {...this.props}
          cancelButtonView='transparent-white'
          startButtonText={startButtonText || t('collaboration', 'button', 'start').s}
          cancelButtonText={t('collaboration', 'button', 'cancel').s}
        />
      </div>
    )
  }
}
