import React, { Component } from 'react'
import PropTypes from 'prop-types'

import FactoringMore from 'MarketPlace/Collector/views/FactoringMore/FactoringMore'
import FactoringHOC from 'MarketPlace/Collector/views/shared/FactoringHOC'
import IpadNavigation from 'Navigation/IpadNavigation'
import More from 'More/views/MoreView/containers/Smartphones'

class FactoringMoreView extends Component {
  static propTypes = {
    t: PropTypes.func,
    location: PropTypes.object.isRequired,
    startHandler: PropTypes.func.isRequired,
    cancelHandler: PropTypes.func.isRequired
  }

  render () {
    const { t, location, startHandler, cancelHandler } = this.props
    const navBarTitle = t('FactoringInfo', 'title').s

    return (
      <IpadNavigation title={navBarTitle} location={location} withoutMaxWidth>
        <More notRightIcon isIpad />
        <FactoringMore t={t} startHandler={startHandler} cancelHandler={cancelHandler} />
      </IpadNavigation>
    )
  }
}

export default FactoringHOC(FactoringMoreView)
