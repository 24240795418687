import * as eventType from 'Events/shared/constants/eventType'
import { pushEventAction as pushEventBaseAction } from 'Events/shared/actions/pushEvent'

// TODO убрать isNewEvent
export function pushEventAction (isNewEvent, event, cameFrom) {
  const data = {
    ...event,
    type: eventType.moneyWithdrawal,
    vatAmount: 0, // for eventRows
    isInvoice: false
  }
  // проверить - есть ли withdrawal.categoryId(иначе будет лишнее поле в транзакции)
  // withdrawal.vat_amount = 0
  // ничего, что vat в тразакциях добавляется?
  return pushEventBaseAction(data, cameFrom, 'withdrawal')
}
