import React, { Component } from 'react'
import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'

export function withCounterpartyMethods (WrappedComponent) {
  return class Counterparty extends Component {
    static propTypes = {
      counterparty: PropTypes.object,
      params: PropTypes.object,
      loadInfo: PropTypes.func,
      deleteCounterparty: PropTypes.func,
      clearState: PropTypes.func,
      t: PropTypes.func
    }

    constructor (props) {
      super(props)
      this.deleteCounterpartyHandler = this.deleteCounterpartyHandler.bind(this)
      this.showModalBox = this.showModalBox.bind(this)
    }

    showModalBox () {
      this.modal.open()
    }

    deleteCounterpartyHandler () {
      const { deleteCounterparty, params: { counterpartyId } } = this.props
      this.modal.close()
      deleteCounterparty(counterpartyId)
    }

    componentDidMount () {
      const { counterparty, params: { counterpartyId }, loadInfo } = this.props

      if (counterpartyId && counterpartyId !== 'new' && isEmpty(counterparty)) {
        loadInfo(counterpartyId)
      }
    }

    componentWillReceiveProps (props) {
      const { params: { counterpartyId }, loadInfo, clearState } = this.props

      if (props.params.counterpartyId === 'new' && counterpartyId !== props.params.counterpartyId) {
        clearState()
      }

      if (counterpartyId !== props.params.counterpartyId && props.params.counterpartyId !== 'new') {
        clearState()
        loadInfo(props.params.counterpartyId)
      }
    }

    render () {
      return (
        <WrappedComponent
          {...this.props}
          provideController={(c) => { this.modal = c }}
          deleteCounterpartyHandler={this.deleteCounterpartyHandler}
        />
      )
    }
  }
}
