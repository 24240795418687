import './VatReports.scss?global'

import Smartphones from 'Overview/VatReports/containers/Smartphones'
import Tablets from 'Overview/VatReports/containers/Tablets'

import {getCurrentDeviceView} from 'shared/helpers/utils'

const VatReportsView = getCurrentDeviceView(Smartphones, Tablets)

export default VatReportsView
