import React, { Component } from 'react'
import PropTypes from 'prop-types'
import cs from 'classnames'
import { connect } from 'react-redux'

// Components

import Navigation from 'Navigation/Navigation'
import MoreLinks from 'More/components/MoreLinks'

class MoreCustomerInvoicesView extends Component {
  static propTypes = {
    t: PropTypes.func
  }

  getMenuList () {
    const {t} = this.props
    return [
      {icon: 'icon-customer-invoices', title: t('buttons', 'customerInvoices').s, path: '/invoices', featureName: 'invoices'},
      {icon: 'icon-gear', title: t('buttons', 'settings').s, path: '/settings/invoice', featureName: 'settings'},
      {icon: 'icon-customers', title: t('buttons', 'customers').s, path: '/customers', featureName: 'invoices'},
      {icon: 'icon-articles', title: t('buttons', 'articles').s, path: '/articles', featureName: 'invoices'}
    ]
  }

  render () {
    const { t } = this.props
    const navClassName = cs('more', 'more-customer-invoices')

    return (
      <Navigation title={t('navbar', 'title').s} className={navClassName} backLink='/more' forceBackLink>
        <MoreLinks linksConfig={this.getMenuList()} />
      </Navigation>
    )
  }
}

function mapStateToProps (state) {
  return {
    t: state.i18n.get('app', 'views', 'MoreCustomerInvoicesView')
  }
}

export default connect(mapStateToProps)(MoreCustomerInvoicesView)
