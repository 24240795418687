import React from 'react'
import PropTypes from 'prop-types'

import Icon from 'shared/components/Icon'
import './styles.scss?global'

const CollectorLayout = ({children}) =>
  <div className='collector-layout'>
    <div className='collector-bank'>
      <Icon className='collector-bank__bank-logo' type='cl_bank_logo' />
    </div>
    {children}
  </div>

CollectorLayout.propTypes = {
  children: PropTypes.element.isRequired
}

export default CollectorLayout
