import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import * as Sentry from '@sentry/browser'

import { orgNumberMaskProps } from 'mrshoebox-ui-components/src/helpers/countrySpecific'
import { required } from 'shared/helpers/formValidations'
import Input from 'shared/components/Input/Input'
import Button from 'shared/components/Button/Button'
import Spinner from 'shared/components/Spinner/Spinner'

import {
  runCreditsafeReportRequest
} from 'MarketPlace/Creditsafe/actions'

import Alert from '../Alert'

import styles from '../../styles.scss'

export default function CreditsafeReportForm (props) {
  const { t } = props

  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(false)
  const [isSendingReport, setIsSendingReport] = useState(false)

  const getReport = async (data) => {
    setError(null)
    setIsSendingReport(true)
    setSuccess(false)

    var response = await runCreditsafeReportRequest(data)

    if (response.data.success) {
      // stripe confirmation window
      if (response.data.do_redirect) {
        window.location = response.data.redirect_url
      }
      setSuccess(true)
    } else {
      Sentry.withScope(scope => {
        scope.setExtra('message', response.data)
        Sentry.captureException('Creditsafe failed on getting report')
      })
      setError(t('somethingWrong').s)
    }
    setIsSendingReport(false)
  }

  if (isSendingReport) {
    return (
      <div className={styles.container}>
        <h1>{ t('reportLoading').s }</h1>
        <Spinner dark />
      </div>
    )
  }

  return (
    <div className={styles.container}>
      <h1>{ t('makeReport').s }</h1>
      <div className={styles.inputs}>
        {error && <Alert error={error} /> }
        {success && <Alert success={t('reportSended').s} /> }
        <Field
          placeholder={t('fields', 'orgNumber').s}
          component={InputWrapper}
          name='orgNumber'
          type='number'
          validate={[ required ]}
          {...orgNumberMaskProps}
        />
        <Button view='primary' onClick={props.handleSubmit(getReport)}>{ t('start').s }</Button>
      </div>
    </div>
  )
}

CreditsafeReportForm.propTypes = {
  handleSubmit: PropTypes.func
}

const InputWrapper = ({ input, meta, ...props }) => {
  return <Input {...input} {...meta} {...props} />
}
InputWrapper.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object
}
