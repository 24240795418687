import ZignsecInteractor from 'User/shared/interactors/zignsecInteractor'
import uuid from 'uuid/v4'

import getGreyLabelOptions from 'GreyLabel/helpers/greyLabel'
import signUpAction from '../signup'

import { showNavbarSpinnerAction } from 'shared/actions/navbarSpinner'

export default function registerWithBankIDWithoutPersonalNumber (formData) {
  return async (dispatch) => {
    dispatch(showNavbarSpinnerAction())

    ZignsecInteractor.initialize({
      connectionParams: {
        channel: 'Zignsec::Bankidse::AuthChannel',
        token: uuid(),
        check_only: true,
        target: 'https://mage.mrshoebox.com/',
        white_label: getGreyLabelOptions().whiteLabel
      },
      onSuccess: maybeRegister.bind(this, dispatch, formData)
    })
    ZignsecInteractor.startBankIDAuth()
  }
}

function maybeRegister (dispatch, formData, dataFromChannel) {
  const dataForSignUp = {
    ...formData,
    gdpr_agreement: formData.gdprAgreement,
    name: dataFromChannel.data.name,
    personalNumber: dataFromChannel.data.personalNumber
  }

  dispatch(signUpAction(dataForSignUp))
}
