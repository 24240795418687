import '../TimesheetsView.scss?global'

import PropTypes from 'prop-types'

import React, { Component } from 'react'
import { Link } from 'react-router'
import { connect } from 'react-redux'

// Components
import Navigation from 'Navigation/Navigation'
import FormatDate from 'shared/components/FormatDate/FormatDate'
import ListView from 'shared/components/ListView/ListView'
import Filters from '../Filters/Filters'

// Actions
import { reloadTimesheetsListAction, extendTimesheetsListAction, cleanTimesheetsListAction } from 'Timesheet/actions/timesheetsList'

// Helpers
import { setTime } from 'shared/helpers/utils'

function TimesheetsListItem (values) {
  const { exported, startTime, stopTime, title, minutes, projectName, id } = values.data
  return (
    <Link to={`/timesheets/${id}/edit`} className='timesheet-list-item'>
      <div className='timesheet-list-item__column'>
        <h4 data-field='title'>{`${title}`} { projectName && (
          <span className='timesheet-list-item__project'>
            <span className='timesheet-list-item__project-delimiter'>| </span>
            <small>{ projectName }</small>
          </span>
        ) }
        </h4>
        <h5 data-field='startDate' className='color-green'><FormatDate value={startTime} format='YYYY-MM-DD HH:mm' /></h5>
      </div>
      <div className='timesheet-list-item__last'>
        <div className='timesheet-list-item__icons'>
          { exported && <span className='icon-bills-full' /> }
        </div>
        <div className='timesheet-list-item__column'>
          <h4 data-field='minutes'>{setTime(minutes, 'minutes', 'HHs:MMs')}</h4>
          <h5 data-field='stopDate' className='color-red'><FormatDate value={stopTime} format='YYYY-MM-DD HH:mm' /></h5>
        </div>
      </div>
    </Link>
  )
}

class TimesheetsView extends Component {
  static propTypes = {
    reloadList: PropTypes.func,
    extendList: PropTypes.func,
    cleanTimesheetsList: PropTypes.func,
    timesheets: PropTypes.arrayOf(PropTypes.object),
    t: PropTypes.func
  }

  constructor (props) {
    super(props)
    this.state = { currentTab: 0 }
  }

  componentDidMount () {
    this.props.reloadList()
  }

  componentWillUnmount () {
    this.props.cleanTimesheetsList()
  }

  render () {
    const {t, timesheets, extendList} = this.props
    return (
      <Navigation backLink='/more' title={t('navbar', 'title').s} forceBackLink actions={[{icon: 'icon-add', path: '/timesheets/new'}]} infinityScrollHandler={extendList} filterBlock>
        <div>
          <Filters canBeHidden={!timesheets.length} />
        </div>
        <ListView data={timesheets} template={TimesheetsListItem} emptyListOptions={{icon: 'icon-timesheets'}} />
      </Navigation>
    )
  }
}

function mapStateToProps (state) {
  return {
    timesheets: state.timesheets.listData,
    navbarSpinner: state.navbarSpinner.value,
    t: state.i18n.get('app', 'views', 'Timesheet')
  }
}

function mapDispatchToProps (dispatch) {
  return {
    extendList: () => { dispatch(extendTimesheetsListAction()) },
    reloadList: () => { dispatch(reloadTimesheetsListAction()) },
    cleanTimesheetsList: () => { dispatch(cleanTimesheetsListAction()) }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TimesheetsView)
