import './Tabs.scss?global'

import PropTypes from 'prop-types'

import React, { Component } from 'react'
import getGreyLabelOptions from 'GreyLabel/helpers/greyLabel'

export default class Tabs extends Component {
  static propTypes = {
    config: PropTypes.array.isRequired,
    onChange: PropTypes.func,
    className: PropTypes.string,
    initValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  }

  constructor (props) {
    super(props)
    this.state = {active: null}
  }

  onClick (key, action, e) {
    const {onChange} = this.props
    onChange && typeof onChange === 'function' && onChange(key)
    this.setState({active: key})
    action && typeof action === 'function' && action.call(this, e)
  }

  clearTab () {
    this.setState({active: null})
  }

  render () {
    const {config, className = '', initValue} = this.props
    const {active} = this.state
    const { whiteLabel } = getGreyLabelOptions()

    if (config && config.length) {
      var options = config.map((option, key) => {
        const value = (option.value || option.value === null) ? option.value : key
        const extraClassName = ((!active && option.active) || (active === value && !initValue) || initValue === value)
          ? 'active'
          : ''
        const permissionProps = option.getPermissionProps ? option.getPermissionProps(extraClassName) : {}

        return (
          <div key={key} className={`${extraClassName} ${option.disabled ? 'disable-feature' : ''}`} {...permissionProps}>
            <div onClick={() => { this.onClick(value, option.action) }}>
              {option.label}
            </div>
          </div>
        )
      })
      return <div className={`tabs ${whiteLabel ? `tabs--${whiteLabel}` : ''} ${className}`}>{options}</div>
    } else return null
  }
}
