import React from 'react'
import PropTypes from 'prop-types'

import Button from 'shared/components/Button/Button'

import './styles.scss?global'

const SuccessDelivery = ({ t, close }) =>
  <div className='success-delivery'>
    <div className='success-delivery__result'>
      <h1 className='success-delivery__title'>{t('common', 'mailDeliveredTitle').s}</h1>
      <span className='success-delivery__info'>
        {t('common', 'mailDeliveredMessage').s}
      </span>
    </div>
    <Button view={'transparent-white'} onClick={() => close()}>
      {t('common', 'mailDeliveredButton').s}
    </Button>
  </div>

SuccessDelivery.propTypes = {
  t: PropTypes.func,
  close: PropTypes.func
}

export default SuccessDelivery
