import {
  APP_INFO_SET_PREVIOUS_LINK,
  APP_INFO_SET_ACTIVE_ITEM_ID,
  APP_INFO_SET_INTERNET_IS_LOST,
  APP_INFO_SET_NOTIFICATION_VISIBLE,
  APP_INFO_SET_WRAPPER_VERSION,
  APP_INFO_RELEASE_NAME
} from 'AppInfo/constants/ActionTypes'

const initialState = {
  previousLink: '',
  activeItemId: '',
  internetIsLost: false,
  notificationVisible: null,
  wrapperVersion: '',
  wrapperBuild: ''
}

export default function (state = initialState, action) {
  switch (action.type) {
    case APP_INFO_SET_PREVIOUS_LINK:
      return {
        ...state,
        previousLink: action.payload
      }
    case APP_INFO_SET_ACTIVE_ITEM_ID:
      return {
        ...state,
        activeItemId: action.payload
      }
    case APP_INFO_SET_INTERNET_IS_LOST:
      return {
        ...state,
        internetIsLost: action.payload,
        notificationVisible: null
      }
    case APP_INFO_SET_NOTIFICATION_VISIBLE:
      return {
        ...state,
        notificationVisible: action.payload
      }
    case APP_INFO_SET_WRAPPER_VERSION:
      return {
        ...state,
        wrapperVersion: action.payload.version,
        wrapperBuild: action.payload.build
      }
    case APP_INFO_RELEASE_NAME:
      return {
        ...state,
        releaseName: action.payload
      }
    default:
      return state
  }
}
