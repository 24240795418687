import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
// import { isDesktop } from 'shared/helpers/utils'

import Button from 'shared/components/Button/Button'
import BackLink from 'User/shared/components/BackLink/BackLink'

import { Container, UpperContent, BottomContent } from '../../../Auth/components/LoginContainer'

import { getBackgroundPath } from 'User/shared/helpers'
const backgroundPath = getBackgroundPath()
class SelectSignUpMethodView extends Component {
  static propTypes = {
    t: PropTypes.func
  }

  render () {
    const { t } = this.props
    // const bankIDLabel = isDesktop()
    //   ? `${t('buttons', 'mobileBankID').s}/${t('buttons', 'bankID').s}`
    //   : t('buttons', 'mobileBankID').s
    return (
      <Container background={backgroundPath}>
        <UpperContent>
          &nbsp;
        </UpperContent>
        <BottomContent>
          {/* <Button view='primary' svgIcon='bankid' link='/sign_up_bankid' >{bankIDLabel}</Button>
          <div className='hr2'><span>{t('labels', 'or').s}</span></div> */}
          <Button view='transparent-white' link='/sign_up_password'>{t('fields', 'password').s}</Button>
        </BottomContent>
        <BackLink transparent link='/entry' text={t('back').s} />
      </Container>
    )
  }
}

function mapStateToProps (state) {
  return {
    t: state.i18n.get('app', 'views', 'SignUpView')
  }
}

export default connect(mapStateToProps)(SelectSignUpMethodView)
