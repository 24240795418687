import './SettingsList.scss?global'

import PropTypes from 'prop-types'

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { reduxForm, getFormValues, getFormMeta, change, Field, getFormSyncErrors } from 'redux-form'
import Moment from 'moment'
import { extendMoment } from 'moment-range'
import { saveOverviewData } from 'Overview/actions/stats'

import { STATUS_APPROVED, STATUS_REJECTED } from 'Events/shared/constants/EventStatus'

// Components
import Modal from 'shared/components/NewModal/NewModal'
import Button from 'shared/components/Button/Button'
import Input from 'shared/components/FormInput'
import Dropdown from 'shared/components/FormDropdown'

// Constants
import { RESULT_TYPE, FORECAST_TYPE } from 'Overview/constants/OverviewSettings'

const moment = extendMoment(Moment)

class SettingsModal extends Component {
  static propTypes = {
    changeFieldValue: PropTypes.func,
    formSyncErrors: PropTypes.object,
    formValues: PropTypes.object,
    handleSubmit: PropTypes.func,
    meta: PropTypes.object,
    onSaveClick: PropTypes.func,
    provideController: PropTypes.func,
    settings: PropTypes.object,
    t: PropTypes.func,
    type: PropTypes.string
  }

  constructor (props) {
    super(props)
    this.onFromChange = this.onFromChange.bind(this)
    this.onToChange = this.onToChange.bind(this)
  }

  componentDidMount () {
    const modalInstance = this.modal
    this.props.provideController({
      open: this.modal.open.bind(modalInstance),
      close: this.modal.close.bind(modalInstance)
    })
  }

  componentWillReceiveProps (props) {
    const {
      formValues: { selectedYearIndex },
      settings: { type, result: { fiscalYears } },
      formValues, changeFieldValue
    } = props

    const selectedFiscalYear = fiscalYears && fiscalYears[selectedYearIndex]
    let dateFrom
    let dateTo

    if (type === RESULT_TYPE && selectedFiscalYear) {
      dateFrom = moment(selectedFiscalYear.start, 'YYYY-MM-DD')
      dateTo = moment(selectedFiscalYear.end, 'YYYY-MM-DD')
    }

    if (dateTo && dateFrom && !formValues.from && !formValues.to) {
      changeFieldValue('from', dateFrom.format('YYYY-MM-DD'))
      changeFieldValue('to', dateTo.format('YYYY-MM-DD'))
    }
  }

  save = (values) => {
    const { settings: { type } } = this.props
    this.props.onSaveClick({ ...values, type })
    this.modal.close()
  }

  get additionalFields () {
    const { t } = this.props
    const statuses = [
      {label: t('statuses', 'all').s, value: '0'},
      {label: t('statuses', 'approved').s, value: STATUS_APPROVED},
      {label: t('statuses', 'rejected').s, value: STATUS_REJECTED}
    ]

    return (
      <div className='overview-settings-forecast'>
        <div className='form__item overview-settings-dropdown'>
          <Field name='eventStatus' component={Dropdown} big hint={t('eventStatus').s} options={statuses} />
        </div>
      </div>
    )
  }

  get yearsDropdown () {
    const { changeFieldValue, settings: { result: { fiscalYears } }, t } = this.props
    const format = (val) => moment(val, 'YYYY-MM-DD').format('YYYY/MM/DD')
    const onChange = () => {
      changeFieldValue('from', '')
      changeFieldValue('to', '')
    }
    if (fiscalYears) {
      const years = fiscalYears.map((year, i) => {
        return {label: `${format(year.start)} - ${format(year.end)}`, value: String(i)}
      })
      return (
        <div className='form__item'>
          <Field name='selectedYearIndex' component={Dropdown} big hint={t('financialYear').s} onChange={onChange} options={years} />
        </div>
      )
    } else {
      return null
    }
  }

  get monthsDropdown () {
    const {
      settings: { result: { fiscalYears } },
      formValues: { selectedYearIndex }, t
    } = this.props
    const selectedIndex = selectedYearIndex || 0
    const selectedFiscalYear = fiscalYears && fiscalYears[selectedIndex]

    if (selectedFiscalYear) {
      let { dateFrom, dateTo } = this.formReportDateValues(selectedFiscalYear)

      const monthsRange = moment.range(dateFrom, dateTo)

      let datesFrom = []
      let datesTo = []

      Array.from(monthsRange.by('months')).forEach((month) => {
        datesFrom.push({ label: month.format('YYYY-MM'), value: month.format('YYYY-MM-DD') })
        datesTo.push({ label: month.format('YYYY-MM'), value: month.endOf('month').format('YYYY-MM-DD') })
      })
      return (
        <div className='overview-settings__dates'>
          <Field name='from' component={Dropdown} big hint={t('dateFrom').s} options={datesFrom} />
          <Field name='to' component={Dropdown} big hint={t('dateTo').s} options={datesTo} />
        </div>
      )
    }
    return null
  }

  formReportDateValues (selectedFiscalYear) {
    const dateFrom = moment(selectedFiscalYear.start, 'YYYY-MM-DD')
    const dateTo = moment(selectedFiscalYear.end, 'YYYY-MM-DD')

    return { dateFrom, dateTo }
  }

  get periodDatePickers () {
    const { t } = this.props
    return (
      <div className='overview-settings__datepickers'>
        <Field name='from' component={Input} placeholder={t('dateFrom').s} onChange={this.onFromChange} type='date' />
        <Field name='to' component={Input} placeholder={t('dateTo').s} onChange={this.onToChange} type='date' />
      </div>
    )
  }

  onFromChange (e) {
    const currentFrom = (e.target && e.target.value) || e
    this.props.changeFieldValue('from', currentFrom)
  }

  onToChange (e) {
    const currentTo = (e.target && e.target.value) || e
    this.props.changeFieldValue('to', currentTo)
  }

  isVisibleContent () {
    const { type, settings: {result: {fiscalYears}} } = this.props
    return (type === RESULT_TYPE && fiscalYears && fiscalYears.length) || (type && type !== RESULT_TYPE)
  }
  isPeriodError () {
    const { meta, formSyncErrors } = this.props
    return ((meta.from && meta.from.touched && formSyncErrors.from) || (meta.to && meta.to.touched && formSyncErrors.to))
  }
  render () {
    const { t, handleSubmit, settings: { type } } = this.props

    return (
      <Modal ref={(c) => { this.modal = c }}>
        {this.isVisibleContent() && <div className='f-column overview-settings'>
          <div className='form f-column-top'>
            <h1 className='overview-settings-title'><b>{t('title').s}</b></h1>
            {type === RESULT_TYPE ? this.yearsDropdown : this.additionalFields}
            {type === RESULT_TYPE ? this.monthsDropdown : this.periodDatePickers}
            {this.isPeriodError() && <div className='overview-settings__error'>{t('error', type).s}</div>}
          </div>
          <div className='f-column-bottom'>
            <Button view='transparent-black' onClick={handleSubmit(this.save)}>Ok</Button>
          </div>
        </div>}
        {!this.isVisibleContent() && <div>
          <h3>{t('emptyMessage').s}</h3>
        </div>}
      </Modal>
    )
  }
}

const formName = 'overviewSettings'
const selector = getFormValues(formName)
const metaSelector = getFormMeta(formName)
const errorsSelector = getFormSyncErrors(formName)

function mapStateToProps (state, ownProps) {
  const { type, result, forecast } = state.overview.settings
  const popupType = ownProps.type || type
  const values = popupType === RESULT_TYPE ? result : forecast
  return {
    initialValues: {
      type: popupType,
      ...values
    },
    settings: { ...state.overview.settings, type: popupType },
    t: state.i18n.get('app', 'views', 'OverviewView', 'components', 'SettingsList'),
    formValues: selector(state) || {},
    meta: metaSelector(state),
    formSyncErrors: errorsSelector(state)
  }
}

function mapDispatchToProps (dispatch) {
  return {
    saveSettings: (data) => { dispatch(saveOverviewData(data)) },
    changeFieldValue: (fieldName, value) => dispatch(change(formName, fieldName, value))
  }
}

function validate (values) {
  let errors = {}
  let { from, to, type } = values

  const dateFrom = moment(from, 'YYYY-MM-DD')
  const dateTo = moment(to, 'YYYY-MM-DD')

  if (dateTo.isBefore(dateFrom) || dateFrom.isSame(dateTo)) {
    errors.from = true
    errors.to = true
  }

  if (type === FORECAST_TYPE && dateTo.diff(dateFrom, 'months') > 18) {
    errors.from = true
    errors.to = true
  }

  return errors
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: 'overviewSettings',
  validate
})(SettingsModal))
