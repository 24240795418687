import {bind} from 'redux-effects'
import {createAction} from 'redux-actions'
import {EVENTS_LIST_RELOADING, EVENTS_LIST_UPDATE, EVENTS_LIST_CLEAR} from 'Events/shared/constants/Event'
import {EVENTS_LIST} from 'Events/shared/constants/Api'
import { isEqual, omit } from 'lodash'
import { getFormValues } from 'redux-form'

import {STATUS_APPROVED, STATUS_REJECTED, STATUS_VOIDED} from 'Events/shared/constants/EventStatus'
import {INVOICE_PAPER_KIND} from 'Events/shared/constants/eventType'
import { showNavbarSpinnerAction, hideNavbarSpinnerAction } from 'shared/actions/navbarSpinner'

import {fetch} from 'shared/helpers/fetch'

import { defaultFilterValues } from 'Events/Events/components/Filter/Filter'
import getDocumentName from 'Events/shared/helpers/eventTypeName'
import { getEventPlusMinusProperty } from 'Events/shared/helpers/eventType'
import { getCategory } from 'Events/shared/helpers/eventDetails'
import { formatTransaction } from './event'

const updateEventsList = createAction(EVENTS_LIST_UPDATE)
const reloadingEventsList = createAction(EVENTS_LIST_RELOADING)
export const clearEventsListAction = createAction(EVENTS_LIST_CLEAR)

export function reloadEventsAction (filters, filtersFromReduxFormUsed = false) {
  return [
    showNavbarSpinnerAction(),
    reloadingEventsList(),
    bind(fetchEvents(filters), processEvents.bind(null, [], filtersFromReduxFormUsed ? filters : null, true), processError)
  ]
}

export function extendEventsAction (currentList, filters, newOffset, filtersFromReduxFormUsed = false, isInitialRequest = false) {
  return [
    showNavbarSpinnerAction(),
    bind(fetchEvents(filters, newOffset), processEvents.bind(null, currentList, filtersFromReduxFormUsed ? filters : null, isInitialRequest), processError)
  ]
}

export function fetchEvents (filters, newOffset) {
  const newFilters = newOffset ? {...processFilters(filters), offset: newOffset} : processFilters(filters)
  return fetch(EVENTS_LIST, { ...omit(newFilters, ['sortBy', 'sortByUserDocId']), sort: [newFilters.sortBy, newFilters.sortByUserDocId] }, {
    method: 'GET'
  })
}

function processEvents (oldList, filtersBeforeResponseReceived, isInitialRequest = false, response) {
  return (dispatch, getState) => {
    console.log('processEvents', response)
    const t = getState().i18n.get('app', 'views', 'EventView')

    if (filtersBeforeResponseReceived) {
      // prevent race conditions on events list if user change filter values very fast
      const currentFilters = getFormValues('eventsViewFilters')(getState())
      if (!isEqual(currentFilters, filtersBeforeResponseReceived)) {
        return
      }
    }
    const events = response.value.source_documents
    const newList = oldList.concat(events.map((event) => {
      const categoryInfo = getCategory(event, event.type)
      const category = event.transactions.length > 1
        ? t('multipleCategories').s
        : categoryInfo ? categoryInfo.name : ''
      let mappedEvent = {
        id: event.id,
        number: event.user_document_id,
        date: event.document_date,
        category,
        amount: event.total_amount,
        approved: event.status === STATUS_APPROVED,
        error: event.status === STATUS_REJECTED,
        strike: event.status === STATUS_VOIDED,
        expenseType: getEventPlusMinusProperty(event.type),
        hasUnreadAdminComments: event.has_unread_admin_comments,
        hasComments: Boolean(event.comments && event.comments.length),
        isPaid: event.is_paid,
        eventType: event.type,
        invoiceNumber: event.invoice_number ? event.invoice_number : null,
        // FIXME what is so condition - event.transactions[0] ?
        transactions: event.transactions[0] ? event.transactions.map(t => formatTransaction(event.type, t)) : [],
        isInvoice: event.paper_kind === INVOICE_PAPER_KIND
      }
      const documentName = getDocumentName(mappedEvent, t)

      return {
        ...mappedEvent,
        title: (event.title && event.title.length) ? event.title : String(documentName)
      }
    }))

    const lastOffset = getState().events.lastOffset
    const newLastOffset = isInitialRequest ? lastOffset : lastOffset + 20
    const numberOfDocuments = response.value.number_of_source_documents

    return dispatch([updateEventsList({data: newList, hasNextPage: checkNextPage(numberOfDocuments, newLastOffset), lastOffset: newLastOffset}), hideNavbarSpinnerAction()])
  }
}

function checkNextPage (numberOfDocuments, newLastOffset) {
  return (numberOfDocuments - newLastOffset) > 0
}

function processError (response) {
  console.log('events-processError', response)
}

function processFilters (filters) {
  // TODO: refactor all this *bad things*
  let result = {
    limit: filters && filters.limit !== undefined ? filters.limit : 20,
    offset: 0
  }

  if (filters) {
    result = filters.orderBy
      ? {
        ...result,
        sortBy: `${filters.orderType === 'desc' ? '-' : ''}${filters.orderBy}`,
        sortByUserDocId: `${filters.orderType === 'desc' ? '-' : ''}user_document_id`
      }
      : result
    result = filters.eventsIds ? Object.assign({ids: filters.eventsIds.join(',')}, result) : result
    result = filters.search ? Object.assign(formatSearchQuery(filters.search), result) : result
    result = filters.eventType ? {...result, types: filters.eventType} : result
    result = filters.projectId ? {...result, project_id: filters.projectId} : result
    result = filters.status ? Object.assign({statuses: filters.status}, result) : result
    result = filters.unread_comments ? {...result, has_unread_admin_comments: filters.unread_comments} : result
    result = filters.dateFrom ? Object.assign({ date_from: filters.dateFrom }, result) : result
    result = filters.dateTo ? Object.assign({ date_to: filters.dateTo }, result) : result
    result = filters.categoryId ? Object.assign({ category_id: filters.categoryId }, result) : result
    result = filters.supplierIds ? Object.assign({ supplier_ids: filters.supplierIds }, result) : result
    result = filters.invoiceApprovedForPayment ? Object.assign({ invoice_approved_for_payment: filters.invoiceApprovedForPayment }, result) : result
    result = filters.fields ? Object.assign({fields: filters.fields}, result) : result

    // Only from events filter, there can be multiple values or different field names
    if (filters.documentType) {
      const documentType = JSON.parse(filters.documentType)
      result = {...result, ...documentType}
    }
    result = filters.invoiceType ? Object.assign(JSON.parse(filters.invoiceType), result) : result
  } else {
    result = {
      ...result,
      sortBy: `${defaultFilterValues.orderType === 'desc' ? '-' : ''}${defaultFilterValues.orderBy}`,
      sortByUserDocId: `${defaultFilterValues.orderType === 'desc' ? '-' : ''}user_document_id`
    }
  }
  // console.log('processFilters', filters, result)
  return result
}

function formatSearchQuery (searchValue) {
  const preparedValue = Math.floor(parseFloat(searchValue.replace(',', '.')))
  if (!isNaN(searchValue.replace(',', '').replace('.', ''))) {
    return {
      'total_amount__gte': preparedValue,
      'total_amount__lt': preparedValue + 1
    }
  } else {
    return {
      'title__contains': searchValue
    }
  }
}
