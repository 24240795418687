import { TIMESHEETS_LIST_UPDATE, TIMESHEETS_LIST_RELOADING, TIMESHEETS_LIST_CLEAN, TIMESHEET_PROP_UPDATE, TIMESHEET_PROP_CLEAN, TIMESHEET_SET_TIMER_STATE } from '../constants/Timesheet'

const initialState = {
  lastPageLoaded: 0,
  hasNextPage: true,
  listData: [],
  newTimesheet: {},
  timerIsActive: false
}

export default function (state = initialState, action) {
  switch (action.type) {
    case TIMESHEETS_LIST_RELOADING:
      return {
        ...state,
        lastPageLoaded: 0
      }
    case TIMESHEETS_LIST_UPDATE:
      return {
        ...state,
        ...action.payload
      }
    case TIMESHEETS_LIST_CLEAN:
      return {
        ...state,
        listData: initialState.listData,
        hasNextPage: initialState.hasNextPage,
        lastPageLoaded: initialState.lastPageLoaded
      }
    case TIMESHEET_PROP_UPDATE:
      return {
        ...state,
        newTimesheet: {
          ...state.newTimesheet,
          ...action.payload
        }
      }
    case TIMESHEET_SET_TIMER_STATE:
      return {
        ...state,
        timerIsActive: action.payload
      }
    case TIMESHEET_PROP_CLEAN:
      return {
        ...state,
        newTimesheet: {},
        timerIsActive: false
      }
    default:
      return state
  }
}
