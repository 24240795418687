import React, { Component } from 'react'
import PropTypes from 'prop-types'
import cs from 'classnames'
import { connect } from 'react-redux'

// Components
import Navigation from 'Navigation/Navigation'
import MoreLinks from 'More/components/MoreLinks'

class MoreSupplierInvoicesView extends Component {
  static propTypes = {
    t: PropTypes.func
  }

  getMenuList () {
    const {t} = this.props
    return [
      {icon: 'icon-supplier-invoices', title: t('buttons', 'supplierInvoices').s, path: '/supplier-invoices', featureName: 'invoices'},
      {icon: 'icon-customers', title: t('buttons', 'suppliers').s, path: '/suppliers', featureName: 'invoices'}
    ]
  }

  render () {
    const { t } = this.props
    const navClassName = cs('more', 'more-supplier-invoices')

    return (
      <Navigation title={t('navbar', 'title').s} className={navClassName} backLink='/more' forceBackLink>
        <MoreLinks linksConfig={this.getMenuList()} />
      </Navigation>
    )
  }
}

function mapStateToProps (state) {
  return {
    t: state.i18n.get('app', 'views', 'MoreSupplierInvoicesView')
  }
}

export default connect(mapStateToProps)(MoreSupplierInvoicesView)
