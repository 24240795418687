import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import IpadNavigation from 'Navigation/IpadNavigation'
import More from 'More/views/MoreView/containers/Smartphones'
import { connectedOpenPaymentsOnboarding as OpenPaymentsOnboarding } from './Smartphones'

const OpenPaymentsOnboardingView = ({ navBarTitle, location }) =>
  <IpadNavigation title={navBarTitle} location={location} withoutMaxWidth>
    <More notRightIcon isIpad />
    <OpenPaymentsOnboarding location={location} />
  </IpadNavigation>

OpenPaymentsOnboardingView.propTypes = {
  navBarTitle: PropTypes.string,
  location: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
  navBarTitle: state.i18n.get('app', 'views', 'Integrations', 'OpenPayments', 'navBar', 'title').s
})

export default connect(mapStateToProps)(OpenPaymentsOnboardingView)
