import axios from 'api-client-connector'
import { getCurrentClientCompany } from 'api-client-connector/utils'

import { change } from 'redux-form'
import { updateSuggestionList } from 'shared/components/SuggestionInput/Async/reducer'
import { fetchSuppliersList } from './suppliers'
import { SUPPLIER_COMPANY_INFO, SUPPLIER_COMPANY_NAMES } from '../constants/Api'
import { country } from 'mrshoebox-ui-components/src/helpers/countrySpecific'

export function loadSuppliersSuggestions (value) {
  return async (dispatch) => {
    const companyId = getCurrentClientCompany()
    const params = {
      company_id: companyId,
      name__icontains: value,
      with_deleted: true,
      limit: 20
    }
    const response = await fetchSuppliersList(params)

    dispatch(updateSuggestionList({
      key: 'ownSuppliers',
      data: response.data.suppliers.map(supplier => ({
        name: supplier.name,
        id: supplier.id
      }))
    }))
  }
}

export function loadSupplierCompanyNames (value) {
  return async (dispatch) => {
    const response = await loadCompanyNamesRequest(value)

    dispatch(updateSuggestionList({
      key: 'supplierCompanyName',
      data: response.data.map(supplierCompany => ({
        name: supplierCompany.name,
        overviewKey: supplierCompany.overview_key,
        orgNumber: supplierCompany.org_number
      }))
    }))
  }
}

export function updateSupplierInfo (key) {
  return async (dispatch, getState) => {
    const suggestions = getState().suggestions.supplierCompanyName
    const companyWithOrgNumber = suggestions.filter(suggestion =>
      Boolean(suggestion.orgNumber) && suggestion.overviewKey === key
    )[0]

    if (companyWithOrgNumber) {
      dispatch(changeSupplierOrgNumber(companyWithOrgNumber.orgNumber))
    } else {
      const response = await getCompanyInfo({ overview_key: key })
      if (response.data.org_number) {
        dispatch(changeSupplierOrgNumber(response.data.org_number))
      }
    }
  }
}

export function updateSupplierName (orgNumber) {
  return async (dispatch) => {
    const response = await getCompanyInfo({ org_number: orgNumber })
    if (response.data.name) {
      dispatch(changeSupplierName(response.data.name))
    }
  }
}

function loadCompanyNamesRequest (value) {
  return axios.get(SUPPLIER_COMPANY_NAMES({ name: value, country_code: country }).toString())
}

function getCompanyInfo (params) {
  return axios.get(SUPPLIER_COMPANY_INFO(params))
}

function changeSupplierOrgNumber (orgNumber) {
  return change('supplier', 'orgNumber', orgNumber)
}

function changeSupplierName (name) {
  return change('supplier', 'name', name)
}
