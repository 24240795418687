import { bind } from 'redux-effects'
import { routerActions } from 'react-router-redux'
import { change } from 'redux-form'
import { getCurrentClientCompany } from 'api-client-connector/utils'
import { createAction } from 'redux-act'

import { updateInfoRequest } from 'User/Settings/actions/companySettings'
import { fetch2 as fetch } from 'shared/helpers/fetch'
import { showNavbarSpinnerAction, hideNavbarSpinnerAction } from 'shared/actions/navbarSpinner'
import { showMessageBoxWithParamsAction } from 'MessageBox/actions/messageBox'
import { updateNotificationsCountAction } from 'Notifications/actions/notificationBadges'

import loadSettingsAction from 'User/Settings/actions/settingsLoader'

import { WEB_SCRAPER } from 'Wizard/constants/Api'

export const updateWizardProfileStorage = createAction('Add some data to wizard profile reducer')

export function updateProfile (data) {
  return (dispatch, getState) => {
    const companyId = getCurrentClientCompany()
    const dataWithAddresses = {
      ...data,
      primaryAddress: getState().wizardProfile.addresses[0] || {},
      withAdditionalAddress: getState().wizardProfile.addresses.length > 1,
      additionalAddress: getState().wizardProfile.addresses[1] || {}
    }

    return dispatch([
      showNavbarSpinnerAction(),
      bind(
        updateInfoRequest(companyId, dataWithAddresses),
        () => [
          bind(loadSettingsAction(), () => updateNotificationsCountAction()),
          hideNavbarSpinnerAction(),
          routerActions.push('/wizard/next-actions')
        ],
        updateProfileError
      )
    ])
  }
}

function updateProfileError (response) {
  return (dispatch, getState) => {
    const t = getState().i18n.get('app', 'views', 'Wizard', 'Profile')
    if (response.status === 422) {
      return dispatch([
        hideNavbarSpinnerAction(),
        showMessageBoxWithParamsAction('', t('alreadyRegistered').s)
      ])
    } else {
      return dispatch(hideNavbarSpinnerAction())
    }
  }
}

export function fillUpCompanyInfoFieldsAction (orgNumber) {
  if (!orgNumber) {
    return []
  }

  return [
    showNavbarSpinnerAction(),
    bind(getCompanyInfoRequest(orgNumber), fillUpFields, onErrorResponse)
  ]
}

function getCompanyInfoRequest (orgNumber) {
  return fetch(WEB_SCRAPER, { org_number: orgNumber.match(/\d+/g).join('') })
}

export function fillUpFields (response) {
  const data = mapCompanyInfoResponse(response.value)
  const reduxFormActions = Object.keys(data).map((field) => {
    return change('wizard-profile', field, data[field])
  })
  const addresses = mapAddressesInfo(response.value)

  return [
    hideNavbarSpinnerAction(),
    updateWizardProfileStorage({ addresses }),
    change('wizard-profile', 'allowManualInput', false),
    ...reduxFormActions
  ]
}

function onErrorResponse () {
  return [
    change('wizard-profile', 'allowManualInput', true),
    hideNavbarSpinnerAction()
  ]
}

function mapCompanyInfoResponse (data) {
  return {
    companyName: data.company_name,
    companyType: getCompanyType(data.legal_form),
    withAdditionalAddress: data.addresses.length > 1,
    organizationNumber: data.organization_number
  }
}

function getCompanyType (legalForm) {
  switch (legalForm) {
    case 'AB':
    case 'KB':
      return 'limited'
    case 'HB':
    case 'HB/kb':
      return 'general'
    case 'EF':
      return 'sole'
  }
}

function mapAddressesInfo (data) {
  return data.addresses.map((address) => ({
    city: address.city,
    country: 'Sweden',
    street: address.address,
    zip: address.zipcode
  }))
}
