import './Filter.scss?global'

import PropTypes from 'prop-types'

import React, { Component } from 'react'
import { reduxForm, Field, change, getFormValues } from 'redux-form'
import { connect } from 'react-redux'

// Components
import Tabs from 'shared/components/FormTabs'
import SearchField from 'shared/components/FormSearchField'
import Dropdown from 'shared/components/FormDropdown'
import SortButton from 'Events/Events/components/SortButton/SortButton'
import Button from 'shared/components/Button/Button'

// Constants
import {STATUS_UNPROCESSED, STATUS_APPROVED, STATUS_REJECTED, STATUS_VOIDED, STATUS_PREPARED} from 'Events/shared/constants/EventStatus'
import {
  outType, inType, other, moneyWithdrawal, transfer,
  moneyDeposit, INVOICE_PAPER_KIND, RECEIPT_PAPER_KIND
} from 'Events/shared/constants/eventType'

// Helpers
import {omit, isEqual} from 'lodash'
import getGreyLabelOptions from 'GreyLabel/helpers/greyLabel'

const positions = {
  collapsed: 'collapsed',
  normal: 'normal',
  open: 'open'
}

const changeFilterCssClass = (val) => {
  const filter = document.getElementsByClassName('filter')
  if (val === positions.open) {
    [].forEach.call(filter, (elem) => {
      elem.classList.toggle(`filter--${positions.open}`, true)
      elem.classList.toggle(`filter--${positions.collapsed}`, false)
    })
  }
  if (val === positions.normal) {
    [].forEach.call(filter, (elem) => {
      elem.classList.toggle(`filter--${positions.open}`, false)
    })
  }
  if (val === positions.collapsed) {
    [].forEach.call(filter, (elem) => {
      elem.classList.toggle(`filter--${positions.open}`, false)
      elem.classList.toggle(`filter--${positions.collapsed}`, true)
    })
  }
}

export const defaultFilterValues = {
  status: null,
  eventType: null,
  search: '',
  orderBy: 'document_date',
  orderType: 'desc',
  documentType: '',
  invoiceType: ''
}

class FiltersForm extends Component {
  constructor (props) {
    super(props)
    this.clearForm = this.clearForm.bind(this)
    this.onDocumentTypeChange = this.onDocumentTypeChange.bind(this)
    this.onEventTypeChange = this.onEventTypeChange.bind(this)
    this.statusTabs = React.createRef()
    this.eventTypesTabs = React.createRef()
  }
  static propTypes = {
    changeFormValue: PropTypes.func,
    t: PropTypes.func,
    formValues: PropTypes.object,
    reset: PropTypes.func,
    changePosition: PropTypes.func,
    onImportClick: PropTypes.func
  }

  changeOrderType (val) {
    this.props.changeFormValue('orderType', val)
  }

  toggleFilter (val) {
    this.props.changePosition(val)
    changeFilterCssClass(val)
  }

  clearForm () {
    this.props.reset()
    this.statusTabs.current.clearTab()
    this.eventTypesTabs.current.clearTab()
  }

  getDocumentTypeSelectItems () {
    const {formValues: {eventType}, t} = this.props
    const options = [
      {label: t('documentTypes', 'receipt').s, value: JSON.stringify({paper_kind: RECEIPT_PAPER_KIND})},
      {label: t('documentTypes', 'invoice').s, value: JSON.stringify({paper_kind: INVOICE_PAPER_KIND})},
      {label: t('documentTypes', 'creditInvoice').s, value: JSON.stringify({paper_kind: INVOICE_PAPER_KIND, is_credit: true})},
      {label: t('documentTypes', 'accountTransfer').s, value: JSON.stringify({type: transfer})},
      {label: t('documentTypes', 'deposit').s, value: JSON.stringify({types: [moneyDeposit]})},
      {label: t('documentTypes', 'withdrawal').s, value: JSON.stringify({types: [moneyWithdrawal]})}
    ]
    if (eventType === null) {
      return options
    } else if (eventType === `${inType},${moneyDeposit}`) {
      return [...options.slice(0, 3), options[4]]
    } else if (eventType === `${outType},${moneyWithdrawal}`) {
      return [...options.slice(0, 3), ...options.slice(-1)]
    } else if (eventType === other) {
      return [options[3]]
    }
  }

  getInvoiceTypeSelectItems () {
    const { t } = this.props
    return [
      {label: t('invoiceTypes', 'fullyPaid').s, value: JSON.stringify({is_paid: true})},
      {label: t('invoiceTypes', 'partiallyPaid').s, value: JSON.stringify({is_partially_paid: true})},
      {label: t('invoiceTypes', 'notPaid').s, value: JSON.stringify({is_paid: false})},
      {label: t('invoiceTypes', 'overdue').s, value: JSON.stringify({status: STATUS_VOIDED})}
    ]
  }

  getDisableConditionForInvoiceTypeSelect () {
    const { formValues: { documentType } } = this.props
    return !(JSON.parse(documentType || '{}').paper_kind === INVOICE_PAPER_KIND)
  }

  onDocumentTypeChange (val) {
    const { changeFormValue } = this.props
    changeFormValue('documentType', val)
    changeFormValue('invoiceType', '')
  }

  onEventTypeChange (val) {
    const { changeFormValue } = this.props
    changeFormValue('eventType', val)
    changeFormValue('documentType', '')
    changeFormValue('invoiceType', '')
  }

  isShowClearButton () {
    const { formValues } = this.props
    const defaultObject = omit(defaultFilterValues, 'status')
    const currentObject = omit(formValues, 'status')
    return !isEqual(defaultObject, currentObject)
  }

  render () {
    const { formValues: { status, eventType, orderType }, t, onImportClick } = this.props
    const { whiteLabel } = getGreyLabelOptions()
    const filterDropdownClass = whiteLabel
      ? `filter__dropdown--${whiteLabel}`
      : 'filter__dropdown'
    return (
      <div className='filter-wrap'>
        <div className='filter__top-part'>
          <h4 className='filter__tabs-title'><b>{t('labels', 'status').s}</b></h4>
          <Field name='status' component={Tabs} className='events-filter__event-statuses' customRef={this.statusTabs} initValue={status} config={[
            {label: t('statuses', 'all').s, value: null},
            {label: t('statuses', 'withErrors').s, value: STATUS_REJECTED},
            {label: t('statuses', 'notChecked').s, value: `${STATUS_UNPROCESSED},${STATUS_PREPARED}`},
            {label: t('statuses', 'verified').s, value: STATUS_APPROVED}
          ]} />
        </div>

        <div className='filter__main-part'>
          <h4 className='filter__tabs-title'><b>{t('labels', 'categories').s}</b></h4>
          <Field name='eventType' component={Tabs} className='events-filter__event-types' customRef={this.eventTypesTabs} initValue={eventType} onChange={this.onEventTypeChange} config={[
            {label: t('eventTypes', 'all').s, value: null},
            {label: t('eventTypes', 'revenue').s, value: `${inType},${moneyDeposit}`},
            {label: t('eventTypes', 'expense').s, value: `${outType},${moneyWithdrawal}`},
            {label: t('eventTypes', 'other').s, value: `${other}, ${transfer}`}
          ]} />
          <div className='filter__multi-row'>
            <div>
              <Field name='orderBy' component={Dropdown} className={filterDropdownClass} options={[
                {label: t('orderBy', 'userEventId').s, value: 'user_document_id'},
                {label: t('orderBy', 'dateOfEvent').s, value: 'document_date'},
                {label: t('orderBy', 'title').s, value: 'title'},
                {label: t('orderBy', 'totalAmount').s, value: 'total_amount'},
                {label: t('orderBy', 'latestUpdate').s, value: 'latest_update'}
              ]} />
            </div>
            <div className='filter__multi-row'>
              <div>
                <SortButton type='desc' value={orderType} onClick={() => this.changeOrderType('desc')} />
              </div>
              <div>
                <SortButton type='asc' value={orderType} onClick={() => this.changeOrderType('asc')} />
              </div>
            </div>
          </div>
          <div className='filter__multi-row'>
            <div>
              <Field name='documentType' component={Dropdown} className={filterDropdownClass} emptyOption={t('documentTypes', 'label').s} options={this.getDocumentTypeSelectItems()} onChange={this.onDocumentTypeChange} />
            </div>
            <div>
              <Field name='invoiceType' component={Dropdown} className={filterDropdownClass} emptyOption={t('invoiceTypes', 'label').s} disabled={this.getDisableConditionForInvoiceTypeSelect()} options={this.getInvoiceTypeSelectItems()} />
            </div>
          </div>
          <Field name='search' component={SearchField} changeAfterBlur placeholder={t('search', 'placeholder').s} />
        </div>

        <div className={'filter__bottom ' + (onImportClick ? 'filter__bottom--without-right-padding' : '')}>
          <div className={`filter__clear ${this.isShowClearButton() ? '' : 'filter__clear--hidden'}`} onClick={this.clearForm}>
            <span className='icon-cross' />
            <span className='filter__clear__text'>{t('clear').s}</span>
          </div>
          <div className='filter__open'>
            <span className='icon-arrow-pointer-down' onClick={() => this.toggleFilter(positions.open)} />
          </div>
          <div className={onImportClick ? 'filter__close-center' : 'filter__close'} onClick={() => this.toggleFilter(positions.normal)}>
            {!onImportClick && <span className='filter__close__text'>{t('hide').s}</span> }
            <span className='icon-arrow-pointer-down' />
          </div>
          { onImportClick && (
            <div className='filter__add-button'>
              <Button view='white-small' onClick={onImportClick}>{t('buttons', 'add').s}</Button>
            </div>
          )}
        </div>
      </div>
    )
  }
}

const formName = 'eventsViewFilters'
const mapStateToProps = (state) => ({
  formValues: getFormValues(formName)(state),
  t: state.i18n.get('app', 'views', 'EventsView', 'filters')
})

const mapDispatchToProps = (dispatch) => ({
  changeFormValue: (fieldName, value) => dispatch(change(formName, fieldName, value))
})

const MappedFiltersForm = reduxForm({
  initialValues: defaultFilterValues,
  form: formName,
  destroyOnUnmount: false
})(connect(mapStateToProps, mapDispatchToProps)(FiltersForm))

export default class EventsViewFilters extends Component {
  constructor (props) {
    super(props)
    this.position = positions.normal
    this.changePosition = this.changePosition.bind(this)
  }
  static propTypes = {
    onImportClick: PropTypes.func
  }

  onCollapseFilter () {
    if (this.position !== positions.collapsed) {
      this.position = positions.collapsed
      changeFilterCssClass(positions.collapsed)
    }
  }

  changePosition (val) {
    this.position = val
  }

  render () {
    return (
      <div className='filter events-filter'>
        <MappedFiltersForm onImportClick={this.props.onImportClick} changePosition={this.changePosition} />
      </div>
    )
  }
}
