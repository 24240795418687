import PropTypes from 'prop-types'
import React, { Component } from 'react'
import FormatNumber from 'shared/components/FormatNumber/FormatNumber'
import FormatDate from 'shared/components/FormatDate/FormatDate'
import { Link } from 'react-router'

import getGreyLabelOptions from 'GreyLabel/helpers/greyLabel'

function getEventListItemColor (expenseType) {
  switch (expenseType) {
    case 'minus':
      return 'red'
    case 'plus':
      return 'green'
    default:
      return 'gray'
  }
}

function getEventListItemIcon (event) {
  if (event.error) {
    return 'rejected'
  } else if (event.approved) {
    return 'approved'
  } else {
    return 'pending'
  }
}

function getEventListItemDescription (expenseType, eventType, t) {
  switch (expenseType) {
    case 'minus':
      return t('shared', 'eventType', 'outcome').s
    case 'plus':
      return t('shared', 'eventType', 'income').s
    default:
      return t('shared', 'eventType', 'other').s
  }
}

export function getEventsListItemContent (values, t, notArrow) {
  const {title, number, date, category, amount, expenseType, eventType, hasUnreadAdminComments, hasComments, isPaid, isInvoice} = values
  const { whiteLabel } = getGreyLabelOptions()
  return [
    <div className='list-multiline-item__row' key='1'>
      <div>
        <h4>
          {title}
        </h4>
        <h4 className={whiteLabel ? `amount--${whiteLabel}` : ''}><FormatNumber value={amount} /></h4>
      </div>
      <div>
        <div className='event-additional-info'>
          <span className='number'>{number}</span>
          <span className='delimiter'>|</span>
          <span className='date'><FormatDate value={date} /></span>
          { category
            ? [ <span className='delimiter' key='1'>|</span>, <span className='category' key='2'>{category}</span> ]
            : null
          }
        </div>
        { isPaid && isInvoice &&
          <span className={whiteLabel ? `color-green ${whiteLabel}` : ''}>{t('shared', 'eventState', 'paid').s}</span>}
        { hasUnreadAdminComments
          ? <span className='icon-messages-note color-red' />
          : hasComments
            ? <span className='icon-messages-note' />
            : null
        }
      </div>
    </div>,
    <h4 className='list-multiline-item__arrow' key='2'>{!notArrow && <span className='icon-arrow-right' />}</h4>,
    <div className={`list-multiline-item__status list-multiline-item__status--${getEventListItemColor(expenseType)}`} key='3'>
      <span className={`list-multiline-item__status-icon-${getEventListItemIcon(values)}`} />
      <span className='list-multiline-item__status-title'><b>{getEventListItemDescription(expenseType, eventType, t)}</b></span>
    </div>
  ]
}

export default class EventsListItem extends Component {
  static propTypes = {
    data: PropTypes.object,
    t: PropTypes.func,
    notLink: PropTypes.bool,
    query: PropTypes.object
  }

  // TODO: it will be needed to add soon
  /* get eventPreviewLink () {
    const {data: {invoiceNumber, id}} = this.props
    if (invoiceNumber) {
      return `/invoices/${invoiceNumber}`
    } else {
      return `/events/${id}`
    }
  } */

  render () {
    const {data: {id}, t, notLink, query} = this.props
    if (notLink) {
      return <div className='list-multiline-item'>{getEventsListItemContent(this.props.data, t, true)}</div>
    } else {
      return (
        <Link to={{ pathname: `/events/${id}`, query }} className='list-multiline-item'>
          {getEventsListItemContent(this.props.data, t)}
        </Link>
      )
    }
  }
}
