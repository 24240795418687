import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { routerActions } from 'react-router-redux'
import moment from 'moment'

import Navigation from 'Navigation/Navigation'
import FormatNumber from 'shared/components/FormatNumber/FormatNumber'

import { getSalary, markAsPaid } from 'Salaries/actions'

import getGreyLabelOptions from 'GreyLabel/helpers/greyLabel'

class SalaryPreview extends Component {
  static propTypes = {
    employee: PropTypes.object,
    loadSalary: PropTypes.func.isRequired,
    params: PropTypes.object,
    project: PropTypes.object,
    salary: PropTypes.object,
    t: PropTypes.func.isRequired,
    paymentAccount: PropTypes.object
  }

  componentDidMount () {
    this.props.loadSalary(this.props.params.salaryId)
  }

  componentDidUpdate (prevProps) {
    if (this.props.params.salaryId !== prevProps.params.salaryId) {
      this.props.loadSalary(this.props.params.salaryId)
    }
  }

  renderField (field) {
    const {salary, t} = this.props
    return (
      <div>
        <h3 className='event-preview__fields__item__content' id={field}>
          <FormatNumber value={salary[field]} withSymbol />
        </h3>
        <h5 className='event-preview__fields__item__label'>{t('fields', field).s}</h5>
      </div>
    )
  }

  renderBenefits () {
    const { t, salary: { benefits } } = this.props
    const { titleClass, contentClass, eventPreviewRowsClass, previewRowClass } = this.getRowWhiteLabelClasses()
    if (benefits && benefits.length) {
      return (
        <div className={eventPreviewRowsClass}>
          <div className='hr'><span>{t('specifications').s}</span></div>
          <div className='event-preview__rows__list'>
            <div className={previewRowClass}>
              <div className={titleClass}><h3>{t('benefits').s}</h3></div>
              <div className={contentClass}>
                <table>
                  <tbody>
                    {benefits.map((benefit, index) =>
                      <tr key={index}>
                        <td>{benefit.title}:</td>
                        <td id='row-amount'><FormatNumber value={benefit.value} withSymbol /></td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )
    }
  }

  getRowWhiteLabelClasses () {
    const { whiteLabel } = getGreyLabelOptions()

    const titleClass = whiteLabel ? `event-preview__row__title ${whiteLabel}` : 'event-preview__row__title'
    const previewRowClass = whiteLabel ? `event-preview__row ${whiteLabel}` : 'event-preview__row'
    const contentClass = whiteLabel ? `event-preview__row__content ${whiteLabel}` : 'event-preview__row__content'
    const eventPreviewRowsClass = whiteLabel ? `event-preview__rows ${whiteLabel}` : 'event-preview__rows'

    return { titleClass, previewRowClass, contentClass, eventPreviewRowsClass }
  }

  render () {
    const { t, salary, paymentAccount, employee, project } = this.props
    const { whiteLabel } = getGreyLabelOptions()
    const staticSectionClass = `event-preview__static-section ${whiteLabel || ''}`
    const singleFieldClass = `event-preview__single-field ${whiteLabel || ''}`
    const fieldsClass = `event-preview__fields ${whiteLabel || ''}`
    return (
      <div className='event-preview salary-preview'>
        <div className={staticSectionClass}>
          <div className='hr' />
          <div className='event-preview__status'>
            <div className='event-preview__status__left'>{t('fields', 'status').s}</div>
            <div className='event-preview__status__right'>
              <div className='event-view__status' id='status'>
                <span className={'salary-status' + `${salary.paid ? ' salary-status__paid' : ' salary-status__not-paid'}`} />&nbsp;{t('statuses', `${salary.paid ? 'paid' : 'notPaid'}`).s}
              </div>
            </div>
          </div>
          <div className={singleFieldClass}>
            <h5><b>{t('fields', 'person').s}</b></h5>
            <h3>{employee.firstName + ` ${employee.lastName}`}</h3>
          </div>
        </div>
        <div className='event-preview__content'>
          <div className='event-preview__content__left'>
            <div className={singleFieldClass}>
              <h5><b>{t('fields', 'project').s}</b></h5>
              <h3>{project.label || '-'}</h3>
            </div>
            <div className={singleFieldClass}>
              <h5><b>{t('fields', 'createdAt').s}</b></h5>
              <h3>{moment(salary.createdAt).format('YYYY-MM-DD')}</h3>
            </div>
            <div className={fieldsClass}>
              {this.renderField('netSalary')}
              {this.renderField('grossSalary')}
            </div>
          </div>
          <div className='event-preview__content__right'>
            {this.renderBenefits()}
            <div className={'salary-preview__payment-account-row'}>
              <h5><b>{t('fields', 'paymentMethod').s}</b></h5>
              <h3>{paymentAccount.label}</h3>
            </div>
          </div>
        </div>
        <div className='event-preview__total'>
          <table>
            <tbody>
              <tr>
                <td>{t('employerTax').s}:</td>
                <td><FormatNumber value={salary.employerTax} withSymbol /></td>
              </tr>
              <tr>
                <td>{t('totalEmployeeTax').s}:</td>
                <td><FormatNumber value={salary.totalEmployeeTax} withSymbol /></td>
              </tr>
              <tr>
                <td>{t('totalSalaryCost').s}:</td>
                <td><FormatNumber value={salary.companyTotalSalaryCost} withSymbol /></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}

const SalaryPreviewWithNavigation = ({redirectToEditView, markAsPaid, ...props}) => {
  const multipleActions = [
    { title: props.t('buttons', 'edit').s, onClick: () => redirectToEditView(props.params.salaryId) },
    { title: props.t('buttons', 'markAsPaid').s, onClick: () => markAsPaid(props.params.salaryId) }
  ]
  return (
    <Navigation title={props.salary.title} backLink='/salaries' forceBackLink multipleActions={!props.salary.paid && multipleActions} hideFooter>
      <SalaryPreview {...props} />
    </Navigation>
  )
}

SalaryPreviewWithNavigation.propTypes = {
  t: PropTypes.func,
  markAsPaid: PropTypes.func,
  params: PropTypes.object,
  redirectToEditView: PropTypes.func,
  salary: PropTypes.object
}

const mapStateToProps = (state) => {
  const { salaries: { salary }, userSettings: { expensePaymentMethods, employees, projects } } = state
  return {
    t: state.i18n.get('app', 'views', 'Salaries', 'SalaryPreviewView'),
    salary,
    paymentAccount: expensePaymentMethods.find(method => method.value === salary.paymentAccount.id) || {},
    employee: employees.find(employee => employee.id === salary.employeeId) || { firstName: '', lastName: '' },
    project: projects.find(project => project.value === salary.projectId) || {}
  }
}

const mapDispatchToProps = (dispatch) => ({
  loadSalary: (id) => dispatch(getSalary(id)),
  redirectToEditView: (salaryId) => dispatch(routerActions.push(`/salaries/${salaryId}/edit`)),
  markAsPaid: (salaryId) => dispatch(markAsPaid(salaryId))
})

export const SalaryPreviewView = connect(mapStateToProps, mapDispatchToProps)(SalaryPreview)
export default connect(mapStateToProps, mapDispatchToProps)(SalaryPreviewWithNavigation)
