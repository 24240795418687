import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'

// Actions
import { loadKeyFigureReportAction, loadFiscalYearsAndReport } from 'Reports/actions/report'

export default function KeyFiguresReportContainer (Component) {
  class KeyFiguresReport extends React.Component {
    static propTypes = {
      loadKeyFiguresReport: PropTypes.func,
      settings: PropTypes.object,
      getFiscalYears: PropTypes.func
    }
    componentWillMount () {
      const {settings: {result: {fiscalYears, from, to, selectedYearIndex}}, getFiscalYears, loadKeyFiguresReport} = this.props
      if (!fiscalYears) {
        getFiscalYears()
      } else {
        loadKeyFiguresReport({from, to, selectedYearIndex})
      }
    }

    getKeyFigures = (params) => {
      const {loadKeyFiguresReport} = this.props
      loadKeyFiguresReport(params)
    }

    render () {
      return (
        <Component {...this.props} getKeyFigures={this.getKeyFigures} />
      )
    }
  }

  function mapStateToProps (state) {
    return {
      t: state.i18n.get('app', 'views', 'Reports', 'KeyFigure', 'Report'),
      settings: state.overview.settings,
      collectorStatus: state.marketplace.collector.onboardingStatus,
      report: state.reports.currentReport,
      loan: state.overview.loan
    }
  }

  function mapDispatchToProps (dispatch) {
    return {
      loadKeyFiguresReport: (params) => { dispatch(loadKeyFigureReportAction(params)) },
      getFiscalYears: () => { dispatch(loadFiscalYearsAndReport()) }
    }
  }

  return connect(mapStateToProps, mapDispatchToProps)(KeyFiguresReport)
}
