import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { reduxForm, Field, formValueSelector, getFormMeta, change, reset } from 'redux-form'
import classnames from 'classnames'

// Components
import SearchField from 'shared/components/FormSearchField'
import Dropdown from 'shared/components/FormDropdown'
import SortButton from 'Events/Events/components/SortButton/SortButton'
import Button from 'shared/components/Button/Button'
import Tabs from 'shared/components/FormTabs'

// Actions
import { reloadListAction } from 'Articles/actions/articles'

// Constants
import { ARTICLE_TYPE_SERVICE, ARTICLE_TYPE_GOODS } from 'Articles/constants/ArticleTypes'

class ArticleListFilters extends Component {
  static propTypes = {
    formValues: PropTypes.object,
    meta: PropTypes.object,
    t: PropTypes.func,
    canBeHidden: PropTypes.bool,
    onImportClick: PropTypes.func,
    dirty: PropTypes.bool,
    resetForm: PropTypes.func,
    changeOrderType: PropTypes.func
  };

  constructor (props) {
    super(props)
    this.state = { filterState: 'normal' }
  }

  render () {
    const {
      formValues: { orderType, articleType },
      t, canBeHidden, onImportClick, changeOrderType, meta
    } = this.props

    if (meta.search && !meta.search.visited && canBeHidden && articleType === 'all') {
      return null
    }

    const filterClassNames = classnames([
      'filter', 'filter-wrap', 'filter--without-fixed-height',
      this.state.filterState === 'open' && 'filter--open',
      this.state.filterState === 'collapsed' && 'filter--collapsed'
    ])

    return (
      <div className={filterClassNames}>
        <div>
          <div className='filter__top-part'>
            <Field
              name='articleType'
              initValue={articleType}
              component={Tabs}
              config={[
                {label: t('selects', 'articleType', 'all').s, value: 'all'},
                {label: t('selects', 'articleType', String(ARTICLE_TYPE_SERVICE)).s, value: ARTICLE_TYPE_SERVICE},
                {label: t('selects', 'articleType', String(ARTICLE_TYPE_GOODS)).s, value: ARTICLE_TYPE_GOODS}
              ]}
              className='filter__top-part__tabs'
            />
          </div>

          <div className='filter__main-part'>
            <div className='filter__multi-row filter__multi-row--small-margin'>
              <div>
                <Field
                  name='orderBy'
                  component={Dropdown}
                  options={[
                    {label: t('filter', 'orderBy', 'articleNumber').s, value: 'inventory_number'},
                    {label: t('filter', 'orderBy', 'name').s, value: 'name'},
                    {label: t('filter', 'orderBy', 'price').s, value: 'price'}
                  ]}
                />
              </div>
              <div className='filter__multi-row'>
                <div>
                  <SortButton type='desc' value={orderType} onClick={() => changeOrderType('desc')} />
                </div>
                <div>
                  <SortButton type='asc' value={orderType} onClick={() => changeOrderType('asc')} />
                </div>
              </div>
            </div>
            <Field
              name='search'
              component={SearchField}
              changeAfterBlur
              placeholder={t('filter', 'search').s}
            />
          </div>

          <div className='filter__bottom filter__bottom--without-right-padding'>
            <div className={`filter__clear ${this.props.dirty ? '' : 'filter__clear--hidden'}`} onClick={() => this.props.resetForm()}>
              <span className='icon-cross' />
              <span className='filter__clear__text'>{t('buttons', 'clearFilters').s}</span>
            </div>
            <div className='filter__open'>
              <span className='icon-arrow-pointer-down' onClick={() => this.setState({ filterState: 'open' })} />
            </div>
            <div className='filter__close-center ' onClick={() => this.setState({ filterState: 'normal' })}>
              <span className='icon-arrow-pointer-down' />
            </div>
            { onImportClick && (
              <div className='filter__add-button'>
                <Button view='white-small' onClick={onImportClick}>{t('buttons', 'add').s}</Button>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}

export const defaultFilterValues = {
  articleType: 'all',
  search: '',
  orderBy: 'inventory_number',
  orderType: 'asc'
}
const formName = 'articlesViewFilters'
const selector = formValueSelector(formName)
const metaSelector = getFormMeta(formName)

function mapStateToProps (state) {
  return {
    t: state.i18n.get('app', 'views', 'Articles', 'ArticlesView'),
    formValues: selector(state, 'search', 'orderType', 'articleType'),
    meta: metaSelector(state)
  }
}

function mapDispatchToProps (dispatch) {
  return {
    reloadList: () => dispatch(reloadListAction()),
    changeOrderType: (value) => dispatch(change(formName, 'orderType', value)),
    resetForm: () => dispatch(reset(formName))
  }
}

const connectedFilters = connect(mapStateToProps, mapDispatchToProps)(ArticleListFilters)

export default reduxForm({
  form: formName,
  initialValues: defaultFilterValues,
  destroyOnUnmount: false
})(connectedFilters)
