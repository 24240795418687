import { combineReducers } from 'redux'

import collector from 'MarketPlace/Collector/reducers'
import capcito from 'MarketPlace/Capcito/reducers'
import creditsafe from 'MarketPlace/Creditsafe/reducers'
import index from 'MarketPlace/Index/reducers'

const marketplaceReducer = combineReducers({
  collector,
  capcito,
  creditsafe,
  index
})

export default function (state, action) {
  return marketplaceReducer(state, action)
}
