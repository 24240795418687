exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._3h5XJlZeVmoTi857u-fz3i {\n  width: 100%;\n  height: 100%;\n}\n\n._3LDf8nwbY0Pzg9yVh_q2cw {\n  width: 100%;\n  height: calc(100vh - 8rem);\n}\n\n", "", {"version":3,"sources":["/home/circleci/mobile_ui/src/MarketPlace/Advertising/views/AdvertisingFrameView/containers/index.scss"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;CACd;;AAED;EACE,YAAY;EACZ,2BAA2B;CAC5B","file":"index.scss","sourcesContent":[".frame {\n  width: 100%;\n  height: 100%;\n}\n\n.tabletFrame {\n  width: 100%;\n  height: calc(100vh - 8rem);\n}\n\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"frame": "_3h5XJlZeVmoTi857u-fz3i",
	"tabletFrame": "_3LDf8nwbY0Pzg9yVh_q2cw"
};