import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Moment from 'moment'

function setTime (value) {
  const duration = Moment.duration(value, 'minutes')
  const setZero = (val) => val < 10 ? ('0' + val).substr(-2) : val
  const hours = setZero(Math.floor(duration.asHours()))
  const minutes = setZero(Math.floor(duration.minutes()) - hours * 60)
  return `${hours} H:${minutes} M`
}

export default class FormatDate extends Component {
  static propTypes = {
    value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    format: PropTypes.string
  };

  render () {
    const {value, format} = this.props
    if (format === 'HH \\H:mm \\M') {
      return <span>{setTime(value)}</span>
    } else {
      return <span>{value ? Moment(value).format(format || 'YYYY-MM-DD') : ''}</span>
    }
  }
}
