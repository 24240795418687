import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'

import Icon from 'shared/components/Icon'
import Input from 'shared/components/Input/Input'
import Button from 'shared/components/Button/Button'
import FormLayout from 'Integrations/shared/FormLayout'

import '../styles/kivra-form.scss?global'

const KivraUserForm = ({ texts, handleSubmit, startIntegration }) =>
  <FormLayout>
    <Icon type='kivra' className='integration-kivra-logo' />
    <h2>{texts.header}</h2>
    <span className='integration-description'>{texts.description}</span>
    <div className='integration-form'>
      <div className='integration-form__fields'>
        <Field
          placeholder={texts.tFields('name').s}
          component={InputWrapper}
          name='name'
        />
        <Field
          placeholder={texts.tFields('vatNumber').s}
          component={InputWrapper}
          name='vATNumber'
        />
      </div>
      <div className='kivra-buttons'>
        <Button onClick={handleSubmit(startIntegration)}>{texts.tButtons('submitButton').s}</Button>
        <Button goBackButton>{texts.tButtons('cancel').s}</Button>
      </div>
    </div>
  </FormLayout>

const InputWrapper = ({input, meta, ...props}) =>
  <Input {...input} {...meta} {...props} />

InputWrapper.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object
}

KivraUserForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  startIntegration: PropTypes.func.isRequired,
  texts: PropTypes.shape({
    description: PropTypes.string.isRequired,
    header: PropTypes.string.isRequired,
    tFields: PropTypes.func.isRequired,
    tButtons: PropTypes.func.isRequired
  })
}

const form = 'kivra-user-form'
const reduxFormHOC = reduxForm({
  form,
  enableReinitialize: true
})

export default reduxFormHOC(KivraUserForm)
