import React, { Component } from 'react'

import IpadNavigation from 'Navigation/IpadNavigation'
import TodoTaxReports from './Smartphones'

export class TodoReportsView extends Component {
  render () {
    return (
      <IpadNavigation {...this.props} onlyLeftSide setCurrentLink>
        <TodoTaxReports {...this.props} />
      </IpadNavigation>
    )
  }
}

export default TodoReportsView
