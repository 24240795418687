import React, { Component } from 'react'

import IpadNavigation from 'Navigation/IpadNavigation'
import MoreCustomerInvoices from 'More/views/MoreCustomerInvoicesView/containers/Smartphones'

export default class MoreCustomerInvoicesView extends Component {
  render () {
    return (
      <IpadNavigation {...this.props} onlyLeftSide>
        <MoreCustomerInvoices {...this.props} notRightIcon />
      </IpadNavigation>
    )
  }
}
