import PropTypes from 'prop-types'
import React, { Component } from 'react'

// Components
import IpadNavigation from 'Navigation/IpadNavigation'
import Navigation from 'Navigation/Navigation'

import PercentLineChart from 'Projects/views/ProjectView/FinanceVisualization/components/PercentLineChart'
import PieChart from 'shared/components/Chart/Pie'
import FinanceVisualizationTopControl from 'Projects/views/ProjectView/FinanceVisualization/components/TopControl'

import ListView from 'shared/components/ListView/ListView'

// Containers
import FinanceVisualizationContainer from 'Projects/views/ProjectView/FinanceVisualization/containers/Common'

class LeftSideView extends Component {
  static propTypes = {
    t: PropTypes.func,
    project: PropTypes.object
  }
  getView () {
    const {project} = this.props
    if (project.revenues && project.revenues.length) {
      return (
        <PercentLineChart {...this.props} />
      )
    } else if (project.revenues && project.revenues.length === 0) {
      return (
        <ListView data={[]} emptyListOptions={{icon: 'icon-events'}} />
      )
    }
  }
  render () {
    const {t} = this.props
    return (
      <Navigation title={t('visualization').s} className='finance-visualization'>
        {this.getView()}
      </Navigation>
    )
  }
}

class FinanceVisualizationView extends Component {
  static propTypes = {
    project: PropTypes.object,
    getColor: PropTypes.func,
    vat: PropTypes.bool
  }

  getView () {
    const {project, vat, getColor} = this.props
    if (project.revenues && project.revenues.length) {
      const data = project.revenues.map((item) => {
        return {
          sum: vat ? parseFloat(item.categorySum) : parseFloat(item.categorySum) - parseFloat(item.categoryVatSum),
          percent: vat ? item.percentForAmountWithVat : item.percentForAmount,
          name: item.categoryName
        }
      })
      return (
        <div>
          <PieChart data={data} getColor={getColor} />
        </div>
      )
    }
  }

  getTopControl () {
    const {project} = this.props
    let props = {}
    if (project.revenues && project.revenues.length === 0) {
      props.onlyLeftSide = true
    } else {
      props.filter = (<FinanceVisualizationTopControl {...this.props} tabletMode />)
    }
    return props
  }

  render () {
    return (
      <IpadNavigation {...this.props} {...this.getTopControl()} notBack className='finance-visualization'>
        <LeftSideView {...this.props} />
        {this.getView()}
      </IpadNavigation>
    )
  }
}

export default FinanceVisualizationContainer(FinanceVisualizationView)
