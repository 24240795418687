import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Link } from 'react-router'

// Components
import PlusButton from 'shared/components/PlusButton/PlusButton'
import InvoiceQuantity from './InvoiceQuantity'
import FormatNumber from 'shared/components/FormatNumber/FormatNumber'

// Constants
import { KM } from 'Invoices/shared/constants/UnitTypes'

export default class InvoiceRows extends Component {
  static propTypes = {
    t: PropTypes.func,
    location: PropTypes.object,
    updateInvoiceRow: PropTypes.func,
    removeInvoiceRow: PropTypes.func,
    saveFormToInvoice: PropTypes.func,
    rows: PropTypes.object
  }

  removeSpecificationRow (index) {
    this.props.removeInvoiceRow(index)
  }

  getMilePrice (price) {
    return price * 10
  }

  render () {
    const { t, location: { pathname }, updateInvoiceRow, rows, saveFormToInvoice } = this.props
    if (rows.length) {
      return (
        <div className='form-rows create-invoice__rows'>
          {rows.map((item, index) => {
            return (
              <div className='form-rows__item create-invoice__row' key={index}>
                <Link to={`${pathname}/invoice_row/${index}`} className='create-invoice__row__info' onClick={saveFormToInvoice}>
                  <div className='create-invoice__row__titles'>
                    <h3>{item.description}</h3>
                    <h5>{item.subdescription}</h5>
                  </div>
                  <table>
                    <tbody>
                      <tr>
                        <td>{t('specification', 'perUnit').s}:</td>
                        <td><h3><FormatNumber value={item.unit === KM ? this.getMilePrice(item.price) : item.price} /></h3></td>
                      </tr>
                      <tr>
                        <td>{t('specification', 'vat').s}:</td>
                        <td><h3><FormatNumber value={item.sumVat} />&nbsp;{item.currencyName}</h3></td>
                      </tr>
                      <tr>
                        <td>{t('specification', 'total').s}:</td>
                        <td><h3><FormatNumber value={item.sumExVat + item.sumVat} />&nbsp;{item.currencyName}</h3></td>
                      </tr>
                      <tr>
                        <td>{t('units', item.unit).s}:</td>
                        <td><InvoiceQuantity data={item} index={index} t={t} updateInvoiceRow={updateInvoiceRow} /></td>
                      </tr>
                    </tbody>
                  </table>
                </Link>
                <div className='form-rows__item__right-button'>
                  <PlusButton type='minus' onClick={() => { this.removeSpecificationRow(index) }} />
                </div>
              </div>
            )
          })}
        </div>
      )
    } else {
      return null
    }
  }
}
