import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'

import Navigation from 'Navigation/Navigation'
import Spinner from 'shared/components/Spinner/Spinner'

import { usePaymentStatus, useAccountStatus } from '../helpers/hooks'
import CreditsafeReportForm from '../components/CreditsafeReportForm/'
import Alert from '../components/Alert/'

import styles from '../styles.scss'

function CreditsafeFrame (props) {
  const { t } = props

  const [paymentStatus, paymentError] = usePaymentStatus(props.location.query, t)
  const [hasAccount, accountError] = useAccountStatus()

  if (paymentError || accountError) {
    return (
      <div className={styles.container}>
        <Alert error={paymentError || accountError} />
      </div>
    )
  }

  if (paymentStatus === 'processing') {
    return (
      <div className={styles.container}>
        <h1>{ t('reportLoading').s }</h1>
        <Spinner dark />
      </div>
    )
  }

  if (!hasAccount) {
    return (
      <div className={styles.container}>
        <h1>{ t('hello').s }</h1>
        <h3>{ t('registrationIsGoing').s }</h3>
        <Spinner dark />
      </div>
    )
  }

  return <CreditsafeReportForm {...props} />
}

CreditsafeFrame.propTypes = {
  handleSubmit: PropTypes.func,
  location: PropTypes.object
}

const CreditsafeFrameWithNavigation = (props) => {
  return (
    <Navigation title={props.t('categories', props.selectedCategory).s} backLink='/marketplace'>
      <CreditsafeFrameView {...props} />
    </Navigation>
  )
}

CreditsafeFrameWithNavigation.propTypes = {
  t: PropTypes.func,
  selectedCategory: PropTypes.string
}

const mapStateToProps = (state) => {
  return {
    t: state.i18n.get('app', 'views', 'MarketPlace', 'creditsafeForm')
  }
}

const reduxFormHOC = reduxForm({
  form: 'creditsafe-form',
  enableReinitialize: true
})

export const CreditsafeFrameView = connect(mapStateToProps)(reduxFormHOC(CreditsafeFrame))
export default CreditsafeFrameWithNavigation
