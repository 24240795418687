import { createAction } from 'redux-actions'
import { routerActions } from 'react-router-redux'

import { REPRESENTATION_GUESTS } from 'Events/shared/constants/Api'
import axios from 'api-client-connector'
import { find } from 'lodash'

import {
  NEW_EVENT_COPY_REPRESENTATION_DATA,
  NEW_EVENT_PUSH_REPRESENTATION_CONTACT,
  NEW_EVENT_EDIT_REPRESENTATION_CONTACT,
  NEW_EVENT_DELETE_REPRESENTATION_CONTACT,
  NEW_EVENT_SAVE_REPRESENTATION
} from 'Events/Expense/constants/expense'

import { updateGuestsList } from 'Events/Expense/reducers/representationGuestsReducer'

const copyRepresentationData = createAction(NEW_EVENT_COPY_REPRESENTATION_DATA)
const addRepresentationContact = createAction(NEW_EVENT_PUSH_REPRESENTATION_CONTACT) // TODO: rename
const editRepresentationContact = createAction(NEW_EVENT_EDIT_REPRESENTATION_CONTACT)
const deleteRepresentationContact = createAction(NEW_EVENT_DELETE_REPRESENTATION_CONTACT)
const saveRepresentation = createAction(NEW_EVENT_SAVE_REPRESENTATION)

export function copyRepresentationDataAction () {
  return (dispatch, getState) => {
    const { events: {newEvent: {representationGuests, representationCause, withAlcohol, internalRepresentation}} } = getState()
    return dispatch(copyRepresentationData({representationGuests, representationCause, withAlcohol, internalRepresentation}))
  }
}

export function addRepresentationContactAction (infoFromForm, index) {
  return (dispatch, getState) => {
    const [ contactInfo ] = updateContactsFromSaved(getState(), [infoFromForm])

    if (index || index === 0) {
      return dispatch(editRepresentationContact({data: [contactInfo], index}))
    } else {
      return dispatch(addRepresentationContact([contactInfo]))
    }
  }
}

export function addRepresentationContactsAction (contacts) {
  return (dispatch, getState) => {
    const withIds = updateContactsFromSaved(getState(), contacts)
    return dispatch(addRepresentationContact(withIds))
  }
}

function updateContactsFromSaved (state, contacts) {
  const allGuests = state.representationGuests
  return contacts.map((contact) => {
    const savedGuest = find(allGuests, { name: contact.name, company: contact.company })
    const contactInfo = savedGuest || contact
    return contactInfo
  })
}

export function deleteRepresentationContactAction (index) {
  return deleteRepresentationContact(index)
}

export function saveRepresentationAction (data) {
  return [saveRepresentation(data), routerActions.goBack()]
}

export function prepareRepresentationAction () {
  return async (dispatch, getState) => {
    const state = getState()
    const responseGuests = await representationGuestsRequest()
    const savedGuests = handleRepresentationGuestsResponse(responseGuests)
    const actions = getRepresentationActions(savedGuests, state)
    dispatch(actions)
  }
}

function representationGuestsRequest () {
  return axios.get(REPRESENTATION_GUESTS, {})
}

function handleRepresentationGuestsResponse (response) {
  const data = response.data.map((r) => ({
    company: r.company,
    name: r.name,
    id: r.id
  }))

  return data
}

function getRepresentationActions (savedGuests, state) {
  let actions = [updateGuestsList(savedGuests)]
  const { events: {newEvent: {representationGuests, representationCause, withAlcohol, internalRepresentation}} } = state
  const alreadyHasGuests = Boolean(representationGuests.length)

  const guests = alreadyHasGuests ? representationGuests : [getDefaultGuest(state, savedGuests)]
  actions.push(copyRepresentationData({representationGuests: guests, representationCause, withAlcohol, internalRepresentation}))

  return actions
}

function getDefaultGuest (state, savedGuests) {
  const name = state.userSettings.user.name
  const company = state.userSettings.company.companyName

  const savedGuest = find(savedGuests, { name, company })
  const defaultGuest = savedGuest || { name, company }

  return defaultGuest
}
