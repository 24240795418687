import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { get } from 'lodash'

import IpadNavigation from 'Navigation/IpadNavigation'
import {DepositView} from 'Events/Deposit/views/DepositView/containers/Smartphones'

import getLeftComponent from 'Navigation/helpers/linkAccordance'

export class Transfer extends Component {
  static propTypes = {
    previousLink: PropTypes.string,
    params: PropTypes.object,
    deposit: PropTypes.object,
    t: PropTypes.func
  }

  render () {
    const {previousLink, deposit, t, params} = this.props
    const Events = getLeftComponent('event', previousLink)
    const navBarTitle = deposit.id ? deposit.title : t('navbar', 'title').s
    const backLink = `/events/${get(params, 'id', '')}`

    return (
      <IpadNavigation {...this.props} title={navBarTitle} backLink={backLink}>
        <Events {...this.props} />
        <DepositView {...this.props} />
      </IpadNavigation>
    )
  }
}

function mapStateToProps (state) {
  return {
    previousLink: state.appInfo.previousLink,
    deposit: state.events.newEvent,
    t: state.i18n.get('app', 'views', 'Income', 'Deposit', 'DepositView')
  }
}

export default connect(mapStateToProps)(Transfer)
