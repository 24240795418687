import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import styles from './styles.scss'

import Navigation from 'Navigation/Navigation'

import Button from 'shared/components/Button/Button'

class WizardTutorialPage extends PureComponent {
  static propTypes = {
    title: PropTypes.string,
    text: PropTypes.string,
    onButtonClick: PropTypes
  }

  render () {
    return (
      <Navigation navigationWithMaxWidth className={styles.page} title={this.props.title} backLink='/wizard/next-actions' forceBackLink actions={this.navigateActions} hideFooter>
        <div className={styles.text}>
          { this.props.text }
        </div>

        <div className={styles.button}>
          <Button view='primary' onClick={this.props.onButtonClick}>{this.props.title}</Button>
        </div>
      </Navigation>
    )
  }
}

export default WizardTutorialPage
