import './CustomersListItem.scss?global'
import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router'

export function CustomersListItem (props) {
  const { id, name } = props.data
  return (
    <Link to={`/customers/${id}`} className='list-single-item customer-list-item'>
      <h3>{name}</h3>
      <h4><span className='icon-arrow-right' /></h4>
    </Link>
  )
}

CustomersListItem.prototype.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired
}
