import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getFormValues } from 'redux-form'
import { get, isEqual } from 'lodash'

import { loadCustomersListAction, cleanCustomersListAction } from 'Counterparties/Customers/actions/customers'

import Navigation from 'Navigation/Navigation'
import Filters, { defaultFilterValues } from 'Counterparties/Customers/components/Filters/Filters'
import { CustomersListItem } from 'Counterparties/Customers/components/CustomersListItem/CustomersListItem'
import ListView from 'shared/components/ListView/ListView'

const actions = [
  {icon: 'icon-add', path: '/customers/new'}
]

export class CustomersView extends Component {
  static propTypes = {
    loadList: PropTypes.func,
    cleanList: PropTypes.func,
    hasNextPage: PropTypes.bool,
    lastPageLoaded: PropTypes.number,
    customers: PropTypes.arrayOf(PropTypes.object),
    filters: PropTypes.object,
    t: PropTypes.func
  };

  constructor (props) {
    super(props)
    this.extendList = this.extendList.bind(this)
  }

  extendList () {
    const {hasNextPage, lastPageLoaded, customers, filters} = this.props
    if (hasNextPage) {
      this.props.loadList(customers, filters, lastPageLoaded + 1)
    }
  }

  componentDidMount () {
    const { filters } = this.props
    this.props.loadList([], filters, 1)
  }

  componentDidUpdate (prevProps) {
    const { filters } = this.props
    if (prevProps.filters && !isEqual(prevProps.filters, filters)) {
      this.props.loadList([], filters, 1)
    }
  }

  componentWillUnmount () {
    this.props.cleanList()
  }

  render () {
    const {t, customers, filters} = this.props
    return (
      <Navigation title={t('navbar', 'title').s} actions={actions} backLink='/more/customer-invoices' forceBackLink filterBlock infinityScrollHandler={this.extendList}>
        <Filters placeholder={t('filters', 'search').s} canBeHidden={!customers.length} />
        <ListView data={customers} template={CustomersListItem} emptyListOptions={{icon: 'icon-customers', showNotFound: Boolean(get(filters, ['search', 'value', 'length']))}} />
      </Navigation>
    )
  }
}

function mapStateToProps (state) {
  return {
    customers: state.customers.listData,
    lastPageLoaded: state.customers.lastPageLoaded,
    hasNextPage: state.customers.hasNextPage,
    filters: getFormValues('customersListFilters')(state) || defaultFilterValues,
    t: state.i18n.get('app', 'views', 'Customers', 'CustomersView')
  }
}

function mapDispatchToProps (dispatch) {
  return {
    loadList: (customers, filters, pageToLoad) => { dispatch(loadCustomersListAction(customers, filters, pageToLoad)) },
    cleanList: () => { dispatch(cleanCustomersListAction()) }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomersView)
