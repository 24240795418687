import './AccountSettingsView.scss?global'

import Smartphones from 'User/Settings/views/AccountSettingsView/containers/Smartphones'
import Tablets from 'User/Settings/views/AccountSettingsView/containers/Tablets'

import {getCurrentDeviceView} from 'shared/helpers/utils'

const AccountSettingsView = getCurrentDeviceView(Smartphones, Tablets)

export default AccountSettingsView
