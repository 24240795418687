import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'

// Actions
import { loadDocumentAction, clearReducerAction, showDeleteDocumentMessageBox } from 'Documents/actions/document'

// Components
import Navigation from 'Navigation/Navigation'
import FilesPreview from 'FileUpload/components/FilesPreviewList/FilesPreviewList'

// Helpers
import getMultipleActions from 'Documents/views/DocumentShowView/helpers/getMultipleActions'

class DocumentShow extends Component {
  static propTypes = {
    document: PropTypes.object,
    params: PropTypes.object,
    getDocument: PropTypes.func,
    clearReducer: PropTypes.func,
    t: PropTypes.func
  }

  componentDidMount () {
    const { getDocument, params: { documentId, companyId } } = this.props
    getDocument(companyId, documentId)
  }

  componentWillReceiveProps (props) {
    const newParams = props.params
    const { params: { documentId }, getDocument } = this.props
    if (newParams.documentId !== documentId) {
      getDocument(newParams.companyId, newParams.documentId)
    }
  }

  componentWillUnmount () {
    this.props.clearReducer()
  }

  render () {
    const { t, document } = this.props

    return (
      <div className='f-column'>
        <table className='info'>
          <tbody>
            <tr className='info__title info__title--border'>
              <td colSpan='2'>
                <table>
                  <tbody>
                    <tr>
                      <td>{t('props', 'title').s}</td>
                      <td><small id='title'>{document.title}</small></td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr className='info__title info__title--border'>
              <td colSpan='2'>
                <table>
                  <tbody>
                    <tr>
                      <td>{t('props', 'category').s}</td>
                      <td><small id='category'>{document.category}</small></td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            { document.comment ? (
              <tr className='info__title info__title--border'>
                <td colSpan='2'>
                  <table>
                    <tbody>
                      <tr>
                        <td>{t('comment').s}</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>) : null }
            { document.comment ? (
              <tr className='info__title info__title--border'>
                <td colSpan='2'>
                  <table>
                    <tbody>
                      <tr>
                        <td>{document.comment}</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            ) : null }
          </tbody>
        </table>
        <FilesPreview files={document.files} />
      </div>
    )
  }
}

class DocumentShowWithNavigation extends Component {
  static propTypes = {
    document: PropTypes.object,
    t: PropTypes.func
  }

  render () {
    const { t, document } = this.props
    const navBarTitle = document.id ? t('titles', 'existing').s : t('titles', 'new').s
    const multipleActions = getMultipleActions(this.props)

    return (
      <Navigation hideFooter title={navBarTitle} className='f-column' multipleActions={multipleActions}>
        <DocumentShow {...this.props} ref='document' />
      </Navigation>
    )
  }
}

function mapStateToProps (state) {
  return {
    document: state.documents.document,
    documentCategories: state.userSettings.documentCategories,
    filesToUpload: state.filesToUpload,
    t: state.i18n.get('app', 'views', 'CreateDocumentView')
  }
}

function mapDispatchToProps (dispatch, ownProps) {
  const { companyId, documentId } = ownProps.params

  return {
    getDocument: (companyId, id) => { dispatch(loadDocumentAction(companyId, id)) },
    clearReducer: () => { dispatch(clearReducerAction()) },
    showDeleteDocumentMessageBox: () => { dispatch(showDeleteDocumentMessageBox(companyId, documentId)) }
  }
}

export const DocumentShowView = connect(mapStateToProps, mapDispatchToProps, null, { withRef: true })(DocumentShow)
export default connect(mapStateToProps, mapDispatchToProps)(DocumentShowWithNavigation)
