import {bind} from 'redux-effects'
import {createAction} from 'redux-actions'
import { base64StringToBlob } from 'blob-util'
import { routerActions } from 'react-router-redux'

import {fetch} from 'shared/helpers/fetch'

import { hideNavbarSpinnerAction } from 'shared/actions/navbarSpinner'
import { showMessageBoxWithParamsAction } from 'MessageBox/actions/messageBox'
import { performAfterLangLoaded } from 'I18N/helpers'
import { closeExtensionWindow } from 'appBridge/notificators/extension'

import { ADD_FILES, ADD_FILE_TO_VIEW, CLEAR_VIEW, LOAD_CURRENT_FILES, REMOVE_NEW_FILES, REMOVE_CURRENT_FILES, SAVE_CURRENT_PATH, CLEAR_FILES, EDIT_NEW_FILE, FORCE_DELETE_FILES } from '../constants/FilesToUpload'
import { FILE_UPLOAD, FILE_DELETE } from '../constants/Api'

const addFiles = createAction(ADD_FILES)
const addFileToView = createAction(ADD_FILE_TO_VIEW)
const clearViewFile = createAction(CLEAR_VIEW)
const clearFiles = createAction(CLEAR_FILES)
const loadCurrentFiles = createAction(LOAD_CURRENT_FILES)
const removeNewFile = createAction(REMOVE_NEW_FILES)
const removeCurrentFile = createAction(REMOVE_CURRENT_FILES)
const saveCurrentPath = createAction(SAVE_CURRENT_PATH)
const editNewFile = createAction(EDIT_NEW_FILE)
const forceDelete = createAction(FORCE_DELETE_FILES)

export function saveCurrentPathAction (path) {
  return saveCurrentPath(path)
}

export function addFileToViewAction (file) {
  return addFileToView(file)
}

export function clearViewFileAction () {
  return clearViewFile()
}

export function loadCurrentFilesAction (files) {
  return loadCurrentFiles(files)
}

export function addFilesAction (files) {
  return addFiles(files)
}

export function removeFileAction (file) {
  if (file.id) {
    return removeCurrentFile(file)
  } else {
    return removeNewFile(file)
  }
}

export function clearFilesAction (path) {
  return clearFiles(path)
}

export function uploadFiles (files, parentType, parentId, afterUpload = () => {}) {
  const actions = []
  if (files.newFiles.length) {
    actions.push(files.newFiles.map((file) => {
      return uploadFile(file, parentType, parentId)
    }))
  }
  if (files.removeFiles.length) {
    actions.push(files.removeFiles.map((id) => {
      return deleteFile(id, parentType)
    }))
  }
  return bind(actions, afterUpload, processError)
}

function uploadFile (file, parentType, parentId) {
  let formData = createFormData({
    file: file,
    parentId: parentId
  })

  return fetch(FILE_UPLOAD, { parentType }, {
    method: 'POST',
    body: formData
  })
}

function deleteFile (id, parentType) {
  return fetch(FILE_DELETE, { parentType, id }, {
    method: 'DELETE'
  })
}

function createFormData (data) {
  let formData = new FormData()
  const {parentId, file} = data

  formData.append('parent_id', parentId)
  formData.append('file', file.file, file.name)
  formData.append('file_mime', file.mime)
  if (file.imageForOcr) {
    formData.append('image_for_ocr', file.imageForOcr)
  }

  return formData
}

export function loadFileFromExtension (base64String, type, withRedirect = true) {
  return (dispatch) => {
    base64StringToBlob(base64String, type).then((file) => {
      if (type === 'null') {
        // because of bug in android wrapper
        type = 'image/jpeg'
      }
      const fileExtension = type.split('/')[1]

      dispatch([
        addFilesAction([{
          name: Number(new Date()) + `.${fileExtension}`,
          mime: type,
          fileBase64: `data:${type};base64,` + base64String,
          file: file
        }]),
        withRedirect && routerActions.push('/?popup_view=firstStep&fromExtension=true')
      ])
    })
  }
}

export function importIsNotSupportedAction () {
  return performAfterLangLoaded((dispatch, getState) => {
    const t = getState().i18n.get('app', 'shared', 'messages')

    dispatch(showMessageBoxWithParamsAction('', t('importIsNotSupported').s, () => { closeExtensionWindow() }))
  })
}

export function addOCRFilesAction (files) {
  const markedFiles = files.map((file) => { return {...file, imageForOcr: true} })

  return addFiles(markedFiles)
}

export function markAsFileForOCR (index) {
  return editNewFile({ index, imageForOcr: true })
}

export function forceDeleteFilesAction () {
  return forceDelete()
}

function processError (response) {
  console.log('fileupload error', response)
  return hideNavbarSpinnerAction()
}
