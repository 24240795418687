import {API_BASE} from 'shared/constants/Api'

export const GET_PRICE_LIST = `${API_BASE}/api/v2/administrators/request_pricelist/`
export const GET_PRICE = (companyId) => `${API_BASE}/api/v2/administrators/${companyId}/request_offer_details/`
// FIXME why REQUEST_CALL and SAVE_PRICE is the same?
export const REQUEST_CALL = (clientCompanyId) => `${API_BASE}/api/v2/ident-service/api/v2/client_companies/${clientCompanyId}/wizard_requests`
export const SAVE_PRICE = (clientCompanyId) => `${API_BASE}/api/v2/ident-service/api/v2/client_companies/${clientCompanyId}/wizard_requests`

export const UPLOADS_LOGO = (companyName) => `https://s3-eu-west-1.amazonaws.com/marketplace-assets-mrshoebox-com/se/administrators/logos/${companyName}.png`

export const WEB_SCRAPER = `${API_BASE}/api/v2/company_info_by_query/`
