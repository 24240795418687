import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { routerActions } from 'react-router-redux'

import { blockWelcomeModal } from 'MarketPlace/shared/actions'
import Collaboration from 'MarketPlace/shared/views/CollaborationView'
import Navigation from 'Navigation/Navigation'
import Details from '../components/MovesticDetailsModal'

import './index.scss?global'

class PensionCollaborationView extends Component {
  static propTypes = {
    t: PropTypes.func,
    blockWelcomeModal: PropTypes.func.isRequired,
    cancelHandler: PropTypes.func.isRequired,
    startHandler: PropTypes.func.isRequired
  }

  componentDidMount () {
    this.props.blockWelcomeModal(true)
  }

  get collaborationConfig () {
    const { t } = this.props
    return {
      message: t('pensionCollaboration', 'message').s,
      partnerOffer: t('pensionCollaboration', 'offers').s,
      iconName: 'movestic',
      iconType: 'png'
    }
  }

  showDetailsModal = () => {
    this.detailsModal.open()
  }

  render () {
    return (
      <div className='movestic-collaboration'>
        <Collaboration
          {...this.collaborationConfig}
          {...this.props}
          readMoreHandler={this.showDetailsModal}
        />
        <Details startHandler={this.props.startHandler} provideController={(node) => { this.detailsModal = node }} />
      </div>
    )
  }
}

const PensionCollaborationWithNavigation = (props) => {
  const navBarTitle = props.t('pensionCollaboration', 'title').s

  return (
    <div className='pension-collaboration-wrapper'>
      <Navigation hideFooter onClickBack={props.cancelHandler} title={navBarTitle} notBottomSpace >
        <PensionCollaborationView {...props} />
      </Navigation>
    </div>
  )
}

PensionCollaborationWithNavigation.propTypes = {
  cancelHandler: PropTypes.func.isRequired
}

const mapStateToProps = (state) => {
  return {
    t: state.i18n.get('app', 'views', 'MarketPlace')
  }
}

function mapDispatchToProps (dispatch) {
  const movesticLanding = 'https://www.movestic.se/foretag/smaforetagarplan/tjanstepension-egenforetagare?utm_source=partner&utm_medium=banner&utm_campaign=mr-shoebox#'
  return {
    blockWelcomeModal: (...args) => dispatch(blockWelcomeModal(...args)),
    cancelHandler: () => dispatch(routerActions.goBack()),
    startHandler: () => window.open(movesticLanding, '_blank')
  }
}

export const PensionCollaboration = connect(mapStateToProps, mapDispatchToProps)(PensionCollaborationView)
export default connect(mapStateToProps, mapDispatchToProps)(PensionCollaborationWithNavigation)
