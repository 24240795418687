import React from 'react'
import { Route } from 'react-router'

import { requireAuthentication } from 'User/Auth/higherOrderComponents/AuthComponent'

import TodoView from 'Todo/views/TodoView/TodoView'
import TodoTaxReportsView from 'Todo/views/TodoTaxReportsView/TodoTaxReportsView'
import TodoErrorEventsView from 'Todo/views/TodoErrorEventsView/TodoErrorEventsView'
import TaxPaymentReportView from 'Reports/views/TaxPaymentReport/TaxPaymentReportView/TaxPaymentReportView'
import TodoUnreadEventsView from 'Todo/views/TodoUnreadEventsView/TodoUnreadEventsView'
import TaxPaymentReportApproveView from 'Reports/views/TaxPaymentReport/TaxPaymentApproveFormView/TaxPaymentApproveFormView'
import TodoPDFReportsView from 'Todo/views/TodoUnreadPDFReports/TodoPDFReportsView'
import TodoAnnualReportsView from 'Todo/views/TodoUnreadAnnualReports/TodoAnnualReportsView'
import TodoOnboardingStatusView from 'Todo/views/TodoOnboardingStatusView'
import TodoCollectorInvoices from 'Todo/views/TodoCollectorInvoicesView'
import TodoIntegrationsView from 'Todo/views/TodoIntegrationsView'
import TodoIntegrationView from 'Todo/views/TodoIntegrationView'

const todoRoutes = (
  <Route>
    <Route path='/todo' component={requireAuthentication(TodoView)} />
    <Route path='/todo/tax_payment' component={requireAuthentication(TodoTaxReportsView)} />
    <Route path='/todo/tax_payment/:taxPaymentReportId' component={requireAuthentication(TaxPaymentReportView)} />
    <Route path='/todo/tax_payment/:taxPaymentReportId/approve' component={requireAuthentication(TaxPaymentReportApproveView)} />
    <Route path='/todo/error_events' component={requireAuthentication(TodoErrorEventsView)} />
    <Route path='/todo/unread_events' component={requireAuthentication(TodoUnreadEventsView)} />
    <Route path='/todo/pdf-reports' component={requireAuthentication(TodoPDFReportsView)} />
    <Route path='/todo/annual-reports' component={requireAuthentication(TodoAnnualReportsView)} />
    <Route path='/todo/onboarding_status' component={requireAuthentication(TodoOnboardingStatusView)} />
    <Route path='/todo/collector_invoices' components={requireAuthentication(TodoCollectorInvoices)} />
    <Route path='/todo/integrations' components={requireAuthentication(TodoIntegrationsView)} />
    <Route path='/todo/integrations/open-payments' components={requireAuthentication(TodoIntegrationView)} />
  </Route>
)

export default todoRoutes
