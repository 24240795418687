import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { getApplications } from 'MarketPlace/Collector/actions'
import { markCollectorOnboardingStatusAsReadAction } from 'Notifications/actions/collectorStatuses'

export default function withRequests (WrappedComponent) {
  class Component extends React.Component {
    static propTypes = {
      location: PropTypes.object.isRequired,
      status: PropTypes.string.isRequired,
      t: PropTypes.func.isRequired,
      getCollectorApplications: PropTypes.func,
      markStatusAsRead: PropTypes.func
    }

    componentDidMount () {
      this.props.getCollectorApplications()
      this.props.markStatusAsRead()
    }

    render () {
      return <WrappedComponent {...this.props} />
    }
  }

  function mapStateToProps (state) {
    return {
      t: state.i18n.get('app', 'views', 'Todo', 'TodoOnboardingStatus'),
      status: state.marketplace.collector.onboardingStatus
    }
  }

  function mapDispatchToProps (dispatch) {
    return {
      getCollectorApplications: () => dispatch(getApplications()),
      markStatusAsRead: () => dispatch(markCollectorOnboardingStatusAsReadAction())
    }
  }

  return connect(mapStateToProps, mapDispatchToProps)(Component)
}
