import {bind} from 'redux-effects'
import { getCurrentClientCompany } from 'api-client-connector/utils'

import {fetch} from 'shared/helpers/fetch'
import { hideNavbarSpinnerAction } from 'shared/actions/navbarSpinner'
import { FILE_STORAGE_UPLOAD, FILE_STORAGE_DELETE } from '../constants/Api'

export function uploadFiles (files, documentType, userDocumentId, id, afterUpload = () => {}) {
  const actions = []
  if (files.newFiles.length) {
    actions.push(files.newFiles.map((file) => {
      return uploadFile(file, documentType, userDocumentId, id)
    }))
  }
  if (files.removeFiles.length) {
    actions.push(files.removeFiles.map((id) => {
      return deleteFile(id)
    }))
  }
  return bind(actions, afterUpload, processError)
}

function uploadFile (file, documentType, userDocumentId, id) {
  let formData = createFormData({
    file: file,
    documentType,
    userDocumentId,
    id
  })

  return fetch(FILE_STORAGE_UPLOAD, {}, {
    method: 'POST',
    body: formData
  })
}

function deleteFile (id) {
  return fetch(FILE_STORAGE_DELETE, { id }, {
    method: 'DELETE'
  })
}

function createFormData (data) {
  let formData = new FormData()
  const { file, documentType, userDocumentId, id } = data
  const currentClientCompanyId = Number(getCurrentClientCompany())

  formData.append('company_id', currentClientCompanyId)
  formData.append('object_type', documentType)
  formData.append('object_id', id)
  formData.append('user_object_id', userDocumentId)
  formData.append('file', file.file, file.name)

  return formData
}

function processError (response) {
  console.log('filestorageupload error', response)
  return hideNavbarSpinnerAction()
}
