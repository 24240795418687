import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'

import OnboardingFields from 'MarketPlace/Capcito/views/CapcitoOnboardingView/components/OnboardingFields'
import Icon from 'shared/components/Icon'
import ButtonsBlock from 'MarketPlace/shared/components/ButtonsBlock'
import OnboardingLayout from 'MarketPlace/shared/components/OnboardingLayout'

const CapcitoOnboarding = (props) => {
  const {
    t, sendOnboardingRequest, goBack, setErrors,
    makeFieldsTouched, handleSubmit, tSettings
  } = props
  return (
    <OnboardingLayout isFullParentHeight>
      <div className='capcito-onboarding__content'>
        <Icon className='capcito__logo' type='capcito' />
        <OnboardingFields
          t={t}
          tSettings={tSettings}
          setErrors={setErrors}
          makeFieldsTouched={makeFieldsTouched}
        />
        <div className='capcito-onboarding__terms'>
          <p>{t('message').s}</p>
          <Link to='/marketplace/capcito/terms'>
            {t('link').s}
          </Link>
        </div>
      </div>
      <div className='capcito-onboarding__buttons-block'>
        <ButtonsBlock
          startButtonText={t('buttons', 'accept').s}
          startButtonView='transparent-black'
          startHandler={handleSubmit(sendOnboardingRequest)}
          cancelButtonText={t('buttons', 'close').s}
          cancelButtonView='transparent-red'
          cancelHandler={goBack}
        />
      </div>
    </OnboardingLayout>
  )
}

CapcitoOnboarding.propTypes = {
  t: PropTypes.func.isRequired,
  tSettings: PropTypes.func.isRequired,
  sendOnboardingRequest: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired,
  setErrors: PropTypes.func.isRequired,
  makeFieldsTouched: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired
}

export default CapcitoOnboarding
