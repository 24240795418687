import { routerActions } from 'react-router-redux'
import { createAction } from 'redux-actions'

import { NEW_EVENT_CLEAR } from '../constants/Event'

const removeNewEventFromState = createAction(NEW_EVENT_CLEAR)

export function createBackLink (currentPath) {
  const pathComponents = currentPath.split('/')

  if (pathComponents[pathComponents.length - 3] === 'new' || pathComponents[pathComponents.length - 4] === 'edit') {
    return pathComponents.slice(0, pathComponents.length - 1).join('/')
  } else {
    return pathComponents.slice(0, pathComponents.length - 2).join('/')
  }
}

export function createLink (currentPath, additionalPath) {
  return currentPath + '/' + additionalPath
}

export function redirectToFormAction (currentPath, additionalPath = '') {
  const redirectPath = createBackLink(currentPath)
  return routerActions.push(redirectPath + additionalPath)
}

export function clearForm () {
  return [removeNewEventFromState()]
}
