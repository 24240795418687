import { bind } from 'redux-effects'
import { routerActions } from 'react-router-redux'
import { createAction } from 'redux-actions'
import {fetch2 as fetch} from 'shared/helpers/fetch'
import { sortBy, minBy, pick } from 'lodash'
import { getCurrentClientCompany } from 'api-client-connector/utils'
import { orgNumberMaskProps } from 'mrshoebox-ui-components/src/helpers/countrySpecific'

import {addHashForIos} from 'shared/helpers/utils'
import getGreyLabelOptions from 'GreyLabel/helpers/greyLabel'

import { showNavbarSpinnerAction, hideNavbarSpinnerAction } from 'shared/actions/navbarSpinner'
import { showMessageBoxWithParamsAction } from 'MessageBox/actions/messageBox'
import { updateSettings } from 'User/Settings/actions/accountSettings'
import getUserSettingsAction, { addToUserSettings } from 'User/Settings/actions/userSettings'

import { WIZARD_SET_INFO, WIZARD_UPDATE_NEW_PRICE, WIZARD_SET_DEFAULT_NEW_PRICE, WIZARD_CLEAR_INFO } from 'Wizard/constants/ActionTypes'
import { GET_PRICE_LIST, GET_PRICE, SAVE_PRICE } from 'Wizard/constants/Api'

const setInfo = createAction(WIZARD_SET_INFO)
const updateNewPrice = createAction(WIZARD_UPDATE_NEW_PRICE)
const setDefaultNewPrice = createAction(WIZARD_SET_DEFAULT_NEW_PRICE)
const clearInfo = createAction(WIZARD_CLEAR_INFO)

export function setInfoAction (data) {
  return setInfo(data)
}

export function setCompanyTypeAction (data) {
  return [
    updateNewPrice({companyType: data.companyType}),
    setInfo({companyName: data.companyName})
  ]
}

export function saveBaseInfoAction (data) {
  return [updateNewPrice(data), routerActions.push('/wizard/offers')]
}

export function getPriceListForNotStartedCompanyAction () {
  return [setDefaultNewPrice(), routerActions.push('/wizard/offers'), getPriceListAction()]
}

export function getPriceListAction () {
  return (dispatch, getState) => {
    const { wizard: {newPrice}, userSettings: { whiteLabel } } = getState()
    return dispatch([
      showNavbarSpinnerAction(),
      bind(getPriceList(newPrice, whiteLabel), processGetPriceList, processError)
    ])
  }
}

function getPriceList (data, whiteLabel) {
  return fetch(GET_PRICE_LIST, {}, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(getPriceRequestPayload(data, whiteLabel))
  })
}

function processGetPriceList (res) {
  return (dispatch, getState) => {
    const { wizard: { newPrice } } = getState()
    let info = {}
    info.priceList = sortBy(res.value, (item) => {
      if (typeof item.packet.total_price === 'number') {
        return item.packet.total_price
      }
    })
    const cheaperPriceItem = minBy(res.value, (item) => {
      if (typeof item.packet.total_price === 'number') {
        return item.packet.total_price
      }
    })
    if (cheaperPriceItem) {
      info.cheaperPrice = Number(cheaperPriceItem.packet.total_price)
    }
    info.accountingPrice = null
    info.timeSaving = null
    if (newPrice.accountantMonthlyCost && Number(newPrice.accountantMonthlyCost) > info.cheaperPrice) {
      info.accountingPrice = (Number(newPrice.accountantMonthlyCost) * 12) - (Number(info.cheaperPrice) * 12)
    }
    if (Number(newPrice.accountantMonthlyHours) > 0) {
      info.timeSaving = Number(newPrice.accountantMonthlyHours) - calculateTimeSpending(newPrice)
    }
    return dispatch([setInfo(info), hideNavbarSpinnerAction()])
  }
}

export function calculateTimeSpending (data) {
  const eventsCount =
    Number(data.monthlyClientInvoices) + Number(data.monthlySupplierInvoices) + Number(data.monthlyReceipts)
  if (eventsCount >= 1 && eventsCount <= 25) {
    return 1
  } else if (eventsCount >= 26 && eventsCount <= 50) {
    return 1.5
  } else if (eventsCount >= 51) {
    return Math.ceil(eventsCount / 25) - 1
  }
}

export function getPriceAction (name) {
  return (dispatch, getState) => {
    const { wizard: {newPrice}, userSettings: { whiteLabel } } = getState()

    return dispatch([
      showNavbarSpinnerAction(),
      bind(
        fetch(GET_PRICE(name), {}, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(getPriceRequestPayload(newPrice, whiteLabel))
        }),
        processGetPrice,
        processError
      )
    ])
  }
}

function getPriceRequestPayload (data, whiteLabel) {
  let requestParams = {
    cash_register: Boolean(data.cashRegister),
    type: 'month',
    verifications_amount: Number(data.monthlyClientInvoices || 0) + Number(data.monthlySupplierInvoices || 0) + Number(data.monthlyReceipts || 0),
    turnover_amount: Number(data.turnover),
    with_employees: Boolean(data.withEmployees)
  }
  const greyLabel = getGreyLabelOptions().greyLabel

  if (greyLabel && !['blinfo', 'slippab', 'dg9economy', 'winwin', 'werkeys'].includes(greyLabel)) {
    requestParams.grey_label = greyLabel
  } else {
    requestParams.white_label = whiteLabel
  }

  return requestParams
}

function processGetPrice (res) {
  return [setInfo({currentPrice: res.value}), hideNavbarSpinnerAction()]
}

export function updateNewPriceAction (data, withoutRedirect = false) {
  return [updateNewPrice(data), !withoutRedirect && routerActions.push('/wizard/next-actions')]
}

export function requestCallAction () {
  return makeRequestToIdentService(() => [hideNavbarSpinnerAction(), setInfo({afterCallTextShow: true})])
}

export function saveNewPriceAction () {
  return makeRequestToIdentService(processSaveNewPrice)
}

function makeRequestToIdentService (afterSuccess) {
  return (dispatch, getState) => {
    const { wizard: {newPrice, currentPrice: {company}}, userSettings: { user: { email, phone, personalNumber }, company: { companyName, organizationNumber } } } = getState()

    const requestBody = {
      accounting_company_id: company.accounting_company_id,
      client_company: {
        name: companyName,
        org_number: orgNumberMaskProps.normalize(organizationNumber)
      },
      user: {
        email,
        personal_code: personalNumber,
        phone
      },
      connection_details: {
        callback_request: newPrice.requestCall,
        vat_frequency: newPrice.vatFrequency,
        with_employees: newPrice.withEmployees,
        accountant_monthly_hours: newPrice.accountantMonthlyHours,
        accountant_monthly_cost: newPrice.accountantMonthlyCost,
        source_documents: {
          monthly: true,
          receipts: newPrice.monthlyReceipts,
          client_invoices: newPrice.monthlyClientInvoices,
          supplier_invoices: newPrice.monthlySupplierInvoices
        }
      }
    }

    return dispatch([
      showNavbarSpinnerAction(),
      bind(fetch(SAVE_PRICE(getCurrentClientCompany()), {}, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestBody)
      }), afterSuccess, processError)])
  }
}

function processSaveNewPrice () {
  addHashForIos()
  return [
    clearInfo(),
    addToUserSettings({ canRequestAccountants: false }),
    hideNavbarSpinnerAction(),
    routerActions.push('/?greetings=true')
  ]
}

export function clearInfoAction () {
  return clearInfo()
}

function processError (res) {
  console.log(res)
}

export function wizardProfileShowMessageBox () {
  return (dispatch, getState) => {
    const t = getState().i18n.get('app', 'views', 'Wizard', 'Profile')
    return dispatch(showMessageBoxWithParamsAction('', t('errorMessage').s))
  }
}

export function updateUserInfoAction (newInfo) {
  return (dispatch, getState) => {
    const alreadySavedInfo = pick(getState().userSettings.user, ['telephone', 'email', 'tax_period_type']) // TODO: investigate tax period type
    const infoForRequest = {
      ...alreadySavedInfo,
      first_name: newInfo.firstName,
      last_name: newInfo.lastName,
      company_name: newInfo.companyName
    }

    return dispatch(bind(updateSettings(infoForRequest), () => [getUserSettingsAction()]))
  }
}
