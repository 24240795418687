import '../ConversationsView.scss?global'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { routerActions } from 'react-router-redux'
import { connect } from 'react-redux'
import moment from 'moment'

// Components
import Navigation from 'Navigation/Navigation'
import Conversation from 'mrshoebox-ui-components/src/modules/Messaging/components/Conversation'
import SearchField from 'shared/components/SearchField/SearchField'
// Actions
import { loadConversationsActions } from 'Messages/actions/messages'

export class ConversationsView extends Component {
  static propTypes = {
    children: PropTypes.node,
    currentConversationId: PropTypes.number,
    t: PropTypes.func,
    conversations: PropTypes.array,
    loadConversationsList: PropTypes.func.isRequired,
    location: PropTypes.object,
    redirectToConversation: PropTypes.func.isRequired
  }

  static defaultProps = {
    shouldLoadList: true
  }

  state = { refreshConversationsTimerId: null, searchQuery: '' }

  changeSearchQuery = event => {
    this.setState({ searchQuery: event.target.value })
  }

  filterConversations (conversations) {
    const searchQuery = this.state.searchQuery.toLowerCase()
    const filteredConversations = conversations.filter(conversation =>
      conversation.companyName.toLowerCase().match(searchQuery)
    )

    return filteredConversations
  }

  refreshConversations () {
    const { loadConversationsList } = this.props
    const timerId = setInterval(() => loadConversationsList(), 5000)

    this.setState({ refreshConversationsTimerId: timerId })
  }

  conversationClickHandler (conversationId) {
    const { redirectToConversation } = this.props
    clearInterval(this.state.refreshConversationsTimerId)
    redirectToConversation(conversationId)
  }

  componentDidMount () {
    this.props.loadConversationsList()
    this.refreshConversations()
  }

  componentDidUpdate (prevProps) {
    const { currentConversationId } = this.props
    const { currentConversationId: prevCurrentConversationId } = prevProps
    if (currentConversationId !== prevCurrentConversationId) clearInterval(this.state.refreshConversationsTimerId)
  }

  componentWillUnmount () {
    clearInterval(this.state.refreshConversationsTimerId)
  }

  render () {
    const {
      children, t, conversations, currentConversationId,
      location: { query: { cameFrom } }
    } = this.props
    const filteredConversations = this.filterConversations(conversations)
    const backLink = cameFrom ? '/todo' : '/more'
    if (children) {
      return children
    } else {
      return (
        <div className='conversations'>
          <Navigation title={t('navbar', 'title').s} backLink={backLink} forceBackLink filterBlock>
            <SearchField onChange={this.changeSearchQuery} value={this.state.searchQuery} />
            {filteredConversations.map(
              ({ lastMessageDate, companyName, unreadMessagesNumber, conversationId }, index) =>
                <Conversation
                  time={lastMessageDate}
                  companyName={companyName}
                  onClickHandler={() => this.conversationClickHandler(conversationId)}
                  key={index}
                  isSelected={conversationId === currentConversationId}
                  newMessagesNumber={unreadMessagesNumber}
                />
            )}
          </Navigation>
        </div>
      )
    }
  }
}

function mapStateToProps (state) {
  const { conversations = [], currentConversation } = state.messages
  const { data } = currentConversation
  return {
    userId: state.userSettings.user.id,
    conversations: conversations.map(conversation => ({
      lastMessageDate: moment.utc(conversation.updatedAt).local().format('YYYY-MM-DD'),
      companyName: conversation.accountingCompanyName,
      unreadMessagesNumber: conversation.numberOfUnread,
      conversationId: conversation.conversationId
    })),
    t: state.i18n.get('app', 'views', 'Messages'),
    currentConversationId: data.conversationId
  }
}

function mapDispatchProps (dispatch) {
  return {
    redirectToConversation: (conversationId) => dispatch(routerActions.push(`/messages/conversation/${conversationId}`)),
    loadConversationsList: () => dispatch(loadConversationsActions())
  }
}
export default connect(mapStateToProps, mapDispatchProps)(ConversationsView)
