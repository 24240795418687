import React, {Component} from 'react'
import {connect} from 'react-redux'

import PieChart from 'mrshoebox-ui-components/src/components/Charts/Pie'

import { showNavbarSpinnerAction, hideNavbarSpinnerAction } from 'shared/actions/navbarSpinner'

class PieChartContainer extends Component {
  render () {
    return (
      <PieChart {...this.props} />
    )
  }
}

function mapDispatchToProps (dispatch) {
  return {
    showSpinner: () => { dispatch(showNavbarSpinnerAction()) },
    hideSpinner: () => { dispatch(hideNavbarSpinnerAction()) }
  }
}

export default connect(null, mapDispatchToProps)(PieChartContainer)
