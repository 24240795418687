import PropTypes from 'prop-types'
import React, { Component } from 'react'

import OverviewViewContainer from 'Overview/Overview/containers/Common'

import IpadNavigation from 'Navigation/IpadNavigation'
import Navigation from 'Navigation/Navigation'
import OverviewList from 'Overview/Overview/components/List/List'

import Common from './Common'

class LeftSideView extends Component {
  static propTypes = {
    t: PropTypes.func
  }
  render () {
    const {t} = this.props
    return (
      <Navigation title={t('navbar', 'title').s} className='overview-view' home>
        <OverviewList {...this.props} className='overview-list--tablet' />
      </Navigation>
    )
  }
}

class OverviewView extends Component {
  static propTypes = {
    tViews: PropTypes.func
  }

  render () {
    const { tViews } = this.props
    return (
      <IpadNavigation notBack {...this.props} title={tViews('PnlReports', 'title').s}>
        <LeftSideView {...this.props} />
        <div className='overview-rev-costs'>
          <Common {...this.props} />
        </div>
      </IpadNavigation>
    )
  }
}

export default OverviewViewContainer(OverviewView)
