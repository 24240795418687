import {
  INVOICES_LIST_UPDATE,
  INVOICES_LIST_CLEAN,
  INVOICE_UPDATE_PROPS,
  INVOICE_CLEAR_PROPS,
  INVOICE_ROW_UPDATE,
  INVOICE_ROW_ADD,
  INVOICE_ROW_REMOVE,
  INVOICE_SHOW_TOTALS_SPINNER,
  INVOICE_HIDE_TOTALS_SPINNER,
  INVOICE_DIRECT_PAYMENT_UPDATE,
  INVOICE_DIRECT_PAYMENT_CLEAN
} from '../../shared/constants/ActionTypes'
import { INVOICE } from '../../shared/constants/InvoiceTypes'
import { currencyId } from 'mrshoebox-ui-components/src/helpers/countrySpecific'

export const initalState = {
  lastPageLoaded: 0,
  hasNextPage: true,
  listData: [],
  tabsCounters: {
    amountOfOverdue: ''
  },
  totalsAreCalculating: false,
  invoice: {
    invoiceType: INVOICE,
    currencyId,
    discountAmount: 0,
    preTotal: 0,
    preTotalVat: 0,
    invoiceRows: [],
    rotRutObject: {},
    totals: {
      preTotal: 0,
      discountAmount: 0
    },
    customer: {},
    collectorInfo: {},
    kivraValidation: {
      errors: [],
      info: [],
      valid: false
    }
  },
  directPayment: {
    amount: 0
  }
}

export default function (state = initalState, action) {
  let newTotal = 0
  let newTotalVat = 0
  let newDiscountPercentage = 0

  switch (action.type) {
    case INVOICES_LIST_UPDATE:
      return {
        ...state,
        ...action.payload
      }
    case INVOICES_LIST_CLEAN:
      return {
        ...initalState,
        invoice: state.invoice
      }
    case INVOICE_UPDATE_PROPS:
      return {
        ...state,
        invoice: {
          ...state.invoice,
          ...action.payload
        }
      }
    case INVOICE_CLEAR_PROPS:
      return {
        ...state,
        invoice: initalState.invoice
      }
    case INVOICE_ROW_ADD:
      newTotal = state.invoice.preTotal + action.payload.sumExVat
      newTotalVat = state.invoice.preTotalVat + action.payload.sumVat
      newDiscountPercentage = Number(state.invoice.discountAmount) / Number(newTotal) * 100

      return {
        ...state,
        invoice: {
          ...state.invoice,
          invoiceRows: [
            ...state.invoice.invoiceRows,
            action.payload
          ],
          preTotal: newTotal,
          preTotalVat: newTotalVat,
          discountPercentage: newDiscountPercentage
        }
      }
    case INVOICE_ROW_UPDATE:
      newTotal = 0
      newTotalVat = 0
      const invoiceRows = state.invoice.invoiceRows.map((row, index) => {
        let data
        if (index === action.payload.index) {
          data = {...row, ...action.payload.data}
        } else {
          data = row
        }
        newTotal += data.sumExVat
        newTotalVat += data.sumVat
        return data
      })
      newDiscountPercentage = Number(state.invoice.discountAmount) / Number(newTotal) * 100

      return {
        ...state,
        invoice: {
          ...state.invoice,
          invoiceRows,
          preTotal: newTotal,
          preTotalVat: newTotalVat,
          discountPercentage: newDiscountPercentage
        }
      }
    case INVOICE_ROW_REMOVE:
      newTotal = state.invoice.preTotal - state.invoice.invoiceRows[action.payload].sumExVat
      newTotalVat = state.invoice.preTotalVat - state.invoice.invoiceRows[action.payload].sumVat
      newDiscountPercentage = Number(state.invoice.discountAmount) / Number(newTotal) * 100
      return {
        ...state,
        invoice: {
          ...state.invoice,
          invoiceRows: state.invoice.invoiceRows.filter((e, i) => {
            return action.payload !== i
          }),
          preTotal: state.invoice.preTotal - state.invoice.invoiceRows[action.payload].sumExVat,
          preTotalVat: state.invoice.preTotalVat - state.invoice.invoiceRows[action.payload].sumVat,
          discountPercentage: newDiscountPercentage
        }
      }
    case INVOICE_SHOW_TOTALS_SPINNER:
      return {
        ...state,
        totalsAreCalculating: true
      }
    case INVOICE_HIDE_TOTALS_SPINNER:
      return {
        ...state,
        totalsAreCalculating: false
      }
    case INVOICE_DIRECT_PAYMENT_UPDATE:
      return {
        ...state,
        directPayment: action.payload
      }
    case INVOICE_DIRECT_PAYMENT_CLEAN:
      return {
        ...state,
        directPayment: initalState.directPayment
      }
    default:
      return state
  }
}
