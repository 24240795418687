import './styles/index.scss?global'

import PropTypes from 'prop-types'

import React, { Component } from 'react'
import { connect } from 'react-redux'
import cs from 'classnames'

import {isIpadResolution} from 'shared/helpers/utils'
import getGreyLabelOptions from 'GreyLabel/helpers/greyLabel'

import {setActiveItemIdAction} from 'AppInfo/actions/appInfo'

import TimeRange from 'shared/components/ListView/components/TimeRange'

export class ListView extends Component {
  constructor (props) {
    super(props)
    this.getSelectedClassName = this.getSelectedClassName.bind(this)
    this.onClickItem = this.onClickItem.bind(this)
  }

  static propTypes = {
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    template: PropTypes.func,
    title: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
    passIndex: PropTypes.bool,
    eventType: PropTypes.string,
    onCheckboxChange: PropTypes.func,
    clickHandler: PropTypes.func,
    id: PropTypes.number,
    t: PropTypes.func,
    isLoading: PropTypes.bool,
    emptyListOptions: PropTypes.object,
    setActiveItemId: PropTypes.func,
    activeId: PropTypes.number,
    notMark: PropTypes.bool,
    timeRange: PropTypes.bool,
    emptyStyles: PropTypes.bool
  };

  static defaultProps = {
    emptyListOptions: {}
  };

  get emptyListMessage () {
    const { emptyListOptions: { title, icon, showNotFound }, data, isLoading, t } = this.props

    const message = title || (showNotFound ? t('shared', 'labels', 'notFound').s : t('shared', 'labels', 'emptyList').s)

    if (!data.length && !isLoading) {
      return (
        <div className='list-view__empty'>
          <span className={`icon ${icon}`} />
          <h2>{message}</h2>
        </div>
      )
    } else {
      return null
    }
  }

  getSelectedClassName (item) {
    const {activeId, notMark} = this.props
    if (isIpadResolution() && !notMark && activeId && (activeId === this.getItemId(item))) {
      return 'list-view__item--selected'
    } else {
      return ''
    }
  }

  getBaseClassNames (className = 'list-view__item') {
    const {emptyStyles} = this.props
    const { whiteLabel } = getGreyLabelOptions()
    let resultClassName = cs(className, whiteLabel)

    if (!emptyStyles) {
      return resultClassName
    } else {
      return ''
    }
  }

  onClickItem (item) {
    const {setActiveItemId, notMark} = this.props

    if (isIpadResolution() && !notMark) {
      setActiveItemId(this.getItemId(item))
    }
  }

  getItemId (item) {
    // backcompability for items without id field
    return item.id || item.path || item.title
  }

  render () {
    const {
      data, template, title, passIndex, eventType, onCheckboxChange, clickHandler, t, id, timeRange, ...props
    } = this.props

    let html = null
    if (data && passIndex) {
      html = data.map((item, key) => {
        return (
          <div
            onClick={() => { this.onClickItem(item) }}
            className={`${this.getBaseClassNames()} ${this.getSelectedClassName(item)}`}
            key={key}
          >
            {React.createElement(template, {data: item, index: key, clickHandler: clickHandler, eventType: eventType, t, ...props})}
          </div>
        )
      })
    } else if (data && onCheckboxChange) {
      html = data.map((item, key) => {
        return (
          <div className={this.getBaseClassNames()} key={key}>{React.createElement(template, {data: item, onCheckboxChange, id, t})}</div>
        )
      })
    } else if (data && timeRange) {
      html = <TimeRange data={data} t={t} template={template} onClickItem={this.onClickItem} getSelectedClassName={this.getSelectedClassName} {...props} />
    } else if (data) {
      html = data.map((item, key) => {
        return (
          <div onClick={() => { this.onClickItem(item) }} className={`${this.getBaseClassNames()} ${this.getSelectedClassName(item)}`} key={key}>{React.createElement(template, {data: item, t, ...props})}</div>
        )
      })
    }

    return (
      <div>
        {title && <div className='list-view-title'>{title}</div>}
        <div className={this.getBaseClassNames('list-view')}>{html}</div>
        { this.emptyListMessage }
      </div>
    )
  }
}

function mapStateToProps (state) {
  return {
    t: state.i18n.get('app'),
    isLoading: state.navbarSpinner.value,
    activeId: Number(state.appInfo.activeItemId)
  }
}

function mapDispatchProps (dispatch) {
  return {
    setActiveItemId: (id) => { dispatch(setActiveItemIdAction(id)) }
  }
}

export default connect(mapStateToProps, mapDispatchProps)(ListView)
