import { result, find } from 'lodash'
import Moment from 'moment'
import isMobile from 'ismobilejs'
import { browserHistory } from 'react-router'
import platform from 'platform'

// find prop name from object using 'name:value'
export function findValueInObjectByProp (obj, param, returnPropName) {
  return result(find(obj, param), returnPropName)
}

// Moment
// show time like 00:00:00 (optional), fullFormat - with seconds
export function setTime (value, receipt, format) {
  const duration = Moment.duration(value, receipt)
  const setZero = (val) => ('0' + val).substr(-2)
  const hours = () => {
    let currHours = String(Math.floor(duration.asHours()))
    if (currHours.length === 1) {
      currHours = setZero(currHours)
    }
    return currHours
  }
  const minutes = setZero(duration.get('minutes'))
  const seconds = setZero(duration.get('seconds'))
  if (format === 'HH:MM:SS') {
    return `${hours()}:${minutes}:${seconds}`
  } else if (format === 'HHs:MMs') {
    return `${hours()} H:${minutes} M`
  } else if (format === 'HH:MM') {
    return `${hours()}:${minutes}`
  }
}

// get difference between dates as minutes or seconds
export function getDifferentFromDates (dateFrom, dateTo, receipt) {
  const formattedDateFrom = Moment(dateFrom).format('YYYY-MM-DDTHH:mm:ss')
  const formattedDateTo = Moment(dateTo).format('YYYY-MM-DDTHH:mm:ss')

  const different = Moment.duration(Moment(formattedDateFrom, 'YYYY-MM-DDTHH:mm:ss').diff(Moment(formattedDateTo, 'YYYY-MM-DDTHH:mm:ss')))
  if (receipt === 'minutes') {
    return different.asMinutes()
  }
  if (receipt === 'seconds') {
    return different.asSeconds()
  }
}

export function convertToStringTime (value) {
  return Moment(value, 'YYYY-MM-DDTHH:mm:ss').format('YYYY-MM-DD HH:mm:ss')
  // return Moment.utc(value).zone(Moment().utcOffset()).format('YYYY-MM-DD HH:mm:ss')
}

export function getCookie (name) {
  var matches = document.cookie.match(new RegExp(
    '(?:^|; )' + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)'
  ))
  return matches ? decodeURIComponent(matches[1]) : undefined
}

export function addHashForIos () {
  const isIos = getCookie('passingToken')
  if (isIos) {
    window.location.hash = 'final_step'
  }
}

export function isIpadResolution () {
  // TODO research intersections with android devices width. Currently set to iPad mini width
  return window.screen.width >= 744
}

export function isIpadLandscapeResolution () {
  return window.screen.width >= 1024
}

export function isDesktop () {
  if (process.env.NODE_ENV === 'test') return false
  return !isMobile.any
}

export function getCurrentDeviceView (phoneView, iPadView) {
  if (isIpadResolution()) {
    return iPadView
  } else {
    return phoneView
  }
}

export function disableAnimation () {
  const style = document.createElement('style')
  style.type = 'text/css'
  style.innerHTML = '* {' +
    '/*CSS transitions*/' +
    ' -o-transition-property: none !important;' +
    ' -moz-transition-property: none !important;' +
    ' -ms-transition-property: none !important;' +
    ' -webkit-transition-property: none !important;' +
    '  transition-property: none !important;' +
    '/*CSS transforms*/' +
    '  -o-transform: none !important;' +
    ' -moz-transform: none !important;' +
    '   -ms-transform: none !important;' +
    '  -webkit-transform: none !important;' +
    '   transform: none !important;' +
    '  /*CSS animations*/' +
    '   -webkit-animation: none !important;' +
    '   -moz-animation: none !important;' +
    '   -o-animation: none !important;' +
    '   -ms-animation: none !important;' +
    '   animation: none !important;}'
  document.getElementsByTagName('head')[0].appendChild(style)
}

export function hideFooterOnInputFocus () {
  const height = window.offsetHeight

  if (isMobile.android.phone) {
    document.write([
      '<style>',
      '.navigation__footer { visibility: hidden; }',
      `#root { height: ${height}px!important; }`,
      `@media screen and (orientation: portrait) and (min-height: ${height - 10}px)`,
      `{ .navigation__footer { visibility: visible; } }`,
      '</style>'
    ].join(''))
  }
}

export function delay (duration) {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve()
    }, duration)
  })
}

export const createDelayedRedirect = (links) => () => {
  let delay = 0
  const step = 25

  links.forEach((link) => {
    setTimeout(() => {
      browserHistory.push(link)
    }, delay)
    delay += step
  })
}

export const isIOS = () => platform.os.family.includes('iOS')
