import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { ClientOverviewContainer } from './Smartphones'
import OverviewList from 'Overview/Overview/components/List/List'
import IpadNavigation from 'Navigation/IpadNavigation'
import Navigation from 'Navigation/Navigation'

class LeftSide extends Component {
  static propTypes = {
    t: PropTypes.func
  }
  render () {
    const { t } = this.props
    return (
      <Navigation title={t('navbar', 'title').s} className='overview-view'>
        <OverviewList {...this.props} />
      </Navigation>
    )
  }
}

class ClientOverview extends Component {
  render () {
    return (
      <IpadNavigation notBack {...this.props}>
        <LeftSide {...this.props} />
        <ClientOverviewContainer {...this.props} />
      </IpadNavigation>
    )
  }
}

const mapStateToProps = state => {
  return {
    t: state.i18n.get('app', 'views', 'OverviewView'),
    title: state.i18n.get('app', 'views', 'ClientOverview', 'title').s,
    collectorStatus: state.marketplace.collector.onboardingStatus,
    loan: state.overview.loan
  }
}

export default connect(mapStateToProps)(ClientOverview)
