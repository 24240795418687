import axios from 'api-client-connector'
import { createAction } from 'redux-act'
import { camelizeKeys, decamelizeKeys } from 'humps'

import { hideNavbarSpinnerAction, showSlowNavbarSpinnerAction } from 'shared/actions/navbarSpinner'
import { showMessageBoxWithParamsAction } from 'MessageBox/actions/messageBox'

import { VACANCIES, ROLE_AREAS, VACANCY } from '../constants/api'

export const vacanciesToStore = createAction('Vacancies to store')
export const extendVacanciesList = createAction('Extend vacancies list')
export const vacancyToStore = createAction('Vacancy to store')
export const roleAreasToStore = createAction('Role areas to store')

export function loadVacancies (filters = {}, offset = 0) {
  return async (dispatch, getState) => {
    const params = {
      ...decamelizeKeys(filters),
      offset,
      limit: 20
    }
    dispatch(showSlowNavbarSpinnerAction())
    try {
      const response = await getVacanciesRequest(params)
      const total = response.data.number_of_vacancies
      const hasMoreVacancies = offset < total && total > 20
      const vacancies = response.data.vacancies.map(vacancy =>
        ({
          ...vacancy.full_data,
          keymanId: vacancy.full_data.id,
          id: vacancy.id
        }))
      const payload = { offset, data: camelizeKeys(vacancies), total, hasMoreVacancies }
      dispatch([
        offset === 0 ? vacanciesToStore(payload) : extendVacanciesList(payload),
        hideNavbarSpinnerAction()
      ])
    } catch (e) {
      processError(e, getState, dispatch)
      dispatch(hideNavbarSpinnerAction())
    }
  }
}

function getVacanciesRequest (params) {
  return axios.get(VACANCIES(params))
}

export function loadRoleAreas () {
  return async (dispatch, getState) => {
    dispatch(showSlowNavbarSpinnerAction())
    try {
      const response = await getRoleAreasRequest()
      dispatch([
        roleAreasToStore(camelizeKeys(response.data)),
        hideNavbarSpinnerAction()
      ])
    } catch (e) {
      processError(e, getState, dispatch)
      dispatch(hideNavbarSpinnerAction())
    }
  }
}

function getRoleAreasRequest () {
  return axios.get(ROLE_AREAS)
}

export function loadVacancy (id) {
  return async (dispatch, getState) => {
    dispatch(showSlowNavbarSpinnerAction())
    try {
      const response = await getVacancyRequest(id)
      dispatch([
        vacancyToStore(camelizeKeys({...response.data, keymanId: response.data.id})),
        hideNavbarSpinnerAction()
      ])
    } catch (e) {
      processError(e, getState, dispatch)
      dispatch(hideNavbarSpinnerAction())
    }
  }
}

function getVacancyRequest (id) {
  return axios.get(VACANCY(id))
}

function processError (error, getState, dispatch) {
  const t = getState().i18n.get('app', 'shared', 'messages')
  dispatch(showMessageBoxWithParamsAction(null, t('unexpectedError', 'description').s))
  console.log('error', error)
}
