import { createReducer } from 'redux-act'

import { kivraValidatedFieldsToStore } from 'Integrations/Kivra/actions'

const initialState = {
  validatedFields: { initialValues: {}, currentErrors: {} }
}

export default createReducer({
  [kivraValidatedFieldsToStore]: (state, payload) => ({
    ...state,
    validatedFields: payload
  })
}, initialState)
