import React from 'react'
import { Route } from 'react-router'
import { destroy } from 'redux-form'
import { requireAuthentication } from 'User/Auth/higherOrderComponents/AuthComponent'

// Views
import ArticlesView from 'Articles/views/ArticlesView/ArticlesView'
import ArticleView from 'Articles/views/ArticleView/ArticleView'

// Form-cleaning components
import createClearFormComponent from 'shared/factories/ClearForm'
import { clearArticlePropsAction } from 'Articles/actions/article'
const ClearArticleForm = createClearFormComponent(clearArticlePropsAction)
const destroyArticlesFiltersForm = (dispatch) => dispatch(destroy('articlesViewFilters'))

const articlesRoutes = (
  <Route onLeave={destroyArticlesFiltersForm}>
    <Route path='/articles' component={requireAuthentication(ArticlesView)} />
    <Route component={ClearArticleForm}>
      <Route path='/articles/:articleNumber' component={requireAuthentication(ArticleView)} />
    </Route>
  </Route>
)

export default articlesRoutes
