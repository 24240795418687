import { defaultsDeep, indexOf, has, merge } from 'lodash'
import getGreyLabelOptions from 'GreyLabel/helpers/greyLabel'

function deepMerge (source, value) {
  // TODO: refactor
  Object.keys(source).forEach((key) => {
    if (indexOf(Object.keys(value), key) !== -1) {
      defaultsDeep(source[key], value[key])
    } else if (typeof source[key] === 'object') {
      deepMerge(source[key], value)
    }
  })
}

function tryToApplyGreyLabelTexts (data, greyLabelValue) {
  Object.keys(data).forEach((key) => {
    if (typeof data[key] !== 'object') {
      return
    }

    const objectToEdit = data[key]
    if (greyLabelValue && has(objectToEdit, ['greyLabel', '_shared'])) {
      merge(objectToEdit, data[key].greyLabel._shared)
    }
    if (has(objectToEdit, ['greyLabel', greyLabelValue])) {
      merge(objectToEdit, data[key].greyLabel[greyLabelValue])
    } else {
      tryToApplyGreyLabelTexts(objectToEdit, greyLabelValue)
    }
  })
}

function applyGreyLabelTexts (data) {
  const greyLabel = getGreyLabelOptions().greyLabel
  if (greyLabel) {
    tryToApplyGreyLabelTexts(data, greyLabel)
  }
}

function extendLocaleData (data) {
  let newData = {}
  Object.assign(newData, data)
  deepMerge(newData, data.app.shared)
  applyGreyLabelTexts(newData)

  return newData
}

export default function middleware () {
  return next => action => {
    if (action.type === '@@i18n/loadLangSuccess') {
      const newData = extendLocaleData(action.lang)
      return next({
        ...action,
        lang: newData
      })
    } else {
      return next(action)
    }
  }
}
