/* eslint-disable camelcase */
import PropTypes from 'prop-types'

import React, { Component } from 'react'

// Components
import InputSwitcher from 'shared/components/InputSwitcher/InputSwitcher'
import FormatNumber from 'shared/components/FormatNumber/FormatNumber'

export default class FinanceVisualizationTopControl extends Component {
  constructor (props) {
    super(props)
    this.onToggleChartType = this.onToggleChartType.bind(this)
  }

  static propTypes = {
    project: PropTypes.object,
    location: PropTypes.object,
    t: PropTypes.func,
    changeChartType: PropTypes.func,
    vat: PropTypes.bool,
    onVATModeChange: PropTypes.func,
    tabletMode: PropTypes.bool
  }

  onToggleChartType () {
    const { location: { query: { chart_type } }, changeChartType } = this.props
    if (chart_type === 'bars') {
      changeChartType('pie')
    } else {
      changeChartType('bars')
    }
  }

  render () {
    const { project, t, location: { query: { chart_type = 'pie' } }, vat, onVATModeChange, tabletMode } = this.props
    const headerClass = chart_type === 'pie'
      ? 'finance-visualization__top-control finance-visualization__top-control--with-border'
      : 'finance-visualization__top-control'
    return (
      <div className={headerClass}>
        <div className='finance-visualization__top-control__values'>
          <div>
            <span>{t('includeVat').s}</span>
            <FormatNumber value={project.totalAmountWithVat || 0} notFraction />
          </div>
          <div>
            <b>{t('excludeVat').s}</b>
            <b><FormatNumber value={project.totalAmount || 0} notFraction /></b>
          </div>
        </div>
        {!tabletMode && <div className='finance-visualization__top-control__toggle'>
          <span onClick={this.onToggleChartType} className={chart_type === 'bars' ? 'icon-pie-chart' : 'icon-bar-chart'} />
        </div>}
        <div className='finance-visualization__top-control__switch-vat'>
          <div className='label'>{t('vat').s}</div>
          <InputSwitcher dark value={vat} onChange={onVATModeChange} />
        </div>
      </div>
    )
  }
}
/* eslint-enable camelcase */
