import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import styles from './index.scss'

import Frame from 'MarketPlace/shared/components/Frame'
import Navigation from 'Navigation/NavigationForIframe'

import {capcitoRedirectLinkToStore, loadOnboardingInfo} from 'MarketPlace/Capcito/actions/capcito'
import { hideNavbarSpinnerAction, showSlowNavbarSpinnerAction } from 'shared/actions/navbarSpinner'

class CapcitoFrame extends Component {
  static propTypes = {
    showSpinner: PropTypes.func.isRequired,
    hideSpinner: PropTypes.func.isRequired,
    loadOnboardingInfo: PropTypes.func.isRequired,
    clearRedirectLink: PropTypes.func.isRequired,
    redirectLink: PropTypes.string
  }

  state = {
    url: null
  }

  async componentDidMount () {
    const { redirectLink } = this.props
    this.props.showSpinner()

    let url
    if (!redirectLink) {
      const result = await this.props.loadOnboardingInfo({ refreshRedirectLink: true })
      if (result) url = result.data.redirectLink
    } else {
      url = redirectLink
    }
    this.setState({url}, () => this.props.hideSpinner())
  }

  componentWillUnmount () {
    this.props.clearRedirectLink()
  }

  render () {
    return (
      <Frame
        className={styles.frame}
        url={this.state.url || ''}
        message={{'X-Authorization': 'capcito-5cecdf2b10fef8.99524844'}}
      />
    )
  }
}

const CapcitoFrameWithNavigation = (props) => {
  return (
    <Navigation title={props.t('categories', props.selectedCategory).s}>
      <CapcitoFrame {...props} />
    </Navigation>
  )
}

CapcitoFrameWithNavigation.propTypes = {
  t: PropTypes.func,
  selectedCategory: PropTypes.string
}
const mapStateToProps = (state) => ({
  redirectLink: state.marketplace.capcito.redirectLink
})

const mapDispatchToProps = (dispatch) => ({
  showSpinner: () => dispatch(showSlowNavbarSpinnerAction()),
  hideSpinner: () => dispatch(hideNavbarSpinnerAction()),
  loadOnboardingInfo: (options) => dispatch(loadOnboardingInfo(options)),
  clearRedirectLink: () => dispatch(capcitoRedirectLinkToStore(null))
})

export const CapcitoFrameView = connect(mapStateToProps, mapDispatchToProps)(CapcitoFrame)
export default connect(mapStateToProps, mapDispatchToProps)(CapcitoFrameWithNavigation)
