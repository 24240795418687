export default function getEmptyListOptions (t, premiumUser) {
  if (premiumUser) {
    return {
      title: t('noReportsWithAdmin').s
    }
  } else {
    return {
      title: t('noReportsWithoutAdmin').s
    }
  }
}
