import URI from 'urijs'
import { API_BASE } from 'shared/constants/Api'

export const COLLECTOR_VALIDATE = `${API_BASE}/api/v2/marketplace/collector_bank/onboarding/validate/`
export const COLLECTOR_ONBOARDING = `${API_BASE}/api/v2/marketplace/collector_bank/onboarding/`
export const COLLECTOR_APPLICATIONS = `${API_BASE}/api/v2/marketplace/collector_bank/onboarding/applications/`
export const COLLECTOR_FACTORING_CREDIT = `${API_BASE}/api/v2/marketplace/collector_bank/factoring/invoices/credit/`
export const COLLECTOR_FACTORING = (params) =>
  URI(`${API_BASE}/api/v2/marketplace/collector_bank/factoring/invoices/`).addSearch(params)
export const COLLECTOR_DIRECT_PAYMENT = (params) =>
  URI(`${API_BASE}/api/v2/marketplace/collector_bank/factoring/invoices/register_direct_payment/`).addSearch(params)
