import PropTypes from 'prop-types'
import React, { Component } from 'react'
import {connect} from 'react-redux'

// Components
import Navigation from 'Navigation/Navigation'
import ListView from 'shared/components/ListView/ListView'
import SelectableListItem from 'shared/components/SelectableListItem/SelectableListItem'
import FormatDate from 'shared/components/FormatDate/FormatDate'
import Filters from 'Timesheet/views/TimesheetsView/Filters/Filters'

// Actions
import { extendTimesheetsListAction } from 'Timesheet/actions/timesheetsList'
import { addItemAction, removeItemAction, removeAllImportItems } from 'ImportItems/actions/importItems'

// Helpers
import { setTime } from 'shared/helpers/utils'

export function TimesheetsImportListItem ({onCheckboxChange, ...props}) {
  const {exported, startTime, stopTime, title, minutes, projectName} = props.data
  return (
    <SelectableListItem {...props} onChange={onCheckboxChange}>
      <div className='list-multiline-item'>
        <div className='list-multiline-item__column-wrapper'>
          <div className='list-multiline-item__column'>
            <h4>{`${title} ${projectName}`}</h4>
            <h5><FormatDate value={startTime} format='YYYY-MM-DD HH:mm' /></h5>
          </div>
          <div className='list-multiline-item__last'>
            <div className='list-multiline-item__icons'>
              { exported && <span className='icon-bills-full' /> }
            </div>
            <div className='list-multiline-item__column'>
              <h4>{setTime(minutes, 'minutes', 'HHs:MMs')}</h4>
              <h5><FormatDate value={stopTime} format='YYYY-MM-DD HH:mm' /></h5>
            </div>
          </div>
        </div>
        <h4 className='list-multiline-item__arrow'><span className='icon-arrow-right' /></h4>
      </div>
    </SelectableListItem>
  )
}

TimesheetsImportListItem.propTypes = {
  onCheckboxChange: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired
}

class TimesheetsImport extends Component {
  static propTypes = {
    onCheck: PropTypes.func,
    onUncheck: PropTypes.func,
    extendListData: PropTypes.func,
    listData: PropTypes.arrayOf(PropTypes.object),
    clearItems: PropTypes.func
  }

  constructor (props) {
    super(props)
    this.onCheckboxClickHandler = this.onCheckboxClickHandler.bind(this)
  }

  componentDidMount () {
    this.props.extendListData()
  }

  componentWillUnmount () {
    this.props.clearItems()
  }

  extendList () {
    this.props.extendListData()
  }

  onCheckboxClickHandler (isChecked, data) {
    isChecked ? this.props.onCheck(data) : this.props.onUncheck(data)
  }

  render () {
    const { listData } = this.props

    return (
      <ListView data={listData} template={TimesheetsImportListItem} onCheckboxChange={this.onCheckboxClickHandler} />
    )
  }
}

class TimesheetsImportWithNavigation extends Component {
  static propTypes = {
    importItemsToForm: PropTypes.func,
    itemsForImport: PropTypes.arrayOf(PropTypes.object),
    backLink: PropTypes.string,
    t: PropTypes.func
  }

  constructor (props) {
    super(props)
    this.extendList = this.extendList.bind(this)
  }

  extendList () {
    this.refs.timesheets.extendList()
  }
  render () {
    const { importItemsToForm, itemsForImport, t, backLink } = this.props
    const actions = [
      { path: '/timesheets/new?cameFrom=invoice', icon: 'icon-add' }
    ]

    return (
      <Navigation filterBlock hideFooter backLink={backLink} title={t('title').s} actions={actions} infinityScrollHandler={this.extendList}>
        <Filters onImportClick={() => { importItemsToForm(itemsForImport) }} />
        <TimesheetsImport {...this.props} ref='timesheets' />
      </Navigation>
    )
  }
}

function mapStateToProps (state) {
  return {
    itemsForImport: state.importItems,
    listData: state.timesheets.listData,
    t: state.i18n.get('app', 'views', 'Timesheet', 'import'),
    t_button: state.i18n.get('app', 'shared', 'buttons')
  }
}

function mapDispatchToProps (dispatch) {
  return {
    onCheck: (data) => { dispatch(addItemAction(data)) },
    onUncheck: (data) => { dispatch(removeItemAction(data)) },
    clearItems: () => { dispatch(removeAllImportItems()) },
    extendListData: () => { dispatch(extendTimesheetsListAction()) }
  }
}
export const TimesheetsImportView = connect(mapStateToProps, mapDispatchToProps, null, { withRef: true })(TimesheetsImport)

export default connect(mapStateToProps, mapDispatchToProps)(TimesheetsImportWithNavigation)
