import URI from 'urijs'
import {API_BASE} from 'shared/constants/Api'

export const PROJECTS_LIST = (params) => { return URI(`${API_BASE}/api/v2/projects/`).addSearch(params) }
export const PROJECT_INFO = ({id, ...params}) => { return URI(`${API_BASE}/api/v2/projects/${id}/`).addSearch(params) }
export const PROJECT_NEW = ({...params}) => { return URI(`${API_BASE}/api/v2/projects/`).addSearch(params) }

// currently export not exists in app
export const PROJECT_EXPORT = ({id, ...params}) => { return URI(`${API_BASE}/api/print/project/${id}/`).addSearch(params) }

// relations
export const PROJECT_CREATE_TIMESHEET = ({id, ...params}) => URI(`${API_BASE}/api/v2/projects/${id}/timesheets/`).addSearch(params)
export const PROJECT_CREATE_TRANSACTION = ({id, ...params}) => URI(`${API_BASE}/api/v2/projects/${id}/transactions/`).addSearch(params)
export const PROJECT_CREATE_DRIVERLOG = ({id, ...params}) => URI(`${API_BASE}/api/v2/projects/${id}/driverlogs/`).addSearch(params)

export const PROJECT_DELETE_TIMESHEET = ({id, relationId, ...params}) => URI(`${API_BASE}/api/v2/projects/${id}/timesheets/${relationId}/`).addSearch(params)
export const PROJECT_DELETE_TRANSACTION = ({id, relationId, ...params}) => URI(`${API_BASE}/api/v2/projects/${id}/transactions/${relationId}/`).addSearch(params)
export const PROJECT_DELETE_DRIVERLOG = ({id, relationId, ...params}) => URI(`${API_BASE}/api/v2/projects/${id}/driverlogs/${relationId}/`).addSearch(params)

// financial-presenter
export const PROJECT_REVENUES = ({id, ...params}) => { return URI(`${API_BASE}/api/v2/financial-presenter/projects/${id}/details/revenue/`).addSearch(params) }
export const PROJECT_EXPENSES = ({id, ...params}) => { return URI(`${API_BASE}/api/v2/financial-presenter/projects/${id}/details/expenses/`).addSearch(params) }
