import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'

// Components
import Button from 'shared/components/Button/Button'
import FurtherActionsModal from 'OCR/components/FurtherActionsModal/FurtherActionsModal'

// Actions
import { markAsFileForOCR } from 'FileUpload/actions/filesToUpload'

class AutocompleteButton extends Component {
  static propTypes = {
    saveFunction: PropTypes.func,
    eventType: PropTypes.string,
    makeFirstFileForOCR: PropTypes.func,
    newFiles: PropTypes.arrayOf(PropTypes.object)
  }

  constructor (props) {
    super(props)
    this._onClick = this._onClick.bind(this)
  }

  _onClick () {
    this.props.makeFirstFileForOCR()
    this.props.saveFunction()
    this.modal.open()
  }

  render () {
    const { eventType, newFiles } = this.props

    return (
      <div>
        { newFiles.length ? <Button saveButton className='receipt__save' onClick={this._onClick} view='transparent-black'>Autocomplete</Button> : null }
        <FurtherActionsModal eventType={eventType} provideController={(c) => { this.modal = c }} />
      </div>
    )
  }
}

function mapStateToProps (state) {
  return {
    newFiles: state.filesToUpload.newFiles
  }
}

function mapDispatchToProps (dispatch) {
  return {
    makeFirstFileForOCR: () => dispatch(markAsFileForOCR(0))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AutocompleteButton)
