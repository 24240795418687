import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { scaleOrdinal } from 'd3-scale'

import PieChart from 'shared/components/Chart/Pie'
import Spinner from 'shared/components/Spinner/Spinner'
import Legend from 'Overview/ClientOverview/components/Legend'
import DataRow from 'Overview/ClientOverview/components/DataRow'

import chartColors from 'Overview/constants/ChartColors'

import './index.scss?global'

export default class ClientOverview extends Component {
  static propTypes = {
    myEconomy: PropTypes.object,
    myInvoices: PropTypes.object,
    invoiceStatus: PropTypes.object
  }

  getColor = scaleOrdinal(chartColors)

  render () {
    const { t, myEconomy, myInvoices, invoiceStatus } = this.props
    if (myEconomy.balanceSettlementAccount === undefined) {
      return (
        <div className='client-overview-spinner'>
          <Spinner dark />
        </div>
      )
    }
    return (
      <div className='client-overview'>
        <div className='hr'>{`  ${t('myEconomy', 'blockTitle').s}  `}</div>
        <div className='client-overview__info-block'>
          <PieChart
            data={myEconomy.chartData}
            getColor={this.getColor}
            donutId={1}
            disablePropeller
            disablePercentArrow
            chartName={t('myEconomy', 'chartTitle').s}
          />
          <DataRow value={myEconomy.totalLimit} description={t('myEconomy', 'totalLimit').s} />
          <div className='hr' />
          <Legend
            colors={chartColors}
            data={myEconomy.chartData}
          />
          <DataRow value={myEconomy.balanceSettlementAccount} description={t('myEconomy', 'balanceSettlementAccount').s} />
        </div>
        <div className='hr'>{`  ${t('myInvoices', 'blockTitle').s}  `}</div>
        <div className='client-overview__info-block'>
          <PieChart
            data={myInvoices.chartData}
            getColor={this.getColor}
            donutId={2}
            disablePropeller
            disablePercentArrow
            chartName={t('myInvoices', 'chartTitle').s}
          />
          <DataRow value={myInvoices.activeInvoices} description={t('myInvoices', 'activeInvoices').s} />
          <div className='hr' />
          <Legend
            colors={chartColors}
            data={myInvoices.chartData}
          />
          <DataRow value={myInvoices.activeCreditInvoices} description={t('myInvoices', 'activeCreditInvoices').s} />
        </div>
        <div className='hr'>{`  ${t('invoicesStatuses', 'blockTitle').s}  `} </div>
        <div className='client-overview__info-block'>
          <PieChart
            data={invoiceStatus.chartData}
            getColor={this.getColor}
            donutId={3}
            disablePropeller
            disablePercentArrow
            chartName={t('invoicesStatuses', 'chartTitle').s}
          />
          <Legend
            colors={chartColors}
            data={invoiceStatus.chartData}
          />
        </div>
      </div>
    )
  }
}
