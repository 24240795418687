import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Button from 'shared/components/Button/Button'
import Icon from 'shared/components/Icon'
import Modal from 'shared/components/NewModal/NewModal'

import './styles.scss?global'

class ValidationErrorModal extends Component {
  componentWillReceiveProps (nextProps) {
    if (nextProps.isVisible !== this.props.isVisible) {
      nextProps.isVisible && this.refs.validationErrorModal.open()
      !nextProps.isVisible && this.refs.validationErrorModal.close()
    }
  }
  render () {
    const { t, redirectToSettings, close } = this.props
    return (
      <Modal ref='validationErrorModal' onClose={() => close()}>
        <div className='validation_error_modal__info'>
          <Icon type='meditator-balls' />
          <div className='market-place__notification'>!</div>
          <h3>{t('validationError', 'message').s}</h3>
          <Button onClick={() => redirectToSettings()}>
            {t('validationError', 'toSettings').s}
          </Button>
        </div>
      </Modal>
    )
  }
}

ValidationErrorModal.propTypes = {
  t: PropTypes.func,
  isVisible: PropTypes.bool,
  redirectToSettings: PropTypes.func,
  close: PropTypes.func
}

export default ValidationErrorModal
