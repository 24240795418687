import { createReducer } from 'redux-act'
import { updateWizardProfileStorage } from 'Wizard/actions/wizardProfile'

const initialState = {
  addresses: []
}

export default createReducer({
  [updateWizardProfileStorage]: (state, payload) => ({
    ...state,
    ...payload
  })
}, initialState)
