import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'

import Icon from 'shared/components/Icon'
import Input from 'shared/components/Input/Input'
import Button from 'shared/components/Button/Button'
import Checkbox from 'shared/components/Checkbox/Checkbox'
import FormLayout from 'Integrations/shared/FormLayout'

import '../styles/kivra-form.scss?global'

const TenantForm = ({ texts, handleSubmit, onSubmit }) =>
  <FormLayout>
    <Icon type='kivra' className='integration-kivra-logo' />
    <h2>{texts.header}</h2>
    <span className='integration-description'>{texts.description}</span>
    <div className='integration-form'>
      <div>
        <Field
          placeholder={texts.tFields('name').s}
          component={InputWrapper}
          name='userName'
        />
        <Field
          placeholder={texts.tFields('email').s}
          component={InputWrapper}
          name='userEmail'
        />
        <Field
          placeholder={texts.tFields('companyName').s}
          component={InputWrapper}
          name='companyName'
        />
        <Field
          placeholder={texts.tFields('vatNumber').s}
          component={InputWrapper}
          name='vATNumber'
        />
      </div>
      <span className='kivra-terms'>
        {texts.tTerms('readAndApprove').s}<a href='https://kivra.se/sv/om-oss/om-kivra/villkor/allmanna-villkor-for-formedlingstjansten' target='_blank'> {texts.tTerms('termsLink').s} </a>
        {texts.tTerms('handling').s}<a href='https://kivra.se/sv/om-oss/om-kivra/villkor/personuppgiftsbitradesavtal-for-avsandare-av-e-forsandelser' target='_blank'> {texts.tTerms('personalDataLink').s}</a>.
      </span>
      <div className='kivra-agreements'>
        <Field
          name='acceptedGeneralAgreement'
          description={texts.tCheckbox('acceptedGeneralAgreement').s}
          component={CheckboxRow}
        />
        <Field
          name='acceptedGdprAgreement'
          description={texts.tCheckbox('acceptedGdprAgreement').s}
          component={CheckboxRow}
        />
      </div>
      <div className='kivra-buttons'>
        <Button onClick={handleSubmit(onSubmit)}>{texts.tButtons('submitButton').s}</Button>
        <Button goBackButton>{texts.tButtons('cancel').s}</Button>
      </div>
    </div>
  </FormLayout>

const CheckboxRow = ({ name, description, ...props }) =>
  <div className='kivra-checkbox-row'>
    <CheckboxWrapper
      name={name}
      component={CheckboxWrapper}
      {...props}
    />
    <span className={props.meta.error && 'kivra-checkbox-row__description_error'}>
      {description}
    </span>
  </div>

CheckboxRow.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  meta: PropTypes.object
}

const InputWrapper = ({input, meta, ...props}) =>
  <Input {...input} {...meta} {...props} />

InputWrapper.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object
}

const CheckboxWrapper = ({input, meta, ...props}) =>
  <Checkbox {...input} {...meta} {...props} />

CheckboxWrapper.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object
}

TenantForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  texts: PropTypes.shape({
    description: PropTypes.string.isRequired,
    header: PropTypes.string.isRequired,
    tFields: PropTypes.func.isRequired,
    tButtons: PropTypes.func.isRequired,
    tTerms: PropTypes.func.isRequired,
    tCheckbox: PropTypes.func.isRequired
  })
}

const form = 'kivra-tenant-form'
const reduxFormHOC = reduxForm({
  form,
  enableReinitialize: true
})

export default reduxFormHOC(TenantForm)
