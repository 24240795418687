import { connect } from 'react-redux'
import { showNavbarSpinnerAction, hideNavbarSpinnerAction } from 'shared/actions/navbarSpinner'

export default function (Component) {
  function mapDispatchToProps (dispatch) {
    return {
      showSpinner: () => { dispatch(showNavbarSpinnerAction()) },
      hideSpinner: () => { dispatch(hideNavbarSpinnerAction()) }
    }
  }

  return connect(null, mapDispatchToProps)(Component)
}
