import {createAction} from 'redux-actions'
import { SHOW_MESSAGE_BOX, CLOSE_MESSAGE_BOX, SET_MESSAGE_BOX_PROPS } from '../constants/MessageBox'

export const showMessageBox = createAction(SHOW_MESSAGE_BOX)
export const closeMessageBox = createAction(CLOSE_MESSAGE_BOX)
export const setMessageBoxProps = createAction(SET_MESSAGE_BOX_PROPS)

export function showMessageBoxWithParamsAction (title, text, onClose = null) {
  return [setMessageBoxProps({ title, text, onClose }), showMessageBox()]
}

export function closeMessageBoxAction () {
  return closeMessageBox()
}

export function showElementInMessageBoxAction (element, onClose) {
  return [setMessageBoxProps({ element, onClose }), showMessageBox()]
}
