exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._3Zl9TwJcqzP5MA5egJwpNS {\n  display: flex;\n  align-items: center;\n  margin: 0 -1.5rem;\n}\n\n.j5ToTKZXbfD4kYQebEO3L {\n  height: 3.5rem;\n  width: 3.5rem;\n  flex-shrink: 0;\n}\n\n._84ZTsYzz31QExoryAVIwU {\n  max-width: 39rem;\n  padding-left: 2rem;\n  white-space: nowrap;\n  overflow: hidden;\n}\n\n.-m8NahVRZStHYqHt2MLwi {\n  display: flex;\n  flex-shrink: 0;\n  margin-left: auto;\n  align-items: center;\n}\n\n._1kqW0tR6sHd5xNdJ2-fTYo {\n  display: flex;\n  flex-direction: column;\n  text-align: right;\n  margin-right: 2rem;\n}\n\n._1kqW0tR6sHd5xNdJ2-fTYo>* {\n  padding: 0.5rem 0;\n}\n\n.d6cxyAP3LYo6Q6t0NiU0Q {\n  color: #839AA7;\n}\n\n", "", {"version":3,"sources":["/home/circleci/mobile_ui/src/Reports/views/TaxPaymentReport/TaxPaymentReportsView/TaxPaymentReportsView.scss"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,oBAAoB;EACpB,kBAAkB;CACnB;;AAED;EACE,eAAe;EACf,cAAc;EACd,eAAe;CAChB;;AAED;EACE,iBAAiB;EACjB,mBAAmB;EACnB,oBAAoB;EACpB,iBAAiB;CAClB;;AAED;EACE,cAAc;EACd,eAAe;EACf,kBAAkB;EAClB,oBAAoB;CACrB;;AAED;EACE,cAAc;EACd,uBAAuB;EACvB,kBAAkB;EAClB,mBAAmB;CACpB;;AAED;EACE,kBAAkB;CACnB;;AAED;EACE,eAAe;CAChB","file":"TaxPaymentReportsView.scss","sourcesContent":[".listItem {\n  display: flex;\n  align-items: center;\n  margin: 0 -1.5rem;\n}\n\n.statusIcon {\n  height: 3.5rem;\n  width: 3.5rem;\n  flex-shrink: 0;\n}\n\n.title {\n  max-width: 39rem;\n  padding-left: 2rem;\n  white-space: nowrap;\n  overflow: hidden;\n}\n\n.rightSide {\n  display: flex;\n  flex-shrink: 0;\n  margin-left: auto;\n  align-items: center;\n}\n\n.rightSideInfo {\n  display: flex;\n  flex-direction: column;\n  text-align: right;\n  margin-right: 2rem;\n}\n\n.rightSideInfo>* {\n  padding: 0.5rem 0;\n}\n\n.date {\n  color: #839AA7;\n}\n\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"listItem": "_3Zl9TwJcqzP5MA5egJwpNS",
	"statusIcon": "j5ToTKZXbfD4kYQebEO3L",
	"title": "_84ZTsYzz31QExoryAVIwU",
	"rightSide": "-m8NahVRZStHYqHt2MLwi",
	"rightSideInfo": "_1kqW0tR6sHd5xNdJ2-fTYo",
	"date": "d6cxyAP3LYo6Q6t0NiU0Q"
};