import { SHOW_MESSAGE_BOX, CLOSE_MESSAGE_BOX, SET_MESSAGE_BOX_PROPS } from '../constants/MessageBox'

const initialState = {
  title: '',
  text: '',
  showMessageBox: false,
  onClose: null,
  element: null
}

export default function (state = initialState, action) {
  switch (action.type) {
    case SHOW_MESSAGE_BOX:
      return {
        ...state,
        showMessageBox: true
      }
    case CLOSE_MESSAGE_BOX:
      return {
        ...initialState
      }
    case SET_MESSAGE_BOX_PROPS: {
      return {
        ...state,
        ...action.payload
      }
    }
    default:
      return state
  }
}
