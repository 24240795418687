import { bind } from 'redux-effects'
import { fetch } from 'redux-effects-fetch'

import { RECOVERY } from '../constants/Api'
import { showMessageBoxWithParamsAction } from 'MessageBox/actions/messageBox'
import { showNavbarSpinnerAction, hideNavbarSpinnerAction } from 'shared/actions/navbarSpinner'

export default function resetPassword (email) {
  console.log(`try to reset password with ${email}`)
  return [
    showNavbarSpinnerAction(),
    bind(sendRequest(email), processResponse.bind(this, email), processError)
  ]
}

function sendRequest (data) {
  return fetch(RECOVERY, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      email: data,
      redirect_url: window.location.origin + '/password_reset'
    })
  })
}

function processResponse (email, response) {
  return (dispatch, getState) => {
    const t = getState().i18n.get('app', 'views', 'ForgotPasswordView', 'messages')
    if (response.value.success) {
      return dispatch([
        hideNavbarSpinnerAction(),
        showMessageBoxWithParamsAction('', String(t('resetPasswordSuccess', 'description').s).replace('%email%', email))
      ])
    } else {
      // This is unusual situation, because success:false should coming together with
      // bad http code - so it will be processed as error in another function
      return dispatch([
        hideNavbarSpinnerAction(),
        showMessageBoxWithParamsAction('', response.value.error)
      ])
    }
  }
}

function processError (response) {
  if (response.value && !response.value.success) {
    return (dispatch) => {
      return dispatch([
        hideNavbarSpinnerAction(),
        showMessageBoxWithParamsAction('', response.value.error)
      ])
    }
  } else {
    console.error('resetPassword processError', response)
  }
}
