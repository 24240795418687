import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'

// Components
import Navigation from 'Navigation/Navigation'
import ListView from 'shared/components/ListView/ListView'
import ListItem from 'Events/Events/components/ListItem/ListItem'

// Actions
import { extendEventsAction } from 'Events/shared/actions/events'

class EventsListView extends Component {
  static propTypes = {
    loadListData: PropTypes.func,
    hasNextPage: PropTypes.bool,
    lastOffset: PropTypes.number,
    listData: PropTypes.arrayOf(PropTypes.object),
    filter: PropTypes.object,
    path: PropTypes.string,
    backLink: PropTypes.string,
    t: PropTypes.func
  };

  constructor (props) {
    super(props)
    this.extendList = this.extendList.bind(this)
  }

  componentDidMount () {
    this.props.loadListData([], this.props.filter, 0, true)
  }

  extendList () {
    const {hasNextPage, lastOffset, listData, loadListData, filter} = this.props
    if (hasNextPage) {
      loadListData(listData, filter, lastOffset + 20, false)
    }
  }

  eventsListItemWrapper (path) {
    return (props) => <ListItem {...props} query={{ backLink: path }} />
  }

  render () {
    const {listData, t, path, backLink} = this.props
    return (
      <Navigation title={t('navbar', 'title').s} backLink={backLink} forceBackLink className='events-view' infinityScrollHandler={this.extendList}>
        <ListView data={listData} template={this.eventsListItemWrapper(path)} />
      </Navigation>
    )
  }
}

function mapStateToProps (state) {
  return {
    listData: state.events.data,
    lastOffset: state.events.lastOffset,
    hasNextPage: state.events.hasNextPage
  }
}

function mapDispatchToProps (dispatch) {
  return {
    loadListData: (currentList, filtersForEvents, newOffset, isInitialRequest) => { dispatch(extendEventsAction(currentList, filtersForEvents, newOffset, false, isInitialRequest)) }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EventsListView)
