import Smartphones from './containers/Smartphones'
import Tablets from './containers/Tablets'

import './FactoringMissingInfoView.scss?global'

import { getCurrentDeviceView } from 'shared/helpers/utils'

const FactoringMissingInfoView = getCurrentDeviceView(Smartphones, Tablets)

export default FactoringMissingInfoView
