import { createReducer } from 'redux-act'
import {
  selectCategory, addSentRequest,
  listOfRequests, changeWelcomeModalState
} from '../actions'

import { blockWelcomeModal } from 'MarketPlace/shared/actions'

const initialState = {
  category: null,
  welcomeModalIsBlocked: false,
  welcomeModalWasViewed: false,
  sentRequestsList: []
}

export default createReducer({
  [selectCategory]: (state, payload) => ({
    ...state,
    category: payload
  }),
  [addSentRequest]: (state, payload) => ({
    ...state,
    sentRequestsList: [
      ...state.sentRequestsList,
      { category: payload.category }
    ]
  }),
  [listOfRequests]: (state, payload) => ({
    ...state,
    sentRequestsList: payload
  }),
  [changeWelcomeModalState]: (state, payload) => ({
    ...state,
    ...payload
  }),
  [blockWelcomeModal]: (state, payload) => ({
    ...state,
    welcomeModalIsBlocked: payload
  })
}, initialState)
