import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import SalariesList from 'Salaries/views/SalariesListView/containers/Smartphones'
import { SalaryEditView } from './Smartphones'
import IpadNavigation from 'Navigation/IpadNavigation'

class SalaryEdit extends Component {
  static propTypes = {
    title: PropTypes.string
  }
  render () {
    return (
      <IpadNavigation title={this.props.title} {...this.props}>
        <SalariesList {...this.props} />
        <SalaryEditView {...this.props} />
      </IpadNavigation>
    )
  }
}

const mapStateToProps = (state) => ({
  title: state.salaries.salary.title || state.i18n.get('app', 'views', 'Salaries', 'SalaryEditView', 'navbar', 'title').s
})

export default connect(mapStateToProps)(SalaryEdit)
