import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Helmet } from 'react-helmet'

import OverviewViewContainer from 'Overview/Overview/containers/Common'

// Components
import Navigation from 'Navigation/Navigation'
import OverviewCharts from 'Overview/Overview/components/Charts/Charts'
import OverviewList from 'Overview/Overview/components/List/List'

// Helpers
import getGreyLabelOptions from 'GreyLabel/helpers/greyLabel'

class Overview extends Component {
  static propTypes = {
    collectorStatus: PropTypes.string,
    t: PropTypes.func,
    location: PropTypes.object,
    loan: PropTypes.object
  }
  render () {
    const { t } = this.props
    const { mainTextColor, whiteLabel } = getGreyLabelOptions()
    return (
      <Navigation title={t('navbar', 'title').s} className={`overview-view ${mainTextColor ? whiteLabel : ''}`} home>
        <Helmet>
          <meta
            name='viewport'
            content='width=640, maximum-scale=1.0, user-scalable=no'
          />
        </Helmet>
        <OverviewList {...this.props} >
          <OverviewCharts {...this.props} openSettings={this.openSettings} />
        </OverviewList>
      </Navigation>
    )
  }
}

export default OverviewViewContainer(Overview)
