import './InvoiceDiscountView.scss?global'

import Smartphones from 'Invoices/CustomerInvoices/views/InvoiceDiscountView/containers/Smartphones'
import Tablets from 'Invoices/CustomerInvoices/views/InvoiceDiscountView/containers/Tablets'

import {getCurrentDeviceView} from 'shared/helpers/utils'

const InvoiceDiscountView = getCurrentDeviceView(Smartphones, Tablets)

export default InvoiceDiscountView
