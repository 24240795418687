import React from 'react'
import { Route } from 'react-router'

import {requireAuthentication} from 'User/Auth/higherOrderComponents/AuthComponent'

import MoreView from 'More/views/MoreView/MoreView'
import MoreSupplierInvoicesView from 'More/views/MoreSupplierInvoicesView/MoreSupplierInvoicesView'
import MoreCustomerInvoicesView from '../views/MoreCustomerInvoicesView/MoreCustomerInvoicesView'
import MoreSalaryView from 'More/views/MoreSalaryView'

const moreRoutes = (
  <Route>
    <Route path='/more' component={requireAuthentication(MoreView)} />
    <Route path='/more/customer-invoices' component={requireAuthentication(MoreCustomerInvoicesView)} />
    <Route path='/more/supplier-invoices' component={requireAuthentication(MoreSupplierInvoicesView)} />
    <Route path='/more/salary' component={requireAuthentication(MoreSalaryView)} />
  </Route>
)

export default moreRoutes
