import { ADD_PHONEBOOK_CONTACTS, CLEAR_PHONEBOOK_CONTACTS, MARK_CONTACT_AS_CHECKED, MARK_CONTACT_AS_UNCHECKED } from 'appBridge/PhoneBook/constants/ActionTypes'

const initialState = {
  list: []
}

export default function (state = initialState, action) {
  switch (action.type) {
    case ADD_PHONEBOOK_CONTACTS:
      return {
        ...state,
        list: action.payload
      }
    case CLEAR_PHONEBOOK_CONTACTS:
      return {
        ...state,
        list: []
      }
    case MARK_CONTACT_AS_CHECKED:
      return {
        ...state,
        list: state.list.map((contact) => {
          if (contact.index === action.payload) {
            return { ...contact, isChecked: true }
          } else {
            return contact
          }
        })
      }
    case MARK_CONTACT_AS_UNCHECKED:
      return {
        ...state,
        list: state.list.map((contact) => {
          if (contact.index === action.payload) {
            return { ...contact, isChecked: false }
          } else {
            return contact
          }
        })
      }
    default:
      return state
  }
}
