import PropTypes from 'prop-types'
import React, { Component } from 'react'

// Components
import Input from 'shared/components/Input/Input'
import PlusButton from 'shared/components/PlusButton/PlusButton'
import TimeInput from 'shared/components/TimeInput/TimeInput'

// Constants
import { PIECE, HOUR, KM } from 'Invoices/shared/constants/UnitTypes'

export default class InvoiceQuantity extends Component {
  static propTypes = {
    data: PropTypes.object,
    index: PropTypes.number,
    updateInvoiceRow: PropTypes.func
  }

  constructor (props) {
    super(props)
    this.state = {value: '', blur: true}
    this.onPieceChange = this.onPieceChange.bind(this)
    this.onPieceBlur = this.onPieceBlur.bind(this)
    this.onIncrease = this.onIncrease.bind(this)
    this.onReduce = this.onReduce.bind(this)
    this.onHourChange = this.onHourChange.bind(this)
    this.onClick = this.onClick.bind(this)
  }

  componentDidMount () {
    this.setState({value: Number(this.props.data.amountOfUnits)})
  }

  componentWillReceiveProps (props) {
    this.setState({value: Number(props.data.amountOfUnits)})
  }

  onPieceChange (e) {
    if (e.target.value) {
      const value = Number(e.target.value)
      this.setState({ value })
    } else {
      this.setState({ value: '' })
    }
  }

  onPieceBlur () {
    if (!this.state.value) {
      this.setState({value: 1}, this.updateInvoiceRow)
    } else {
      this.updateInvoiceRow()
    }
  }

  onHourChange (value) {
    this.updateInvoiceRow(value)
  }

  onIncrease () {
    const newValue = this.state.value + 1
    this.updateInvoiceRow(newValue || 1)
  }

  onReduce () {
    const newValue = this.state.value - 1
    this.updateInvoiceRow(newValue || 1)
  }

  updateInvoiceRow (amountOfUnits = this.state.value) {
    const {data, updateInvoiceRow, index} = this.props
    this.setState({value: amountOfUnits}, () => {
      updateInvoiceRow({...data, amountOfUnits: this.state.value}, index)
    })
  }

  onClick (e) {
    const { data: {unit} } = this.props

    if (unit === PIECE || unit === HOUR) {
      e.stopPropagation()
      e.preventDefault()
    }
  }

  getMileFromKm (km) {
    return km / 10
  }

  renderInputBlock () {
    const {data: {unit}} = this.props
    if (unit === PIECE) {
      return (
        <Input type='float' onChange={this.onPieceChange} onBlur={this.onPieceBlur} value={this.state.value} />
      )
    } else if (unit === HOUR) {
      return (
        <TimeInput value={this.state.value} onChange={this.onHourChange} />
      )
    } else if (unit === KM) {
      return (
        <Input className='create-invoice__row__quantity__mile' value={`${this.getMileFromKm(this.state.value)} MIL`} readOnly />
      )
    }
  }

  render () {
    const isEditable = this.props.data.unit !== KM
    return (
      <div className='create-invoice__row__quantity' onClick={this.onClick}>
        { isEditable && <PlusButton type='minus' onClick={this.onReduce} /> }
        {this.renderInputBlock()}
        { isEditable && <PlusButton type='plus' onClick={this.onIncrease} /> }
      </div>
    )
  }
}
