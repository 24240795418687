import { fetch as call } from 'redux-effects-fetch'
import URI from 'urijs'
import { omitBy, isNil } from 'lodash'

export function fetch (api, search, body = {}, options = {}) {
  const filteredSearchParams = omitBy(search, isNil)

  return (dispatch) => {
    return dispatch(call(api({...filteredSearchParams}).toString(), {
      ...body,
      body: options.rawBody ? body.body : convertRequestBody(body.body)
    }))
  }
}

// TODO: later we should turn fetch2 to fetch and refactor the project
export function fetch2 (api, search = {}, body = {}) {
  const filteredSearchParams = omitBy(search, isNil)

  return (dispatch) => {
    return dispatch(call(URI(api).addSearch({...filteredSearchParams}).toString(), {
      ...body,
      body: convertRequestBody(body.body)
    }))
  }
}

function convertRequestBody (body) {
  let result
  try {
    const bodyObject = JSON.parse(body)
    result = JSON.stringify(bodyObject, replacer)
  } catch (e) {
    // if body is formUrlEncoded or FormData for example
    result = body
  }

  return result
}

function replacer (key, value) {
  if (typeof value === 'number') {
    return String(value)
  } else {
    return value
  }
}
