import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Icon from 'shared/components/Icon'
import Button from 'shared/components/Button/Button'
import Modal from 'shared/components/NewModal/NewModal'

class WelcomeModal extends Component {
  close = () => {
    this.refs.welcomeModal.close()
    this.props.markAsViewed()
  }

  render () {
    const { t } = this.props
    return (
      <Modal showOpen ref='welcomeModal' onClose={this.close}>
        <div className='market-place__info'>
          <Icon type='meditator-balls' />
          <div className='market-place__welcome-modal-content'>
            <h1>{t('welcome', 'welcome').s}</h1>
            <h3 className='welcome-modal-content__text_align-left'>{t('welcome', 'mainText').s}</h3>
            <h3 className='welcome-modal-content__text_bold'>{t('welcome', 'advices').s}</h3>
            <h3>{t('welcome', 'hello').s}</h3>
            <Icon type='logo-mshoebox' className='welcome-modal-content__mrshoebox-logo' />
          </div>
          <Button onClick={this.close}>
            {t('welcome', 'continue').s}</Button>
        </div>
      </Modal>
    )
  }
}

WelcomeModal.propTypes = {
  t: PropTypes.func,
  markAsViewed: PropTypes.func
}

export default WelcomeModal
