import { ARTICLE_LIST_UPDATE, ARTICLE_UPDATE_PROPS, ARTICLE_CLEAN } from 'Articles/constants/ActionTypes'

const initialState = {
  listData: [],
  hasNextPage: true,
  lastPageLoaded: 0,
  article: { }
}

export default function (state = initialState, action) {
  switch (action.type) {
    case ARTICLE_LIST_UPDATE:
      const { listData, hasNextPage, lastPageLoaded } = action.payload
      return {
        ...state,
        listData,
        hasNextPage,
        lastPageLoaded
      }
    case ARTICLE_UPDATE_PROPS:
      return {
        ...state,
        article: {
          ...state.article,
          ...action.payload
        }
      }
    case ARTICLE_CLEAN:
      return {
        ...state,
        article: initialState.article
      }
    default:
      return state
  }
}
