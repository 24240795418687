import './styles/index.scss?global'

import Smartphones from 'Invoices/CustomerInvoices/views/InvoiceCreateView/containers/Smartphones'
import Tablets from 'Invoices/CustomerInvoices/views/InvoiceCreateView/containers/Tablets'

import {getCurrentDeviceView} from 'shared/helpers/utils'

const InvoiceCreateView = getCurrentDeviceView(Smartphones, Tablets)

export default InvoiceCreateView
