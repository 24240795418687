import './WizardProfile.scss?global'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { reduxForm, Field, formValueSelector, submit } from 'redux-form'
import { connect } from 'react-redux'
import { browserHistory } from 'react-router'
import cs from 'classnames'
import { haveRoaringSuggestions } from 'mrshoebox-ui-components/src/helpers/countrySpecific'

import Navigation from 'Navigation/Navigation'
import Input from 'shared/components/Input/Input'
import Button from 'shared/components/Button/Button'
import DropDown from 'shared/components/Dropdown/Dropdown'

import { setInfoAction, wizardProfileShowMessageBox } from 'Wizard/actions/wizard'
import {
  updateProfile,
  fillUpCompanyInfoFieldsAction,
  updateWizardProfileStorage,
  fillUpFields
} from 'Wizard/actions/wizardProfile'

import { required, isCorrectOrgNumber } from 'shared/helpers/formValidations'
import getGreyLabelOptions from 'GreyLabel/helpers/greyLabel'
import RoaringCompanies from 'Wizard/components/RoaringCompanies/RoaringCompanies'

const InputWrapper = ({ input, meta, ...props }) => {
  return <Input {...input} {...meta} {...props} />
}
InputWrapper.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object
}

export class WizardProfile extends Component {
  static propTypes = {
    t: PropTypes.func,
    tButton: PropTypes.func,
    companyTypesT: PropTypes.func,
    fillCompanyInfo: PropTypes.func,
    handleSubmit: PropTypes.func,
    selectedValues: PropTypes.object,
    userName: PropTypes.string,
    clearAddresses: PropTypes.func,
    isLoading: PropTypes.bool,
    userPersonalNumber: PropTypes.string,
    submit: PropTypes.func.isRequired,
    fillUpFields: PropTypes.func
  }

  companyTypeDropdown = (fieldProps) => {
    const { companyTypesT, t } = this.props

    return <DropDown
      {...fieldProps.input}
      {...fieldProps.meta}
      className='wizard-profile__field--fix-width'
      big
      emptyOption={t('fields', 'companyType').s}
      hint={t('fields', 'companyType').s}
      options={[
        { label: companyTypesT('limitedCompany').s, value: 'limited' },
        { label: companyTypesT('tradingCompany').s, value: 'general' },
        { label: companyTypesT('soleProprietorship').s, value: 'sole' },
        { label: companyTypesT('other corporate').s, value: 'other' }
      ]}
    />
  }

  fillCompanyInfo = (_, orgNumber) => {
    const { selectedValues: { companyType }, clearAddresses, fillCompanyInfo, isLoading } = this.props

    if (isLoading) {
      return
    }

    clearAddresses()
    if (companyType === 'limited') {
      fillCompanyInfo(orgNumber)
    }
  }

  onOrgNumberChange = (_, newValue = '') => {
    if (newValue.length < 10) {
      return
    }
    // ! is inserted because it's redux-form validator
    // validator should return true if error is presented
    if (!isCorrectOrgNumber(newValue)) {
      this.fillCompanyInfo(_, newValue)
    }
  }

  onRoaringCompanyChange = company => {
    this.props.fillUpFields({ value: company })
    this.props.submit('wizard-profile')
  }

  render () {
    const {
      t,
      tButton,
      handleSubmit,
      userName,
      userPersonalNumber,
      selectedValues: { companyType: selectedCompanyType, companyName, allowManualInput }
    } = this.props

    const isEditableCompanyName = selectedCompanyType !== 'limited' || allowManualInput

    const continueButtonView = this.props.isLoading ? 'gray' : 'primary'
    const { whiteLabel } = getGreyLabelOptions()
    return (
      <Navigation navigationWithMaxWidth className={cs('wizard', 'wizard-profile', whiteLabel)} title={''} hideFooter backLink='/' forceBackLink>
        <div className='wizard-profile__greeting'>
          <div className='wizard-profile__greeting-title'>{t('greetingTitle').replace('%name%', userName)}</div>
        </div>
        {userPersonalNumber && haveRoaringSuggestions && (
          <div className='wizard-profile__roaring-companies'>
            <RoaringCompanies
              personalNumber={userPersonalNumber}
              onChange={this.onRoaringCompanyChange}
            />
          </div>
        )}
        <div className='wizard-profile__form'>
          <Field
            validate={[required]}
            name='companyType'
            component={this.companyTypeDropdown}
          />
          {
            selectedCompanyType
              ? <Field
                validate={[required, isCorrectOrgNumber]}
                name='organizationNumber'
                component={InputWrapper}
                onBlur={this.fillCompanyInfo}
                onChange={this.onOrgNumberChange}
                props={{ placeholder: t('fields', 'organizationNumber').s }}
              />
              : null
          }
          {
            (selectedCompanyType && selectedCompanyType !== 'limited') || companyName || allowManualInput
              ? <Field
                validate={[required]}
                name='companyName'
                component={InputWrapper}
                props={{ placeholder: t('fields', 'companyName').s, readOnly: !isEditableCompanyName }}
              />
              : null
          }
        </div>

        <div className='wizard-profile__buttons'>
          <Button view={continueButtonView} disabled={this.props.isLoading} className='wizard-profile__save' onClick={handleSubmit}>
            {tButton('continue').s}
          </Button>
          <Button view='transparent-black' className='wizard-profile__save' onClick={() => { browserHistory.push('/wizard/next-actions') }}>
            {tButton('skipThisNow').s}
          </Button>
        </div>
      </Navigation>
    )
  }
}

const selector = formValueSelector('wizard-profile')
function mapStateToProps (state) {
  return {
    isLoading: state.navbarSpinner.value,
    userName: state.userSettings.user.name,
    userPersonalNumber: state.userSettings.user.personalNumber,
    initialValues: state.userSettings.company,
    selectedValues: selector(state, 'companyType', 'companyName', 'allowManualInput', 'roaringCompany'),
    t: state.i18n.get('app', 'views', 'Wizard', 'Profile'),
    tButton: state.i18n.get('app', 'shared', 'buttons'),
    companyTypesT: state.i18n.get('app', 'views', 'Wizard', 'BusinessType', 'menu')
  }
}

function mapDispatchToProps (dispatch) {
  return {
    submit: (data) => { dispatch(submit(data)) },
    onSubmit: (data) => { dispatch(updateProfile(data)) },
    onMessageBoxShow: () => { dispatch(wizardProfileShowMessageBox()) },
    setInfo: (data) => { dispatch(setInfoAction(data)) },
    fillCompanyInfo: (data) => { dispatch(fillUpCompanyInfoFieldsAction(data)) },
    clearAddresses: () => { dispatch(updateWizardProfileStorage({ addresses: [] })) },
    fillUpFields: (data) => { dispatch(fillUpFields(data)) }
  }
}

const reduxHOC = connect(mapStateToProps, mapDispatchToProps)
const reduxFormHOC = reduxForm({
  form: 'wizard-profile',
  enableReinitialize: true
})

export default reduxHOC(reduxFormHOC(WizardProfile))
