import PropTypes from 'prop-types'
import React, { Component } from 'react'

import Overlay from 'shared/components/Overlay/Overlay'
import Spinner from 'shared/components/Spinner/Spinner'
import Button from 'shared/components/Button/Button'

import './LoginPreloader.scss?global'

export default class LoginPreloader extends Component {
  static propTypes = {
    title: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
    onCancelClick: PropTypes.func,
    children: PropTypes.object,
    t: PropTypes.func
  };

  constructor (props) {
    super(props)
    this.state = {visible: false}
  }

  setVisible (value) {
    this.setState({visible: value})
  }

  render () {
    const { title, onCancelClick, t, children } = this.props
    const { visible } = this.state

    if (visible) {
      return (
        <Overlay center>
          <div className='login-preloader'>
            <h2>{title}</h2>
            {children ? <div>{children}</div> : null}
            <Spinner />
            { onCancelClick ? <div className='login-preloader__cancel-button'>
              <Button view='white' onClick={onCancelClick}>
                { t('cancel').s }
              </Button>
            </div> : null }
          </div>
        </Overlay>
      )
    } else return null
  }
}
