import PropTypes from 'prop-types'
import React from 'react'
import {connect} from 'react-redux'

// Actions
import { pushRevenueAction } from '../../actions/revenue'

// Views
import ReceiptBaseView from 'Events/shared/views/ReceiptBaseView/ReceiptBaseView'

function RevenueView (props) {
  const { location: { query: { type = 'receipt', paymentEditMode } } } = props

  return (
    <ReceiptBaseView
      {...props}
      pushAction={pushRevenueAction}
      type='revenue'
      invoiceMode={type === 'invoice'}
      paymentEditMode={paymentEditMode === 'true'}
    />
  )
}

RevenueView.propTypes = {
  location: PropTypes.object
}

function mapStateToProps (state, ownProps) {
  const { location: { query: { type = 'receipt' } } } = ownProps
  const navbarTitle = type === 'receipt'
    ? state.i18n.get('app', 'views', 'Income', 'Revenue', 'RevenueView', 'navbar', 'titleReceipt').s
    : state.i18n.get('app', 'views', 'Income', 'Revenue', 'RevenueView', 'navbar', 'titleInvoice').s

  return {
    categories: state.userSettings.revenueCategories,
    paymentMethods: state.userSettings.expensePaymentMethods,
    defaultNavbarTitle: navbarTitle
  }
}

export default connect(mapStateToProps)(RevenueView)
