import '../../styles/index.scss?global'

import React, { Component } from 'react'
import { browserHistory } from 'react-router'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'

// Components
import { Container, UpperContent, BottomContent } from '../../../Auth/components/LoginContainer'
import BackLink from 'User/shared/components/BackLink/BackLink'
import Button from 'shared/components/Button/Button'
import FullscreenSpinner from 'User/shared/containers/FullscreenSpinner/FullscreenSpinner'
import Terms from '../../components/Terms'
import Modal from 'shared/components/NewModal/NewModal'
import GdprCheckbox from 'User/shared/components/GdprCheckbox/GdprCheckbox'

// Actions
import signUpAction from 'User/Register/actions/signup'

// Helpers
import { getBackgroundPath } from 'User/shared/helpers'
const backgroundPath = getBackgroundPath()

class SignUpBaseView extends Component {
  static propTypes = {
    t: PropTypes.func,
    handleSubmit: PropTypes.func,
    children: PropTypes.node,
    submitButtonProps: PropTypes.object
  }

  static defaultProps = {
    submitButtonProps: {
      view: 'transparent-white',
      type: 'submit'
    }
  }

  render () {
    const { t, handleSubmit } = this.props

    return (
      <Container background={backgroundPath}>
        <Modal ref={(c) => { this.termsModal = c }}>
          <Terms />
        </Modal>
        <UpperContent>
          &nbsp;
        </UpperContent>
        <BottomContent>
          <form onSubmit={handleSubmit} autoComplete='off'>
            <div className='registration__content__form__fields'>
              { this.props.children }
              <GdprCheckbox view='white' />
            </div>
            <Button {...this.props.submitButtonProps}>{t('buttons', 'create').s}</Button>
          </form>
          <div className='registration__terms-footer'>
            { t('termsText').s }
            <u onClick={() => { this.termsModal.open() }}>{t('termsLink').s}</u>
          </div>
        </BottomContent>
        <FullscreenSpinner />
        <BackLink transparent onClick={() => { browserHistory.go(-1) }} text={t('back').s} />
      </Container>
    )
  }
}

function mapStateToProps (state) {
  return {
    t: state.i18n.get('app', 'views', 'SignUpView')
  }
}

function mapDispatchToProps (dispatch, ownProps) {
  return {
    onSubmit: (...args) => { ownProps.onSubmit ? ownProps.onSubmit(...args) : dispatch(signUpAction(...args)) }
  }
}

const reduxHOC = connect(mapStateToProps, mapDispatchToProps)
const reduxFormHOC = reduxForm({ form: 'signup' })

export default reduxHOC(reduxFormHOC(SignUpBaseView))
