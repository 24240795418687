import React from 'react'
import { Route } from 'react-router'

import { requireAuthentication } from 'User/Auth/higherOrderComponents/AuthComponent'
import { redirectLoggedInUser } from 'User/Auth/higherOrderComponents/RedirectComponent'

import SelectCompanyView from 'User/Auth/views/SelectCompanyView/SelectCompanyView'

import SelectSignUpMethodView from 'User/Register/views/SelectSignUpMethodView'
import SignUpViaBankIDView from 'User/Register/views/SignUpWithBankIDView'
import SignUpViaPasswordView from 'User/Register/views/SignUpWithPasswordView'

import SelectLoginMethodView from 'User/Auth/views/SelectLoginMethodView'
import LoginViaPasswordView from 'User/Auth/views/LoginViaPasswordView'
import LoginViaBankIDView from 'User/Auth/views/LoginViaBankIDView'

import BankIDWaitView from 'User/shared/views/BankIDWaitingView'

import ForgotPasswordView from 'User/ResetPassword/views/ForgotPasswordView/ForgotPasswordView'
import ResetPasswordView from 'User/ResetPassword/views/ResetPasswordView/ResetPasswordView'

import SettingsView from 'User/Settings/views/SettingsView/SettingsView'
import AccountSettingsView from 'User/Settings/views/AccountSettingsView/AccountSettingsView'
import CompanySettingsView from 'User/Settings/views/CompanySettingsView/CompanySettingsView'
import InvoiceSettingsView from 'User/Settings/views/InvoiceSettingsView/InvoiceSettingsView'

const userRoutes = (
  <Route>
    <Route path='/sign_up' component={redirectLoggedInUser(SelectSignUpMethodView)} />
    <Route path='/sign_up_bankid' component={redirectLoggedInUser(SignUpViaBankIDView)} />
    <Route path='/sign_up_password' component={redirectLoggedInUser(SignUpViaPasswordView)} />

    <Route path='/login' component={redirectLoggedInUser(SelectLoginMethodView)} />
    <Route path='/login_password' component={redirectLoggedInUser(LoginViaPasswordView)} />
    <Route path='/login_bankid' component={redirectLoggedInUser(LoginViaBankIDView)} />

    <Route path='/bankid_wait' component={redirectLoggedInUser(BankIDWaitView)} />

    <Route path='/select_company' component={SelectCompanyView} />
    <Route path='/forgot_password' component={redirectLoggedInUser(ForgotPasswordView)} />
    <Route path='/password_reset' component={redirectLoggedInUser(ResetPasswordView)} />

    <Route path='/settings' component={requireAuthentication(SettingsView)} />
    <Route path='/settings/account' component={requireAuthentication(AccountSettingsView)} />
    <Route path='/settings/company' component={requireAuthentication(CompanySettingsView)} />
    <Route path='/settings/invoice' component={requireAuthentication(InvoiceSettingsView)} />
  </Route>
)

export default userRoutes
