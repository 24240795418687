import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { reduxForm, Field, getFormValues, getFormMeta } from 'redux-form'

// Components
import Tabs from 'shared/components/FormTabs'
import SearchField from 'shared/components/FormSearchField'
import Grid from 'shared/components/Grid/Grid'
import Dropdown from 'shared/components/FormDropdown'

export const STATUS_ALL = 'all'
export const STATUS_ACTIVE = 'active'
export const STATUS_INACTIVE = 'inactive'

export const initialFilterValues = {
  status: STATUS_ALL,
  orderBy: 'start_date',
  orderType: 'asc'
}

export class ProjectsViewFilters extends Component {
  static propTypes = {
    asyncValidate: PropTypes.func,
    canBeHidden: PropTypes.bool,
    formValues: PropTypes.object,
    meta: PropTypes.object,
    t: PropTypes.func
  };

  componentDidMount () {
    this.props.asyncValidate(this.props.formValues)
  }

  render () {
    const { formValues: { status }, t, canBeHidden, meta } = this.props

    if (meta.search && !meta.search.visited && canBeHidden) {
      return (
        <div>
          <Field name='status' component={Tabs} initValue={status} config={[
            {label: t('tabs', 'all').s, action: function () {}, value: STATUS_ALL},
            {label: t('tabs', 'active').s, action: function () {}, value: STATUS_ACTIVE},
            {label: t('tabs', 'archived').s, action: function () {}, value: STATUS_INACTIVE}
          ]} />
        </div>
      )
    }

    return (
      <div>
        <Field name='status' component={Tabs} initValue={status} config={[
          {label: t('tabs', 'all').s, action: function () {}, value: STATUS_ALL},
          {label: t('tabs', 'active').s, action: function () {}, value: STATUS_ACTIVE},
          {label: t('tabs', 'archived').s, action: function () {}, value: STATUS_INACTIVE}
        ]} />
        <Field name='search' component={SearchField} placeholder={t('search', 'placeholder').s} />
        <Grid>
          <div>
            <Field name='orderBy' component={Dropdown} options={[
              {label: t('orderBy', 'startDate').s, value: 'start_date'},
              {label: t('orderBy', 'name').s, value: 'name'}
            ]} />
          </div>
          <div>
            <Field name='orderType' component={Dropdown} options={[
              {label: t('orderType', 'asc').s, value: 'asc'},
              {label: t('orderType', 'desc').s, value: 'desc'}
            ]} />
          </div>
        </Grid>
      </div>
    )
  }
}

const formName = 'projectsViewFilters'
const formValuesSelector = getFormValues(formName)
const metaSelector = getFormMeta(formName)

function mapStateToProps (state) {
  return {
    t: state.i18n.get('app', 'views', 'ProjectsView', 'filters'),
    formValues: formValuesSelector(state) || {},
    meta: metaSelector(state)
  }
}

export default connect(mapStateToProps)(reduxForm({
  form: formName,
  destroyOnUnmount: false,
  initialValues: initialFilterValues
})(ProjectsViewFilters))
