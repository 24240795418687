import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { routerActions } from 'react-router-redux'
import { reduxForm, FieldArray, Field, arrayPush } from 'redux-form'

import Navigation from 'Navigation/Navigation'
import Button from 'shared/components/Button/Button'
import PlusButton from 'shared/components/PlusButton/PlusButton'
import FormInput from 'shared/components/FormInput'

import { getBenefits, deletedBenefitIdToStore } from 'Employees/actions'

export class Benefits extends Component {
  static propTypes = {
    goToEmployee: PropTypes.func.isRequired,
    params: PropTypes.object.isRequired,
    loadBenefits: PropTypes.func.isRequired,
    deleteBenefit: PropTypes.func.isRequired
  }

  componentDidMount () {
    const { params: { employeeId }, loadBenefits } = this.props
    if (employeeId !== 'new') {
      loadBenefits(employeeId)
    }
  }

  render () {
    const { goToEmployee, params: { employeeId }, t, deleteBenefit } = this.props
    return (
      <div className='benefits-view'>
        <div className='benefits'>
          <FieldArray
            name='benefits'
            component={renderBenefits}
            t={t}
            deleteBenefit={deleteBenefit}
          />
        </div>
        <Button
          view='transparent-black'
          onClick={() => goToEmployee(employeeId)}
        >
          {t('buttons', 'continue').s}
        </Button>
      </div>
    )
  }
}

const renderBenefits = ({ fields, t, deleteBenefit, ...props }) =>
  fields.map((benefit, index) =>
    <div className='benefit-row'>
      <Field
        name={`${benefit}.title`}
        component={FormInput}
        placeholder={t('fields', 'title').s}
        {...props}
      />
      <Field
        name={`${benefit}.value`}
        component={FormInput}
        placeholder={t('fields', 'value').s}
        type='number'
        inputMode='decimal'
        {...props}
      />
      <PlusButton
        view='transparent-black'
        type='minus'
        onClick={() => { fields.remove(index); fields.get(index).id && deleteBenefit(fields.get(index).id) }}
      />
    </div>
  )

class BenefitsWithNavigation extends Component {
  static propTypes = {
    addNewBenefit: PropTypes.func.isRequired
  }
  render () {
    const { t, addNewBenefit } = this.props
    const actions = [
      { icon: 'icon-add', callback: addNewBenefit }
    ]
    return (
      <Navigation title={t('navbar', 'title').s} hideFooter actions={actions}>
        <BenefitsView {...this.props} />
      </Navigation>
    )
  }
}

const mapStateToProps = (state) => ({
  initialValues: state.employees.employee,
  t: state.i18n.get('app', 'views', 'Employees', 'BenefitsView')
})

const mapDispatchToProps = (dispatch) => ({
  loadBenefits: (employeeId) => dispatch(getBenefits(employeeId)),
  goToEmployee: (employeeId) => dispatch(routerActions.push(`/employees/${employeeId}?cameFrom=benefits`)),
  addNewBenefit: () => dispatch(arrayPush('employee', 'benefits', { title: '', value: '' })),
  deleteBenefit: (id) => dispatch(deletedBenefitIdToStore(id))
})

const formHOC = reduxForm({
  form: 'employee',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  destroyOnUnmount: false
})

export const BenefitsView = connect(mapStateToProps, mapDispatchToProps)(formHOC(Benefits))
export default connect(mapStateToProps, mapDispatchToProps)(formHOC(BenefitsWithNavigation))
