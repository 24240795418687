import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styles from './index.scss'

import { DEFISO_PORTAL } from 'MarketPlace/shared/constants/frameUrls'

import Frame from 'MarketPlace/shared/components/Frame'
import Navigation from 'Navigation/NavigationForIframe'

export default class AdvertisingFrameView extends Component {
  static propTypes = {
    t: PropTypes.func,
    blockWelcomeModal: PropTypes.func.isRequired,
    selectedCategory: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.func
    ])
  }

  componentDidMount () {
    this.props.blockWelcomeModal(true)
  }

  render () {
    const { t, selectedCategory } = this.props

    return (
      <Navigation title={t('categories', selectedCategory).s} backLink='/marketplace'>
        <Frame
          src={DEFISO_PORTAL}
          className={styles.frame}
        />
      </Navigation>
    )
  }
}
