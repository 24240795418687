import './Spinner.scss?global'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

export default class Spinner extends Component {
  static propTypes = {
    dark: PropTypes.bool
  };

  render () {
    const { dark } = this.props
    return (
      <div className={`spinner ${dark ? 'spinner-color-navy' : ''}`}>
        <div /><div /><div /><div /><div /><div /><div /><div /><div /><div /><div /><div />
      </div>
    )
  }
}
