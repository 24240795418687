import { bind } from 'redux-effects'
import { updateSettings } from 'User/Settings/actions/accountSettings'
import { addPropsToUserSettings } from 'User/Settings/actions/userSettings'

import { updateNotificationsCountAction } from 'Notifications/actions/notificationBadges'
import { delay } from 'shared/helpers/utils'

export default function () {
  return async (dispatch, getState) => {
    dispatch([
      addPropsToUserSettings({ mainPageHintsWereShown: true }),
      bind(updateSettings({ tutorial_passed: true }))
    ])
    await delay(5000)
    const alreadyHasNotifications = getState().notifications.unreadMessagesNum
    if (!alreadyHasNotifications) {
      dispatch(updateNotificationsCountAction())
    }
  }
}
