import './FileView.scss?global'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import {connect} from 'react-redux'
import cs from 'classnames'

// Components
import FileRenderer from 'FileUpload/components/FileRenderer/FileRenderer'

// Actions
import { clearViewFileAction, removeFileAction } from '../../actions/filesToUpload'

import {isEmpty} from 'lodash'

// Bridges
import { shareFile } from 'appBridge/notificators/app'
import { isBridgeAvailable } from 'appBridge/bridge'
import { UPLOADS_BASE } from 'shared/constants/Api'

// Helpers
import getGreyLabelOptions from 'GreyLabel/helpers/greyLabel'

class CurrentFileView extends Component {
  static propTypes = {
    file: PropTypes.object,
    clearView: PropTypes.func,
    removeFile: PropTypes.func
  }

  componentDidMount () {
    const { file, clearView } = this.props
    window.onpopstate = function () {
      if (file) {
        clearView()
        return false
      }
    }
  }

  onBackClick = () => {
    window.history.go(-1) // go from #file-preview hash
    this.props.clearView()
  }

  render () {
    const { file, removeFile } = this.props
    const { whiteLabel } = getGreyLabelOptions()
    if (!isEmpty(file)) {
      return (
        <div className={cs('navigation', whiteLabel, 'navigation--notfooter', 'current-file-view')}>
          <div className='navigation__header'>
            <div>
              <div className='navigation__header__left-links'>
                <div onClick={this.onBackClick} className='navigation__header__back'><span className='icon-arrow-left' /></div>
              </div>
              <div className='navigation__header__title'>
                <div>{ file.file_name || file.name }</div>
              </div>
              <div className='navigation__header__right-links'>
                { file.removable && <div onClick={() => { removeFile(file) }}><span className='icon-trash' /></div> }
                { !file.removable && file.path && isBridgeAvailable() && <div onClick={() => { shareFile(`${UPLOADS_BASE}/${file.path}/${file.file_name}`) }}><span className='icon-share' /></div> }
              </div>
            </div>
          </div>
          <div className='navigation-content content'>
            <FileRenderer file={file} zoomable />
          </div>
        </div>
      )
    } else {
      return null
    }
  }
}

function mapStateToProps (state) {
  return {
    file: state.filesToUpload.view
  }
}

function mapDispatchToProps (dispatch) {
  return {
    clearView: () => { dispatch(clearViewFileAction()) },
    removeFile: (file) => { dispatch([removeFileAction(file), clearViewFileAction()]) }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CurrentFileView)
