import './styles/index.scss?global'

import Smartphones from 'Invoices/CustomerInvoices/views/InvoicePreviewView/containers/Smartphones'
import Tablets from 'Invoices/CustomerInvoices/views/InvoicePreviewView/containers/Tablets'

import {getCurrentDeviceView} from 'shared/helpers/utils'

const InvoicePreviewView = getCurrentDeviceView(Smartphones, Tablets)

export default InvoicePreviewView
