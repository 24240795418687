import PropTypes from 'prop-types'
import React, { Component } from 'react'

import IpadNavigation from 'Navigation/IpadNavigation'
import TodoAnnualReports from './Smartphones'

export class TodoReportsView extends Component {
  static propTypes = {
    t: PropTypes.func
  }

  render () {
    return (
      <IpadNavigation {...this.props} onlyLeftSide setCurrentLink>
        <TodoAnnualReports {...this.props} />
      </IpadNavigation>
    )
  }
}

export default TodoReportsView
