import * as Sentry from '@sentry/browser'
import React from 'react'
import ReactDOM from 'react-dom'
import { browserHistory } from 'react-router'
import { routerMiddleware } from 'react-router-redux'
import routes from './routes'
import Root from './containers/Root'
import configureStore from './redux/configureStore'
import ReactGA from 'react-ga'

import axios from 'api-client-connector'
import {
  configure as configureApiConnector,
  isLoggedIn,
  transferCookiesToRightDomain
} from 'api-client-connector/utils'
import { CLIENT_APP_TYPE } from 'api-client-connector/constants'
import maybePerformTokenAuthentication from 'api-client-connector/tokenAuth'
import { API_BASE } from 'shared/constants/Api'

import { isAndroidBridge, isIOSBridge } from 'appBridge/bridge'
import { mockIOS } from 'appBridge/mock'

import 'inobounce'

import 'styles/core.scss?global'

// import { disableAnimation, hideFooterOnInputFocus } from 'shared/helpers/utils'
import { disableAnimation } from 'shared/helpers/utils'
import { removeReducerCache, cleanMarketplaceCache } from 'shared/helpers/initializeHelpers'

import { hasLegacyAuthSession, transformLegacyAuthSessionIntoNew } from './User/Auth/helpers/index'

// Bridges between iOS and Android appsated bridges
import { setTokenFromExtension, loginSuccess } from 'User/Auth/actions/auth'
import { loadFileFromExtension, importIsNotSupportedAction } from 'FileUpload/actions/filesToUpload'
import { saveLanguage, setLanguage } from 'I18N/actions/i18n'
import { addRequestAction, removeRequestAction } from 'appBridge/actions/appBridge'
import { setReleaseName, setWrapperVersionAction } from 'AppInfo/actions/appInfo'

// GPS tracking
import { addPoint, finishTracking } from 'mrshoebox-ui-components/src/modules/DriverLogs/actions'

// Helpers
import { getBackgroundPath } from 'User/shared/helpers'

import appendStyleElementWithFonts from 'shared/helpers/fontLoader'
import { appendWhiteLabelSettings } from 'shared/helpers/whiteLabelSettings'

removeReducerCache('overview')

cleanMarketplaceCache()

// Sync dispatched route actions to the history
const reduxRouterMiddleware = routerMiddleware(browserHistory)
export const store = configureStore(window.__INITIAL_STATE__, reduxRouterMiddleware)

if (__PROD__) {
  ReactGA.initialize('UA-57918487-1')
  if (isAndroidBridge()) {
    ReactGA.set({ appName: 'Mr Shoebox Android' })
  } else if (isIOSBridge()) {
    ReactGA.set({ appName: 'Mr Shoebox iOS' })
  } else {
    ReactGA.set({ appName: 'Mr Shoebox Web' })
  }
}

appendStyleElementWithFonts()

appendWhiteLabelSettings()

configureApiConnector({
  apiBase: API_BASE,
  appType: CLIENT_APP_TYPE,
  appVersion: RELEASE_NAME
})

// Setup axios for sentry
axios.interceptors.response.use(
  response => response,
  error => {
    const { response } = error
    const { status } = response
    if (status >= 400 && ![401, 404].includes(status)) {
      Sentry.withScope(scope => {
        scope.setExtra('response', response)
        Sentry.captureException('Got bad response from server')
      })
    }
    return Promise.reject(error)
  }
)

// hideFooterOnInputFocus()

maybePerformTokenAuthentication().then(() => {
  transferCookiesToRightDomain()

  if (isLoggedIn()) {
    store.dispatch(loginSuccess())
  } else if (hasLegacyAuthSession()) {
    transformLegacyAuthSessionIntoNew()
    store.dispatch(loginSuccess())
  }

  ReactDOM.render(
    <Root history={browserHistory} routes={routes} store={store} />,
    document.getElementById('root')
  )
})

let previousUrl = null
window.initialHistoryLength = window.history.length
browserHistory.listen((location) => {
  window.previousUrl = previousUrl
  previousUrl = location.pathname + location.search

  appendStyleElementWithFonts(location.pathname)
})

store.dispatch(setReleaseName(RELEASE_NAME))

// Bridges between iOS and Android wrappers
let appLoaded = null
// setAuthToken runs first and logins user.(and assign appLoaded to promise, for future use)
// Another functions that work with user interface should be run after it
window.setAuthToken = (token) => { appLoaded = store.dispatch(setTokenFromExtension(token)) }
window.loadDataFromExtension = (base64String, type) => {
  const onLoad = () => {
    if (type === 'application/octet-stream') {
      store.dispatch(importIsNotSupportedAction())
    } else {
      store.dispatch(loadFileFromExtension(base64String, type))
    }
  }
  if (appLoaded) {
    appLoaded.then(onLoad)
  } else {
    // Android somehow don't have setAuthToken function, so appLoaded always `null` on it.
    // Btw, loadDataFromExtension is used on Android in very strange way - maybe load some file on
    // first install or from sharing?
    onLoad()
  }
}
window.setLanguage = (lang) => { store.dispatch([saveLanguage(lang), setLanguage(lang)]) }
window.wrapperInitialized = (version, build) => {
  __PROD__ && ReactGA.set({ appVersion: `${version} build ${build}` })
  store.dispatch(setWrapperVersionAction({ version, build }))
}
window.addBridgeRequest = (uuid, pendingPromise) => { store.dispatch(addRequestAction(uuid, pendingPromise)) }
// handles universal links, e.g. redirect after bankid authorization. Currently on link do nothing.
window.handleIOSUniversalLink = () => true
window.resolveBridgeRequest = (uuid, payload, error) => {
  const pendingRequests = store.getState().bridgeRequests
  if (pendingRequests[uuid]) {
    if (error) {
      Sentry.withScope(scope => {
        scope.setExtra('appError', error)
        Sentry.captureException('Request to app was returned with error')
      })
      pendingRequests[uuid].reject(error)
    } else {
      if (!payload) {
        Sentry.withScope(scope => {
          scope.setExtra('requestUuid', uuid)
          scope.setExtra('appPayload', payload)
          scope.setExtra('appError', error)
          Sentry.captureException('Request to app was returned without payload')
        })
      }
      pendingRequests[uuid].resolve(payload)
    }
    store.dispatch(removeRequestAction(uuid))
  } else {
    Sentry.withScope(scope => {
      scope.setExtra('requestUuid', uuid)
      scope.setExtra('appPayload', payload)
      scope.setExtra('appError', error)
      Sentry.captureException('Request to app was not found in state')
    })
  }
}
window.broadcast = (eventName, data) => {
  if (eventName === 'location_updated') {
    store.dispatch(addPoint(data))
  } else if (eventName === 'location_tracking_ended') {
    store.dispatch(finishTracking(data))
    if (window.location.pathname !== '/driver_logs/new') {
      browserHistory.push('/driver_logs/new?tripType=new')
    }
  }
}

if (__IOS_MODE__) {
  mockIOS()
}

if (__E2E_TESTS_MODE__) {
  disableAnimation()
}

// Image preloading
const img = new Image()
img.src = getBackgroundPath()
