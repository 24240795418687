import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import CollectorLayout from 'MarketPlace/Collector/components/CollectorLayout'
import Button from 'shared/components/Button/Button'

import { RECOURSE } from 'MarketPlace/Collector/constants/factoringTypes'
import { COLLECTOR_INVOICE_OPEN, COLLECTOR_INVOICE_CLOSED } from 'Invoices/shared/constants/CollectorInvoiceStatuses'
import { CREDIT_INVOICE } from 'Invoices/shared/constants/InvoiceTypes'

import './styles.scss?global'

export const CollectorInvoiceStatusInfo = ({children, t, status, decisionReasons, recoursedOn, factoringType, isCredit}) =>
  <div className='invoice-collector-status-info'>
    <CollectorLayout>
      <div className='invoice-collector-status-info__wrapper'>
        <div className='invoice-collector-status-info__content'>
          {children}
          {!isCredit && <span>{getDescription(status, factoringType, recoursedOn, t)}</span>}
        </div>
        {
          !isCredit && decisionReasons.length > 0 &&
          <div className='invoice-collector-status-info__reasons'>
            <div>{removeDuplicates(decisionReasons).map((reason, i) =>
              <span key={reason}>
                {t('decisionReasons', reason.toString()).s}{(decisionReasons.length - 1) !== i ? '\n\n' : ''}
              </span>)}
            </div>
          </div>
        }
        <div className='invoice-collector-status-info__buttons'>
          <Button view={'transparent-white'}><a href='tel:0101610000'>{t('buttons', 'call').s}</a></Button>
          <Button view={'transparent-white'}><a href='mailto:info@collector.se'>{t('buttons', 'sendEmail').s}</a></Button>
        </div>
      </div>
    </CollectorLayout>
  </div>

CollectorInvoiceStatusInfo.propTypes = {
  children: PropTypes.element.isRequired,
  status: PropTypes.string.isRequired,
  decisionReasons: PropTypes.array,
  recoursedOn: PropTypes.string,
  factoringType: PropTypes.string.isRequired,
  isCredit: PropTypes.bool
}

function getDescription (status, factoringType, recoursedOn, t) {
  if (RECOURSE === factoringType) {
    if (COLLECTOR_INVOICE_OPEN === status || COLLECTOR_INVOICE_CLOSED === status) {
      return t('descriptions', status, factoringType, recoursedOn ? 'notNull' : 'null').s
    } else {
      return t('descriptions', status, factoringType).s
    }
  } else {
    return t('descriptions', status, factoringType).s
  }
}

function removeDuplicates (reasons) {
  // Some decision reasons codes have the same text, removing duplicates.
  const modifiedReasons = reasons.map(reason =>
    reason === 1002
      ? 1001
      : reason === 1004
        ? 1003
        : reason
  )
  return [ ...new Set(modifiedReasons) ]
}

function mapStateToProps (state) {
  const { invoiceType } = state.invoices.customerInvoices.invoice
  const { status, decisionReasons, recoursedOn, factoringType } = state.invoices.customerInvoices.invoice.collectorInfo
  return {
    t: state.i18n.get('app', 'views', 'Collector', 'CollectorInvoiceStatusInfo'),
    status,
    decisionReasons,
    recoursedOn,
    factoringType,
    isCredit: invoiceType === CREDIT_INVOICE
  }
}

export default connect(mapStateToProps)(CollectorInvoiceStatusInfo)
