import thunk from 'redux-thunk'
import rootReducer from './rootReducer'
import {
  applyMiddleware,
  compose,
  createStore
} from 'redux'
import multi from 'redux-multi'
import effects from 'redux-effects'
import fetch from './middleware/fetchMiddleware'
import cookie from 'redux-effects-cookie'
import persistState from 'redux-localstorage'
import errorMessage from './middleware/errorMessageMiddleware'
import i18n from './middleware/i18nMiddleware'
import * as Sentry from '@sentry/browser'
import createSentryMiddleware from 'redux-sentry-middleware'

const SENTRY_DSN = 'https://103709e3e89946e388302047a6604bff@sentry.mrshoebox.com/18'

export default function configureStore (initialState, ...middlewares) {
  let createStoreWithMiddleware

  let sentryMiddleware = () => (next) => (action) => next(action)
  if (!['development', 'test'].includes(process.env.APP_ENV)) {
    Sentry.init({
      dsn: SENTRY_DSN,
      release: RELEASE_NAME,
      node_env: process.env.NODE_ENV,
      environment: process.env.APP_ENV
    })
    sentryMiddleware = createSentryMiddleware(Sentry)
  }

  const middleware = applyMiddleware(thunk, multi, i18n, errorMessage, effects, fetch, cookie(), sentryMiddleware, ...middlewares)

  const reducersToCache = ['userSettings', 'invoiceSettings', 'wizard', 'appInfo', 'marketplace']
  // caching marketplace only for welcome modal state, redux-localstorage doesn't support nested fields
  // so have to store whole thing.

  if (__DEBUG__) {
    createStoreWithMiddleware = compose(
      middleware,
      persistState(reducersToCache),
      window.devToolsExtension
        ? window.devToolsExtension({ trace: true })
        : require('containers/DevTools').default.instrument()
    )
  } else {
    createStoreWithMiddleware = compose(middleware, persistState(reducersToCache))
  }

  const store = createStoreWithMiddleware(createStore)(
    rootReducer, initialState
  )
  if (module.hot) {
    module.hot.accept('./rootReducer', () => {
      const nextRootReducer = require('./rootReducer').default

      store.replaceReducer(nextRootReducer)
    })
  }
  return store
}
