import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import SuggestInput from 'shared/components/SuggestionInput'

const renderSuggestion = (suggestion) => {
  return `${suggestion.name} (${suggestion.company})`
}

const getInputValue = (suggestion) => suggestion.name

class NameInput extends Component {
  static propTypes = {
    changeCompany: PropTypes.func.isRequired,
    guests: PropTypes.arrayOf(PropTypes.object),
    input: PropTypes.object
  }

  getSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase()
    const inputLength = inputValue.length

    return this.props.guests.filter(g =>
      g.name.toLowerCase().slice(0, inputLength) === inputValue
    )
  }

  changeCompany = (event, { suggestion }) => {
    this.props.changeCompany(suggestion.company)
  }

  render () {
    return (
      <SuggestInput
        getSuggestions={this.getSuggestions}
        getInputValue={getInputValue}
        renderSuggestion={renderSuggestion}
        inputProps={this.props.input}
        onSuggestionSelected={this.changeCompany}
        shouldRenderSuggestions={() => true}
      />
    )
  }
}

const mapStateToProps = (state) => ({
  guests: state.representationGuests
})

export default connect(mapStateToProps)(NameInput)
