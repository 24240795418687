import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Navigation from 'Navigation/Navigation'
import ListView from 'shared/components/ListView/ListView'
import InvoiceListItem from 'Invoices/shared/components/ListItem/ListItem'
import CommonContainer from 'Todo/views/TodoCollectorInvoicesView/containers/Common'

class TodoCollectorInvoices extends Component {
  static propTypes = {
    invoices: PropTypes.array.isRequired,
    t: PropTypes.func.isRequired,
    extendCollectorInvoiceList: PropTypes.func.isRequired
  }

  render () {
    const { t, invoices, extendCollectorInvoiceList } = this.props
    return (
      <div className='todo-onboarding-wrapper'>
        <Navigation
          title={t('navbar', 'title').s} backLink={'/todo'}
          notBottomSpace
          infinityScrollHandler={extendCollectorInvoiceList}
          className='invoices-view'
        >
          <ListView
            data={invoices}
            template={InvoiceListItem}
            emptyListOptions={{icon: 'icon-bills-full'}}
          />
        </Navigation>
      </div>
    )
  }
}

export default CommonContainer(TodoCollectorInvoices)
