import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Field } from 'redux-form'
import { connect } from 'react-redux'
import { result, find, reverse } from 'lodash'
import './SplitAmountRow.scss?global'

// Components
import PlusButton from 'shared/components/PlusButton/PlusButton'
import DropDown from 'shared/components/FormDropdown'
import Input from 'shared/components/FormInput'

// Helpers
import { onAmountChange, onVatAmountChange, onVatChange, calculateVatAmount } from 'Events/shared/helpers/calculateAmount'

class SplitAmountRow extends Component {
  static propTypes = {
    changeFormValue: PropTypes.func,
    readOnlyVatDropDown: PropTypes.bool,
    categories: PropTypes.arrayOf(PropTypes.object),
    formValues: PropTypes.object,
    onDeleteClick: PropTypes.func,
    onCategoryChange: PropTypes.func,
    formSectionName: PropTypes.string,

    vatPercents: PropTypes.arrayOf(PropTypes.object),
    projects: PropTypes.arrayOf(PropTypes.object),
    t: PropTypes.func
  }

  constructor (props) {
    super(props)
    this.onCategoryChange = this.onCategoryChange.bind(this)
    this.onAmountChange = this.onAmountChange.bind(this)
    this.onVatChange = this.onVatChange.bind(this)
    this.onVatAmountChange = this.onVatAmountChange.bind(this)
  }

  static defaultProps = {
    formValues: {
      vatAmount: {},
      amountWithVat: {},
      vat: {},
      categoryId: {},
      projectId: {}
    }
  }

  onCategoryChange (e) {
    const {
      formValues: { categoryId, amountWithVat },
      readOnlyVatDropDown,
      categories,
      changeFormValue,
      formSectionName
    } = this.props

    const newCategoryId = Number(e.target.value)
    if (readOnlyVatDropDown) {
      const vatValue = result(find(categories, { value: newCategoryId }), 'vat')
      changeFormValue(`${formSectionName}.vat`, String(vatValue))
      changeFormValue(`${formSectionName}.vatAmount`, Number(calculateVatAmount(amountWithVat, vatValue)).toFixed(2))
    }
    changeFormValue(`${formSectionName}.categoryId`, e.target.value)
    this.props.onCategoryChange(newCategoryId, Number(categoryId))
  }

  onAmountChange (e) {
    onAmountChange(this.props, e)
  }

  onVatAmountChange (e) {
    onVatAmountChange(this.props, e)
  }

  onVatChange (e) {
    onVatChange(this.props, e)
  }

  render () {
    const {
      formValues,
      onDeleteClick,
      readOnlyVatDropDown,
      categories,
      vatPercents,
      projects,
      t,
      formSectionName
    } = this.props

    const projectsOptions = projects.filter(p => p.active ||
                                            (formValues && p.value === formValues.projectId))
    const { vatAmount, amountWithVat, vat } = formValues
    return (
      <div className='form split-amount-row'>
        <div className='form__item split-amount-row__title-field'>
          <Field
            name={`${formSectionName}.amountWithVat`}
            component={Input}
            onChange={this.onAmountChange}
            type='float'
            placeholder={t('props', 'splitAmountTotal').s}
          />
          <PlusButton type='minus' onClick={onDeleteClick} />
        </div>
        <div className='form__item form__item form__input-select form__input-select__wider-select'>
          <Field
            name={`${formSectionName}.vatAmount`}
            component={Input}
            disabled={vat === '0' || readOnlyVatDropDown}
            onChange={this.onVatAmountChange}
            type='float'
            placeholder={t('props', 'vatAmount').s} error={Math.abs(Number(vatAmount)) > Math.abs(Number(amountWithVat))}
          />
          <Field
            name={`${formSectionName}.vat`}
            component={DropDown}
            big
            onChange={this.onVatChange}
            disabled={readOnlyVatDropDown}
            options={reverse(vatPercents.slice()).concat([{label: t('props', 'vat', 'customVat').s, value: 'custom'}])}
            hint={t('props', 'vat', 'value').s}
          />
        </div>
        <div className='form__item'>
          <Field
            name={`${formSectionName}.categoryId`}
            component={DropDown}
            big
            onChange={this.onCategoryChange}
            options={categories}
            hint={t('props', 'category', 'value').s}
            emptyOption={t('props', 'category', 'label').s}
          />
        </div>
        <div className='form__item'>
          <Field
            name={`${formSectionName}.projectId`}
            component={DropDown}
            big
            emptyOption={t('props', 'project', 'label').s}
            options={projectsOptions}
            hint={t('props', 'project', 'value').s}
          />
        </div>
      </div>
    )
  }
}

function mapStateToProps (state) {
  return {
    vatPercents: (state.userSettings && state.userSettings.vatPercents) || [],
    projects: state.userSettings.projects,
    t: state.i18n.get('app', 'views', 'ReceiptSplitAmountView')
  }
}

export default connect(mapStateToProps)(SplitAmountRow)
