import {
  UPDATE_SUPPLIER_INVOICES_LIST,
  LOAD_SUPPLIER_INVOICES_LIST,
  SELECT_FOR_APPROVE_PAYMENT,
  UNSELECT_FOR_APPROVE_PAYMENT,
  CHANGE_APPROVE_SELECTION_FOR_ALL
} from '../constants'

import {
  loadRulesAction,
  setRuleFiltersAction
} from '../actions/rules'

export const initialState = {
  lastOffset: 0,
  hasMoreInvoices: true,
  listData: [],
  invoiceApproveRules: [],
  approveRuleFilters: {}
}

export default function (state = initialState, action) {
  switch (action.type) {
    case LOAD_SUPPLIER_INVOICES_LIST:
      return {
        ...state,
        ...action.payload
      }
    case UPDATE_SUPPLIER_INVOICES_LIST:
      return {
        ...state,
        ...action.payload,
        listData: [
          ...state.listData,
          ...action.payload.listData
        ]
      }
    case SELECT_FOR_APPROVE_PAYMENT:
      return {
        ...state,
        listData: state.listData.map(item =>
          item.id === action.payload.id ? { ...item, checkedForAction: true } : item
        )
      }
    case UNSELECT_FOR_APPROVE_PAYMENT:
      return {
        ...state,
        listData: state.listData.map(item =>
          item.id === action.payload.id ? { ...item, checkedForAction: false } : item
        )
      }
    case CHANGE_APPROVE_SELECTION_FOR_ALL:
      return {
        ...state,
        listData: state.listData.map(item => ({ ...item, checkedForAction: action.payload }))
      }
    case setRuleFiltersAction.getType():
      return {
        ...state,
        approveRuleFilters: action.payload
      }
    case loadRulesAction.getType():
      return {
        ...state,
        invoiceApproveRules: action.payload
      }
    default:
      return state
  }
}
