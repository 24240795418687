import React, {Component} from 'react'

import IpadNavigation from 'Navigation/IpadNavigation'
import Settings from 'User/Settings/views/SettingsView/containers/Smartphones'

export default class SettingsView extends Component {
  render () {
    return (
      <IpadNavigation {...this.props} onlyLeftSide setCurrentLink>
        <Settings{...this.props} />
      </IpadNavigation>
    )
  }
}
