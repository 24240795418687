import React from 'react'
import Checkbox from 'shared/components/Checkbox/Checkbox'
import './SelectableCounterparty.scss?global'

export default function SelectableCounterpartyItem (currentId, selectCounterparty) {
  return (info) => {
    const {name, id} = info.data
    return (
      <div onClick={() => { selectCounterparty(info.data) }} tabIndex='1' className='list-single-item counterparty-item'>
        <div><Checkbox value={currentId === id} /></div>
        <div className='counterparty-item-name'>{ name }</div>
        <h4><span className='icon-arrow-right' /></h4>
      </div>
    )
  }
}
