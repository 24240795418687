import { ADD_FILES, ADD_FILE_TO_VIEW, CLEAR_VIEW, LOAD_CURRENT_FILES, REMOVE_NEW_FILES, REMOVE_CURRENT_FILES, CLEAR_FILES, SAVE_CURRENT_PATH, EDIT_NEW_FILE, FORCE_DELETE_FILES } from '../constants/FilesToUpload'

import {isEmpty} from 'lodash'

const initialState = {
  newFiles: [],
  removeFiles: [],
  currentFiles: [],
  view: {}
}

function addIndexes (files) {
  return files.map((file, index) => { return { ...file, index } })
}

export default function (state = initialState, action) {
  switch (action.type) {
    case ADD_FILES:
      return {
        ...state,
        newFiles: addIndexes([
          ...state.newFiles,
          ...action.payload
        ])
      }
    case ADD_FILE_TO_VIEW:
      return {
        ...state,
        view: action.payload
      }
    case EDIT_NEW_FILE: {
      return {
        ...state,
        newFiles: state.newFiles.map((file, indexFromReducer) => {
          const { index, ...info } = action.payload
          if (indexFromReducer === index) {
            return {
              ...file,
              ...info
            }
          } else {
            return file
          }
        })
      }
    }
    case CLEAR_VIEW:
      return {
        ...state,
        view: {}
      }
    case LOAD_CURRENT_FILES:
      return {
        ...state,
        editMode: true,
        currentFiles: addIndexes([
          ...action.payload
        ])
      }
    case REMOVE_NEW_FILES:
      return {
        ...state,
        newFiles: addIndexes(state.newFiles.filter((e, i) => {
          return action.payload.index !== i
        }))
      }
    case REMOVE_CURRENT_FILES:
      return {
        ...state,
        removeFiles: [
          ...state.removeFiles,
          action.payload.id
        ],
        currentFiles: addIndexes(state.currentFiles.filter((e, i) => {
          return action.payload.index !== i
        }))
      }
    case SAVE_CURRENT_PATH:
      return {
        ...state,
        path: action.payload
      }
    case CLEAR_FILES:
      const pathQuery = action.payload.split('/').length
      const currentPathQuery = state.path && state.path.split('/').length // TODO: remove
      if (pathQuery < currentPathQuery && isEmpty(state.view)) {
        return {
          ...initialState
        }
      } else {
        return state
      }
    case FORCE_DELETE_FILES:
      return initialState
    default:
      return state
  }
}
