import '../SelectRecipientView.scss?global'

import PropTypes from 'prop-types'

import React, { Component } from 'react'
import { connect } from 'react-redux'

// Components
import Navigation from 'Navigation/Navigation'
import CheckBoxList from 'CheckBoxList/components/CheckBoxList/CheckBoxList'

// Action
import { loadContactsListAction, createNewConversationAction } from 'Messages/actions/messages'
import { routerActions } from 'react-router-redux'

export function RecipientsListItem (props) {
  const {name} = props.data
  return (
    <div className='recipient-name'>{name}</div>
  )
}

RecipientsListItem.propTypes = {
  data: PropTypes.object.isRequired
}

class SelectRecipient extends Component {
  static propTypes = {
    userId: PropTypes.number,
    contacts: PropTypes.arrayOf(PropTypes.object),
    currentReceivers: PropTypes.arrayOf(PropTypes.object),
    loadContactsList: PropTypes.func
  };

  componentDidMount () {
    this.props.loadContactsList(this.props.userId)
  }

  get currentReceiversUsernames () {
    const { currentReceivers = [] } = this.props
    const receiversArray = Object.keys(currentReceivers).map((r) => currentReceivers[r]) // because of redux-form
    return receiversArray.map((r) => r.id)
  }

  render () {
    return (
      <div>
        { this.props.contacts.length !== 0 &&
        <CheckBoxList
          items={this.props.contacts}
          template={RecipientsListItem}
          shouldBeChecked={(item) => this.currentReceiversUsernames.indexOf(item.id) !== -1}
          hasOnlyOneCheckedItem
        />
        }
      </div>
    )
  }
}

export class SelectRecipientWithNavigation extends Component {
  static propTypes = {
    onAddClick: PropTypes.func,
    checkboxListItems: PropTypes.arrayOf(PropTypes.object),
    t: PropTypes.func
  }

  get receiver () {
    const { checkboxListItems } = this.props
    return checkboxListItems.filter((r) => r.isChecked)[0]
  }

  render () {
    const {t, onAddClick} = this.props
    const actions = [{text: t('add').s, callback: () => { onAddClick(this.receiver) }}]

    return (
      <Navigation
        title={t('navbar', 'title').s}
        backLink='/messages'
        className='select-recipient-view'
        hideFooter
        actions={actions}
      >
        <SelectRecipient {...this.props} />
      </Navigation>
    )
  }
}

function mapStateToProps (state) {
  return {
    userId: state.userSettings.user.id,
    checkboxListItems: state.checkBoxList.items,
    contacts: state.messages.possibleReceivers,
    t: state.i18n.get('app', 'views', 'Messages', 'selectRecepient')
  }
}

function mapDispatchToProps (dispatch) {
  return {
    loadContactsList: (userId) => { dispatch(loadContactsListAction(userId)) },
    onAddClick: (receiver) => {
      dispatch([
        createNewConversationAction(receiver),
        routerActions.push(
          `/messages/conversation?accountantId=${receiver.receiverId}&accountingCompanyId=${receiver.companyId}`
        )
      ])
    }
  }
}

export const SelectRecipientView = connect(mapStateToProps, mapDispatchToProps)(SelectRecipient)
export default connect(mapStateToProps, mapDispatchToProps)(SelectRecipientWithNavigation)
