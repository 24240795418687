import React from 'react'
import PropTypes from 'prop-types'

import styles from '../../styles.scss'

export default function Alert ({ error, success }) {
  const className = error ? styles.error : styles.success
  const text = error || success
  return (
    <div className={className}>
      <p>{ text }</p>
    </div>
  )
}

Alert.propTypes = {
  error: PropTypes.string,
  success: PropTypes.string
}
