import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Button from 'shared/components/Button/Button'
import Icon from 'shared/components/Icon'
import SuccessDelivery from '../SuccessDelivery'

import './styles.scss?global'

class KnowledgeCategory extends Component {
  componentWillReceiveProps (nextProps) {
    if (nextProps.isVisible !== this.props.isVisible) {
      nextProps.isVisible && this.refs.knowledgeModal.open()
      !nextProps.isVisible && this.refs.knowledgeModal.close()
    }
  }

  get BLContent () {
    const { t, sendEmail } = this.props
    return (
      <div>
        <span className='knowledge__info'>
          {t('knowledge', 'info').s}</span>
        <ul className='knowledge__list'>
          <li>{t('knowledge', 'books').s}</li>
          <li>{t('knowledge', 'courses').s}</li>
          <li>{t('knowledge', 'apps').s}</li>
        </ul>
        <Button view={'transparent-white'} onClick={() => sendEmail('knowledge')}>
          {t('common', 'sendMail').s}</Button>
      </div>
    )
  }

  render () {
    const { t, close, isDelivered } = this.props
    return (
      <div className='knowledge'>
        <div className='knowledge__BL'>
          <Icon className='knowledge__BL-logo' type='bjornlunden' />
          <div className='knowledge__slogan'>
            {t('knowledge', 'slogan').s}</div>
        </div>
        {isDelivered
          ? <SuccessDelivery t={t} close={close} />
          : this.BLContent
        }
      </div>
    )
  }
}

KnowledgeCategory.propTypes = {
  t: PropTypes.func,
  close: PropTypes.func,
  sendEmail: PropTypes.func,
  isVisible: PropTypes.bool,
  isDelivered: PropTypes.bool
}

export default KnowledgeCategory
