import PropTypes from 'prop-types'
import React, { Component } from 'react'
import {connect} from 'react-redux'
import { getFormValues } from 'redux-form'
import { isEqual, get } from 'lodash'

// Components
import Navigation from 'Navigation/Navigation'
import DocumentsViewFilters, { initialFilterValues } from 'Documents/containers/Filter/Filter'
import {DocumentsListItem} from 'Documents/containers/ListViewWrapper/ListViewWrapper'
import ListView from 'shared/components/ListView/ListView'

// Actions
import { reloadDocumentsListAction, extendDocumentsListAction } from 'Documents/actions/documentsList'

class DocumentsView extends Component {
  static propTypes = {
    documents: PropTypes.arrayOf(PropTypes.object),
    hasNextPage: PropTypes.bool,
    lastPageLoaded: PropTypes.number,
    filters: PropTypes.object,
    loadMore: PropTypes.func,
    reloadList: PropTypes.func,
    t: PropTypes.func
  };

  constructor (props) {
    super(props)
    this.extendList = this.extendList.bind(this)
  }

  extendList () {
    const {hasNextPage, lastPageLoaded, documents} = this.props
    if (hasNextPage) {
      this.props.loadMore(documents, this.props.filters, lastPageLoaded + 1)
    }
  }

  componentDidMount () {
    this.props.reloadList(this.props.filters)
  }

  componentDidUpdate (prevProps) {
    const {filters} = this.props
    if (prevProps.filters && !isEqual(prevProps.filters, filters)) {
      this.props.reloadList(this.props.filters)
    }
  }

  render () {
    const actions = [
      {path: '/documents/new/other', icon: 'icon-add'}
    ]

    const {t, documents, filters} = this.props

    return (
      <Navigation title={t('navbar', 'title').s} backLink='/more' forceBackLink actions={actions} filterBlock infinityScrollHandler={this.extendList}>
        <DocumentsViewFilters canBeHidden={!documents.length} />
        <ListView data={documents} template={DocumentsListItem} emptyListOptions={{icon: 'icon-documents', showNotFound: Boolean(get(filters, ['search', 'value', 'length']))}} />
      </Navigation>
    )
  }
}

function mapStateToProps (state) {
  return {
    documents: state.documents.documents,
    lastPageLoaded: state.documents.lastPageLoaded,
    hasNextPage: state.documents.hasNextPage,
    filters: getFormValues('documentsViewFilters')(state) || initialFilterValues,
    t: state.i18n.get('app', 'views', 'DocumentsView')
  }
}

function mapDispatchToProps (dispatch) {
  return {
    loadMore: (list, filters, pageToLoad) => dispatch(extendDocumentsListAction(list, filters, pageToLoad)),
    reloadList: (filterValues) => { dispatch(reloadDocumentsListAction(filterValues)) }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DocumentsView)
