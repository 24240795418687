import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import styles from './ListItem.scss'
import { UniversalLink } from 'shared/components/LinkReplace'
import { isFlutterApp } from 'appBridge/bridge'
import { UniversalLinkToFlutter } from 'shared/components/LinkReplaceOpenInFlutter'
import { isIpadResolution } from 'shared/helpers/utils'

export default function AnnualReportsViewListItem (props) {
  const {date, period, path} = props.data
  if (isFlutterApp() && !isIpadResolution()) {
    return (
      <UniversalLinkToFlutter to={path} className={`list-single-item ${styles.listItem}`}>
        <h3 className='truncate'>
          { period }
        </h3>
        <h3>
          {moment(date).format('YYYY-MM-DD')}
        </h3>
        <h3><span className='icon-arrow-right' /></h3>
      </UniversalLinkToFlutter>
    )
  }

  return (
    <UniversalLink to={path} className={`list-single-item ${styles.listItem}`}>
      <h3 className='truncate'>
        { period }
      </h3>
      <h3>
        {moment(date).format('YYYY-MM-DD')}
      </h3>
      <h3><span className='icon-arrow-right' /></h3>
    </UniversalLink>
  )
}

AnnualReportsViewListItem.propTypes = {
  data: PropTypes.object.isRequired
}
