import PropTypes from 'prop-types'
import React, { Component } from 'react'
import {connect} from 'react-redux'

import { logoutAction } from 'User/Auth/actions/auth'
import getGreyLabelOptions from 'GreyLabel/helpers/greyLabel'

class LogoutComponent extends Component {
  static propTypes = {
    dispatch: PropTypes.func
  };

  componentDidMount () {
    document.title = getGreyLabelOptions().title
    this.props.dispatch(logoutAction())
  }

  render () {
    return <div />
  }
}

export default connect()(LogoutComponent)
