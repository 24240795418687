import React from 'react'
import PropTypes from 'prop-types'

import Input from 'shared/components/Input/Input'

// Component injects props from redux-form Field component.
// TODO: when migration from redux-form-5 is finished, refactor Input component and remove this one.
const FormInput = ({input, meta, ...props}) =>
  <Input {...input} {...meta} {...props} />

FormInput.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object
}

export default FormInput
