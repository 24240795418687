import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Link } from 'react-router'
import { connect } from 'react-redux'
import { routerActions } from 'react-router-redux'
import { formatCurrency } from 'mrshoebox-ui-components/src/helpers/countrySpecific'

import { isIpadResolution, isIOS } from 'shared/helpers/utils'
import getGreyLabelOptions from 'GreyLabel/helpers/greyLabel'
import { openCollectorPortal, isCollectorEnabled } from 'MarketPlace/Collector/helpers'
import { isMrshoeboxRelease, areLoansAvailable, areReportsAvailable } from 'shared/helpers/releaseHelpers'

import Icon from 'shared/components/Icon'
import LinkReplace from 'shared/components/LinkReplace'
import { loadOnboardingInfo } from 'MarketPlace/Capcito/actions/capcito'
import { ONBOARDED } from 'MarketPlace/Collector/constants/onboardingStatuses'

import OverviewTabs from 'Overview/Overview/components/Tabs/Tabs'

// Actions
import { getForecastData } from 'Overview/actions/stats'
import { getResultOverviewAction } from 'Overview/actions/resultStats'
import { getApplications } from 'MarketPlace/Collector/actions'

// Decorators
import Policy from 'Policy/decorators'

export class OverviewList extends Component {
  static propTypes = {
    t: PropTypes.func,
    className: PropTypes.string,
    type: PropTypes.string,
    loan: PropTypes.object,
    location: PropTypes.object,
    children: PropTypes.object,
    userSettings: PropTypes.object,
    loadCapcitoOnboardingInfo: PropTypes.func,
    getForecastData: PropTypes.func,
    getResultData: PropTypes.func,
    redirectIfNotPermitted: PropTypes.func,
    changeOverviewType: PropTypes.func,
    getCollectorApplications: PropTypes.func,
    collectorStatus: PropTypes.string
  }

  state = {
    isCapcitoActive: false,
    redirectLink: null
  }

  componentWillMount () {
    const { location: { query: { type = 'result' } }, redirectIfNotPermitted } = this.props
    this.props.getCollectorApplications()
    redirectIfNotPermitted(`overview-${type}`)
  }
  getData = (type, data) => {
    if (type === 'forecast') {
      this.props.getForecastData(data)
    } else {
      this.props.getResultData(data)
    }
  }
  changeOverviewTab = (type) => {
    this.props.changeOverviewType(type)
    this.getData(type)
  }

  async componentDidMount () {
    this.fetchState()
  }

  async componentDidUpdate (prevProps, prevState) {
    if (!this.state.redirectLink && prevState.redirectLink !== this.state.redirectLink) this.fetchState()
  }

  fetchState = async () => {
    if (isMrshoeboxRelease()) {
      const response = await this.props.loadCapcitoOnboardingInfo()
      if (response) this.setState({ isCapcitoActive: true, redirectLink: response.data.redirectLink })
    }
  }

  get listConfig () {
    const { t, loan, collectorStatus, type } = this.props
    const { isCapcitoActive } = this.state
    const overviewButton = { title: t('navbar', 'title').s, path: `/overview`, icon: 'icon-pie-coupled' }

    let reportsList = []
    if (areReportsAvailable()) {
      reportsList = [
        {title: t('reports', 'pnlReports').s, path: `/overview/pnl_reports`, icon: 'icon-bills-many'},
        {title: t('reports', 'balanceReports').s, path: `/overview/balance_reports`, icon: 'icon-bills-many'},
        {title: t('reports', 'vatReports').s, path: `/overview/vat_reports`, icon: 'icon-bills-many'}
      ]
    }

    if (type === 'forecast') {
      if (isIpadResolution()) {
        reportsList.unshift(overviewButton)
      }
      const withParam = reportsList.map(row => ({ ...row, path: row.path + '?type=forecast' }))
      return withParam
    }

    const loanPath = isCapcitoActive
      ? '/marketplace/capcito/portal'
      : '/marketplace/capcito'
    const loanValues = {
      existingLoan: formatCurrency(isCapcitoActive ? loan.existing : loan.limit, { withSymbol: true }),
      loanLimit: formatCurrency(isCapcitoActive ? loan.limit : '?', { withSymbol: true })
    }
    let buttons = [
      ...reportsList,
      {title: t('reports', 'key_reports').s, path: `/reports/key_figures`, icon: 'icon-bills-many', linkWithReplace: true},
      {title: t('reports', 'tax').s, path: '/reports/tax_payment', icon: 'icon-bills-percent'},
      {title: t('reports', 'financial').s, path: '/reports/financial', icon: 'icon-paper-pdf'},
      {title: t('reports', 'annual').s, path: '/reports/annual', icon: 'icon-bills-many'}
    ]

    if (isMrshoeboxRelease() && areLoansAvailable()) {
      let linkConfig = {}
      if (isIOS() && isCapcitoActive) {
        linkConfig.onClick = () => {
          window.open(this.state.redirectLink, '_blank')
          this.setState({...this.state, redirectLink: null})
        }
      } else {
        linkConfig.path = loanPath
      }

      buttons = [
        {title: t('loans', 'existingLoan').s, icon: 'icon-loan', value: loanValues.existingLoan, ...linkConfig},
        {title: t('loans', 'loanLimit').s, icon: 'icon-loan2', value: loanValues.loanLimit, ...linkConfig},
        ...buttons
      ]
    }

    if (collectorStatus && isCollectorEnabled()) {
      let buttonConfig = {
        title: t('reports', 'clientOverview').s,
        path: `/overview/client_overview`,
        icon: 'icon-client-overview'
      }
      collectorStatus === ONBOARDED
        ? buttonConfig.path = '/overview/client_overview'
        : buttonConfig.onClick = openCollectorPortal

      buttons.unshift(buttonConfig)
    }

    // TODO: move this svg icon to shared component library. Add to font and use here.
    const overviewTableIcon = 'overview-table'

    if (isIpadResolution()) {
      buttons.unshift(
        {
          title: t('reports', 'revenueCosts').s,
          path: `/overview/revenue_costs`,
          newIcon: overviewTableIcon,
          linkWithReplace: true
        }
      )
      buttons.unshift(overviewButton)
    } else {
      buttons.push({title: t('reports', 'revenueCosts').s, path: `/overview/revenue_costs`, icon: 'icon-pie-coupled'})
    }

    return buttons
  }

  render () {
    const { whiteLabel } = getGreyLabelOptions()
    const whiteLabelIconStyle = whiteLabel ? `overview-list__link--${whiteLabel}` : ''
    const { t } = this.props
    return (
      <div>
        <OverviewTabs {...this.props} changeOverviewTab={this.changeOverviewTab} />
        { this.props.children &&
          <div>
            { this.props.children }
            <div className='hr'><span>{t('reports', 'title').s}</span></div>
          </div>
        }
        <div className={`overview-list ${this.props.className || ''}`}>
          {this.listConfig.map((menu, index) => {
            const className =
                  `overview-list__link ${menu.path === window.location.pathname ? 'overview-list__link--active' : ''} ` +
                  `${whiteLabelIconStyle} ${menu.onClick ? 'overview-list__new-tab-link' : ''}` +
                  `${menu.value ? ' with-value' : ''}`

            const ComponentToUse = (isIpadResolution() && menu.linkWithReplace)
              ? LinkReplace
              : menu.onClick
                ? NewTabLink
                : Link
            const extraProps = menu.onClick ? { onClick: menu.onClick } : {}
            return (
              <ComponentToUse to={menu.path} className={className} key={index} {...extraProps}>
                { menu.newIcon ? <Icon className='overview-list__link__icon' type={menu.newIcon} /> : null }
                { menu.icon ? <span className={menu.icon} /> : null }
                <h3>{menu.title}</h3>
                {menu.value && <span className='overview-list__loan-value'>{menu.value}</span>}
              </ComponentToUse>
            )
          })}
        </div>
      </div>
    )
  }
}

const NewTabLink = (props) => <div {...props} />

const mapStateToProps = (state, props) => {
  const { settings, results, loan } = state.overview
  const { location: {query: {type = 'result'}} } = props
  return {
    type,
    settings,
    results,
    loan,
    userSettings: state.userSettings,
    t: state.i18n.get('app', 'views', 'OverviewView'),
    tViews: state.i18n.get('app', 'views'),
    collectorStatus: state.marketplace.collector.onboardingStatus
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const { route: { path } } = ownProps
  return {
    loadCapcitoOnboardingInfo: () => dispatch(loadOnboardingInfo({refreshRedirectLink: true})),
    getForecastData: (data) => { dispatch(getForecastData(data)) },
    getResultData: (data) => { dispatch(getResultOverviewAction(data)) },
    changeOverviewType: (newType) => { dispatch(routerActions.replace(`${path}?type=${newType}`)) },
    getCollectorApplications: () => dispatch(getApplications())
  }
}

export default Policy(connect(mapStateToProps, mapDispatchToProps)(OverviewList))
