import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { routerActions } from 'react-router-redux'
import { change, formValueSelector, destroy, isDirty, initialize } from 'redux-form'
import { isEqual } from 'lodash'

import Navigation from 'Navigation/Navigation'
import SalaryForm from 'Salaries/components/SalaryForm'
import { getSalary, saveSalary, clearSalary, calculateSalary } from 'Salaries/actions'

class SalaryEdit extends Component {
  static propTypes = {
    clearSalary: PropTypes.func.isRequired,
    calculateSalary: PropTypes.func.isRequired,
    formIsDirty: PropTypes.bool.isRequired,
    initializeForm: PropTypes.func,
    loadSalary: PropTypes.func.isRequired,
    loadEmployees: PropTypes.func.usRequired,
    location: PropTypes.object.isRequired,
    params: PropTypes.object,
    save: PropTypes.func.isRequired,
    goToAddBenefit: PropTypes.func.isRequired,
    goToSalaries: PropTypes.func.isRequired,
    changeBenefits: PropTypes.func.isRequired,
    formValues: PropTypes.object.isRequired,
    resetForm: PropTypes.func.isRequired
  }

  componentDidMount () {
    const {
      params: { salaryId }, loadSalary, formIsDirty,
      formValues: { benefits = [], netSalary, grossSalary, employeeId }, calculateSalary
    } = this.props

    if (salaryId && salaryId !== 'new' && !formIsDirty) {
      this.clearSalaryState()
      loadSalary(salaryId)
    }

    if (benefits.length && employeeId) {
      calculateSalary({ benefits, netSalary, grossSalary, employeeId })
    }
  }

  componentDidUpdate (prevProps) {
    const { calculateSalary, formValues: { benefits = [], netSalary, grossSalary, employeeId }, params: { salaryId } } = this.props

    const { formValues: { employeeId: prevEmployeeId, benefits: prevBenefits = [] }, params: { salaryId: prevSalaryId } } = prevProps

    if (salaryId !== prevSalaryId) this.clearSalaryState()

    if (
      Boolean(employeeId) && (!isEqual(employeeId, prevEmployeeId) ||
      benefits.length !== prevBenefits.length) && salaryId === prevSalaryId
    ) {
      calculateSalary({ benefits, netSalary, grossSalary, employeeId })
    }
  }

  calculateSalaryHandler = () => {
    const { formValues: { benefits, netSalary, grossSalary, employeeId }, calculateSalary } = this.props
    if (employeeId) {
      calculateSalary({ benefits, netSalary, grossSalary, employeeId })
    }
  }

  clearSalaryState = () => {
    this.props.clearSalary()
    this.props.resetForm()
  }

  cancelHandler = () => {
    this.clearSalaryState()
    this.props.goToSalaries()
  }

  render () {
    const { t, params: { salaryId }, goToAddBenefit, formValues: { benefits } } = this.props
    return (
      <SalaryForm
        {...this.props}
        t={t('form')}
        id={salaryId}
        addBenefitHandler={() => goToAddBenefit(salaryId || 'new')}
        benefits={benefits || []}
        cancelHandler={this.cancelHandler}
        calculateSalaryHandler={this.calculateSalaryHandler}
        onSuccess={this.clearSalaryState}
      />
    )
  }
}

const SalaryEditWithNavigation = (props) =>
  <Navigation title={props.title || props.t('navbar', 'title').s} hideFooter>
    <SalaryEdit {...props} />
  </Navigation>

SalaryEditWithNavigation.propTypes = {
  params: PropTypes.object,
  title: PropTypes.string
}

const selector = formValueSelector('salary')

const mapStateToProps = (state) => {
  const salary = state.salaries.salary
  const initialValues = {
    employeeId: salary.employeeId,
    title: salary.title,
    paymentAccountId: salary.paymentAccount.id,
    benefits: salary.benefits,
    projectId: salary.projectId,
    netSalary: salary.netSalary,
    grossSalary: salary.grossSalary
  }
  return {
    t: state.i18n.get('app', 'views', 'Salaries', 'SalaryEditView'),
    tButtons: state.i18n.get('app', 'shared', 'buttons'),
    initialValues,
    employeesOptions: state.userSettings.employees.map(employee =>
      ({ label: `${employee.firstName} ${employee.lastName}`, value: employee.id, benefits: employee.benefits })
    ),
    projectsOptions: state.userSettings.projects,
    paymentOptions: state.userSettings.expensePaymentMethods,
    formValues: selector(state, 'benefits', 'netSalary', 'grossSalary', 'employeeId'),
    calculatedTotals: state.salaries.salary.totals,
    title: state.salaries.salary.title,
    isCalculating: state.salaries.salary.isCalculating,
    formIsDirty: isDirty('salary')(state)
  }
}

const mapDispatchToProps = (dispatch) => ({
  loadSalary: (id) => dispatch(getSalary(id)),
  save: (values, SalaryId) => dispatch(saveSalary(values, SalaryId)),
  goToAddBenefit: (id) => dispatch(routerActions.push(`/salaries/${id}/add-benefit`)),
  goToSalaries: () => dispatch(routerActions.push('/salaries')),
  changeBenefits: (benefits) => dispatch(change('salary', 'benefits', benefits)),
  clearSalary: () => dispatch(clearSalary()),
  resetForm: () => dispatch(destroy('salary')),
  calculateSalary: (data) => dispatch(calculateSalary(data)),
  initializeForm: (data) => dispatch(initialize('salary', data, true))
})

export const SalaryEditView = connect(mapStateToProps, mapDispatchToProps)(SalaryEdit)
export default connect(mapStateToProps, mapDispatchToProps)(SalaryEditWithNavigation)
