import PropTypes from 'prop-types'
import React from 'react'

import { Link } from 'react-router'
import FormatNumber from 'shared/components/FormatNumber/FormatNumber'

export default function ArticlesListItem (props) {
  const {name, price, id, articleNumber} = props.data
  return (
    <Link to={`/articles/${id}`} className='list-multiline-item'>
      <div className='list-multiline-item__row'>
        <div>
          <h4>{name}</h4>
          <h4><FormatNumber value={price} /></h4>
        </div>
        <div>
          <div className='event-additional-info'>
            <span className='number'>{articleNumber}</span>
          </div>
        </div>
      </div>
      <h4 className='list-multiline-item__arrow'><span className='icon-arrow-right' /></h4>
    </Link>
  )
}

ArticlesListItem.prototype.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired
}
