import React, {Component} from 'react'

import Timesheets from 'Timesheet/views/TimesheetsView/containers/Smartphones'
import IpadNavigation from 'Navigation/IpadNavigation'

export default class TimesheetsView extends Component {
  render () {
    return (
      <IpadNavigation {...this.props} onlyLeftSide setCurrentLink>
        <Timesheets {...this.props} />
      </IpadNavigation>
    )
  }
}
