import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { reduxForm, getFormValues, Field, change } from 'redux-form'
import { isEqual, isNil, pick, values, isEmpty, debounce } from 'lodash'

import Input from 'shared/components/FormInput'
import Button from 'shared/components/Button/Button'
import Checkbox from 'shared/components/FormCheckbox'

import CompanyNameInput from 'Counterparties/shared/components/CompanyNameInput'
import { loadSuppliersSuggestions } from 'Counterparties/Suppliers/actions/suggestions'
import { setRuleFiltersAction } from 'Invoices/SupplierInvoices/actions/rules'

import styles from './styles.scss'

const isNullNumber = (n) => n !== 0 && !n

class ApproveRuleForm extends Component {
  static propTypes = {
    changeSupplierId: PropTypes.func,
    t: PropTypes.func,
    error: PropTypes.string,
    rulesFormValues: PropTypes.object,
    valid: PropTypes.bool,
    setRuleFilters: PropTypes.func,
    onSave: PropTypes.func,
    goToList: PropTypes.func,
    handleSubmit: PropTypes.func,
    updateSuggestions: PropTypes.func
  }

  onSelectSupplier = (supplier) => {
    this.props.changeSupplierId(supplier.id)
  }

  onBlurSupplier = (_, supplier) => {
    const { rulesFormValues, changeSupplierId } = this.props
    if (!supplier && !rulesFormValues.supplierName && rulesFormValues.supplierId) {
      changeSupplierId(null)
    }
  }

  componentDidMount () {
    this.updateFilters(this.props.rulesFormValues)
  }

  componentWillUnmount () {
    this.props.setRuleFilters({})
  }

  componentWillReceiveProps (nextProps) {
    const integerFields = ['minAmount', 'maxAmount', 'supplierId']
    const otherFields = ['dueDate']
    const getFields = (rule) => {
      return values(pick(rule, integerFields))
        .map(x => !isNil(x) && Number(x))
        .concat(values(pick(rule, otherFields)))
    }
    if (nextProps.valid && !isEqual(getFields(nextProps.rulesFormValues), getFields(this.props.rulesFormValues))) {
      this.updateFilters(nextProps.rulesFormValues)
    }
  }

  updateFilters = debounce((rule) => {
    const filtrableKeys = ['minAmount', 'maxAmount', 'supplierId', 'dueDate']
    if (values(pick(rule, filtrableKeys)).every(x => isNil(x))) return
    this.props.setRuleFilters(pick(rule, filtrableKeys))
  }, 350)

  render () {
    const {
      onSave, t, goToList, handleSubmit, error
    } = this.props
    return (
      <div>
        <div className={styles.listLink} onClick={goToList}>
          {t('returnToList').s}
          <span className='icon-arrow-right' />
        </div>
        <Field name='name' component={Input} view='filter' label={t('props', 'name').s} />
        {/* FIXME: without that onBlur date not changed o_o */}
        <Field name='dueDate' component={Input} view='filter' label={t('props', 'dueDate').s} onBlur={() => {}} type='date' />
        <Field name='minAmount' component={Input} view='filter' label={t('props', 'minAmount').s} type='float' />
        <Field name='maxAmount' component={Input} view='filter' label={t('props', 'maxAmount').s} type='float' />
        <Field
          component={CompanyNameInput}
          view='filter'
          label={t('props', 'supplier').s}
          name='ownSuppliers'
          onBlur={this.onBlurSupplier}
          updateSuggestions={this.props.updateSuggestions}
          onSelectSuggestion={this.onSelectSupplier}
        />
        { error && <span className={styles.error}> {error} </span> }
        <div className={styles.submitRow}>
          <Button view='primary-small' saveButton onClick={handleSubmit((values) => onSave(values))}>
            {t('save').s}
          </Button>
          <Field name='isActive' component={Checkbox} white label={t('props', 'autoApproving').s} />
        </div>
      </div>
    )
  }
}

const formName = 'invoiceApproveRulesForm'

function mapStateToProps (state, ownProps) {
  const rule = ownProps.rule || {}
  return {
    t: state.i18n.get('app', 'views', 'Invoices', 'InvoicesView', 'ApproveRulesView'),
    initialValues: {
      ...rule,
      isActive: rule.isActive || false
    },
    rulesFormValues: getFormValues(formName)(state) || {},
    invoicesFilters: getFormValues('invoicesViewFilters')(state) || {}
  }
}

function mapDispatchToProps (dispatch) {
  return {
    updateSuggestions: (...props) => { dispatch(loadSuppliersSuggestions(...props)) },
    setRuleFilters: (...props) => { dispatch(setRuleFiltersAction(...props)) },
    changeSupplierId: (id) => dispatch(change(formName, 'supplierId', id))
  }
}

function validate (values, { t }) {
  let errors = {}
  if (!isNullNumber(values.minAmount) && Number(values.minAmount) < 0) {
    errors.minAmount = true
  }
  if (!isNullNumber(values.maxAmount) && Number(values.maxAmount) < 1) {
    errors.maxAmount = true
  }
  if (!isNullNumber(values.minAmount) && !isNullNumber(values.maxAmount) && Number(values.maxAmount) < Number(values.minAmount)) {
    errors.minAmount = true
    errors.maxAmount = true
    errors._error = t('errors', 'maxAmountLessThanMin').s
  }
  return errors
}

async function onSubmitValidate (values, _dispatch, { t }) {
  let errors = {}
  if (isEmpty(values.name)) {
    errors.name = true
  }
  if (isNullNumber(values.minAmount) && isNullNumber(values.maxAmount) &&
      isNullNumber(values.supplierId) && !values.dueDate) {
    // at least one field should be filled
    errors = Object.assign(errors, {
      minAmount: true,
      maxAmount: true,
      supplierName: true,
      dueDate: true,
      _error: t('errors', 'oneFieldRequired').s
    })
  }
  if (!isEmpty(errors)) {
    throw errors // eslint-disable-line
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: formName,
  validate,
  asyncValidate: onSubmitValidate,
  asyncBlurFields: [] // to run asyncValidate only onSubmit
})(ApproveRuleForm))
