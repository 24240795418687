import React from 'react'
import PropTypes from 'prop-types'
import cs from 'classnames'
import Button from 'shared/components/Button/Button'

import './styles.scss?global'

const KivraButton = ({green, white, ...props}) => {
  const className = cs(
    'kivra-button',
    green && 'kivra-button_green',
    white && 'kivra-button_white'
  )
  return (
    <div className={className}>
      <Button {...props} />
    </div>
  )
}

KivraButton.propTypes = {
  green: PropTypes.bool,
  white: PropTypes.bool
}
export default KivraButton
