import React from 'react'
import { routerActions } from 'react-router-redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { blockWelcomeModal } from 'MarketPlace/shared/actions'

export default function FactoringHOC (Component) {
  class Factoring extends React.Component {
    static propTypes = {
      blockWelcomeModal: PropTypes.func.isRequired
    }

    componentDidMount () {
      this.props.blockWelcomeModal(true)
    }

    render () {
      return <Component {...this.props} />
    }
  }

  function mapStateToProps (state) {
    return {
      t: state.i18n.get('app', 'views', 'Collector')
    }
  }

  function mapDispatchToProps (dispatch) {
    return {
      blockWelcomeModal: (...args) => dispatch(blockWelcomeModal(...args)),
      cancelHandler: () => dispatch(routerActions.goBack()),
      startHandler: () => dispatch(routerActions.push('/marketplace/factoring/form'))
    }
  }

  return connect(mapStateToProps, mapDispatchToProps)(Factoring)
}
