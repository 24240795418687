import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { routerActions } from 'react-router-redux'

// Actions
import { loadOverviewFinanceAction, clearOverviewTotalAction } from 'Overview/actions/stats'
import { loadResultOverviewChartDataAction, getFiscalYearsAndChartDataAction } from 'Overview/actions/resultStats'

// Constants
import chartColors from 'Overview/constants/ChartColors'

import {scaleOrdinal} from 'd3-scale'
import {includes} from 'lodash'

export default function FinanceVisualizationContainer (Component) {
  class FinanceVisualization extends React.Component {
    static propTypes = {
      loadOverviewFinance: PropTypes.func,
      loadResultOverviewChartData: PropTypes.func,
      clearOverviewTotal: PropTypes.func,
      params: PropTypes.object,
      getFiscalYears: PropTypes.func,
      settings: PropTypes.object,
      location: PropTypes.object
    }

    getColor = scaleOrdinal(chartColors)

    componentWillMount () {
      const { settings: { result: {fiscalYears} }, getFiscalYears, location: {pathname}, params: {type} } = this.props
      if (!fiscalYears && includes(pathname, 'result')) {
        getFiscalYears(type)
      } else {
        this.loadInformation()
      }
    }

    componentWillUnmount () {
      this.props.clearOverviewTotal()
    }

    loadInformation = (filterValues) => {
      const {location: {pathname}, params: {type}, loadResultOverviewChartData, loadOverviewFinance} = this.props
      if (includes(pathname, 'result')) {
        loadResultOverviewChartData(type, filterValues)
      } else {
        loadOverviewFinance(type, filterValues)
      }
    }

    render () {
      return (
        <Component {...this.props} loadInfo={this.loadInformation} getColor={this.getColor} />
      )
    }
  }

  function mapStateToProps (state) {
    return {
      settings: state.overview.settings,
      totals: state.overview.totals,
      t: state.i18n.get('app', 'views', 'FinanceVisualization')
    }
  }

  function mapDispatchToProps (dispatch, ownProps) {
    const { location: { pathname, query: {date} } } = ownProps
    return {
      changeChartType: (chartType) => { dispatch(routerActions.replace(`${pathname}?chart_type=${chartType}`)) },
      loadOverviewFinance: (type, filter) => { dispatch(loadOverviewFinanceAction(type, filter, date)) },
      loadResultOverviewChartData: (type, filter) => { dispatch(loadResultOverviewChartDataAction(type, filter, date)) },
      clearOverviewTotal: () => { dispatch(clearOverviewTotalAction()) },
      getFiscalYears: (type) => { dispatch(getFiscalYearsAndChartDataAction(type)) }
    }
  }

  return connect(mapStateToProps, mapDispatchToProps)(FinanceVisualization)
}
