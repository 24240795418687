exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._242Ud33tZwrSf9sgm_jbCd {\n  width: 100%;\n  height: 100%;\n}\n\n.x2N1p2ZIuwbVDOeVug9AT {\n  width: 100%;\n  height: calc(100vh - 8rem);\n}\n\n", "", {"version":3,"sources":["/home/circleci/mobile_ui/src/MarketPlace/Capcito/views/CapcitoFrameView/containers/index.scss"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;CACd;;AAED;EACE,YAAY;EACZ,2BAA2B;CAC5B","file":"index.scss","sourcesContent":[".frame {\n  width: 100%;\n  height: 100%;\n}\n\n.tabletFrame {\n  width: 100%;\n  height: calc(100vh - 8rem);\n}\n\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"frame": "_242Ud33tZwrSf9sgm_jbCd",
	"tabletFrame": "x2N1p2ZIuwbVDOeVug9AT"
};