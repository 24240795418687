export function getTodoLink (props) {
  const {userSettings, wizardStep, userSettings: { company: { companyName } = {} }} = props

  if (userSettings.loaded && userSettings.accountant) {
    return '/todo'
  }

  if (wizardStep) {
    return wizardStep
  }

  if (!companyName) {
    return '/wizard/profile'
  }

  return '/wizard/next-actions'
}
