import {bind} from 'redux-effects'
import {createAction} from 'redux-actions'
import {sortBy} from 'lodash'
import moment from 'moment'

import {fetch, fetch2} from 'shared/helpers/fetch'

import {
  OVERVIEW_REVENUES_LIST,
  OVERVIEW_EXPENSE_LIST,
  FORECAST_MONTHLY_AGGREGATE
} from 'Overview/constants/Api'
import { OVERVIEW_SETTINGS_FORECAST_INFO_LOAD, OVERVIEW_SETTINGS_INFO_LOAD, OVERVIEW_DATA_LOAD, OVERVIEW_TOTALS_SAVE, OVERVIEW_TOTALS_CLEAR } from 'Overview/constants/ActionTypes'
import { EVENTS_LIST_UPDATE } from 'Events/shared/constants/Event'
import { TRANSACTIONS_LIST } from 'Events/shared/constants/Api'
import * as eventType from 'Events/shared/constants/eventType'
import {FORECAST_TYPE} from 'Overview/constants/OverviewSettings'

import { showNavbarSpinnerAction, hideNavbarSpinnerAction } from 'shared/actions/navbarSpinner'
import { isMrshoeboxRelease } from 'shared/helpers/releaseHelpers'
import { prepareMonthlyResults } from './resultStats'
import { loadLoanAction } from './loan'

const loadOverviewSettingsInfo = createAction(OVERVIEW_SETTINGS_INFO_LOAD)
const loadOverviewSettingsForecastInfo = createAction(OVERVIEW_SETTINGS_FORECAST_INFO_LOAD)
const loadOverviewData = createAction(OVERVIEW_DATA_LOAD)
const updateOverviewTotal = createAction(OVERVIEW_TOTALS_SAVE)
const clearOverviewTotal = createAction(OVERVIEW_TOTALS_CLEAR)
const updateEventsList = createAction(EVENTS_LIST_UPDATE)

const transactionsLimitToPage = 25

export function getForecastData (formValues) {
  return (dispatch, getState) => {
    const data = formValues || getState().overview.settings.forecast
    let {eventStatus, from, to} = data
    const defaultYear = moment().format('YYYY')

    if (!from && !to) {
      from = moment(defaultYear, 'YYYY').startOf('year').format('YYYY-MM-DD')
      to = moment().endOf('month').format('YYYY-MM-DD')
    }

    const actions = []
    actions.push(loadOverviewSettingsForecastInfo({from, to, eventStatus, type: FORECAST_TYPE}))
    actions.push(loadOverviewSettingsInfo({type: FORECAST_TYPE}))
    actions.push(bind(fetch2(FORECAST_MONTHLY_AGGREGATE, {
      from,
      to,
      transaction_status: eventStatus
    }), loadForecastInfoToState, processError))
    isMrshoeboxRelease() && actions.push(loadLoanAction())

    return dispatch(actions)
  }
}

export function clearOverviewTotalAction () {
  return clearOverviewTotal()
}

function calculationAccount (info) {
  const {revenuesGivenPeriodAmount, expensesGivenPeriodAmount} = info
  let account = {income: null, outcome: null, difference: null}
  account.income = revenuesGivenPeriodAmount
  account.outcome = expensesGivenPeriodAmount
  account.difference = (revenuesGivenPeriodAmount - expensesGivenPeriodAmount).toFixed(2)
  return account
}

// total overview visualization
export function loadOverviewFinanceAction (page, filterValues) {
  return (dispatch, getState) => {
    const defaultYear = moment().format('YYYY')
    const chartSettings = filterValues || getState().overview.settings.forecast
    let {from, to, eventStatus} = chartSettings

    if (!from && !to) {
      from = moment(defaultYear, 'YYYY').startOf('year').format('YYYY-MM-DD')
      to = moment().endOf('month').format('YYYY-MM-DD')
    }

    return dispatch([
      showNavbarSpinnerAction(),
      loadOverviewSettingsInfo({type: FORECAST_TYPE}),
      loadOverviewSettingsForecastInfo({from, to, eventStatus}),
      bind(fetchOverviewFinance(page, from, to, eventStatus), processOverviewFinance.bind(null, page), processError)
    ])
  }
}

function fetchOverviewFinance (type, from, to, status) {
  const api = {revenues: OVERVIEW_REVENUES_LIST, expenses: OVERVIEW_EXPENSE_LIST}
  return fetch(api[type], {from, to, excludeVat: true, transactionStatus: status}, {
    method: 'GET'
  })
}

function processOverviewFinance (type, {value = {}}) {
  return (dispatch, getState) => {
    const t = getState().i18n.get('app', 'views', 'OverviewView', 'statuses')

    const mapFinanceRow = (totalAmount) => (item) => {
      return {
        categoryName: item.account_name ? item.account_name : String(t('untagged')),
        categorySum: item.sum,
        categoryId: item.account_id,
        eventType: item.transaction_type,
        percent: Math.round((parseFloat(item.sum || 0) / totalAmount) * 100)
      }
    }

    let totalAmountWithVat
    let totalAmountWithoutVat
    let categories

    if (type === 'expenses') {
      totalAmountWithVat = value.total_expenses || 0
      totalAmountWithoutVat = value.total_expenses_ex_vat || 0
      categories = value.expenses
    } else {
      totalAmountWithVat = value.total_revenues
      totalAmountWithoutVat = value.total_revenues_ex_vat || 0
      categories = value.revenues
    }

    const financeRow = categories.filter((item) => item.sum > 0).map(mapFinanceRow(totalAmountWithVat))
    const sortedFinanceRows = sortBy(financeRow, (item) => item.categorySum).reverse()
    const info = {
      finance: sortedFinanceRows,
      totalAmount: totalAmountWithoutVat
    }

    return dispatch([updateOverviewTotal(info), hideNavbarSpinnerAction()])
  }
}

function processError (response) {
  console.log('userSettings error', response)
}

function loadForecastInfoToState (response) {
  return (dispatch) => {
    const overall = response.value.reduce((result, month) => {
      const newResult = Object.assign({}, result)
      /* TODO: (temporary solution) It's necessary to clarify this calculate logic for total sum */
      newResult.revenuesGivenPeriodAmount += Number(month.revenue - month.vat_revenue)
      newResult.expensesGivenPeriodAmount += Number(month.expenses)

      return newResult
    }, {
      revenuesGivenPeriodAmount: 0,
      expensesGivenPeriodAmount: 0
    })

    const preparedMonthsData = response.value.map((month) => {
      return { ...month, revenue: month.revenue - month.vat_revenue, expense: month.expenses }
    })

    return dispatch([
      hideNavbarSpinnerAction(),
      loadOverviewData({
        detailedResults: prepareMonthlyResults(preparedMonthsData),
        ...calculationAccount(overall)
      })
    ])
  }
}

export function getForecastDetailsByAccountAction (params) {
  const {financeType, lastOffset} = params
  if (!financeType) return [] // after click on event it's showing preview without link
  return (dispatch, getState) => {
    let oldList
    if (lastOffset === 0) {
      oldList = []
    } else {
      oldList = getState().events.data
    }

    return dispatch([
      showNavbarSpinnerAction(),
      bind(loadForecastDetailsByAccount(params), mapForecastDetailsByAccount.bind(this, oldList, financeType, lastOffset))
    ])
  }
}

function loadForecastDetailsByAccount ({financeType, lastOffset, from, to, accountId}) {
  const types = financeType === 'revenue'
    ? [eventType.inType, eventType.moneyDeposit]
    : [eventType.outType, eventType.moneyWithdrawal]
  const params = {
    source_document_date__gte: from,
    source_document_date__lte: to,
    source_document_types: types.join(','),
    filled_objects: 'source_document',
    sort: '-source_document_date',
    offset: lastOffset,
    limit: transactionsLimitToPage
  }
  const accId = accountId && accountId !== 'null' ? accountId : 0
  if (financeType === 'revenue') {
    params.credit_account_id = accId
  } else {
    params.debit_account_id = accId
  }

  return fetch2(TRANSACTIONS_LIST(params))
}

function mapForecastDetailsByAccount (oldList, financeType, lastOffset, response) {
  const eventList = response.value.transactions.map((event) => ({
    id: event.source_document.id,
    title: event.description || event.source_document.title,
    amount: event.amount,
    number: event.source_document.user_document_id,
    expenseType: financeType === 'revenue' ? 'plus' : 'minus',
    date: event.source_document.document_date
    // category: event.credit_account_name || event.debit_account_name
  }))

  return [
    updateEventsList({
      data: oldList.concat(eventList),
      hasNextPage: response.value.number_of_transactions > lastOffset + transactionsLimitToPage,
      lastOffset: lastOffset + transactionsLimitToPage
    }), hideNavbarSpinnerAction()
  ]
}
