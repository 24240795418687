import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { reduxForm, Field, getFormValues, reset, formValueSelector } from 'redux-form'
import { isEqual } from 'lodash'
import classnames from 'classnames'

import Grid from 'shared/components/Grid/Grid'
import Dropdown from 'shared/components/FormDropdown'
import Tabs from 'shared/components/FormTabs'
import Button from 'shared/components/Button/Button'
import SearchField from 'shared/components/FormSearchField'

import { reloadTimesheetsListAction } from 'Timesheet/actions/timesheetsList'

export const filterStatus = {
  all: 'all',
  active: 'active',
  ended: 'ended'
}

export class Filters extends Component {
  static propTypes = {
    filters: PropTypes.object,
    t: PropTypes.func,
    dispatch: PropTypes.func,
    onImportClick: PropTypes.func,
    dirty: PropTypes.bool,
    resetForm: PropTypes.func,
    tab: PropTypes.string
  }

  state = {
    filterState: 'normal'
  }

  componentDidUpdate (prevProps) {
    if (!isEqual(prevProps.filters, this.props.filters)) {
      this.props.dispatch(reloadTimesheetsListAction())
    }
  }

  render () {
    const { tab, t, onImportClick } = this.props

    const filterClassNames = classnames([
      'filter', 'filter-wrap', 'filter--without-fixed-height',
      this.state.filterState === 'open' && 'filter--open',
      this.state.filterState === 'collapsed' && 'filter--collapsed'
    ])

    return (
      <div className={filterClassNames}>
        <div className='filter__top-part'>
          <Field
            name='tab'
            component={Tabs}
            className='filter__top-part__tabs'
            initValue={tab}
            config={[
              {label: t('tab', 'all').s, value: filterStatus.all},
              {label: t('tab', 'active').s, value: filterStatus.active},
              {label: t('tab', 'ended').s, value: filterStatus.ended}
            ]}
          />
        </div>

        <div className='filter__main-part'>
          <Grid>
            <div>
              <Field
                name='orderBy'
                component={Dropdown}
                options={[
                  {label: t('filters', 'date').s, value: 'started_at_utc'},
                  {label: t('filters', 'minutes').s, value: 'duration'}
                ]}
              />
            </div>
            <div>
              <Field
                name='orderType'
                component={Dropdown}
                options={[
                  {label: t('filters', 'desc').s, value: 'desc'},
                  {label: t('filters', 'asc').s, value: 'asc'}
                ]}
              />
            </div>
          </Grid>
          <Grid>
            <div>
              <Field
                name='exported'
                component={Dropdown}
                options={[
                  { label: t('filters', 'all').s, value: '' },
                  { label: t('filters', 'invoiced').s, value: 'true' },
                  { label: t('filters', 'not_invoiced').s, value: 'false' }
                ]}
              />
            </div>
            <div />
          </Grid>
          <Field
            name='search'
            component={SearchField}
            changeAfterBlur
            placeholder={t('filters', 'search').s}
          />
        </div>

        <div className='filter__bottom filter__bottom--without-right-padding'>
          <div className={`filter__clear ${this.props.dirty ? '' : 'filter__clear--hidden'}`} onClick={() => this.props.resetForm()}>
            <span className='icon-cross' />
            <span className='filter__clear__text'>{t('buttons', 'clearFilters').s}</span>
          </div>
          <div className='filter__open'>
            <span className='icon-arrow-pointer-down' onClick={() => this.setState({ filterState: 'open' })} />
          </div>
          <div className='filter__close-center ' onClick={() => this.setState({ filterState: 'normal' })}>
            <span className='icon-arrow-pointer-down' />
          </div>
          { onImportClick && (
            <div className='filter__add-button'>
              <Button view='white-small' onClick={onImportClick}>{t('buttons', 'add').s}</Button>
            </div>
          )}
        </div>
      </div>
    )
  }
}

const formName = 'timesheetsViewFilters'
const selector = formValueSelector(formName)

const mapStateToProps = (state) => ({
  t: state.i18n.get('app', 'views', 'Timesheet'),
  filters: getFormValues(formName)(state),
  tab: selector(state, 'tab')
})

const mapDispatchToProps = (dispatch) => ({
  resetForm: () => dispatch(reset(formName))
})

const connectedFilters = connect(mapStateToProps, mapDispatchToProps)(Filters)

export default reduxForm({
  form: 'timesheetsViewFilters',
  destroyOnUnmount: false,
  initialValues: {
    tab: 'all',
    orderBy: 'started_at_utc',
    orderType: 'desc',
    search: ''
  }
}, mapStateToProps)(connectedFilters)
