import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'

import Icon from 'shared/components/Icon'

import styles from './tablets.scss'

const Integration = ({ name, logoClass, logoName, description, link, disabled, activatedText }) => {
  const classname = disabled ? styles.integrationsDisabled : styles.integrations
  return (
    <Link to={link} className={classname}>
      <Icon className={logoClass} type={logoName} />
      <div className={styles.infoBlock}>
        <h3>{name} {disabled ? `( ${activatedText} )` : ''}</h3>
        <span>{description}</span>
      </div>
      <span className='icon-arrow-right' />
    </Link>
  )
}

Integration.propTypes = {
  activatedText: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  link: PropTypes.string.isRequired,
  logoClass: PropTypes.string,
  logoName: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired
}

export default Integration
