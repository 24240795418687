import React from 'react'
import Modal from 'shared/components/NewModal/NewModal'

const MaintenancePage = () => {
  return (
    <Modal showOpen hideCloseButton>
      <h2>
        Idag från ca. 12.30, kommer vi utföra ett driftsunderhåll. Tjänsten kommer att vara otillgänglig fram till ca. 21:30.
      </h2>
    </Modal>
  )
}

export default MaintenancePage
