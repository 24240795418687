import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { routerActions } from 'react-router-redux'

import IpadNavigation from 'Navigation/IpadNavigation'
import {ReceiptBaseView} from 'Events/shared/views/ReceiptBaseView/containers/Smartphones'

import getLeftComponent from 'Navigation/helpers/linkAccordance'

import { clearNewEventAction } from 'Events/shared/actions/event'

export class ReceiptBase extends Component {
  static propTypes = {
    previousLink: PropTypes.string,
    event: PropTypes.object,
    defaultNavbarTitle: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
    clearEvent: PropTypes.func
  }

  render () {
    const {previousLink, clearEvent, event} = this.props
    const Events = getLeftComponent('event', previousLink)
    const navBarTitle = event.id ? event.title : this.props.defaultNavbarTitle
    return (
      <IpadNavigation {...this.props} title={navBarTitle} onClickBack={clearEvent}>
        <Events {...this.props} />
        <ReceiptBaseView {...this.props} />
      </IpadNavigation>
    )
  }
}

function mapStateToProps (state) {
  return {
    previousLink: state.appInfo.previousLink,
    event: state.events.newEvent
  }
}

function mapDispatchToProps (dispatch) {
  return {
    clearEvent: (backLink) => { dispatch([clearNewEventAction(), backLink ? routerActions.goBack() : null]) }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReceiptBase)
