import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { reduxForm, Field, change, formValueSelector } from 'redux-form'

// Actions
import {getAmountFromPercent, getPercentFromAmount, invoiceUpdatePropAction} from '../../../actions/invoiceCreate'

import { getTrimmedLink } from 'shared/helpers/linkUtils'

// Components
import Navigation from 'Navigation/Navigation'
import Input from 'shared/components/FormInput'
import Button from 'shared/components/Button/Button'
import FormatNumber from 'shared/components/FormatNumber/FormatNumber'

export class InvoiceDiscount extends Component {
  static propTypes = {
    changeDiscountAmount: PropTypes.func,
    changeDiscountPercentage: PropTypes.func,
    formValues: PropTypes.object,
    location: PropTypes.object,
    total: PropTypes.number,
    getAmountFromPercent: PropTypes.func,
    getPercentFromAmount: PropTypes.func,
    invoiceUpdateProps: PropTypes.func,
    t: PropTypes.func,
    t_button: PropTypes.func
  }

  constructor (props) {
    super(props)
    this.save = this.save.bind(this)
    this.changeAmount = this.changeAmount.bind(this)
    this.changePercent = this.changePercent.bind(this)
  }

  changeAmount (e) {
    const {
      getPercentFromAmount, total, formValues: { discountAmount },
      changeDiscountAmount, changeDiscountPercentage
    } = this.props
    const amount = Number(e.target.value)
    if (amount <= total && !(amount < 0)) {
      changeDiscountPercentage(getPercentFromAmount(total, amount))
      changeDiscountAmount(e.target.value)
    } else {
      changeDiscountAmount(discountAmount)
    }
    e.preventDefault()
  }

  changePercent (e) {
    const {
      getAmountFromPercent, total, formValues: { discountPercentage },
      changeDiscountPercentage, changeDiscountAmount
    } = this.props
    const percent = Number(e.target.value)
    if (percent >= 0 && percent <= 100) {
      changeDiscountPercentage(e.target.value)
      changeDiscountAmount(getAmountFromPercent(total, percent))
    } else {
      changeDiscountPercentage(discountPercentage)
    }
    e.preventDefault()
  }

  save () {
    const { invoiceUpdateProps, formValues: { discountPercentage, discountAmount } } = this.props
    invoiceUpdateProps('discountPercentage', Number(discountPercentage), false)
    invoiceUpdateProps('discountAmount', Number(discountAmount))
  }

  render () {
    const {t, t_button, location: {pathname}, formValues: { discountAmount }, total} = this.props
    return (
      <div className='invoice-discount f-column'>
        <div className='form f-column-top'>
          <div className='form__item'>
            <Field name='discountAmount' component={Input} onChange={this.changeAmount} placeholder={t('discountAmount').s} type='float' />
          </div>
          <div className='form__item'>
            <Field name='discountPercentage' component={Input} onChange={this.changePercent} placeholder={t('discountPercent').s} type='float' />
          </div>
          <table className='invoice-discount__totals'>
            <tbody>
              <tr>
                <td>{t('totalAmount').s}:</td>
                <td><FormatNumber value={total} /></td>
              </tr>
              <tr>
                <td>{t('totalDiscount').s}:</td>
                <td><b><FormatNumber value={discountAmount} /></b></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className='f-column-bottom form-buttons'>
          <Button view='transparent-black' className='invoice-discount-save' onClick={this.save}>{t_button('save').s}</Button>
          <Button view='transparent-red' link={getTrimmedLink(pathname, 1)}>{t_button('cancel').s}</Button>
        </div>
      </div>
    )
  }
}

class InvoiceDiscountWithNavigation extends Component {
  static propTypes = {
    t: PropTypes.func,
    location: PropTypes.object
  }

  render () {
    const {t, location: {pathname}} = this.props
    return (
      <Navigation title={t('title').s} hideFooter backLink={getTrimmedLink(pathname, 1)} className='f-column'>
        <InvoiceDiscount {...this.props} />
      </Navigation>
    )
  }
}

const formName = 'invoiceDiscount'
const selector = formValueSelector(formName)

function mapStateToProps (state) {
  const totals = state.invoices.customerInvoices.invoice.totals
  return {
    initialValues: {
      discountPercentage: state.invoices.customerInvoices.invoice.discountPercentage,
      discountAmount: state.invoices.customerInvoices.invoice.discountAmount
    },
    total: (totals.preTotal + totals.discountAmount),
    t: state.i18n.get('app', 'views', 'Invoices', 'InvoiceDiscountView'),
    t_button: state.i18n.get('app', 'shared', 'buttons'),
    formValues: selector(state, 'discountPercentage', 'discountAmount')
  }
}

function mapDispatchToProps (dispatch) {
  return {
    getAmountFromPercent: (...props) => { return getAmountFromPercent(...props) },
    getPercentFromAmount: (...props) => { return getPercentFromAmount(...props) },
    invoiceUpdateProps: (...props) => { dispatch(invoiceUpdatePropAction(...props)) },
    changeDiscountPercentage: (value) => dispatch(change(formName, 'discountPercentage', value)),
    changeDiscountAmount: (value) => dispatch(change(formName, 'discountAmount', value))
  }
}
export const InvoiceDiscountView = reduxForm({
  form: formName
})(connect(mapStateToProps, mapDispatchToProps)(InvoiceDiscount))

export default reduxForm({
  form: formName
})(connect(mapStateToProps, mapDispatchToProps)(InvoiceDiscountWithNavigation))
