import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import './styles.scss?global'

import { setInfoAction } from 'Wizard/actions/wizard'

import Navigation from 'Navigation/Navigation'
import getGreyLabelOptions from '../../../GreyLabel/helpers/greyLabel'
import ListItem from './components/ListItem'

import { createDelayedRedirect } from 'shared/helpers/utils'

class WizardNextActions extends Component {
  static propTypes = {
    t: PropTypes.func,
    canRequestAccountants: PropTypes.bool,
    companyInfo: PropTypes.object
  }

  render () {
    const { t, companyInfo: { companyName }, canRequestAccountants } = this.props

    const backLink = companyName ? '/' : '/wizard/profile'

    return (
      <Navigation navigationWithMaxWidth className='wizard wizard-todo' title={t('title').s} backLink={backLink} actions={this.navigateActions} forceBackLink hideFooter>
        <div className='wizard-todo__list'>
          { canRequestAccountants &&
          <ListItem
            icon='wizard-accountant'
            title={t('selectAccountant', 'title').s}
            text={t('selectAccountant', 'longDescription').s}
            link='/wizard/base-info'
          />
          }
          <ListItem
            icon='wizard-invoice'
            title={t('addInvoice', 'title').s}
            text={t('addInvoice', 'longDescription').s}
            onClick={createDelayedRedirect(['/', '/more', '/invoices', '/invoices/new'])}
          />
          <ListItem
            icon='wizard-receipt'
            title={t('addReceipt', 'title').s}
            text={t('addReceipt', 'longDescription').s}
            onClick={createDelayedRedirect(['/', '/events/new/expense?type=receipt'])}
          />
          <ListItem
            icon='wizard-driverlog'
            title={t('addDriverlog', 'title').s}
            text={t('addDriverlog', 'longDescription').s}
            onClick={createDelayedRedirect(['/', '/more', '/driver_logs', '/driver_logs/new'])}
          />
          <ListItem
            icon='wizard-timesheet'
            title={t('addTimesheet', 'title').s}
            text={t('addTimesheet', 'longDescription').s}
            onClick={createDelayedRedirect(['/', '/more', '/timesheets', '/timesheets/new'])}
          />
        </div>
      </Navigation>
    )
  }
}

function mapStateToProps (state) {
  return {
    canRequestAccountants: !(getGreyLabelOptions().canRequestAccountants === false) &&
      state.userSettings.canRequestAccountants,
    companyInfo: state.userSettings.company,
    t: state.i18n.get('app', 'views', 'Wizard', 'NextActions')
  }
}

function mapDispatchToProps (dispatch) {
  return {
    setInfo: (...args) => { dispatch(setInfoAction(...args)) }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WizardNextActions)
