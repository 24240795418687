import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router'

export function SuppliersListItem (props) {
  const { id, name, supplierNumber } = props.data
  return (
    <div className='list-multiline-item'>
      <Link to={`/suppliers/${id}`} className='list-multiline-item'>
        <div className='list-multiline-item__row'>
          <div>
            <h3>{name}</h3>
          </div>
          <div>
            <span className='number'>{supplierNumber}</span>
          </div>
        </div>
        <h4 className='list-multiline-item__arrow'><span className='icon-arrow-right' /></h4>
      </Link>
    </div>
  )
}

SuppliersListItem.propTypes = {
  data: PropTypes.object.isRequired
}
