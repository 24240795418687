import { bind } from 'redux-effects'
import { routerActions } from 'react-router-redux'
import { createAction } from 'redux-actions'
import { isEmpty } from 'lodash'

import moment from 'moment'

// Constants
import { PIECE, HOUR, KM } from '../../shared/constants/UnitTypes'
import { INVOICE_ROW_ADD, INVOICE_ROW_UPDATE, INVOICE_ROW_REMOVE, INVOICE_UPDATE_PROPS } from '../../shared/constants/ActionTypes'
import { ARTICLE_TYPE_SERVICE } from 'Articles/constants/ArticleTypes'

// Actions
import { fetchEvent } from 'Events/shared/actions/event'
import { fetchProject } from 'Projects/actions/project'

// Helpers
import { getVatPercentage, getVatPercentageForRow } from 'Events/shared/helpers/eventDetails'

const addInvoiceRow = createAction(INVOICE_ROW_ADD)
const updateInvoiceRow = createAction(INVOICE_ROW_UPDATE)
const removeInvoiceRow = createAction(INVOICE_ROW_REMOVE)
const updateInvoiceProps = createAction(INVOICE_UPDATE_PROPS)

function calculateSum (data) {
  const sumExVat = Number(data.amountOfUnits) * Number(data.price)
  const vatPercentage = Number(data.vatPercentage || 0)
  const sumVat = vatPercentage * sumExVat / 100
  return {...data, sumExVat, sumVat}
}

export function addInvoiceRowAction (data) {
  return [addInvoiceRow(calculateSum(data))]
}

export function updateInvoiceRowAction (data, index, back) {
  return [updateInvoiceRow({data: calculateSum(data), index: Number(index)}), back ? routerActions.goBack() : null, recalculateRotRut()]
}

export function removeInvoiceRowAction (index) {
  return [removeInvoiceRow(index), recalculateRotRut()]
}

export function recalculateRotRut () {
  return (dispatch, getState) => {
    const { invoices: { customerInvoices: { invoice: { invoiceRows, rotRutObject } } } } = getState()

    if (isEmpty(rotRutObject)) {
      return
    }

    const rowsTotal = invoiceRows.reduce((sum, item) => {
      return Number(sum + (item.rotRut === true ? item.sumExVat + item.sumVat : 0))
    }, 0)

    if (!rowsTotal) {
      return dispatch(updateInvoiceProps({
        rotRutObject: {}
      }))
    }

    if (rotRutObject.customTotal) {
      const newPercentage = rotRutObject.customTotal / rowsTotal * 100

      return dispatch(updateInvoiceProps({
        rotRutObject: {
          ...rotRutObject,
          percentage: newPercentage
        }
      }))
    } else {
      const newRotRutTotal = rowsTotal * rotRutObject.percentage / 100.0

      return dispatch(updateInvoiceProps({
        rotRutObject: {
          ...rotRutObject,
          total: newRotRutTotal
        }
      }))
    }
  }
}

// Imports
export function importEventRowAction (events) {
  return events.map((event) => {
    return bind(fetchEvent(event.id), processImportingEvent, processError)
  })
}

function processImportingEvent (response) {
  const event = response[0].value
  const vatPercentage = getVatPercentage(event)

  return event.transactions.map((row) => {
    const amountOfUnits = 1
    const price = Number(row.amount) - Number(row.vat_amount)

    return addInvoiceRowAction({
      amountOfUnits,
      unit: PIECE,
      description: row.debit_account ? row.debit_account.name : '',
      price,
      articleType: ARTICLE_TYPE_SERVICE,
      vatPercentage
    })
  })
}

export function importArticleRowAction (articles) {
  return articles.map((article) => {
    const amountOfUnits = 1
    return addInvoiceRowAction({
      amountOfUnits,
      articleType: article.articleType,
      articleId: article.id,
      unit: article.unit,
      description: article.name,
      price: article.price,
      vatPercentage: article.vatPercentage || 0
    })
  })
}

export function importTimesheetsRowAction (reports) {
  return (dispatch, getState) => {
    const state = getState()
    const vatPercents = state.userSettings && state.userSettings.vatPercents
    return dispatch(reports.map((report) => {
      const amountOfUnits = Number(report.minutes / 60)

      const startDate = report.startTime.format('YYYY-MM-DD')
      const stopDate = (report.stopTime && report.stopTime.format('YYYY-MM-DD')) || ''
      let description
      if (startDate === stopDate || !stopDate) {
        description = `Timmar ${startDate}`
      } else {
        description = `Timmar ${startDate} - ${stopDate}`
      }

      return addInvoiceRowAction({
        amountOfUnits,
        unit: HOUR,
        description,
        subdescription: report.title,
        articleType: ARTICLE_TYPE_SERVICE,
        price: report.amountPerHour || 0,
        vatPercentage: vatPercents[0].value,
        meta: { // meta is frontend-only field
          id: report.id,
          type: 'timesheet'
        }
      })
    }))
  }
}

export function importMileageReportsAction (reports) {
  return (dispatch, getState) => {
    const state = getState()
    const vatPercents = state.userSettings && state.userSettings.vatPercents
    return dispatch(reports.map((report) => {
      return addInvoiceRowAction({
        amountOfUnits: report.distance,
        unit: KM,
        price: report.amountPerKm,
        description: 'Antal körda mil',
        articleType: ARTICLE_TYPE_SERVICE,
        vatPercentage: vatPercents[0].value,
        meta: { // meta is frontend-only field
          id: report.id,
          type: 'driverlog'
        }
      })
    }))
  }
}

export function importProjectsRowAction (projects) {
  if (!projects.length) {
    return null
  }

  return importProjectAction(projects[0].id)
}

function importProjectAction (projectId) {
  return bind(fetchProject(projectId), processImportingFromProject, processError)
}

function processImportingFromProject (response) {
  // может использовать экшены выше типо importTimesheetsRowAction? Или использовать
  // форматеры типо responseSerializer
  const project = response.value
  return [
    createEventsRows(project.transactions),
    createTimesheetsRows(project.timesheets),
    createMileageReportsRows(project.driverlogs)
  ]
}

function createEventsRows (transactions) {
  return transactions.map((row) => {
    const amountOfUnits = 1
    const price = Number(row.amount) - Number(row.vat_amount)
    const account = row.debit_account || row.credit_account

    return addInvoiceRowAction({
      amountOfUnits,
      unit: PIECE,
      description: row.description || (account && account.name) || '',
      price,
      articleType: ARTICLE_TYPE_SERVICE,
      vatPercentage: getVatPercentageForRow(row)
    })
  })
}

function createTimesheetsRows (timesheets) {
  const mappedTimesheets = timesheets.map((timesheet) => ({
    id: timesheet.id,
    minutes: timesheet.duration,
    title: timesheet.title ? timesheet.title : ' ',
    startTime: moment.utc(timesheet.started_at_utc).local(),
    stopTime: timesheet.finished_at_utc && moment.utc(timesheet.finished_at_utc).local(),
    amountPerHour: timesheet.price_per_hour
  }))

  return importTimesheetsRowAction(mappedTimesheets)
}

function createMileageReportsRows (mileageReports) {
  return (dispatch, getState) => {
    const state = getState()
    const vatPercents = state.userSettings && state.userSettings.vatPercents
    return dispatch(mileageReports.map((report) => {
      return addInvoiceRowAction({
        amountOfUnits: report.distance,
        unit: KM,
        price: report.compensation_per_km,
        description: 'Antal körda mil',
        articleType: ARTICLE_TYPE_SERVICE,
        vatPercentage: vatPercents[0].value
      })
    }))
  }
}

function processError (res) {
  console.log(res)
}
