import React from 'react'
import { Route } from 'react-router'

import {requireAuthentication} from 'User/Auth/higherOrderComponents/AuthComponent'

import ReportsView from 'Reports/views/ReportsView/ReportsView'

import KeyFiguresReportView from 'Reports/views/KeyFigures/KeyFiguresReportView'

import TaxPaymentReportsView from 'Reports/views/TaxPaymentReport/TaxPaymentReportsView/TaxPaymentReportsView'
import TaxPaymentReportView from 'Reports/views/TaxPaymentReport/TaxPaymentReportView/TaxPaymentReportView'
import TaxPaymentApproveForm from 'Reports/views/TaxPaymentReport/TaxPaymentApproveFormView/TaxPaymentApproveFormView'

import FinancialReportsView from 'Reports/views/FinancialReport/FinancialReportsView/FinancialReportsView'
import FinancialReportView from 'Reports/views/FinancialReport/FinancialReportView/FinancialReportView'

import AnnualReportsView from 'Reports/views/AnnualReport/AnnualReportsView/AnnualReportsView'
import AnnualReportView from 'Reports/views/AnnualReport/AnnualReportView/AnnualReportView'

const reportsRoutes = (
  <Route>
    <Route path='/reports' component={requireAuthentication(ReportsView)} />

    <Route path='/reports/key_figures' component={requireAuthentication(KeyFiguresReportView)} />

    <Route path='/reports/tax_payment' component={requireAuthentication(TaxPaymentReportsView)} />
    <Route path='/reports/tax_payment/:taxPaymentReportId' component={requireAuthentication(TaxPaymentReportView)} />
    <Route path='/reports/tax_payment/:taxPaymentReportId/approve' component={requireAuthentication(TaxPaymentApproveForm)} />

    <Route path='/reports/financial' component={requireAuthentication(FinancialReportsView)} />
    <Route path='/reports/financial/:financialReportId' component={requireAuthentication(FinancialReportView)} />

    <Route path='/reports/annual' component={requireAuthentication(AnnualReportsView)} />
    <Route path='/reports/annual/:annualReportId' component={requireAuthentication(AnnualReportView)} />
  </Route>
)

export default reportsRoutes
