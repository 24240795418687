import { createReducer } from 'redux-act'
import { vacancyToStore } from 'Vacancies/actions'

const initialState = {}

export default createReducer({
  [vacancyToStore]: (state, payload) => ({
    ...state,
    ...payload
  })
}, initialState)
