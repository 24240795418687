
import axios from 'api-client-connector'
import { getCurrentClientCompany } from 'api-client-connector/utils'
import { createAction } from 'redux-act'
import { camelizeKeys } from 'humps'

import { hideNavbarSpinnerAction, showSlowNavbarSpinnerAction } from 'shared/actions/navbarSpinner'
import { showMessageBoxWithParamsAction } from 'MessageBox/actions/messageBox'
import { INTEGRATIONS } from '../constants/api'

export const integrationsToStore = createAction('Integrations to store')

export function loadIntegrations (params = {}) {
  return async (dispatch, getState) => {
    dispatch(showSlowNavbarSpinnerAction())
    try {
      const response = await getIntegrationsRequest({...params, company_id: getCurrentClientCompany()})
      dispatch([
        integrationsToStore(camelizeKeys(response.data)),
        hideNavbarSpinnerAction()
      ])
    } catch (e) {
      processError(e, getState, dispatch)
      dispatch(hideNavbarSpinnerAction())
    }
  }
}

function getIntegrationsRequest (params) {
  return axios.get(INTEGRATIONS(params))
}

function processError (error, getState, dispatch) {
  const t = getState().i18n.get('app', 'shared', 'messages')
  dispatch(showMessageBoxWithParamsAction(null, t('unexpectedError', 'description').s))
  console.log('error', error)
}
