import { createReducer } from 'redux-act'
import {
  employeesToStore, employeeToStore,
  benefitsToStore, clearEmployee, deletedBenefitIdToStore
} from 'Employees/actions'

const initialState = {
  list: [],
  employee: {
    benefits: [],
    deletedBenefitsIds: []
  }
}

export default createReducer({
  [employeesToStore]: (state, payload) => ({
    ...state,
    list: payload.employees,
    total: payload.total
  }),
  [employeeToStore]: (state, payload) => ({
    ...state,
    employee: payload
  }),
  [clearEmployee]: (state) => ({
    ...state,
    employee: initialState.employee
  }),
  [benefitsToStore]: (state, payload) => ({
    ...state,
    employee: {
      ...state.employee,
      benefits: payload
    }
  }),
  [deletedBenefitIdToStore]: (state, payload) => ({
    ...state,
    employee: {
      ...state.employee,
      deletedBenefitsIds: [...state.employee.deletedBenefitsIds, payload]
    }
  })
}, initialState)
