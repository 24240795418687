import { createAction } from 'redux-actions'
import { change, getFormValues } from 'redux-form'

// Constants
import { NEW_EVENT_UPDATE_PROPS } from 'Events/shared/constants/Event'
import { NEW_EVENT_EDIT_EVENT_ROWS_FIELDS } from 'Events/Expense/constants/expense'

// Helpers
import { findValueInObjectByProp } from 'shared/helpers/utils'

const updateNewEventProps = createAction(NEW_EVENT_UPDATE_PROPS)
const editEventRowsFields = createAction(NEW_EVENT_EDIT_EVENT_ROWS_FIELDS)

function addExtraFields (data, settings) {
  let newData = Object.assign({}, data)

  newData.project = findValueInObjectByProp(settings.projects, {value: Number(data.projectId)}, 'label')
  newData.category = findValueInObjectByProp(settings.expenseCategories, {value: Number(data.categoryId)}, 'label')
  if (!newData.category) {
    newData.category = findValueInObjectByProp(settings.revenueCategories, {value: Number(data.categoryId)}, 'label')
  }
  if (!newData.description) {
    newData.description = ''
  }
  if (!newData.amountWithVat) {
    newData.amountWithVat = 0.0
  }
  if (!newData.vatAmount) {
    newData.vatAmount = 0.0
  }

  return newData
}

export function saveEventRowsAction (rows) {
  return (dispatch, getState) => {
    const settings = getState().userSettings
    const rowsWithAdditionalInfo = rows.map((row) => addExtraFields(row, settings))
    const rowsTotalAmount = rows.reduce((prev, row) => prev + Number(row.amountWithVat), 0)

    return dispatch([
      updateNewEventProps({ eventRows: rowsWithAdditionalInfo, amountWithVat: rowsTotalAmount }),
      change('receipt-form', 'amountWithVat', rowsTotalAmount)
    ])
  }
}

export function saveEventFromSplitAmount (rows, rawPushAction) {
  const pushAction = (dispatch, getState) => {
    const event = getState().events.newEvent
    const formValues = getFormValues('receipt-form')(getState())

    dispatch(rawPushAction({...event, ...formValues}))
  }

  return [saveEventRowsAction(rows), pushAction]
}

export function editEventRowsFieldsAction (params) {
  return [editEventRowsFields(params)]
}
