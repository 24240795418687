import { connect } from 'react-redux'

// Views
import ProjectImportView from 'Projects/views/ProjectsImportView/ProjectsImportView'

// Actions
import { importProjectsRowAction } from '../../../actions/imports'
import { routerActions } from 'react-router-redux'

import { getTrimmedLink } from 'shared/helpers/linkUtils'

function mapStateToProps (state, ownProps) {
  const {location: {pathname}} = ownProps
  return {
    backLink: getTrimmedLink(pathname, 1)
  }
}

function mapDispatchToProps (dispatch) {
  return {
    importItemsToForm: (data) => { dispatch([importProjectsRowAction(data), routerActions.goBack()]) }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectImportView)
