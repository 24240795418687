import React from 'react'
import PropTypes from 'prop-types'
import { reduxForm, Field, change, formValueSelector, reset } from 'redux-form'
import { connect } from 'react-redux'
import classnames from 'classnames'

import SortButton from 'Events/Events/components/SortButton/SortButton'
import Dropdown from 'shared/components/Dropdown/Dropdown'

const filterClassNames = classnames([
  'filter', 'filter-wrap', 'filter--without-fixed-height', 'filter--open'
])

const EmployeesListFilters = ({ orderType, t, changeOrderType, dirty, resetForm }) =>
  <div className={filterClassNames}>
    <div className='filter__main-part'>
      <div className='filter__multi-row filter__multi-row--small-margin'>
        <div>
          <Field
            name='orderBy'
            component={FormDropdown}
            options={[
              {label: t('orderBy', 'firstName').s, value: 'first_name'},
              {label: t('orderBy', 'lastName').s, value: 'last_name'}
            ]}
            disabled
          />
        </div>
        <div className='filter__multi-row'>
          <div>
            <SortButton type='desc' value={orderType} onClick={() => changeOrderType('desc')} disabled />
          </div>
          <div>
            <SortButton type='asc' value={orderType} onClick={() => changeOrderType('asc')} disabled />
          </div>
        </div>
      </div>
    </div>

    <div style={{height: '5rem'}} className='filter__bottom filter__bottom--without-right-padding'>
      <div className={`filter__clear ${dirty ? '' : 'filter__clear--hidden'}`} onClick={() => resetForm()}>
        <span className='icon-cross' />
        <span className='filter__clear__text'>{t('buttons', 'clearFilters').s}</span>
      </div>
    </div>
  </div>

EmployeesListFilters.propTypes = {
  t: PropTypes.func.isRequired,
  orderType: PropTypes.string.isRequired,
  changeOrderType: PropTypes.func.isRequired,
  dirty: PropTypes.bool,
  resetForm: PropTypes.func
}

const FormDropdown = ({input, meta, ...props}) => // TODO: remove it when shared component code will be in master (TREP-556).
  <Dropdown {...input} {...meta} {...props} />

FormDropdown.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object
}

const formName = 'employeesListFilters'
const selector = formValueSelector(formName)

const mapStateToProps = (state) => ({
  t: state.i18n.get('app', 'views', 'Employees', 'EmployeesListView', 'filters'),
  orderType: selector(state, 'orderType')
})

const mapDispatchToProps = (dispatch) => ({
  changeOrderType: (value) => dispatch(change(formName, 'orderType', value)),
  resetForm: () => dispatch(reset(formName))
})

const connectedEmployeesFilters = connect(mapStateToProps, mapDispatchToProps)(EmployeesListFilters)

export const defaultFilterValues = {
  orderBy: 'first_name',
  orderType: 'asc'
}

export default reduxForm({
  form: formName,
  initialValues: defaultFilterValues
})(connectedEmployeesFilters)
