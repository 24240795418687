import '../styles/index.scss?global'

import Smartphones from 'More/views/MoreSalaryView/containers/Smartphones'
import Tablets from 'More/views/MoreSalaryView/containers/Tablets'

import { getCurrentDeviceView } from 'shared/helpers/utils'

const MoreSalaryView = getCurrentDeviceView(Smartphones, Tablets)

export default MoreSalaryView
