import { compact } from 'lodash'
import URI from 'urijs'

// Constants
import { STATUS_APPROVED, STATUS_VOIDED } from 'Events/shared/constants/EventStatus'
import { UPLOADS_BASE } from 'shared/constants/Api'
import * as eventTypes from 'Events/shared/constants/eventType'

import { isAvailableForOneUser } from 'shared/helpers/releaseHelpers'

export default function getMultipleActions (props, openAvoidModal) {
  function getEventEditLink () {
    const { event } = props

    if (event.eventType === eventTypes.outType && event.isInvoice) {
      return `/events/edit/${event.id}/expense?type=invoice`
    } else if (event.eventType === eventTypes.outType && !event.isInvoice) {
      return `/events/edit/${event.id}/expense?type=receipt`
    } else if (event.eventType === eventTypes.inType && event.isInvoice) {
      return `/events/edit/${event.id}/revenue?type=invoice`
    } else if (event.eventType === eventTypes.inType && !event.isInvoice) {
      return `/events/edit/${event.id}/revenue?type=receipt`
    } else if (event.eventType === eventTypes.moneyWithdrawal) {
      return `/events/edit/${event.id}/withdrawal`
    } else if (event.eventType === eventTypes.transfer) {
      return `/events/edit/${event.id}/transfer`
    } else if (event.eventType === eventTypes.moneyDeposit) {
      return `/events/edit/${event.id}/deposit`
    } else {
      return null
    }
  }

  function getMailtoContext () {
    const { event, t } = props

    const fileUrls = (event.files || []).map((f) => `${UPLOADS_BASE}/${f.path}/${f.file_name}`).join('\n')
    const subject = t('email', 'subject').s
    const body =
      `${t('email', 'subject').s}\n\n` +
      (event.title ? `${t('email', 'title').s}: ${event.title}\n` : '') +
      (event.date ? `${t('email', 'date').s}: ${event.date}\n` : '') +
      (event.category ? `${t('email', 'category').s}: ${event.category}\n` : '') +
      (event.amountWithoutVat ? `${t('email', 'amount').s}: ${event.amountWithoutVat} ${event.currency}\n` : '') +
      (event.vatAmount ? `${t('email', 'vatAmount').s}: ${event.vatAmount} ${event.currency}\n\n` : '') +
      (event.files && event.files.length ? `${t('email', 'attachments').s}:\n${fileUrls}` : '')

    return 'mailto:?' + URI.buildQuery({ subject, body }, false, false)
  }

  const {
    t, event, goToEventEdit, goToPaymentValidation,
    openRedirectToIntegrationModal, isOpenPaymentsIntegrationActive
  } = props
  const eventEditLink = getEventEditLink()
  const canBeEdited = event.status !== STATUS_APPROVED && event.status !== STATUS_VOIDED && eventEditLink
  const mailtoLink = getMailtoContext()
  const onPayInvoiceButtonClick = isOpenPaymentsIntegrationActive ? () => goToPaymentValidation(event.id) : openRedirectToIntegrationModal

  return compact([
    canBeEdited && { title: t('modal', 'edit').s, link: getEventEditLink() },
    event.isInvoice && !event.isPaid && event.status === STATUS_APPROVED &&
      { title: t('modal', 'markAsPaid').s, onClick: () => { goToEventEdit(event, eventEditLink + '&paymentEditMode=true') } },
    canBeEdited && { title: t('buttons', 'avoid').s, onClick: () => openAvoidModal() },
    { title: t('modal', 'sendByMail').s, nativeLink: mailtoLink },
    isAvailableForOneUser() && event.id && event.isInvoice && !event.isPaid &&
      event.eventType === eventTypes.outType && { title: t('modal', 'pay').s, onClick: onPayInvoiceButtonClick }
  ])
}
