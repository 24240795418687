import './Timer.scss?global'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import {connect} from 'react-redux'
import moment from 'moment'

// Components
import Button from 'shared/components/Button/Button'

// Helpers
import {setTime, getDifferentFromDates} from 'shared/helpers/utils'

// Actions
import { setTimerStateAction } from 'Timesheet/actions/timesheetsList'

class Timer extends Component {
  static propTypes = {
    startValue: PropTypes.string,
    stopValue: PropTypes.string,
    onStart: PropTypes.func,
    onStop: PropTypes.func,
    setStarted: PropTypes.func,
    setNotStarted: PropTypes.func,
    isStarted: PropTypes.bool,
    t: PropTypes.func
  };

  constructor (props) {
    super(props)
    this.state = {
      seconds: 0
    }
    this.onButtonClick = this.onButtonClick.bind(this)
  }

  startTimer (startValue = new Date()) {
    this.setState({
      seconds: getDifferentFromDates(new Date(), startValue, 'seconds')
    }, () => {
      this._intervalId = setInterval(() => {
        const difference = getDifferentFromDates(new Date(), startValue, 'seconds')
        this._isMounted && this.setState({ seconds: difference })
      }, 1000)
      this.props.onStart(moment(startValue).format('YYYY-MM-DDTHH:mm'))
      this.props.setStarted()
    })
  }

  stopTimer (stopValue = new Date()) {
    clearInterval(this._intervalId)
    this.props.setNotStarted()
    this.props.onStop(moment(stopValue).format('YYYY-MM-DDTHH:mm'))
  }

  onButtonClick () {
    const { isStarted } = this.props

    if (isStarted) {
      this.onStopButtonClick()
    } else {
      this.onStartButtonClick()
    }
  }

  onStartButtonClick () {
    const { startValue, stopValue } = this.props
    if (!startValue && !stopValue) {
      this.startTimer()
    }
  }

  onStopButtonClick () {
    const { startValue, stopValue } = this.props
    if (startValue && !stopValue) {
      this.stopTimer()
    }
  }

  componentDidMount () {
    const { startValue, stopValue } = this.props
    if (startValue && !stopValue) {
      this.startTimer(startValue)
    } else if (startValue && stopValue) {
      this.setState({ seconds: getDifferentFromDates(stopValue, startValue, 'seconds') })
    }
    this._isMounted = true
  }

  componentWillReceiveProps (nextProps) {
    if ((nextProps.startValue && nextProps.stopValue) && (this.props.startValue !== nextProps.startValue || this.props.stopValue !== nextProps.stopValue)) {
      this.setState({ seconds: getDifferentFromDates(nextProps.stopValue, nextProps.startValue, 'seconds') })
    }
  }

  componentWillUnmount () {
    clearInterval(this.state.intervalId)
    this._isMounted = false
  }

  get shouldBeSmall () {
    const { seconds } = this.state
    return seconds > 3.6 * Math.pow(10, 6)
  }

  render () {
    const {seconds} = this.state
    const {isStarted, t} = this.props
    return (
      <div className='timesheet-timer'>
        <Button onClick={this.onButtonClick}>
          <span className='icon icon-clock' />
          <span className='button-label'>{isStarted ? t('stopTimer').s : t('startTimer').s}</span>
        </Button>
        <div className={`time ${this.shouldBeSmall ? 'time-small' : ''}`}>
          {setTime(seconds, 'seconds', 'HH:MM')}
        </div>
      </div>
    )
  }
}

function mapStateToProps (state) {
  return {
    isStarted: state.timesheets.timerIsActive,
    t: state.i18n.get('app', 'views', 'Timesheet')
  }
}

function mapDispatchToProps (dispatch) {
  return {
    setStarted: () => { dispatch(setTimerStateAction(true)) },
    setNotStarted: () => { dispatch(setTimerStateAction(false)) }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Timer)
