/* eslint-disable camelcase */
import PropTypes from 'prop-types'

import React, { Component } from 'react'

// Components
import PieChart from 'shared/components/Chart/Pie'
import ListView from 'shared/components/ListView/ListView'
import PercentLineChart from 'Projects/views/ProjectView/FinanceVisualization/components/PercentLineChart'

export default class FinanceVisualizationCharts extends Component {
  static propTypes = {
    project: PropTypes.object,
    location: PropTypes.object,
    getColor: PropTypes.func,
    vat: PropTypes.bool
  }

  render () {
    const { project, location: { query: { chart_type = 'pie' } }, getColor, vat } = this.props

    const renderChart = () => {
      if (project.revenues && project.revenues.length) {
        const data = project.revenues.map((item) => {
          return {
            sum: vat ? parseFloat(item.categorySum) : parseFloat(item.categorySum) - parseFloat(item.categoryVatSum),
            percent: vat ? item.percentForAmountWithVat : item.percentForAmount,
            name: item.categoryName
          }
        })

        return (
          <div>
            <div style={{display: chart_type === 'bars' ? 'none' : 'block'}}>
              <PieChart data={data} getColor={getColor} />
            </div>
            <div style={{display: chart_type === 'pie' ? 'none' : 'block'}}>
              <PercentLineChart {...this.props} />
            </div>
          </div>
        )
      } else if (project.revenues && project.revenues.length === 0) {
        return <ListView data={[]} emptyListOptions={{icon: 'icon-events'}} />
      }
    }

    return (
      <div className='f-column'>
        {renderChart()}
      </div>
    )
  }
}
/* eslint-enable camelcase */
