import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import IpadNavigation from 'Navigation/IpadNavigation'
import {ProjectsView as Projects} from 'Projects/views/ProjectsImportView/containers/Smartphones'

import getLeftComponent from 'Navigation/helpers/linkAccordance'

class ProjectsView extends Component {
  static propTypes = {
    importItemsToForm: PropTypes.func,
    itemsToImport: PropTypes.arrayOf(PropTypes.object),
    backLink: PropTypes.string,
    t: PropTypes.func,
    tButton: PropTypes.func,
    previousLink: PropTypes.string
  }

  constructor (props) {
    super(props)
    this.extendList = this.extendList.bind(this)
  }

  extendList () {
    this.refs.projects.getWrappedInstance().extendList()
  }

  render () {
    const { t, tButton, backLink, itemsToImport, importItemsToForm, previousLink } = this.props
    const actions = [{text: tButton('add').s, callback: () => { importItemsToForm(itemsToImport) }}]

    const RightSideItems = getLeftComponent('selectableItemList', previousLink)

    return (
      <IpadNavigation {...this.props} title={t('title').s} actions={actions} infinityScrollHandler={this.extendList} backLink={backLink}>
        <RightSideItems {...this.props} />
        <Projects {...this.props} ref='projects' />
      </IpadNavigation>
    )
  }
}

function mapStateToProps (state) {
  return {
    previousLink: state.appInfo.previousLink,
    itemsToImport: state.importItems,
    t: state.i18n.get('app', 'views', 'ProjectImportView'),
    tButton: state.i18n.get('app', 'shared', 'buttons')
  }
}

export default connect(mapStateToProps)(ProjectsView)
