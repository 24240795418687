import { ADD_BRIDGE_REQUEST, REMOVE_BRIDGE_REQUEST } from 'appBridge/constants/actionTypes'

const initialState = {}

export default function (state = initialState, action) {
  switch (action.type) {
    case ADD_BRIDGE_REQUEST:
      return {
        ...state,
        ...action.payload
      }
    case REMOVE_BRIDGE_REQUEST:
      const newState = Object.assign({}, state)
      delete newState[action.payload]
      return newState
    default:
      return state
  }
}
