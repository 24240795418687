import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { reduxForm, Field, change, formValueSelector, getFormMeta } from 'redux-form'

// Components
import SearchField from 'shared/components/FormSearchField'
import Dropdown from 'shared/components/FormDropdown'
import SortButton from 'Events/Events/components/SortButton/SortButton'

class PDFReportsViewFilters extends Component {
  static propTypes = {
    canBeHidden: PropTypes.bool,
    changeOrderType: PropTypes.func,
    meta: PropTypes.object,
    orderType: PropTypes.string,
    t: PropTypes.func
  };

  render () {
    const { t, canBeHidden, meta, changeOrderType, orderType } = this.props

    if (meta.search && !meta.search.visited && canBeHidden) {
      return <div />
    }

    return (
      <div className='filter filter--without-fixed-height document-filters'>
        <div>
          <div className='filter__multi-row filter__multi-row--small-margin'>
            <div>
              <Field name='orderBy' component={Dropdown} options={[
                {label: t('orderBy', 'date').s, value: 'created_at'},
                {label: t('orderBy', 'title').s, value: 'title'}
              ]} />
            </div>
            <div className='filter__multi-row'>
              <div>
                <SortButton type='desc' value={orderType} onClick={() => changeOrderType('desc')} />
              </div>
              <div>
                <SortButton type='asc' value={orderType} onClick={() => changeOrderType('asc')} />
              </div>
            </div>
          </div>
          <Field name='search' component={SearchField} changeAfterBlur placeholder={t('search', 'placeholder').s} />
        </div>
      </div>
    )
  }
}

export const initialFilterValues = {
  search: '',
  orderBy: 'created_at',
  orderType: 'desc'
}

const formName = 'annualReportsFilters'
const selector = formValueSelector(formName)
const metaSelector = getFormMeta(formName)

function mapStateToProps (state) {
  return {
    initialValues: initialFilterValues,
    t: state.i18n.get('app', 'views', 'DocumentsView', 'filters'),
    orderType: selector(state, 'orderType'),
    meta: metaSelector(state)
  }
}

const mapDispatchToProps = (dispatch) => ({
  changeOrderType: (value) => dispatch(change(formName, 'orderType', value))
})

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: formName,
  destroyOnUnmount: false
})(PDFReportsViewFilters))
