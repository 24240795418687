import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Invoices from 'Invoices/CustomerInvoices/views/InvoicesView/containers/Smartphones'
import SendMessageForm from 'Invoices/CustomerInvoices/components/SendMessageForm/SendMessageForm'
import IpadNavigation from 'Navigation/IpadNavigation'

import CommonHOC from './Common'

class InvoiceSendView extends Component {
  static propTypes = {
    t: PropTypes.func,
    invoice: PropTypes.object
  }
  render () {
    const { t, invoice: { invoiceNumber } } = this.props
    return (
      <IpadNavigation {...this.props} title={`${t('title').s} #${invoiceNumber || ''}`} >
        <Invoices {...this.props} />
        <SendMessageForm {...this.props} />
      </IpadNavigation>
    )
  }
}

export default CommonHOC(InvoiceSendView)
