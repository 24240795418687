function handleMessage (message) {
  // for more messages check postAsyncMessage and postMessageToIOS
  const handlers = {
    contacts: (data) => {
      const uuid = data.pop()
      const contacts = [
        { name: 'DearFriend1', organization: 'GoogleAnalog1' },
        { name: 'DearFriend2', organization: 'GoogleAnalog2' },
        { name: 'DearFriend3', organization: 'GoogleAnalog3' },
        { name: 'DearFriend4', organization: 'GoogleAnalog4' },
        { name: 'DearFriend5', organization: 'GoogleAnalog5' },
        { name: 'FriendWithoutCompany1', organization: '' },
        { name: 'FriendWithoutCompany2', organization: '' }
      ]
      window.resolveBridgeRequest(uuid, contacts)
    },
    save_token: (data) => {
      localStorage['mock_iOS_token'] = data
    }
  }

  for (const key in handlers) {
    if (message.startsWith(key)) {
      const data = message.replace(`${key}_`, '').split('_')
      return handlers[key](data)
    }
  }
}

function deleteAllCookies () {
  var cookies = document.cookie.split(';')

  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i]
    var eqPos = cookie.indexOf('=')
    var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie
    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT'
  }
}

function maybeSetAuthToken () {
  const token = localStorage['mock_iOS_token']
  token && window.setAuthToken(token)
}

// NOTE sometimes you can get in console error state.i18n.get - for fix close the current
// page and open new
export function mockIOS () {
  window.webkit = {
    messageHandlers: {
      observer: {
        postMessage: (message) => setTimeout(() => handleMessage(message), 0)
      }
    }
  }
  // emulate .nonPersistent webview
  window.onunload = () => {
    delete localStorage.redux // delete redux state
    deleteAllCookies()
  }

  window.onload = () => {
    window.wrapperInitialized('1.4.3', 84)
    maybeSetAuthToken()
  }
}
