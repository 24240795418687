import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router'

export const VacanciesListItem = (props) => {
  const { id, linkId, title, startDate, endDate, price, role, keymanId } = props.data
  return (
    <div className='list-multiline-item'>
      <Link to={`/vacancies/${id}`} className='list-multiline-item'>
        <div className='list-multiline-item__row'>
          <div>
            <h4>{title}</h4>
            <h4 className='amount--mrshoebox'>{price}</h4>
          </div>
          <div>
            <div>
              <span>{keymanId} - {linkId} </span>
              <span className='delimiter'>|</span>
              <span> {startDate} - {endDate}</span>
            </div>
            <span>{role.title}</span>
          </div>
        </div>
        <h4 className='list-multiline-item__arrow'><span className='icon-arrow-right' /></h4>
      </Link>
    </div>
  )
}

VacanciesListItem.propTypes = {
  data: PropTypes.object.isRequired
}
