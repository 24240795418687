import { bind } from 'redux-effects'
import { createAction } from 'redux-actions'
import { isNil, uniq, isEmpty } from 'lodash'
import { getCurrentClientCompany } from 'api-client-connector/utils'
import { change, getFormValues } from 'redux-form'

import { fetchEvents } from 'Events/shared/actions/events'
import { pushEvent } from 'Events/shared/actions/pushEvent'
import { fetchSuppliersList } from 'Counterparties/Suppliers/actions/suppliers'
import { hideNavbarSpinnerAction, showNavbarSpinnerAction, showSlowNavbarSpinnerAction } from 'shared/actions/navbarSpinner'

import { ALL, PAID, NOT_PAID, APPROVED } from 'Invoices/shared/constants/StatusTypes'
import {
  UPDATE_SUPPLIER_INVOICES_LIST,
  LOAD_SUPPLIER_INVOICES_LIST,
  SELECT_FOR_APPROVE_PAYMENT,
  UNSELECT_FOR_APPROVE_PAYMENT,
  CHANGE_APPROVE_SELECTION_FOR_ALL
} from '../constants'

import { getCurrencyName } from 'shared/helpers/addNamesForIdProps'
import { fetchPreviewInvoicesIds } from './rules'

const initialLoadList = createAction(LOAD_SUPPLIER_INVOICES_LIST)
const updateList = createAction(UPDATE_SUPPLIER_INVOICES_LIST)

export const selectForApprove = createAction(SELECT_FOR_APPROVE_PAYMENT)
export const unselectForApprove = createAction(UNSELECT_FOR_APPROVE_PAYMENT)
export const changeApproveSelectionForAllAction = createAction(CHANGE_APPROVE_SELECTION_FOR_ALL)

export function changeApproveSelectionForAll (changeToApprove) {
  return async (dispatch, getState) => {
    const filters = getFormValues('invoicesViewFilters')(getState()) || {}
    const ruleFilters = getState().invoices.supplierInvoices.approveRuleFilters
    // to load all before check all
    dispatch(showSlowNavbarSpinnerAction())
    await dispatch(extendSupplierInvoices(filters, { ...ruleFilters, limit: 0 }))

    return dispatch(changeApproveSelectionForAllAction(changeToApprove))
  }
}

export function approveInvoices (invoices) {
  return async (dispatch) => {
    dispatch(showNavbarSpinnerAction())
    for (const invoice of invoices) {
      await pushEvent(invoice.id, { invoice_approved_for_payment: true })
    }
    return dispatch([hideNavbarSpinnerAction(), change('invoicesViewFilters', 'status', APPROVED)])
  }
}

export function unapproveInvoices (invoices) {
  return async (dispatch) => {
    dispatch(showNavbarSpinnerAction())
    for (const invoice of invoices) {
      await pushEvent(invoice.id, { invoice_approved_for_payment: false })
    }
    return dispatch([hideNavbarSpinnerAction(), change('invoicesViewFilters', 'status', APPROVED)])
  }
}

export function loadSupplierInvoices (filters, ruleFilters = {}, offset = 0) {
  return async (dispatch) => {
    let params = {
      eventType: '10, 11',
      documentType: JSON.stringify({paper_kind: 1}),
      orderType: filters.orderType,
      orderBy: filters.orderBy
      // TODO: use to decrease download size. But firstly need to add some to api.
      // fields: [
      //   "id",
      //   "title",
      //   // supplier_id,
      //   // invoice_approve_for_payment,
      //   "total_amount",
      //   "document_date",
      //   // invoice_due_date,
      //   "user_document_id",
      //   "is_paid",
      //   "currency"
      // ].join(',')
    }
    const companyId = Number(getCurrentClientCompany())
    // for checkAll checkbox
    const limit = !isNil(ruleFilters.limit) ? ruleFilters.limit : 20
    delete ruleFilters.limit
    params.limit = limit

    if (filters.status !== ALL) {
      // weird names and values because of process event filter logic(events actions)
      // TODO maybe we should not set is_paid for APPROVED invoices tab
      filters.status === PAID
        ? params.invoiceType = JSON.stringify({is_paid: true})
        : params.invoiceType = JSON.stringify({is_paid: false})
    }
    if (filters.status === APPROVED) {
      params.invoiceApprovedForPayment = true
      params.limit = 0 // to count all invoices
    }

    dispatch(showNavbarSpinnerAction())

    // FIXME: do we have that filter?
    if (filters.search) {
      const suppliersRequestParams = {
        company_id: companyId,
        with_deleted: true,
        name__icontains: filters.search
      }

      // TODO not do that in extendInvoices - because we already know all suppliers in the
      // first loading
      const { data: { suppliers } } = await fetchSuppliersList(suppliersRequestParams)
      params.supplierIds = suppliers.map(s => s.id).join(',')
      if (!params.supplierIds) {
        params.supplierIds = '-1'
      }
    }

    let totalSds
    if (filters.status === NOT_PAID && !isEmpty(ruleFilters)) {
      const response = await fetchPreviewInvoicesIds({ ...ruleFilters, offset, limit }, companyId)
      const eventsIds = response.data.sd_ids
      totalSds = response.data.total
      if (eventsIds.length === 0) {
        return dispatch(processSupplierInvoices(offset, null, {
          value: {
            source_documents: [],
            number_of_source_documents: 0
          }
        }))
      }
      params.eventsIds = eventsIds
      params.limit = 0 // because we loading by list of events ids
    }

    return dispatch([
      bind(fetchEvents(params, params.eventsIds ? 0 : offset), processSupplierInvoices.bind(null, offset, totalSds), processError)
    ])
  }
}

export function extendSupplierInvoices (filters, ruleFilters) {
  return async (dispatch, getState) => {
    const {
      invoices: { supplierInvoices: { hasMoreInvoices, lastOffset } }
    } = getState()

    // TODO: если чекбокс - limit 0 - только это будет колбек на кнопку - загрузка инвойсов без лимита

    if (hasMoreInvoices) {
      const newOffset = lastOffset + 20
      return dispatch(loadSupplierInvoices(filters, ruleFilters, newOffset))
    }
  }
}

function processSupplierInvoices (newOffset, previewTotal, response) {
  return async (dispatch, getState) => {
    const { source_documents: sourceDocuments, number_of_source_documents: sdsNumber } = response.value
    // previewTotal - pagination from rules preview
    const totalSds = previewTotal > 0 ? previewTotal : sdsNumber
    const supplierIds = uniq(sourceDocuments
      .filter(sd => !isNil(sd.supplier_id))
      .map(sd => sd.supplier_id)).join(',')
    const suppliersRequestParams = {
      ids: supplierIds,
      company_id: Number(getCurrentClientCompany())
    }

    const { data: { suppliers } } = await fetchSuppliersList(suppliersRequestParams)

    const { userSettings } = getState()
    const supplierInvoices = sourceDocuments.map(sd => {
      const supplier = (sd.supplier_id && suppliers.length) && suppliers.find(s => sd.supplier_id === s.id)
      return {
        id: sd.id,
        amount: sd.total_amount,
        date: sd.document_date,
        dueDate: sd.invoice_due_date,
        title: sd.title,
        number: sd.user_document_id,
        supplierName: (supplier && supplier.name) || '',
        invoiceApprovedForPayment: sd.invoice_approved_for_payment,
        status: sd.is_paid ? PAID : NOT_PAID,
        currency: getCurrencyName(userSettings, sd.currency_id)
      }
    })
    let payload = {
      listData: supplierInvoices
    }

    if (newOffset !== undefined) {
      payload = {
        ...payload,
        lastOffset: newOffset,
        hasMoreInvoices: totalSds > newOffset + sourceDocuments.length
      }
    }

    return dispatch([
      hideNavbarSpinnerAction(),
      newOffset ? updateList(payload) : initialLoadList(payload)
    ])
  }
}

function processError (response) {
  console.log('error', response)
}
