import { bind } from 'redux-effects'

import { fetch2 as fetch } from 'shared/helpers/fetch'
import { NOTIFICATIONS, NOTIFICATIONS_COLLECTOR_STATUS } from 'Notifications/constants/Api'
import { updateNotificationBadgesAction } from 'Notifications/actions/notificationBadges'

export function markCollectorOnboardingStatusAsReadAction () {
  return bind(
    fetch(NOTIFICATIONS, {}, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: {'collector_onboarding_was_read': true}
    }),
    updateNotificationBadgesAction
  )
}

export function markCollectorInvoiceStatusAsReadAction (invoiceId) {
  return bind(
    fetch(NOTIFICATIONS_COLLECTOR_STATUS, {}, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: { was_read: invoiceId }
    }),
    updateNotificationBadgesAction
  )
}
