import './styles/index.scss?global'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import {connect} from 'react-redux'
import cs from 'classnames'

import markAsTutorialPassedAction from 'MainPage/actions/overlayHints'
import getGreyLabelOptions from 'GreyLabel/helpers/greyLabel'

import Button from 'shared/components/Button/Button'

export class OverlayHints extends Component {
  static propTypes = {
    markAsTutorialPassed: PropTypes.func,
    active: PropTypes.bool,
    t: PropTypes.func
  }
  constructor (props) {
    super(props)
    this.state = {hide: false, active: false}
    this.onClose = this.onClose.bind(this)
  }
  onClose () {
    const {markAsTutorialPassed} = this.props
    this.setState({hide: true})
    markAsTutorialPassed()
  }
  componentWillReceiveProps (props) {
    const {active} = props
    if (active === false) {
      setTimeout(() => {
        this.setState({active})
      }, 750)
    } else {
      this.setState({active})
    }
  }
  componentDidMount () {
    const {active} = this.props
    this.setState({active})
  }
  renderDom () {
    let {t} = this.props
    const {active, hide} = this.state
    const { whiteLabel } = getGreyLabelOptions()
    if (active) {
      return (
        <div className={cs('mainpage-hints', whiteLabel)}>
          <div className={`mainpage-hints__content ${!hide ? 'mainpage-hints__content--active' : ''}`}>
            <div className={`mainpage-hints__item mainpage-hints__item--todo`}>
              <span><b>{t('todo').s}</b></span>
            </div>
            <div className={`mainpage-hints__item mainpage-hints__item--events`}>
              <span><b>{t('events').s}</b></span>
            </div>
            <div className={`mainpage-hints__item mainpage-hints__item--overview`}>
              <span><b>{t('overview').s}</b></span>
            </div>
            <div className={`mainpage-hints__item mainpage-hints__item--more`}>
              <span><b>{t('more').s}</b></span>
            </div>
            <div className={`mainpage-hints__item mainpage-hints__item--plus`}>
              <span><b>{t('plus').s}</b></span>
            </div>
          </div>
          <div className={`mainpage-hints__overlay ${!hide ? 'mainpage-hints__overlay--active' : ''}`}>
            <div className='mainpage-hints__overlay__content'>
              <div className='mainpage-hints__overlay__close'>
                <Button view='white' icon='icon-ok-light-round' onClick={this.onClose}>{t('title').s}</Button>
              </div>
            </div>
          </div>
        </div>
      )
    } else {
      return null
    }
  }
  render () {
    return this.renderDom()
  }
}

function mapStateToProps (state) {
  return {
    t: state.i18n.get('app', 'views', 'MainPage', 'components', 'OverlayHints')
  }
}

function mapDispatchToProps (dispatch) {
  return {
    markAsTutorialPassed: () => { dispatch(markAsTutorialPassedAction()) }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OverlayHints)
