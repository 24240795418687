import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'

// Actions
import { addFileToViewAction } from 'FileUpload/actions/filesToUpload'

// Components
import FileRenderer from 'FileUpload/components/FileRenderer/FileRenderer'
import { isFlutterApp } from 'appBridge/bridge'
import Button from 'shared/components/Button/Button'
import { isIpadResolution } from 'shared/helpers/utils'

function handleClick (url) {
  window.open(url, '_blank')
}

export class FilePreview extends Component {
  static propTypes = {
    file: PropTypes.object,
    addFileToView: PropTypes.func
  };

  render () {
    const {file, addFileToView} = this.props
    // TODO add translations to "Open File"
    if (isFlutterApp() && !isIpadResolution()) {
      return (
        <div className='f-column-bottom form-buttons'>
          <Button
            view='light'
            onClick={() => { handleClick(file.fullURL) }}
          >
            Öppna fil
          </Button>
        </div>
      )
    }

    return (
      <div onClick={() => { addFileToView(file) }}>
        <FileRenderer file={file} />
      </div>
    )
  }
}

function mapDispatchToProps (dispatch) {
  return {
    addFileToView: (file) => { dispatch(addFileToViewAction(file)) }
  }
}

export default connect(null, mapDispatchToProps)(FilePreview)
