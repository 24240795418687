import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import IpadNavigation from 'Navigation/IpadNavigation'
import Filters from '../../../components/Filters/Filters'
import {Customers} from './Smartphones'

import getLeftComponent from 'Navigation/helpers/linkAccordance'

class SelectableCustomersView extends Component {
  static propTypes = {
    t: PropTypes.func,
    hideNavbarActions: PropTypes.bool,
    previousLink: PropTypes.string
  }

  constructor (props) {
    super(props)
    this.extendList = this.extendList.bind(this)
  }

  extendList () {
    this.refs.customers.extendList()
  }

  render () {
    const {t, previousLink, hideNavbarActions} = this.props
    const RightSideItems = getLeftComponent('selectableItemList', previousLink)
    const actions = [{icon: 'icon-add', path: `/customers/new`, state: 'invoice'}]

    return (
      <IpadNavigation {...this.props} title={t('navbar', 'title').s} filter={<Filters />} actions={!hideNavbarActions && actions} infinityScrollHandler={this.extendList} backLink>
        <RightSideItems {...this.props} />
        <Customers {...this.props} ref='customers' />
      </IpadNavigation>
    )
  }
}

function mapStateToProps (state) {
  return {
    previousLink: state.appInfo.previousLink
  }
}

export default connect(mapStateToProps)(SelectableCustomersView)
