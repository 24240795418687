import React from 'react'
import styles from './styles.scss'

const DotsSpinner = () => {
  return (
    <div className={styles.container}>
      <div className={styles.dot1} />
      <div className={styles.dot2} />
      <div className={styles.dot3} />
      <div className={styles.dot4} />
      <div className={styles.dot5} />
    </div>
  )
}

export default DotsSpinner
