import axios from 'api-client-connector'

const FETCH = 'EFFECT_FETCH'

function fetchMiddleware () {
  return next => action =>
    action.type === FETCH
      ? performRequest(action.payload).then(createResponse, createErrorResponse)
      : next(action)
}

function performRequest ({ url, params }) {
  return axios.request({
    url,
    ...transformLegacyParams(params)
  })
}

function transformLegacyParams (params) {
  return {
    ...params,
    data: params.body
  }
}

function createResponse (res) {
  return {
    url: res.config.url,
    status: res.status,
    headers: res.headers,
    value: res.data
  }
}

function createErrorResponse (error) {
  throw createResponse(error.response)
}

export default fetchMiddleware
