import React from 'react'

import getGreyLabelOptions from 'GreyLabel/helpers/greyLabel'

function createElement (type, props) {
  const element = document.createElement(type)
  Object.assign(element, props)
  return element
}

export default class Favicon extends React.Component {
  componentDidMount () {
    const faviconCategory = getGreyLabelOptions().faviconCategory
    const faviconPath = `/favicons/${faviconCategory}`

    const appleLinks = ['180x180', '152x152', '144x144', '120x120', '114x114', '76x76', '72x72', '60x60', '57x57'].map((size) => {
      return createElement('link', { rel: 'apple-touch-icon', sizes: size, href: `${faviconPath}/apple-touch-icon-${size}.png` })
    })
    const faviconLinks = ['96x96', '32x32', '16x16'].map((size) => {
      return createElement('link', { rel: 'icon', type: 'image/png', sizes: size, href: `${faviconPath}/favicon-${size}.png` })
    })
    const otherLinks = [
      createElement('link', { rel: 'shortcut icon', href: `${faviconPath}/favicon.ico` }),
      createElement('link', { rel: 'icon', type: 'image/png', sizes: '192x192', href: `${faviconPath}/android-chrome-192x192.png` }),
      createElement('link', { rel: 'manifest', href: `${faviconPath}/manifest.json` }),
      createElement('link', { rel: 'mask-icon', href: `${faviconPath}/safari-pinned-tab.svg`, color: '#5bbad5' }),
      createElement('meta', { name: 'msapplication-TileColor', content: '#da532c' }),
      createElement('meta', { name: 'msapplication-TileImage', content: `${faviconPath}/mstile-144x144.png` }),
      createElement('meta', { name: 'theme-color', content: '#ffffff' })
    ]

    const head = document.getElementsByTagName('head')[0]
    const links = [...appleLinks, ...faviconLinks, ...otherLinks]
    links.forEach((link) => {
      head.appendChild(link)
    })
  }

  render () {
    return null
  }
}
