import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import IpadNavigation from 'Navigation/IpadNavigation'

import {TaxPaymentApproveFormView} from 'Reports/views/TaxPaymentReport/TaxPaymentApproveFormView/containers/Smartphones'

import getLeftComponent from 'Navigation/helpers/linkAccordance'

export class TaxPaymentApproveForm extends Component {
  static propTypes = {
    t: PropTypes.func,
    previousLink: PropTypes.string
  }

  render () {
    const {t, previousLink} = this.props
    return (
      <IpadNavigation {...this.props} title={t('title').s} backLink>
        {getLeftComponent('report', previousLink)}
        <TaxPaymentApproveFormView {...this.props} />
      </IpadNavigation>
    )
  }
}

function mapStateToProps (state) {
  return {
    t: state.i18n.get('app', 'views', 'Reports', 'TaxPaymentReport', 'TaxPaymentApproveForm'),
    previousLink: state.appInfo.previousLink
  }
}

export default connect(mapStateToProps)(TaxPaymentApproveForm)
