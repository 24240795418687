import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import { isEqual } from 'lodash'

import Input from 'shared/components/Input/Input'
import PersonalNumberInput from 'shared/components/Input/PersonalNumber'
import DropDown from 'shared/components/Dropdown/Dropdown'

import { createSelect } from 'shared/factories/createSelect'

import * as AccountTypes from 'User/Settings/constants/AccountTypes'

import './index.scss?global'

class OnboardingFields extends Component {
  static propTypes = {
    setErrors: PropTypes.func.isRequired,
    makeFieldsTouched: PropTypes.func.isRequired,
    currentErrors: PropTypes.object,
    tSettings: PropTypes.func.isRequired
  }

  componentWillReceiveProps (nextProps) {
    if (!isEqual(this.props.currentErrors, nextProps.currentErrors)) {
      this.props.setErrors(nextProps.currentErrors)
      this.props.makeFieldsTouched(nextProps.currentErrors)
    }
  }

  getAccountTypesList () {
    return createSelect(
      [AccountTypes.BANKGIRO, AccountTypes.PLUSGIRO, AccountTypes.BANK_ACCOUNT],
      this.props.tSettings('selects', 'accountType')
    )
  }

  render () {
    const { tSettings } = this.props
    return (
      <div className='capcito-onboarding-form'>
        <Field
          placeholder={tSettings('companyName').s}
          component={InputWrapper}
          name='companyName'
        />
        <Field
          placeholder={tSettings('email').s}
          component={InputWrapper}
          name='email'
        />
        <Field
          placeholder={tSettings('personalNumber').s}
          component={PersonalNumberWrapper}
          name='personalNumber'
        />
        <Field
          hint={tSettings('accountType').s}
          component={DropDownWrapper}
          name='accountType'
          options={this.getAccountTypesList()}
          emptyOption={tSettings('emptyOptions', 'accountType').s}
        />
        <Field
          placeholder={tSettings('accountNumber').s}
          component={InputWrapper}
          name='accountNumber'
        />
      </div>
    )
  }
}

const InputWrapper = ({input, meta, ...props}) =>
  <Input {...input} {...meta} {...props} />

InputWrapper.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object
}

const PersonalNumberWrapper = ({input, meta, ...props}) =>
  <PersonalNumberInput {...input} {...meta} {...props} />

PersonalNumberWrapper.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object
}

const DropDownWrapper = ({input, meta, ...props}) =>
  <DropDown {...input} {...meta} {...props} />

DropDownWrapper.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object
}

export default OnboardingFields
