import '../styles/index.scss?global'

import Smartphones from 'More/views/MoreView/containers/Smartphones'
import Tablets from 'More/views/MoreView/containers/Tablets'

import {getCurrentDeviceView} from 'shared/helpers/utils'

const MoreView = getCurrentDeviceView(Smartphones, Tablets)

export default MoreView
