import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import IpadNavigation from 'Navigation/IpadNavigation'
import Employees from 'Employees/views/EmployeesListView/containers/Smartphones'
import { EmployeeView as Employee } from 'Employees/views/EmployeeView/containers/Smartphones'

class EmployeeView extends Component {
  static propTypes = {
    title: PropTypes.string
  }

  render () {
    const { title } = this.props
    return (
      <IpadNavigation {...this.props} title={title} backLink='/employees'>
        <Employees />
        <Employee {...this.props} />
      </IpadNavigation>
    )
  }
}

const mapStateToProps = (state) => {
  const { firstName, lastName } = state.employees.employee
  return ({
    title: firstName || lastName ? `${firstName} ${lastName}` : ''
  })
}
export default connect(mapStateToProps)(EmployeeView)
