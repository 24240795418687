import './ResetPasswordView.scss?global'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { reduxForm, Field } from 'redux-form'

// Components
import Button from 'shared/components/Button/Button'
import Input from 'shared/components/FormInput'
import FullscreenSpinner from 'User/shared/containers/FullscreenSpinner/FullscreenSpinner'
import { Container, UpperContent, BottomContent } from 'User/Auth/components/LoginContainer'

// Actions
import { checkResetLinkAction, resetPasswordAction } from 'User/ResetPassword/actions/resetPassword'

import { getBackgroundPath } from 'User/shared/helpers'
const backgroundPath = getBackgroundPath()

class ResetPasswordView extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func,
    location: PropTypes.object,
    t: PropTypes.func,
    resetPassword: PropTypes.func
  };

  constructor (props) {
    super(props)
    this.submitHandler = this.submitHandler.bind(this)
  }

  submitHandler (values) {
    const { location: { query: { token } }, resetPassword } = this.props
    resetPassword({...values, resetToken: token})
  }

  componentDidMount () {
    // const { location: { query: { token } }, checkLink } = this.props
    // checkLink(token)
  }

  render () {
    const {
      handleSubmit,
      t
    } = this.props

    return (
      <Container background={backgroundPath}>
        <UpperContent withoutShadow navyText />
        <BottomContent notTransparent>
          <form onSubmit={handleSubmit(this.submitHandler)} className='login__form'>
            <div className='login__form__fields'>
              <Field name='password' component={Input} view='white' type='password' placeholder={t('fields', 'password').s} />
              <Field name='passwordConfirmation' component={Input} view='white' type='password' placeholder={t('fields', 'passwordConfirmation').s} />
            </div>
            <div className='login__form__send'>
              <Button view='transparent-white' type='submit'>{t('buttons', 'resetPassword').s}</Button>
            </div>
          </form>
        </BottomContent>
        <FullscreenSpinner />
      </Container>
    )
  }
}

function mapStateToProps (state) {
  return {
    t: state.i18n.get('app', 'views', 'ResetPasswordView')
  }
}

function mapDispatchToProps (dispatch) {
  return {
    checkLink: (resetToken) => { dispatch(checkResetLinkAction(resetToken)) },
    resetPassword: (values) => { dispatch(resetPasswordAction(values)) }
  }
}

function validate (values) {
  let errors = {}
  if (!values.password || values.password.length < 4 || values.password.length > 28) {
    errors.password = true
  }
  if (values.password !== values.passwordConfirmation) {
    errors.passwordConfirmation = true
  }

  return errors
}

export default reduxForm({
  form: 'reset-password',
  validate
})(connect(mapStateToProps, mapDispatchToProps)(ResetPasswordView))
