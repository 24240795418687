import './styles/index.scss?global'

import Smartphones from 'Overview/Overview/containers/Smartphones'
import Tablets from 'Overview/Overview/containers/Tablets'

import {getCurrentDeviceView} from 'shared/helpers/utils'

const OverviewView = getCurrentDeviceView(Smartphones, Tablets)

export default OverviewView
