import './styles/index.scss?global'

import Smartphones from 'Overview/FinanceVisualization/containers/Smartphones'
import Tablets from 'Overview/FinanceVisualization/containers/Tablets'

import {getCurrentDeviceView} from 'shared/helpers/utils'

const FinanceVisualizationView = getCurrentDeviceView(Smartphones, Tablets)

export default FinanceVisualizationView
