import { bind } from 'redux-effects'
import { createAction } from 'redux-actions'
import { routerActions } from 'react-router-redux'
import { decamelizeKeys } from 'humps'

import {fetch2 as fetch} from 'shared/helpers/fetch'

import { prepareUserObject } from 'User/Auth/actions/auth'
import { showMessageBoxWithParamsAction } from 'MessageBox/actions/messageBox'

import { GET_USER_INFO, UPDATE_USER_INFO } from 'User/Settings/constants/Api'
import { USER_SETTINGS_ADD } from 'User/Settings/constants/UserSettings'
import { personalNumberMaskProps } from 'mrshoebox-ui-components/src/helpers/countrySpecific'

const updateUserSettings = createAction(USER_SETTINGS_ADD)

export function getAccountSettingsAction () {
  return bind(fetch(GET_USER_INFO), processResponse, processError)
}

function processResponse (response) {
  const user = prepareUserObject(response.value)
  return updateUserSettings({
    mainPageHintsWereShown: response.value.tutorial_passed,
    user
  })
}

export function updateAccountSettingsAction (userSettings) {
  const fixedData = {
    ...userSettings,
    personalNumber: userSettings.personalNumber ? personalNumberMaskProps.normalize(userSettings.personalNumber) : null
  }
  return bind(updateSettings(decamelizeKeys(fixedData)), updateAccountSettingsSuccess, processError)
}

export function updateSettings (data) {
  return fetch(UPDATE_USER_INFO, {}, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      ...data
    })
  })
}

export function updateAccountSettingsSuccess (response) {
  return (dispatch, getState) => {
    const t = getState().i18n.get('app', 'shared')

    if (!response.value.success) {
      const errors = Object.keys(response.value.errors).map((errorProperty) => {
        return response.value.errors[errorProperty].map((errorMessage) => {
          return translateError(t, errorProperty, errorMessage)
        }).join('\n\n')
      }).join('\n\n')

      return dispatch(showMessageBoxWithParamsAction('', errors))
    } else {
      return dispatch([getAccountSettingsAction(), routerActions.goBack()])
    }
  }
}

function translateError (t, property, error) {
  const translatedProperty = t('fromServer', 'fields', property).s
  const translatedMessage = t('fromServer', 'errors', error).s

  return `${translatedProperty} ${translatedMessage}`
}

function processError (response) {
  console.log('userSettings error', response)
}
