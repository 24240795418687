import { bind } from 'redux-effects'
import { createAction } from 'redux-actions'
import { routerActions } from 'react-router-redux'
import { getCurrencyName } from 'shared/helpers/addNamesForIdProps'
import { showNavbarSpinnerAction, hideNavbarSpinnerAction } from 'shared/actions/navbarSpinner'
import { showMessageBoxWithParamsAction } from 'MessageBox/actions/messageBox'
import { bulkUpdateTimesheetsAction } from 'Timesheet/actions/timesheetsList'
import { bulkUpdateMileageReportsAction } from 'MileageReports/actions/mileageReports'
import { personalNumberMaskProps, country } from 'mrshoebox-ui-components/src/helpers/countrySpecific'
import moment from 'moment'

import { destroy, getFormValues, change } from 'redux-form'
import { omit, isEmpty, get } from 'lodash'
import { camelizeKeys } from 'humps'

import { delay } from 'shared/helpers/utils'

import { fetch2 as fetch } from 'shared/helpers/fetch'

import { INVOICE_UPDATE_PROPS, INVOICE_CLEAR_PROPS, INVOICE_SHOW_TOTALS_SPINNER, INVOICE_HIDE_TOTALS_SPINNER } from '../../shared/constants/ActionTypes'
import { INVOICE_SAVE, INVOICE_GET, INVOICE_GET_TOTALS } from '../../shared/constants/Api'
import { INVOICE_SETTINGS } from 'User/Settings/constants/Api'

import {PRIVATE_PERSON} from '../../shared/constants/PersonType'

// Bridges between iOS and Android apps
import { closeExtensionWindow } from 'appBridge/notificators/extension'

// Constants
import { CREDIT_INVOICE } from '../../shared/constants/InvoiceTypes'
import { REGULAR_VAT, EU_VAT, INVERTED_VAT, NO_VAT } from '../../shared/constants/VatTypes'

import { loadCollectorInvoiceInfo } from 'MarketPlace/Collector/actions'
import { validateKivraInvoiceSending } from './invoiceSend'

const invoiceUpdateProps = createAction(INVOICE_UPDATE_PROPS)
const invoiceClearProps = createAction(INVOICE_CLEAR_PROPS)

export const showTotalsSpinner = createAction(INVOICE_SHOW_TOTALS_SPINNER)
export const hideTotalsSpinner = createAction(INVOICE_HIDE_TOTALS_SPINNER)

export function invoiceUpdatePropAction (field, value, shouldGoBack = true) {
  return (dispatch, getState) => {
    const prop = {[field]: value}
    const invoice = getFormValues('invoice-form')(getState())

    let actions = []
    if (field === 'customer' && value.customerType !== PRIVATE_PERSON) {
      actions.push(updateCustomerVatNumber(value))
    }
    if (field === 'customer' && value.daysForPayment && invoice.invoiceDate) {
      const dueDate = moment(invoice.invoiceDate, 'YYYY-MM-DD')
        .add(value.daysForPayment, 'days')
        .format('YYYY-MM-DD')
      prop.dueDate = dueDate
      actions.push(change('invoice-form', 'dueDate', dueDate))
    }
    return dispatch([
      invoiceUpdateProps(prop),
      shouldGoBack ? routerActions.goBack() : null,
      ...actions
    ])
  }
}

export function updateCustomerVatNumber (customer) {
  if (customer.VAT_number) {
    return change('invoice-form', 'customerVatNumber', customer.VAT_number)
  }
  // TODO change this to country specific
  if (customer.org_number && country === 'se') {
    return change('invoice-form', 'customerVatNumber', `SE${customer.org_number}01`)
  }
}

export function invoiceUpdatePropsAction (data) {
  return invoiceUpdateProps(data)
}

export function setInitialInvoicePropsAction () {
  return bind(fetch(INVOICE_SETTINGS), setInitialInvoiceProps)
}

export function setInitialCreditNotePropsAction (id) {
  return [
    showNavbarSpinnerAction(),
    bind(fetch(INVOICE_GET(id)), setInitialCreditNotePropsProcess.bind(this, id)),
    bind(fetch(INVOICE_SETTINGS), setInitialCreditNoteNumberPropProcess)
  ]
}

function setInitialCreditNoteNumberPropProcess (response) {
  return invoiceUpdateProps({invoiceNumber: response.value.next_invoice_number})
}

function setInitialCreditNotePropsProcess (invoiceId, response) {
  return (dispatch, getState) => {
    const { userSettings, invoiceSettings, userSettings: { defaultCurrency: { id } } } = getState()
    const customer = get(getState(), 'customerInvoices.invoice.customer')

    const currencyName = getCurrencyName(userSettings, id)

    const preparedValues = prepareInvoiceGetFields(response.value, userSettings)
    const dueDays = customer && customer.daysForPayment ? customer.daysForPayment : invoiceSettings.default_invoice_days
    const values = {
      ...omit(preparedValues, ['id', 'status', 'invoiceNumber', 'invoiceDate', 'dueDate']),
      invoiceType: CREDIT_INVOICE,
      invoiceDate: moment().format('YYYY-MM-DD'),
      dueDate: moment().add(dueDays, 'days').format('YYYY-MM-DD'),
      currencyId: id,
      currencyName,
      originalInvoiceId: invoiceId
    }
    return dispatch([invoiceUpdateProps(values), hideNavbarSpinnerAction()])
  }
}

function setInitialInvoiceProps (response) {
  return (dispatch, getState) => {
    const invoiceSettings = response.value
    const { userSettings, userSettings: { defaultCurrency: { id } } } = getState()
    const currencyName = getCurrencyName(userSettings, id)
    const customer = get(getState(), 'customerInvoices.invoice.customer')

    const dueDays = customer && customer.daysForPayment ? customer.daysForPayment : invoiceSettings.default_invoice_days
    const fields = {
      invoiceNumber: invoiceSettings.next_invoice_number,
      invoiceDate: moment().format('YYYY-MM-DD'),
      dueDate: moment().add(dueDays, 'days').format('YYYY-MM-DD'),
      lateFeePercentage: invoiceSettings.default_late_fee_percentage,
      invoiceFee: invoiceSettings.default_invoice_fee,
      reminderFee: invoiceSettings.default_reminder_fee,
      currencyId: id,
      currencyName
    }

    return dispatch(invoiceUpdateProps(fields))
  }
}

export function saveInvoiceAction () {
  return (dispatch, getState) => {
    const { invoices: { customerInvoices: { invoice } } } = getState()
    invoice.customer_id = invoice.customer.id

    const updateRelationsActions = updateInvoicedRelationsAction(invoice)
    invoice.invoiceRows = invoice.invoiceRows.map(row => omit(row, ['meta']))

    // here save timesheets/driverlogs
    if (invoice.id) {
      return dispatch([
        showNavbarSpinnerAction(),
        updateRelationsActions,
        bind(updateInvoice(invoice), invoiceProcess.bind(this, false), processError)
      ])
    } else {
      return dispatch([
        showNavbarSpinnerAction(),
        updateRelationsActions,
        bind(pushInvoice(invoice), invoiceProcess.bind(this, true), processError)
      ])
    }
  }
}

function updateInvoicedRelationsAction (invoice) {
  const updateAction = (type, relationUpdateAction) => {
    const relations = invoice.invoiceRows.filter(row => row.meta && row.meta.type === type)
    if (relations.length > 0) {
      return relationUpdateAction(relations.map(r => ({ id: r.meta.id, exported: true })))
    }
  }
  const updateTimesheets = updateAction('timesheet', bulkUpdateTimesheetsAction)
  const updateDriverlogs = updateAction('driverlog', bulkUpdateMileageReportsAction)
  return [updateTimesheets, updateDriverlogs].filter(x => x)
}

function pushInvoice (data) {
  return fetch(INVOICE_SAVE, {}, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: createRequestBody(data)
  })
}

function updateInvoice (data) {
  return fetch(INVOICE_GET(data.id), {}, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json'
    },
    body: createRequestBody(data)
  })
}

export function createRequestBody (data) {
  let requestPayload = {
    invoice: {
      invoice_number: data.invoiceNumber,
      invoice_type: data.invoiceType,
      VAT_type: data.vatType,
      invoice_date: data.invoiceDate,
      pay_due: data.dueDate,
      customer_id: data.customer.id,
      customer_VAT_number: data.customerVatNumber,
      currency_id: data.currencyId,
      message: data.message,

      late_fee_percentage: data.lateFeePercentage || 0,
      invoice_fee: data.invoiceFee || 0,
      discount_amount: data.discountAmount || 0,
      discount_percentage: data.discountPercentage || 0,
      reminder_fee: data.reminderFee || 0,
      invoice_rows_attributes: transformInvoiceRowsForRequest(data.invoiceRows)
    }
  }

  if (data.invoiceType === CREDIT_INVOICE) requestPayload.invoice.original_invoice_id = data.originalInvoiceId

  if (!isEmpty(data.rotRutObject)) {
    const { rotRutType, personalNumber, buildingNumber, percentage } = data.rotRutObject
    requestPayload.invoice = {
      ...requestPayload.invoice,
      rot_rut_object_attributes: {
        rot_rut_type: rotRutType,
        personal_number: personalNumberMaskProps.normalize(personalNumber) || '',
        building_number: buildingNumber || '',
        percentage: percentage
      }
    }
  }

  if (data.euVat) {
    requestPayload.invoice.VAT_type = EU_VAT
  } else if (data.invertedVat) {
    requestPayload.invoice.VAT_type = INVERTED_VAT
  } else if (data.noVat) {
    requestPayload.invoice.VAT_type = NO_VAT
  } else {
    requestPayload.invoice.VAT_type = REGULAR_VAT
  }

  return JSON.stringify(requestPayload)
}

function transformInvoiceRowsForRequest (rows) {
  return rows.map((row) => ({
    amount_of_units: row.amountOfUnits,
    price: row.price,
    unit: row.unit,
    article_type: row.articleType,
    VAT_percentage: row.vatPercentage,
    description: row.description,
    subdescription: row.subdescription,
    rot_rut: Boolean(row.rotRut),
    article_id: row.articleId
  }))
}

function invoiceProcess (isNewInvoice = false, response) {
  closeExtensionWindow()

  if (!isNewInvoice) {
    return [hideNavbarSpinnerAction(), invoiceClearAction(), routerActions.goBack()]
  } else {
    const invoiceId = response.value.entity && response.value.entity.id
    return [hideNavbarSpinnerAction(), invoiceClearAction(), redirectToInvoice(invoiceId)]
  }
}

function redirectToInvoice (invoiceId) {
  return async (dispatch) => {
    dispatch(routerActions.go(-2))
    await delay(100)

    const pathname = window.location.pathname
    if (pathname === '/') {
      dispatch(routerActions.push('/invoices'))
    } else {
      dispatch(routerActions.replace('/invoices'))
    }
    await delay(100)

    dispatch(routerActions.push(`/invoices/${invoiceId}`))
  }
}

function processError (response) {
  return (dispatch, getState) => {
    let actions = [hideNavbarSpinnerAction()]
    if (response.status === 422) {
      const t = getState().i18n.get('app', 'views', 'Invoices', 'InvoiceCreateView', 'errors')
      actions.push(showMessageBoxWithParamsAction('', t('alreadyUsed').s))
    }

    return dispatch(actions)
  }
}

export function invoiceGetAction (id) {
  return [
    showNavbarSpinnerAction(),
    bind(fetch(INVOICE_GET(id)), invoiceGetProcess)
  ]
}

export function prepareInvoiceGetFields (values, settings) {
  // TODO: remove
  // const getInvoiceStatus = (invoice) => {
  //   // because of beautiful API
  //   if (invoice.status === 2 && moment(invoice.dueDate).isBefore(moment())) {
  //     return 4
  //   } else {
  //     return invoice.status
  //   }
  // }

  const currencyName = getCurrencyName(settings, values.currency_id)
  let invoice = {
    id: values.id,
    status: values.status,
    invoiceNumber: values.invoice_number,
    invoiceType: values.invoice_type,
    discountAmount: values.discount_amount, // TODO: DRY, remove that
    discountPercentage: values.discount_percentage,
    sourceDocumentId: values.source_document_id,
    totals: {
      preTotal: values.pre_total,
      preTotalVat: values.pre_total_VAT,
      total: values.rounded_total,
      totalVat: values.total_VAT,
      rotRut: values.rot_rut_deduction,
      discountAmount: values.discount_amount,
      lateFeeAmount: values.late_fee_amount,
      roundingAmount: values.rounding
    },
    message: values.message,
    customer: values.receiver ? {
      name: values.receiver.name,
      id: values.receiver.id,
      customerType: values.receiver.customer_type,
      email: values.receiver.email
    } : {},
    invoiceDate: values.invoice_date,
    dueDate: values.due_date,
    deliveryDate: values.delivery_date,
    sentAt: values.sent_at,
    paidAt: values.paid_date,
    lateFeePercentage: values.late_fee_percentage,
    invoiceFee: values.invoice_fee,
    reminderFee: values.reminder_fee,
    customerVatNumber: values.customer_VAT_number || values.receiver.VAT_number, // there is a bug in invoice service, sometimes returnes null in customer_VAT_number for new companies
    invertedVat: values.VAT_type === INVERTED_VAT,
    noVat: values.VAT_type === NO_VAT,
    euVat: values.VAT_type === EU_VAT,
    currencyId: values.currency_id,
    currencyName,
    collectorStatus: values.collector_status,
    collectorInfo: {}, // to clear previous loaded invoice field, since this field will not be refilled for credit invoice.
    originalInvoiceId: values.original_invoice_id,
    invoiceRows: values.invoice_rows.map((row) => ({
      amountOfUnits: row.amount_of_units,
      articleType: row.article_type,
      articleId: row.article_id,
      unit: row.unit,
      description: row.description,
      subdescription: row.subdescription,
      price: row.price,
      vatPercentage: row.VAT_percentage,
      sumExVat: row.price * Number(row.amount_of_units),
      sumVat: Number(row.amount_of_units) * row.price * row.VAT_percentage / 100,
      rotRut: row.rot_rut,
      currencyName: currencyName
    }))
  }
  if (values.rot_rut_object) {
    const percentage = values.rot_rut_object.percentage
    invoice.rotRutObject = {
      rotRutType: values.rot_rut_object.rot_rut_type,
      personalNumber: values.rot_rut_object.personal_number,
      buildingNumber: values.rot_rut_object.building_number,
      percentage
    }
    invoice.rotRutObject.invoiceFinalTotalAmount = invoice.totals.total // TODO: remove, bad architecture
  } else {
    invoice.rotRutObject = {}
  }

  return invoice
}

function invoiceGetProcess (response) {
  const { invoiceType, originalInvoiceId } = camelizeKeys(response.value)

  if (invoiceType === CREDIT_INVOICE && originalInvoiceId) {
    return [
      showNavbarSpinnerAction(),
      bind(fetch(INVOICE_GET(originalInvoiceId)), creditInvoiceProcess.bind(this, response))
    ]
  }

  return async (dispatch, getState) => {
    let values = prepareInvoiceGetFields(response.value, getState().userSettings)
    if (values.collectorStatus) {
      const collectorResponse = await loadCollectorInvoiceInfo(response.value.id)
      const { status, recoursedOn, factoringType, decisionReasons } = camelizeKeys(collectorResponse.data)
      values.collectorInfo = {
        status,
        recoursedOn,
        factoringType,
        decisionReasons
      }
    }

    dispatch([invoiceUpdateProps(values), hideNavbarSpinnerAction()])

    if (country === 'se') {
      try {
        const { data } = await validateKivraInvoiceSending(response.value.id)
        dispatch([invoiceUpdateProps({ kivraValidation: data })])
      } catch (e) {
        console.log('something went wrong:', e)
      }
    }
  }
}

function creditInvoiceProcess (creditInvoice, originalInvoice) {
  return async (dispatch, getState) => {
    let values = prepareInvoiceGetFields(creditInvoice.value, getState().userSettings)
    if (country === 'se') {
      try {
        const { data } = await validateKivraInvoiceSending(values.id)
        values.kivraValidation = data
      } catch (e) {
        console.log('something went wrong:', e)
      }
    }
    values.originalInvoice = { id: originalInvoice.value.id, collectorStatus: originalInvoice.value.collector_status }
    return dispatch([invoiceUpdateProps(values), hideNavbarSpinnerAction()])
  }
}

export function invoiceClearAction () {
  return [invoiceClearProps(), destroy('invoice-form')]
}

export function getAmountFromPercent (total, percent) {
  return ((Number(total) / 100) * parseFloat(percent)).toFixed(2)
}

export function getPercentFromAmount (total, amount) {
  return ((Number(amount) * 100) / Number(total)).toFixed(2)
}

export function showInvoiceNumberHelpAction (message) {
  return showMessageBoxWithParamsAction('', message)
}

export function updateInvoiceTotalsAction (values) {
  return [
    showTotalsSpinner(),
    bind(getTotalsRequest(values), updateTotals, () => hideTotalsSpinner())
  ]
}

export function getTotalsRequest (data) {
  return fetch(INVOICE_GET_TOTALS, {}, {
    method: 'PUT',
    headers: {
      'content-type': 'application/json'
    },
    body: createRequestBody(data)
  })
}

function updateTotals (response) {
  const values = response.value
  return [
    hideTotalsSpinner(),
    invoiceUpdateProps({
      totals: {
        preTotal: values.pre_total,
        preTotalVat: values.pre_total_VAT,
        total: values.rounded_total,
        totalVat: values.total_VAT,
        rotRut: values.rot_rut_deduction,
        lateFeeAmount: values.late_fee_amount,
        discountAmount: values.discount_amount,
        roundingAmount: values.rounding
      }
    })
  ]
}
