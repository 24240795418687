import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { arrayPush } from 'redux-form'

import IpadNavigation from 'Navigation/IpadNavigation'
import Employees from 'Employees/views/EmployeesListView/containers/Smartphones'
import { BenefitsView as Benefits } from 'Employees/views/BenefitsView/containers/Smartphones'

class EmployeeView extends Component {
  static propTypes = {
    addNewBenefit: PropTypes.func.isRequired
  }

  render () {
    const { t, addNewBenefit } = this.props
    const actions = [
      { icon: 'icon-add', callback: addNewBenefit }
    ]
    return (
      <IpadNavigation {...this.props} title={t('navbar', 'title').s} actions={actions}>
        <Employees />
        <Benefits {...this.props} />
      </IpadNavigation>
    )
  }
}

const mapStateToProps = (state) => ({
  t: state.i18n.get('app', 'views', 'Employees', 'BenefitsView')
})

const mapDispatchToProps = (dispatch) => ({
  addNewBenefit: () => dispatch(arrayPush('employee', 'benefits', { title: '', value: '' }))
})

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeView)
