import PropTypes from 'prop-types'
import React, { Component } from 'react'

// Containers
import InvoiceCreateContainer from './Common'

// Components
import IpadNavigation from '../../../../../Navigation/IpadNavigation'
import InvoiceCreate from '../components/InvoiceCreate'

// Helpers
import getLeftComponent from 'Navigation/helpers/linkAccordance'

export class InvoiceCreateView extends Component {
  static propTypes = {
    invoiceClear: PropTypes.func,
    navbarTitle: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
    previousLink: PropTypes.string
  }

  render () {
    const {invoiceClear, navbarTitle, previousLink} = this.props
    const Events = getLeftComponent('invoice', previousLink)

    return (
      <IpadNavigation {...this.props} withoutMaxWidth title={navbarTitle} onClickBack={invoiceClear}>
        <Events {...this.props} ipad />
        <InvoiceCreate {...this.props} />
      </IpadNavigation>
    )
  }
}

export default InvoiceCreateContainer(InvoiceCreateView)
