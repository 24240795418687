import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { isHiddenRelease, isKivraFeatureAvailable, isOpenPaymentsAvailable } from 'shared/helpers/releaseHelpers'

import Navigation from 'Navigation/Navigation'
import Integration from 'Integrations/Integrations/components/Integration'

import { KIVRA_TENANT_TYPE, KIVRA_MAIL_TYPE, OPEN_PAYMENTS_TYPE } from 'Integrations/Integrations/constants/integrationsTypes'

import { loadIntegrations } from 'Integrations/Integrations/actions'

class IntegrationsView extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    integrations: PropTypes.array.isRequired,
    loadIntegrations: PropTypes.func.isRequired
  }

  componentDidMount () {
    this.props.loadIntegrations()
  }

  renderKivraMailIntegration () {
    if (isHiddenRelease()) {
      // Should be visible only on stage for now.
      return (
        <Integration
          description={this.props.t('List', 'kivraDescription').s}
          name='Kivra'
          logoName='kivra'
          link='/integrations/kivra'
          disabled={this.isIntegrationActive(KIVRA_MAIL_TYPE)}
          activatedText={this.props.t('List', 'activated').s}
        />
      )
    }
  }

  isIntegrationActive (type) {
    return Boolean(this.props.integrations.find(i => i.type === type && i.isActive))
  }

  render () {
    const { t } = this.props
    return (
      <React.Fragment>
        {isKivraFeatureAvailable() &&
          <React.Fragment>
            {this.renderKivraMailIntegration()}
            <Integration
              description={t('List', 'kivraTenantDescription').s}
              name='Kivra'
              logoName='kivra'
              link='/integrations/kivra-tenant'
              disabled={this.isIntegrationActive(KIVRA_TENANT_TYPE)}
              activatedText={t('List', 'activated').s}
            />
          </React.Fragment>
        }
        {isOpenPaymentsAvailable() &&
          <Integration
            description={t('List', 'openPaymentsDescription').s}
            name='Open Payments'
            logoName='open-payments-logo'
            link='/integrations/open-payments'
            logoClass='open-payments-logo'
            disabled={this.isIntegrationActive(OPEN_PAYMENTS_TYPE)}
            activatedText={t('List', 'activated').s}
          />
        }

      </React.Fragment>
    )
  }
}

function mapStateToProps (state) {
  return {
    t: state.i18n.get('app', 'views', 'Integrations'),
    integrations: state.integrations.integrationsList
  }
}

function mapDispatchToProps (dispatch) {
  return {
    loadIntegrations: () => dispatch(loadIntegrations())
  }
}

const IntegrationsViewWithNavigation = (props) => {
  return (
    <Navigation title={props.t('navbar', 'title').s} className='integrations' backLink='/more' forceBackLink>
      <IntegrationsView {...props} />
    </Navigation>
  )
}

export const connectedIntegrationsView = connect(mapStateToProps, mapDispatchToProps)(IntegrationsView)
export default connect(mapStateToProps, mapDispatchToProps)(IntegrationsViewWithNavigation)
