import { createAction } from 'redux-act'
import axios from 'api-client-connector'
import { camelizeKeys, decamelizeKeys } from 'humps'
import { omitBy, isNil } from 'lodash'
import { getCurrentClientCompany } from 'api-client-connector/utils'
import { SubmissionError } from 'redux-form'
import React from 'react'
import { routerActions } from 'react-router-redux'

import InProgress from 'MarketPlace/shared/components/InProgress'

import {
  KIVRA_CLIENTS_VALIDATE, KIVRA_CLIENTS_INTEGRATE,
  KIVRA_REGISTER_TENANT, KIVRA_TENANT_VALIDATE
} from '../constants/api'
import { COMPANY_INFO, UPDATE_USER_INFO } from 'User/Settings/constants/Api'

import { hideNavbarSpinnerAction, showSlowNavbarSpinnerAction } from 'shared/actions/navbarSpinner'
import {
  showElementInMessageBoxAction,
  closeMessageBoxAction,
  showMessageBoxWithParamsAction
} from 'MessageBox/actions/messageBox'
import { redirectToStripe } from 'MarketPlace/shared/actions'

export const kivraValidatedFieldsToStore = createAction('Kivra validated fields to store')

export const getValidateClientFunc = () => getValidatedFields(validateClientRequest)
export const getValidateTenantFunc = () => getValidatedFields(validateTenantRequest)

function getValidatedFields (validationRequest) {
  return async (dispatch) => {
    dispatch(showSlowNavbarSpinnerAction())
    const response = await validationRequest()
    const { initialValues, currentErrors } = processFields(response.data)
    dispatch(kivraValidatedFieldsToStore({ initialValues, currentErrors }))
    dispatch(hideNavbarSpinnerAction())

    return { initialValues, currentErrors }
  }
}

function processFields (fields) {
  // TODO: share this for capcito/collector/creditsafe/kivra
  const fieldsDetails = camelizeKeys(fields)
    .map(({fieldData, fieldName}) => ({
      name: fieldName,
      value: fieldData.value,
      errors: fieldData.errors
    }))

  const valueReducer = (result, field) => (camelizeKeys({ ...result, [field.name]: field.value }))
  const initialValues = fieldsDetails.reduce(valueReducer, {})
  const errorsReducer = (result, field) => (camelizeKeys({ ...result, [field.name]: field.errors.length ? field.errors : null }))
  const currentErrors = omitBy(fieldsDetails.reduce(errorsReducer, {}), isNil)

  return { initialValues, currentErrors }
}

const validateClientRequest = (data) => axios.post(KIVRA_CLIENTS_VALIDATE, data)
const validateTenantRequest = (data) => axios.post(KIVRA_TENANT_VALIDATE, data)

export function startIntegration (settings) {
  return async (dispatch, getState) => {
    const t = getState().i18n.get('app', 'views', 'Integrations', 'Kivra', 'userRegistration')
    dispatch(showSlowNavbarSpinnerAction())
    const { currentErrors } = await dispatch(updateSettingsForKivraClientAction(settings))
    if (!Object.keys(currentErrors).length) await dispatch(processIntegration(sendIntegrationRequest, t))
    dispatch(hideNavbarSpinnerAction())
    if (Object.keys(currentErrors).length) throw new SubmissionError(currentErrors)
  }
}

function updateSettingsForKivraClientAction (settings) {
  return async (dispatch) => {
    dispatch(showSlowNavbarSpinnerAction())

    let validationResult
    let errors = {}

    try {
      await updateCurrentCompanyInfo(settings)
    } catch (e) {
      dispatch(processError(e))
      errors = {
        ...errors,
        ...camelizeKeys(e.response.data.errors)
      }
      if (Object.keys(errors).length) return { currentErrors: camelizeKeys(errors) }
    }
    const validateClient = () => dispatch(getValidateClientFunc())
    validationResult = await validateClient()
    dispatch(hideNavbarSpinnerAction())
    return validationResult
  }
}

function updateCurrentCompanyInfo (settings) {
  let companyData = {
    client_company: {
      name: settings.name,
      VAT_number: settings.vATNumber
    }
  }

  const currentCompanyId = getCurrentClientCompany()

  return axios.patch(COMPANY_INFO + currentCompanyId + '?legacy_id=true', companyData)
}

const sendIntegrationRequest = () => axios.post(KIVRA_CLIENTS_INTEGRATE)

export function saveDataAndRedirectToStripe (values) {
  return async (dispatch) => {
    dispatch(showSlowNavbarSpinnerAction())
    let errors = {}
    if (!values.acceptedGdprAgreement) errors.acceptedGdprAgreement = true
    if (!values.acceptedGeneralAgreement) errors.acceptedGeneralAgreement = true
    if (!Object.keys(errors).length) {
      let { currentErrors } = await dispatch(updateSettingsForKivraTenantAction(values))
      errors = {
        ...errors,
        ...currentErrors
      }
    }

    if (!Object.keys(errors).length) await redirectToStripe()
    if (Object.keys(errors).length) throw new SubmissionError(errors)
  }
}

export function registerTenant (data, cameFromInvoiceId) {
  return async (dispatch, getState) => {
    const t = getState().i18n.get('app', 'views', 'Integrations', 'Kivra', 'tenantRegistration')

    await dispatch(processIntegration(() => registerTenantRequest(decamelizeKeys(data)), t, cameFromInvoiceId))
    dispatch(hideNavbarSpinnerAction())
  }
}

const registerTenantRequest = (data) => axios.post(KIVRA_REGISTER_TENANT, data)

function updateSettingsForKivraTenantAction (settings) {
  return async (dispatch) => {
    dispatch(showSlowNavbarSpinnerAction())

    let validationResult
    let errors = {}

    try {
      const userSettingsResponse = await updateUserNameInSettings(settings.userName)
      const { data: { errors: userSettingsErrors } } = userSettingsResponse

      if (userSettingsErrors && Object.keys(userSettingsErrors).length) errors = { userName: userSettingsErrors.name }
    } catch (e) {
      dispatch(processError(e))
    }

    try {
      await updateCurrentCompanyInfo(settings)
    } catch (e) {
      dispatch(processError(e))
      errors = {
        ...errors,
        ...camelizeKeys(e.response.data.errors)
      }
      if (Object.keys(errors).length) return { currentErrors: camelizeKeys(errors) }
    }
    const validateTenant = () => dispatch(getValidateTenantFunc())
    validationResult = await validateTenant()
    dispatch(hideNavbarSpinnerAction())
    return validationResult
  }
}

function processIntegration (request, t, cameFromInvoiceId) {
  return async (dispatch, getState) => {
    try {
      const response = await request()
      const closeHandler = () => {
        const urlToRedirect = cameFromInvoiceId
          ? `/invoices/${cameFromInvoiceId}/send_invoice?cameFrom=tenant-register`
          : '/integrations'
        dispatch([
          closeMessageBoxAction(),
          routerActions.push(urlToRedirect)
        ])
      }

      if (response.status === 201) {
        dispatch(showElementInMessageBoxAction(
          <InProgress
            closeHandler={closeHandler}
            buttonText={t('modal', 'button').s}
            customHeader={t('modal', 'customHeader').s}
          />,
          closeHandler
        ))
      }
    } catch (e) {
      if (e.response.status === 422) {
        dispatch(showMessageBoxWithParamsAction(null, t('errorCodes', '422').s))
      } else {
        const tShared = getState().i18n.get('app', 'shared', 'messages')
        dispatch(showMessageBoxWithParamsAction(null, tShared('unexpectedError', 'description').s))
      }
    }
  }
}

const updateUserNameInSettings = (name) => axios.patch(UPDATE_USER_INFO, { name })

function processError (response) {
  console.log('error', response)
  return hideNavbarSpinnerAction()
}
