import './ListItems.scss?global'

import PropTypes from 'prop-types'

import React, { Component } from 'react'
import { Link } from 'react-router'

import ListView from 'shared/components/ListView/ListView'
import getGreyLabelOptions from 'GreyLabel/helpers/greyLabel'

export function ListItem (info) {
  const {title, path, icon, value, highlighted, nativeLink, onClick, target} = info.data
  const { whiteLabel } = getGreyLabelOptions()
  const itemWhiteLabelClass = whiteLabel ? `listitems-item__icon--${whiteLabel}` : ''
  const renderReactLink = (
    <Link to={path} className='listitems-item'>
      <div className={`listitems-item__icon ${itemWhiteLabelClass}`}><span className={icon} /></div>
      <h3 className={`listitems-item__title ${highlighted ? 'listitems-item__title--red' : ''}`}>{title}</h3>
      <h3 className='listitems-item__amount'>{value && value}</h3>
      <h4 className='listitems-item__arrow'>
        <span className='icon-arrow-right' />
      </h4>
    </Link>
  )
  const renderNativeLink = (
    <a href={path} target={target} className='listitems-item'>
      <div className={`listitems-item__icon ${itemWhiteLabelClass}`}><span className={icon} /></div>
      <h3 className={`listitems-item__title ${highlighted ? 'listitems-item__title--red' : ''}`}>{title}</h3>
      <h4 className='listitems-item__arrow'>
        <span className='icon-arrow-right' />
      </h4>
    </a>
  )
  const renderBlock = (
    <div className='listitems-item' onClick={onClick}>
      <div className={`listitems-item__icon ${itemWhiteLabelClass}`}><span className={icon} /></div>
      <h3 className='listitems-item__title'>{title}</h3>
      <h4 className='listitems-item__arrow'>
        <span className='icon-arrow-right' />
      </h4>
    </div>
  )
  const render = () => {
    if (path && !nativeLink) {
      return renderReactLink
    } else if (path && nativeLink) {
      return renderNativeLink
    } else {
      return renderBlock
    }
  }
  return (
    render()
  )
}

export default class List extends Component {
  static propTypes = {
    data: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string,
    emptyListOptions: PropTypes.object
  };

  render () {
    const {data, title, emptyListOptions} = this.props
    return (
      <ListView data={data} template={ListItem} title={title} emptyListOptions={emptyListOptions} />
    )
  }
}
