import PropTypes from 'prop-types'
import { Component } from 'react'
import { connect } from 'react-redux'

import Visibility from 'visibilityjs'

import { updateNotificationBadgesAction, clearIntervalIdAction } from 'Notifications/actions/notificationBadges'

export class NotificationBadges extends Component {
  static propTypes = {
    isLoggedIn: PropTypes.bool,
    updateNotificationBadges: PropTypes.func,
    clearInterval: PropTypes.func
  }

  setTimer () {
    const { isLoggedIn, updateNotificationBadges, clearInterval } = this.props

    if (isLoggedIn && !this.intervalId) {
      updateNotificationBadges()

      this.intervalId = Visibility.every(60000, () => {
        updateNotificationBadges()
      })
    } else if (!isLoggedIn && !isNaN(this.intervalId)) {
      Visibility.stop(this.intervalId)
      clearInterval()
    }
  }

  componentDidMount () {
    this.setTimer()
  }

  componentDidUpdate () {
    this.setTimer()
  }

  render () {
    return null
  }
}

function mapStateToProps (state) {
  return {
    isLoggedIn: state.auth.isLoggedIn
  }
}

function mapDispatchToProps (dispatch) {
  return {
    updateNotificationBadges: () => { dispatch(updateNotificationBadgesAction()) },
    clearInterval: () => { dispatch(clearIntervalIdAction()) }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationBadges)
