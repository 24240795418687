import axios from 'api-client-connector'
import { omit } from 'lodash'
import { UPDATE_USER_INFO, COMPANY_INFO, INVOICE_SETTINGS } from 'User/Settings/constants/Api'
import { getCurrentClientCompany } from 'api-client-connector/utils'
import { personalNumberMaskProps, orgNumberMaskProps } from 'mrshoebox-ui-components/src/helpers/countrySpecific'

export function updateUserSettings (settings) {
  const userData = {
    name: settings.name,
    email: settings.email,
    phone: settings.phone,
    personal_number: settings.personalNumber ? personalNumberMaskProps.normalize(settings.personalNumber) : null
  }

  return axios.patch(UPDATE_USER_INFO, userData)
}

export function updateCurrentCompanyInfo (settings) {
  let companyData = {
    client_company: {
      name: settings.companyName,
      org_number: orgNumberMaskProps.normalize(settings.orgNumber),
      addresses_attributes: [
        {
          street: settings.street,
          postal_code: settings.postalCode,
          country: settings.country,
          city: settings.city,
          billable: true
        }
      ]
    }
  }

  if (settings.addressId) {
    companyData.client_company.addresses_attributes[0].id = settings.addressId
  }

  const currentCompanyId = getCurrentClientCompany()

  return axios.patch(COMPANY_INFO + currentCompanyId + '?legacy_id=true', companyData)
}

export function updateInvoiceSettings (formSettings, invoiceSettings) {
  if (invoiceSettings.isNewSettings) {
    let formData = new FormData()
    const preparedData = omit(invoiceSettings, ['isNewSettings'])
    for (let key in preparedData) {
      if (preparedData[key] !== undefined) {
        formData.append(`invoice_settings[${key}]`, preparedData[key])
      }
    }
    formData.append(`invoice_settings[account_type]`, formSettings.accountType)
    formData.append(`invoice_settings[account_number]`, formSettings.accountNumber)

    return axios.post(INVOICE_SETTINGS, formData)
  } else {
    const body = {
      invoice_settings: {
        account_number: formSettings.accountNumber,
        account_type: formSettings.accountType
      }
    }

    return axios.patch(INVOICE_SETTINGS, body)
  }
}
