import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'

// Actions
import { loadFinancialReportsListAction, cleanReportsListAction } from 'Reports/actions/reports'

// Components
import ListView from 'shared/components/ListView/ListView'
import ListItem from 'Reports/views/FinancialReport/FinancialReportsView/templates/ListItem'

class TodoReports extends Component {
  static propTypes = {
    loadList: PropTypes.func,
    cleanList: PropTypes.func,
    reportsList: PropTypes.arrayOf(PropTypes.object)
  }

  componentDidMount () {
    this.props.loadList([], 1)
  }

  componentWillUnmount () {
    this.props.cleanList()
  }

  render () {
    const { reportsList } = this.props
    return (
      <ListView data={reportsList} template={ListItem} />
    )
  }
}

function mapStateToProps (state) {
  return {
    reportsList: state.reports.listData
  }
}

function mapDispatchToProps (dispatch) {
  return {
    loadList: (currentList, pageToLoad) => { dispatch(loadFinancialReportsListAction(currentList, pageToLoad, { showUnread: true })) },
    cleanList: () => { dispatch(cleanReportsListAction()) }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TodoReports)
