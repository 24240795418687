import {bind} from 'redux-effects'
import {createAction} from 'redux-actions'

import { showNavbarSpinnerAction, hideNavbarSpinnerAction } from '../../../shared/actions/navbarSpinner'

import {fetch} from '../../../shared/helpers/fetch'

import {CUSTOMERS_LIST_UPDATE, CUSTOMERS_LIST_CLEAN} from '../constants/ActionTypes'
import {CUSTOMERS_LIST} from '../constants/Api'

const updateCustomersList = createAction(CUSTOMERS_LIST_UPDATE)
export const cleanCustomersListAction = createAction(CUSTOMERS_LIST_CLEAN)

export function loadCustomersListAction (currentList = [], filters = {}, pageToLoad) {
  return [showNavbarSpinnerAction(), bind(fetchCustomersList(filters, pageToLoad), processCustomersList.bind(null, currentList), processError)]
}

function fetchCustomersList (filters, pageToLoad = 1) {
  const newFilters = processFilters(filters)
  return fetch(CUSTOMERS_LIST, {...newFilters, p: pageToLoad})
}

function processFilters (filters) {
  let requestParams = {
    'filter[name]': filters.search,
    order: `${filters.orderBy} ${filters.orderType}`
  }
  if (filters.state) {
    requestParams['filter[state]'] = filters.state
  }
  if (filters.customerType !== 'all') {
    requestParams['filter[customer_type]'] = filters.customerType
  }

  return requestParams
}

function processCustomersList (oldList, response) {
  const customers = response.value.data
  const newCustomers = customers.map((customer) => {
    return {
      name: customer.name,
      id: customer.id,
      customerType: customer.customer_type,
      daysForPayment: customer.days_for_payment,
      org_number: customer.org_number,
      VAT_number: customer.VAT_number,
      email: customer.email
    }
  })

  const newList = oldList.concat(newCustomers)
  return [
    hideNavbarSpinnerAction(),
    updateCustomersList({
      listData: newList,
      hasNextPage: response.value.current_page < response.value.overall_pages,
      lastPageLoaded: response.value.current_page
    })
  ]
}

function processError (response) {
  console.log('customers processError', response)
}
