import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { range } from 'lodash'
import { routerActions } from 'react-router-redux'

import Icon from 'shared/components/Icon'
import CATEGORIES from 'WerkeysMainPage/constants'

import './index.scss?global'
import 'MarketPlace/Index/views/Index/styles/index.scss?global'

const settings = {
  piePieceFromIndex: 1,
  piePieceToIndex: 9,
  piePieceSvgViewBoxCoord: '0 0 113.81 130.36',
  piePieceFigureCoord: 'M2,77.61l54.71,52s3.57,2,6.35-.83,22.86-25.88,35.74-56.53c10.32-24.57,15.15-60.39,15-66S107.33,0,107.33,0,37.32.58,34.5.63a7.44,7.44,0,0,0-7,5.79C27,9.2,25.73,24.13,19,39.81S2.61,67.24,1.11,69.84C-.94,73.42.15,75.17,2,77.61Z'
  // piePieceFigureCoord: 'M411.092,227.3H344.311a3.862,3.862,0,0,0-3.87,3.374l-.893,5.358a24.431,24.431,0,0,0-7.145-1.092A25.333,25.333,0,0,0,320.6,282.67c-1.29,1.985-2.679,3.969-4.168,5.855a3.909,3.909,0,0,0,.3,5.061l47.332,47.332a3.87,3.87,0,0,0,5.656-.2,201.809,201.809,0,0,0,45.149-109.053,3.873,3.873,0,0,0-3.771-4.366'
}

// const pieceIconNames = ['cash-bag-dollar', 'user-home', 'user-multiple', 'jewelry-diamond', 'lighting-light-bulb', 'binocular', 'magic-wand', 'money-graph']
const textIconNames = ['purchasing-power', 'workspaces', 'community', 'best-practice', 'inspiration', 'assignments', 'gig-invoicing', 'accounting']
const textIconColors = ['#0d89ce', '#b54da6', '#fa2154', '#ff5252', '#f56696', '#049d5c', '#15cec4', '#32b4e1']
class CategoriesPie extends React.Component {
  static propTypes = {
    lang: PropTypes.string,
    goToVacancies: PropTypes.func,
    goToCreateInvoice: PropTypes.func,
    goToTodo: PropTypes.func
  }

  onClick = (elemIndex) => {
    const { goToVacancies, goToCreateInvoice, goToTodo } = this.props
    if (elemIndex === CATEGORIES.JOBS) {
      return goToVacancies
    } else if (elemIndex === CATEGORIES.ACCOUNTING) {
      return goToTodo
    } else if (elemIndex === CATEGORIES.SEND_INVOICE) {
      return goToCreateInvoice
    }
  }

  isUpperSide = (elem) => elem > 4

  render () {
    const { lang } = this.props
    return (
      <div className='categories-pie'>
        {range(settings.piePieceFromIndex, settings.piePieceToIndex).map((elem, i) => {
          return (
            <div className={`categories-pie__piece ${lang}`} key={i}>
              <svg viewBox={settings.piePieceSvgViewBoxCoord}>
                <g>
                  {/* <path className='categories-pie__piece__shape' d={settings.piePieceFigureCoord} /> */}
                  <path d={settings.piePieceFigureCoord} fill={textIconColors[i]} />
                  {/* <path d={settings.piePieceFigureCoord} transform='translate(-307.1 -227.3)' fill={textIconColors[i]} /> */}
                </g>
              </svg>
              <div onClick={this.onClick(elem)} className='categories-pie__piece__text'>
                <Icon type={`${textIconNames[i]}-${lang}`} />
              </div>
              <div className='categories-pie__piece__circle' style={{'background': textIconColors[i]}} >
                {/* <Icon type={pieceIconNames[i]} /> */}
              </div>
            </div>
          )
        })}
        <Icon className='resting-person' type='resting-person' />
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  t: state.i18n.get('app', 'views', 'WerkeysMainPage', 'categoriesPie'),
  lang: state.userSettings.lang
})

const mapDispatchToProps = (dispatch) => ({
  goToTodo: () => dispatch(routerActions.push('/todo')),
  goToVacancies: () => dispatch(routerActions.push('/vacancies')),
  goToCreateInvoice: () => dispatch(routerActions.push('/invoices/new'))
})

export default connect(mapStateToProps, mapDispatchToProps)(CategoriesPie)
