import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Link } from 'react-router'
import {connect} from 'react-redux'

// Components
import FormatNumber from 'shared/components/FormatNumber/FormatNumber'
import InputSwitcher from 'shared/components/InputSwitcher/InputSwitcher'

// Actions
import {projectPropChangeAction, projectInfoClearAction} from 'Projects/actions/project'

// Helpers
import { setTime } from 'shared/helpers/utils'

class OverviewView extends Component {
  static propTypes = {
    projectPropChange: PropTypes.func,
    project: PropTypes.object,
    t: PropTypes.func
  }

  onChange () {
    this.setState({checked: !this.state.activeProject})
  }

  projectPropChange (prop, value) {
    this.props.projectPropChange(prop, value)
  }

  render () {
    const {project, t} = this.props
    return (
      <div className='project-overview'>
        <table className='info'>
          <tbody>
            <tr className='info__title'>
              <td>{t('projectName').s}</td>
              <td>{project.name}</td>
            </tr>
            <tr className='info__title info__title--border'>
              <td>{t('active').s}</td>
              <td>
                <div className='project-view__switcher'>
                  <InputSwitcher dark value={project.active} onChange={(value) => this.projectPropChange('active', value)} />
                </div>
              </td>
            </tr>
            <tr className='info__title info__title--border'>
              <td>{t('startDate').s}</td>
              <td>{project.startDate}</td>
            </tr>
            <tr className='info__title info__title--border'>
              <td>{t('lastUpdate').s}</td>
              <td>{project.latestUpdate}</td>
            </tr>
            <tr className='info__title info__title--border'>
              <td>{t('inc/excVat').s}</td>
              <td>
                <div className='project-view__switcher'>
                  <InputSwitcher dark value={project.includeVat} onChange={(value) => this.projectPropChange('includeVat', value)} />
                </div>
              </td>
            </tr>
            <tr className='info__title info__title--border'>
              <td>{t('hoursQuantity').s}</td>
              <td>{setTime(project.totalMinutes, 'minutes', 'HH:MM')}</td>
            </tr>
            <tr className='info__title info__title--border'>
              <td>{t('totalTimesheetsPrice').s}</td>
              <td>{project.totalTimesheetsPrice && project.totalTimesheetsPrice.toFixed(2)}</td>
            </tr>
            <tr className='info__title info__title--border'>
              <td>{t('kmQuantity').s}</td>
              <td>{project.totalDistance}</td>
            </tr>
            <tr className='info__title info__title--border'>
              <td>{t('totalDriverlogsCompensation').s}</td>
              <td>{project.totalDriverlogsCompensation && project.totalDriverlogsCompensation.toFixed(2)}</td>
            </tr>
            <tr className='info__title info__title--border'>
              <td colSpan='2'>
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <Link className='project-overview__income' to={`/projects/${project.id}/incomechart`}>
                          <FormatNumber value={project.includeVat ? project.totalIncomeWithVat : project.totalIncomeWithoutVat} />
                        </Link>
                      </td>
                      <td>
                        <Link className='project-overview__income' to={`/projects/${project.id}/incomechart`}>
                          <span className='icon-forward' />
                        </Link>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr className='info__title info__title--border'>
              <td colSpan='2'>
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <Link className='project-overview__outcome' to={`/projects/${project.id}/outcomechart`}>
                          <FormatNumber value={project.includeVat ? project.totalOutcomeWithVat : project.totalOutcomeWithoutVat} />
                        </Link>
                      </td>
                      <td>
                        <Link className='project-overview__outcome' to={`/projects/${project.id}/outcomechart`}>
                          <span className='icon-forward' />
                        </Link>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr className='info__title info__title--border'>
              <td colSpan='2'>
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <a className='project-overview__total'>
                          {t('total').s}: <FormatNumber value={project.includeVat ? project.totalProfitWithVat : project.totalProfitWithoutVat} />
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }
}

function mapStateToProps (state) {
  return {
    t: state.i18n.get('app', 'views', 'ProjectView', 'overview')
  }
}

function mapDispatchToProps (dispatch) {
  return {
    projectPropChange: (...props) => { dispatch(projectPropChangeAction(...props)) },
    projectInfoClear: () => { dispatch(projectInfoClearAction()) }
  }
}

function mergeProps (stateProps, dispatchProps, ownProps) {
  const events = ownProps.project.eventRows
  return Object.assign({}, ownProps, {
    ...dispatchProps,
    projectPropChange: (prop, value) => dispatchProps.projectPropChange(prop, value, events),
    ...stateProps
  })
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(OverviewView)
