import '../TimesheetView.scss?global'

import PropTypes from 'prop-types'

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { isEmpty } from 'lodash'

import { reduxForm, Field, formValueSelector, change } from 'redux-form'

// Components
import Navigation from 'Navigation/Navigation'
import Button from 'shared/components/Button/Button'
import DropDown from 'shared/components/FormDropdown'
import Input from 'shared/components/FormInput'
import Timer from 'Timesheet/components/Timer/Timer'
import FormatNumber from 'shared/components/FormatNumber/FormatNumber'

// Actions
import {
  loadTimesheetAction,
  pushTimesheetAction,
  updateTimesheetTimesAction,
  updateTimesheetDatesAction,
  cleanTimesheetAction,
  pushTimesheetForInvoiceAction,
  showDeleteTimesheetMessageBox
} from 'Timesheet/actions/timesheetsList'
import { showMessageBoxWithParamsAction } from 'MessageBox/actions/messageBox'

class Timesheet extends Component {
  static propTypes = {
    params: PropTypes.object,
    projectsList: PropTypes.arrayOf(PropTypes.object),
    getTimesheet: PropTypes.func,
    timesheet: PropTypes.object,
    pushTimesheet: PropTypes.func,
    updateTimesheetTimes: PropTypes.func,
    updateTimesheetDates: PropTypes.func,
    t: PropTypes.func,
    timerIsActive: PropTypes.bool,
    cleanTimesheet: PropTypes.func,
    handleSubmit: PropTypes.func,
    minutes: PropTypes.string,
    price: PropTypes.string,
    start: PropTypes.string,
    stop: PropTypes.string,
    id: PropTypes.string,
    exported: PropTypes.string
  }

  constructor (props) {
    super(props)
    this.onStartChange = this.onStartChange.bind(this)
    this.onMinutesChange = this.onMinutesChange.bind(this)
    this.onStopChange = this.onStopChange.bind(this)
    this.saveTimesheet = this.saveTimesheet.bind(this)
    this.onTimerStart = this.onTimerStart.bind(this)
    this.onTimerStop = this.onTimerStop.bind(this)
  }

  componentDidMount () {
    const { params: {timesheetId}, getTimesheet } = this.props
    if (timesheetId && isEmpty(this.props.timesheet)) getTimesheet(timesheetId)
  }

  componentWillReceiveProps (props) {
    const newTimesheetId = props.params.timesheetId
    const { params: { timesheetId }, getTimesheet, cleanTimesheet } = this.props
    if (newTimesheetId !== timesheetId) {
      cleanTimesheet()
      getTimesheet(newTimesheetId)
    }
  }

  getTotalSum () {
    const { minutes, price } = this.props
    if (minutes && price) {
      const hours = Number(minutes) / 60
      return Number(price) * hours
    } else {
      return 0
    }
  }

  saveTimesheet (values) {
    this.props.pushTimesheet(values)
  }

  onStartChange (_, value) {
    const { updateTimesheetTimes, stop } = this.props
    updateTimesheetTimes(value, stop)
  }

  onTimerStart (value) {
    this.onStartChange(null, value)
  }

  onTimerStop (value) {
    this.onStopChange(null, value)
  }

  onStopChange (_, value) {
    const { start, updateTimesheetTimes } = this.props
    updateTimesheetTimes(start, value)
  }

  onMinutesChange (e) {
    const { start, updateTimesheetDates } = this.props
    const value = Math.round(e.target.value)
    e.target.value = value
    updateTimesheetDates(start, value)
  }

  get timesheetForm () {
    const { start, stop, id, exported, timesheet, timerIsActive, projectsList, t, handleSubmit } = this.props
    const projectsOptions = projectsList.filter(p => p.active ||
                                                (timesheet && p.value === timesheet.projectId))

    return [
      <Timer
        startValue={start}
        stopValue={stop}
        onStart={this.onTimerStart}
        onStop={this.onTimerStop}
        key='1'
      />,
      <div key='2' className='form f-column-top'>
        <div className='form__item'>
          <Field name='title' component={Input} placeholder={t('props', 'title').s} />
        </div>
        <div className='form__item'>
          <Field name='start' component={Input} placeholder={t('props', 'start').s} onChange={this.onStartChange} onBlur={this.onStartChange} type='datetime-local' readOnly={timerIsActive} />
        </div>
        <div className='form__item'>
          <Field name='stop' component={Input} placeholder={t('props', 'stop').s} onChange={this.onStopChange} onBlur={this.onStopChange} type='datetime-local' readOnly={timerIsActive} />
        </div>
        <div className='form__item'>
          <Field name='minutes' component={Input} placeholder={t('props', 'totalTime').s} onChange={this.onMinutesChange} type='number' readOnly={timerIsActive} />
        </div>
        <div className='form__item'>
          <Field name='price' component={Input} placeholder={t('props', 'price').s} type='float' />
        </div>
        <div className='form__item'>
          <Field name='projectId' component={DropDown} big emptyOption={t('emptyOptions', 'project').s} hint={t('props', 'project').s} options={projectsOptions} />
        </div>
        <div className='form__item'>
          <h3 className='input text-right color-red font-bold'>{ exported ? t('invoiced').s : t('not_invoiced').s }</h3>
        </div>
        <h3 className='grand-total'>
          {t('totalSum').s}: <FormatNumber value={this.getTotalSum()} />
        </h3>
      </div>,
      <div key='3' className='f-column-bottom form-buttons'>
        <Button saveButton view='transparent-black' onClick={handleSubmit((values) => this.saveTimesheet(values))}>{id ? t('update').s : t('create').s}</Button>
        <Button view='transparent-red' goBackButton>{t('cancel').s}</Button>
      </div>
    ]
  }

  render () {
    const { params: { timesheetId }, id } = this.props
    return (
      <div className='timesheet-view f-column' >
        { (timesheetId && !id)
          ? null
          : this.timesheetForm
        }
      </div>
    )
  }
}

class TimesheetWithNavigation extends Component {
  static propTypes = {
    params: PropTypes.object,
    t: PropTypes.func,
    showDeleteTimesheetMessageBox: PropTypes.func
  }

  render () {
    const { t, params: { timesheetId }, showDeleteTimesheetMessageBox } = this.props
    const actions = timesheetId
      ? [{ icon: 'icon-trash', callback: showDeleteTimesheetMessageBox }]
      : null

    return (
      <Navigation actions={actions} title={timesheetId ? t('new', 'editTitle').s : t('new', 'newTitle').s} hideFooter className='f-column' >
        <Timesheet {...this.props} />
      </Navigation>
    )
  }
}

const formName = 'timesheet'
const selector = formValueSelector(formName)

function mapStateToProps (state) {
  return {
    timerIsActive: state.timesheets.timerIsActive,
    projectsList: state.userSettings.projects,
    timesheet: state.timesheets.newTimesheet,
    initialValues: state.timesheets.newTimesheet,
    t: state.i18n.get('app', 'views', 'Timesheet'),
    minutes: selector(state, 'minutes'),
    price: selector(state, 'price'),
    start: selector(state, 'start'),
    stop: selector(state, 'stop'),
    id: selector(state, 'id'),
    exported: selector(state, 'exported')
  }
}

function mapDispatchToProps (dispatch, ownProps) {
  const { location: { query: { cameFrom } }, params: { timesheetId } } = ownProps

  let pushTimesheet
  if (cameFrom === 'invoice') {
    pushTimesheet = (data) => { dispatch(pushTimesheetForInvoiceAction(data)) }
  } else {
    pushTimesheet = (data) => { dispatch(pushTimesheetAction(data)) }
  }

  return {
    getTimesheet: (id) => { dispatch(loadTimesheetAction(id)) },
    pushTimesheet,
    updateTimesheetTimes: (start, stop, now) => { dispatch(updateTimesheetTimesAction(start, stop, now)) },
    updateTimesheetDates: (start, minutes) => { dispatch(updateTimesheetDatesAction(start, minutes)) },
    showMessageBox: (...props) => { dispatch(showMessageBoxWithParamsAction(...props)) },
    cleanTimesheet: () => { dispatch(cleanTimesheetAction()) },
    showDeleteTimesheetMessageBox: () => { dispatch(showDeleteTimesheetMessageBox(timesheetId)) },
    changeStartTime: (value) => dispatch(change(formName, 'start', value)),
    changeStopTime: (value) => dispatch(change(formName, 'stop', value))
  }
}

function validate (values) {
  let errors = {}
  if (!values.title) {
    errors.title = true
  }
  if (!values.start) {
    errors.start = true
  }
  // we validate hourly rate only while creating timesheet
  if (values.id && ((values.start && values.stop && !values.price) || values.price === 0)) {
    errors.price = true
  }
  if (values.start && values.stop && Number(values.minutes) <= 0) {
    errors.minutes = true
    errors.stop = true
  }

  return errors
}

const wrappedTimesheet = reduxForm({
  form: formName,
  validate,
  enableReinitialize: true
})(Timesheet)

const wrappedTimesheetWithNavigation = reduxForm({
  form: formName,
  validate,
  enableReinitialize: true
})(TimesheetWithNavigation)

export const TimesheetView = connect(mapStateToProps, mapDispatchToProps)(wrappedTimesheet)

export default connect(mapStateToProps, mapDispatchToProps)(wrappedTimesheetWithNavigation)
