import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import IpadNavigation from 'Navigation/IpadNavigation'
import {InvoicePdfPreviewView as InvoicePdfPreview} from './Smartphones'

// Helpers
import getLeftComponent from 'Navigation/helpers/linkAccordance'
import { getTrimmedLink } from 'shared/helpers/linkUtils'

export class InvoicePdfPreviewView extends Component {
  static propTypes = {
    location: PropTypes.object,
    t: PropTypes.func,
    previousLink: PropTypes.string
  }

  render () {
    const { location: { pathname }, t, previousLink } = this.props
    const Events = getLeftComponent('invoice', previousLink)
    return (
      <IpadNavigation {...this.props} backLink={getTrimmedLink(pathname, 1)} title={t('navbar', 'title').s}>
        <Events {...this.props} />
        <InvoicePdfPreview {...this.props} />
      </IpadNavigation>
    )
  }
}

function mapStateToProps (state) {
  return {
    t: state.i18n.get('app', 'views', 'Invoices', 'InvoicePdfPreview'),
    previousLink: state.appInfo.previousLink
  }
}
export default connect(mapStateToProps)(InvoicePdfPreviewView)
