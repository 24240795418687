import { bind } from 'redux-effects'
import { fetch } from 'redux-effects-fetch'

import { showMessageBoxWithParamsAction } from 'MessageBox/actions/messageBox'
import { showNavbarSpinnerAction, hideNavbarSpinnerAction } from 'shared/actions/navbarSpinner'
import getGreyLabelOptions from 'GreyLabel/helpers/greyLabel'
import { country, personalNumberMaskProps } from 'mrshoebox-ui-components/src/helpers/countrySpecific'

import { REGISTER } from 'User/Register/constants/Api'
import { saveTokens } from 'User/Auth/actions/auth'

export default function signUpAction (data) {
  return (dispatch) => {
    return dispatch([
      showNavbarSpinnerAction(),
      bind(makeSignUpRequest(data), onSuccessSignUp.bind(this, data), onError)
    ])
  }
}

function makeSignUpRequest (data) {
  return (dispatch) => {
    let body = {
      country,
      email: data.email,
      password: data.password,
      password_confirmation: data.password,
      phone: data.telephone,
      name: data.name,
      client_company: {
        gdpr_agreement: data.gdprAgreement,
        country,
        white_label: getGreyLabelOptions().whiteLabel
      }
    }

    if (personalNumberMaskProps.normalize(data.personalNumber)) {
      body.personal_number = personalNumberMaskProps.normalize(data.personalNumber)
    }

    return dispatch(fetch(REGISTER, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    }))
  }
}

function onSuccessSignUp (formValues, response) {
  return (dispatch, getState) => {
    const t = getState().i18n.get('app', 'views', 'SignUpView', 'messages')

    if (response.status === 200) {
      dispatch(saveTokens({ data: response.value }))
    } else {
      console.error('Response not successfull', response)
      dispatch([
        hideNavbarSpinnerAction(),
        showMessageBoxWithParamsAction(t('title').s, t('unexpectedError').s)
      ])
    }
  }
}

function onError (response) {
  return (dispatch, getState) => {
    const t = getState().i18n.get('app', 'views', 'SignUpView', 'messages')
    if (response.status === 422) {
      const translatedErrors = translateErrors(t, response.value.errors.full_messages)
      const errorsToDisplay = translatedErrors.reduce((prev, e) => prev + `${e}\n\n`, '')

      return dispatch(showMessageBoxWithParamsAction(t('title').s, errorsToDisplay))
    } else {
      console.error('Response not successfull', response)
      return dispatch(showMessageBoxWithParamsAction(t('title').s, t('unexpectedError').s))
    }
  }
}

function translateErrors (t, errors = []) {
  return errors.map((error) => {
    if (error === 'Email has already been taken' || error === 'Email används redan') {
      return t('emailTaken').s
    } else if (error === 'Personal number has already been taken' || error === 'Personnummer används redan') {
      return t('personalNumberTaken').s
    } else {
      return t('unexpectedError').s
    }
  })
}
