import './AddContactModal.scss?global'

import PropTypes from 'prop-types'

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { reduxForm, Field, change } from 'redux-form'
import { get, isNil } from 'lodash'

// Components
import Modal from 'shared/components/NewModal/NewModal'
import Button from 'shared/components/Button/Button'

import NameInput from './components/NameInput'
import CompanyInput from './components/CompanyInput'

class ModalContext extends Component {
  static propTypes = {
    addContact: PropTypes.func,
    changeCompanyName: PropTypes.func,
    editIndex: PropTypes.number,
    t: PropTypes.func,
    t_buttons: PropTypes.func,
    reset: PropTypes.func,
    handleSubmit: PropTypes.func
  }

  constructor (props) {
    super(props)
    this.save = this.save.bind(this)
  }

  save (values) {
    const { editIndex, reset } = this.props
    this.props.addContact(values, editIndex)
    reset()
  }

  render () {
    const { t, t_buttons, handleSubmit, changeCompanyName } = this.props
    return (
      <div className='representation-add-contact f-column'>
        <div className='form f-column-top'>
          <h1 className='representation-add-contact__title'><b>{t('title').s}</b></h1>
          <div>
            <div className='form__item'>
              <Field
                name='name'
                component={NameInput}
                placeholder={t('name').s}
                changeCompany={changeCompanyName}
              />
            </div>
            <div className='form__item'>
              <Field
                name='company'
                component={CompanyInput}
                placeholder={t('company').s}
              />
            </div>
          </div>
        </div>
        <div className='f-column-bottom'>
          <Button view='transparent-black' onClick={handleSubmit(this.save)}>{t_buttons('save').s}</Button>
        </div>
      </div>
    )
  }
}

const formName = 'representation.expense-representation-contact'

function mapStateToProps (state, ownProps) {
  return {
    initialValues: getInitialValues(state, ownProps),
    t: state.i18n.get('app', 'views', 'ExpenseRepresentationView', 'modal'),
    t_buttons: state.i18n.get('app', 'shared', 'buttons')
  }
}

function mapDispatchToProps (dispatch) {
  return {
    changeCompanyName: (value) => dispatch(change(formName, 'company', value))
  }
}

function getInitialValues (state, ownProps) {
  let name
  let company
  let id

  if (!isNil(ownProps.editIndex)) {
    id = get(state.events.newEvent.representationCopy.representationGuests, [ownProps.editIndex, 'id'])
    name = get(state.events.newEvent.representationCopy.representationGuests, [ownProps.editIndex, 'name'])
    company = get(state.events.newEvent.representationCopy.representationGuests, [ownProps.editIndex, 'company'])
  }

  return { id, name, company }
}

function validate (values) {
  const errors = {}

  if (!values.name) {
    errors.name = true
  }
  if (!values.company) {
    errors.company = true
  }

  return errors
}

const AddContact = connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: formName,
  validate
})(ModalContext))

class AddContactModal extends Component {
  static propTypes = {
  };

  constructor (props) {
    super(props)
    this.close = this.close.bind(this)
  }

  componentWillMount () {
    this.setState({editIndex: null})
  }

  open () {
    this.setState({editIndex: null})
    this.refs.modal.open()
  }

  close () {
    this.setState({editIndex: null})
    this.refs.modal.close()
  }

  edit (index) {
    this.setState({editIndex: index})
    this.refs.modal.open()
  }

  render () {
    return (
      <Modal ref='modal'>
        <AddContact onCloseClick={this.close} {...this.props} editIndex={this.state.editIndex} />
      </Modal>
    )
  }
}

export default AddContactModal
