import { ADD_ITEM, REMOVE_ITEM, REMOVE_ALL } from '../constants/ImportItems'

const initialState = []

export default function (state = initialState, action) {
  switch (action.type) {
    case ADD_ITEM:
      return [
        action.payload,
        ...state
      ]
    case REMOVE_ITEM:
      return state.filter(item =>
        item.id !== action.payload.id
      )
    case REMOVE_ALL:
      return initialState
    default:
      return state
  }
}
