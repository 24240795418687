import './BackLink.scss?global'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Link } from 'react-router'
import cs from 'classnames'

export default class BackLink extends Component {
  static propTypes = {
    text: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.func
    ]),
    link: PropTypes.string,
    onClick: PropTypes.func,
    transparent: PropTypes.bool
  };

  get buttonContext () {
    const { text } = this.props
    return [
      <span className='icon-arrow-long-left' key='1' />,
      <span className='auth-back__text' key='2'>{text}</span>
    ]
  }

  render () {
    const { link, onClick, transparent } = this.props
    const classNames = cs({
      'auth-back': true,
      'auth-back--transparent': transparent
    })

    if (!link && onClick) {
      return <div className={classNames}><div onClick={onClick}>{this.buttonContext}</div></div>
    } else {
      return <div className={classNames}><Link to={link} onClick={onClick}>{this.buttonContext}</Link></div>
    }
  }
}
