import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { loadVacancy } from 'Vacancies/actions'

import Navigation from 'Navigation/Navigation'
import Vacancy from 'Vacancies/components/Vacancy'

function VacancyView (props) {
  const { loadVacancy, params: { vacancyId }, vacancy } = props
  useEffect(() => { loadVacancy(vacancyId) }, [loadVacancy, vacancyId])
  return (
    <Vacancy {...vacancy} t={props.t} />
  )
}

VacancyView.propTypes = {
  loadVacancy: PropTypes.func,
  params: PropTypes.object,
  vacancy: PropTypes.object
}

const VacancyViewWithNavigation = (props) =>
  <Navigation title={props.vacancy.title || ''} {...props}>
    <VacancyView {...props} />
  </Navigation>

VacancyViewWithNavigation.propTypes = {
  vacancy: PropTypes.object
}

function mapStateToProps (state) {
  const { vacancy } = state.vacancies
  return {
    vacancy: {
      ...vacancy,
      tenderInviteIndividualQualities: vacancy.tenderInviteIndividialQualities ? vacancy.tenderInviteIndividualQualities.map(q => q.tenderInviteIndividualQuality) : [],
      tenderInviteOtherDemands: vacancy.tenderInviteOtherDemands ? vacancy.tenderInviteOtherDemands.map(q => q.tenderInviteOtherDemand) : [],
      tenderInviteShallDemands: vacancy.tenderInviteShallDemands ? vacancy.tenderInviteShallDemands.map(q => q.tenderInviteShallDemand) : [],
      tenderInviteShouldDemands: vacancy.tenderInviteShouldDemands ? vacancy.tenderInviteShouldDemands.map(q => q.tenderInviteShouldDemand) : []
    },
    t: state.i18n.get('app', 'views', 'VacancyView')
  }
}

const mapDispatchToProps = (dispatch) => ({
  loadVacancy: (id) => dispatch(loadVacancy(id))
})

export const connectedVacancyView = connect(mapStateToProps, mapDispatchToProps)(VacancyView)
export default connect(mapStateToProps, mapDispatchToProps)(VacancyViewWithNavigation)
