import PropTypes from 'prop-types'
import React, { Component } from 'react'

import OverviewViewContainer from 'Overview/Overview/containers/Common'

import IpadNavigation from 'Navigation/IpadNavigation'
import Navigation from 'Navigation/Navigation'

import OverviewList from 'Overview/Overview/components/List/List'

import SettingsModalList from 'Overview/Overview/components/SettingsList/SettingsList'
import OverviewCharts from 'Overview/Overview/components/Charts/Charts'

class LeftSideView extends Component {
  static propTypes = {
    t: PropTypes.func
  }
  render () {
    const {t} = this.props
    return (
      <Navigation title={t('navbar', 'title').s} className='overview-view' home>
        <OverviewList {...this.props} className='overview-list--tablet' />
      </Navigation>
    )
  }
}

class OverviewView extends Component {
  static propTypes = {
    loadOverviewData: PropTypes.func,
    location: PropTypes.object
  }

  constructor (props) {
    super(props)
    this.openSettings = this.openSettings.bind(this)
  }

  openSettings = () => {
    this.settingsModal.open()
  }

  render () {
    const {location: {query: {type = 'result'}}} = this.props
    return (
      <IpadNavigation {...this.props} title={''} notBack>
        <LeftSideView {...this.props} />
        <div className='overview-view overview-view--tablet'>
          <OverviewCharts {...this.props} />
          <SettingsModalList onSaveClick={this.props.loadOverviewData} provideController={(c) => { this.settingsModal = c }} type={type} />
        </div>
      </IpadNavigation>
    )
  }
}

export default OverviewViewContainer(OverviewView)
