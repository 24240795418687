import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Tabs from 'shared/components/Tabs/Tabs'

export default class OverviewTabs extends Component {
  static propTypes = {
    location: PropTypes.object,
    changeOverviewTab: PropTypes.func,
    getFeaturePermissionProps: PropTypes.func,
    t: PropTypes.func
  }
  onTabsChange = (newOverviewType) => {
    return () => {
      this.props.changeOverviewTab(newOverviewType)
    }
  }

  isTabActive = (tabName) => {
    const { location: { query: { type = 'result' } } } = this.props
    return tabName === type
  }

  render () {
    const { t, getFeaturePermissionProps } = this.props
    return (
      <Tabs config={[
        {label: t('tabs', 'result').s, action: this.onTabsChange('result'), active: this.isTabActive('result'), getPermissionProps: (className) => getFeaturePermissionProps('overview', className)},
        {label: t('tabs', 'forecast').s, action: this.onTabsChange('forecast'), active: this.isTabActive('forecast'), getPermissionProps: (className) => getFeaturePermissionProps('forecast', className)}
      ]} />
    )
  }
}
