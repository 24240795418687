import './InputRange.scss?global'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

export default class InputRange extends Component {
  static propTypes = {
    initialValue: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    onChange: PropTypes.func,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    displayedValueFormatter: PropTypes.func
  };

  constructor (props) {
    super(props)
    this.onChange = this.onChange.bind(this)
  }

  componentWillMount () {
    this.state = {value: this.props.initialValue}
  }

  onChange () {
    const displayedValue = this.props.displayedValueFormatter
      ? this.props.displayedValueFormatter(this.refs.input.value)
      : this.refs.input.value

    this.setState({value: displayedValue})
    if (this.props.onChange) this.props.onChange(this.refs.input.value)
  }

  render () {
    return (
      <div className='input-range'>
        <div>
          <input type='range' ref='input' {...this.props} onChange={this.onChange} />
          <span className='icon-arrow-right' />
        </div>
        <span>{this.state.value}</span>
      </div>
    )
  }
}
