import React, { Component } from 'react'
import { connect } from 'react-redux'

import Salaries from 'Salaries/views/SalariesListView/containers/Smartphones'
import IpadNavigation from 'Navigation/IpadNavigation'

class SalariesListView extends Component {
  render () {
    return (
      <IpadNavigation title={this.props.t('navbar', 'title').s} {...this.props} onlyLeftSide>
        <Salaries {...this.props} />
      </IpadNavigation>
    )
  }
}

const mapStateToProps = (state) => ({
  t: state.i18n.get('app', 'views', 'Salaries', 'SalariesListView')
})

export default connect(mapStateToProps)(SalariesListView)
