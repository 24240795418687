import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Field } from 'redux-form'
import { isEmpty } from 'lodash'
import { haveNoVatOption } from 'mrshoebox-ui-components/src/helpers/countrySpecific'

// Components
import DropDown from 'shared/components/FormDropdown'
import Input from 'shared/components/FormInput'

// Constants
import {PRIVATE_PERSON} from 'Invoices/shared/constants/PersonType'
const euVatValue = 1
const reversedTaxValue = 2
const noVatValue = 3

export default class InvoiceVatType extends Component {
  static propTypes = {
    invoice: PropTypes.object,
    fields: PropTypes.object,
    formValues: PropTypes.object,
    updateCustomerVatNumber: PropTypes.func,
    t: PropTypes.func
  }

  constructor (props) {
    super(props)
    this.state = {value: undefined}
    this.onVatTypeChange = this.onVatTypeChange.bind(this)
  }

  getVatOptions () {
    const { t, formValues: {euVat, invertedVat, noVat} } = this.props
    const options = [
      {label: t('labels', 'euVat').s, value: euVatValue, selected: euVat === true},
      {label: t('labels', 'ReversedTax').s, value: reversedTaxValue, selected: invertedVat === true}
    ]
    if (haveNoVatOption) {
      options.push({label: t('labels', 'noVat').s, value: noVatValue, selected: noVat === true})
    }
    return options
  }

  onVatTypeChange (e) {
    const { invoice: { customer }, updateCustomerVatNumber,
      fields: {euVat, invertedVat, noVat} } = this.props
    const value = Number(e.target.value)
    this.setState({value})
    const isEuVat = value === euVatValue
    const isInvertedVat = value === reversedTaxValue
    const isNoVat = value === noVatValue
    noVat.onChange(isNoVat)
    euVat.onChange(isEuVat)
    invertedVat.onChange(isInvertedVat)
    if (!isEmpty(customer) && customer.customerType !== PRIVATE_PERSON) {
      updateCustomerVatNumber(customer)
    }
  }

  render () {
    const { invoice: {customer: { customerType }}, formValues: { euVat, invertedVat }, t } = this.props
    const isPrivatePerson = customerType === PRIVATE_PERSON
    const {value} = this.state

    if (!isPrivatePerson) {
      return (
        <div className='create-invoice__vat-type'>
          <DropDown big emptyOption={t('labels', 'selectVatType').s} onChange={this.onVatTypeChange} value={value} options={this.getVatOptions()} hint={t('labels', 'selectVatType').s} />
          {(euVat || invertedVat) ? <div className='create-invoice__vat-type__vat-number'>
            <Field name='customerVatNumber' component={Input} placeholder={t('labels', 'customerVatNumber').s} />
          </div> : null}
        </div>
      )
    } else {
      return null
    }
  }
}
