import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { reduxForm, Field } from 'redux-form'
import { connect } from 'react-redux'
import { routerActions } from 'react-router-redux'

import Button from 'shared/components/Button/Button'
import BackLink from 'User/shared/components/BackLink/BackLink'
import Dropdown from 'shared/components/Dropdown/Dropdown'
import { Container, UpperContent, BottomContent } from 'User/Auth/components/LoginContainer'

import { selectCompanyAction } from 'User/Auth/actions/auth'

const DropDownWrapper = (props) => {
  return <Dropdown
    big
    white
    {...props.input}
    {...props.meta}
    {...props}
  />
}
DropDownWrapper.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object
}

class SelectCompanyView extends Component {
  static propTypes = {
    companies: PropTypes.arrayOf(PropTypes.object),
    handleSubmit: PropTypes.func,
    goBack: PropTypes.func,
    t: PropTypes.func
  }

  render () {
    const { t, companies, handleSubmit, goBack } = this.props

    return (
      <Container>
        <UpperContent withoutShadow navyText>
          {t('selectCompanyTitle').s}
        </UpperContent>
        <BottomContent notTransparent>
          <form onSubmit={handleSubmit} className='login__form'>
            <div className='login__form__fields'>
              <Field
                name='companyId'
                component={DropDownWrapper}
                props={{
                  options: companies
                }}
              />
            </div>
            <div className='login__form__send'>
              <Button view='transparent-white' type='submit'>{t('loginButton').s}</Button>
            </div>
          </form>
        </BottomContent>
        <BackLink onClick={goBack} text={t('back').s} />
      </Container>
    )
  }
}

const mapStateToProps = (state) => ({
  t: state.i18n.get('app', 'views', 'LoginView'),
  companies: state.userSettings.companies.map((c) => ({ value: c.id, label: `${c.name} / ${c.orgNumber || ''}` })),
  initialValues: {
    companyId: state.userSettings.companies[0].id
  }
})

const mapDispatchToProps = (dispatch, ownProps) => {
  const forceSettingsReload = ownProps.location.query.reloadOptions

  return {
    onSubmit: (data) => { dispatch(selectCompanyAction(data, forceSettingsReload)) },
    goBack: () => { dispatch(routerActions.go(-1)) }
  }
}

const reduxHOC = connect(mapStateToProps, mapDispatchToProps)
const reduxFormHOC = reduxForm({
  form: 'selectCompany'
})

export default reduxHOC(reduxFormHOC(SelectCompanyView))
