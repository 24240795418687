import getGreyLabelOptions from 'GreyLabel/helpers/greyLabel'

let currentStyleElement

export default function appendStyleElementWithFonts () {
  let font
  if (getGreyLabelOptions().whiteLabel === 'werkeys') {
    font = {
      name: 'lato',
      extension: 'ttf',
      format: 'truetype'
    }
    document.body.style.cssText += 'text-size-adjust:85%;-webkit-text-size-adjust:85%;-moz-text-size-adjust:85%;-ms-text-size-adjust:85%'
  } else {
    font = {
      name: 'dosis',
      extension: 'woff',
      format: 'woff'
    }
  }

  const newStyle = document.createElement('style')
  newStyle.appendChild(document.createTextNode(`
  @font-face {
    font-family: 'Base_Light';
    src: url('/fonts/${font.name}/${font.name}_Light.${font.extension}') format('${font.format}');
    size-adjust: ${getGreyLabelOptions().whiteLabel === 'werkeys' ? '85%' : '100%'};
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Base_Regular';
    src: url('/fonts/${font.name}/${font.name}_Regular.${font.extension}') format('${font.format}');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Base_Medium';
    src: url('/fonts/${font.name}/${font.name}_Medium.${font.extension}') format('${font.format}');
    font-weight: normal;
    font-style: normal;
  }
  `))

  currentStyleElement && document.head.removeChild(currentStyleElement)
  document.head.appendChild(newStyle)
  currentStyleElement = newStyle
}
