import { bind } from 'redux-effects'
import { createAction } from 'redux-actions'
import { getFormValues } from 'redux-form'
import { showNavbarSpinnerAction, hideNavbarSpinnerAction } from 'shared/actions/navbarSpinner'
import { fetch2 as fetch } from 'shared/helpers/fetch'
import { isEqual } from 'lodash'

import { defaultFilterValues } from 'Articles/components/Filters/Filters'

import { ARTICLE_LIST_UPDATE } from 'Articles/constants/ActionTypes'
import { ARTICLE_LIST } from 'Articles/constants/Api'

const updateArticlesListAction = createAction(ARTICLE_LIST_UPDATE)

export function reloadListAction () {
  return (dispatch, getState) => {
    const filters = getFormValues('articlesViewFilters')(getState()) || defaultFilterValues

    return dispatch([
      showNavbarSpinnerAction(),
      bind(fetchArticleList(filters, 1), updateArticlesList.bind(null, [], filters))
    ])
  }
}

export function extendListAction () {
  return (dispatch, getState) => {
    const { articles: { hasNextPage, lastPageLoaded, listData } } = getState()
    const filters = getFormValues('articlesViewFilters')(getState()) || defaultFilterValues

    if (hasNextPage) {
      return dispatch([
        showNavbarSpinnerAction(),
        bind(fetchArticleList(filters, lastPageLoaded + 1), updateArticlesList.bind(null, listData, filters))
      ])
    } else {
      return Promise.resolve() // redux-mock-store needs that
    }
  }
}

function fetchArticleList (filters, pageToLoad) {
  let newFilters = {
    order: `${filters.orderBy} ${filters.orderType}`
  }
  if (filters.articleType !== 'all') {
    newFilters['filter[article_type]'] = filters.articleType
  }
  if (filters.search) {
    newFilters['filter[name]'] = filters.search
  }

  return fetch(ARTICLE_LIST, { ...newFilters, p: pageToLoad })
}

function updateArticlesList (initialList, filtersBeforeResponseReceived, response) {
  return (dispatch, getState) => {
    const currentFilters = getFormValues('articlesViewFilters')(getState()) || defaultFilterValues
    if (!isEqual(currentFilters, filtersBeforeResponseReceived)) {
      return
    }

    const dataToUpdate = {
      hasNextPage: response.value.current_page < response.value.overall_pages,
      lastPageLoaded: response.value.current_page,
      listData: initialList.concat(response.value.data.map((article) => transformArticle(article)))
    }

    return dispatch([hideNavbarSpinnerAction(), updateArticlesListAction(dataToUpdate)])
  }
}

export function transformArticle (article) {
  return {
    id: article.id,
    articleNumber: article.inventory_number,
    name: article.name,
    unit: article.unit,
    price: article.price,
    articleType: article.article_type,
    vatPercentage: article.VAT_percentage
  }
}
