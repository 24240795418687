import React from 'react'
import PropTypes from 'prop-types'

import Modal from 'shared/components/NewModal/NewModal'

export default class ContentModal extends React.Component {
  constructor (props) {
    super(props)
    this.modal = React.createRef()
  }

  static propTypes = {
    isVisible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    content: PropTypes.node,
    class: PropTypes.string
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.isVisible !== this.props.isVisible) {
      nextProps.isVisible && this.modal.current.open()
      !nextProps.isVisible && this.modal.current.close()
    }
  }

  render () {
    const { content, ...restProps } = this.props
    return (
      <Modal ref={this.modal} {...restProps}>
        {content}
      </Modal>
    )
  }
}
