import PropTypes from 'prop-types'
import React, { Component } from 'react'

// Containers
import InvoiceCreateContainer from './Common'

// Components
import Navigation from 'Navigation/Navigation'
import InvoiceCreate from '../components/InvoiceCreate'

export class InvoiceCreateView extends Component {
  static propTypes = {
    invoiceClear: PropTypes.func,
    navbarTitle: PropTypes.oneOfType([PropTypes.func, PropTypes.string])
  }

  render () {
    const {invoiceClear, navbarTitle} = this.props
    return (
      <Navigation hideFooter backLink='/invoices' title={navbarTitle} onClickBack={invoiceClear}>
        <InvoiceCreate {...this.props} />
      </Navigation>
    )
  }
}

export default InvoiceCreateContainer(InvoiceCreateView)
