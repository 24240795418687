import React from 'react'
import PropTypes from 'prop-types'

// jest not likes require.context
if (process.env.NODE_ENV !== 'test') {
  // it's necessary to have svg file without <style> .class {...} </style> for color things if you want to change them in later using { .className > use selector } (only this way is working because shadow dom)
  // This needed for svg rendering, without it some svg icons like marketplace in More will not be rendered
  const files = require.context('!svg-sprite-loader!../../../static/svg-icons', true, /.*\.svg$/)
  files.keys().forEach(files)
}

const Icon = ({ type, className, fileExtension }) => {
  if (fileExtension === 'png' || fileExtension === 'jpg') {
    return (
      <span className={`${className} ${type}`} />
    )
  }

  return (
    <svg className={`dib v-mid ${className}`} width='1em' height='1em'>
      <use xlinkHref={`#${type}`} />
    </svg>
  )
}

Icon.propTypes = {
  fileExtension: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string
}

export default Icon
