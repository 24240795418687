import PropTypes from 'prop-types'
import React, { Component } from 'react'

import IpadNavigation from 'Navigation/IpadNavigation'
import Representation from 'Events/Expense/views/RepresentationView/components/Representation'

import EventsView from 'Events/Events/views/EventsView/containers/Smartphones'

// Containers
import RepresentationContainer from 'Events/Expense/views/RepresentationView/containers/Common'

class RepresentationView extends Component {
  static propTypes = {
    t: PropTypes.func
  }

  render () {
    const {t} = this.props
    return (
      <IpadNavigation {...this.props} title={t('title').s} className='expense-representation'>
        <EventsView {...this.props} />
        <Representation {...this.props} />
      </IpadNavigation>
    )
  }
}

export default RepresentationContainer(RepresentationView)
