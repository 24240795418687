import animatedScrollTo from 'animated-scrollto'

// Helpers
import { isIpadResolution } from 'shared/helpers/utils'

const NAVIGATION_SCROLL_CONTAINER_CLASS = '.scrollBlock__content'
const NAVIGATION_SCROLL_DESKTOP_CONTAINER_CLASS = '.scrollBlock__content--right'

export function getNavigationContainer () {
  const navigationContainer = isIpadResolution()
    ? document.querySelector(NAVIGATION_SCROLL_DESKTOP_CONTAINER_CLASS)
    : document.querySelector(NAVIGATION_SCROLL_CONTAINER_CLASS)
  return navigationContainer
}

export function isScrolledToContainerBottom () {
  const navigationContainer = getNavigationContainer()
  return navigationContainer.scrollHeight <=
    navigationContainer.offsetHeight + navigationContainer.scrollTop
}

export function isScrolledToContainerTop () {
  const navigationContainer = getNavigationContainer()
  return navigationContainer.scrollTop === 0
}

export function scrollToContainerBottom (options = { duration: 0 }) {
  const navigationContainer = getNavigationContainer()
  animatedScrollTo(navigationContainer, navigationContainer.scrollHeight, options.duration)
}

export function scrollTo (value, options = { duration: 750 }) {
  const navigationContainer = getNavigationContainer()
  animatedScrollTo(navigationContainer, value, options.duration)
}
