import {
  LOAD_SUPPLIERS, SUPPLIER_CLEAR,
  SUPPLIERS_CLEAR, LOAD_SUPPLIER,
  EXTEND_SUPPLIERS
} from '../constants/ActionTypes'

const initialState = {
  lastOffset: 0,
  hasNextPage: true,
  listData: [],
  supplier: {}
}

export default function (state = initialState, action) {
  switch (action.type) {
    case SUPPLIER_CLEAR:
      return {
        ...state,
        supplier: initialState.supplier
      }
    case LOAD_SUPPLIERS:
      return {
        ...state,
        listData: action.payload.suppliers,
        lastOffset: action.payload.offset,
        hasNextPage: action.payload.hasNextPage
      }
    case EXTEND_SUPPLIERS:
      return {
        ...state,
        listData: [...state.listData, ...action.payload.suppliers],
        lastOffset: action.payload.offset,
        hasNextPage: action.payload.hasNextPage
      }
    case LOAD_SUPPLIER:
      return {
        ...state,
        supplier: action.payload
      }
    case SUPPLIERS_CLEAR:
      return {
        ...state,
        listData: initialState.listData,
        hasNextPage: initialState.hasNextPage,
        lastOffset: initialState.lastOffset
      }
    default:
      return state
  }
}
