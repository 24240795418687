import './ConfirmationMessageBox.scss?global'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import Button from 'shared/components/Button/Button'
import Modal from 'shared/components/NewModal/NewModal'

export function ConfirmationMessageBoxContent (props) {
  const { title, text, onYesClick, onNoClick, t } = props

  return (
    <div className='confirm-message-box__content'>
      <h1><b>{title || t('title').s}</b></h1>
      <h2>{text}</h2>
      <div className='confirm-message-box-buttons'>
        <Button saveButton onClick={onYesClick}>
          <span className='icon-yes icon-ok-light-round' />
          <span>{t('yes').s}</span>
        </Button>
        <Button saveButton onClick={onNoClick}>
          <span>{t('no').s}</span>
          <span className='icon-no' />
        </Button>
      </div>
    </div>
  )
}
ConfirmationMessageBoxContent.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  onYesClick: PropTypes.func,
  onNoClick: PropTypes.func,
  t: PropTypes.func
}

class ConfirmationMessageBox extends Component {
  static propTypes = {
    provideController: PropTypes.func,
    onYesClick: PropTypes.func,
    onNoClick: PropTypes.func
  };

  static defaultProps = {
    provideController: () => {}
  }

  componentDidMount () {
    const modalInstance = this.modal
    this.props.provideController({
      open: this.modal.open.bind(modalInstance),
      close: this.modal.close.bind(modalInstance)
    })
  }

  clickHandler (func, ...payload) {
    this.modal.close()
    func && func(...payload)
  }

  render () {
    const { onYesClick, onNoClick } = this.props

    return (
      <Modal ref={(c) => { this.modal = c }}>
        <ConfirmationMessageBoxContent
          {...this.props}
          onYesClick={() => { this.clickHandler(onYesClick) }}
          onNoClick={() => { this.clickHandler(onNoClick) }}
        />
      </Modal>
    )
  }
}

function mapStateToProps (state) {
  return {
    t: state.i18n.get('app', 'shared', 'messages', 'confirmationMessage')
  }
}

export default connect(mapStateToProps)(ConfirmationMessageBox)
