import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import IpadNavigation from 'Navigation/IpadNavigation'
import { connectedIntegrationsView as Integrations } from 'Integrations/views/IntegrationsView/containers/Smartphones'
import More from 'More/views/MoreView/containers/Smartphones'

class MoreIntegrationsView extends Component {
  static propTypes = {
    navbarTitle: PropTypes.string.isRequired
  }
  render () {
    return (
      <IpadNavigation title={this.props.navbarTitle} {...this.props}>
        <More {...this.props} notRightIcon />
        <Integrations {...this.props} />
      </IpadNavigation>
    )
  }
}

const mapStateToProps = (state) => ({
  navbarTitle: state.i18n.get('app', 'views', 'Integrations', 'navbar', 'title').s
})

export default connect(mapStateToProps)(MoreIntegrationsView)
