import React from 'react'
import PropTypes from 'prop-types'

import './styles.scss?global'

const ErrorsList = props => {
  const { title, description, errors } = props

  return (
    <div className='invoice-send__error-list'>
      <h1><b>{title}</b></h1>
      <h2>{description}</h2>
      <ul>
        { errors.map((error, i) => {
          return <li key={i}>{error}</li>
        }) }
      </ul>
    </div>
  )
}

ErrorsList.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  errors: PropTypes.array
}

export default ErrorsList
