import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'
import cs from 'classnames'

import Icon from 'shared/components/Icon'

import getGreyLabelOptions from 'GreyLabel/helpers/greyLabel'

// TODO: move styles definition to this folder from wizard todo view
class ListItem extends PureComponent {
  static propTypes = {
    link: PropTypes.string,
    icon: PropTypes.string,
    title: PropTypes.string,
    text: PropTypes.string,
    onClick: PropTypes.func
  }

  static defaultProps = {
    onClick: () => {}
  }

  render () {
    const ComponentToWrap = this.props.link ? Link : 'div'
    const { whiteLabel } = getGreyLabelOptions()

    return (
      <ComponentToWrap onClick={this.props.onClick} to={this.props.link} className={cs('wizard-todo__list__item', whiteLabel)}>
        <div>
          <div className='wizard-todo__list__item__logo'>
            <Icon type={this.props.icon} className='wizard-todo__list__icon-wrap' />
          </div>
          <div className={cs('wizard-todo__list__item__text', whiteLabel)} >
            <h2><b>{ this.props.title }</b></h2>
            { this.props.text }
          </div>
          <div className={'wizard-todo__list__item__arrow'} />
        </div>
      </ComponentToWrap>
    )
  }
}

export default ListItem
