import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'

const EventMessageBox = (props) => {
  const { t } = props
  return (
    <div>
      <h2><b>{ t('title').s }</b></h2>
      <br />
      <h2>{ t('description').s }</h2>
      <br />
    </div>
  )
}
EventMessageBox.propTypes = {
  t: PropTypes.func
}

function mapStateToProps (state) {
  return {
    t: state.i18n.get('app', 'views', 'LoginView', 'wrongApp')
  }
}

export default connect(mapStateToProps)(EventMessageBox)
