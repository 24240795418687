import getGreyLabelOptions from 'GreyLabel/helpers/greyLabel'
import { getCurrentClientCompany } from 'api-client-connector/utils'

export const isKivraFeatureAvailable = () => isSwedishUser()
export const isSalaryFeatureAvailable = () => isHiddenRelease() || isSwedishUser()
export const isAvailableForOneUser = () => isHiddenRelease() || getCurrentClientCompany() === '274'
export const areReportsAvailable = () => isHiddenRelease() || [274, 38007, 26363, 32371].includes(Number(getCurrentClientCompany()))
export const isIntegrationsButtonAvailable = () => isHiddenRelease() || isSwedishUser()
export const isOpenPaymentsAvailable = () => isMrshoeboxRelease()

export const isMrshoeboxRelease = () => {
  const { whiteLabel } = getGreyLabelOptions()
  return isHiddenRelease() || whiteLabel === 'mrshoebox'
}
export const areLoansAvailable = () => {
  // Capcito doesn't work for now, can be returned back when it will be fixed
  return false
}
export const isHiddenRelease = () => process.env.APP_ENV === 'staging' || process.env.APP_ENV === 'development'
export const isSwedishUser = () => getGreyLabelOptions().country === 'se'
