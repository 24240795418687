import React from 'react'
import PropTypes from 'prop-types'
import { formatCurrency } from 'mrshoebox-ui-components/src/helpers/countrySpecific'

import './index.scss?global'

const Legend = ({ colors, data }) =>
  <div className='legend'>
    <div className='legend__rows'>
      {data.map((row, i) =>
        <LegendRow key={i} color={colors[i]} value={row.sum} name={row.name} />
      )}
    </div>
  </div>

Legend.propTypes = {
  colors: PropTypes.array,
  data: PropTypes.array
}

const LegendRow = ({ color, value, name }) =>
  <div className='legend-row'>
    <div className='legend-row__color' style={{'backgroundColor': color}} />
    <div className='legend-row__name'>{name}</div>
    <div className='legend-row__value'>{formatCurrency(value, { withSymbol: true })}</div>
  </div>

LegendRow.propTypes = {
  color: PropTypes.string,
  value: PropTypes.number,
  name: PropTypes.string
}

export default Legend
