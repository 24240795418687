import PropTypes from 'prop-types'
import React, { Component } from 'react'

// Components
import Navigation from 'Navigation/Navigation'
import FinanceVisualizationTopControl from 'Projects/views/ProjectView/FinanceVisualization/components/TopControl'
import FinanceVisualizationCharts from 'Projects/views/ProjectView/FinanceVisualization/components/Charts'

// Containers
import FinanceVisualizationContainer from 'Projects/views/ProjectView/FinanceVisualization/containers/Common'

class FinanceVisualizationView extends Component {
  static propTypes = {
    t: PropTypes.func
  }

  render () {
    const { t } = this.props

    return (
      <Navigation title={t('visualization').s} hideFooter className='f-column finance-visualization'>
        <FinanceVisualizationTopControl {...this.props} />
        <FinanceVisualizationCharts {...this.props} />
      </Navigation>
    )
  }
}

export default FinanceVisualizationContainer(FinanceVisualizationView)
