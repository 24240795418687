import { NAVBAR_SHOW_SPINNER, NAVBAR_HIDE_SPINNER, NAVBAR_SHOW_SLOW_SPINNER } from '../constants/NavbarSpinner'

const initialState = {
  value: false,
  mode: 'normal'
}

export default function (state = initialState, action) {
  switch (action.type) {
    case NAVBAR_SHOW_SPINNER:
      return {
        value: true,
        mode: 'normal'
      }
    case NAVBAR_HIDE_SPINNER:
      return {
        value: false,
        mode: 'normal'
      }
    case NAVBAR_SHOW_SLOW_SPINNER:
      return {
        value: true,
        mode: 'slow'
      }
    default:
      return state
  }
}
