import './SendMessageForm.scss?global'

import PropTypes from 'prop-types'

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { reduxForm, Field, formValueSelector } from 'redux-form'
import { get } from 'lodash'
import { routerActions } from 'react-router-redux'

import Button from 'shared/components/Button/Button'
import Switcher from 'shared/components/InputSwitcher/InputSwitcher'
import TextArea from 'shared/components/FormTextarea'
import SuggestionInput from 'shared/components/SuggestionInput'
import SendInvoiceBlock from './SendInvoiceBlock'
import KivraModalLayout from 'Invoices/CustomerInvoices/views/InvoiceSendView/components/KivraModalLayout/KivraModalLayout'
import KivraButton from 'Integrations/Kivra/components/KivraButton'
import Modal from 'shared/components/NewModal/NewModal'
import KivraRegister from '../KivraRegister'

import { PRIVATE_PERSON } from 'Invoices/shared/constants/PersonType'
import { COLLECTOR_INVOICE_CANCELLED } from 'Invoices/shared/constants/CollectorInvoiceStatuses'
import { CREDIT_INVOICE } from 'Invoices/shared/constants/InvoiceTypes'
import { NOT_TENANT } from 'Invoices/CustomerInvoices/views/InvoiceSendView/constants/kivraValidationErrorCodes'
import { ORIGINAL_INVOICE_SENT_VIA_KIVRA, ORIGINAL_INVOICE_SENT_VIA_MAIL } from 'Invoices/CustomerInvoices/views/InvoiceSendView/constants/kivraValidationInfoCodes'

import { sellInvoice, sendCreditInvoice } from 'MarketPlace/Collector/actions'
import { sendInvoiceWithKivra } from '../../actions/invoiceSend'

import { isCollectorEnabled } from 'MarketPlace/Collector/helpers'
import { isKivraFeatureAvailable } from 'shared/helpers/releaseHelpers'

const renderEmailSuggestion = (value) => value

export class SendMessageForm extends Component {
  static propTypes = {
    customerVatNumber: PropTypes.string,
    handleSubmit: PropTypes.func,
    title: PropTypes.string.isRequired,
    sendEmailHandler: PropTypes.func.isRequired,
    withAdditionalFeesSwitcher: PropTypes.bool,
    email: PropTypes.string,
    t: PropTypes.func,
    userIsKivraTenant: PropTypes.bool,
    userSettings: PropTypes.object,
    location: PropTypes.object,
    redirectToKivraTenantRegistration: PropTypes.func.isRequired,
    redirectToFactoring: PropTypes.func.isRequired,
    redirectToOnboardingStatus: PropTypes.func.isRequired,
    isPrivatePerson: PropTypes.bool.isRequired,
    isSendEmailDisabled: PropTypes.bool,
    isSendViaKivraDisabled: PropTypes.bool,
    onboardingStatus: PropTypes.string.isRequired,
    cancelledByCollector: PropTypes.bool.isRequired,
    sendInvoiceWithKivra: PropTypes.func.isRequired,
    sendInvoiceToCollector: PropTypes.func.isRequired,
    invoiceId: PropTypes.string,
    hasCreditInvoiceType: PropTypes.bool,
    kivraValidation: PropTypes.object
  }

  static defaultProps = {
    withAdditionalFeesSwitcher: false
  }

  constructor (props) {
    super(props)
    this.state = { modalType: null }
  }

  componentDidMount () {
    const { location: { query: { cameFrom } } } = this.props

    if (cameFrom === 'tenant-register') {
      this.setState({ modalType: 'send-via-kivra' }, this.showModalBox)
    }
  }

  get additionalFeesSwitcher () {
    const { withAdditionalFeesSwitcher, t } = this.props
    if (withAdditionalFeesSwitcher) {
      return [
        <div className='form__item invoice-send-message__switcher-field'>
          <div><span>{t('reminderFee').s}</span></div>
          <div><Field name='reminderFee' component={Switcher} dark /></div>
        </div>
        // TODO: commented for TREP-671. Uncomment when needed.
        // <div className='form__item invoice-send-message__switcher-field'>
        //   <div><span>{t('lateFee').s}</span></div>
        //   <div><Switcher dark {...lateFee} /></div>
        // </div>
      ]
    } else {
      return null
    }
  }

  getEmailSuggestions = () => {
    const currentUserEmail = get(this.props.userSettings, 'user.email', '')
    const inputValue = this.props.email
    if (!inputValue || currentUserEmail.startsWith(inputValue)) {
      return [currentUserEmail]
    } else {
      return []
    }
  }

  renderSendEmailBlock () {
    const { title, t, isSendEmailDisabled, handleSubmit } = this.props
    // For credit invoice we are allowing user to use only the same send method which was used for original invoice.
    return (
      <div>
        <div className='form f-column-top'>
          <div className='send-message-form-title'><b>{title}</b></div>
          <div className='form__item'>
            <Field
              name='email'
              component={WrappedSuggestionInput}
              renderSuggestion={renderEmailSuggestion}
              getInputValue={renderEmailSuggestion}
              getSuggestions={this.getEmailSuggestions}
              placeholder={t('email').s}
              disabled={isSendEmailDisabled}
            />
          </div>
          { this.additionalFeesSwitcher }
          <div className='form__item'>
            <Field name='message' component={TextArea} ref='cause' placeholder={t('message').s} label={t('message').s} disabled={isSendEmailDisabled} />
          </div>
        </div>
        <div className='f-column-bottom'>
          <Button
            saveButton
            view='transparent-black'
            onClick={handleSubmit(this.sendEmailHandler)}
            disabled={isSendEmailDisabled}
          >
            {t('buttons', 'send').s}
          </Button>
        </div>
        {isSendEmailDisabled &&
          <span className='info'>{t('infoList', 'infoCodes', ORIGINAL_INVOICE_SENT_VIA_KIVRA).s}</span>
        }
      </div>
    )
  }

  sendWithKivraHandler = () => {
    const { userIsKivraTenant } = this.props
    if (!userIsKivraTenant) {
      this.setState({ modalType: 'register' }, this.showModalBox)
    } else {
      this.setState({ modalType: 'send-via-kivra' }, this.showModalBox)
    }
  }

  sendEmailHandler = (values) => {
    const { kivraValidation, sendEmailHandler, userIsKivraTenant, isSendViaKivraDisabled } = this.props

    if (
      isKivraFeatureAvailable() && !isSendViaKivraDisabled &&
      (kivraValidation.valid || (kivraValidation.errors.length === 1 && !userIsKivraTenant))
    ) {
      this.setState({ modalType: 'send-via-kivra-suggestion' }, this.showModalBox)
    } else {
      sendEmailHandler(values)
    }
  }

  registerHandler = () => {
    this.closeModalBox()
    this.props.redirectToKivraTenantRegistration(this.props.invoiceId)
  }

  get modalContent () {
    const { modalType } = this.state
    const {
      t, invoiceId, customerVatNumber, sendEmailHandler,
      sendInvoiceWithKivra, kivraValidation, handleSubmit
    } = this.props
    switch (modalType) {
      case 'register':
        return <KivraRegister onSubmit={this.registerHandler} onCancel={this.closeModalBox} />
      case 'send-via-kivra':
        return (
          <KivraModalLayout
            content={
              <React.Fragment>
                <h2>{t('kivraModals', 'sendInvoice', 'texts', 'header').s}</h2>
              </React.Fragment>
            }
            buttons={
              <React.Fragment>
                <KivraButton
                  saveButton
                  green
                  onClick={() => { this.closeModalBox(); sendInvoiceWithKivra({ invoiceId, recipientId: customerVatNumber }) }}
                  disabled={!kivraValidation.valid}
                >
                  {t('kivraModals', 'sendInvoice', 'buttons', 'send').s}
                </KivraButton>
              </React.Fragment>
            }
          />
        )
      case 'send-via-kivra-suggestion':
        const registerOrSendInvoiceWithKivra = () => {
          if (!this.props.userIsKivraTenant) {
            this.setState({ modalType: 'register' })
          } else {
            this.closeModalBox()
            sendInvoiceWithKivra({ invoiceId, recipientId: customerVatNumber })
          }
        }
        const sendEmail = (values) => {
          this.closeModalBox()
          sendEmailHandler(values)
        }
        return (
          <KivraModalLayout
            content={
              <React.Fragment>
                <h2>{t('kivraModals', 'sendWithKivraSuggestion', 'texts', 'header').s}</h2>
                <span>{t('kivraModals', 'sendWithKivraSuggestion', 'texts', 'details').s}</span>
              </React.Fragment>
            }
            buttons={
              <React.Fragment>
                <KivraButton saveButton green onClick={() => registerOrSendInvoiceWithKivra()}>
                  {t('kivraModals', 'sendWithKivraSuggestion', 'buttons', 'sendWithKivra').s}
                </KivraButton>
                <KivraButton saveButton white onClick={handleSubmit(sendEmail)}>
                  {t('kivraModals', 'sendWithKivraSuggestion', 'buttons', 'sendEmail').s}
                </KivraButton>
              </React.Fragment>
            }
          />
        )
      default:
        return null
    }
  }

  closeModalBox = () => {
    this.refs.modal.close()
    setTimeout(() => {
      this.setState({ modalType: null })
    }, 750)
  }

  showModalBox = () => this.refs.modal.open()

  render () {
    const {
      sendInvoiceToCollector, invoiceId, redirectToFactoring, isPrivatePerson, redirectToOnboardingStatus,
      onboardingStatus, cancelledByCollector, t, hasCreditInvoiceType, kivraValidation, isSendViaKivraDisabled
    } = this.props

    return (
      <div className='f-column send-message-form'>
        {this.renderSendEmailBlock()}
        <Modal ref='modal' onClose={this.closeModalBox} small>
          { this.modalContent }
        </Modal>
        {isKivraFeatureAvailable() &&
          <SendInvoiceBlock
            t={t}
            sendInvoice={sendInvoiceToCollector}
            redirectToFactoring={redirectToFactoring}
            invoiceId={invoiceId}
            redirectToOnboardingStatus={redirectToOnboardingStatus}
            isPrivatePerson={isPrivatePerson}
            onboardingStatus={onboardingStatus}
            showSendToCollectorText={hasCreditInvoiceType}
            sendWithKivraHandler={this.sendWithKivraHandler}
            kivraValidation={kivraValidation}
            collectorButtonIsVisible={!cancelledByCollector && isCollectorEnabled()}
            kivraButtonIsVisible={isKivraFeatureAvailable()}
            originalInvoiceWasSentViaMail={isSendViaKivraDisabled}
          />
        }
      </div>
    )
  }
}

function WrappedSuggestionInput (props) {
  return (
    <SuggestionInput inputProps={props.input} {...props} />
  )
}

WrappedSuggestionInput.propTypes = {
  input: PropTypes.object
}

const formName = 'invoiceMessageForm'

function mapStateToProps (state) {
  const { id, invoiceType, collectorStatus, originalInvoice, originalInvoiceId, customerVatNumber = null, kivraValidation } = state.invoices.customerInvoices.invoice
  const cancelledByCollector = collectorStatus === COLLECTOR_INVOICE_CANCELLED
  const hasCreditInvoiceType = invoiceType === CREDIT_INVOICE
  const userIsKivraTenant = !kivraValidation.errors.filter(error => error.code === NOT_TENANT).length
  return {
    userSettings: state.userSettings,
    isPrivatePerson: state.invoices.customerInvoices.invoice.customer.customerType === PRIVATE_PERSON,
    onboardingStatus: state.marketplace.collector.onboardingStatus,
    invoiceId: id,
    originalInvoiceId,
    cancelledByCollector,
    hasCreditInvoiceType,
    originalInvoice,
    userIsKivraTenant,
    kivraValidation,
    isSendEmailDisabled: Boolean(kivraValidation.info.find(e => e.code === ORIGINAL_INVOICE_SENT_VIA_KIVRA)),
    isSendViaKivraDisabled: Boolean(kivraValidation.info.find(e => e.code === ORIGINAL_INVOICE_SENT_VIA_MAIL)),
    t: state.i18n.get('app', 'views', 'Invoices', 'InvoiceSendView'),
    customerVatNumber,
    initialValues: {
      email: state.invoices.customerInvoices.invoice.customer.email,
      message: '',
      reminderFee: false,
      lateFee: false
    },
    email: formValueSelector(formName)(state, 'email')
  }
}

function mapDispatchToProps (dispatch) {
  return {
    redirectToFactoring: () => dispatch(routerActions.push('/marketplace/factoring')),
    redirectToOnboardingStatus: () => dispatch(routerActions.push('/todo/onboarding_status')),
    redirectToKivraTenantRegistration: (invoiceId) => dispatch(routerActions.push(`/integrations/kivra-tenant?cameFromInvoiceId=${invoiceId}`)),
    sellInvoice: (invoiceId) => dispatch(sellInvoice(invoiceId)),
    sendCreditInvoice: (creditInvoiceId) => dispatch(sendCreditInvoice(creditInvoiceId)),
    sendInvoiceWithKivra: (data) => dispatch(sendInvoiceWithKivra(data))
  }
}

function mergeProps (stateProps, dispatchProps, ownProps) {
  const { sendCreditInvoice, sellInvoice } = dispatchProps
  const { hasCreditInvoiceType } = stateProps
  const sendInvoiceToCollector = hasCreditInvoiceType ? sendCreditInvoice : sellInvoice

  return {
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    sendInvoiceToCollector
  }
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(
  reduxForm({ form: formName, enableReinitialize: true })(SendMessageForm)
)
