import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { routerActions } from 'react-router-redux'

import IpadNavigation from 'Navigation/IpadNavigation'
import {EventsImportView as EventsImport} from 'Events/EventsImport/views/containers/Smartphones'
import Filters from 'Events/Events/components/Filter/Filter'

import getLeftComponent from 'Navigation/helpers/linkAccordance'

class EventsImportView extends Component {
  static propTypes = {
    t: PropTypes.func,
    importItemsToForm: PropTypes.func,
    itemsToImport: PropTypes.arrayOf(PropTypes.object),
    one: PropTypes.bool,
    backLink: PropTypes.string,
    previousLink: PropTypes.string,
    cameFrom: PropTypes.string,
    dispatch: PropTypes.func,
    location: PropTypes.object
  }

  constructor (props) {
    super(props)
    this.extendList = this.extendList.bind(this)
    this.onScroll = this.onScroll.bind(this)
  }

  extendList () {
    this.refs.eventsImport.getWrappedInstance().extendList()
  }

  onScroll () {
    this.refs.filters.onCollapseFilter()
  }

  render () {
    const {t, one, importItemsToForm, backLink, previousLink, cameFrom, dispatch} = this.props
    const itemsToImport = one ? this.props.itemsToImport[0] : this.props.itemsToImport
    const actions = cameFrom && [{icon: 'icon-add', callback: () => { dispatch(routerActions.push(this.props.location.pathname + '?popup_view=firstStep&cameFrom=invoice')) }}]

    const RightSideItems = getLeftComponent('selectableItemList', previousLink)

    const filterComponent = (
      <Filters ref='filters' onImportClick={() => { importItemsToForm(itemsToImport) }} />
    )

    return (
      <IpadNavigation {...this.props} title={t('title').s} actions={actions} filter={filterComponent} infinityScrollHandler={this.extendList} scrollHandler={this.onScroll} backLink={backLink}>
        <RightSideItems {...this.props} />
        <EventsImport {...this.props} ref='eventsImport' />
      </IpadNavigation>
    )
  }
}

function mapStateToProps (state) {
  return {
    previousLink: state.appInfo.previousLink,
    itemsToImport: state.importItems,
    t: state.i18n.get('app', 'views', 'EventsImport')
  }
}

export default connect(mapStateToProps)(EventsImportView)
