import { createReducer } from 'redux-act'
import { salariesToStore, salaryToStore, addBenefit, totalsToStore, clearSalary, calculate } from 'Salaries/actions'

const initialState = {
  list: [],
  total: 0,
  salary: {
    benefits: [],
    employeeId: null,
    projectId: null,
    netSalary: 0,
    grossSalary: 0,
    totals: {
      employerTax: 0,
      totalEmployeeTax: 0,
      companyTotalSalaryCost: 0
    },
    paymentAccount: {},
    isCalculating: false
  }
}

export default createReducer({
  [salariesToStore]: (state, payload) => ({
    ...state,
    list: payload.salaries,
    total: payload.total
  }),
  [salaryToStore]: (state, payload) => ({
    ...state,
    salary: {
      ...state.salary,
      ...payload
    }
  }),
  [clearSalary]: (state) => ({
    ...state,
    salary: initialState.salary
  }),
  [addBenefit]: (state, payload) => ({
    ...state,
    salary: {
      ...state.salary,
      benefits: [...state.salary.benefits, payload]
    }
  }),
  [totalsToStore]: (state, payload) => ({
    ...state,
    salary: {
      ...state.salary,
      totals: payload
    }
  }),
  [calculate]: (state, payload) => ({
    ...state,
    salary: {
      ...state.salary,
      isCalculating: payload
    }
  })
}, initialState)
