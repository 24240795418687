import PropTypes from 'prop-types'
import React, { Component } from 'react'
import {connect} from 'react-redux'

// Components
import IpadNavigation from 'Navigation/IpadNavigation'
import Navigation from 'Navigation/Navigation'

import PercentLineChart from 'Overview/FinanceVisualization/components/PercentLineChart'
import FinanceVisualizationTopControl from 'Overview/FinanceVisualization/components/TopControl'
import ListView from 'shared/components/ListView/ListView'
import SettingsModalList from 'Overview/Overview/components/SettingsList/SettingsList'
import FinanceVisualizationCharts from 'Overview/FinanceVisualization/components/Charts'

// Containers
import FinanceVisualizationContainer from 'Overview/FinanceVisualization/containers/Common'

class LeftSide extends Component {
  static propTypes = {
    t: PropTypes.func,
    totals: PropTypes.object
  }
  getView () {
    const {totals} = this.props
    if (totals.finance && totals.finance.length) {
      return (
        <PercentLineChart {...this.props} />
      )
    } else if (totals.finance && totals.finance.length === 0) {
      return (
        <ListView data={[]} emptyListOptions={{icon: 'icon-events'}} />
      )
    }
  }
  render () {
    const {t} = this.props
    return (
      <Navigation title={t('navbar', 'title').s} className='finance-visualization'>
        {this.getView()}
      </Navigation>
    )
  }
}

function mapStateToProps (state) {
  return {
    t: state.i18n.get('app', 'views', 'OverviewView')
  }
}

const LeftSideView = connect(mapStateToProps)(LeftSide)

class FinanceVisualizationView extends Component {
  static propTypes = {
    t: PropTypes.func,
    totals: PropTypes.object,
    settings: PropTypes.object,
    loadInfo: PropTypes.func,
    params: PropTypes.object
  }

  getTopControl () {
    const {totals} = this.props
    let props = {}
    if (totals.finance && totals.finance.length === 0) {
      props.onlyLeftSide = true
    } else {
      props.filter = (<FinanceVisualizationTopControl {...this.props} tabletMode openSettings={this.openSettings} />)
    }
    return props
  }

  openSettings = () => {
    this.settingsModal.open()
  }
  render () {
    const {t, loadInfo, settings: {type = 'result'}, params} = this.props
    return (
      <IpadNavigation {...this.props} title={t('title', params.type).s} {...this.getTopControl()} className='finance-visualization'>
        <LeftSideView {...this.props} />
        <div>
          <FinanceVisualizationCharts {...this.props} tabletMode />
          <SettingsModalList onSaveClick={loadInfo} provideController={(c) => { this.settingsModal = c }} type={type} />
        </div>
      </IpadNavigation>
    )
  }
}

export default FinanceVisualizationContainer(FinanceVisualizationView)
