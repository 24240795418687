import './styles/index.scss?global'

import Smartphones from 'Projects/views/ProjectView/Preview/containers/Smartphones'
import Tablets from 'Projects/views/ProjectView/Preview/containers/Tablets'

import {getCurrentDeviceView} from 'shared/helpers/utils'

const ProjectPreviewView = getCurrentDeviceView(Smartphones, Tablets)

export default ProjectPreviewView
