import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import IpadNavigation from 'Navigation/IpadNavigation'
import Settings from 'User/Settings/views/SettingsView/containers/Smartphones'
import { AccountSettingsView as AccountSettings } from 'User/Settings/views/AccountSettingsView/containers/Smartphones'

class SettingsView extends Component {
  static propTypes = {
    t: PropTypes.func
  }
  render () {
    const { t } = this.props
    return (
      <IpadNavigation {...this.props} title={t('title').s}>
        <Settings {...this.props} />
        <AccountSettings {...this.props} />
      </IpadNavigation>
    )
  }
}

function mapStateToProps (state) {
  return {
    t: state.i18n.get('app', 'views', 'AccountSettingsView')
  }
}

export default connect(mapStateToProps)(SettingsView)
