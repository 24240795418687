import React from 'react'
import PropTypes from 'prop-types'
import { formatCurrency } from 'mrshoebox-ui-components/src/helpers/countrySpecific'

import './index.scss?global'

const DataRow = ({ value, description }) =>
  <div className='data-row'>
    <span>{description}</span>
    <span>{formatCurrency(value, { withSymbol: true })}</span>
  </div>

DataRow.propTypes = {
  value: PropTypes.number,
  description: PropTypes.string
}

export default DataRow
