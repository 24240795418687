import { bind } from 'redux-effects'
import { createAction } from 'redux-actions'
import { camelizeKeys } from 'humps'
import { fetch2 as fetch } from 'shared/helpers/fetch'

import { NOTIFICATIONS_UPDATE } from 'Notifications/constants/ActionTypes'
import { NOTIFICATIONS } from 'Notifications/constants/Api'
import { countNotificationsNumber } from 'Notifications/helpers'

import { updateBadgeCounter } from 'appBridge/notificators/app'
import { updateUnreadCollectorInvoicesIds, updateTodoItems, mapTodoItems } from 'Todo/actions/todo'

const updateNotifications = createAction(NOTIFICATIONS_UPDATE)

export function clearIntervalIdAction () {
  return updateNotifications({ unreadMessagesNum: null })
}

export function updateNotificationBadgesAction () {
  return bind(fetch(NOTIFICATIONS), processNotificationsNumber)
}

function processNotificationsNumber (response) {
  const notifications = camelizeKeys(response.value)
  const count = countNotificationsNumber(notifications)
  return (dispatch) => {
    dispatch([
      updateNotificationsCountAction(count),
      updateUnreadCollectorInvoicesIds(notifications.collectorInvoicesIds),
      updateTodoItems(mapTodoItems(notifications).filter(item => item.value > 0))
    ])
  }
}

export function updateNotificationsCountAction (count = 0) {
  return (dispatch, getState) => {
    const { userSettings } = getState()
    const wizardProfileWasPassed = userSettings.company && userSettings.company.organizationNumber
    const mainPageHintsWereShown = userSettings.mainPageHintsWereShown
    if (!count && !wizardProfileWasPassed && mainPageHintsWereShown) {
      count = 1
    }
    updateBadgeCounter(count)

    return dispatch(updateNotifications({
      unreadMessagesNum: count
    }))
  }
}
