import PropTypes from 'prop-types'
import React, { Component } from 'react'

// Components
import IpadNavigation from 'Navigation/IpadNavigation'
import Filters from 'Articles/components/Filters/Filters'
import {Articles} from 'Articles/views/ArticlesImportView/containers/Smartphones'

// Containers
import ArticleImportContainer from 'Articles/views/ArticlesImportView/containers/Common'

import getLeftComponent from 'Navigation/helpers/linkAccordance'

class ArticlesImportView extends Component {
  static propTypes = {
    t: PropTypes.func,
    previousLink: PropTypes.string,
    articles: PropTypes.arrayOf(PropTypes.object),
    itemsToImport: PropTypes.arrayOf(PropTypes.object),
    extendList: PropTypes.func,
    importItemsToForm: PropTypes.func
  }

  constructor (props) {
    super(props)
    this.onImportClick = this.onImportClick.bind(this)
  }

  onImportClick () {
    const { importItemsToForm, itemsToImport } = this.props
    importItemsToForm(itemsToImport)
  }

  render () {
    const { t, articles, extendList, importItemsToForm, itemsToImport, previousLink } = this.props
    const RightSideItems = getLeftComponent('selectableItemList', previousLink)
    const actions = [{ path: '/articles/new?cameFrom=invoice', icon: 'icon-add' }]
    const filterComponent = (
      <Filters canBeHidden={!articles.length} onImportClick={() => { importItemsToForm(itemsToImport) }} />
    )

    return (
      <IpadNavigation {...this.props} title={t('navbar', 'title').s} filter={filterComponent} actions={actions} infinityScrollHandler={extendList}>
        <RightSideItems {...this.props} />
        <Articles {...this.props} />
      </IpadNavigation>
    )
  }
}

export default ArticleImportContainer(ArticlesImportView)
