import PropTypes from 'prop-types'
import React, { Component } from 'react'
import URI from 'urijs'
import { Link } from 'react-router'
import { includes } from 'lodash'

// Components
import FormatNumber from 'shared/components/FormatNumber/FormatNumber'

export default class PercentLineChart extends Component {
  static propTypes = {
    totals: PropTypes.object,
    settings: PropTypes.object,
    location: PropTypes.object,
    getColor: PropTypes.func
  }

  render () {
    const {totals: {finance}, getColor, location: {pathname}, settings: {forecast: {from, to}}} = this.props
    const isForecast = includes(pathname, 'forecast')
    const financeType = includes(pathname, 'revenues') ? 'revenue' : 'expenses'
    const render = (data, i) => {
      const {categoryName, categorySum, percent, categoryId} = data
      const ComponentToUse = isForecast ? Link : 'div'
      const link = URI('/overview/details_by_account').addSearch({ accountId: String(categoryId), financeType, from, to }).toString()
      return (
        <ComponentToUse to={link} className={`finance-visualization__bar ${isForecast ? 'finance-visualization__bar--link' : ''}`} key={i}>
          <div>
            <div className='finance-visualization__bar__line'>
              <span style={{width: `${percent}%`, backgroundColor: getColor(i)}} />
              <h6>{parseInt(percent, 10) || '< 1'}%</h6>
            </div>
            <div className='finance-visualization__bar__info'>
              <div className='truncate'>{categoryName}</div>
              <div><FormatNumber value={categorySum} notFraction /></div>
            </div>
          </div>
          {isForecast && <h4><span className='icon-arrow-right' /></h4>}
        </ComponentToUse>
      )
    }

    return (
      <div className='finance-visualization__bar-list'>
        {finance && finance.map((item, i) => {
          return render(item, i)
        })}
      </div>
    )
  }
}
