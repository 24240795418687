import PropTypes from 'prop-types'
import React, { Component } from 'react'
import {connect} from 'react-redux'

// Components
import Navigation from 'Navigation/Navigation'
import Button from 'shared/components/Button/Button'
import FormatDate from 'shared/components/FormatDate/FormatDate'

// Actions
import { loadEventMessagesAction, pushNewEventMessageAction, markAsReadMessagesAction } from 'Events/Event/actions/eventMessages'
import getUserSettingsAction from 'User/Settings/actions/userSettings'

// Helpers
import {scrollToContainerBottom} from 'shared/helpers/scrollUtils'
import getGreyLabelOptions from 'GreyLabel/helpers/greyLabel'

class EventMessagesTextBlock extends Component {
  static propTypes = {
    messages: PropTypes.array,
    userId: PropTypes.number
  };

  defineMessageStyle (messageId, userId) {
    if (Number(messageId) === Number(userId)) {
      return 'event-messages__chat__item event-messages__chat__item--my'
    } else {
      return 'event-messages__chat__item'
    }
  }

  render () {
    const {messages, userId} = this.props
    return (
      <div>
        {messages.map((message) => {
          return (
            <div className={this.defineMessageStyle(message.sender_id, userId)} key={message.id}>
              <div>
                <div className='event-messages__chat__item__text'><b>{message.comment}</b></div>
                <div className='event-messages__chat__item__date'>
                  <FormatDate format='YYYY-MM-DD HH:mm' value={message.date_time_sent} />
                </div>
              </div>
            </div>
          )
        })}
      </div>
    )
  }
}

export class EventMessages extends Component {
  static propTypes = {
    eventId: PropTypes.string,
    getMessages: PropTypes.func,
    pushNewEventMessage: PropTypes.func,
    changeMessagesStatus: PropTypes.func,
    messages: PropTypes.array,
    unreadMessages: PropTypes.array,
    getUserSettings: PropTypes.func,
    userId: PropTypes.number,
    t_button: PropTypes.func
  }

  componentWillMount () {
    this.props.getMessages()
  }

  componentWillUnmount () {
    this.fixContentStyle(false)
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.unreadMessages !== this.props.unreadMessages) {
      if (nextProps.unreadMessages.length) {
        this.props.changeMessagesStatus(nextProps.unreadMessages)
      }
    }
  }

  componentDidMount () {
    this.checkUserId(this.props.userId)
    this.fixContentStyle(true)
  }

  componentDidUpdate () {
    this.scrollBlockToBottom()
  }

  fixContentStyle (val) {
    const scrollBlock = document.getElementsByClassName('scrollBlock__content')
    if (!scrollBlock.length) {
      return
    }
    [].forEach.call(scrollBlock, (elem) => {
      elem.classList.toggle('scrollBlock__content--no-smooth', val)
    })
  }

  scrollBlockToBottom () {
    scrollToContainerBottom()
  }

  checkUserId (id) {
    if (!id) this.props.getUserSettings()
  }

  sendMessage () {
    const message = this.refs.message.value.trim()
    if (message !== '') {
      const {userId, eventId, pushNewEventMessage} = this.props
      pushNewEventMessage(message, userId, eventId)
      this.refs.message.value = ''
    }
  }

  render () {
    const {messages, userId, t_button} = this.props
    const { whiteLabel } = getGreyLabelOptions()
    const messageEntryClass = whiteLabel
      ? `event-messages__entry ${whiteLabel}`
      : 'event-messages__entry'
    return (
      <div className='event-messages'>
        <div className='event-messages__chat'>
          <EventMessagesTextBlock userId={userId} messages={messages} />
        </div>
        <div className={messageEntryClass}>
          <div>
            <div className='event-messages__entry__input'>
              <input autoCorrect='off' ref='message' />
            </div>
            <div className='event-messages__entry__send'>
              <Button view='transparent-white' onClick={() => this.sendMessage()}>{t_button('send').s}</Button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

class EventMessagesWithNavigation extends Component {
  static propTypes = {
    t: PropTypes.func
  }

  render () {
    const {t} = this.props
    return (
      <Navigation title={t('navbar', 'title').s} hideFooter notBottomSpace className='events-messages-navigation'>
        <EventMessages {...this.props} />
      </Navigation>
    )
  }
}

function mapStateToProps (state) {
  let event = state.events.currentEvent
  return {
    messages: event && event.messages ? event.messages : [],
    userId: state.userSettings && state.userSettings.user.id,
    t: state.i18n.get('app', 'views', 'EventMessagesView'),
    t_button: state.i18n.get('app', 'shared', 'buttons')
  }
}

function mapDispatchToProps (dispatch) {
  return {
    getMessages: (id) => { dispatch(loadEventMessagesAction(id)) },
    getUserSettings: () => { dispatch(getUserSettingsAction()) },
    changeMessagesStatus: (unreadMessages) => { dispatch(markAsReadMessagesAction(unreadMessages)) },
    pushNewEventMessage: (...args) => { dispatch(pushNewEventMessageAction(...args)) }
  }
}

function mergeProps (stateProps, dispatchProps, ownProps) {
  const unreadMessages = stateProps.messages.filter(message => message.reporter_has_read === false)
  return Object.assign({}, ownProps, {
    ...stateProps,
    ...dispatchProps,
    eventId: ownProps.routeParams.eventId,
    unreadMessages,
    getMessages: () => dispatchProps.getMessages(ownProps.routeParams.eventId)
  })
}

export const EventMessagesView = connect(mapStateToProps, mapDispatchToProps, mergeProps)(EventMessages)

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(EventMessagesWithNavigation)
