import React from 'react'
import { Route } from 'react-router'
import { destroy } from 'redux-form'
import {requireAuthentication} from '../../../User/Auth/higherOrderComponents/AuthComponent'

import CustomersView from '../views/CustomersView/CustomersView'
import CustomerView from '../views/CustomerView/CustomerView'

// Form-cleaning components
import createClearFormComponent from '../../../shared/factories/ClearForm'
import { clearCustomerInfoAction } from '../actions/customer'
const ClearCustomerForm = createClearFormComponent(clearCustomerInfoAction)
const destroyCustomersListFiltersForm = (dispatch) => dispatch(destroy('customersListFilters'))

const customersRoutes = (
  <Route onLeave={destroyCustomersListFiltersForm}>
    <Route path='/customers' component={requireAuthentication(CustomersView)} />
    <Route component={ClearCustomerForm} >
      <Route path='/customers/:counterpartyId' component={requireAuthentication(CustomerView)} />
    </Route>
  </Route>
)

export default customersRoutes
