import PropTypes from 'prop-types'
import React, { Component } from 'react'

import IpadNavigation from 'Navigation/IpadNavigation'
import Event from 'Events/Event/views/EventView/components/Event/Event'

import getLeftComponent from 'Navigation/helpers/linkAccordance'

// Containers
import EventContainer from 'Events/Event/views/EventView/containers/Common'

// Helpers
import getMultipleActions from 'Events/Event/helpers/getMultipleActions'

export class EventView extends Component {
  static propTypes = {
    event: PropTypes.object,
    t: PropTypes.func,
    getInfo: PropTypes.func,
    previousLink: PropTypes.string,
    params: PropTypes.object.isRequired
  }

  componentWillReceiveProps (props) {
    if (this.props.params.eventId !== props.params.eventId) {
      this.props.getInfo(props.params.eventId)
    }
  }

  render () {
    const {t, previousLink, event} = this.props
    const Events = getLeftComponent('event', previousLink)
    const multipleActions = event && getMultipleActions(this.props, () => { this.refs.event.showEventAvoidModal() })

    return (
      <IpadNavigation {...this.props} withoutMaxWidth title={event && event.title ? event.title : t('navbar', 'title').s} multipleActions={multipleActions} notBack>
        <Events {...this.props} />
        {event && <Event {...this.props} ref='event' />}
      </IpadNavigation>
    )
  }
}

export default EventContainer(EventView)
