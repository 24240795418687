import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Field } from 'redux-form'

import Textarea from 'shared/components/FormTextarea'
import Button from 'shared/components/Button/Button'

class InvoiceMessage extends Component {
  static propTypes = {
    value: PropTypes.string,
    clear: PropTypes.func,
    t: PropTypes.func
  }

  constructor (props) {
    super(props)
    this.state = {
      showTextArea: Boolean(props.value)
    }
    this.removeMessage = this.removeMessage.bind(this)
    this.showMessageArea = this.showMessageArea.bind(this)
  }

  componentWillReceiveProps (nextProps) {
    if (!this.state.showTextArea && nextProps.value) {
      this.setState({
        showTextArea: true
      })
    }
  }

  removeMessage () {
    this.setState({ showTextArea: false }, () => {
      this.props.clear()
    })
  }

  showMessageArea () {
    this.setState({ showTextArea: true })
  }

  get content () {
    const { t } = this.props
    if (this.state.showTextArea) {
      return [
        <Field name='message' component={Textarea} white placeholder={t('message', 'placeholder').s} />,
        <Button view='transparent-red' onClick={this.removeMessage}>{t('message', 'remove').s}</Button>
      ]
    } else {
      return (
        <Button view='transparent-black' onClick={this.showMessageArea}>{t('message', 'add').s}</Button>
      )
    }
  }

  render () {
    const { t } = this.props
    return (
      <div className='create-invoice__message'>
        <div className='hr'><span>{t('labels', 'message').s}</span></div>
        <div className='create-invoice__message__content'>
          { this.content }
        </div>
      </div>
    )
  }
}

export default InvoiceMessage
