/* eslint-disable camelcase */

import PropTypes from 'prop-types'
import React, { Component } from 'react'

// Components
import Navigation from 'Navigation/Navigation'
import FinanceVisualizationTopControl from 'Overview/FinanceVisualization/components/TopControl'
import FinanceVisualizationCharts from 'Overview/FinanceVisualization/components/Charts'
import SettingsModalList from 'Overview/Overview/components/SettingsList/SettingsList'

// Containers
import FinanceVisualizationContainer from 'Overview/FinanceVisualization/containers/Common'

class FinanceVisualization extends Component {
  static propTypes = {
    t: PropTypes.func,
    location: PropTypes.object,
    changeChartType: PropTypes.func,
    settings: PropTypes.object,
    loadInfo: PropTypes.func,
    params: PropTypes.object
  }

  onToggleChartType = () => {
    const { location: { query }, changeChartType } = this.props

    if (query.chart_type === 'bars') {
      changeChartType('pie')
    } else {
      changeChartType('bars')
    }
  }
  getMenuAction () {
    const {location: { query: { chart_type = 'pie' } }} = this.props

    return [{icon: chart_type === 'bars' ? 'icon-pie-chart' : 'icon-bar-chart-slim', callback: this.onToggleChartType}]
  }

  openSettings = () => {
    this.settingsModal.open()
  }

  render () {
    const {t, settings: {type = 'result'}, params, loadInfo} = this.props
    return (
      <Navigation title={t('title', params.type).s} filterBlock className='f-column finance-visualization' filterClass='finance-visualization-filter' actions={this.getMenuAction()}>
        <FinanceVisualizationTopControl {...this.props} openSettings={this.openSettings} />
        <FinanceVisualizationCharts {...this.props} />
        <SettingsModalList onSaveClick={loadInfo} provideController={(c) => { this.settingsModal = c }} type={type} />
      </Navigation>
    )
  }
}

export default FinanceVisualizationContainer(FinanceVisualization)

/* eslint-enable camelcase */
