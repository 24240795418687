import './PhoneBookView.scss?global'

import PropTypes from 'prop-types'

import React, { Component } from 'react'
import { connect } from 'react-redux'
import Infinite from 'react-infinite'
import { get, omit } from 'lodash'
import Fuse from 'fuse.js'

// Components
import Navigation from 'Navigation/Navigation'
import Checkbox from 'shared/components/Checkbox/Checkbox'
import SearchField from 'shared/components/SearchField/SearchField'

// Actions
import { loadContactsListAction, markContactAsCheckedAction, markContactAsUncheckedAction, clearContactsAction } from 'appBridge/PhoneBook/actions/phoneBook'

class PhoneBookView extends Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,

    contacts: PropTypes.arrayOf(PropTypes.object),
    loadContacts: PropTypes.func,
    markContactAsChecked: PropTypes.func,
    markContactAsUnchecked: PropTypes.func,
    t: PropTypes.func,
    clearContacts: PropTypes.func
  }

  constructor (props) {
    super(props)
    this.state = { searchQuery: '' }
    this._onSearchChange = this._onSearchChange.bind(this)
    this._listViewTemplate = this._listViewTemplate.bind(this)
    this._onSubmitHandler = this._onSubmitHandler.bind(this)
  }

  _onSearchChange (e) {
    this.setState({ searchQuery: e.target.value })
  }

  _listViewTemplate (itemProps) {
    const { data: { isChecked, index, name }, key } = itemProps
    const { markContactAsChecked, markContactAsUnchecked } = this.props
    const onItemClick = () => { isChecked ? markContactAsUnchecked(index) : markContactAsChecked(index) }

    return (
      <div className='phonebook-item' onClick={onItemClick} key={key}>
        <div>
          <Checkbox value={isChecked} onChange={onItemClick} />
        </div>
        <h2>{name}</h2>
      </div>
    )
  }

  _onSubmitHandler () {
    const { contacts, onSubmit } = this.props
    const selectedContacts = contacts.filter((c) => c.isChecked)
    const contactsInfoToSubmit = selectedContacts.map((c) => omit(c, ['isChecked', 'index']))

    onSubmit(contactsInfoToSubmit)
  }

  componentDidMount () {
    this.props.loadContacts()
  }

  componentWillUnmount () {
    this.props.clearContacts()
  }

  render () {
    const { t, contacts } = this.props
    const { searchQuery } = this.state

    const fuse = new Fuse(contacts, { keys: ['name'] })
    const filteredContacts = searchQuery ? fuse.search(searchQuery) : contacts

    const containerHeight = get(document.getElementsByClassName('navigation-content'), [0, 'offsetHeight'], 10)

    const actions = [{ icon: 'icon-add', callback: this._onSubmitHandler }]

    return (
      <Navigation title={t('title').s} actions={actions} filterBlock hideFooter notBottomSpace >
        <SearchField onChange={this._onSearchChange} />
        <Infinite
          containerHeight={containerHeight}
          elementHeight={100}
          className='list-view phonebook-list'
        >
          {
            filteredContacts.map((contact, key) => {
              return (
                React.createElement(this._listViewTemplate, {data: contact, key})
              )
            })
          }
        </Infinite>
      </Navigation>
    )
  }
}

function mapStateToProps (state) {
  return {
    t: state.i18n.get('app', 'views', 'PhoneBookView'),
    contacts: state.phoneBook.list
  }
}

function mapDispatchToProps (dispatch) {
  return {
    loadContacts: () => { dispatch(loadContactsListAction()) },
    markContactAsChecked: (index) => { dispatch(markContactAsCheckedAction(index)) },
    markContactAsUnchecked: (index) => { dispatch(markContactAsUncheckedAction(index)) },
    clearContacts: () => { dispatch(clearContactsAction()) }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PhoneBookView)
