import './styles/index.scss?global'

import Smartphones from 'Events/Event/views/EventMessagesView/containers/Smartphones'
import Tablets from 'Events/Event/views/EventMessagesView/containers/Tablets'

import {getCurrentDeviceView} from 'shared/helpers/utils'

const EventMessagesView = getCurrentDeviceView(Smartphones, Tablets)

export default EventMessagesView
