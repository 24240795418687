export const ADD_FILES = 'ADD_FILES'
export const ADD_FILE_TO_VIEW = 'ADD_FILE_TO_VIEW'
export const CLEAR_VIEW = 'CLEAR_VIEW'
export const LOAD_CURRENT_FILES = 'LOAD_CURRENT_FILES'
export const REMOVE_NEW_FILES = 'REMOVE_NEW_FILES'
export const REMOVE_CURRENT_FILES = 'REMOVE_CURRENT_FILES'
export const CLEAR_FILES = 'CLEAR_FILES'
export const SAVE_CURRENT_PATH = 'SAVE_CURRENT_PATH'
export const EDIT_NEW_FILE = 'EDIT_NEW_FILE'
export const FORCE_DELETE_FILES = 'FORCE_DELETE_FILES'
