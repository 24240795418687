import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'

import Input from 'shared/components/Input/Input'
import Modal from 'shared/components/NewModal/NewModal'
import getGreyLabelOptions from 'GreyLabel/helpers/greyLabel'

import GdprTerms from '../GdprTerms/GdprTerms'

const InputWrapper = ({ input, meta, ...props }) => {
  return <Input {...input} {...meta} {...props} />
}
InputWrapper.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object
}

class GdprCheckbox extends PureComponent {
  static propTypes = {
    t: PropTypes.func,
    reduxForm5: PropTypes.bool
  }

  renderInput () {
    const { reduxForm5, t } = this.props
    if (getGreyLabelOptions().greyLabel !== 'blinfo') return null

    const label = (
      <span> { t('signUpAgreement').s } <u onClick={() => this.signGdprModal.open()}> { t('readMore').s } </u></span>
    )
    // TODO maybe move reduxForm5 check to Input component
    if (reduxForm5) {
      return <Input type='checkbox' {...this.props} placeholder={label} />
    } else {
      return (
        <Field
          name='gdprAgreement'
          component={InputWrapper}
          type='checkbox'
          placeholder={label}
          {...this.props}
        />
      )
    }
  }

  render () {
    return (
      <div>
        { this.renderInput() }
        <Modal ref={(c) => { this.signGdprModal = c }}>
          <GdprTerms />
        </Modal>
      </div>
    )
  }
}

function mapStateToProps (state) {
  return {
    t: state.i18n.get('app', 'components', 'GdprCheckbox')
  }
}

export default connect(mapStateToProps)(GdprCheckbox)
