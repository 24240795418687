import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { reduxForm, Field } from 'redux-form'
import { isEqual, omit } from 'lodash'

import Input from 'shared/components/Input/Input'
import DropDown from 'shared/components/Dropdown/Dropdown'
import PersonalNumberInput from 'shared/components/Input/PersonalNumber'
import Button from 'shared/components/Button/Button'

import { orgNumberMaskProps } from 'mrshoebox-ui-components/src/helpers/countrySpecific'

import './styles.scss?global'
import { createSelect } from 'shared/factories/createSelect'
import * as AccountTypes from 'User/Settings/constants/AccountTypes'

class Form extends Component {
  static propTypes = {
    validatedFields: PropTypes.array.isRequired,
    submitHandler: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    setInitialErrors: PropTypes.func.isRequired,
    currentErrors: PropTypes.object,
    makeFieldsTouched: PropTypes.func.isRequired,
    fields: PropTypes.object.isRequired,
    tInvoiceSettings: PropTypes.func.isRequired
  }

  componentWillReceiveProps (nextProps) {
    if (!isEqual(this.props.currentErrors, nextProps.currentErrors)) {
      this.props.setInitialErrors('collector-form', nextProps.currentErrors)
      this.props.makeFieldsTouched(nextProps.currentErrors)
    }
  }

  configureFields (validatedFields) {
    const { tInvoiceSettings } = this.props
    return validatedFields.map(field => {
      if (field.name === 'orgNumber') {
        return { ...field, ...orgNumberMaskProps }
      }

      if (['accountNumber', 'phone', 'postalCode'].includes(field.name)) {
        return { ...field, type: 'tel' }
      }

      if (field.name === 'email') {
        return { ...field, type: 'email' }
      }

      if (field.name === 'accountType') {
        const accountTypesList = createSelect(
          [AccountTypes.BANKGIRO, AccountTypes.PLUSGIRO, AccountTypes.BANK_ACCOUNT],
          tInvoiceSettings('selects', 'accountType')
        )

        return {
          ...field,
          options: accountTypesList,
          hint: tInvoiceSettings('fields', 'accountType').s,
          emptyOption: tInvoiceSettings('emptyOptions', 'accountType').s,
          white: true
        }
      }
      return field
    })
  }

  render () {
    const { t, validatedFields, submitHandler, handleSubmit } = this.props
    const configuredFields = this.configureFields(validatedFields)
    return (
      <div className='collector-form'>
        <form onSubmit={handleSubmit(submitHandler)}>
          {configuredFields.map((field, index) =>
            <Field
              placeholder={t('fieldsNames', field.name).s}
              key={index}
              component={ComponentSelector}
              fieldName={field.name}
              {...field}
            />
          )}
          <Button view={'transparent-white'} type='submit'>
            {t('continue').s}</Button>
        </form>
      </div>
    )
  }
}

const ComponentSelector = ({input, meta, ...restProps}) => {
  const props = omit(restProps, 'fieldName')
  if (restProps.fieldName === 'personalNumber') {
    return <PersonalNumberInput {...input} {...meta} {...props} />
  } else if (restProps.fieldName === 'accountType') {
    return <DropDown {...input} {...meta} {...props} />
  } else {
    return <Input {...input} {...meta} {...props} />
  }
}

export default reduxForm({
  form: 'collector-form',
  enableReinitialize: true
})(Form)
