import React from 'react'
import PropTypes from 'prop-types'

import Button from 'shared/components/Button/Button'
import './index.scss?global'

const ButtonsBlock = (
  { startHandler, cancelHandler, startButtonText, cancelButtonText, cancelButtonView, startButtonView }
) =>
  <div className='marketplace__buttons-block'>
    <Button view={startButtonView || 'primary'} onClick={startHandler}>{startButtonText}</Button>
    <Button view={cancelButtonView} onClick={cancelHandler}>{cancelButtonText}</Button>
  </div>

ButtonsBlock.propTypes = {
  startButtonText: PropTypes.string.isRequired,
  cancelButtonText: PropTypes.string.isRequired,
  startHandler: PropTypes.func.isRequired,
  cancelHandler: PropTypes.func.isRequired,
  cancelButtonView: PropTypes.string,
  startButtonView: PropTypes.string
}

export default ButtonsBlock
