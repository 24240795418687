import PropTypes from 'prop-types'
import React, { Component } from 'react'
import {connect} from 'react-redux'

import Input from 'shared/components/Input/Input'
import PlusButton from 'shared/components/PlusButton/PlusButton'

// Actions
import { removeFileAction, addFileToViewAction } from 'FileUpload/actions/filesToUpload'

export class FileListView extends Component {
  static propTypes = {
    files: PropTypes.arrayOf(PropTypes.object),
    removeFile: PropTypes.func,
    addFileToView: PropTypes.func,
    withoutPaddingBottom: PropTypes.bool
  }

  static defaultProps = {
    files: []
  }

  render () {
    const {files, addFileToView, removeFile, withoutPaddingBottom} = this.props
    return (
      <div className='file-upload__list'>
        {files.map((file, i) => {
          return (
            <div className={'form__item  form__input-plus ' + (withoutPaddingBottom ? 'form__item-remove-padding-bottom' : '')} key={i}>
              <div onClick={() => { addFileToView({...file, removable: true}) }}><Input value={file.file_name || file.name} readOnly /></div>
              <PlusButton type='minus' onClick={() => { removeFile(file) }} />
            </div>
          )
        })}
      </div>
    )
  }
}

function mapDispatchToProps (dispatch) {
  return {
    removeFile: (file) => { dispatch(removeFileAction(file)) },
    addFileToView: (file) => { dispatch(addFileToViewAction(file)) }
  }
}

export default connect(null, mapDispatchToProps)(FileListView)
