import React from 'react'
import { bind } from 'redux-effects'
import { createAction } from 'redux-actions'
import { routerActions } from 'react-router-redux'
import { sortBy, uniq } from 'lodash'
import moment from 'moment'
import axios from 'api-client-connector'

import { getCurrentClientCompany } from 'api-client-connector/utils'
import { showNavbarSpinnerAction, hideNavbarSpinnerAction } from 'shared/actions/navbarSpinner'
import { updateProjectsInSettingsAction } from 'User/Settings/actions/settingsLoader'

import { showElementInMessageBoxAction, closeMessageBoxAction } from 'MessageBox/actions/messageBox'
import ContinueMessageBox from 'MessageBox/components/ContinueMessageBox'

import { fetch, fetch2 } from 'shared/helpers/fetch'

import { PROJECT_PROPS_UPDATE, PROJECT_PROPS_CLEAR, PROJECT_ADD_EVENT_ROW, PROJECT_ADD_MILEAGE_REPORT, PROJECT_ADD_TIME_REPORT } from '../constants/ActionTypes'
import {
  PROJECT_INFO, PROJECT_EXPORT, PROJECT_REVENUES, PROJECT_EXPENSES, PROJECT_NEW,
  PROJECT_CREATE_TIMESHEET, PROJECT_CREATE_TRANSACTION, PROJECT_CREATE_DRIVERLOG,
  PROJECT_DELETE_TIMESHEET, PROJECT_DELETE_TRANSACTION, PROJECT_DELETE_DRIVERLOG
} from '../constants/Api'
import * as eventType from 'Events/shared/constants/eventType'
import { TRANSACTIONS_LIST } from 'Events/shared/constants/Api'
import { EVENTS_LIST_UPDATE } from 'Events/shared/constants/Event'

import { delay } from 'shared/helpers/utils'

const updateProjectProps = createAction(PROJECT_PROPS_UPDATE)
const clearProjectProps = createAction(PROJECT_PROPS_CLEAR)
const addEventRow = createAction(PROJECT_ADD_EVENT_ROW)
const addMileageReport = createAction(PROJECT_ADD_MILEAGE_REPORT)
const addTimeReport = createAction(PROJECT_ADD_TIME_REPORT)
const updateEventsList = createAction(EVENTS_LIST_UPDATE)

const transactionsLimitToPage = 25

export function loadProjectInfoAction (id) {
  return [
    showNavbarSpinnerAction(),
    bind(fetchProject(id), processProject, processError)
  ]
}

export function fetchProject (id) {
  return fetch(PROJECT_INFO, {id, with_relations: true}, {
    method: 'GET'
  })
}

function fetchExportProject (id, sendAsEmail) {
  return fetch(PROJECT_EXPORT, {id, send_as_email: sendAsEmail}, {
    method: 'GET'
  })
}

export function projectPropChangeAction (prop, value) {
  return updateProjectProps({[prop]: value})
}

export function projectInfoClearAction () {
  return clearProjectProps()
}

export function exportProjectAction (projectId) {
  const sendAsEmail = 1
  return [
    showNavbarSpinnerAction(),
    bind(fetchExportProject(projectId, sendAsEmail), processExportProject)
  ]
}

export function projectSaveAction (projectId, data, shouldGoBack = false) {
  if (!projectId) {
    return saveNewProject(data)
  } else {
    return saveProject(projectId, data, shouldGoBack)
  }
}

export function importProjectRelationsAction (projectId, data, shouldGoBack = false) {
  const mappedData = {
    transaction_ids: uniq(data.eventRows.map((r) => { return Number(r.id) })),
    timesheet_ids: uniq(data.timeReports.map((r) => { return r.id })),
    driverlog_ids: uniq(data.mileageReports.map((r) => { return r.id }))
  }
  return [
    showNavbarSpinnerAction(),
    bind([
      sendUpdateProjectRequest(projectId, mappedData)
    ], afterProjectUpdate.bind(this, projectId, shouldGoBack))
  ]
}

function saveNewProject (data) {
  return [
    showNavbarSpinnerAction(),
    bind(sendNewProjectRequest(data), afterNewProjectSave)
  ]
}

function sendNewProjectRequest (values) {
  const {name, customerId, startDate} = values
  const companyId = getCurrentClientCompany()
  const data = {
    name,
    customer_id: customerId,
    company_id: Number(companyId),
    start_date: startDate
  }

  return fetch(PROJECT_NEW, {}, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: data
  })
}

function afterNewProjectSave (response) {
  return (dispatch, getState) => {
    const t = getState().i18n.get('app', 'views', 'ProjectView', 'new')

    const actionsToDispatch = [hideNavbarSpinnerAction(), redirectToProject(response.value.id), updateProjectsInSettingsAction(), closeMessageBoxAction()]
    const onClose = () => { dispatch(actionsToDispatch) }
    return dispatch(
      showElementInMessageBoxAction(<ContinueMessageBox description={t('messages', 'save').s} onClose={onClose} />, onClose)
    )
  }
}

function redirectToProject (projectId) {
  return async (dispatch) => {
    dispatch(routerActions.goBack())
    await delay(100) // FIXME wtf. And why need to go back if next action - redirect?
    dispatch(routerActions.push(`/projects/${projectId}`))
  }
}

function saveProject (projectId, data, shouldGoBack) {
  const mappedData = {
    active: data.active, // проверить что не строка приходит
    name: data.name,
    customer_id: data.customerId,
    start_date: data.startDate
  }
  return [
    showNavbarSpinnerAction(),
    bind([
      sendUpdateProjectRequest(projectId, mappedData)
    ], afterProjectUpdate.bind(this, projectId, shouldGoBack))
  ]
}

function sendUpdateProjectRequest (id, data) {
  return fetch(PROJECT_INFO, {id}, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  },
  {
    rawBody: true
  })
}

function afterProjectUpdate (projectId, shouldGoBack) {
  return (dispatch, getState) => {
    const t = getState().i18n.get('app', 'views', 'ProjectView', 'overview')

    if (shouldGoBack) {
      return dispatch([
        hideNavbarSpinnerAction(),
        routerActions.goBack(),
        loadProjectInfoAction(projectId),
        updateProjectsInSettingsAction()
      ])
    }

    const onClose = () => { dispatch(closeMessageBoxAction()) }

    return dispatch([
      hideNavbarSpinnerAction(),
      showElementInMessageBoxAction(<ContinueMessageBox title={t('message').s} description={t('projectWasSaved').s} onClose={onClose} />, onClose),
      updateProjectsInSettingsAction()
    ])
  }
}

function processExportProject () {
  return (dispatch, getState) => {
    const t = getState().i18n.get('app', 'views', 'ProjectView', 'overview')
    const onClose = () => { dispatch(closeMessageBoxAction()) }

    return dispatch([
      hideNavbarSpinnerAction(),
      showElementInMessageBoxAction(<ContinueMessageBox title={t('message').s} description={t('projectIsExport').s} onClose={onClose} />, onClose)
    ])
  }
}

// TODO: should be moved from action creator file to helper
export function calculateTotalAmounts (events) {
  const initialValues = {
    totalIncomeWithVat: 0,
    totalIncomeWithoutVat: 0,
    totalOutcomeWithVat: 0,
    totalOutcomeWithoutVat: 0
  }

  const totals = events.reduce((prev, curr) => {
    if (curr.eventType === eventType.inType) {
      return {
        ...prev,
        totalIncomeWithVat: prev.totalIncomeWithVat + parseFloat(curr.amount),
        totalIncomeWithoutVat: prev.totalIncomeWithoutVat + parseFloat(curr.amount) - parseFloat(curr.vatAmount)
      }
    } else if (curr.eventType === eventType.outType) {
      return {
        ...prev,
        totalOutcomeWithVat: prev.totalOutcomeWithVat + parseFloat(curr.amount),
        totalOutcomeWithoutVat: prev.totalOutcomeWithoutVat + parseFloat(curr.amount) - parseFloat(curr.vatAmount)
      }
    } else {
      return prev
    }
  }, initialValues)

  return {
    ...totals,
    totalProfitWithVat: totals.totalIncomeWithVat - totals.totalOutcomeWithVat,
    totalProfitWithoutVat: totals.totalIncomeWithoutVat - totals.totalOutcomeWithoutVat
  }
}

// TODO: should be moved from action creator file to helper
export function calculateTotalMinutes (reports) {
  const total = reports.reduce(function (prev, curr) {
    return prev + Number(curr.minutes)
  }, 0)
  return total
}

// TODO: should be moved from action creator file to helper
export function calculateTotalDistance (distances) {
  const total = distances.reduce(function (prev, curr) {
    return prev + parseFloat(curr.distance)
  }, 0)
  return total
}

export function getStatistics (project) {
  const statistics = project.statistics
  const totals = {
    totalIncomeWithVat: statistics.transactions.sum_IN_with_vat,
    totalIncomeWithoutVat: statistics.transactions.sum_IN_without_vat,
    totalOutcomeWithVat: statistics.transactions.sum_OUT_with_vat,
    totalOutcomeWithoutVat: statistics.transactions.sum_OUT_without_vat,
    totalMinutes: statistics.timesheets.total_duration,
    totalTimesheetsPrice: statistics.timesheets.total_price,
    totalDistance: statistics.driverlogs.total_distance,
    totalDriverlogsCompensation: statistics.driverlogs.total_compensation
  }

  return {
    ...totals,
    totalProfitWithVat: totals.totalIncomeWithVat - totals.totalOutcomeWithVat,
    totalProfitWithoutVat: totals.totalIncomeWithoutVat - totals.totalOutcomeWithoutVat
  }
}

function processProject (response) {
  const project = response.value
  const timeReports = mapTimeReports(project.timesheets)
  const mileageReports = mapMileageReports(project.driverlogs)
  const eventRows = mapEventRows(project.transactions)
  const projectInfo = {
    id: project.id,
    name: project.name,
    customerId: project.customer_id,
    customerName: project.customer && project.customer.name,
    includeVat: true, // TODO this is frontend-only checkbox for interactivity
    active: project.active,
    startDate: project.start_date,
    latestUpdate: moment(project.latest_update).format('YYYY-MM-DD'),
    eventRows,
    timeReports,
    mileageReports,
    ...getStatistics(project)
  }

  return [
    updateProjectProps({...projectInfo}),
    hideNavbarSpinnerAction()
  ]
}

// revenues visualization
export function loadProjectRevenuesAction (page, id) {
  return bind(fetchProjectRevenues(page, id), processProjectRevenues, processError)
}

function fetchProjectRevenues (page, id) {
  const api = {incomechart: PROJECT_REVENUES, outcomechart: PROJECT_EXPENSES}
  return fetch(api[page], { id }, {
    method: 'GET'
  })
}

function processProjectRevenues (response) {
  return (dispatch, getState) => {
    const t = getState().i18n.get('app', 'views', 'OverviewView', 'statuses')

    const totalAmountWithVat = Number(response.value.total_expenses || response.value.total_revenue)
    const totalAmount = totalAmountWithVat - (response.value.total_expenses_VAT || response.value.total_revenue_VAT)

    const details = (response.value.expenses || response.value.revenues).map((detail) => ({
      percentForAmount: ((parseFloat(detail.amount) - parseFloat(detail.VAT_amount)) / totalAmount) * 100,
      percentForAmountWithVat: (parseFloat(detail.amount) / totalAmountWithVat) * 100,
      categoryName: detail.account_name || String(t('untagged')),
      categorySum: Number(detail.amount),
      categoryVatSum: detail.VAT_amount,
      categoryId: detail.account_id
    }))
    const sortedFinanceRows = sortBy(details, (item) => item.categorySum).reverse()

    const projectInfo = {
      revenues: sortedFinanceRows,
      totalAmountWithVat: totalAmountWithVat,
      totalAmount: totalAmount
    }

    return dispatch(updateProjectProps(projectInfo))
  }
}

function mapEventRows (rows = []) {
  return rows.map((row) => {
    const account = row.debit_account || row.credit_account
    return {
      title: row.description,
      category: account ? account.name : ' ',
      date: row.source_document && row.source_document.document_date,
      amount: row.amount,
      vatAmount: row.vat_amount,
      id: row.id,
      eventType: row.source_document && row.source_document.type,
      eventId: row.source_document_id
    }
  })
}

function mapTimeReports (reports = []) {
  return reports.map((timesheet) => {
    return {
      id: timesheet.id,
      minutes: timesheet.duration,
      title: timesheet.title ? timesheet.title : ' ',
      startTime: moment.utc(timesheet.started_at_utc).local(),
      stopTime: timesheet.finished_at_utc && moment.utc(timesheet.finished_at_utc).local()
    }
  })
}

export function loadFinanceDetailsByAccountAction (lastOffset, params) {
  if (!params.financeType) return [] // after click on event it's showing preview without link
  return (dispatch, getState) => {
    let oldList
    if (lastOffset === 0) {
      oldList = []
    } else {
      oldList = getState().events.data
    }

    return dispatch([
      showNavbarSpinnerAction(),
      bind(fetchFinanceDetailsByAccount(lastOffset, params), mapFinanceDetailsByAccount.bind(this, oldList, params.financeType, lastOffset))
    ])
  }
}

function fetchFinanceDetailsByAccount (lastOffset, {financeType, accountId, projectId}) {
  // have no clue why without deposit and withdrawal
  const types = financeType === 'revenue' ? [eventType.inType] : [eventType.outType]
  const params = {
    source_document_types: types.join(','),
    project_id: projectId,
    filled_objects: 'source_document',
    sort: '-source_document_date',
    offset: lastOffset,
    limit: transactionsLimitToPage
  }
  const accId = accountId && accountId !== 'null' ? accountId : 0
  if (financeType === 'revenue') {
    params.credit_account_id = accId
  } else {
    params.debit_account_id = accId
  }

  return fetch2(TRANSACTIONS_LIST(params))
}

function mapFinanceDetailsByAccount (oldList, financeType, lastOffset, response) {
  const eventList = response.value.transactions.map((event) => ({
    id: event.source_document.id,
    title: event.description || event.source_document.title,
    amount: event.amount,
    number: event.source_document.user_document_id,
    expenseType: financeType === 'revenue' ? 'plus' : 'minus',
    date: event.source_document.document_date
  }))

  return [
    updateEventsList({
      data: oldList.concat(eventList),
      hasNextPage: response.value.number_of_transactions > lastOffset + transactionsLimitToPage,
      lastOffset: lastOffset + transactionsLimitToPage
    }), hideNavbarSpinnerAction()]
}

export function mapMileageReports (reports = []) {
  return reports.map((report) => {
    return {
      id: report.id,
      distance: report.distance,
      // TODO какого-то хера километры вместо миль, это законно вообще?
      // в mileage_report * 10. Но этож не коэффициент km/mil?
      amountPerMile: report.compensation_per_km * 10
    }
  })
}

export function updateProjectPropsAction (prop, value) {
  return updateProjectProps({ [prop]: value })
}

function processError (response) {
  console.log(`projectList error`, response)
}

export function addEventsAction (events) {
  return events.map((event) => {
    return processImportingEvent(event)
  })
}

function processImportingEvent (event) {
  return event.transactions.map((row) => {
    return addEventRow({
      title: row.description,
      category: row.category,
      date: event.date,
      amount: row.amount,
      vatAmount: row.vatAmount,
      id: row.id,
      eventType: event.eventType,
      eventId: event.id
    })
  })
}

export function addMileageReportsAction (reports) {
  return reports.map((report) => {
    return addMileageReport(report)
  })
}

export function addTimeReportsAction (reports) {
  console.log(reports)
  return reports.map((report) => {
    return addTimeReport(report)
  })
}

// bind relation to project - projects API v2
export function updateProjectTimesheet (...args) {
  return updateProjectRelation(PROJECT_CREATE_TIMESHEET, PROJECT_DELETE_TIMESHEET, ...args)
}

export function updateProjectDriverlog (...args) {
  return updateProjectRelation(PROJECT_CREATE_DRIVERLOG, PROJECT_DELETE_DRIVERLOG, ...args)
}

export function updateProjectTransaction (...args) {
  return updateProjectRelation(PROJECT_CREATE_TRANSACTION, PROJECT_DELETE_TRANSACTION, ...args)
}

function updateProjectRelation (createUrl, deleteUrl, relationId, initialData, formData) {
  return async (dispatch) => {
    if (Number(formData.projectId) === Number(initialData.projectId)) return
    if (!formData.projectId && !initialData.projectId) return

    const actions = []
    if (initialData.projectId) {
      await axios.delete(deleteUrl({
        id: initialData.projectId,
        relationId
      }).toString())
    }
    if (formData.projectId) {
      await axios.post(createUrl({ id: formData.projectId }).toString(), { id: relationId }, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
    }

    return dispatch(actions)
  }
}
