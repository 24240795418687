import React from 'react'
import { Route } from 'react-router'

import {requireAuthentication} from 'User/Auth/higherOrderComponents/AuthComponent'
import addSpinnerControl from 'shared/higherOrderComponents/addSpinnerControl'

import OverviewView from 'Overview/Overview'
import OverviewFinanceVisualizationView from 'Overview/FinanceVisualization/FinanceVisualizationView'
import OverviewRevenueAndCosts from 'Overview/RevenueAndCosts/RevenueAndCosts'
import OverviewPnlReports from 'Overview/PnlReports/PnlReports'
import OverviewBalanceReports from 'Overview/BalanceReports/BalanceReports'
import OverviewVatReports from 'Overview/VatReports/VatReports'
import FinanceDetailsByAccountView from '../FinanceVisualization/views/FinanceDetailsByAccountView'
import ClientOverviewView from 'Overview/ClientOverview/views/ClientOverviewView'

const overviewRoute = (
  <Route>
    <Route path='/overview' component={requireAuthentication(OverviewView)} />
    <Route path='/overview/result/:type' component={requireAuthentication(addSpinnerControl(OverviewFinanceVisualizationView))} />
    <Route path='/overview/forecast/:type' component={requireAuthentication(addSpinnerControl(OverviewFinanceVisualizationView))} />
    <Route path='/overview/details_by_account' component={requireAuthentication(FinanceDetailsByAccountView)} />
    <Route path='/overview/revenue_costs' component={requireAuthentication(OverviewRevenueAndCosts)} />
    <Route path='/overview/pnl_reports' component={requireAuthentication(OverviewPnlReports)} />
    <Route path='/overview/balance_reports' component={requireAuthentication(OverviewBalanceReports)} />
    <Route path='/overview/vat_reports' component={requireAuthentication(OverviewVatReports)} />
    <Route path='/overview/client_overview' component={requireAuthentication(ClientOverviewView)} />
  </Route>
)

export default overviewRoute
