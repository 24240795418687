import { API_BASE } from 'shared/constants/Api'
import URI from 'urijs'

export const CREDITSAFE_ONBOARDING = (params) => URI(`${API_BASE}/api/v2/marketplace/creditsafe/onboarding/`).addSearch(params)
export const CREDITSAFE_VALIDATE = `${API_BASE}/api/v2/marketplace/creditsafe/onboarding/validate/`

export const CREDITSAFE_CHECK_ACTIVATION = `${API_BASE}/api/v2/credit-safe/users/check_activation/`
export const CREDITSAFE_USERS = `${API_BASE}/api/v2/credit-safe/users/`
export const CREDITSAFE_REPORTS = `${API_BASE}/api/v2/credit-safe/reports/`
export const CREDITSAFE_CHECK_PAYMENT_STATUS = `${API_BASE}/api/v2/credit-safe/check_payment_status/`
