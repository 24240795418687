export const NEW_EVENT_COPY_REPRESENTATION_DATA = 'NEW_EVENT_COPY_REPRESENTATION_DATA'
export const NEW_EVENT_PUSH_REPRESENTATION_CONTACT = 'NEW_EVENT_PUSH_REPRESENTATION_CONTACT'
export const NEW_EVENT_EDIT_REPRESENTATION_CONTACT = 'NEW_EVENT_EDIT_REPRESENTATION_CONTACT'
export const NEW_EVENT_DELETE_REPRESENTATION_CONTACT = 'NEW_EVENT_DELETE_REPRESENTATION_CONTACT'
export const NEW_EVENT_SAVE_REPRESENTATION = 'NEW_EVENT_SAVE_REPRESENTATION'

export const NEW_EVENT_ADD_EVENT_ROW = 'NEW_EVENT_ADD_EVENT_ROW'
export const NEW_EVENT_EDIT_EVENT_ROW = 'NEW_EVENT_EDIT_EVENT_ROW'
export const NEW_EVENT_DELETE_EVENT_ROW = 'NEW_EVENT_DELETE_EVENT_ROW'
export const NEW_EVENT_EDIT_EVENT_ROWS_FIELDS = 'NEW_EVENT_EDIT_EVENT_ROWS_FIELDS'
