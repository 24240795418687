import React, { Component } from 'react'
import PropTypes from 'prop-types'
import cs from 'classnames'
import { connect } from 'react-redux'

// Components

import Navigation from 'Navigation/Navigation'
import MoreLinks from 'More/components/MoreLinks'

class MoreSalaryView extends Component {
  static propTypes = {
    t: PropTypes.func
  }

  getMenuList () {
    const { t } = this.props
    return [
      {icon: 'icon-cost', title: t('buttons', 'salaries').s, path: '/salaries', featureName: 'salary'},
      {icon: 'icon-customers', title: t('buttons', 'employees').s, path: '/employees', featureName: 'employees'}
    ]
  }

  render () {
    const { t } = this.props
    const navClassName = cs('more', 'more-salary')

    return (
      <Navigation title={t('navbar', 'title').s} className={navClassName} backLink='/more' forceBackLink>
        <MoreLinks linksConfig={this.getMenuList()} />
      </Navigation>
    )
  }
}

function mapStateToProps (state) {
  return {
    t: state.i18n.get('app', 'views', 'MoreSalaryView')
  }
}

export default connect(mapStateToProps)(MoreSalaryView)
