import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { omit } from 'lodash'

import { stopSubmit, touch, formValueSelector } from 'redux-form'
import { getValidatedFields, sendOnboarding } from 'MarketPlace/Collector/actions'

import Icon from 'shared/components/Icon'
import Spinner from 'shared/components/Spinner/Spinner'
import Form from 'MarketPlace/Collector/components/Form'

import './styles.scss?global'

class MissingInfo extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    tInvoiceSettings: PropTypes.func.isRequired,
    validatedFields: PropTypes.array.isRequired,
    getValidatedFields: PropTypes.func.isRequired,
    initialValues: PropTypes.object.isRequired,
    setErrors: PropTypes.func.isRequired,
    currentErrors: PropTypes.object,
    makeFieldsTouched: PropTypes.func.isRequired,
    orgNumber: PropTypes.string,
    personalNumber: PropTypes.string,
    sendOnboarding: PropTypes.func.isRequired
  }

  componentDidMount () {
    this.props.getValidatedFields()
  }

  getContent () {
    const {
      validatedFields, currentErrors, initialValues,
      makeFieldsTouched, orgNumber, personalNumber,
      sendOnboarding, t, setErrors, tInvoiceSettings
    } = this.props

    if (validatedFields.length) {
      return <Form
        validatedFields={validatedFields}
        t={t}
        initialValues={initialValues}
        setInitialErrors={setErrors}
        currentErrors={currentErrors}
        makeFieldsTouched={makeFieldsTouched}
        fields={{orgNumber, personalNumber}}
        submitHandler={sendOnboarding}
        tInvoiceSettings={tInvoiceSettings}
      />
    } else {
      return (
        <div className='collector-bank__spinner_wrapper'>
          <Spinner />
        </div>)
    }
  }

  render () {
    const { t } = this.props
    return (
      <div className='collector-missing-info'>
        <div className='collector-bank'>
          <Icon className='collector-bank__bank-logo' type='cl_bank_logo' />
          <span className='collector-bank__welcome'>{t('welcome').s}</span>
        </div>
        {this.getContent()}
      </div>
    )
  }
}

function mapStateToProps (state) {
  const { initialValues, currentErrors } = state.marketplace.collector.missingInfoForm
  const validatedFields = Object.keys(omit(initialValues, 'addressId')).map(fieldName => (
    {name: fieldName, value: initialValues[fieldName]})
  )
  const selector = formValueSelector('collector-form')
  return {
    t: state.i18n.get('app', 'views', 'Collector', 'MissingInfo'),
    tInvoiceSettings: state.i18n.get('app', 'views', 'InvoiceSettingsView'),
    validatedFields,
    initialValues,
    currentErrors,
    orgNumber: selector(state, 'orgNumber'),
    personalNumber: selector(state, 'personalNumber')
  }
}

function mapDispatchToProps (dispatch) {
  return {
    getValidatedFields: () => dispatch(getValidatedFields()),
    setErrors: (formName, errors) => dispatch(stopSubmit(formName, errors)),
    makeFieldsTouched: (errors) => dispatch(touch('collector-form', ...Object.keys(errors))),
    sendOnboarding: (settings) => dispatch(sendOnboarding(settings))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MissingInfo)
