import {
  OVERVIEW_SETTINGS_INFO_LOAD, OVERVIEW_SETTINGS_RESULT_INFO_LOAD, OVERVIEW_SETTINGS_FORECAST_INFO_LOAD,
  OVERVIEW_DATA_LOAD, OVERVIEW_TOTALS_SAVE, OVERVIEW_TOTALS_CLEAR, OVERVIEW_LOAN_LOAD, CLIENT_OVERVIEW_LOAD
} from 'Overview/constants/ActionTypes'
import { RESULT_TYPE } from 'Overview/constants/OverviewSettings'

export let initialState = {
  settings: {
    type: RESULT_TYPE,
    result: {
      from: null,
      to: null,
      selectedYearIndex: '',
      fiscalYears: null
    },
    forecast: {
      year: null,
      from: null,
      to: null,
      eventStatus: '0'
    }
  },
  results: {
    income: 0,
    outcome: 0,
    difference: 0,
    detailedResults: [],
    monthlyResults: [],
    cashBankResults: []
  },
  loan: {
    existing: 0,
    limit: 0
  },
  clientOverview: {
    economy: {},
    invoices: {},
    invoicesStatuses: {}
  },
  totals: {}
}

export default function (state = initialState, action) {
  switch (action.type) {
    case OVERVIEW_SETTINGS_INFO_LOAD:
      return {
        ...state,
        settings: {
          ...state.settings,
          ...action.payload
        }
      }
    case OVERVIEW_SETTINGS_RESULT_INFO_LOAD:
      return {
        ...state,
        settings: {
          ...state.settings,
          result: {
            ...state.settings.result,
            ...action.payload
          }
        }
      }
    case OVERVIEW_SETTINGS_FORECAST_INFO_LOAD:
      return {
        ...state,
        settings: {
          ...state.settings,
          forecast: {
            ...state.settings.forecast,
            ...action.payload
          }
        }
      }
    case OVERVIEW_DATA_LOAD:
      return {
        ...state,
        results: {
          ...state.results,
          ...action.payload
        }
      }
    case OVERVIEW_TOTALS_SAVE:
      return {
        ...state,
        totals: {
          ...state.totals,
          ...action.payload
        }
      }
    case OVERVIEW_TOTALS_CLEAR:
      return {
        ...state,
        totals: {}
      }
    case OVERVIEW_LOAN_LOAD:
      return {
        ...state,
        loan: action.payload
      }
    case CLIENT_OVERVIEW_LOAD:
      return {
        ...state,
        clientOverview: action.payload
      }
    default:
      return state
  }
}
