import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { reduxForm, Field } from 'redux-form'

import Input from 'shared/components/Input/Input'
import Button from 'shared/components/Button/Button'

class BenefitForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    tButtons: PropTypes.func.isRequired
  }

  render () {
    const { t, tButtons, handleSubmit, onSubmit, onCancel } = this.props
    return (
      <div className='f-column'>
        <div className='form f-column-top'>
          <div className='form__item'>
            <Field
              name='title'
              component={FormInput}
              placeholder={t('fields', 'title').s}
            />
          </div>
          <div className='form__item'>
            <Field
              name='value'
              component={FormInput}
              placeholder={t('fields', 'value').s}
              type='number'
              inputMode='decimal'
            />
          </div>
        </div>
        <div className='f-column-bottom form-buttons'>
          <Button
            view='transparent-black'
            saveButton
            onClick={handleSubmit(onSubmit)}
          >
            {tButtons('save').s}
          </Button>
          <Button
            onClick={onCancel}
            view='transparent-red'>
            {tButtons('cancel').s}
          </Button>
        </div>
      </div>
    )
  }
}

const FormInput = ({ input, meta, ...props }) =>
  <Input {...input} {...meta} {...props} />

FormInput.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object
}

export default reduxForm({
  form: 'benefit',
  enableReinitialize: true
})(BenefitForm)
