import {connect} from 'react-redux'

// Views
import TimesheetsImportView from 'Timesheet/views/TimesheetsImportView/TimesheetsImportView'

// Actions
import { importTimesheetsRowAction } from '../../../actions/imports'
import { routerActions } from 'react-router-redux'

import { getTrimmedLink } from 'shared/helpers/linkUtils'

function mapStateToProps (state, ownProps) {
  const {location: {pathname}} = ownProps
  return {
    withAddButton: true,
    cameFrom: 'invoice',
    backLink: getTrimmedLink(pathname, 1)
  }
}

function mapDispatchToProps (dispatch) {
  return {
    importItemsToForm: (data) => { dispatch([importTimesheetsRowAction(data), routerActions.goBack()]) }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TimesheetsImportView)
