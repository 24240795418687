import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import IpadNavigation from 'Navigation/IpadNavigation'
import {ProjectPreviewView as ProjectPreview} from 'Projects/views/ProjectView/Preview/containers/Smartphones'
import ProjectsView from 'Projects/views/ProjectsView/containers/Smartphones'

// Actions
import { projectInfoClearAction } from 'Projects/actions/project'

class ProjectPreviewView extends Component {
  static propTypes = {
    params: PropTypes.object,
    projectInfoClear: PropTypes.func,
    t: PropTypes.func
  }

  render () {
    const {t, projectInfoClear, params: {projectId}} = this.props
    const actions = [{path: `/projects/${projectId}/edit`, icon: 'icon-pen'}]

    return (
      <IpadNavigation {...this.props} title={t('navbar', 'title').s} actions={actions} notBack onClickBack={projectInfoClear}>
        <ProjectsView {...this.props} />
        <ProjectPreview {...this.props} />
      </IpadNavigation>
    )
  }
}

function mapStateToProps (state) {
  return {
    t: state.i18n.get('app', 'views', 'ProjectView'),
    project: state.project
  }
}

function mapDispatchToProps (dispatch) {
  return {
    projectInfoClear: () => { dispatch(projectInfoClearAction()) }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectPreviewView)
