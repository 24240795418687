import React, { Component } from 'react'
import PropTypes from 'prop-types'

import IpadNavigation from 'Navigation/IpadNavigation'
import TodoIntegrations from './Smartphones'
import withRequests from './withRequestsHOC'

class TodoIntegrationsView extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired
  }

  render () {
    const { location } = this.props
    return (
      <IpadNavigation location={location} onlyLeftSide>
        <TodoIntegrations {...this.props} />
      </IpadNavigation>
    )
  }
}

export default withRequests(TodoIntegrationsView)
