import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import CompanyNameInput from 'Counterparties/shared/components/CompanyNameInput'
import { loadSupplierCompanyNames, updateSupplierInfo } from '../../actions/suggestions'

const ConnectedCompanyNameInput = (props) =>
  <CompanyNameInput {...props} name='supplierCompanyName' />

ConnectedCompanyNameInput.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object
}

const mapDispatchToProps = (dispatch) => ({
  updateSuggestions: (...props) => { dispatch(loadSupplierCompanyNames(...props)) },
  onSelectSuggestion: (supplier) => { dispatch(updateSupplierInfo(supplier.overviewKey)) }
})

export default connect(null, mapDispatchToProps)(ConnectedCompanyNameInput)
