import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { get } from 'lodash'

// Components
import Navigation from 'Navigation/Navigation'
import ListView from 'shared/components/ListView/ListView'
import ArticlesListItem from 'Articles/components/ListItem/ListItem'
import SelectableListItem from 'shared/components/SelectableListItem/SelectableListItem'
import Filters from 'Articles/components/Filters/Filters'

// Containers
import ArticleImportContainer from 'Articles/views/ArticlesImportView/containers/Common'

export function ArticleImportListItem ({onCheckboxChange, ...props}) {
  return (
    <SelectableListItem {...props} onChange={onCheckboxChange} >
      <ArticlesListItem {...props} notLink />
    </SelectableListItem>
  )
}

ArticleImportListItem.propTypes = {
  onCheckboxChange: PropTypes.func
}

export class Articles extends Component {
  static propTypes = {
    articles: PropTypes.arrayOf(PropTypes.object),
    filters: PropTypes.object,
    onCheck: PropTypes.func,
    onUncheck: PropTypes.func
  }

  constructor (props) {
    super(props)
    this.onChangeHandler = this.onChangeHandler.bind(this)
  }

  onChangeHandler (checked, data) {
    const { onCheck, onUncheck } = this.props
    checked ? onCheck(data) : onUncheck(data)
  }

  render () {
    const { articles, filters } = this.props

    return (
      <ListView data={articles} template={ArticleImportListItem} notMark onCheckboxChange={this.onChangeHandler} emptyListOptions={{icon: 'icon-articles', showNotFound: Boolean(get(filters, ['search', 'value', 'length']))}} />
    )
  }
}

class ArticlesWithNavigation extends Component {
  static propTypes = {
    articles: PropTypes.arrayOf(PropTypes.object),
    itemsToImport: PropTypes.arrayOf(PropTypes.object),
    extendList: PropTypes.func,
    importItemsToForm: PropTypes.func,
    t: PropTypes.func
  }

  render () {
    const { t, articles, extendList, importItemsToForm, itemsToImport } = this.props
    const actions = [
      { path: '/articles/new?cameFrom=invoice', icon: 'icon-add' }
    ]

    return (
      <Navigation actions={actions} title={t('navbar', 'title').s} hideFooter filterBlock infinityScrollHandler={extendList}>
        <Filters canBeHidden={!articles.length} onImportClick={() => { importItemsToForm(itemsToImport) }} />
        <Articles {...this.props} />
      </Navigation>
    )
  }
}

export default ArticleImportContainer(ArticlesWithNavigation)
