import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { reduxForm, Field } from 'redux-form'

// Components
import Navigation from 'Navigation/Navigation'
import Input from 'shared/components/FormInput'
import Button from 'shared/components/Button/Button'
import GdprCheckbox from 'User/shared/components/GdprCheckbox/GdprCheckbox'

// Actions
import { loadCurrentCompanyInfoAction, updateCurrentCompanyInfoAction } from 'User/Settings/actions/companySettings'

// Helpers
import { orgNumberValid, orgNumberMaskProps, haveTaxNumber } from 'mrshoebox-ui-components/src/helpers/countrySpecific'

export class CompanySettings extends Component {
  static propTypes = {
    updateCompanyInfo: PropTypes.func,
    setDefaultSettingsData: PropTypes.func,
    t: PropTypes.func,
    t_buttons: PropTypes.func,
    handleSubmit: PropTypes.func
  }

  constructor (props) {
    super(props)
    this.onSubmit = this.onSubmit.bind(this)
  }

  componentWillMount () {
    this.props.setDefaultSettingsData()
  }

  onSubmit (values) {
    const { updateCompanyInfo } = this.props
    updateCompanyInfo(values)
  }

  render () {
    const { t, t_buttons, handleSubmit } = this.props

    return (
      <div className='company-settings f-column'>
        <div className='company-settings__form form'>
          <Field name='companyName' component={Input} placeholder={t('fields', 'companyName').s} />
          <Field name='organizationNumber' component={Input} placeholder={t('fields', 'organizationNumber').s} type='tel' {...orgNumberMaskProps} key='orgNumber' />
          <Field name='vatNumber' component={Input} placeholder={t('fields', 'vatNumber').s} />
          { haveTaxNumber && <Field name='taxNumber' component={Input} placeholder={t('fields', 'taxNumber').s} /> }
          <Field name='primaryAddress.street' component={Input} placeholder={t('fields', 'address').s} />
          <Field name='primaryAddress.zip' component={Input} placeholder={t('fields', 'zipcode').s} type='tel' />
          <Field name='primaryAddress.city' component={Input} placeholder={t('fields', 'city').s} />
          <Field name='primaryAddress.country' component={Input} placeholder={t('fields', 'country').s} />
          <GdprCheckbox />
        </div>

        <div className='f-column-bottom'>
          <Button saveButton view='transparent-black' onClick={handleSubmit(this.onSubmit)}>{t_buttons('save').s}</Button>
        </div>
      </div>
    )
  }
}

class CompanySettingsWitnNavigation extends Component {
  static propTypes = {
    t: PropTypes.func
  }
  render () {
    const { t } = this.props

    return (
      <Navigation hideFooter title={t('title').s} className='f-column'>
        <CompanySettings {...this.props} />
      </Navigation>
    )
  }
}

function mapStateToProps (state) {
  return {
    initialValues: state.userSettings.company,
    t: state.i18n.get('app', 'views', 'CompanySettingsView'),
    t_buttons: state.i18n.get('app', 'shared', 'buttons')
  }
}

function mapDispatchToProps (dispatch, ownProps) {
  return {
    setDefaultSettingsData: () => { dispatch(loadCurrentCompanyInfoAction()) },
    updateCompanyInfo: (values) => { dispatch(updateCurrentCompanyInfoAction(values, ownProps.location.query.redirectPath)) }
  }
}

function validate (values) {
  let errors = {
    primaryAddress: {}
  }

  const mainFieldsToCheck = ['companyName', 'organizationNumber']
  mainFieldsToCheck.forEach((field) => {
    if (!values[field]) {
      errors[field] = true
    }
  })

  const addressFieldCheck = ['city', 'country', 'street', 'zip']
  addressFieldCheck.forEach((field) => {
    if (!values.primaryAddress[field]) {
      errors.primaryAddress[field] = true
    }
  })

  if (!orgNumberValid(values.organizationNumber)) {
    errors.organizationNumber = true
  }

  return errors
}

export const CompanySettingsView = connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: 'company-settings',
  validate
})(CompanySettings))

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: 'company-settings',
  validate
})(CompanySettingsWitnNavigation))
