import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import './styles/index.scss?global'

export class Loader extends Component {
  static propTypes = {
    // eslint-disable-next-line react/no-unused-prop-types
    show: PropTypes.bool,
    mode: PropTypes.string,
    forceSmartphoneTopMargin: PropTypes.bool
  }

  constructor (props) {
    super(props)
    this.state = { start: null }
  }

  setCurrentLoaderWidth () {
    const loaderWidth = getComputedStyle(document.querySelector('.loader__thumb')).width
    for (const styleSheet of document.styleSheets) {
      // https://stackoverflow.com/a/49160760 Cannot access cssRules from local css file in Chrome 64
      try {
        for (const cssRule of styleSheet.cssRules) {
          if (cssRule.type === window.CSSRule.KEYFRAMES_RULE && cssRule.name === 'loader-end') {
            cssRule.deleteRule('0%')
            if (cssRule.appendRule) {
              cssRule.appendRule(`0% { width: ${loaderWidth}; }`)
            } else {
              cssRule.insertRule(`0% { width: ${loaderWidth}; }`)
            }
          }
        }
      } catch (error) {
        console.warn(error.message)
      }
    }
  }

  componentDidMount () {
    this._isMounted = true
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  componentWillReceiveProps (props) {
    if (props.show === true) {
      this.setState({ start: true })
    }
    if (props.show === false && this.state.start === true) {
      this.setCurrentLoaderWidth()
      this.setState({ start: false })
      setTimeout(() => {
        this._isMounted && this.setState({ start: null })
      }, 350)
    }
  }

  shouldComponentUpdate (nextProps, nexState) {
    return nexState.start !== this.state.start
  }

  render () {
    const { mode, forceSmartphoneTopMargin } = this.props
    const { start } = this.state
    const setClass = (val) => {
      if (val === true && mode === 'slow') {
        return 'loader--start-slow'
      } else if (val === true) {
        return 'loader--start'
      } else if (val === false) {
        return 'loader--end'
      } else {
        return ''
      }
    }
    return (
      <div className={`loader ${setClass(start)} ${forceSmartphoneTopMargin ? 'loader--smartphone-top' : ''}`}>
        <div className='loader__thumb' />
      </div>
    )
  }
}

function mapStateToProps (state) {
  return {
    show: state.navbarSpinner.value,
    mode: state.navbarSpinner.mode
  }
}

export default connect(mapStateToProps)(Loader)
