import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import IpadNavigation from 'Navigation/IpadNavigation'
import { SelectRecipientView as SelectReceipt } from 'Messages/views/SelectRecipientView/containers/Smartphones'

import ConversationsView from 'Messages/views/ConversationsView/containers/Smartphones'

// Action
import { loadContactsListAction, createNewConversationAction } from 'Messages/actions/messages'
import { routerActions } from 'react-router-redux'

export class SelectRecipientView extends Component {
  static propTypes = {
    onAddClick: PropTypes.func,
    currentReceivers: PropTypes.arrayOf(PropTypes.object),
    checkboxListItems: PropTypes.arrayOf(PropTypes.object),
    t: PropTypes.func
  }

  get receiver () {
    const { checkboxListItems } = this.props
    return checkboxListItems.filter((r) => r.isChecked)[0]
  }

  render () {
    const { t, onAddClick } = this.props
    const actions = [{text: t('add').s, callback: () => { onAddClick(this.receiver) }}]

    return (
      <IpadNavigation {...this.props} title={t('navbar', 'title').s} className='select-recipient-view' actions={actions} >
        <ConversationsView shouldLoadList={false} {...this.props} />
        <SelectReceipt {...this.props} />
      </IpadNavigation>
    )
  }
}

function mapStateToProps (state) {
  return {
    userId: state.userSettings.user.id,
    checkboxListItems: state.checkBoxList.items,
    t: state.i18n.get('app', 'views', 'Messages', 'selectRecepient'),
    contacts: state.messages.possibleReceivers
  }
}

function mapDispatchToProps (dispatch) {
  return {
    loadContactsList: (userId) => { dispatch(loadContactsListAction(userId)) },
    onAddClick: (receiver) => {
      dispatch([
        createNewConversationAction(receiver),
        routerActions.push(
          `/messages/conversation?accountantId=${receiver.receiverId}&accountingCompanyId=${receiver.companyId}`
        )
      ])
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectRecipientView)
