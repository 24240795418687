import PropTypes from 'prop-types'
import React, { Component } from 'react'
import {connect} from 'react-redux'
import {isEmpty} from 'lodash'
import { routerActions } from 'react-router-redux'

// Components
import Navigation from 'Navigation/Navigation'
import Tabs from 'shared/components/Tabs/Tabs'
import Button from 'shared/components/Button/Button'

// Views
import Specification from 'Projects/views/ProjectView/Specification/Specification'
import Overview from 'Projects/views/ProjectView/Overview/Overview'

// Actions
import {
  projectInfoClearAction, loadProjectInfoAction, exportProjectAction,
  projectSaveAction, importProjectRelationsAction
} from 'Projects/actions/project'

class ProjectPreview extends Component {
  static propTypes = {
    params: PropTypes.object,
    project: PropTypes.object,
    getProjectInfo: PropTypes.func,
    changeActiveView: PropTypes.func,
    location: PropTypes.object,
    t: PropTypes.func,
    exportProject: PropTypes.func,
    importProjectRelations: PropTypes.func,
    saveProject: PropTypes.func
  }

  componentDidMount () {
    if (isEmpty(this.props.project) || !this.props.project.id) {
      const {projectId} = this.props.params
      this.props.getProjectInfo(projectId)
    }
  }

  componentDidUpdate (prevProps) {
    const { params: { projectId }, project } = prevProps
    if (
      projectId !== this.props.params.projectId ||
      (!this.props.project.id && this.props.project.id !== project.id)
    ) {
      this.props.getProjectInfo(this.props.params.projectId)
    }
  }

  onTabChange = (type) => {
    return () => {
      this.props.changeActiveView(type)
    }
  }

  isTabActive = (tabName) => {
    const { location: { query: { type = 'overview' } } } = this.props
    return tabName === type
  }

  exportProject = () => {
    this.props.exportProject()
  }

  saveProject = () => {
    const { params: { projectId }, project } = this.props
    this.props.saveProject(projectId, project)
  }

  importProjectRelations = () => {
    const { params: { projectId }, project } = this.props
    this.props.importProjectRelations(projectId, project)
  }

  render () {
    const {t, location: { query: { type = 'overview' } }} = this.props
    return (
      <div className='project-view f-column'>
        <div className='project-view__tabs'>
          <Tabs initValue={type} config={[
            {label: t('overview', 'tabTitle').s, action: this.onTabChange('overview'), value: 'overview'},
            {label: t('specification', 'tabTitle').s, action: this.onTabChange('specification'), value: 'specification'}
          ]} />
        </div>
        <div className='f-column-top'>
          { this.isTabActive('overview') ? <Overview params={this.props.params} project={this.props.project} /> : <Specification {...this.props} /> }
        </div>
        <div className='f-column-bottom form-buttons'>
          {/* <Button saveButton view='transparent-black' onClick={this.exportProject}>{t('overview', 'export').s}</Button> */}
          <Button saveButton view='transparent-black'
            onClick={this.isTabActive('overview') ? this.saveProject : this.importProjectRelations}>
            {t('overview', 'save').s}</Button>
          <Button view='transparent-black' goBackButton>{t('overview', 'cancel').s}</Button>
        </div>
      </div>
    )
  }
}

class ProjectPreviewWithNavigation extends Component {
  static propTypes = {
    params: PropTypes.object,
    projectInfoClear: PropTypes.func,
    t: PropTypes.func
  }
  render () {
    const {t, projectInfoClear, params: {projectId}} = this.props
    const actions = [{path: `/projects/${projectId}/edit`, icon: 'icon-pen'}]
    return (
      <Navigation title={t('navbar', 'title').s} actions={actions} backLink='/projects' onClickBack={projectInfoClear} className='f-column' hideFooter>
        <ProjectPreview {...this.props} />
      </Navigation>
    )
  }
}

function mapStateToProps (state) {
  return {
    t: state.i18n.get('app', 'views', 'ProjectView'),
    project: state.project
  }
}

function mapDispatchToProps (dispatch, ownProps) {
  const {projectId} = ownProps.params
  const {location: {pathname}} = ownProps
  return {
    getProjectInfo: (projectId) => { dispatch(loadProjectInfoAction(projectId)) },
    projectInfoClear: () => { dispatch(projectInfoClearAction()) },
    changeActiveView: (newType) => { dispatch(routerActions.replace(`${pathname}?type=${newType}`)) },
    exportProject: () => { dispatch(exportProjectAction(projectId)) },
    saveProject: (...props) => { dispatch(projectSaveAction(...props)) },
    importProjectRelations: (...props) => { dispatch(importProjectRelationsAction(...props)) }
  }
}

export const ProjectPreviewView = connect(mapStateToProps, mapDispatchToProps)(ProjectPreview)

export default connect(mapStateToProps, mapDispatchToProps)(ProjectPreviewWithNavigation)
