import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Link } from 'react-router'

// Components
import FilesPreview from 'FileUpload/components/FilesPreviewList/FilesPreviewList'
import FormatNumber from 'shared/components/FormatNumber/FormatNumber'
import ConfirmModal from 'MessageBox/components/ConfirmationMessageBox/ConfirmationMessageBox'

// Constants
import { STATUS_UNPROCESSED, STATUS_APPROVED, STATUS_PREPARED, STATUS_REJECTED, STATUS_VOIDED } from 'Events/shared/constants/EventStatus'
import * as eventTypes from 'Events/shared/constants/eventType'

// Helpers
import { getPaymentMethodName } from 'shared/helpers/addNamesForIdProps'
import { isIpadLandscapeResolution } from 'shared/helpers/utils'
import getDocumentName from 'Events/shared/helpers/eventTypeName'
import getGreyLabelOptions from 'GreyLabel/helpers/greyLabel'

const formattedFields = ['amountWithVat', 'amountWithoutVat', 'vatAmount']

export default class Event extends Component {
  static propTypes = {
    event: PropTypes.object,
    routeParams: PropTypes.object,
    t: PropTypes.func,
    settings: PropTypes.object,
    makeAvoid: PropTypes.func
  }

  constructor (props) {
    super(props)
    this.showEventAvoidModal = this.showEventAvoidModal.bind(this)
    this.makeEventAvoid = this.makeEventAvoid.bind(this)
  }

  showEventAvoidModal () {
    this.eventAvoidModal.open()
  }

  makeEventAvoid () {
    const { event: { id }, makeAvoid } = this.props
    makeAvoid(id)
  }

  get eventStatus () {
    const { event: { status }, t } = this.props

    switch (status) {
      case STATUS_UNPROCESSED:
        return t('statuses', 'unprocessed').s
      case STATUS_APPROVED:
        return <div>{t('statuses', 'approved').s}</div>
      case STATUS_REJECTED:
        return <div>{t('statuses', 'rejected').s}</div>
      case STATUS_VOIDED:
        return t('statuses', 'voided').s
      case STATUS_PREPARED:
        return t('statuses', 'unprocessed').s
      default:
        return t('statuses', 'unknown').s
    }
  }

  renderBankTransferContext () {
    return (
      <div className={`${!this.isOneRowFieldView() ? this.getPreviewFieldsClass() : ''}`}>
        { this.renderField('amountWithVat') }
        { this.renderField('currency') }
        { this.renderField('date') }
        { this.renderField('accountFrom') }
        { this.renderField('accountTo') }
      </div>
    )
  }

  renderWithdrawalContext () {
    const {event} = this.props
    return (
      <div className={`${!this.isOneRowFieldView() ? this.getPreviewFieldsClass() : ''}`}>
        { this.renderField('amountWithVat') }
        { this.renderField('currency') }
        { this.renderField('date') }
        {(event.isPaid && event.isInvoice && event.paymentDate) ? (
          this.renderField('paymentDate')
        ) : null}
        { this.renderField('paymentMethod') }
      </div>
    )
  }

  renderGeneralEventContext () {
    const { event, t } = this.props
    return (
      <div className={`${!this.isOneRowFieldView() ? this.getPreviewFieldsClass() : ''}`}>
        {(event.isInvoice && event.invoiceNumber) &&
          this.renderField('invoiceNumber')
        }
        { this.renderField('amountWithVat') }
        { this.renderField('amountWithoutVat') }
        { this.renderField('currency') }
        { this.renderField('vatAmount') }
        { this.renderField('date') }
        {(event.isPaid && event.isInvoice && event.paymentDate) ? (
          this.renderField('paymentDate')
        ) : null}
        {(event.isInvoice && event.invoiceDueDate) &&
          this.renderField('invoiceDueDate')
        }
        {(event.isInvoice && event.supplierName) &&
          this.renderField('supplierName')
        }
        {(event.isInvoice && event.OCRnumber) &&
          this.renderField('OCRnumber')
        }
        {event.isInvoice && event.bgNumber &&
          this.renderField('bgNumber')
        }
        {event.isInvoice && event.pgNumber &&
          this.renderField('pgNumber')
        }
        {event.paymentMethod && (
          this.renderField('paymentMethod')
        )}
        {event.category && !(event.eventRows || []).length && (
          this.renderField('category')
        )}
        { (event.eventRows || []).length ? (
          <div>
            <h3 className='event-preview__fields__item__content'>
              {t('multipleCategories').s}</h3>
            <h5 className='event-preview__fields__item__label'>{t('props', 'category').s}</h5>
          </div>
        ) : null }
      </div>
    )
  }

  getPreviewFieldsClass () {
    const { whiteLabel } = getGreyLabelOptions()
    const fieldsClass = whiteLabel ? `event-preview__fields ${whiteLabel}` : 'event-preview__fields'

    return fieldsClass
  }

  isGeneralEvent () {
    const {event: {eventType}} = this.props
    return eventType !== eventTypes.transfer && eventType !== eventTypes.moneyWithdrawal && eventType !== eventTypes.moneyDeposit
  }

  isOneRowFieldView () {
    const {event: {eventRows = []}} = this.props
    return isIpadLandscapeResolution() && ((this.isGeneralEvent() && !eventRows.length) || !this.isGeneralEvent())
  }

  getRowWhiteLabelClasses () {
    const { whiteLabel } = getGreyLabelOptions()

    const titleClass = whiteLabel ? `event-preview__row__title ${whiteLabel}` : 'event-preview__row__title'
    const previewRowClass = whiteLabel ? `event-preview__row ${whiteLabel}` : 'event-preview__row'
    const contentClass = whiteLabel ? `event-preview__row__content ${whiteLabel}` : 'event-preview__row__content'
    const eventPreviewRowsClass = whiteLabel ? `event-preview__rows ${whiteLabel}` : 'event-preview__rows'

    return { titleClass, previewRowClass, contentClass, eventPreviewRowsClass }
  }

  get paymentsSection () {
    const { event: { payments, isPaid, amountWithVat, isInvoice }, settings, t } = this.props
    const { previewRowClass, titleClass, contentClass, eventPreviewRowsClass } = this.getRowWhiteLabelClasses()
    if (payments && payments.length) {
      const remainingAmount = Number(amountWithVat) - payments.reduce((prev, payment) => prev + Number(payment.amount), 0)
      return (
        <div className={eventPreviewRowsClass} id='paymentRows'>
          <div className='hr'><span>{t('props', 'payments').s}</span></div>
          <div className='event-preview__rows__list'>
            {payments.map((payment, index) => {
              return (
                <div className={previewRowClass} key={index}>
                  <div className={titleClass}><h3>{getPaymentMethodName(settings, payment.methodId)}</h3></div>
                  <div className={contentClass}>
                    <table>
                      <tbody>
                        <tr>
                          <td>{t('props', 'amount').s}:</td>
                          <td>{payment.amount.toFixed(2)}</td>
                        </tr>
                        <tr>
                          <td>{t('props', 'date').s}:</td>
                          <td>{payment.date}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              )
            })}
          </div>
          { (isInvoice && !isPaid && remainingAmount > 0) ? (
            <table className='info event-view__payments-total'>
              <tbody>
                <tr>
                  <td><h3 className='color-red'>{t('props', 'remainingAmount').s}</h3></td>
                  <td><h3 className='color-red' id='toPayAmount'><FormatNumber value={remainingAmount} /></h3></td>
                </tr>
              </tbody>
            </table>
          ) : null }
        </div>
      )
    } else {
      return null
    }
  }

  get eventType () {
    const { event: {eventType}, t } = this.props

    if (eventTypes.isRevenue(eventType)) {
      return (
        <div id='type'><span className='event-preview__icon-revenue icon-arrow-top' />{t('financeTypes', 'revenue').s}</div>
      )
    } else if (eventTypes.isExpense(eventType)) {
      return (
        <div id='type'><span className='event-preview__icon-expense icon-arrow-top' />{t('financeTypes', 'expense').s}</div>
      )
    } else if (eventTypes.isOther(eventType)) {
      return (
        <div id='type'><span className='event-preview__icon-other icon-uncertainty' />{t('financeTypes', 'other').s}</div>
      )
    }
  }

  get documentType () {
    const { event, t } = this.props
    return getDocumentName(event, t)
  }

  renderRowsList (rows) {
    const { t } = this.props
    const { previewRowClass, titleClass, contentClass, eventPreviewRowsClass } = this.getRowWhiteLabelClasses()
    if (rows && rows.length) {
      return (
        <div className={eventPreviewRowsClass}>
          <div className='hr'><span>{t('props', 'eventRows').s}</span></div>
          <div className='event-preview__rows__list'>
            {rows.map((item, index) => {
              return (
                <div className={previewRowClass} key={index}>
                  <div className={titleClass}><h3>{item.category || item.description}</h3></div>
                  <div className={contentClass}>
                    <table>
                      <tbody>
                        <tr>
                          <td>{t('props', 'amountWithVat').s}:</td>
                          <td id='row-amount'>{Number(item.amountWithVat || 0).toFixed(2)}</td>
                        </tr>
                        { item.project ? <tr>
                          <td>{t('props', 'project').s}:</td>
                          <td id='row-project'>{item.project || ''}</td>
                        </tr> : null }
                        <tr>
                          <td>{t('props', 'vatAmount').s}:</td>
                          <td id='row-vatAmount'>{Number(item.vatAmount || 0).toFixed(2)}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      )
    } else {
      return null
    }
  }

  renderField (field) {
    const {event, t} = this.props
    if (!this.isOneRowFieldView()) {
      return (
        <div>
          <h3 className='event-preview__fields__item__content' id={field}>
            {formattedFields.indexOf(field) !== -1 ? <FormatNumber value={event[field]} /> : event[field]}
          </h3>
          <h5 className='event-preview__fields__item__label'>{t('props', field).s}</h5>
        </div>
      )
    } else {
      return (
        <div className='event-preview__single-field'>
          <h5><b>{t('props', field).s}</b></h5>
          <h3 id={field}>
            {formattedFields.indexOf(field) !== -1 ? <FormatNumber value={event[field]} /> : event[field]}
          </h3>
        </div>
      )
    }
  }

  isPaidStatus () {
    const { event: { eventType, isPaid, isInvoice } } = this.props
    if (isPaid && eventType === !eventTypes.outType && isInvoice) return true
  }

  render () {
    const { event, routeParams: {eventId}, t, settings } = this.props
    if (settings.withOCR) {
      return (
        <div className='event-preview'>
          <div className='ocr-processing'>
            <span className='icon-receipt-ocr' />
            <h2>{t('ocrProcessing').s}</h2>
          </div>
        </div>
      )
    }

    const { whiteLabel } = getGreyLabelOptions()
    const staticSectionClass = whiteLabel ? `event-preview__static-section ${whiteLabel}` : 'event-preview__static-section'
    const singleFieldClass = whiteLabel ? `event-preview__single-field ${whiteLabel}` : 'event-preview__single-field'
    const eventRows = event.eventRows || []
    const hasProjects = eventRows.some(t => t.project) || event.project
    const projects = eventRows.filter(t => t.project).map(t => t.project).join(', ') || event.project
    // TODO maybe we should separately show active status in preview for each project
    const projectActive = eventRows.some(t => t.projectActive) || event.projectActive
    return (
      <div className='event-preview'>
        {this.isPaidStatus() && <div className='event-preview__paid'><h3>{t('eventState', 'paid').s}</h3></div>}
        <div className={staticSectionClass}>
          <div className='event-preview__title'><h2>{this.documentType}</h2></div>
          <div className='event-preview__status'>
            <div className='event-preview__status__left'>{this.eventType}</div>
            <div className='event-preview__status__right'>
              <div className='event-view__status' id='status'>{t('props', 'status').s}:&nbsp;{this.eventStatus}</div>
            </div>
          </div>
          {hasProjects && <div className='event-preview__project'>
            <div className={`event-preview__project__status ${projectActive ? 'event-preview__project__status--active' : ''}`} />
            <div id='project'>{t('props', 'project').s}: {projects}</div>
          </div>}
          <Link to={`/events/${eventId}/messages`} className={`event-preview__comment ${event.hasNewComments ? 'event-preview__comment--filled' : ''}`}>
            <div>
              <h3><span className='icon-messages-filled' /></h3>
              <div>{event.hasComments ? t('props', 'messages').s : t('props', 'createMessage').s}</div>
            </div>
            <h3><span className='icon-arrow-right' /></h3>
          </Link>
        </div>

        {!this.isOneRowFieldView() ? (
          <div className='event-preview__content'>
            <div className='event-preview__content__left'>
              <div className={singleFieldClass}>
                <h5><b>{t('props', 'orderNumber').s}</b></h5>
                <h3>{event.number}</h3>
              </div>
              {this.isGeneralEvent() && this.renderGeneralEventContext()}
              {event.eventType === eventTypes.transfer && this.renderBankTransferContext()}
              {event.eventType === eventTypes.moneyWithdrawal && this.renderWithdrawalContext()}
              {event.eventType === eventTypes.moneyDeposit && this.renderWithdrawalContext() }
            </div>
            <div className='event-preview__content__right'>
              {this.isGeneralEvent() && this.renderRowsList(eventRows)}
              {this.paymentsSection}
            </div>
          </div>
        ) : (
          <div className='event-preview__static-section'>
            <div className={singleFieldClass}>
              <h5><b>{t('props', 'orderNumber').s}</b></h5>
              <h3>{event.number}</h3>
            </div>
            {this.isGeneralEvent() && this.renderGeneralEventContext()}
            {event.eventType === eventTypes.transfer && this.renderBankTransferContext()}
            {event.eventType === eventTypes.moneyWithdrawal && this.renderWithdrawalContext()}
            {event.eventType === eventTypes.moneyDeposit && this.renderWithdrawalContext() }

            {this.isGeneralEvent() && this.renderRowsList(eventRows)}
            {this.paymentsSection}
          </div>
        )}

        <div className='event-preview__files'><FilesPreview files={event.files} /></div>

        <ConfirmModal provideController={(c) => { this.eventAvoidModal = c }} onYesClick={this.makeEventAvoid} />
      </div>
    )
  }
}
