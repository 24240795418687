import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Link } from 'react-router'

import FormatNumber from 'shared/components/FormatNumber/FormatNumber'

export default class Info extends Component {
  static propTypes = {
    data: PropTypes.array,
    t: PropTypes.func
  }

  getLink (receiptType, date) {
    let link
    if (receiptType === 'revenue') {
      link = `/overview/result/revenues?chart_type=bars&date=${date}`
    }
    if (receiptType === 'expense') {
      link = `/overview/result/expenses?chart_type=bars&date=${date}`
    }

    return link
  }

  render () {
    const {data, t} = this.props
    return (
      <div className='overview-rev-costs__table'>
        {data.map((item, index) => {
          return (
            <div key={index}>
              <div className='overview-rev-costs__table__label'>
                <b>{t('months', `${Number(item.month.slice(-2))}`).s}</b>
              </div>
              <table>
                <tbody>
                  <tr>
                    <td>{t('revenue').s}</td>
                    <td>
                      <Link to={this.getLink('revenue', item.month)} className='overview-rev-costs__table__link'>
                        <FormatNumber value={item.revenue} />
                        <span className='icon-arrow-right' />
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td>{t('costs').s}</td>
                    <td>
                      <Link to={this.getLink('expense', item.month)} className='overview-rev-costs__table__link'>
                        <FormatNumber value={item.expense} />
                        <span className='icon-arrow-right' />
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td><div>{t('result').s}</div></td>
                    <td><div><FormatNumber value={item.revenue - item.expense} /></div></td>
                  </tr>
                </tbody>
              </table>
            </div>
          )
        })}
        {data && !data.length && <h3 className='overview-rev-costs__empty'>{t('OverviewView', 'components', 'SettingsList', 'emptyMessage').s}</h3>}
      </div>
    )
  }
}
