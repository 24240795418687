import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { arrayPush } from 'redux-form'
import { routerActions } from 'react-router-redux'

import Navigation from 'Navigation/Navigation'
import BenefitForm from 'Employees/components/BenefitForm'

class AddBenefit extends Component {
  static propTypes = {
    addBenefit: PropTypes.func.isRequired,
    goBackToSalary: PropTypes.func.isRequired,
    params: PropTypes.object.isRequired
  }

  addBenefit = (values) => {
    const { addBenefit, goBackToSalary, params: { salaryId } } = this.props
    addBenefit(values)
    goBackToSalary(salaryId)
  }

  render () {
    return (
      <BenefitForm {...this.props} onSubmit={this.addBenefit} onCancel={() => this.props.goBackToSalary(this.props.params.salaryId)} t={this.props.t('form')} />
    )
  }
}

const AddBenefitWithNavigation = (props) =>
  <Navigation title={props.t('navbar', 'title').s} hideFooter className='f-column' backLink={`/salaries/${props.params.salaryId}/edit`} forceBackLink>
    <AddBenefit {...props} />
  </Navigation>

AddBenefitWithNavigation.propTypes = {
  params: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
  t: state.i18n.get('app', 'views', 'Salaries', 'AddBenefitView'),
  tButtons: state.i18n.get('app', 'shared', 'buttons')
})

const mapDispatchToProps = (dispatch) => ({
  addBenefit: (value) => dispatch(arrayPush('salary', 'benefits', value)),
  goBackToSalary: () => dispatch(routerActions.goBack())
})

export const AddBenefitView = connect(mapStateToProps, mapDispatchToProps)(AddBenefit)
export default connect(mapStateToProps, mapDispatchToProps)(AddBenefitWithNavigation)
