import { bind } from 'redux-effects'
import { createAction } from 'redux-actions'
import { get } from 'lodash'

import { showNavbarSpinnerAction, hideNavbarSpinnerAction } from 'shared/actions/navbarSpinner'

import {fetch} from 'shared/helpers/fetch'

import { MILEAGE_REPORTS_LIST, MILEAGE_REPORT_CREATE } from '../constants/Api'

// Helpers from shared
import { DRIVER_LOGS_LIST_EXTEND_SUCCESS, DRIVER_LOGS_LIST_RELOAD_SUCCESS } from 'mrshoebox-ui-components/src/modules/DriverLogs/constants/ActionTypes'
import responseSerializer from 'mrshoebox-ui-components/src/modules/DriverLogs/helpers/listResponseSerializer'
import createListRequestParams from 'mrshoebox-ui-components/src/modules/DriverLogs/helpers/listRequestParamsCreator'

const extendListAction = createAction(DRIVER_LOGS_LIST_EXTEND_SUCCESS)
const reloadListAction = createAction(DRIVER_LOGS_LIST_RELOAD_SUCCESS)

export function reloadDriverLogsListAction () {
  return (dispatch, getState) => {
    const filters = get(getState(), 'form.mileagesViewFilters.values')

    return dispatch([
      showNavbarSpinnerAction(),
      bind(fetchMileageReports(filters), processListReload, processError)
    ])
  }
}

function processListReload (response) {
  return [
    hideNavbarSpinnerAction(),
    reloadListAction(responseSerializer(response.value))
  ]
}

export function extendDriverLogsListAction () {
  return (dispatch, getState) => {
    const filters = get(getState(), 'form.mileagesViewFilters.values')
    const lastPageLoaded = getState().driverLogs.pagination.page
    return dispatch([
      showNavbarSpinnerAction(),
      bind(fetchMileageReports(filters, lastPageLoaded + 1), processListExtend, processError)
    ])
  }
}

function processListExtend (response) {
  return [
    hideNavbarSpinnerAction(),
    extendListAction(responseSerializer(response.value))
  ]
}

export function fetchMileageReports (filters, pageToLoad = 1) {
  const params = createListRequestParams(pageToLoad, filters)
  return fetch(MILEAGE_REPORTS_LIST, params, {
    method: 'GET'
  })
}

export function bulkUpdateMileageReportsAction (mileageReports) {
  return fetch(MILEAGE_REPORT_CREATE, {}, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ driver_reports: mileageReports })
  })
}

function processError (response) {
  console.error('mileageReports processError', response)
}
