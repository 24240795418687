import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { omit } from 'lodash'
import { orgNumberValid, orgNumberMaskProps, haveRoaringSuggestions } from 'mrshoebox-ui-components/src/helpers/countrySpecific'

import Input from 'shared/components/FormInput'
import { updateSupplierName } from '../../actions/suggestions'

class OrgNumberInput extends Component {
  static propTypes = {
    updateSupplierName: PropTypes.func,
    placeholder: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.func
    ]),
    input: PropTypes.object
  }

  orgNumberChangeHandler = (event) => {
    const { input: { onChange }, updateSupplierName } = this.props

    if (event.target.value && orgNumberValid(event.target.value) && haveRoaringSuggestions) {
      updateSupplierName(orgNumberMaskProps.normalize(event.target.value))
    }
    onChange(event.target.value)
  }

  render () {
    const inputProps = omit(this.props, ['updateSupplierName'])
    return (
      <Input
        {...inputProps}
        onChange={this.orgNumberChangeHandler}
      />
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateSupplierName: (...props) => { dispatch(updateSupplierName(...props)) }
})

export default connect(null, mapDispatchToProps)(OrgNumberInput)
