import PropTypes from 'prop-types'
import React, { Component } from 'react'
import {connect} from 'react-redux'

// Components
import Navigation from 'Navigation/Navigation'
import ListView from 'shared/components/ListView/ListView'
import ListItem from 'Events/Events/components/ListItem/ListItem'

// Actions
import { extendEventsAction, clearEventsListAction } from 'Events/shared/actions/events'
import { loadEventRowsAction } from 'Events/shared/actions/eventRows'

export class EventsView extends Component {
  static propTypes = {
    loadMore: PropTypes.func,
    loadEventRows: PropTypes.func,
    clearList: PropTypes.func,
    hasNextPage: PropTypes.bool,
    mockData: PropTypes.arrayOf(PropTypes.object),
    lastPageLoaded: PropTypes.number,
    location: PropTypes.object,
    t: PropTypes.func
  }

  constructor (props) {
    super(props)
    this.extendList = this.extendList.bind(this)
  }

  extendList () {
    const {hasNextPage, lastPageLoaded, mockData, loadMore, loadEventRows, location: {query}} = this.props
    if (hasNextPage) {
      (query.projectId || query.useEventRows) ? loadEventRows(query, lastPageLoaded + 1) : loadMore(mockData, query, lastPageLoaded + 1)
    }
  }
  componentWillMount () {
    this.extendList()
  }
  componentWillUnmount () {
    this.props.clearList()
  }

  render () {
    const {t, mockData, location: {query: {backLink}, pathname}} = this.props
    return (
      <Navigation title={t('navbar', 'title').s} backlink={backLink} className='events-view' hideFooter infinityScrollHandler={this.extendList}>
        <ListView data={mockData} query={pathname} template={ListItem} />
      </Navigation>
    )
  }
}

function mapStateToProps (state) {
  return {
    mockData: state.events.data,
    lastPageLoaded: state.events.lastPageLoaded,
    hasNextPage: state.events.hasNextPage,
    t: state.i18n.get('app', 'views', 'EventsView')
  }
}

function mapDispatchProps (dispatch) {
  return {
    loadMore: (list, filters, pageToLoad) => { dispatch(extendEventsAction(list, filters, pageToLoad)) },
    loadEventRows: (requestParams, pageToLoad) => { dispatch(loadEventRowsAction(requestParams, pageToLoad)) },
    clearList: () => { dispatch(clearEventsListAction()) }
  }
}
export default connect(mapStateToProps, mapDispatchProps)(EventsView)
