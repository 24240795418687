import PropTypes from 'prop-types'
import React, { Component } from 'react'
import {connect} from 'react-redux'
import { Link } from 'react-router'
import cs from 'classnames'

// Components
import Navigation from 'Navigation/Navigation'
import Icon from 'shared/components/Icon'
import MoreLinks from 'More/components/MoreLinks'

import { includes } from 'lodash'
import getGreyLabelOptions from 'GreyLabel/helpers/greyLabel'
import { isIntegrationsButtonAvailable, isSalaryFeatureAvailable } from 'shared/helpers/releaseHelpers'

class MoreView extends Component {
  static propTypes = {
    userSettings: PropTypes.object,
    t: PropTypes.func,
    notRightIcon: PropTypes.bool,
    isIpad: PropTypes.bool
  }

  get navigateActions () {
    const {notRightIcon} = this.props
    if (!notRightIcon) {
      return [{icon: 'icon-gear', path: '/settings'}]
    }
  }

  get menuList () {
    const { t } = this.props

    let list = [
      {icon: 'icon-customer-invoices', title: t('buttons', 'customerInvoices').s, path: '/more/customer-invoices', featureName: 'invoices'},
      {icon: 'icon-supplier-invoices', title: t('buttons', 'supplierInvoices').s, path: '/more/supplier-invoices', featureName: 'invoices'},
      {icon: 'icon-tags', title: t('buttons', 'project').s, path: '/projects', featureName: 'projects'},
      {icon: 'icon-car-detail', title: t('buttons', 'driversLog').s, path: '/driver_logs', featureName: 'mileage'},
      {icon: 'icon-documents', title: t('buttons', 'document').s, path: '/documents', featureName: 'documents'},
      {icon: 'icon-timesheets', title: t('buttons', 'timesheet').s, path: '/timesheets', featureName: 'timesheets'}
    ]

    if (isIntegrationsButtonAvailable()) {
      list.unshift({icon: 'icon-integrations', title: t('buttons', 'integrations').s, path: '/integrations'})
    }

    if (isSalaryFeatureAvailable()) {
      list.push({icon: 'icon-cost', title: t('buttons', 'salary').s, path: '/more/salary', featureName: 'salary'})
    }

    return list
  }

  get marketplaceLink () {
    const whiteLabelsWithFeature = ['mrshoebox', 'digitalaforetaget', 'slipp']
    if (whiteLabelsWithFeature.includes(getGreyLabelOptions().whiteLabel)) {
      return (
        <Link to='/marketplace' className={`more__market-place ${this.setActiveLink('marketplace').s}`}>
          <Icon type='meditator-balls' />
          <h3>{this.props.t('buttons', 'marketplace').s}</h3>
        </Link>
      )
    }
  }

  get messagesLink () {
    const { t, userSettings } = this.props
    const { whiteLabel } = getGreyLabelOptions()
    const linkClass = cs('more__messages', whiteLabel)

    if (!userSettings.accountant) {
      return null
    }

    return (
      <Link to='/messages' className={linkClass} >
        <span className='icon-messages' />
        <h3>{t('buttons', 'messages').s}</h3>
      </Link>
    )
  }

  setActiveLink (entity, pathname = window.location.pathname, activeClass = 'more__market-place--active') {
    if (this.props.isIpad && includes(pathname, entity)) {
      return activeClass
    } else {
      return ''
    }
  }

  render () {
    const { t } = this.props

    return (
      <Navigation title={t('navbar', 'title').s} home actions={this.navigateActions} className='more'>
        {/* {this.marketplaceLink} */}
        {this.messagesLink}
        <MoreLinks linksConfig={this.menuList} />
      </Navigation>
    )
  }
}

function mapStateToProps (state) {
  return {
    userSettings: state.userSettings,
    t: state.i18n.get('app', 'views', 'MoreView')
  }
}

export default connect(mapStateToProps)(MoreView)
