import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { routerActions } from 'react-router-redux'
import { registerField } from 'redux-form'

import Navigation from 'Navigation/Navigation'
import EmployeeForm from 'Employees/components/EmployeeForm'
import { getEmployee, saveEmployee, clearEmployee } from 'Employees/actions'

class Employee extends Component {
  static propTypes = {
    params: PropTypes.object,
    loadEmployee: PropTypes.func.isRequired,
    save: PropTypes.func.isRequired,
    clearEmployee: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    registerBenefitsField: PropTypes.func.isRequired
  }

  componentDidMount () {
    const { params: { employeeId }, loadEmployee, clearEmployee, location: { query: { cameFrom } }, registerBenefitsField } = this.props
    registerBenefitsField() // To prevent benefits list not being rendered on first render in BenefitsView.

    if (employeeId !== 'new' && cameFrom !== 'benefits') {
      clearEmployee()
      loadEmployee(employeeId)
    }

    if (employeeId === 'new' && cameFrom !== 'benefits') {
      clearEmployee()
    }
  }

  componentDidUpdate (prevProps) {
    const { params: { employeeId }, loadEmployee, clearEmployee } = this.props
    if (prevProps.params.employeeId !== employeeId && employeeId !== 'new') {
      clearEmployee()
      loadEmployee(employeeId)
    }

    if (employeeId === 'new' && employeeId !== prevProps.params.employeeId) {
      clearEmployee()
    }
  }

  render () {
    return (
      <EmployeeForm {...this.props} t={this.props.t('form')} />
    )
  }
}

const EmployeeWithNavigation = (props) =>
  <Navigation title={props.title} backLink='/employees' forceBackLink>
    <Employee {...props} />
  </Navigation>

EmployeeWithNavigation.propTypes = {
  title: PropTypes.string
}

const mapStateToProps = (state) => {
  let employee = state.employees.employee
  return ({
    t: state.i18n.get('app', 'views', 'Employees', 'EmployeeView'),
    initialValues: state.employees.employee,
    title: employee.firstName || employee.lastName ? `${employee.firstName} ${employee.lastName}` : ''
  })
}

const mapDispatchToProps = (dispatch) => ({
  loadEmployee: (id) => dispatch(getEmployee(id)),
  save: (values, employeeId) => dispatch(saveEmployee(values, employeeId)),
  goToBenefits: (id) => dispatch(routerActions.push(`/employees/${id}/benefits`)),
  clearEmployee: () => dispatch(clearEmployee()),
  registerBenefitsField: () => dispatch(registerField('employee', 'benefits', 'FieldArray'))
})

export const EmployeeView = connect(mapStateToProps, mapDispatchToProps)(Employee)
export default connect(mapStateToProps, mapDispatchToProps)(EmployeeWithNavigation)
