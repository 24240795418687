import '../EventsView.scss?global'

import PropTypes from 'prop-types'

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getFormValues } from 'redux-form'

// Components
import Navigation from 'Navigation/Navigation'
import EventsViewFilters from 'Events/Events/components/Filter/Filter'
import ListView from 'shared/components/ListView/ListView'
import { isEqual, get } from 'lodash'

import ListItem from 'Events/Events/components/ListItem/ListItem'

// Constants
import { STATUS_REJECTED } from 'Events/shared/constants/EventStatus'

// Actions
import { reloadEventsAction, extendEventsAction, clearEventsListAction } from 'Events/shared/actions/events'

class EventsView extends Component {
  static propTypes = {
    reloadList: PropTypes.func,
    loadMore: PropTypes.func,
    clearList: PropTypes.func,
    filters: PropTypes.object,
    hasNextPage: PropTypes.bool,
    mockData: PropTypes.arrayOf(PropTypes.object),
    lastOffset: PropTypes.number,
    t: PropTypes.func
  }

  constructor (props) {
    super(props)
    this.state = {wasScroll: false}
    this.extendList = this.extendList.bind(this)
    this.onScroll = this.onScroll.bind(this)
  }

  extendList () {
    const {hasNextPage, lastOffset, mockData, filters, loadMore} = this.props
    if (hasNextPage) {
      loadMore(mockData, filters, lastOffset + 20)
    }
  }

  componentDidMount () {
    this.props.reloadList(this.props.filters)
  }

  componentDidUpdate (props) {
    const {filters} = this.props
    if (props.filters && isEqual(props.filters, filters) === false) {
      this.props.reloadList(this.props.filters)
    }
  }

  componentWillUnmount () {
    this.props.clearList()
  }

  getTimeRangeAvailability () {
    const {filters} = this.props
    let timeRange = null
    if (filters) {
      return filters.orderBy === 'document_date'
    }
    return timeRange
  }

  onScroll () {
    this.refs.filter.onCollapseFilter()
  }

  render () {
    const {t, mockData, filters} = this.props

    const emptyListTitle = (filters && filters.status === STATUS_REJECTED) ? t('labels', 'noErrorEvents').s : null
    return (
      <Navigation title={t('navbar', 'title').s} home className='events-view' filterBlock infinityScrollHandler={this.extendList} scrollHandler={this.onScroll} filterClass='events-nav-filter' >
        <EventsViewFilters ref='filter' />
        <ListView data={mockData} template={ListItem} timeRange={this.getTimeRangeAvailability()} emptyListOptions={{icon: 'icon-events', title: emptyListTitle, showNotFound: Boolean(get(filters, ['search', 'length']))}} />
      </Navigation>
    )
  }
}

function mapStateToProps (state) {
  return {
    mockData: state.events.data,
    lastOffset: state.events.lastOffset,
    hasNextPage: state.events.hasNextPage,
    filters: getFormValues('eventsViewFilters')(state),
    t: state.i18n.get('app', 'views', 'EventsView')
  }
}

function mapDispatchProps (dispatch) {
  return {
    reloadList: (filters) => { dispatch(reloadEventsAction(filters, true)) },
    loadMore: (list, filters, newOffset) => { dispatch(extendEventsAction(list, filters, newOffset, true)) },
    clearList: () => { dispatch(clearEventsListAction()) }
  }
}
export default connect(mapStateToProps, mapDispatchProps)(EventsView)
