import React from 'react'
import { Route } from 'react-router'

import {requireAuthentication} from 'User/Auth/higherOrderComponents/AuthComponent'
import loadEvent from 'Events/shared/higherOrderComponents/LoadEvent'

// Views
import EventsView from 'Events/Events/views/EventsView/EventsView'
import SpecificEventsView from 'Events/Events/views/SpecificEventsView/SpecificEventsView'
import EventView from 'Events/Event/views/EventView/EventView'
import EventMessagesView from 'Events/Event/views/EventMessagesView/EventMessagesView'
import EventPayView from 'Events/EventPay/views/EventPayView/EventPayView'

import EventWithdrawalCreateView from 'Events/Withdrawal/views/WithdrawalCreateView/WithdrawalCreateView'

import RevenueView from 'Events/Revenue/views/RevenueView/RevenueView'

import NewDepositView from 'Events/Deposit/views/DepositView/DepositView'

import ExpenseView from 'Events/Expense/views/ExpenseView/ExpenseView'
import RepresentationView from 'Events/Expense/views/RepresentationView/RepresentationView'
import ContactsImportForRepresentation from 'Events/Expense/views/ContactsImportForRepresentation/ContactsImportForRepresentation'

import EventTransferFormView from 'Events/BankTransfer/views/BankTransferFormView/BankTransferFormView'

import ImportSupplierView from 'Events/Expense/views/ImportSupplierView/ImportSupplierView'

// Form cleaners
import { clearNewEventAction, clearReceiptFormAction } from 'Events/shared/actions/event'

const clearEvent = (actions) => (dispatch, prevState) => {
  const prevRoute = prevState.routes[prevState.routes.length - 1].path
  if (prevRoute !== '/events/new/expense/suppliers' && prevRoute !== '/events/edit/:id/expense/suppliers') {
    dispatch(actions)
  }
}

const clearEventInfo = clearEvent([clearNewEventAction(), clearReceiptFormAction()])

const eventsRoutes = (
  <Route>
    <Route path='/events' component={requireAuthentication(EventsView)} />
    <Route path='/events/specific' component={requireAuthentication(SpecificEventsView)} />
    <Route path='/events/:eventId' component={requireAuthentication(EventView)} />
    <Route path='/events/:eventId/messages' component={requireAuthentication(EventMessagesView)} />
    <Route path='/events/:eventId/pay' component={requireAuthentication(EventPayView)} />

    <Route onLeave={clearEventInfo}>
      <Route path='/events/new/withdrawal' component={requireAuthentication(EventWithdrawalCreateView)} />
      <Route path='/events/edit/:id/withdrawal' component={requireAuthentication(loadEvent(EventWithdrawalCreateView))} />

      <Route path='/events/new/revenue' component={requireAuthentication(RevenueView)} />
      <Route path='/events/edit/:id/revenue' component={requireAuthentication(loadEvent(RevenueView))} />

      <Route path='/events/new/expense/representation' component={requireAuthentication(RepresentationView)} />
      <Route path='/events/new/expense/representation/import_contacts' component={requireAuthentication(ContactsImportForRepresentation)} />
      <Route path='/events/new/expense/suppliers' component={requireAuthentication(ImportSupplierView)} />

      <Route path='/events/new/expense' component={requireAuthentication(ExpenseView)} />
      <Route path='/events/edit/:id/expense' component={requireAuthentication(loadEvent(ExpenseView))} />

      <Route path='/events/edit/:id/expense/representation' component={requireAuthentication(loadEvent(RepresentationView))} />
      <Route path='/events/edit/:id/expense/representation/import_contacts' component={requireAuthentication(ContactsImportForRepresentation)} />
      <Route path='/events/edit/:id/expense/suppliers' component={requireAuthentication(ImportSupplierView)} />

      <Route path='/events/new/deposit' component={requireAuthentication(NewDepositView)} />
      <Route path='/events/edit/:id/deposit' component={requireAuthentication(loadEvent(NewDepositView))} />

      <Route path='/events/new/transfer' component={requireAuthentication(EventTransferFormView)} />
      <Route path='/events/edit/:id/transfer' component={requireAuthentication(loadEvent(EventTransferFormView))} />
    </Route>
  </Route>
)

export default eventsRoutes
