import '../styles/index.scss?global'

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { routerActions } from 'react-router-redux'
import Icon from 'shared/components/Icon'
import { range } from 'lodash'

import {
  selectCategory, getListOfRequestsAction,
  sendEmailAction, changeWelcomeModalState
} from 'MarketPlace/Index/actions'
import { blockWelcomeModal } from 'MarketPlace/shared/actions'
import { loadOnboardingInfo } from 'MarketPlace/Capcito/actions/capcito'

import { isIOS } from 'shared/helpers/utils'

import ContentModal from '../components/ContentModal'
import NotActivated from '../components/NotActivated'
import ValidationErrorModal from '../components/ValidationErrorModal'
import Knowledge from '../components/Knowledge'
import WelcomeModal from '../components/WelcomeModal'
import Navigation from 'Navigation/Navigation'

import {
  PENSION, KNOWLEDGE, VACANCIES, ADVERTISING,
  LAW, INSURANCE, CREDIT_INFORMATION, FINANCING
} from 'MarketPlace/Index/constants'
import { CAPCITO } from 'MarketPlace/shared/constants/vendors'
import getGreyLabelOptions from 'GreyLabel/helpers/greyLabel'

const pieceIconNames = ['house-dollar', 'pile-of-books', 'hand-love', 'chart-line', 'scales', 'arms-dollar', 'loupe-on-doc', 'pie-piece']

const settings = {
  piePieceFromIndex: 1,
  piePieceToIndex: 9,
  piePieceSvgViewBoxCoord: '0 0 217.59 167.29',
  piePieceFigureCoord: 'M110.41,160a142.27,142.27,0,0,1,42.28,6.4,8,8,0,0,0,9.76-4.62L217,28.18a8,8,0,0,0-4.67-10.55A303.34,303.34,0,0,0,5.19,18.83,8,8,0,0,0,.63,29.43l56.1,133a8,8,0,0,0,9.8,4.51A142.27,142.27,0,0,1,110.41,160Z',
  piePieceLineCoord: 'M7.56,28.38q3.19-1.19,6.41-2.3t6.48-2.16q3.25-1,6.54-2T33.59,20q3.32-.89,6.67-1.71T47,16.77q3.38-.74,6.79-1.4t6.84-1.24Q64,13.55,67.51,13t6.95-.92q3.49-.42,7-.76t7-.59q3.54-.26,7.09-.43t7.14-.26q3.58-.09,7.18-.09t7,.08q3.5.08,7,.25t6.93.41q3.46.24,6.89.57t6.84.73q3.41.4,6.8.88t6.75,1q3.36.56,6.69,1.19t6.64,1.34q3.31.71,6.59,1.49t6.53,1.63q3.25.85,6.47,1.78t6.41,1.92q3.19,1,6.35,2.06t6.28,2.2'
}

const categories = {
  [PENSION]: 1,
  [KNOWLEDGE]: 2,
  [VACANCIES]: 3,
  [ADVERTISING]: 4,
  [LAW]: 5,
  [INSURANCE]: 6,
  [CREDIT_INFORMATION]: 7,
  [FINANCING]: 8
}

class MarketPlace extends Component {
  constructor (props) {
    super(props)
    this.state = {
      chosenCategoryIndex: null,
      lastViewedCategoryIndex: null, // Need this to prevent modal content dissapearing before modal close animation finishes.
      showMailErrorModal: false,
      shouldShowWelcomeModal: false,
      creditsafeLink: null,
      capcitoRedirectLink: null
    }
    this.resetCategoryIndex = this.resetCategoryIndex.bind(this)
    this.sendEmailHandler = this.sendEmailHandler.bind(this)
  }
  static propTypes = {
    t: PropTypes.func,
    blockWelcomeModal: PropTypes.func.isRequired,
    redirectToAdvertising: PropTypes.func,
    redirectToLaw: PropTypes.func,
    redirectToPension: PropTypes.func,
    redirectToCreditInformation: PropTypes.func,
    redirectToCreditsafePortal: PropTypes.func,
    selectCategory: PropTypes.func,
    sendEmail: PropTypes.func,
    redirectToSettings: PropTypes.func,
    getListOfRequests: PropTypes.func,
    sentRequestsList: PropTypes.array,
    userInfo: PropTypes.object,
    markWelcomeModalContentAsRead: PropTypes.func,
    isFirstVisit: PropTypes.bool,
    loadCapcitoOnboardingInfo: PropTypes.func.isRequired,
    redirectToCapcitoPortal: PropTypes.func.isRequired,
    redirectToCapcitoOnboarding: PropTypes.func.isRequired,
    redirectToVacancies: PropTypes.func
  }

  getModalContent = () => {
    const { chosenCategoryIndex, lastViewedCategoryIndex } = this.state
    let modalContentComponent
    if (this.isNotActivated(chosenCategoryIndex)) {
      modalContentComponent = <NotActivated t={this.props.t} />
    } else if (chosenCategoryIndex === categories[KNOWLEDGE] || lastViewedCategoryIndex === categories[KNOWLEDGE]) {
      modalContentComponent = <Knowledge
        t={this.props.t}
        sendEmail={this.sendEmailHandler}
        isDelivered={this.props.sentRequestsList.some(request => request.category === KNOWLEDGE)}
        close={this.resetCategoryIndex}
      />
    }

    return modalContentComponent
  }

  onClick (chosenCategoryIndex) {
    const {
      selectCategory, redirectToAdvertising, redirectToLaw, redirectToPension, redirectToVacancies
    } = this.props

    return () => {
      this.setState({chosenCategoryIndex, lastViewedCategoryIndex: chosenCategoryIndex})
      selectCategory(chosenCategoryIndex)
      setTimeout(() => {
        if (this.state.chosenCategoryIndex === categories[ADVERTISING]) {
          redirectToAdvertising()
        } else if (this.state.chosenCategoryIndex === categories[PENSION]) {
          redirectToPension()
        } else if (this.state.chosenCategoryIndex === categories[LAW]) {
          redirectToLaw()
        } else if (this.state.chosenCategoryIndex === categories[CREDIT_INFORMATION]) {
          this.redirectToCreditsafe()
        } else if (this.state.chosenCategoryIndex === categories[FINANCING]) {
          if (this.isFinancingFeatureActivated()) this.handleCapcito()
        } else if (this.state.chosenCategoryIndex === categories[VACANCIES]) {
          redirectToVacancies()
        }
      }, 500)
    }
  }

  redirectToCreditsafe = () => {
    if (this.props.sentRequestsList.find(request => request.category === CREDIT_INFORMATION)) {
      this.props.redirectToCreditsafePortal()
    } else {
      this.props.redirectToCreditInformation()
    }
  }

  handleCapcito = () => {
    if (this.props.sentRequestsList.some(request => request.vendor === CAPCITO)) {
      if (isIOS()) {
      // Capcito auth doesn't work in iOS iframe because cookies don't save,
      // so have to open new tab.
        this.openCapcitoPortalInNewTab(this.state.capcitoRedirectLink)
      } else {
        this.props.redirectToCapcitoPortal()
      }
    } else {
      this.props.redirectToCapcitoOnboarding()
    }
  }

  openCapcitoPortalInNewTab = (url) => {
    window.open(url, '_blank')
  }

  resetCategoryIndex () {
    this.setState({chosenCategoryIndex: null})
  }

  getPiePieceClass () {
    // const chosenCategoryIndex = this.state.chosenCategoryIndex
    // const { sentRequestsList } = this.props
    // const categoryName = getKeyByValue(categories, element)

    let pieceClass = 'market-place__pie__piece'
    // // Highlighting of activated apps
    // if (chosenCategoryIndex === element || sentRequestsList.find(request => request.category === categoryName)) {
    //   pieceClass = `${pieceClass} market-place__pie__piece--${element}`
    // }
    return pieceClass
  }

  isKnowledge (categoryIndex) {
    return categoryIndex === 2
  }

  isFinancingFeatureActivated () {
    return (['mrshoebox', 'slipp'].includes(getGreyLabelOptions().whiteLabel))
  }

  isNotActivated (categoryIndex) {
    let activatedCategories = [
      categories[KNOWLEDGE],
      categories[ADVERTISING],
      categories[LAW],
      categories[CREDIT_INFORMATION]
    ]
    if (this.isFinancingFeatureActivated()) {
      activatedCategories.push(categories[FINANCING])
    }

    return categoryIndex && !activatedCategories.includes(categoryIndex)
  }

  sendEmailHandler (categoryName) {
    if (validateUserInfo(this.props.userInfo)) {
      this.props.sendEmail(categoryName)
    } else {
      this.setState({showMailErrorModal: true})
    }
  }

  getContentModalClass () {
    return this.isKnowledge(this.state.chosenCategoryIndex) ? 'knowledge-wrapper' : ''
  }

  isContentModalVisible = () => {
    let contentModalCategories = [
      categories[KNOWLEDGE],
      categories[INSURANCE]
    ]
    if (!this.isFinancingFeatureActivated()) contentModalCategories.push(categories[FINANCING])

    return contentModalCategories.includes(this.state.chosenCategoryIndex)
  }

  async componentDidMount () {
    this.props.getListOfRequests()
    if (isIOS()) await this.getCapcitoLinkState()
    // delay needed to prevent wrong initial pop up after first render (before we get connected categories list)
    setTimeout(() => {
      this.setState({
        shouldShowWelcomeModal: this.props.isFirstVisit
      })
      this.props.blockWelcomeModal(false)
    }, 600)
  }

  async getCapcitoLinkState () {
    const result = await this.props.loadCapcitoOnboardingInfo({ refreshRedirectLink: true })
    if (result) this.setState({ capcitoRedirectLink: result.data.redirectLink })
  }

  render () {
    const { t, markWelcomeModalContentAsRead } = this.props

    return (
      <div className='market-place'>
        <h2><b>{t('selectCategory').s}</b></h2>
        <div className='market-place__pie'>
          <Icon className='market-place__pie__center' type='meditator' />
          {range(settings.piePieceFromIndex, settings.piePieceToIndex).map((elem, i) => {
            return (
              <div className={this.getPiePieceClass()} key={i}>
                <div className='market-place__pie__piece__text' onClick={this.onClick(elem)}>{t('categories', String(elem)).s}</div>
                <div className='market-place__pie__piece__circle'>
                  <Icon type={pieceIconNames[i]} />
                </div>
                <svg viewBox={settings.piePieceSvgViewBoxCoord}>
                  <g>
                    <path onClick={this.onClick(elem)} className='market-place__pie__piece__shape' d={settings.piePieceFigureCoord} />
                    <path className='market-place__pie__piece__line' d={settings.piePieceLineCoord} />
                  </g>
                </svg>
              </div>
            )
          })}
        </div>
        {this.state.shouldShowWelcomeModal &&
          <WelcomeModal
            t={this.props.t}
            markAsViewed={markWelcomeModalContentAsRead}
          />
        }
        <ContentModal
          content={this.getModalContent()}
          onClose={this.resetCategoryIndex}
          isVisible={this.isContentModalVisible()}
          customClass={this.getContentModalClass()}
        />
        <ValidationErrorModal
          t={this.props.t}
          close={this.resetCategoryIndex}
          isVisible={this.state.showMailErrorModal}
          redirectToSettings={this.props.redirectToSettings}
        />
      </div>
    )
  }
}

function mapStateToProps (state) {
  const { name, email, phone } = state.userSettings.user
  const { companyName, organizationNumber, primaryAddress } = state.userSettings.company
  const userInfo = { name, email, phone, companyName, organizationNumber, primaryAddress }
  const { sentRequestsList, welcomeModalIsBlocked, welcomeModalWasViewed } = state.marketplace.index
  const hasConnectedCategory = sentRequestsList.length > 0

  let isFirstVisit = true
  if (hasConnectedCategory || welcomeModalIsBlocked || welcomeModalWasViewed) {
    isFirstVisit = false
  }

  return {
    t: state.i18n.get('app', 'views', 'MarketPlace'),
    sentRequestsList,
    userInfo,
    isFirstVisit
  }
}

function mapDispatchToProps (dispatch) {
  return {
    blockWelcomeModal: (...args) => dispatch(blockWelcomeModal(...args)),
    getListOfRequests: () => dispatch(getListOfRequestsAction()),
    selectCategory: (category) => dispatch(selectCategory(category)),
    redirectToAdvertising: () => dispatch(routerActions.push('/marketplace/advertising')),
    redirectToLaw: () => dispatch(routerActions.push('/marketplace/law')),
    redirectToPension: () => dispatch(routerActions.push('/marketplace/pension')),
    redirectToFactoring: () => dispatch(routerActions.push('/marketplace/factoring')),
    redirectToCreditInformation: () => dispatch(routerActions.push('/marketplace/creditsafe')),
    redirectToCreditsafePortal: () => dispatch(routerActions.push('marketplace/creditsafe/portal')),
    redirectToSettings: () => dispatch(routerActions.push('/settings')),
    sendEmail: (categoryName) => dispatch(sendEmailAction(categoryName)),
    markWelcomeModalContentAsRead: () => dispatch(changeWelcomeModalState({ welcomeModalWasViewed: true })),
    loadCapcitoOnboardingInfo: (options) => dispatch(loadOnboardingInfo(options)),
    redirectToCapcitoPortal: () => dispatch(routerActions.push('/marketplace/capcito/portal')),
    redirectToCapcitoOnboarding: () => dispatch(routerActions.push('/marketplace/capcito')),
    redirectToVacancies: () => dispatch(routerActions.push('/vacancies'))
  }
}

class MarketPlaceViewWithNavigation extends Component {
  static propTypes = {
    t: PropTypes.func
  }
  render () {
    const {t} = this.props
    return (
      <div className='marketplace__with-navigation'>
        <Navigation title={t('title').s} backLink='/more' forceBackLink>
          <MarketPlace {...this.props} />
        </Navigation>
      </div>
    )
  }
}

function validateUserInfo (userInfo) {
  let errors = []
  let requiredFields = ['name', 'email', 'phone', 'companyName', 'organizationNumber', 'primaryAddress']

  requiredFields.forEach(field => !userInfo[field] && errors.push(field))

  return errors.length === 0
}

export const MarketPlaceView = connect(mapStateToProps, mapDispatchToProps)(MarketPlace)
export default connect(mapStateToProps, mapDispatchToProps)(MarketPlaceViewWithNavigation)
