import './styles/index.scss?global'

import PropTypes from 'prop-types'

import React, { Component } from 'react'

export default class CheckButton extends Component {
  static propTypes = {
    value: PropTypes.bool
  }

  render () {
    const {value, ...props} = this.props
    return (
      <div className={`check-button ${value ? 'check-button--active' : ''}`} {...props}>
        <span className='icon-ok-thin' />
      </div>
    )
  }
}
