import { bind } from 'redux-effects'
import { fetch, fetch2 } from 'shared/helpers/fetch'
import { showNavbarSpinnerAction, hideNavbarSpinnerAction } from 'shared/actions/navbarSpinner'
import { routerActions } from 'react-router-redux'

import { transformPayment } from 'Payments/helpers'

import { PAYMENT, PAYMENTS_CREATE } from 'Payments/constants/Api'
import { getCurrentClientCompany } from 'api-client-connector/utils'

export function addPaymentAction (payment, eventId, isExpense) {
  const currentClientCompany = Number(getCurrentClientCompany())
  const transformedPayment = transformPayment(payment, currentClientCompany, isExpense)

  return [
    showNavbarSpinnerAction(),
    bind(addPaymentRequest(transformedPayment, eventId), () => [hideNavbarSpinnerAction(), routerActions.goBack()])
  ]
}

export function addPaymentRequest (payment, eventId) {
  return fetch2(PAYMENTS_CREATE, {}, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: {...payment, source_document_id: Number(eventId)}
  })
}

export function updatePaymentRequest ({payment_id, ...payment}) {
  return fetch(PAYMENT, {id: payment_id}, {
    method: 'PATCH',
    header: {
      'Content-Type': 'application/json'
    },
    body: payment
  })
}

export function deletePaymentRequest (paymentId) {
  return fetch(PAYMENT, {id: paymentId}, {
    method: 'DELETE',
    header: {
      'Content-Type': 'application/json'
    },
    body: {}
  })
}
