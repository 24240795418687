export function performAfterLangLoaded (action) {
  return (dispatch, getState) => {
    if (getState().i18n.data) {
      dispatch(action)
    } else {
      setTimeout(() => {
        dispatch(performAfterLangLoaded(action))
      }, 500)
    }
  }
}
