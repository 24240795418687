import * as Sentry from '@sentry/browser'
import { createAction } from 'redux-actions'
import {
  APP_INFO_SET_PREVIOUS_LINK, APP_INFO_SET_ACTIVE_ITEM_ID,
  APP_INFO_SET_INTERNET_IS_LOST, APP_INFO_SET_NOTIFICATION_VISIBLE,
  APP_INFO_SET_WRAPPER_VERSION, APP_INFO_RELEASE_NAME
} from 'AppInfo/constants/ActionTypes'

const setPreviousLink = createAction(APP_INFO_SET_PREVIOUS_LINK)
const setActiveItemId = createAction(APP_INFO_SET_ACTIVE_ITEM_ID)
const setInternetIsLost = createAction(APP_INFO_SET_INTERNET_IS_LOST)
const setNotificationVisible = createAction(APP_INFO_SET_NOTIFICATION_VISIBLE)
export const setWrapperVersionAction = createAction(APP_INFO_SET_WRAPPER_VERSION)
export const setReleaseName = createAction(APP_INFO_RELEASE_NAME)

export function setPreviousLinkAction (link) {
  return setPreviousLink(link)
}

export function setActiveItemIdAction (id) {
  if (id === undefined || id === null) {
    Sentry.captureException(`Id is undefined or null: ${id}`)
  }
  return setActiveItemId(id)
}

export function setInternetIsLostAction (value) {
  return setInternetIsLost(value)
}

export function setNotificationVisibleAction (value) {
  return setNotificationVisible(value)
}
