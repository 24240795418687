import { createAction } from 'redux-actions'

import { ADD_BRIDGE_REQUEST, REMOVE_BRIDGE_REQUEST } from 'appBridge/constants/actionTypes'

const addRequest = createAction(ADD_BRIDGE_REQUEST)
const removeRequest = createAction(REMOVE_BRIDGE_REQUEST)

export function addRequestAction (uuid, pendingPromise) {
  return addRequest({[uuid]: pendingPromise})
}

export function removeRequestAction (uuid) {
  return removeRequest(uuid)
}
