import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import styles from './styles.scss'

import Button from 'shared/components/Button/Button'
import FormatNumber from 'shared/components/FormatNumber/FormatNumber'
import LoanInfoBlock from 'Invoices/SupplierInvoices/components/LoanInfoBlock'

import { isMrshoeboxRelease } from 'shared/helpers/releaseHelpers'

import {
  approveInvoices,
  unapproveInvoices
} from '../../actions/supplierInvoices'

class ApproveForPaymentsBlock extends Component {
  static propTypes = {
    invoices: PropTypes.array,
    approveInvoices: PropTypes.func,
    unapproveInvoices: PropTypes.func,
    withButtons: PropTypes.bool
  }

  getCheckedInvoices () {
    return this.props.invoices.filter(i => i.checkedForAction)
  }

  getInvoicesForApprove () {
    // show sum only for approve invoices,
    // dont show for unapprove
    const invoicesToApprove = this.getCheckedInvoices()
      .filter(i => !i.invoiceApprovedForPayment)
    return invoicesToApprove
  }

  renderSumToApprove () {
    const { t, withButtons } = this.props
    // FIXME: !withButtons - approved invoices tab, on which we show all. Make it clearer
    const invoicesToSum = withButtons
      ? this.getInvoicesForApprove()
      : this.getCheckedInvoices()
    if (invoicesToSum.length === 0) return null

    const approveSum = invoicesToSum
      .reduce((acc, i) => acc + i.amount, 0)

    return <div className={styles.sum}>
      <div> {t('totalSum').s}: </div>
      <div className={styles.sumNumber}> <FormatNumber value={approveSum} /> {invoicesToSum[0].currency} </div>
    </div>
  }

  checkedInvoicesAction = () => {
    const { approveInvoices, unapproveInvoices } = this.props

    const invoicesToApprove = this.getInvoicesForApprove()
    if (invoicesToApprove.length === 0) {
      // only already approved invoices were checked, so we can cancel approved status on them.
      unapproveInvoices(this.getCheckedInvoices())
    } else {
      approveInvoices(invoicesToApprove)
    }
  }

  render () {
    const { invoices, withButtons, t } = this.props
    if (!invoices || !invoices[0]) return null

    return <div className={styles.block}>
      {this.renderSumToApprove()}
      {withButtons && <Button view={'transparent-black'} onClick={this.checkedInvoicesAction}>
        {this.getInvoicesForApprove().length === 0 ? t('unapprove').s : t('approve').s}
      </Button>}
      {isMrshoeboxRelease() && <LoanInfoBlock />}
    </div>
  }
}

function mapStateToProps (state) {
  return {
    t: state.i18n.get('app', 'views', 'Invoices', 'InvoicesView', 'ApproveForPaymentsBlock')
  }
}

function mapDispatchToProps (dispatch) {
  return {
    approveInvoices: (...args) => { dispatch(approveInvoices(...args)) },
    unapproveInvoices: (...args) => { dispatch(unapproveInvoices(...args)) }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ApproveForPaymentsBlock)
