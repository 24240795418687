import { FREE, TRIAL, COMPLETE, FINANCIAL_OVERVIEW } from '../constants/appTypes'

export default function checkPermissionByFeature (featureName, subscriptions) {
  if (!subscriptions) return false

  const canUseFeature = ({ appType }) => {
    if (appType === COMPLETE || appType === FREE || appType === TRIAL) {
      return true
    } else if (appType === FINANCIAL_OVERVIEW) {
      return financialOverviewPermittedFeatures.includes(featureName)
    }
  }

  return subscriptions.some(canUseFeature)
}

export function checkPermissionByURL (url, subscriptions) {
  if (url === '/') {
    return true
  }

  const canAccessUrl = ({ appType }) => {
    if (appType === COMPLETE || appType === FREE || appType === TRIAL) {
      return true
    } else if (appType === FINANCIAL_OVERVIEW) {
      return financialOverviewPermittedFeatures.some((feature) => url.indexOf(feature) !== -1)
    }
  }

  return subscriptions.some(canAccessUrl)
}

const financialOverviewPermittedFeatures = [
  'messages',
  'todo',
  'overview',
  'overview-result',
  'reports',
  'more',
  'settings',
  'logout',
  'specific'
]
