import React from 'react'
import { connect } from 'react-redux'

import Page from './page'

import { createDelayedRedirect } from 'shared/helpers/utils'

const mapStateToProps = (state) => ({
  t: state.i18n.get('app', 'views', 'Wizard', 'NextActions')
})
const reduxHOC = connect(mapStateToProps)

export const InvoiceTutorialPage = reduxHOC((props) => (
  <Page
    title={props.t('addInvoice', 'title').s}
    text={props.t('addInvoice', 'longDescription').s}
    onButtonClick={createDelayedRedirect(['/', '/more', '/invoices', '/invoices/new'])}
  />
))

export const ReceiptTutorialPage = reduxHOC((props) => (
  <Page
    title={props.t('addReceipt', 'title').s}
    text={props.t('addReceipt', 'longDescription').s}
    onButtonClick={createDelayedRedirect(['/', '/?popup_view=firstStep'])}
  />
))

export const DriverLogTutorialPage = reduxHOC((props) => (
  <Page
    title={props.t('addDriverlog', 'title').s}
    text={props.t('addDriverlog', 'longDescription').s}
    onButtonClick={createDelayedRedirect(['/', '/more', '/driver_logs', '/driver_logs/new'])}
  />
))

export const TimesheetTutorialPage = reduxHOC((props) => (
  <Page
    title={props.t('addTimesheet', 'title').s}
    text={props.t('addTimesheet', 'longDescription').s}
    onButtonClick={createDelayedRedirect(['/', '/more', '/timesheets', '/timesheets/new'])}
  />
))
