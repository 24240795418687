import PropTypes from 'prop-types'
import React, { Component } from 'react'
import {connect} from 'react-redux'

// Components
import Navigation from 'Navigation/Navigation'
import ListView from 'shared/components/ListView/ListView'
import SelectableListItem from 'shared/components/SelectableListItem/SelectableListItem'
import {ProjectsListItem} from 'Projects/containers/ListViewWrapper/ListViewWrapper'

// Actions
import { extendProjectsListAction } from 'Projects/actions/projectsList'
import { addItemAction, removeItemAction, removeAllImportItems } from 'ImportItems/actions/importItems'

export function EventsImportListItem ({onCheckboxChange, ...props}) {
  return (
    <SelectableListItem {...props} onChange={onCheckboxChange} >
      <ProjectsListItem {...props} notLink />
    </SelectableListItem>
  )
}

EventsImportListItem.propTypes = {
  onCheckboxChange: PropTypes.func
}

class Projects extends Component {
  static propTypes = {
    loadMore: PropTypes.func,
    projects: PropTypes.arrayOf(PropTypes.object),
    hasNextPage: PropTypes.bool,
    lastPageLoaded: PropTypes.number,
    onCheck: PropTypes.func,
    onUncheck: PropTypes.func,
    clearItems: PropTypes.func,
    itemsToImport: PropTypes.arrayOf(PropTypes.object)
  }

  constructor (props) {
    super(props)
    this.onChangeHandler = this.onChangeHandler.bind(this)
  }

  componentDidMount () {
    this.props.loadMore([], {}, 1)
  }

  componentWillUnmount () {
    this.props.clearItems()
  }

  extendList () {
    const {hasNextPage, lastPageLoaded, projects} = this.props
    if (hasNextPage) {
      this.props.loadMore(projects, {}, lastPageLoaded + 1)
    }
  }

  onChangeHandler (checked, data) {
    const { onCheck, onUncheck, clearItems } = this.props
    clearItems()
    checked ? onCheck(data) : onUncheck(data)
  }

  render () {
    const {projects, itemsToImport} = this.props
    let checkedId
    if (itemsToImport && itemsToImport[0]) {
      checkedId = itemsToImport[0].id
    }
    return (
      <ListView data={projects} template={EventsImportListItem} id={checkedId} onCheckboxChange={this.onChangeHandler} />
    )
  }
}

class ProjectsWithNavigation extends Component {
  static propTypes = {
    importItemsToForm: PropTypes.func,
    itemsToImport: PropTypes.arrayOf(PropTypes.object),
    backLink: PropTypes.string,
    t: PropTypes.func,
    tButton: PropTypes.func
  }

  constructor (props) {
    super(props)
    this.extendList = this.extendList.bind(this)
  }
  extendList () {
    this.refs.projects.extendList()
  }
  render () {
    const {t, tButton, backLink, itemsToImport, importItemsToForm} = this.props
    const actions = [{text: tButton('add').s, callback: () => { importItemsToForm(itemsToImport) }}]
    return (
      <Navigation title={t('title').s} backLink={backLink} hideFooter actions={actions} infinityScrollHandler={this.extendList}>
        <Projects {...this.props} ref='projects' />
      </Navigation>
    )
  }
}

function mapStateToProps (state) {
  return {
    projects: state.projects.projects,
    lastPageLoaded: state.projects.lastPageLoaded,
    hasNextPage: state.projects.hasNextPage,
    itemsToImport: state.importItems,
    t: state.i18n.get('app', 'views', 'ProjectImportView'),
    tButton: state.i18n.get('app', 'shared', 'buttons')
  }
}

function mapDispatchToProps (dispatch) {
  return {
    loadMore: (list, filters, pageToLoad) => dispatch(extendProjectsListAction(list, filters, pageToLoad)),
    onCheck: (data) => { dispatch(addItemAction(data)) },
    onUncheck: (data) => { dispatch(removeItemAction(data)) },
    clearItems: () => { dispatch(removeAllImportItems()) }
  }
}

export const ProjectsView = connect(mapStateToProps, mapDispatchToProps, null, { withRef: true })(Projects)

export default connect(mapStateToProps, mapDispatchToProps)(ProjectsWithNavigation)
