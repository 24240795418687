import './FinancialReportView.scss?global'

import Smartphones from 'Reports/views/FinancialReport/FinancialReportView/containers/Smartphones'
import Tablets from 'Reports/views/FinancialReport/FinancialReportView/containers/Tablets'

import {getCurrentDeviceView} from 'shared/helpers/utils'

const FinancialReportView = getCurrentDeviceView(Smartphones, Tablets)

export default FinancialReportView
