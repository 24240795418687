import React from 'react'
import PropTypes from 'prop-types'
import Input from './Input'

import { personalNumberMaskProps } from 'mrshoebox-ui-components/src/helpers/countrySpecific'

const PersonalNumberInput = (props) => {
  return (
    <Input
      {...props}
      {...personalNumberMaskProps}
    />
  )
}
PersonalNumberInput.propTypes = {
  onChange: PropTypes.func
}

export const WrappedPersonalNumberInput = ({ input, meta, ...props }) =>
  <PersonalNumberInput {...input} {...meta} {...props} />

WrappedPersonalNumberInput.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object
}

export default PersonalNumberInput
