import React, { Component } from 'react'
import PropTypes from 'prop-types'

import MissingInfo from 'MarketPlace/Collector/views/MissingInfo'
import FactoringHOC from 'MarketPlace/Collector/views/shared/FactoringHOC'
import Navigation from 'Navigation/Navigation'

class FactoringMissingInfoView extends Component {
  static propTypes = {
    t: PropTypes.func,
    cancelHandler: PropTypes.func.isRequired
  }

  render () {
    const { t, cancelHandler } = this.props
    const navBarTitle = t('MissingInfo', 'navBarTitle').s
    return (
      <div className='factoring-missing-info'>
        <Navigation hideFooter onClickBack={cancelHandler} title={navBarTitle}>
          <MissingInfo {...this.props} />
        </Navigation>
      </div>
    )
  }
}

export default FactoringHOC(FactoringMissingInfoView)
