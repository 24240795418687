exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._2wl1UjcC5rdkW6lcKC4hKw {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  padding: 0 3rem;\n}\n\n.Y28kYqy04ivqZfUPwb-v4 {\n  display: flex;\n  margin-top: auto;\n  max-width: 64rem;\n  font-size: 4rem;\n  text-align: center;\n}\n\n.DL7w3qR_pqDyY3GnS9TRE {\n  margin-top: auto;\n  width: 100%;\n}\n\n", "", {"version":3,"sources":["/home/circleci/mobile_ui/src/Wizard/views/WizardTutorials/styles.scss"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,uBAAuB;EACvB,wBAAwB;EACxB,oBAAoB;EACpB,gBAAgB;CACjB;;AAED;EACE,cAAc;EACd,iBAAiB;EACjB,iBAAiB;EACjB,gBAAgB;EAChB,mBAAmB;CACpB;;AAED;EACE,iBAAiB;EACjB,YAAY;CACb","file":"styles.scss","sourcesContent":[".page {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  padding: 0 3rem;\n}\n\n.text {\n  display: flex;\n  margin-top: auto;\n  max-width: 64rem;\n  font-size: 4rem;\n  text-align: center;\n}\n\n.button {\n  margin-top: auto;\n  width: 100%;\n}\n\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"page": "_2wl1UjcC5rdkW6lcKC4hKw",
	"text": "Y28kYqy04ivqZfUPwb-v4",
	"button": "DL7w3qR_pqDyY3GnS9TRE"
};