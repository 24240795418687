import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import IpadNavigation from 'Navigation/IpadNavigation'
import {EventMessagesView} from 'Events/Event/views/EventMessagesView/containers/Smartphones'

import getLeftComponent from 'Navigation/helpers/linkAccordance'

export class EventMessages extends Component {
  static propTypes = {
    t: PropTypes.func,
    previousLink: PropTypes.string
  }

  render () {
    const {t, previousLink} = this.props
    const Events = getLeftComponent('event', previousLink)

    return (
      <IpadNavigation {...this.props} title={t('navbar', 'title').s} backLink>
        <Events {...this.props} />
        <EventMessagesView {...this.props} />
      </IpadNavigation>
    )
  }
}

function mapStateToProps (state) {
  return {
    t: state.i18n.get('app', 'views', 'EventMessagesView'),
    previousLink: state.appInfo.previousLink
  }
}

export default connect(mapStateToProps)(EventMessages)
