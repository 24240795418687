import PropTypes from 'prop-types'
import React, { Component } from 'react'

import './styles/index.scss?global'

export default class MessageBox extends Component {
  static propTypes = {
    showMessageBox: PropTypes.bool,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    element: PropTypes.node,
    onClose: PropTypes.func
  };

  get content () {
    const { title, text, onClose, element } = this.props

    if (!element) {
      return (
        <div className='message-box__content'>
          <span className='message-box__close icon-close-round' onClick={onClose} />
          <h1><b>{title}</b></h1>
          <h2>{text}</h2>
        </div>
      )
    } else {
      return (
        <div className='message-box__content'>
          <span className='message-box__close icon-close-round' onClick={onClose} />
          { element }
        </div>
      )
    }
  }

  render () {
    const { showMessageBox } = this.props

    return (
      <div className={`message-box ${showMessageBox ? 'message-box--active' : ''}`}>
        { this.content }
      </div>
    )
  }
}
