import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'

// Actions
import { loadTaxPaymentReportsListAction, cleanReportsListAction } from 'Reports/actions/reports'

// Components
import ListView from 'shared/components/ListView/ListView'
import {TaxPaymentReportsViewListItem} from 'Reports/views/TaxPaymentReport/TaxPaymentReportsView/containers/Smartphones'

class TodoReports extends Component {
  static propTypes = {
    loadList: PropTypes.func,
    cleanList: PropTypes.func,
    reportsList: PropTypes.arrayOf(PropTypes.object)
  }

  componentDidMount () {
    this.props.loadList([], 1)
  }

  componentWillUnmount () {
    this.props.cleanList()
  }

  render () {
    const { reportsList } = this.props
    return (
      <ListView data={reportsList} template={TaxPaymentReportsViewListItem} />
    )
  }
}

function mapStateToProps (state) {
  return {
    reportsList: state.reports.listData
  }
}

function mapDispatchToProps (dispatch, ownProps) {
  const basePathForLink = ownProps.basePathForLink || ownProps.location.pathname
  return {
    loadList: (currentList, pageToLoad) => { dispatch(loadTaxPaymentReportsListAction(currentList, pageToLoad, { isPaid: false }, basePathForLink)) },
    cleanList: () => { dispatch(cleanReportsListAction()) }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TodoReports)
