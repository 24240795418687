import * as Sentry from '@sentry/browser'
import axios from 'api-client-connector'
import { destroy } from 'redux-form'
import {bind} from 'redux-effects'
import {createAction} from 'redux-actions'
import { routerActions } from 'react-router-redux'
import { get } from 'lodash'
import { camelizeKeys } from 'humps'

import {EVENTS_LOADING, EVENT_SHOW, EVENT_CLEAR, NEW_EVENT_UPDATE_PROPS, NEW_EVENT_CLEAR} from 'Events/shared/constants/Event'
import { EVENT_INFO, EVENT_UPDATE, EVENT_EXTENDED_INFO } from 'Events/shared/constants/Api'
import { STATUS_VOIDED } from 'Events/shared/constants/EventStatus'

import * as eventTypes from 'Events/shared/constants/eventType'

import { fetch } from 'shared/helpers/fetch'
import { showNavbarSpinnerAction, hideNavbarSpinnerAction } from 'shared/actions/navbarSpinner'
import { formatPaymentsFromResponse, getPaymentStatus } from 'Payments/helpers'
import { getCurrencyName } from 'shared/helpers/addNamesForIdProps'
import { getCategory, getPaymentMethod, getVatPercentage, getVatPercentageForRow } from 'Events/shared/helpers/eventDetails'
import { fetchProjectsByTransaction } from 'Projects/actions/projectsList'
import { loadSupplierRequest } from 'Counterparties/Suppliers/actions/suppliers'
import {getCurrentClientCompany} from 'api-client-connector/utils'

const eventLoading = createAction(EVENTS_LOADING)
const eventShow = createAction(EVENT_SHOW)
const eventClear = createAction(EVENT_CLEAR)
const newEventClear = createAction(NEW_EVENT_CLEAR)
const newEventUpdateProps = createAction(NEW_EVENT_UPDATE_PROPS)

export function loadEventAction (id, destination = 'current') {
  return [eventLoading(), showNavbarSpinnerAction(), bind(fetchEvent(id), processEvent.bind(null, destination), processError)]
}

export function clearEventAction () {
  return eventClear()
}

export function clearNewEventAction () {
  return newEventClear()
}

export function clearReceiptFormAction () {
  return [destroy('receipt-form'), destroy('paymentStatus')]
}

export function fetchEvent (id) {
  const eventFetch = fetch(EVENT_INFO, { id: id, filled_objects: ['payments', 'comments', 'files', 'representation'] }, {
    method: 'GET'
  })
  return bind(eventFetch, fetchAdditionalInfo, processError)
}

export function fetchRawEvent (id) {
  return axios.get(EVENT_INFO({ id: id, filled_objects: ['representation'] }).toString())
}

function fetchAdditionalInfo (response) {
  return [fetchProject(response), fetchExtendedInfo(response)]
}

function fetchProject (response) {
  if (!response.value.transactions[0]) return () => response
  const transactionIds = response.value.transactions.map(t => t.id).join(',')
  return bind(fetchProjectsByTransaction(transactionIds), (projectResponse) => {
    const projects = projectResponse.value.projects
    for (let transaction of response.value.transactions) {
      const project = projects.find(p => p.transaction_ids.includes(transaction.id))
      transaction.project_id = project && project.id
      transaction.project = project
    }
    return () => response
  }, processError)
}

function fetchExtendedInfo (response) {
  if (response.value.paper_kind === eventTypes.INVOICE_PAPER_KIND && response.value.type === eventTypes.outType) {
    const extendedInfoFetch = fetch(EVENT_EXTENDED_INFO, { id: response.value.id }, {
      method: 'GET'
    })
    return bind(extendedInfoFetch, (infoResponse) => () => infoResponse, processError)
  } else {
    return () => ({ value: {} })
  }
}

function processEvent (destination, response) {
  return async (dispatch, getState) => {
    console.log('processEvent', response)
    const event = response[0].value
    const extendedInfo = camelizeKeys(response[1].value)
    const companyId = Number(getCurrentClientCompany())
    if (event === undefined || event === null) {
      Sentry.captureException(`Event is null or undefined: ${event}, response: ${response}`)
    }
    const supplierId = event.supplier_id
    const supplier = supplierId ? await loadSupplierRequest(supplierId, companyId) : {}
    const settings = getState().userSettings

    const paymentMethod = getPaymentMethod(event, event.type)
    const category = getCategory(event, event.type)
    const project = event.transactions[0] ? event.transactions[0].project : null
    const currencyName = getCurrencyName(settings, event.currency.id)
    const vatPercentage = getVatPercentage(event)

    const data = {
      title: event.title,
      number: event.user_document_id,
      balance: event.balance,
      id: event.id,
      currency: currencyName,
      currencyId: event.currency.id,
      project: project ? project.name : null,
      projectId: project ? project.id : '',
      projectActive: project ? project.active : null,
      status: Number(event.status),
      amountWithVat: event.total_amount,
      amountWithoutVat: event.total_amount_ex_VAT,
      humanizedAmount: `${currencyName} ${event.total_amount} (${event.total_amount_ex_VAT})`,
      vat: vatPercentage,
      vatAmount: event.total_vat_amount,
      date: event.document_date,
      invoiceDueDate: event.invoice_due_date ? event.invoice_due_date : '',
      paymentDate: event.date_of_payment,
      paymentMethod: paymentMethod ? paymentMethod.name : null,
      paymentMethodId: paymentMethod ? paymentMethod.id : '',
      category: category ? category.name : null,
      categoryId: category ? Number(category.id) : '',
      files: event.files || [],
      eventRows: formatEventRows(event),
      // transactions with ids is needed for projects api
      transactions: event.transactions[0] ? event.transactions.map(t => formatTransaction(event.type, t)) : [],
      eventType: event.type,
      hasComments: !!event.comments.length,
      hasNewComments: hasNewComments(event),
      isPaid: event.is_paid,
      isInvoice: event.paper_kind === eventTypes.INVOICE_PAPER_KIND,
      invoiceType: event.is_credit ? eventTypes.INVOICE_TYPE_CREDIT_INVOICE : eventTypes.INVOICE_TYPE_INVOICE,
      payments: formatPaymentsFromResponse(event),
      paymentStatus: getPaymentStatus(event),
      invoiceNumber: event.invoice_number ? event.invoice_number : '',
      supplierName: supplier && supplier.name,
      supplier,
      extendedInfo,
      pgNumber: extendedInfo.pgNumber,
      bgNumber: extendedInfo.bgNumber,
      OCRnumber: event.invoice_ocr_number,
      ...addRepresentationFields(event),
      ...addSpecialFields(event),
      ...addSingleTrasactionFields(event) // TODO remove after refactor eventRows
    }

    const action = destination === 'new' ? newEventUpdateProps : eventShow
    return dispatch([action(data), hideNavbarSpinnerAction()])
  }
}

function hasNewComments (event) {
  let result = false
  event.comments && event.comments.forEach((comment) => {
    if (!comment.reporter_has_read) {
      result = true
    }
  })
  return result
}

function addRepresentationFields (event) {
  const cause = event.representation ? event.representation.cause : ''
  const guests = event.representation ? event.representation.representation_guests : []
  const representationData = {
    representationId: event.representation && Number(event.representation.id),
    withAlcohol: event.representation ? event.representation.with_alcohol : false,
    internalRepresentation: event.representation ? !event.representation.type : false,
    representationCause: cause,
    representationGuests: guests.map((guest) => ({
      name: guest.name,
      company: guest.company,
      id: Number(guest.id)
    }))
  }
  return {
    ...representationData,
    representationCopy: representationData
  }
}

function addSpecialFields (event) {
  switch (event && event.type) {
    case eventTypes.transfer:
      return transferFieldsFromTransfer(event)
    case eventTypes.inType:
      return transferFieldsFromInPayment(event)
    default:
      return {}
  }
}

function transferFieldsFromTransfer (event) {
  if (event.transactions.length > 0) {
    const row = event.transactions[0]
    return {
      accountFromId: row.credit_account ? row.credit_account.id : null,
      accountFrom: row.credit_account ? row.credit_account.name : null,
      accountToId: row.debit_account ? row.debit_account.id : null,
      accountTo: row.debit_account ? row.debit_account.name : null,
      amount: row.amount
    }
  }
}

function addSingleTrasactionFields (event) {
  if (event.transactions.length === 1) {
    return {
      transactionUUID: event.transactions[0].uuid,
      transactionId: event.transactions[0].id
    }
  } else {
    return {}
  }
}

function transferFieldsFromInPayment (event) {
  if (event.transactions.length && event.transactions.length < 2) {
    return {
      vat: get(event, 'transactions[0].credit_account.vat.percentage') || get(event, 'transactions[0].credit_account.vat_percentage')
    }
  }
}

function formatEventRows (event) {
  const rows = event.transactions
  // TODO because of what here is that condition? Because of this we can't reuse
  // formatting in import events to projects :(
  // Maybe for split amount view?
  if (rows.length >= 2) {
    return rows.map((row) => formatTransaction(event.type, row))
  } else {
    return []
  }
}

export function formatTransaction (type, row) {
  const project = row.project
  const rowVatPercentage = getVatPercentageForRow(row)

  return {
    id: row.id,
    uuid: row.uuid,
    description: row.description || '',
    amount: Number(row.amount),
    amountWithVat: Number(row.amount),
    vatAmount: Number(row.vat_amount),
    vat: (Number(row.vat_amount) !== 0 && Number(rowVatPercentage) === 0)
      ? 'custom'
      : Number(rowVatPercentage),
    project: project ? project.name : null,
    projectId: project ? Number(project.id) : '',
    projectActive: project ? project.active : null,
    ...getEventRowSpecialFields(type, row)
  }
}

function getEventRowSpecialFields (type, row) {
  if (eventTypes.isExpense(type)) {
    return {
      categoryId: row.debit_account ? Number(row.debit_account.id) : null,
      category: row.debit_account ? row.debit_account.name : null,
      includeVat: true
    }
  }
  if (eventTypes.isRevenue(type)) {
    return {
      categoryId: row.credit_account ? Number(row.credit_account.id) : null,
      category: row.credit_account ? row.credit_account.name : null,
      includeVat: false
    }
  }
  return {}
}

function processError (response) {
  console.error('getEvent error', response)
  return hideNavbarSpinnerAction()
}

export function makeVoidEventAction (id) {
  return [
    showNavbarSpinnerAction(),
    bind(updateEvent({ status: STATUS_VOIDED }, id), makeVoidProcess, processError)
  ]
}

function makeVoidProcess () {
  return [hideNavbarSpinnerAction(), routerActions.go(-1)]
}

function updateEvent (data, id) {
  return fetch(EVENT_UPDATE, { id: id }, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json'
    },
    body: data
  })
}
