export const EVENTS_LIST_RELOADING = 'EVENTS_LIST_RELOADING'
export const EVENTS_LIST_UPDATE = 'EVENTS_LIST_UPDATE'
export const EVENTS_LIST_CLEAR = 'EVENTS_LIST_CLEAR'
export const EVENTS_LOADING = 'EVENTS_LOADING'
export const EVENT_SHOW = 'EVENT_SHOW'
export const EVENT_CLEAR = 'EVENT_CLEAR'
export const NEW_EVENT_UPDATE_PROPS = 'NEW_EVENT_UPDATE_PROPS'
export const NEW_EVENT_CLEAR = 'NEW_EVENT_CLEAR'
export const EVENT_MESSAGES_LOAD = 'EVENT_MESSAGES_LOAD'
export const EVENT_MESSAGES_UPDATE = 'EVENT_MESSAGES_UPDATE'
export const REPRESENTATION_ID = 118
