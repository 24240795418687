import { PROJECT_PROPS_UPDATE, PROJECT_PROPS_CLEAR, PROJECT_ADD_EVENT_ROW, PROJECT_ADD_MILEAGE_REPORT, PROJECT_ADD_TIME_REPORT } from '../constants/ActionTypes'
import { calculateTotalAmounts, calculateTotalMinutes, calculateTotalDistance } from '../actions/project'

const initialState = {
  eventRows: [],
  timeReports: [],
  mileageReports: []
}

export default function (state = initialState, action) {
  switch (action.type) {
    case PROJECT_PROPS_UPDATE:
      return {
        ...state,
        ...action.payload
      }
    case PROJECT_PROPS_CLEAR:
      return initialState
    case PROJECT_ADD_EVENT_ROW:
      const newEventRow = action.payload

      // TODO: remove totals recalculation? Because now statistics is calculated on the server
      const totalsToAdd = calculateTotalAmounts([newEventRow])
      const newTotals = {
        totalIncomeWithVat: state.totalIncomeWithVat + totalsToAdd.totalIncomeWithVat,
        totalIncomeWithoutVat: state.totalIncomeWithoutVat + totalsToAdd.totalIncomeWithoutVat,
        totalOutcomeWithVat: state.totalOutcomeWithVat + totalsToAdd.totalOutcomeWithVat,
        totalOutcomeWithoutVat: state.totalOutcomeWithoutVat + totalsToAdd.totalOutcomeWithoutVat,
        totalProfitWithVat: state.totalProfitWithVat + totalsToAdd.totalProfitWithVat,
        totalProfitWithoutVat: state.totalProfitWithoutVat + totalsToAdd.totalProfitWithoutVat
      }

      return {
        ...state,
        ...newTotals,
        eventRows: [
          ...state.eventRows,
          newEventRow
        ]
      }
    case PROJECT_ADD_MILEAGE_REPORT:
      const newMileageReport = action.payload
      return {
        ...state,
        totalDistance: state.totalDistance + calculateTotalDistance([newMileageReport]),
        mileageReports: [
          ...state.mileageReports,
          newMileageReport
        ]
      }
    case PROJECT_ADD_TIME_REPORT:
      const newTimeReport = action.payload
      return {
        ...state,
        totalMinutes: state.totalMinutes + calculateTotalMinutes([newTimeReport]),
        timeReports: [
          ...state.timeReports,
          newTimeReport
        ]
      }
    default:
      return state
  }
}
