import React from 'react'
import PropTypes from 'prop-types'

import IpadNavigation from 'Navigation/IpadNavigation'
import More from 'More/views/MoreView/containers/Smartphones'
import { CreditsafeOnboardingForm } from 'MarketPlace/Creditsafe/views/CreditsafeOnboardingView/containers/Smartphones'
import { withHandlersAndTranslations } from './Common'

const CreditsafeOnboardingView = (props) => {
  const navBarTitle = props.t('title').s
  return (
    <IpadNavigation title={navBarTitle} location={props.location} withoutMaxWidth>
      <More notRightIcon isIpad />
      <CreditsafeOnboardingForm {...props} />
    </IpadNavigation>
  )
}

CreditsafeOnboardingView.propTypes = {
  t: PropTypes.func,
  location: PropTypes.object.isRequired,
  navBarTitle: PropTypes.string
}

export default withHandlersAndTranslations(CreditsafeOnboardingView)
