import PropTypes from 'prop-types'
import React, { Component } from 'react'
import ReactSwipe from 'react-swipe'
import { range } from 'lodash'
import { connect } from 'react-redux'

import getGreyLabelOptions from 'GreyLabel/helpers/greyLabel'

import Icon from 'shared/components/Icon'

// TODO: DRY with ComboChart component
class ComboBox extends Component {
  constructor (props) {
    super(props)
    this.state = {section: 0}
    this.swipe = this.swipe.bind(this)
  }

  static propTypes = {
    t: PropTypes.func
  }

  swipe (i) {
    this.setState({section: i})
  }

  setActiveDelimiterClass (chart, type) {
    return chart === type ? 'combo-chart__delimiter__active' : ''
  }

  get logoComponent () {
    const iconClass = `${getGreyLabelOptions().entryPageIconPrefix || 'shoebox'}-logo`
    const logoClass = `combobox__picture--${iconClass}`

    return (
      <Icon className={`combobox__picture ${logoClass}`} type={iconClass} fileExtension={getGreyLabelOptions().logoExtension} />
    )
  }

  getIconComponent (index, whiteLabel) {
    const iconPrefix = getGreyLabelOptions().entryPageIconPrefix || 'shoebox'
    const entryPageIconPrefix = getGreyLabelOptions().entryPageIconPrefix
    const iconPrefixForLastTabs = ['slipp'].includes(entryPageIconPrefix) ? `${whiteLabel}-` : ''
    const isLastTabs = index === 3 || index === 4

    return (
      <Icon
        className={`combobox__picture ${whiteLabel}`}
        type={isLastTabs ? `${iconPrefixForLastTabs}tab${index}` : `${iconPrefix}-tab${index}`}
      />
    )
  }

  get sections () {
    const { t } = this.props
    const { whiteLabel, entryPageIconPrefix } = getGreyLabelOptions()

    if (whiteLabel === 'werkeys') {
      return [
        <div key='1'>
          { this.logoComponent }
          <div className={`combobox__slogan ${whiteLabel}`}>{'Lifestyle 2.0 is here'}</div>
        </div>,
        <div key='2'>
          <Icon
            className={`combobox__picture ${whiteLabel}`}
            type={`${entryPageIconPrefix}-tab1`}
            fileExtension='jpg'
          />
        </div>,
        <div key='3'>
          <Icon
            className={`combobox__picture ${whiteLabel}`}
            type={`${entryPageIconPrefix}-tab2`}
            fileExtension='jpg'
          />
        </div>,
        <div key='4'>
          <Icon
            className={`combobox__picture ${whiteLabel}`}
            type={`${entryPageIconPrefix}-tab3`}
            fileExtension='jpg'
          />
        </div>,
        <div key='5'>
          <Icon
            className={`combobox__picture ${whiteLabel}`}
            type={`${entryPageIconPrefix}-tab4`}
            fileExtension='jpg'
          />
        </div>,
        <div key='6'>
          <Icon
            className={`combobox__picture ${whiteLabel}`}
            type={`${entryPageIconPrefix}-tab5`}
            fileExtension='jpg'
          />
        </div>
      ]
    }

    let sections = [
      <div key='1'>
        { this.logoComponent }
        <div className={`entry-content-text ${whiteLabel}`}>{t('slides', 'slide0').s}</div>
      </div>,
      <div key='2'>
        {this.getIconComponent(1, whiteLabel)}
        <div className={`entry-content-text ${whiteLabel}`}>{t('slides', 'slide1').s}</div>
      </div>,
      <div key='3'>
        {this.getIconComponent(2, whiteLabel)}
        <div className={`entry-content-text ${whiteLabel}`}>{t('slides', 'slide2').s}</div>
      </div>,
      <div key='4'>
        {this.getIconComponent(3, whiteLabel)}
        <div className={`entry-content-text ${whiteLabel}`}>{t('slides', 'slide3').s}</div>
      </div>,
      <div key='5'>
        {this.getIconComponent(4, whiteLabel)}
        <div className={`entry-content-text ${whiteLabel}`}>{t('slides', 'slide4').s}</div>
      </div>
    ]

    return !['winwin'].includes(whiteLabel) ? sections : [sections[0]]
  }

  render () {
    const {section} = this.state
    const sections = this.sections

    return (
      <div className='combo-chart'>
        <ReactSwipe ref={(c) => { this.slider = c }} swipeOptions={{auto: 2000, continuous: true, callback: this.swipe}} key={sections.length}>
          { sections }
        </ReactSwipe>
        {sections.length > 1 &&
          <div className='combo-chart__delimiter'>
            { range(sections.length).map((i, index) => {
              return (
                <div key={index} className={this.setActiveDelimiterClass(section, i)} onClick={() => { this.slider.slide(i) }} />
              )
            }) }
          </div>
        }
      </div>
    )
  }
}

function mapStateToProps (state) {
  return {
    t: state.i18n.get('app', 'views', 'Entry')
  }
}

export default connect(mapStateToProps)(ComboBox)
