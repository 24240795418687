import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'

// Components
import Navigation from 'Navigation/Navigation'
import TodoTaxReports from 'Todo/views/TodoTaxReportsView/components/TodoTaxReports'

// Actions
import { loadTaxPaymentReportsListAction } from 'Reports/actions/reports'

class TodoReportsView extends Component {
  static propTypes = {
    t: PropTypes.func,
    reportsList: PropTypes.arrayOf(PropTypes.object),
    hasNextPage: PropTypes.bool,
    lastPageLoaded: PropTypes.number,
    loadList: PropTypes.func
  }

  extendList = () => {
    const {hasNextPage, lastPageLoaded, loadList, reportsList} = this.props
    if (hasNextPage) {
      loadList(reportsList, lastPageLoaded + 1)
    }
  }

  render () {
    const { t } = this.props
    return (
      <Navigation title={t('navbar', 'title').s} infinityScrollHandler={this.extendList}>
        <TodoTaxReports {...this.props} />
      </Navigation>
    )
  }
}

function mapStateToProps (state) {
  return {
    t: state.i18n.get('app', 'views', 'Todo', 'TodoTaxReportsView'),
    reportsList: state.reports.listData,
    hasNextPage: state.reports.hasNextPage,
    lastPageLoaded: state.reports.lastPageLoaded
  }
}

function mapDispatchToProps (dispatch, ownProps) {
  const basePathForLink = ownProps.basePathForLink || ownProps.location.pathname
  return {
    loadList: (currentList, pageToLoad) => { dispatch(loadTaxPaymentReportsListAction(currentList, pageToLoad, { isPaid: false }, basePathForLink)) }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TodoReportsView)
