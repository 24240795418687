import {connect} from 'react-redux'

// Views
import EventsImportView from 'Events/EventsImport/views/EventsImportView'

// Actions
import { addEventsAction } from 'Projects/actions/project'
import { routerActions } from 'react-router-redux'

function mapStateToProps (state) {
  return {
    eventRows: state.project.eventRows
  }
}

function mergeProps (stateProps, dispatchProps, ownProps) {
  return Object.assign({}, ownProps, {
    importItemsToForm: (data) => { dispatchProps.dispatch([addEventsAction(data), routerActions.goBack()]) }
  })
}

export default connect(mapStateToProps, null, mergeProps)(EventsImportView)
