import { routerActions } from 'react-router-redux'

export function createBackLink (pathname) {
  const pathComponents = pathname.split('/')
  return pathComponents.slice(0, pathComponents.length - 1).join('/')
}

export function goBackAction (pathname) {
  return routerActions.push(createBackLink(pathname))
}

export function createLink (currentPath, additionalPath) {
  return currentPath + '/' + additionalPath
}

export function createBackLinkFromQuery (query, defaultBackLink = '/') {
  return query.backLink ? query.backLink : defaultBackLink
}

export function getTrimmedLink (pathname, parts = 0, params = '') {
  const linkComponents = pathname.split('/')
  return `${linkComponents.slice(0, linkComponents.length - parts).join('/')}${params}`
}

export function createAdditionalLink (pathname, part = '', params = '') {
  const pathComponents = pathname.split('?')
  return `${pathComponents[0]}/${part}${params}`
}
