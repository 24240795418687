import {bind} from 'redux-effects'
import {createAction} from 'redux-actions'
import { getCurrentClientCompany } from 'api-client-connector/utils'
import { currencyId } from 'mrshoebox-ui-components/src/helpers/countrySpecific'

import { COMPANY_INFO } from '../constants/Api'
import { USER_SETTINGS_ADD } from '../constants/UserSettings'
import { DOCUMENT_CATEGORIES } from 'Documents/constants/Api'

import {fetch2 as fetch} from 'shared/helpers/fetch'

import { mapCompanyObject } from './companySettings'

export const addToUserSettings = createAction(USER_SETTINGS_ADD)

export function addPropsToUserSettings (props) {
  return addToUserSettings(props)
}

export default function getUserSettingsAction () {
  return (dispatch) => {
    const currentCompanyId = getCurrentClientCompany()

    return dispatch(
      bind([
        fetch(COMPANY_INFO + currentCompanyId, { legacy_id: true, with_initial_settings: true, with_accountant: true }),
        fetch(DOCUMENT_CATEGORIES({}))
      ], processResponse, processError)
    )
  }
}

function processResponse (responses) {
  const [ response, documentCategoriesRes ] = responses
  return (dispatch) => {
    const initialSettings = response.value.initial_settings
    const accountGroups = initialSettings.account_groups

    const expenseCategories = accountGroups.expense.map((e) => ({ label: e.name, value: e.id }))
    const revenueCategories = accountGroups.revenue.map((e) => ({ label: e.name, value: e.id, vat: e.vat_percentage }))
    const paymentMethods = accountGroups.payment.map((e) => ({ label: e.name, value: e.id, accountNumber: e.account_number }))
    const documentCategories = documentCategoriesRes.value.map(({id, name}) => ({ label: name, value: id }))

    const subscriptionDetails = initialSettings.subscription_details
    const activeSubscriptions = subscriptionDetails.active_subscriptions.map(mapSubscriptionObject)
    // default not working in Dropdown, we're using currencyId from state.
    let currencies = initialSettings.currencies.map((c) => ({ label: c.code, value: c.id, default: c.default }))
    currencies = [currencies.find(c => c.default), ...currencies.filter(c => !c.default)]
    const settings = {
      accountant: mapAccountantObject(response.value.connected_accountant),
      defaultCurrency: mapCurrencyObject({ id: currencyId }),
      documentCategories,
      expenseCategories,
      revenueCategories,
      representationCategoryIds: accountGroups.expense.filter((i) => i.account_number === 6072).map((i) => i.id),
      expensePaymentMethods: paymentMethods,
      depositPaymentMethods: paymentMethods,
      accountTypes: [{label: 'Bankgiro', value: 1}, {label: 'Plusgiro', value: 2}, { label: 'Bankkonto', value: 3 }],
      withOCR: subscriptionDetails.ocr_enabled,
      withNewDriverLogs: subscriptionDetails.gps_driverlogs || false,
      activeSubscriptions,
      canRequestAccountants: response.value.can_request_accountants,
      whiteLabel: response.value.white_label,
      currencies,
      vatPercents: initialSettings.vat_percents.map(({ value }) => ({ label: `${value}%`, value })),
      loaded: true, // TODO: move to settings loader after all settings load
      company: mapCompanyObject(response)
    }
    return dispatch(addToUserSettings(settings))
  }
}

function mapSubscriptionObject ({ app_type }) {
  return { appType: app_type }
}

function mapAccountantObject (accountant) {
  if (!accountant) {
    return null
  }

  return {
    id: accountant.id,
    legacyId: accountant.legacy_id,
    name: accountant.name,
    email: accountant.email
  }
}

function mapCurrencyObject (currency) {
  return {
    id: currency.id
  }
}

function processError (response) {
  console.log('userSettings error', response)
}
