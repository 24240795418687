import { combineReducers } from 'redux'

import kivra from 'Integrations/Kivra/reducers'
import openPayments from 'Integrations/OpenPayments/reducers'
import integrationsList from 'Integrations/Integrations/reducers'

const integrationsReducer = combineReducers({
  kivra,
  openPayments,
  integrationsList
})

export default function (state, action) {
  return integrationsReducer(state, action)
}
