import { createAction } from 'redux-actions'
import { NEW_EVENT_UPDATE_PROPS } from 'Events/shared/constants/Event'
import * as eventType from '../../shared/constants/eventType'
import { pushEventAction } from 'Events/shared/actions/pushEvent'

const updateNewExpenseProps = createAction(NEW_EVENT_UPDATE_PROPS)

// -------------------  Other View ---------------------------------------

export function loadDataToExpenseViewAction (data) {
  return updateNewExpenseProps(data)
}

// ---------------------  Expense ---------------------------------------

// TODO remove isNewEvent
export function pushExpenseAction (isNewEvent, expense, cameFrom, scrollTopHandler) {
  return pushEventAction({ ...expense, type: eventType.outType }, cameFrom, 'receipt-form', scrollTopHandler)
}
