import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'

// Components
import Events from 'Events/shared/views/EventsListView/EventsListView'

import { clearEventsListAction } from 'Events/shared/actions/events'

class TodoErrorEvents extends Component {
  static propTypes = {
    location: PropTypes.object,
    t: PropTypes.func,
    clearList: PropTypes.func
  }

  constructor (props) {
    super(props)
    this.filtersForEvents = {
      orderBy: 'user_document_id',
      orderType: 'desc',
      status: 3
    }
  }

  componentWillUnmount () {
    this.props.clearList()
  }
  render () {
    const path = this.props.location.pathname
    const { t } = this.props
    return (
      <Events filter={this.filtersForEvents} path={path} t={t} backLink='/todo' />
    )
  }
}

function mapStateToProps (state) {
  return {
    t: state.i18n.get('app', 'views', 'Todo', 'TodoErrorEventsView')
  }
}

function mapDispatchToProps (dispatch) {
  return {
    clearList: () => dispatch(clearEventsListAction())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TodoErrorEvents)
