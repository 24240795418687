import React, { Component } from 'react'
import PropTypes from 'prop-types'

import IpadNavigation from 'Navigation/IpadNavigation'
import More from 'More/views/MoreView/containers/Smartphones'
import { CapcitoFrameView as CapcitoFrame } from 'MarketPlace/Capcito/views/CapcitoFrameView/containers/Smartphones'

export default class CapcitoFrameView extends Component {
  static propTypes = {
    t: PropTypes.func,
    selectedCategory: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.func
    ])
  }

  render () {
    const { t, selectedCategory } = this.props

    return (
      <IpadNavigation
        {...this.props}
        title={t('categories', selectedCategory).s}
        withoutScroll
        withoutMaxWidth
      >
        <More {...this.props} notRightIcon isIpad />
        <CapcitoFrame {...this.props} />
      </IpadNavigation>
    )
  }
}
