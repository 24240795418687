export const OVERVIEW_DATA_LOAD = 'OVERVIEW_DATA_LOAD'
export const SAVE_SETTINGS = 'SAVE_SETTINGS'
export const OVERVIEW_TOTALS_SAVE = 'OVERVIEW_TOTALS_SAVE'
export const OVERVIEW_TOTALS_CLEAR = 'OVERVIEW_TOTALS_CLEAR'

export const OVERVIEW_SETTINGS_INFO_LOAD = 'OVERVIEW_SETTINGS_INFO_LOAD'
export const OVERVIEW_SETTINGS_RESULT_INFO_LOAD = 'OVERVIEW_SETTINGS_RESULT_INFO_LOAD'
export const OVERVIEW_SETTINGS_FORECAST_INFO_LOAD = 'OVERVIEW_SETTINGS_FORECAST_INFO_LOAD'

export const OVERVIEW_LOAN_LOAD = 'OVERVIEW_LOAN_LOAD'
export const CLIENT_OVERVIEW_LOAD = 'CLIENT_OVERVIEW_LOAD'
