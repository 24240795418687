import './Checkbox.scss?global'

import PropTypes from 'prop-types'
import cs from 'classnames'

import React, { Component } from 'react'

export default class Checkbox extends Component {
  static propTypes = {
    value: PropTypes.bool,
    label: PropTypes.string,
    white: PropTypes.bool,
    onChange: PropTypes.func
  };

  constructor (props) {
    super(props)
    this.onCheck = this.onCheck.bind(this)
  }

  componentWillMount () {
    const {value} = this.props
    this.setState({checked: value})
  }
  onCheck () {
    this.setState({checked: !this.state.checked}, function () {
      if (typeof this.props.onChange === 'function') this.props.onChange(this.state.checked)
    })
  }
  componentWillReceiveProps (props) {
    // condition for uncontrolled input
    if ('value' in props) {
      this.setState({checked: props.value})
    }
  }
  render () {
    const className = cs('custom-checkbox', {
      '-checked': this.state.checked,
      '-white': this.props.white
    })
    return (
      <div className={className}>
        <label className='custom-checkbox-label'>
          <label className='custom-checkbox-wrap'>
            <span className={this.state.checked && 'icon-ok'} />
            <input type='checkbox' onChange={this.onCheck} />
          </label>
          { this.props.label && <span className='custom-checkbox-text'> {this.props.label} </span> }
        </label>
      </div>
    )
  }
}
