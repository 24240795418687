import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router'

import FormatNumber from 'shared/components/FormatNumber/FormatNumber'

import './ListItem.scss?global'

export const SalariesListItem = (props) => {
  const { id, title, name, netSalary, paymentDate } = props.data
  return (
    <div className='list-multiline-item salaries-list-item'>
      <Link to={`/salaries/${id}`} className='list-multiline-item'>
        <div className='list-multiline-item__row'>
          <div>
            <h3>{title}</h3>
            <span className='salaries-list-item__salary'>{<FormatNumber value={netSalary} />}</span>
          </div>
          <div>
            <h4>{name}</h4>
            <span>{paymentDate}</span>
          </div>
        </div>
        <h4 className='list-multiline-item__arrow'><span className='icon-arrow-right' /></h4>
      </Link>
    </div>
  )
}

SalariesListItem.propTypes = {
  data: PropTypes.object.isRequired
}
