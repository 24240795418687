import { createAction } from 'redux-act'
import axios from 'api-client-connector'
import { camelizeKeys, decamelizeKeys } from 'humps'
import { omitBy, isNil } from 'lodash'
import React from 'react'
import { routerActions } from 'react-router-redux'

// actions
import { hideNavbarSpinnerAction, showSlowNavbarSpinnerAction } from 'shared/actions/navbarSpinner'
import {
  showElementInMessageBoxAction,
  showMessageBoxWithParamsAction,
  closeMessageBoxAction
} from 'MessageBox/actions/messageBox'

// constants
import { OPEN_PAYMENTS_PAYMENT_VALIDATE, OPEN_PAYMENTS_PAYMENT, OPEN_PAYMENTS_INVALIDATE } from '../constants/api'

// components
import InProgress from 'MarketPlace/shared/components/InProgress'

// helpers
import { openBankIDApp } from 'User/shared/helpers'
import { openLink } from 'Integrations/OpenPayments/helpers'

export const invoicePaymentValidationToStore = createAction('Invoice payment validation to store')
export const bankAccountsToStore = createAction('Bank accounts to store')

export function getValidatedFields (params) {
  return async (dispatch) => {
    dispatch(showSlowNavbarSpinnerAction())
    const response = await validatePayment(decamelizeKeys(params))
    const { initialValues, currentErrors } = processFields(response.data)
    dispatch([
      invoicePaymentValidationToStore({ initialValues, currentErrors }),
      hideNavbarSpinnerAction()
    ])

    return { initialValues, currentErrors }
  }
}

function processFields (fields) {
  const fieldsDetails = camelizeKeys(fields)
    .map(({fieldData, fieldName}) => ({
      name: fieldName,
      value: fieldData.value,
      errors: fieldData.errors
    }))

  const valueReducer = (result, field) => (camelizeKeys({ ...result, [field.name]: field.value }))
  const initialValues = fieldsDetails.reduce(valueReducer, {})
  const errorsReducer = (result, field) => (camelizeKeys({ ...result, [field.name]: field.errors.length ? field.errors : null }))
  const currentErrors = omitBy(fieldsDetails.reduce(errorsReducer, {}), isNil)

  return { initialValues, currentErrors }
}

const validatePayment = (data) => axios.post(OPEN_PAYMENTS_PAYMENT_VALIDATE, data)

export function payInvoice (data) {
  return async (dispatch, getState) => {
    dispatch(showSlowNavbarSpinnerAction())
    try {
      const { data: { redirect_link } } = await payInvoiceRequest(decamelizeKeys(data))
      const closeHandler = () => {
        dispatch([
          closeMessageBoxAction(),
          routerActions.goBack()
        ])
        if (redirect_link.split('///')[0] === 'bankid:') {
          openBankIDApp(redirect_link, getState().appInfo)
        } else {
          openLink(redirect_link)
        }
      }
      const t = getState().i18n.get('app', 'views', 'EventPayView', 'redirectModal')
      dispatch(
        showElementInMessageBoxAction(
          <InProgress
            closeHandler={closeHandler}
            customHeader={t('description').s}
            buttonText={t('ok').s}
            iconName='open-payments-logo'
          />,
          closeHandler
        )
      )
    } catch (e) {
      if (typeof e.response.data.code === 'string') {
        // if error doesn't have its internal code, it will be just same as status code of type number. And we should show common error message.
        const t = getState().i18n.get('app', 'views', 'EventPayView', 'errorCodes')
        dispatch(showMessageBoxWithParamsAction(null, t(e.response.data.code).s))
      } else {
        const t = getState().i18n.get('app', 'shared', 'messages')
        dispatch(showMessageBoxWithParamsAction(null, t('unexpectedError', 'description').s))
        console.log('error', e)
      }
    }
    dispatch(hideNavbarSpinnerAction())
  }
}

const payInvoiceRequest = (data) => axios.post(OPEN_PAYMENTS_PAYMENT, data)

export function refreshBankAccounts (onSuccess) {
  return async (dispatch, getState) => {
    dispatch(showSlowNavbarSpinnerAction())
    try {
      const { data } = await refreshBankAccountsRequest()
      dispatch([
        bankAccountsToStore(camelizeKeys(data)),
        hideNavbarSpinnerAction()
      ])
      onSuccess()
    } catch (e) {
      const t = getState().i18n.get('app', 'shared', 'messages')
      dispatch(showMessageBoxWithParamsAction(null, t('unexpectedError', 'description').s))
      console.log('error', e)
    }
  }
}

const refreshBankAccountsRequest = () => axios.post(OPEN_PAYMENTS_INVALIDATE, null)
