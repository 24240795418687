import { bind } from 'redux-effects'
import { createAction } from 'redux-actions'
import { camelizeKeys } from 'humps'
import { reset } from 'redux-form'
import { sortBy, isEqual, differenceWith } from 'lodash'

import { fetch } from 'shared/helpers/fetch'

import {
  CONVERSATIONS_TO_STORE, CURRENT_CONVERSATION_TO_STORE,
  CLEAR_CURRENT_CONVERSATION
} from 'mrshoebox-ui-components/src/modules/Messaging/actions/const'

import { showNavbarSpinnerAction, hideNavbarSpinnerAction } from 'shared/actions/navbarSpinner'

import { CONVERSATIONS, CONVERSATION, MESSAGES } from '../constants/Api'

export const conversationsToStore = createAction(CONVERSATIONS_TO_STORE)
const currentConversationToStore = createAction(CURRENT_CONVERSATION_TO_STORE)
export const clearCurrentConversation = createAction(CLEAR_CURRENT_CONVERSATION)

export function loadConversationsActions () {
  return [bind(loadConversations(), processConversations.bind(null), processError)]
}

export const loadConversations = () => fetch(CONVERSATIONS, null, { method: 'GET' })
export const processConversations = (response) => conversationsToStore(camelizeKeys(response.value))

export function getConversationAction (conversationId) {
  return [showNavbarSpinnerAction(), bind(getConversationRequest({ conversationId }), processConversation)]
}

function getConversationRequest (params) {
  return fetch(CONVERSATION, { limit: 20, ...params }, { method: 'GET' })
}

function processConversation (response) {
  const conversation = camelizeKeys(response.value)
  // TODO: share this logic with processConversation func.
  return (dispatch, getState) => {
    const { currentConversation: { data: { messages = [] } } } = getState().messages
    const mergedMessages = sortBy([...messages, ...differenceWith(conversation.messages, messages, isEqual)], ['messageId'])
    const payload = {
      data: { ...conversation, messages: mergedMessages },
      hasMoreMessages: conversation.numberOfMessages > mergedMessages.length
    }

    return dispatch([currentConversationToStore(payload), hideNavbarSpinnerAction()])
  }
}

export function loadMoreOldMessagesAction (conversationId, offset) {
  let actions = [showNavbarSpinnerAction()]
  actions = [...actions, bind(getConversationRequest({ conversationId, offset }), processMoreMessages, processError)]

  return actions
}

function processMoreMessages (response) {
  const conversation = camelizeKeys(response.value)
  return (dispatch, getState) => {
    const { currentConversation: { data: { messages = [] }, offset } } = getState().messages
    const mergedMessages = sortBy([...messages, ...differenceWith(conversation.messages, messages, isEqual)], ['messageId'])
    const payload = {
      data: { ...conversation, messages: mergedMessages },
      hasMoreMessages: conversation.numberOfMessages > mergedMessages.length,
      offset: offset + 20
    }

    return dispatch([currentConversationToStore(payload), hideNavbarSpinnerAction()])
  }
}

export function sendMessageAction (conversationId, message) {
  return [
    showNavbarSpinnerAction(),
    bind(sendMessageRequest(conversationId, message), processSuccessSendResponse)
  ]
}

function sendMessageRequest (conversationId, message) {
  return fetch(MESSAGES, { conversationId }, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: { message_body: message }
  })
}

function processSuccessSendResponse (response) {
  return (dispatch) => dispatch([
    hideNavbarSpinnerAction(),
    reset('newMessage'),
    getConversationAction(response.value.conversation_id)
  ])
}

function processError (response) {
  console.log('Message error', response)
  return hideNavbarSpinnerAction()
}
