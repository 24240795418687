import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import IpadNavigation from 'Navigation/IpadNavigation'
import { connectedEventPayView as EventPay } from './Smartphones'

import getLeftComponent from 'Navigation/helpers/linkAccordance'

class EventPayView extends React.Component {
  static propTypes = {
    previousLink: PropTypes.string,
    title: PropTypes.string
  }

  render () {
    const { previousLink, title } = this.props
    const Events = getLeftComponent('event', previousLink)
    return (
      <IpadNavigation {...this.props} title={title}>
        <Events {...this.props} />
        <EventPay {...this.props} />
      </IpadNavigation>
    )
  }
}

const mapStateToProps = (state) => ({
  title: state.i18n.get('app', 'views', 'EventPayView', 'navbar', 'title').s,
  previousLink: state.appInfo.previousLink
})

export default connect(mapStateToProps)(EventPayView)
