import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'

// Components
import Navigation from 'Navigation/Navigation'
import Todo from 'Todo/views/TodoView/components/Todo'

class TodoView extends Component {
  static propTypes = {
    t: PropTypes.func
  }

  render () {
    const {t} = this.props

    return (
      <Navigation home title={t('title').s} backLink={'/'} >
        <Todo {...this.props} />
      </Navigation>
    )
  }
}

function mapStateToProps (state) {
  return {
    t: state.i18n.get('app', 'views', 'Todo', 'TodoView')
  }
}

export default connect(mapStateToProps)(TodoView)
