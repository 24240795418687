import React, { Component } from 'react'
import { connect } from 'react-redux'

import Employees from 'Employees/views/EmployeesListView/containers/Smartphones'
import IpadNavigation from 'Navigation/IpadNavigation'

class EmployeesListView extends Component {
  render () {
    return (
      <IpadNavigation title={this.props.t('navbar', 'title').s} {...this.props} onlyLeftSide>
        <Employees {...this.props} />
      </IpadNavigation>
    )
  }
}

const mapStateToProps = (state) => ({
  t: state.i18n.get('app', 'views', 'Employees', 'EmployeesListView')
})

export default connect(mapStateToProps)(EmployeesListView)
