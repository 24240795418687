import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'

// Actions
import initializeLanguage from 'I18N/actions/i18n'
import loadAllSettingsAction from 'User/Settings/actions/settingsLoader'

import I18NProvider from 'mrshoebox-ui-components/src/i18n/i18nProvider'

export class Initializer extends React.Component {
  static propTypes = {
    children: PropTypes.object,
    initializeLanguage: PropTypes.func,
    isAuthenticated: PropTypes.bool,
    loadAllSettings: PropTypes.func,
    langWasLoaded: PropTypes.bool
  }

  componentDidMount () {
    const { isAuthenticated, loadAllSettings, initializeLanguage } = this.props
    initializeLanguage()
    if (isAuthenticated) {
      loadAllSettings()
    }
  }

  componentDidUpdate (prevProps) {
    if (!prevProps.isAuthenticated && this.props.isAuthenticated) {
      this.props.loadAllSettings()
    }
  }

  render () {
    const { children, langWasLoaded } = this.props
    if (langWasLoaded) {
      return (
        <I18NProvider>
          {children}
        </I18NProvider>
      )
    } else {
      return null
    }
  }
}

function mapStateToProps (state) {
  return {
    isAuthenticated: state.auth.isLoggedIn,
    langWasLoaded: !!state.i18n.data
  }
}

function mapDispatchToProps (dispatch) {
  return {
    initializeLanguage: () => { dispatch(initializeLanguage()) },
    loadAllSettings: () => { dispatch(loadAllSettingsAction()) }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Initializer)
