import PropTypes from 'prop-types'
import React, { Component } from 'react'

// Components
import Navigation from 'Navigation/Navigation'
// Containers
import OverviewViewContainer from 'Overview/Overview/containers/Common'
import Common from './Common'

class VatReports extends Component {
  static propTypes = {
    tViews: PropTypes.func
  }

  render () {
    const { tViews } = this.props
    const t = tViews('VatReports')
    return (
      <Navigation title={t('title').s} className='overview-view overview-rev-costs'>
        <Common {...this.props} />
      </Navigation>
    )
  }
}

export default OverviewViewContainer(VatReports)
