import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import IpadNavigation from 'Navigation/IpadNavigation'
import {ProjectCreateView as ProjectCreate} from 'Projects/views/ProjectView/Create/containers/Smartphones'
import ProjectsView from 'Projects/views/ProjectsView/containers/Smartphones'

class ProjectCreateView extends Component {
  static propTypes = {
    params: PropTypes.object,
    t: PropTypes.func
  }

  render () {
    const { t, params: { projectId } } = this.props

    return (
      <IpadNavigation {...this.props} title={projectId ? t('title', 'update').s : t('title', 'create').s} backLink>
        <ProjectsView {...this.props} />
        <ProjectCreate {...this.props} />
      </IpadNavigation>
    )
  }
}

function mapStateToProps (state) {
  return {
    t: state.i18n.get('app', 'views', 'ProjectView', 'new'),
    project: state.project
  }
}

export default connect(mapStateToProps)(ProjectCreateView)
