import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'

import Icon from 'shared/components/Icon'

import styles from './smartphones.scss'

const Integration = ({ description, link, logoClass, logoName, disabled, activatedText }) => {
  const classname = disabled ? styles.integrationsDisabled : styles.integrations
  return (
    <Link to={link} className={classname}>
      <Icon className={logoClass} type={logoName} />
      <span>{disabled ? `( ${activatedText} )` : ''}</span>
      <h3>{description}</h3>
    </Link>
  )
}

Integration.propTypes = {
  activatedText: PropTypes.string,
  description: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  link: PropTypes.string.isRequired,
  logoClass: PropTypes.string,
  logoName: PropTypes.string.isRequired
}

export default Integration
