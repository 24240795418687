import React, {Component} from 'react'

import IpadNavigation from 'Navigation/IpadNavigation'
import Smartphones from 'Reports/views/AnnualReport/AnnualReportsView/containers/Smartphones'

export default class AnnualReportsView extends Component {
  render () {
    return (
      <IpadNavigation {...this.props} onlyLeftSide>
        <Smartphones {...this.props} />
      </IpadNavigation>
    )
  }
}
