import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'

import { redirectToRoot } from 'User/Auth/actions/auth'

export function redirectLoggedInUser (Component) {
  class AuthComponent extends React.Component {
    static propTypes = {
      dispatch: PropTypes.func.isRequired
    };

    componentWillMount () {
      const { dispatch } = this.props
      dispatch(redirectToRoot())
    }

    componentDidUpdate () {
      const { dispatch } = this.props
      dispatch(redirectToRoot())
    }

    render () {
      // render the component that requires auth (passed to this wrapper)
      return (
        <Component {...this.props} />
      )
    }
  }

  const mapStateToProps = (state) => ({
    isAuthenticated: state.auth.isLoggedIn
  })

  return connect(mapStateToProps)(AuthComponent)
}
