import React from 'react'
import { bind } from 'redux-effects'
import { createAction } from 'redux-actions'
import { routerActions } from 'react-router-redux'
import { change } from 'redux-form'
import { getCurrentClientCompany } from 'api-client-connector/utils'

import { fetch } from 'shared/helpers/fetch'
import { uploadFiles } from 'FileUpload/actions/uploadToFileStorage'

import { DOCUMENT_LOAD_TO_STATE, DOCUMENT_REMOVE_FROM_STATE, DOCUMENT_UPDATE_FIELDS } from '../constants/ActionTypes'
import { showSlowNavbarSpinnerAction, showNavbarSpinnerAction, hideNavbarSpinnerAction } from 'shared/actions/navbarSpinner'
import { DOCUMENT_INFO, DOCUMENT_CREATE } from '../constants/Api'
import { showElementInMessageBoxAction, closeMessageBoxAction } from 'MessageBox/actions/messageBox'
import { ConfirmationMessageBoxContent } from 'MessageBox/components/ConfirmationMessageBox/ConfirmationMessageBox'

import { delay } from 'shared/helpers/utils'

// Bridges between iOS and Android apps
import { closeExtensionWindow } from 'appBridge/notificators/extension'

const loadDocumentToState = createAction(DOCUMENT_LOAD_TO_STATE)
const removeDocumentFromState = createAction(DOCUMENT_REMOVE_FROM_STATE)
const documentFieldsUpdate = createAction(DOCUMENT_UPDATE_FIELDS)

export function loadDocumentAction (companyId, id) {
  return [
    showSlowNavbarSpinnerAction(),
    bind(fetchDocument(companyId, id), mapResponseToReducer, processError)
  ]
}

function fetchDocument (companyId, id) {
  return fetch(DOCUMENT_INFO, { id })
}

function mapResponseToReducer (response) {
  const document = response.value

  return [loadDocumentToState({
    comment: document.comment,
    category: document.category ? document.category.name : '',
    categoryId: document.category ? document.category.id : null,
    title: document.title,
    companyId: document.company_id,
    id: document.id,
    files: document.files
  }), hideNavbarSpinnerAction()]
}

function processError (response) {
  console.error('document-processError', response)
  return routerActions.goBack()
}

export function clearReducerAction () {
  return removeDocumentFromState()
}

export function pushDocumentAction (document, files) {
  const isNewDocument = !document.id

  if (!files.newFiles.length && !(files.currentFiles.length - files.removeFiles.length)) {
    return [change('document', 'filesNotPresent', true)]
  } else {
    return [
      change('document', 'filesNotPresent', false),
      showSlowNavbarSpinnerAction(),
      bind(document.id ? pushDocumentChanges(document) : pushNewDocument(document), uploadFilesToDocument.bind(null, files, isNewDocument))
    ]
  }
}

function pushNewDocument (document) {
  const companyId = getCurrentClientCompany()
  const data = {
    title: document.title,
    category_id: document.categoryId && Number(document.categoryId),
    company_id: Number(companyId),
    was_read: false,
    comment: document.comment
  }

  return fetch(DOCUMENT_CREATE, { }, {
    method: 'POST',
    body: data
  })
}

function pushDocumentChanges (document) {
  const data = {
    title: document.title,
    was_read: false,
    category_id: document.categoryId && Number(document.categoryId)
  }
  if (document.comment) {
    data.comment = document.comment
  }

  return fetch(DOCUMENT_INFO, { id: document.id }, {
    method: 'PATCH',
    body: data
  })
}

function uploadFilesToDocument (files, isNewDocument, response) {
  const documentId = response.value.id
  const afterUploadActions = () => {
    return (dispatch) => {
      closeExtensionWindow()

      let actions = [
        removeDocumentFromState(),
        hideNavbarSpinnerAction()
      ]

      if (isNewDocument) {
        actions.push(redirectToDocument(documentId))
      } else {
        actions.push(routerActions.goBack())
      }

      return dispatch(actions)
    }
  }
  return uploadFiles(files, 'user_document', response.value.user_document_id, documentId, afterUploadActions)
}

function redirectToDocument (documentId) {
  return async (dispatch) => {
    dispatch(routerActions.go(-2))
    await delay(100)

    if (window.location.pathname === '/') {
      dispatch(routerActions.push('/documents'))
    } else {
      dispatch(routerActions.replace('/documents'))
    }
    await delay(100)

    const userId = getCurrentClientCompany()
    dispatch(routerActions.push(`/documents/${userId}/${documentId}`))
  }
}

export function fillFieldsForNewStatementAction () {
  return documentFieldsUpdate({
    category: 'Bankkontoutdrag',
    categoryId: 7
  })
}

function deleteDocumentAction (companyId, id) {
  return [
    showNavbarSpinnerAction(),
    bind(deleteDocument(companyId, id), () => [removeDocumentFromState(), hideNavbarSpinnerAction(), routerActions.goBack()])]
}

function deleteDocument (companyId, id) {
  return fetch(DOCUMENT_INFO, { id }, {
    method: 'DELETE'
  })
}

export function showDeleteDocumentMessageBox (companyId, id) {
  return (dispatch, getState) => {
    const t = getState().i18n.get('app', 'shared', 'messages', 'confirmationMessage')

    const element = (
      <ConfirmationMessageBoxContent
        t={t}
        onYesClick={() => { dispatch([closeMessageBoxAction(), deleteDocumentAction(companyId, id)]) }}
        onNoClick={() => { dispatch(closeMessageBoxAction()) }}
      />
    )

    return dispatch(showElementInMessageBoxAction(element))
  }
}
