import './InputSwitcher.scss?global'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

export default class InputSwitcher extends Component {
  static propTypes = {
    initialValue: PropTypes.bool,
    onChange: PropTypes.func,
    dark: PropTypes.bool,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool
    ])
  };

  constructor (props) {
    super(props)
    this.state = {value: this.props.initialValue || this.props.value || false}
    this.onChange = this.onChange.bind(this)
  }

  onChange () {
    this.setState({value: !this.state.value}, function () {
      if (this.props.onChange) this.props.onChange(this.state.value)
    })
  }

  componentWillReceiveProps (nextProps) {
    this.setState({ value: nextProps.value })
  }

  render () {
    const {dark, ...props} = this.props
    return (
      <div className={`onoffswitch ${dark ? 'onoffswitch--dark' : ''}`}>
        <label className='onoffswitch-label'>
          <input type='checkbox' className='onoffswitch-checkbox' {...props} onChange={this.onChange} checked={this.state.value} />
          <span className='onoffswitch-inner' />
          <span className='onoffswitch-switch' />
        </label>
      </div>
    )
  }
}
