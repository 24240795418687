import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import IpadNavigation from 'Navigation/IpadNavigation'
import {ExpenseView} from 'Events/Withdrawal/views/WithdrawalCreateView/containers/Smartphones'

import getLeftComponent from 'Navigation/helpers/linkAccordance'

export class Expense extends Component {
  static propTypes = {
    previousLink: PropTypes.string,
    t: PropTypes.func,
    expense: PropTypes.object
  }

  render () {
    const {previousLink, t, expense} = this.props
    const Events = getLeftComponent('event', previousLink)
    const navBarTitle = expense.id ? expense.title : t('navbar', 'title').s
    const backLink = expense.id ? `/events/${expense.id}` : '/events'

    return (
      <IpadNavigation {...this.props} title={navBarTitle} backLink={backLink}>
        <Events {...this.props} />
        <ExpenseView {...this.props} />
      </IpadNavigation>
    )
  }
}

function mapStateToProps (state) {
  return {
    expense: state.events.newEvent,
    previousLink: state.appInfo.previousLink,
    t: state.i18n.get('app', 'views', 'EventFormView', 'Withdrawal', 'Create')
  }
}

export default connect(mapStateToProps)(Expense)
