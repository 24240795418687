import PropTypes from 'prop-types'
import React, { Component } from 'react'

// Components
import Navigation from 'Navigation/Navigation'
import Info from 'Overview/RevenueAndCosts/components/Info/Info'
import Dates from 'Overview/Overview/components/Dates'

// Containers
import OverviewViewContainer from 'Overview/Overview/containers/Common'

class RevenueCosts extends Component {
  static propTypes = {
    settings: PropTypes.object,
    results: PropTypes.object,
    tViews: PropTypes.func,
    loadOverviewData: PropTypes.func
  }

  render () {
    const {settings, loadOverviewData, tViews, results: {monthlyResults}} = this.props
    const t = tViews('RevenueAndCosts')
    return (
      <Navigation title={t('title').s} className='overview-view overview-rev-costs'>
        <Dates settings={settings} onSaveClick={loadOverviewData} />
        <Info data={monthlyResults} t={t} type='result' />
      </Navigation>
    )
  }
}

export default OverviewViewContainer(RevenueCosts)
