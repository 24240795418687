import { bind } from 'redux-effects'
import { createAction } from 'redux-actions'
import { camelizeKeys } from 'humps'

import { fetch2 as fetch } from 'shared/helpers/fetch'

import { showNavbarSpinnerAction, hideNavbarSpinnerAction } from 'shared/actions/navbarSpinner'

import { UPDATE_TODO_ITEMS, UPDATE_UNREAD_COLLECTOR_INVOICES_IDS } from '../constants/ActionTypes'
import { NOTIFICATIONS } from 'Notifications/constants/Api'
import { updateNotificationsCountAction } from 'Notifications/actions/notificationBadges'
import { countNotificationsNumber } from 'Notifications/helpers'

export const updateTodoItems = createAction(UPDATE_TODO_ITEMS)
export const updateUnreadCollectorInvoicesIds = createAction(UPDATE_UNREAD_COLLECTOR_INVOICES_IDS)

export function loadTodoItemsAction () {
  return [
    showNavbarSpinnerAction(),
    bind(fetchTodoItems(), mapTodoItemsToReducer)
  ]
}

export function fetchTodoItems () {
  return fetch(NOTIFICATIONS)
}

function mapTodoItemsToReducer (response) {
  const notifications = camelizeKeys(response.value)
  const totalItemsNum = countNotificationsNumber(notifications)
  const todoItems = mapTodoItems(notifications)
  const displayedTodoItems = todoItems.filter(item => item.value > 0)
  return [
    hideNavbarSpinnerAction(),
    updateNotificationsCountAction(totalItemsNum),
    updateTodoItems(displayedTodoItems),
    updateUnreadCollectorInvoicesIds(notifications.collectorInvoicesIds)
  ]
}

export const mapTodoItems = (notifications) => [
  {
    type: 1,
    value: notifications.unreadCommentsNum,
    icon: 'icon-warning-message',
    path: '/todo/unread_events'
  },
  {
    type: 2,
    value: notifications.rejectedSourceDocumentsNum,
    icon: 'icon-transactions-medium',
    path: '/todo/error_events'
  },
  {
    type: 3,
    value: notifications.taxPaymentReportsNum,
    icon: 'icon-case',
    path: '/todo/tax_payment'
  },
  {
    type: 4,
    value: notifications.unreadMessagesNum,
    icon: 'icon-messages-hollow',
    path: '/messages?cameFrom=todo'
  },
  {
    type: 5,
    value: notifications.unreadFiscalReportsNum,
    icon: 'icon-paper-pdf',
    path: '/todo/pdf-reports'
  },
  {
    type: 6,
    value: notifications.collectorOnboardingNum,
    icon: 'icon-ok-thin',
    path: '/todo/onboarding_status'
  },
  {
    type: 7,
    value: notifications.collectorInvoicesNum,
    icon: 'icon-ok-light-round',
    path: '/todo/collector_invoices'
  },
  {
    type: 8,
    value: notifications.unreadAnnualReportsNum,
    icon: 'icon-bills-many',
    path: '/todo/annual-reports'
  },
  {
    type: 9,
    value: notifications.integrations,
    icon: 'icon-integrations',
    path: '/todo/integrations'
  }
]
