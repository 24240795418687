import { CHECKBOX_LIST_INITIALIZE, CHECKBOX_LIST_UPDATE, CHECKBOX_LIST_DESTROY } from 'CheckBoxList/constants/ActionTypes'

const initialState = {
  items: []
}

export default function (state = initialState, action) {
  switch (action.type) {
    case CHECKBOX_LIST_INITIALIZE:
      const { items, shouldBeChecked } = action.payload
      return {
        items: items.map((item) => {
          return {
            ...item,
            isChecked: shouldBeChecked(item)
          }
        })
      }
    case CHECKBOX_LIST_UPDATE:
      const updatedItem = action.payload.item
      const updatedItemIndex = action.payload.index
      const onlyOneCheckedItem = action.payload.hasOnlyOneCheckedItem

      return {
        items: state.items.map((item, index) => {
          if (updatedItemIndex === index) {
            return updatedItem
          } else {
            return onlyOneCheckedItem ? { ...item, isChecked: false } : item
          }
        })
      }
    case CHECKBOX_LIST_DESTROY:
      return initialState
    default:
      return state
  }
}
