import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import { connect } from 'react-redux'

import Input from 'shared/components/Input/Input'

import SignUpBaseView from '../SignUpBaseView'

import { required, min } from 'shared/helpers/formValidations'

const InputWrapper = ({ input, meta, ...props }) => {
  return <Input {...input} {...meta} {...props} />
}
InputWrapper.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object
}

const SignUpWithPasswordView = (props) => {
  const { t } = props

  return (
    <SignUpBaseView>
      <Field
        name='name'
        component={InputWrapper}
        props={{ view: 'white', placeholder: t('fields', 'name').s, autoComplete: 'off' }}
        validate={[ required ]}
      />
      <Field
        name='email'
        component={InputWrapper}
        props={{ view: 'white', placeholder: t('fields', 'email').s, type: 'email', autoComplete: 'off' }}
        validate={[ required ]}
      />
      <Field
        name='telephone'
        component={InputWrapper}
        props={{ view: 'white', placeholder: t('fields', 'telephone').s, type: 'tel', autoComplete: 'off' }}
      />
      <Field
        name='password'
        component={InputWrapper}
        props={{ view: 'white', placeholder: t('fields', 'password').s, type: 'password', autoComplete: 'off' }}
        validate={[ required, min(6) ]}
      />
    </SignUpBaseView>
  )
}
SignUpWithPasswordView.propTypes = {
  t: PropTypes.func
}

function mapStateToProps (state) {
  return {
    t: state.i18n.get('app', 'views', 'SignUpView')
  }
}

export default connect(mapStateToProps)(SignUpWithPasswordView)
