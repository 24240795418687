import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { routerActions } from 'react-router-redux'

// Actions
import { invoiceGetAction } from '../../../actions/invoiceCreate'
import {
  directPaymentAction, invoicePdfPreviewAction,
  markAsPaidAction, invoiceCopyAction
} from '../../../actions/invoicePreview'
import { getApplications } from 'MarketPlace/Collector/actions'
import { markCollectorInvoiceStatusAsReadAction } from 'Notifications/actions/collectorStatuses'

export default function InvoicePreviewContainer (Component) {
  class InvoicePreview extends React.Component {
    static propTypes = {
      loadInvoice: PropTypes.func,
      params: PropTypes.object,
      getCollectorApplications: PropTypes.func,
      markCollectorInvoiceStatusAsRead: PropTypes.func,
      invoice: PropTypes.object,
      unreadCollectorInvoicesIds: PropTypes.array
    }

    componentDidMount () {
      const {
        loadInvoice, getCollectorApplications, params: { id },
        unreadCollectorInvoicesIds, markCollectorInvoiceStatusAsRead
      } = this.props
      loadInvoice(id)
      getCollectorApplications()
      if (unreadCollectorInvoicesIds.includes(id)) markCollectorInvoiceStatusAsRead(id)
    }

    componentWillReceiveProps (props) {
      const newInvoiceId = props.params.id
      const { params: { id }, loadInvoice, getCollectorApplications } = this.props
      if (newInvoiceId !== id) {
        loadInvoice(newInvoiceId)
        getCollectorApplications()
      }
    }

    componentDidUpdate (prevProps) {
      const { params: { id }, markCollectorInvoiceStatusAsRead, unreadCollectorInvoicesIds } = this.props
      if (id !== prevProps.params.id && unreadCollectorInvoicesIds.includes(id)) {
        markCollectorInvoiceStatusAsRead(id)
      }
    }

    render () {
      return (
        <Component {...this.props} />
      )
    }
  }

  function mapStateToProps (state) {
    return {
      invoice: state.invoices.customerInvoices.invoice,
      t: state.i18n.get('app', 'views', 'Invoices', 'InvoicePreviewView'),
      previousLink: state.appInfo.previousLink,
      unreadCollectorInvoicesIds: state.todo.unreadCollectorInvoicesIds
    }
  }

  function mapDispatchToProps (dispatch, ownProps) {
    return {
      loadInvoice: (id) => { dispatch(invoiceGetAction(id)) },
      redirectToEditView: () => { dispatch(routerActions.push(`/invoices/edit/${ownProps.params.id}`)) },
      redirectToCreditNote: () => { dispatch(routerActions.push(`/invoices/new?creditNote=${ownProps.params.id}`)) },
      showPreview: () => { dispatch(invoicePdfPreviewAction()) },
      copyInvoice: () => { dispatch(invoiceCopyAction()) },
      markAsPaid: () => { dispatch(markAsPaidAction()) },
      getCollectorApplications: () => { dispatch(getApplications()) },
      redirectToDirectPayment: () => dispatch(directPaymentAction()),
      markCollectorInvoiceStatusAsRead: (id) => dispatch(markCollectorInvoiceStatusAsReadAction(id)),
      redirectToSendInvoice: (args) =>
        dispatch(routerActions.push(`/invoices/${ownProps.params.id}/send_invoice${args.isReminder ? '?reminder=true' : ''}`))
    }
  }

  return connect(mapStateToProps, mapDispatchToProps)(InvoicePreview)
}
