import React, { Component } from 'react'
import PropTypes from 'prop-types'

import CollectorLayout from 'MarketPlace/Collector/components/CollectorLayout'
import OnboardingStatus from 'MarketPlace/Collector/views/OnboardingStatus'
import More from 'More/views/MoreView/containers/Smartphones'
import IpadNavigation from 'Navigation/IpadNavigation'

import withRequests from './withRequestsHOC'

class TodoOnboardingStatus extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    status: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
    getCollectorApplications: PropTypes.func.isRequired,
    markStatusAsRead: PropTypes.func.isRequired
  }

  componentDidMount () {
    this.props.getCollectorApplications()
    this.props.markStatusAsRead()
  }

  render () {
    const { location, status, t } = this.props
    return (
      <IpadNavigation title={t('navbar', 'title').s} location={location} withoutMaxWidth>
        <More notRightIcon isIpad />
        <CollectorLayout>
          <OnboardingStatus status={status} />
        </CollectorLayout>
      </IpadNavigation>
    )
  }
}

export default withRequests(TodoOnboardingStatus)
