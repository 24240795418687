import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getFormValues, blur, FieldArray, arrayPush, touch, registerField } from 'redux-form'
import './SplitAmountBlock.scss?global'

// Components
import Button from 'shared/components/Button/Button'
import SplitAmountRow from '../SplitAmountRow'

class SplitAmountBlock extends Component {
  static propTypes = {
    addEventRow: PropTypes.func,
    categories: PropTypes.arrayOf(PropTypes.object),
    changeOnBlur: PropTypes.func,
    changeFormValue: PropTypes.func,
    formValues: PropTypes.object,
    makeFieldTouched: PropTypes.func,
    onCategoryChange: PropTypes.func,
    readOnlyVatDropDown: PropTypes.bool,
    registerEventRowsField: PropTypes.func,
    t: PropTypes.func,
    vatPercents: PropTypes.arrayOf(PropTypes.object)
  }

  constructor (props) {
    super(props)
    this.addNewRow = this.addNewRow.bind(this)
  }

  componentDidMount () {
    // To prevent split amount rows not being rendered on first render.
    this.props.registerEventRowsField()
  }

  addNewRow () {
    const { vatPercents, formValues: { amountWithVat }, changeOnBlur, addEventRow, makeFieldTouched } = this.props
    if (!Number(amountWithVat)) {
      changeOnBlur('amountWithVat', '')
      makeFieldTouched('amountWithVat')
    } else {
      addEventRow({ vat: vatPercents[0].value, amountWithVat: '0', vatAmount: '0' })
    }
  }

  get viewWithRows () {
    const {
      t, formValues: { amountWithVat, eventRows = [] }, categories,
      readOnlyVatDropDown, onCategoryChange, formValues, changeFormValue
    } = this.props
    const remainingAmount = Number(amountWithVat) - eventRows.reduce((current, r) => current + Number(r.amountWithVat), 0)
    return (
      <div className='receipt-split-amount'>
        <FieldArray
          name='eventRows'
          component={SplitAmountRows}
          categories={categories}
          readOnlyVatDropDown={readOnlyVatDropDown}
          onCategoryChange={onCategoryChange}
          formValues={formValues}
          changeFormValue={changeFormValue}
        />

        {
          remainingAmount
            ? (
              <div className='receipt-split-amount__remaining-amount'>
                <h3>{ t('leftToDistribute').replace('%amount%', remainingAmount) }</h3>
              </div>
            )
            : null
        }

        <div className='receipt-split-amount__add-row-button'>
          <Button view='transparent-black' id='add-split-amount-row' onClick={this.addNewRow}>{t('buttons', 'addNewRow').s}</Button>
        </div>

        <div className='hr' />
      </div>
    )
  }

  get viewWithButton () {
    const { t } = this.props
    return (
      <div className='f-column-top receipt__amount-buttons'>
        <div className='form'>
          <div className='form__item'>
            <Button view='transparent-black' id='add-split-amount-row' onClick={this.addNewRow}>{t('buttons', 'splitAmount').s}</Button>
          </div>
        </div>
        <div className='hr' />
      </div>
    )
  }

  render () {
    const { formValues: { eventRows } } = this.props
    if (eventRows && !eventRows.length) {
      return this.viewWithButton
    } else {
      return this.viewWithRows
    }
  }
}

const SplitAmountRows = (props) =>
  <React.Fragment>
    {props.fields.map((rowFormName, index) =>
      <SplitAmountRow
        {...props}
        formValues={props.formValues.eventRows[index] || {}}
        categories={props.categories}
        formSectionName={rowFormName}
        key={index}
        readOnlyVatDropDown={props.readOnlyVatDropDown}
        showDeleteButton={index > 0}
        onCategoryChange={props.onCategoryChange}
        onDeleteClick={() => props.fields.remove(index)}
        changeFormValue={props.changeFormValue}
      />
    )}
  </React.Fragment>

SplitAmountRows.propTypes = {
  eventRows: PropTypes.array,
  categories: PropTypes.arrayOf(PropTypes.object),
  changeFormValue: PropTypes.func,
  fields: PropTypes.array,
  formValues: PropTypes.object,
  readOnlyVatDropDown: PropTypes.bool,
  onCategoryChange: PropTypes.func
}

const formName = 'receipt-form'
function mapStateToProps (state) {
  return {
    vatPercents: state.userSettings && state.userSettings.vatPercents,
    t: state.i18n.get('app', 'views', 'ReceiptSplitAmountView'),
    formValues: getFormValues(formName)(state) || {}
  }
}

const mapDispatchToProps = (dispatch) => ({
  changeOnBlur: (fieldName, value) => dispatch(blur(formName, fieldName, value)),
  makeFieldTouched: (fieldName) => dispatch(touch(formName, fieldName)),
  addEventRow: (value) => dispatch(arrayPush(formName, 'eventRows', value)),
  registerEventRowsField: () => dispatch(registerField(formName, 'eventRows', 'FieldArray'))
})

export default connect(mapStateToProps, mapDispatchToProps)(SplitAmountBlock)
