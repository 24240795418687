import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'

import styles from './styles.scss'

import Button from 'shared/components/Button/Button'

import ZignsecInteractor from 'User/shared/interactors/zignsecInteractor'

import { connect } from 'react-redux'

class BankIDWaiting extends PureComponent {
  static propTypes = {
    location: PropTypes.objct,
    t: PropTypes.func
  }

  render () {
    const { location: { query: { startURL } }, t } = this.props

    return (
      <div className={styles.container}>
        <div className={styles.text}>
          <p>
            {t('firstParagraph').s} <a className={styles.link} href={startURL}>{t('link').s}</a>
          </p>
          <p>{t('secondParagraph').s}</p>
        </div>
        <div className={styles.button}>
          <Button
            onClick={() => ZignsecInteractor.disconnect()}
            view='dark'
            link='/entry'
          >
            {t('buttons', 'cancel').s}
          </Button>
        </div>
      </div>
    )
  }
}

function mapStateToProps (state) {
  return {
    t: state.i18n.get('app', 'views', 'BankIDWaiting')
  }
}

export default connect(mapStateToProps)(withRouter(BankIDWaiting))
