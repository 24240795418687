import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { routerActions } from 'react-router-redux'

// Actions
import { showNotPermittedMessageAction } from 'Policy/actions'
import checkPermission from 'Policy/helpers'

export default function PolicyHelpers (Component) {
  class ComponentWithPolicy extends React.Component {
    static propTypes = {
      subscriptions: PropTypes.array,
      dispatch: PropTypes.func
    }

    constructor (props, context) {
      super(props, context)
      this._getFeaturePermissionProps = this._getFeaturePermissionProps.bind(this)
      this._checkPermission = this._checkPermission.bind(this)
      this._redirectIfNotPermitted = this._redirectIfNotPermitted.bind(this)
    }

    _getFeaturePermissionProps (featureName, componentClassName = '', disableClassName = 'disable-feature') {
      const { subscriptions, dispatch } = this.props
      const status = checkPermission(featureName, subscriptions)
      if (!status) {
        return {
          onClick: () => { dispatch(showNotPermittedMessageAction()) },
          className: `${componentClassName} ${disableClassName}`,
          to: null
        }
      } else {
        return {
          className: componentClassName
        }
      }
    }

    _checkPermission (featureName) {
      const { subscriptions } = this.props
      return checkPermission(featureName, subscriptions)
    }

    _redirectIfNotPermitted (featureName, path = '/') {
      if (!this._checkPermission(featureName)) {
        this.props.dispatch(routerActions.replace(path))
      }
    }

    render () {
      return (
        <Component
          {...this.props}
          getFeaturePermissionProps={this._getFeaturePermissionProps}
          checkPermission={this._checkPermission}
          redirectIfNotPermitted={this._redirectIfNotPermitted}
        />
      )
    }
  }

  function mapStateToProps (state) {
    return {
      subscriptions: state.userSettings.activeSubscriptions
    }
  }

  return connect(mapStateToProps)(ComponentWithPolicy)
}
