import React from 'react'
import PropTypes from 'prop-types'

import Checkbox from 'shared/components/Checkbox/Checkbox'

const FormCheckbox = ({ input, meta, ...props }) =>
  <Checkbox {...input} {...meta} {...props} />

FormCheckbox.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object
}

export default FormCheckbox
