import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'

// Views
import ReceiptBaseView from 'Events/shared/views/ReceiptBaseView/ReceiptBaseView'

// Actions
import { pushExpenseAction } from 'Events/Expense/actions/newExpense'

function ExpenseView (props) {
  const { location: { query: { type = 'receipt', paymentEditMode } } } = props

  return (
    <ReceiptBaseView
      {...props}
      pushAction={pushExpenseAction}
      type='expense'
      invoiceMode={type === 'invoice'}
      paymentEditMode={paymentEditMode === 'true'}
    />
  )
}

ExpenseView.propTypes = {
  location: PropTypes.object
}

function mapStateToProps (state, ownProps) {
  const { location: { query: { type = 'receipt' } } } = ownProps
  const navbarTitle = type === 'receipt'
    ? state.i18n.get('app', 'views', 'ExpenseView', 'navbar', 'titleReceipt').s
    : state.i18n.get('app', 'views', 'ExpenseView', 'navbar', 'titleInvoice').s

  return {
    categories: state.userSettings.expenseCategories,
    paymentMethods: state.userSettings.expensePaymentMethods,
    defaultNavbarTitle: navbarTitle
  }
}

export default connect(mapStateToProps)(ExpenseView)
