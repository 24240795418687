import { bind } from 'redux-effects'
import { fetch2 as fetch } from 'shared/helpers/fetch'
import { getCurrentClientCompany } from 'api-client-connector/utils'

import { updateSuggestionList } from 'shared/components/SuggestionInput/Async/reducer'

import { EVENTS_AUTOCOMPLETE } from 'Events/shared/constants/Api'

export function loadEventTitleSuggestions ({ value }) {
  return (dispatch) => {
    const companyId = getCurrentClientCompany()

    return dispatch(bind(getEventTitleSuggestions(value, companyId), updateSuggestions))
  }
}

function getEventTitleSuggestions (query, companyId) {
  return fetch(EVENTS_AUTOCOMPLETE, { company_id: companyId, by_field: 'title', search_by: query })
}

function updateSuggestions (response) {
  return updateSuggestionList({
    key: 'eventTitle',
    data: response.value.map((s) => ({
      title: s
    }))
  })
}
