import React, { Component } from 'react'
import { connect } from 'react-redux'
import { routerActions } from 'react-router-redux'
import PropTypes from 'prop-types'

import Collaboration from 'MarketPlace/shared/views/CollaborationView'
import Details from 'MarketPlace/Capcito/views/CapcitoCollaborationView/components/CapcitoDetails'
import Navigation from 'Navigation/Navigation'

class CapcitoCollaborationView extends Component {
  static propTypes = {
    t: PropTypes.func,
    cancelHandler: PropTypes.func.isRequired,
    redirectToOnboarding: PropTypes.func.isRequired
  }

  get collaborationConfig () {
    const { t } = this.props
    return {
      message: t('capcitoCollaboration', 'message').s,
      partnerOffer: t('capcitoCollaboration', 'offers').s,
      iconName: 'capcito'
    }
  }

  showDetailsModal = () => {
    this.detailsModal.open()
  }

  render () {
    return (
      <div className='capcito-collaboration'>
        <Collaboration
          {...this.collaborationConfig}
          {...this.props}
          startHandler={this.props.redirectToOnboarding}
          partnerBlockClass='capcito-collaboration-block'
          readMoreHandler={this.showDetailsModal}
        />
        <Details provideController={(node) => { this.detailsModal = node }} />
      </div>
    )
  }
}

const CapcitoCollaborationWithNavigation = (props) => {
  const navBarTitle = props.t('capcitoCollaboration', 'title').s

  return (
    <div className='capcito-collaboration-wrapper'>
      <Navigation hideFooter onClickBack={props.cancelHandler} title={navBarTitle} notBottomSpace >
        <CapcitoCollaborationView {...props} />
      </Navigation>
    </div>
  )
}

CapcitoCollaborationWithNavigation.propTypes = {
  cancelHandler: PropTypes.func.isRequired
}

const mapStateToProps = (state) => {
  return {
    t: state.i18n.get('app', 'views', 'MarketPlace')
  }
}

function mapDispatchToProps (dispatch) {
  return {
    cancelHandler: () => dispatch(routerActions.goBack()),
    redirectToOnboarding: () => dispatch(routerActions.push('/marketplace/capcito/onboarding'))
  }
}

export const CapcitoCollaboration = connect(mapStateToProps, mapDispatchToProps)(CapcitoCollaborationView)
export default connect(mapStateToProps, mapDispatchToProps)(CapcitoCollaborationWithNavigation)
