import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import cs from 'classnames'

import { getCurrentClientCompany } from 'api-client-connector/utils'

import {
  saveRule,
  loadRules,
  deleteRuleWithConfirmation
} from '../../actions/rules'

import ApproveRuleForm from './ApproveRuleForm/'

import styles from './styles.scss'

class ApproveRules extends Component {
  static propTypes = {
    loadList: PropTypes.func,
    saveRule: PropTypes.func,
    deleteRule: PropTypes.func,
    rules: PropTypes.array
  };

  state = {
    formRuleId: null,
    createView: true
  }

  componentDidMount () {
    this.props.loadList()
  }

  setRuleEdit = (rule) => {
    // TODO Change in redux-form values to selected rule
    this.setState({ formRuleId: rule.id })
  }

  goToForm = () => {
    this.setState({ createView: true })
  }

  goToList = () => {
    this.setState({ formRuleId: null, createView: false })
    this.props.loadList()
  }

  saveRule = (values) => {
    this.props.saveRule(values)
    this.goToList()
  }

  renderRule = (rule) => {
    // TODO: rule.name
    return <div className={styles.ruleRow} key={rule.id}>
      <div className={styles.rowLeft}>
        <div className={styles.backArrow} onClick={() => this.goToForm()}>
          <span className='icon-arrow-left' />
        </div>
        <div className={styles.ruleName} onClick={() => this.setRuleEdit(rule)}>
          {rule.name}
        </div>
      </div>
      <div className={styles.rowRight}>
        {rule.isActive && <div><span className={cs('icon-auto-settings', styles.editIcon)} /></div>}
        <div onClick={() => this.setRuleEdit(rule)}>
          <span className={cs('icon-sliders', styles.editIcon)} />
        </div>
        <div onClick={() => this.props.deleteRule(rule.id)}>
          <span className={cs('icon-trash', styles.removeIcon)} />
        </div>
      </div>
    </div>
  }

  render () {
    const { rules } = this.props
    const { formRuleId, createView } = this.state
    const editableRule = formRuleId && rules.find(r => r.id === formRuleId)

    if (editableRule) return <ApproveRuleForm rule={editableRule} onSave={this.saveRule} goToList={this.goToList} />
    if (rules.length === 0 || createView) return <ApproveRuleForm onSave={this.saveRule} goToList={this.goToList} />

    return (
      <div className={styles.rulesList}>
        { rules.map(this.renderRule) }
      </div>
    )
  }
}

function mapStateToProps (state) {
  return {
    t: state.i18n.get('app', 'views', 'Invoices', 'InvoicesView', 'ApproveRulesView'),
    rules: state.invoices.supplierInvoices.invoiceApproveRules
  }
}

function mapDispatchToProps (dispatch) {
  const companyId = getCurrentClientCompany()
  return {
    loadList: () => { dispatch(loadRules(companyId)) },
    saveRule: (rule) => { dispatch(saveRule(rule, companyId)) },
    deleteRule: (id) => { dispatch(deleteRuleWithConfirmation(id, companyId)) }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ApproveRules)
