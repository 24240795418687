import './styles/index.scss?global'

import {isBoolean, omit} from 'lodash'

import PropTypes from 'prop-types'

import React, { Component } from 'react'

import getGreyLabelOptions from 'GreyLabel/helpers/greyLabel'

export default class Dropdown extends Component {
  static propTypes = {
    emptyOption: PropTypes.string,
    options: PropTypes.array.isRequired,
    label: PropTypes.string,
    hint: PropTypes.string,
    onBlur: PropTypes.func,
    layout: PropTypes.string,
    onChange: PropTypes.func,
    big: PropTypes.bool,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    error: PropTypes.bool,
    disabled: PropTypes.bool,
    touched: PropTypes.bool,
    className: PropTypes.string,
    white: PropTypes.bool
  }

  static defaultProps = {
    options: []
  }

  constructor (props) {
    super(props)
    this.onChangeHandler = this.onChangeHandler.bind(this)
  }

  onChangeHandler (e) {
    if (typeof this.props.onChange === 'function') {
      this.props.onChange(e)
    }
  }

  get shouldShowHint () {
    const { hint, value } = this.props
    if (value && hint) {
      return true
    } else {
      return false
    }
  }

  isError () {
    const {error, touched} = this.props
    if ((isBoolean(touched) && touched && error) || (!isBoolean(touched) && error)) {
      return true
    }
  }

  render () {
    const {options, emptyOption, label, hint, layout, white, big, disabled, className = '', ...props} = this.props

    // selected work for vat_type dropdown in invoices
    var optionsHTML = options.map(function (option, key) {
      return <option key={key} value={option.value} selected={option.selected}>{option.label}</option>
    })

    if (emptyOption) {
      optionsHTML.unshift(<option key='empty_option' value={''}>{emptyOption}</option>)
    }
    const otherOptions = omit(props, [
      'initialValue', 'autofilled', 'autofill', 'onUpdate', 'valid',
      'invalid', 'dirty', 'pristine', 'active', 'touched', 'visited', 'error',
      'asyncValidating', 'submitFailed', 'dispatch', 'submitting'
    ])

    const defaultOption = options.find(o => o.default)
    if (defaultOption !== undefined) {
      otherOptions.defaultValue = defaultOption.value
    }

    const select = (
      <select {...otherOptions} disabled={disabled} onChange={this.onChangeHandler}>
        {optionsHTML}
      </select>
    )

    const { whiteLabel } = getGreyLabelOptions()
    const whiteLabelSelectClass = whiteLabel ? `dropdown__select--${whiteLabel}` : ''
    if (layout === 'select') {
      return select
    } else {
      return (
        <div className={`dropdown ${className} ${white ? 'dropdown--white' : ''} ${big ? 'dropdown--big' : ''} ${this.isError() ? 'dropdown--error' : ''} ${disabled ? 'dropdown--disabled' : ''}`}>
          {label && <span className='label'>{label}</span>}
          <div className={`dropdown__select ${whiteLabelSelectClass}`}>
            <div className='dropdown__select__arrow' />
            {select}
          </div>
          { this.shouldShowHint && <span className='hint'>{hint}</span> }
        </div>
      )
    }
  }
}
