import './RadioButton.scss?global'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

export default class RadioButton extends Component {
  static propTypes = {
    dark: PropTypes.bool,
    radioValue: PropTypes.string.isRequired, // value for radio input
    value: PropTypes.string, // value from redux-form field, not radio input
    onChange: PropTypes.func
  };

  constructor (props) {
    super(props)
    this._onLabelClick = this._onLabelClick.bind(this)
  }

  _onLabelClick () {
    const { onChange, radioValue } = this.props
    onChange(radioValue)
  }

  render () {
    const { dark, radioValue, value, onChange } = this.props
    const checked = value === radioValue
    return (
      <label className={`w-radio ${dark ? 'w-radio--dark' : ''} ${checked ? 'w-radio--checked' : ''}`} onClick={this._onLabelClick}>
        <input
          type='radio'
          ref='radio'
          checked={checked}
          onChange={onChange}
          value={radioValue}
        />
      </label>
    )
  }
}
