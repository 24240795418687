import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import KivraModalLayout from 'Invoices/CustomerInvoices/views/InvoiceSendView/components/KivraModalLayout/KivraModalLayout'
import KivraButton from 'Integrations/Kivra/components/KivraButton'

const KivraRegister = ({ t, onSubmit, onCancel }) =>
  <KivraModalLayout
    content={
      <React.Fragment>
        <h2>{t('texts', 'header').s}</h2>
        <span>{t('texts', 'details').s}</span>
      </React.Fragment>
    }
    buttons={
      <React.Fragment>
        <KivraButton saveButton green onClick={onSubmit}>
          {t('buttons', 'registerCard').s}
        </KivraButton>
        <KivraButton white onClick={onCancel}>{t('buttons', 'cancel').s}</KivraButton>
      </React.Fragment>
    }
  />

KivraRegister.propTypes = {
  t: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  t: state.i18n.get('app', 'views', 'Invoices', 'InvoiceSendView', 'kivraModals', 'register')
})

export default connect(mapStateToProps)(KivraRegister)
