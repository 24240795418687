import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import IpadNavigation from 'Navigation/IpadNavigation'
import {ArticleView} from 'Articles/views/ArticleView/containers/Smartphones'

import getLeftComponent from 'Navigation/helpers/linkAccordance'

export class Article extends Component {
  static propTypes = {
    article: PropTypes.object,
    t: PropTypes.func,
    previousLink: PropTypes.string,
    location: PropTypes.object
  }

  render () {
    const {article, t, previousLink, location: { query: { cameFrom } }} = this.props
    const actions = article.id ? [{ icon: 'icon-trash', callback: () => { this.refs.article.getWrappedInstance().modal.open() } }] : null
    const RightSideItems = getLeftComponent('article', previousLink)

    return (
      <IpadNavigation {...this.props} title={article.name || t('navbar', 'title').s} actions={actions} notBack={!cameFrom}>
        <RightSideItems {...this.props} />
        <ArticleView {...this.props} ref='article' />
      </IpadNavigation>
    )
  }
}

function mapStateToProps (state) {
  return {
    previousLink: state.appInfo.previousLink,
    article: state.articles.article,
    t: state.i18n.get('app', 'views', 'Articles', 'ArticleView')
  }
}

export default connect(mapStateToProps)(Article)
