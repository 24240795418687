import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'

import Button from 'mrshoebox-ui-components/src/components/Button'
import TextareaAutosize from 'react-textarea-autosize'
import './index.scss?global'

class TextareaAutosizeWrapper extends Component {
  static propTypes = {
    placeholder: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
    input: PropTypes.object
  }

  focusTextarea = () => {
    this.textarea.focus()
  }

  textAreaRef = (textarea) => { this.textarea = textarea }

  render () {
    const {placeholder, input: { onChange, value }} = this.props

    return (
      <div onClick={this.focusTextarea}>
        <TextareaAutosize
          placeholder={placeholder}
          onChange={(e) => onChange(e)}
          value={value}
          inputRef={this.textAreaRef} />
      </div>
    )
  }
}

class MessageForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func,
    name: PropTypes.string,
    t: PropTypes.func
  }

  render () {
    const { handleSubmit, name, t } = this.props
    return (
      <form onSubmit={handleSubmit} className='message-form'>
        <Field
          name={name}
          placeholder={t('messagePlaceholder').s}
          component={TextareaAutosizeWrapper}
          type='text'
          maxRows={3}
        />
        <Button type='submit'>
          {t('send').s}
        </Button >
      </form>
    )
  }
}

function mapStateToProps (state) {
  return {
    t: state.i18n.get('app', 'views', 'Messages', 'messageForm')
  }
}

const reduxFormHOC = reduxForm({
  form: 'newMessage'
})

export default connect(mapStateToProps)(reduxFormHOC(MessageForm))
