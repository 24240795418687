import { createAction, createReducer } from 'redux-act'

export const updateSuggestionList = createAction('Update suggestions list')

const reducer = createReducer({
  [updateSuggestionList]: (state, payload) => ({
    ...state,
    [payload.key]: payload.data
  })
}, {})

export default reducer
