import PropTypes from 'prop-types'
import React, { Component } from 'react'

// Components
import Navigation from 'Navigation/Navigation'
import Event from 'Events/Event/views/EventView/components/Event/Event'

// Containers
import EventContainer from 'Events/Event/views/EventView/containers/Common'

// Helpers
import getMultipleActions from 'Events/Event/helpers/getMultipleActions'

class EventWithNavigation extends Component {
  static propTypes = {
    event: PropTypes.object,
    location: PropTypes.object,
    t: PropTypes.func
  }

  render () {
    const {event, t, location} = this.props

    if (!event) {
      return (<Navigation title={t('navbar', 'title').s} hideFooter />)
    } else {
      const multipleActions = getMultipleActions(this.props, () => { this.event.showEventAvoidModal() })
      return (
        <Navigation title={event.title} hideFooter multipleActions={multipleActions} location={location}>
          <Event {...this.props} ref={c => { this.event = c }} />
        </Navigation>
      )
    }
  }
}

export default EventContainer(EventWithNavigation)
