import React from 'react'
import {connect} from 'react-redux'

// Views
import MileageImportViewBase from 'mrshoebox-ui-components/src/modules/DriverLogs/views/DriverLogImportView'

// Actions
import { importMileageReportsAction } from '../../../actions/imports'
import { reloadDriverLogsListAction, extendDriverLogsListAction } from 'MileageReports/actions/mileageReports'
import { routerActions } from 'react-router-redux'

const MileageImportView = (props) => (
  <MileageImportViewBase
    {...props}
    reloadListAction={reloadDriverLogsListAction()}
    extendListAction={extendDriverLogsListAction()}
  />
)

function mapStateToProps () {
  return {
    withAddButton: true,
    cameFrom: 'invoice'
  }
}

function mapDispatchToProps (dispatch) {
  return {
    importItemsToForm: (reports) => { dispatch([importMileageReportsAction(reports), routerActions.goBack()]) }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MileageImportView)
