import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import LoginPreloader from 'User/shared/components/LoginPreloader/LoginPreloader'

class FullscreenSpinner extends Component {
  static propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    children: PropTypes.object,
    onCancelClick: PropTypes.func,
    showSpinner: PropTypes.bool,
    t: PropTypes.func
  };

  componentWillUpdate (nextProps) {
    if (nextProps.showSpinner) {
      this.spinner.setVisible(true)
    } else {
      this.spinner.setVisible(false)
    }
  }

  render () {
    const { title, t, children } = this.props
    return <LoginPreloader
      title={title}
      t={t}
      ref={(c) => { this.spinner = c }}
      onCancelClick={this.props.onCancelClick}
    >
      {children}
    </LoginPreloader>
  }
}

function mapStateToProps (state, ownProps) {
  return {
    t: state.i18n.get('app', 'shared', 'buttons'),
    title: ownProps.title || state.i18n.get('app', 'shared', 'labels', 'loading').s,
    showSpinner: state.navbarSpinner.value
  }
}

export default connect(mapStateToProps)(FullscreenSpinner)
