import React from 'react'
import { connect } from 'react-redux'
import { routerActions } from 'react-router-redux'

export function withHandlersAndTranslations (WrappedComponent) {
  class Component extends React.Component {
    render () {
      return <WrappedComponent {...this.props} />
    }
  }

  const mapStateToProps = (state) => ({
    t: state.i18n.get('app', 'views', 'MarketPlace')
  })

  const mapDispatchToProps = (dispatch) => ({
    cancelHandler: () => dispatch(routerActions.goBack()),
    redirectToOnboarding: () => dispatch(routerActions.push('/marketplace/creditsafe/onboarding'))
  })

  return connect(mapStateToProps, mapDispatchToProps)(Component)
}
