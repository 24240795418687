import { bind } from 'redux-effects'
import { createAction } from 'redux-actions'
import { routerActions } from 'react-router-redux'
import { flatten, omit, pickBy, identity, isEmpty } from 'lodash'
import { decamelizeKeys } from 'humps'

import {showNavbarSpinnerAction, hideNavbarSpinnerAction} from 'shared/actions/navbarSpinner'
import {fetch2 as fetch} from 'shared/helpers/fetch'

import { convertFromNewApi } from 'FileUpload/helpers/apiAdapter'
import {
  showMessageBoxWithParamsAction
} from 'MessageBox/actions/messageBox'

import { INVOICE_SETTINGS } from 'User/Settings/constants/Api'
import { INVOICE_SETTINGS_FIELD_UPDATE, INVOICE_SETTINGS_FIELD_CLEAR } from 'User/Settings/constants/InvoiceSettings'

const setInvoiceSettingsField = createAction(INVOICE_SETTINGS_FIELD_UPDATE)
const clearInvoiceSettingsField = createAction(INVOICE_SETTINGS_FIELD_CLEAR)

export function setDefaultSettingsDataAction () {
  return [
    showNavbarSpinnerAction(),
    bind(fetch(INVOICE_SETTINGS), loadInvoiceSettings, processErrorInvoiceSettings)
  ]
}

function loadInvoiceSettings (response) {
  let settings
  if (response.value.id) {
    settings = {
      ...omit(response.value, ['IBAN', 'SWIFT', 'logo']),
      iban: response.value.IBAN,
      companyRegistrationInfo: response.value.company_registration_info,
      swift: response.value.SWIFT,
      isNewSettings: false,
      logo: response.value.logo ? convertFromNewApi([{ url: response.value.logo }]) : []
    }
  } else {
    settings = {
      isNewSettings: true,
      default_invoice_days: 30,
      default_reminder_fee: 0,
      default_invoice_fee: 0,
      default_late_fee_percentage: 0
    }
  }

  return [
    hideNavbarSpinnerAction(),
    setInvoiceSettingsField(settings)
  ]
}

function processErrorInvoiceSettings () {
  return hideNavbarSpinnerAction()
}

export function clearInvoiceSettingsFieldAction () {
  return clearInvoiceSettingsField()
}

export function updateInvoiceSettingsAction (userSettings, afterSaveRedirectPath = null) {
  return (dispatch, getState) => {
    const logo = getState().filesToUpload.newFiles[0]
    const fileToRemove = getState().filesToUpload.removeFiles[0]

    return dispatch([
      showNavbarSpinnerAction(),
      bind(updateSettings(userSettings, logo, fileToRemove), afterSettingsUpdate.bind(this, afterSaveRedirectPath), onError.bind(this, dispatch))
    ])
  }
}

function onError (dispatch, response) {
  console.error(response)
  const errors = flatten(Object.values(response.value.errors_stack))
  dispatch(showMessageBoxWithParamsAction(null, errors))
}

function updateSettings (data, fileToUpload, fileToRemove) {
  if (data.organizationNumber) {
    data.organizationNumber = data.organizationNumber.replace('-', '')
  }
  const preparedDataWithoutSomeFields = decamelizeKeys(omit(data, ['iban', 'swift', 'isNewSettings']))
  const preparedData = {
    ...preparedDataWithoutSomeFields,
    IBAN: data.iban,
    SWIFT: data.swift
  }

  let formData = new FormData()
  for (let key in preparedData) {
    if (preparedData[key] !== undefined) {
      formData.append(`invoice_settings[${key}]`, preparedData[key])
    }
  }
  if (fileToUpload) {
    formData.append('invoice_settings[logo]', fileToUpload.file)
  } else if (fileToRemove) {
    formData.append('invoice_settings[remove_logo]', true) // TODO: refactor
  }

  return fetch(INVOICE_SETTINGS, {}, {
    method: 'POST',
    body: formData
  })
}

function afterSettingsUpdate (afterSaveRedirectPath) {
  return (dispatch, getState) => {
    const companyAddress = pickBy(getState().userSettings.company.primaryAddress, identity)

    return dispatch([
      setDefaultSettingsDataAction(),
      clearInvoiceSettingsField(),
      hideNavbarSpinnerAction(),
      isEmpty(companyAddress) ? routerActions.replace(`/settings/company?redirectPath=${afterSaveRedirectPath}`) : routerActions.goBack()
    ])
  }
}
