import { omit } from 'lodash'
import { createAction } from 'redux-actions'

import { CHECKBOX_LIST_INITIALIZE, CHECKBOX_LIST_UPDATE, CHECKBOX_LIST_DESTROY } from 'CheckBoxList/constants/ActionTypes'

export const destroyCheckBoxListAction = createAction(CHECKBOX_LIST_DESTROY)

const initializeCheckBoxList = createAction(CHECKBOX_LIST_INITIALIZE)
export function initializeCheckBoxListAction (items, predicate) {
  return initializeCheckBoxList({ items, shouldBeChecked: predicate })
}

export const updateCheckBoxList = createAction(CHECKBOX_LIST_UPDATE)
export function toggleCheckBoxListItemAction (index, onCheckCallback, onUncheckCallback, hasOnlyOneCheckedItem) {
  return (dispatch, getState) => {
    const { checkBoxList: { items } } = getState()
    const clickedItem = items[index]

    const newCheckboxValue = !clickedItem.isChecked
    const updatedItemInfo = {
      ...clickedItem,
      isChecked: newCheckboxValue
    }

    return dispatch([
      updateCheckBoxList({ item: updatedItemInfo, index, hasOnlyOneCheckedItem }),
      () => {
        const infoForCallback = omit(updatedItemInfo, 'isChecked')
        newCheckboxValue === true ? onCheckCallback(infoForCallback) : onUncheckCallback(infoForCallback)
      }
    ])
  }
}
