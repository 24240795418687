import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { FactoringInfo } from 'MarketPlace/shared/views/FactoringInfoView/containers/Smartphones'
import IpadNavigation from 'Navigation/IpadNavigation'
import More from 'More/views/MoreView/containers/Smartphones'

class FactoringInfoView extends Component {
  static propTypes = {
    t: PropTypes.func,
    location: PropTypes.object.isRequired
  }

  render () {
    const { t, location } = this.props
    const navBarTitle = t('factoringInfo', 'title').s

    return (
      <IpadNavigation title={navBarTitle} location={location} withoutMaxWidth>
        <More notRightIcon isIpad />
        <FactoringInfo
          {...this.props}
          readMoreLink={'/marketplace/factoring/more'}
        />
      </IpadNavigation>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    t: state.i18n.get('app', 'views', 'MarketPlace')
  }
}

export default connect(mapStateToProps)(FactoringInfoView)
