// some users has old reducer structure in cache
// so we need to remove it
export function cleanMarketplaceCache () {
  const cache = localStorage.getItem('redux')
  if (cache) {
    const cachedReleaseName = JSON.parse(localStorage.getItem('redux')).appInfo.releaseName
    if (cachedReleaseName !== RELEASE_NAME) {
      console.log('New build has been detected. Clearing marketplace data from storage.')
      removeReducerCache('marketplace')
    }
  }
}

export function removeReducerCache (key) {
  const cache = JSON.parse(window.localStorage.getItem('redux'))
  if (!cache) {
    return
  }

  delete cache[key]

  window.localStorage.setItem('redux', JSON.stringify(cache))
}
