import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form'
import { isEqual } from 'lodash'

import { isCollectorEnabled } from 'MarketPlace/Collector/helpers'

// Components
import Tabs from 'shared/components/FormTabs'

// Actions
import { reloadListAction, loadTabsCountersAction } from '../../actions/invoices'

// Constants
import { ALL, DRAFT, NOT_PAID, PAID, HAS_COLLECTOR_STATUS } from 'Invoices/shared/constants/StatusTypes'

class InvoiceListFilters extends Component {
  static propTypes = {
    filters: PropTypes.object,
    status: PropTypes.string,
    loadTabsCounters: PropTypes.func,
    reloadList: PropTypes.func,
    t: PropTypes.func
  };

  componentDidMount () {
    this.props.loadTabsCounters()
  }

  componentDidUpdate (prevProps) {
    const { filters } = this.props
    if (prevProps.filters && isEqual(prevProps.filters, filters) === false) {
      this.props.reloadList(this.props.filters)
    }
  }

  render () {
    const { t, status } = this.props

    let config = [
      {label: t('invoiceStatuses', ALL).s, value: ALL},
      {label: t('invoiceStatuses', DRAFT).s, value: DRAFT},
      {label: t('invoiceStatuses', NOT_PAID).s, value: NOT_PAID},
      // {label: t('invoiceStatuses', PARTIALLY_PAID), value: PARTIALLY_PAID},
      {label: t('invoiceStatuses', PAID).s, value: PAID}
    ]

    if (isCollectorEnabled()) {
      config = [...config, {label: t('invoiceStatuses', 'collector').s, value: HAS_COLLECTOR_STATUS}]
    }
    return (
      <Field name='status' component={Tabs} initValue={status} config={config} />
    )
  }
}
const formName = 'invoicesViewFilters'
const selector = formValueSelector(formName)

function mapStateToProps (state) {
  return {
    t: state.i18n.get('app', 'views', 'Invoices', 'InvoicesView'),
    tabsCounters: state.invoices.tabsCounters,
    status: selector(state, 'status'),
    filters: getFormValues(formName)(state)
  }
}

function mapDispatchToProps (dispatch) {
  return {
    reloadList: (filters) => { dispatch(reloadListAction(filters)) },
    loadTabsCounters: () => { dispatch(loadTabsCountersAction()) }
  }
}

export default reduxForm({
  form: formName,
  initialValues: {
    status: ALL
  }
})(connect(mapStateToProps, mapDispatchToProps)(InvoiceListFilters))
