import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import SuggestionInput from 'shared/components/SuggestionInput/Async'

import { loadEventTitleSuggestions } from 'Events/shared/actions/eventTitles'

const renderSuggestion = (suggestion) => suggestion.title

const EventTitleInput = ({ updateSuggestions, ...props }) => (
  <SuggestionInput
    name='eventTitle'
    dataKey='title'
    renderSuggestion={renderSuggestion}
    inputProps={props}
    onSuggestionsFetchRequested={updateSuggestions}
  />
)
EventTitleInput.propTypes = {
  updateSuggestions: PropTypes.func
}

const mapDispatchToProps = (dispatch) => ({
  updateSuggestions: (...props) => { dispatch(loadEventTitleSuggestions(...props)) }
})

export default connect(null, mapDispatchToProps)(EventTitleInput)
