import './NewEntry.scss?global'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { routerActions } from 'react-router-redux'
import { withRouter } from 'react-router'
import URI from 'urijs'
import { destroy } from 'redux-form'

// Components
import Button from 'shared/components/Button/Button'
import BackLink from 'User/shared/components/BackLink/BackLink'
import NewModal from 'shared/components/NewModal/NewModal'

// Bridges between iOS and Android apps
import { closeExtensionWindow } from 'appBridge/notificators/extension'

// Actions
import { setPreviousLinkAction } from 'AppInfo/actions/appInfo'
import { clearNewEventAction } from 'Events/shared/actions/event'
import { invoiceClearAction } from 'Invoices/CustomerInvoices/actions/invoiceCreate'
import { clearReducerAction } from 'Documents/actions/document'
import { forceDeleteFilesAction } from 'FileUpload/actions/filesToUpload'
import { clearSalary } from 'Salaries/actions'

// Helpers
import { isSalaryFeatureAvailable } from 'shared/helpers/releaseHelpers'

class NewEntry extends Component {
  static propTypes = {
    t: PropTypes.func,
    provideController: PropTypes.func,
    historyPush: PropTypes.func,
    historyReplace: PropTypes.func,
    historyGoBack: PropTypes.func,
    setPreviousLink: PropTypes.func,
    clearEvent: PropTypes.func,
    clearInvoice: PropTypes.func,
    clearDocument: PropTypes.func,
    cameFrom: PropTypes.string,
    clearFiles: PropTypes.func,
    location: PropTypes.object
  };

  constructor (props) {
    super(props)

    props.provideController({
      makeVisible: this.makeVisible.bind(this)
    })

    this.replaceWithFirstStepView = this.replaceWithFirstStepView.bind(this)
    this.onModalClose = this.onModalClose.bind(this)
    this.createLinkToForm = this.createLinkToForm.bind(this)
    this.formClickHandler = this.formClickHandler.bind(this)
  }

  makeVisible () {
    this.props.historyPush(this.createLink('firstStep'))
  }

  createLink (viewToLoad) {
    const { location: { pathname, query } } = this.props

    let searchParamsObject = {
      ...query,
      popup_view: viewToLoad
    }
    if (this.props.cameFrom) {
      searchParamsObject.cameFrom = this.props.cameFrom
    }

    return String(URI(pathname).addSearch(searchParamsObject))
  }

  createLinkToForm (path) {
    const { location: { query: { cameFrom } } } = this.props
    if (cameFrom) {
      return String(URI(path).addSearch({ cameFrom }))
    } else {
      return path
    }
  }

  replaceWithFirstStepView () {
    const { historyReplace } = this.props
    historyReplace(this.createLink('firstStep'))
  }

  componentWillUpdate (nextProps) {
    /* eslint-disable camelcase */
    const { location: { query: { popup_view } } } = nextProps
    if (popup_view) {
      this.modal.open()
    } else {
      if (this.modal) this.modal.close()
    }
    /* eslint-enable camelcase */
  }

  formClickHandler (cleanerFunc, isExpenseInvoice) {
    return () => {
      const { location: { query: { cameFrom, fromExtension } } } = this.props
      const { setPreviousLink, clearFiles } = this.props

      if (!cameFrom && !isExpenseInvoice) {
        setPreviousLink('/')
      }
      if (!fromExtension) {
        clearFiles()
      }
      if (isExpenseInvoice) setPreviousLink('/events')
      cleanerFunc()
    }
  }

  get firstStepView () {
    const { t, location: { query: { cameFrom } } } = this.props

    return (
      <div className='new-entry-content'>
        <div className='new-entry-texts'>
          <b>{t('add', 'title').s}</b>
          <h2>{t('other', 'selectType').s}</h2>
        </div>
        <div className='new-entry-buttons first-step-view'>
          <Button view='red-light' replaceLink={this.createLink('expenses')}>{t('add', 'expense').s}</Button>
          <Button view='bright-green' replaceLink={this.createLink('revenue')}>{t('add', 'revenue').s}</Button>
          { cameFrom !== 'invoice' && <Button view='gray' replaceLink={this.createLink('others')}>{t('add', 'other').s}</Button> }
        </div>
      </div>
    )
  }

  get expensesView () {
    const { t, clearEvent, setPreviousLink, location } = this.props

    const isExpenseInvoice = true

    const salaryClickHandler = () => {
      setPreviousLink('/salaries')
      clearSalary()
    }

    return (
      <div className='new-entry-content'>
        <div className='new-entry-texts'>
          <b>{t('addExpense', 'title').s}</b>
        </div>
        <div className='new-entry-buttons'>
          <Button
            view='dark'
            onClick={this.formClickHandler(clearEvent)}
            link={this.createLinkToForm('/events/new/expense?type=receipt')}
            state={{ from: location.pathname }}
          >
            {t('addExpense', 'receipt').s}
          </Button>
          <Button
            view='dark'
            onClick={this.formClickHandler(clearEvent, isExpenseInvoice)}
            link={this.createLinkToForm('/events/new/expense?type=invoice')}
            state={{ from: location.pathname }}
          >
            {t('addExpense', 'invoice').s}
          </Button>
          <Button
            view='dark'
            onClick={this.formClickHandler(clearEvent)}
            link={this.createLinkToForm('/events/new/withdrawal')}
            state={{ from: location.pathname }}
          >
            {t('addExpense', 'pay').s}
          </Button>
          {(isSalaryFeatureAvailable()) &&
            <Button
              view='dark'
              onClick={salaryClickHandler}
              link={this.createLinkToForm('/salaries/new')}
            >
              {t('addExpense', 'salary').s}
            </Button>
          }
        </div>
        <BackLink onClick={this.replaceWithFirstStepView} text={t('buttons', 'back').s} />
      </div>
    )
  }

  get revenueView () {
    const {
      t, setPreviousLink, clearEvent, clearInvoice,
      location: { query: { cameFrom } }
    } = this.props

    const invoiceClickHandler = () => {
      setPreviousLink('/invoices')
      clearInvoice()
    }

    return (
      <div className='new-entry-content'>
        <div className='new-entry-texts'>
          <b>{t('addRevenue', 'title').s}</b>
        </div>
        <div className='new-entry-buttons'>
          { cameFrom !== 'invoice' && <Button view='dark' link='/invoices/new' onClick={invoiceClickHandler}>{t('addRevenue', 'newInvoice').s}</Button> }
          <Button view='dark' onClick={this.formClickHandler(clearEvent)} link={this.createLinkToForm('/events/new/revenue?type=receipt')}>{t('addRevenue', 'receipt').s}</Button>
          <Button view='dark' onClick={this.formClickHandler(clearEvent)} link={this.createLinkToForm('/events/new/revenue?type=invoice')}>{t('addRevenue', 'invoice').s}</Button>
          <Button view='dark' onClick={this.formClickHandler(clearEvent)} link={this.createLinkToForm('/events/new/deposit')}>{t('addRevenue', 'deposit').s}</Button>
        </div>
        <BackLink onClick={this.replaceWithFirstStepView} text={t('buttons', 'back').s} />
      </div>
    )
  }

  get othersView () {
    const { t, clearEvent, clearDocument } = this.props

    return (
      <div className='new-entry-content'>
        <div className='new-entry-texts'>
          <b>{t('addOther', 'title').s}</b>
        </div>
        <div className='new-entry-buttons'>
          <Button view='dark' onClick={this.formClickHandler(clearEvent)} link={this.createLinkToForm('/events/new/transfer')}>{t('addOther', 'transfer').s}</Button>
          <Button view='dark' onClick={this.formClickHandler(clearDocument)} link='/documents/new/statement'>{t('addOther', 'statement').s}</Button>
          <Button view='dark' onClick={this.formClickHandler(clearDocument)} link='/documents/new/other'>{t('addOther', 'document').s}</Button>
        </div>
        <BackLink onClick={this.replaceWithFirstStepView} text={t('buttons', 'back').s} />
      </div>
    )
  }

  closeModal () {
    this.modal.close()
    this.onModalClose()
  }

  onModalClose () {
    const { historyGoBack } = this.props

    closeExtensionWindow()

    setTimeout(() => {
      historyGoBack()
    }, 500)
  }

  render () {
    /* eslint-disable camelcase */
    const { location: { query: { popup_view } } } = this.props
    return (
      <NewModal ref={(c) => { this.modal = c }} onClose={this.onModalClose} leftMenu>
        { popup_view === 'firstStep' && this.firstStepView }
        { popup_view === 'expenses' && this.expensesView }
        { popup_view === 'revenue' && this.revenueView }
        { popup_view === 'others' && this.othersView }
      </NewModal>
    )
    /* eslint-enable camelcase */
  }
}

function mapStateToProps (state) {
  return {
    t: state.i18n.get('app', 'components', 'NewEntry')
  }
}

function mapDispatchToProps (dispatch) {
  return {
    historyPush: (path) => { dispatch(routerActions.push(path)) },
    historyReplace: (path) => { dispatch(routerActions.replace(path)) },
    historyGoBack: () => { dispatch(routerActions.goBack()) },
    setPreviousLink: (link) => { dispatch(setPreviousLinkAction(link)) },
    clearEvent: () => dispatch([
      clearNewEventAction(),
      destroy('receipt-form'),
      destroy('paymentStatus'),
      destroy('representation')
    ]),
    clearInvoice: () => { dispatch(invoiceClearAction()) },
    clearDocument: () => { dispatch([clearReducerAction()]) },
    clearFiles: () => { dispatch(forceDeleteFilesAction()) }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(NewEntry))
