import PropTypes from 'prop-types'
import React, { Component } from 'react'
import cs from 'classnames'
import ComboChart from 'shared/components/Chart/Combo'
import Dates from 'Overview/Overview/components/Dates'

import getGreyLabelOptions from 'GreyLabel/helpers/greyLabel'

export default class OverviewCharts extends Component {
  static propTypes = {
    settings: PropTypes.object,
    location: PropTypes.object,
    results: PropTypes.object,
    t: PropTypes.func,
    loadOverviewData: PropTypes.func
  }

  openSettings = () => {
    this.settingsModal.open()
  }

  render () {
    const {settings, location: { query: { type = 'result' } }, t, loadOverviewData} = this.props
    const {income, outcome, difference, detailedResults, cashBankResults} = this.props.results
    const incomeLink = type === 'result' ? '/overview/result/revenues?chart_type=bars' : '/overview/forecast/revenues?chart_type=bars'
    const outcomeLink = type === 'result' ? '/overview/result/expenses?chart_type=bars' : '/overview/forecast/expenses?chart_type=bars'
    const texts = {
      line: {
        empty: t('charts', 'labels', 'emptyDataText').s,
        label: t('charts', 'labels', 'revenueAndCosts').s
      },
      calcLine: {
        empty: t('charts', 'labels', 'emptyDataText').s,
        label: t('charts', 'labels', 'income').s
      },
      arc: {
        empty: t('charts', 'labels', 'emptyDataTextShort').s
      },
      cashBank: {
        empty: t('charts', 'labels', 'emptyDataText').s,
        label: t('charts', 'labels', 'cashBank').s
      },
      signature: {
        income: t('graph', 'income').s,
        outcome: t('graph', 'outcome').s,
        hint: t('graph', 'hint').s
      }
    }

    const chartData = {income, outcome, difference, incomeLink, outcomeLink, detailedResults, cashBankResults, texts}
    const { whiteLabel } = getGreyLabelOptions()
    return (
      <div className={cs('overview-view__charts', whiteLabel)}>
        <Dates settings={settings} onSaveClick={loadOverviewData} type={type} />
        <ComboChart {...chartData} openSettings={this.openSettings} customClass='mobile-overview' type={type} />
      </div>
    )
  }
}
