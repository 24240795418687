import React, { Component } from 'react'
import PropTypes from 'prop-types'

import MissingInfo from 'MarketPlace/Collector/views/MissingInfo'
import FactoringHOC from 'MarketPlace/Collector/views/shared/FactoringHOC'
import IpadNavigation from 'Navigation/IpadNavigation'
import More from 'More/views/MoreView/containers/Smartphones'

class FactoringMissingInfoView extends Component {
  static propTypes = {
    t: PropTypes.func,
    location: PropTypes.object.isRequired,
    startHandler: PropTypes.func.isRequired,
    cancelHandler: PropTypes.func.isRequired
  }

  render () {
    const { t, location, startHandler, cancelHandler } = this.props
    const navBarTitle = t('MissingInfo', 'navBarTitle').s

    return (
      <IpadNavigation title={navBarTitle} location={location} withoutMaxWidth>
        <More notRightIcon isIpad />
        <MissingInfo t={t} startHandler={startHandler} cancelHandler={cancelHandler} />
      </IpadNavigation>
    )
  }
}

export default FactoringHOC(FactoringMissingInfoView)
