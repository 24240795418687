import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getFormValues, getFormMeta } from 'redux-form'
import { isEqual } from 'lodash'

// Components
import FormatNumber from 'shared/components/FormatNumber/FormatNumber'
import DotsSpinner from 'shared/components/DotsSpinner'

// Constants
import { INVOICE } from 'Invoices/shared/constants/InvoiceTypes'

// Actions
import { updateInvoiceTotalsAction } from 'Invoices/CustomerInvoices/actions/invoiceCreate'

class InvoiceTotals extends Component {
  static propTypes = {
    formIsActive: PropTypes.bool,
    invoice: PropTypes.object,
    formValues: PropTypes.object,
    updateTotals: PropTypes.func,
    isCalculating: PropTypes.bool,
    t: PropTypes.func
  }

  componentDidMount () {
    if (this.props.invoice.invoiceFee || this.props.invoice.invoiceFee === 0) {
      this.props.updateTotals({ ...this.props.invoice, ...this.props.formValues })
    }
  }

  componentWillReceiveProps (nextProps) {
    if (
      !nextProps.formIsActive &&
      (
        !isEqual(nextProps.formValues, this.props.formValues) ||
        !isEqual(nextProps.invoice, this.props.invoice)
      )
    ) {
      this.props.updateTotals({ ...nextProps.invoice, ...nextProps.formValues })
    }
  }

  getSection (sectionName) {
    const { t, isCalculating, formValues: { invoiceType = INVOICE, invoiceFee }, invoice: { currencyName, totals = {} } } = this.props
    let amountMultiplier = invoiceType === INVOICE ? 1 : -1

    if (sectionName === 'rotRut' || sectionName === 'discountAmount') {
      amountMultiplier *= -1
    }

    if (sectionName === 'invoiceFee') {
      return (
        <tr>
          <td>{t('labels', 'invoiceFee').s}:</td>
          <td id={sectionName}>
            { isCalculating ? <DotsSpinner /> : <FormatNumber value={invoiceFee * amountMultiplier} /> }
            { currencyName }
          </td>
        </tr>
      )
    }

    if (!totals[sectionName]) {
      return null
    }

    return (
      <tr>
        <td>{t('labels', sectionName).s}:</td>
        <td id={sectionName}>
          { isCalculating ? <DotsSpinner /> : <FormatNumber value={totals[sectionName] * amountMultiplier} /> }
          { currencyName }
        </td>
      </tr>
    )
  }

  render () {
    return (
      <table className='create-invoice__totals'>
        <tbody>
          { this.getSection('preTotal') }
          { this.getSection('invoiceFee') }
          { this.getSection('discountAmount') }
          { this.getSection('rotRut') }
          { this.getSection('totalVat') }
          { this.getSection('roundingAmount') }
          { this.getSection('total') }
        </tbody>
      </table>
    )
  }
}

function mapStateToProps (state) {
  const formMeta = getFormMeta('invoice-form')(state)
  return {
    invoice: state.invoices.customerInvoices.invoice,
    isCalculating: state.invoices.customerInvoices.totalsAreCalculating,
    formValues: getFormValues('invoice-form')(state) || {},
    formIsActive: Object.keys(formMeta).find(fieldName => formMeta[fieldName].active),
    t: state.i18n.get('app', 'views', 'Invoices', 'InvoiceCreateView')
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateTotals: (values) => { dispatch(updateInvoiceTotalsAction(values)) }
})

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceTotals)
