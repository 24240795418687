import React from 'react'
import axios from 'api-client-connector'
import { createAction } from 'redux-act'
import { isNil, uniq } from 'lodash'
import { camelizeKeys, decamelizeKeys } from 'humps'
import { RULES_LIST, RULE, PREVIEW_INVOICES } from '../constants/Api'

import { fetchSuppliersList } from 'Counterparties/Suppliers/actions/suppliers'
import { showNavbarSpinnerAction, hideNavbarSpinnerAction } from '../../../shared/actions/navbarSpinner'
import {
  showMessageBoxWithParamsAction,
  showElementInMessageBoxAction,
  closeMessageBoxAction
} from 'MessageBox/actions/messageBox'

import { ConfirmationMessageBoxContent } from 'MessageBox/components/ConfirmationMessageBox/ConfirmationMessageBox'

export const loadRulesAction = createAction('Load invoice approve rules')
export const setRuleFiltersAction = createAction('Set rule as filters for invoices')

export function fetchPreviewInvoicesIds (params, companyId) {
  const body = formatBody(params, companyId)
  return axios.post(PREVIEW_INVOICES({}).toString(), decamelizeKeys(body))
}

export function loadRules (companyId) {
  return async (dispatch) => {
    dispatch(showNavbarSpinnerAction())
    const res = await fetchRules({ companyId })
    let rules = camelizeKeys(res.data.rules)

    const supplierIds = uniq(rules
      .filter(rule => !isNil(rule.supplierId))
      .map(sd => sd.supplierId)).join(',')
    const suppliersRequestParams = {
      ids: supplierIds,
      company_id: Number(companyId)
    }
    const { data: { suppliers } } = await fetchSuppliersList(suppliersRequestParams)

    rules = rules.map(rule => {
      const supplier = suppliers.find(supplier => rule.supplierId === supplier.id)
      return {
        supplierName: (supplier && supplier.name) || '',
        ...rule
      }
    })

    return dispatch([loadRulesAction(rules), hideNavbarSpinnerAction()])
  }
}

export function fetchRules (params) {
  return axios.get(RULES_LIST(decamelizeKeys(params)).toString())
}

export function saveRule (rule, companyId) {
  return async (dispatch, getState) => {
    dispatch(showNavbarSpinnerAction())
    try {
      await saveRuleRequest(formatBody(rule, companyId), rule.id)

      return dispatch(loadRules(companyId))
    } catch (e) {
      processError(e, getState, dispatch)
    }
  }
}

function formatBody (rule, companyId) {
  const params = {
    ...rule,
    minAmount: isNil(rule.minAmount) || rule.minAmount === '' ? null : Number(rule.minAmount),
    maxAmount: isNil(rule.maxAmount) || rule.maxAmount === '' ? null : Number(rule.maxAmount),
    companyId: Number(companyId)
  }
  return decamelizeKeys(params)
}

function saveRuleRequest (body, id) {
  return id
    ? axios.patch(RULE({ id }).toString(), body)
    : axios.post(RULES_LIST({}).toString(), body)
}

export function deleteRuleWithConfirmation (id, companyId) {
  return (dispatch, getState) => {
    const t = getState().i18n.get('app', 'shared', 'messages', 'confirmationMessage')

    const element = (
      <ConfirmationMessageBoxContent
        t={t}
        onYesClick={() => { dispatch([closeMessageBoxAction(), deleteRule(id, companyId)]) }}
        onNoClick={() => { dispatch(closeMessageBoxAction()) }}
      />
    )

    return dispatch(showElementInMessageBoxAction(element, () => { dispatch(closeMessageBoxAction()) }))
  }
}

export function deleteRule (id, companyId) {
  return async (dispatch, getState) => {
    try {
      dispatch(showNavbarSpinnerAction())
      const params = decamelizeKeys({ id, companyId })
      await deleteRuleRequest(params)
      dispatch(loadRules(companyId))
      dispatch(hideNavbarSpinnerAction())
    } catch (e) {
      processError(e, getState, dispatch)
    }
  }
}

function deleteRuleRequest (params) {
  return axios.delete(RULE(params).toString())
}

function processError (error, getState, dispatch) {
  const t = getState().i18n.get('app', 'shared', 'messages')
  dispatch(showMessageBoxWithParamsAction(null, t('unexpectedError', 'description').s))
  console.log('error', error)
}
