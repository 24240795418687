import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router'

import FormatDate from 'shared/components/FormatDate/FormatDate'

export function ProjectsListItem (props) {
  const {id, title, startDate, latestUpdate, category} = props.data
  return (
    <Link to={'/projects/' + id} className='list-multiline-item'>
      <div className='list-multiline-item__row'>
        <div>
          <h4>{title}</h4>
          <h4>{category}</h4>
        </div>
        <div>
          <h5><FormatDate value={latestUpdate} /></h5>
          <h5><FormatDate value={startDate} /></h5>
        </div>
      </div>
      <h4 className='list-multiline-item__arrow'><span className='icon-arrow-right' /></h4>
    </Link>
  )
}

ProjectsListItem.prototype.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired
}
