import PropTypes from 'prop-types'
import React from 'react'
import { reduxForm, getFormValues, change } from 'redux-form'
import { connect } from 'react-redux'
import { routerActions } from 'react-router-redux'
import { pick, isEmpty, identity, pickBy } from 'lodash'

// Helpers
import { createLink } from 'Events/shared/helpers/eventForm'

// Actions
import { setInitialInvoicePropsAction, setInitialCreditNotePropsAction, saveInvoiceAction,
  invoiceGetAction, invoiceClearAction, invoiceUpdatePropsAction, invoiceUpdatePropAction,
  showInvoiceNumberHelpAction, updateCustomerVatNumber } from '../../../actions/invoiceCreate'
import { showMessageBoxWithParamsAction } from 'MessageBox/actions/messageBox'
import { removeInvoiceRowAction, updateInvoiceRowAction } from '../../../actions/imports'

export default function InvoiceCreateContainer (Component) {
  class InvoiceCreate extends React.Component {
    static propTypes = {
      t: PropTypes.func,
      params: PropTypes.object,
      invoiceSettings: PropTypes.object,
      userSettings: PropTypes.object,
      redirectToInvoiceSettings: PropTypes.func,
      invoice: PropTypes.object,
      formValues: PropTypes.object,
      setInitialProps: PropTypes.func,
      invoiceGet: PropTypes.func,
      clearGetParams: PropTypes.func,
      location: PropTypes.object,
      setInitialCreditNoteProps: PropTypes.func,
      redirectToCompanySettings: PropTypes.func
    }

    componentDidMount () {
      const { redirectToInvoiceSettings, redirectToCompanySettings, invoiceSettings, userSettings } = this.props
      if (invoiceSettings.isNewSettings) {
        redirectToInvoiceSettings()
        return
      }

      const companyAddress = pickBy(userSettings.company.primaryAddress, identity)
      if (isEmpty(companyAddress)) {
        redirectToCompanySettings()
      }

      const {
        invoice,
        formValues,
        setInitialProps,
        invoiceGet,
        params: {id},
        location: {query: {creditNote}},
        clearGetParams,
        setInitialCreditNoteProps
      } = this.props
      // may be mounted with same edit page - not reload it
      const isAnotherEditPage = id && formValues.invoiceNumber !== invoice.invoiceNumber
      if (creditNote && !isAnotherEditPage) {
        setInitialCreditNoteProps(creditNote)
        clearGetParams()
      } else if (isAnotherEditPage) {
        invoiceGet(id)
      } else if (!invoice.invoiceNumber) {
        setInitialProps()
      }
    }

    get navbarTitle () {
      const { t, params, invoice } = this.props
      if (params && params.id && invoice.invoiceNumber) {
        return t('editTitle').replace('%number%', invoice.invoiceNumber)
      } else {
        return t('title').s
      }
    }

    render () {
      return (
        <Component {...this.props} navbarTitle={this.navbarTitle} />
      )
    }
  }

  const fields = [
    'invoiceNumber', 'invoiceDate', 'dueDate', 'invoiceFee', 'lateFeePercentage', 'reminderFee',
    'invoiceType', 'euVat', 'invertedVat', 'customerVatNumber', 'message', 'currencyId', 'noVat'
  ]

  const formName = 'invoice-form'
  function mapStateToProps (state) {
    return {
      currencies: state.userSettings && state.userSettings.currencies,
      formValues: getFormValues(formName)(state) || {},
      invoice: state.invoices.customerInvoices.invoice,
      initialValues: pick(state.invoices.customerInvoices.invoice, fields),
      invoiceSettings: state.invoiceSettings,
      userSettings: state.userSettings,
      t: state.i18n.get('app', 'views', 'Invoices', 'InvoiceCreateView'),
      previousLink: state.appInfo.previousLink
    }
  }

  function mapDispatchToProps (dispatch, ownProps) {
    const { location: { pathname } } = ownProps
    return {
      invoiceUpdateProps: (data) => { dispatch(invoiceUpdatePropsAction(data)) },
      setInitialProps: (creditNote) => { dispatch(setInitialInvoicePropsAction(creditNote)) },
      saveInvoice: () => { dispatch(saveInvoiceAction()) },
      invoiceGet: (id) => { dispatch(invoiceGetAction(id)) },
      invoiceClear: () => { dispatch(invoiceClearAction()) },
      redirectToRotRutForm: () => { dispatch(routerActions.push(createLink(pathname, 'rot_rut'))) },
      redirectToCompanySettings: () => { dispatch(routerActions.replace('/settings/company?redirectPath=/invoices/new')) },
      redirectToInvoiceSettings: () => { dispatch(routerActions.replace('/settings/invoice?redirectPath=/invoices/new')) },
      redirectToDiscount: () => { dispatch(routerActions.push(`${pathname}/discount`)) },
      clearGetParams: () => { dispatch(routerActions.replace(pathname)) },
      showMessageBox: (title, message) => { dispatch(showMessageBoxWithParamsAction(title, message)) },
      removeInvoiceRow: (index) => { dispatch(removeInvoiceRowAction(index)) },
      updateInvoiceRow: (data, index) => { dispatch(updateInvoiceRowAction(data, index)) },
      removeCustomer: () => { dispatch(invoiceUpdatePropAction('customer', {}, false)) },
      updateCustomerVatNumber: (customer) => dispatch(updateCustomerVatNumber(customer)),
      showInvoiceNumberHelp: (message) => { dispatch(showInvoiceNumberHelpAction(message)) },
      setInitialCreditNoteProps: (id) => { dispatch(setInitialCreditNotePropsAction(id)) },
      changeInvoiceTypeToCredit: () => dispatch(change(formName, 'invoiceType', 'credit')),
      changeFieldValue: (fieldName) => (value) => dispatch(change(formName, fieldName, value)),
      clearMessage: () => dispatch(change(formName, 'message', ''))
    }
  }

  function mergeProps (stateProps, dispatchProps, ownProps) {
    let fields = {};
    [
      'invoiceNumber', 'invoiceDate', 'dueDate', 'invoiceFee', 'lateFeePercentage', 'reminderFee',
      'invoiceType', 'euVat', 'invertedVat', 'customerVatNumber', 'message', 'currencyId', 'noVat'
    ].forEach(fieldName => {
      fields[fieldName] = {
        value: stateProps.formValues[fieldName] ? stateProps.formValues[fieldName] : '',
        onChange: dispatchProps.changeFieldValue(fieldName)
      }
    })

    return Object.assign({}, ownProps, {
      ...dispatchProps,
      showInvoiceNumberHelp: () => dispatchProps.showInvoiceNumberHelp(stateProps.t('invoiceNumberHelp', 'description').s),
      ...stateProps,
      fields
    })
  }

  return connect(mapStateToProps, mapDispatchToProps, mergeProps)(reduxForm({
    form: 'invoice-form',
    destroyOnUnmount: false,
    enableReinitialize: true
  })(InvoiceCreate))
}
