import './WizardOffers.scss?global'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router'
import cs from 'classnames'

import { getPriceListAction, setInfoAction } from 'Wizard/actions/wizard'

import {addHashForIos} from 'shared/helpers/utils'

import { NOT_STARTED_COMPANY } from 'Wizard/constants/BusinessTypes'

import Navigation from 'Navigation/Navigation'

import getGreyLabelOptions from 'GreyLabel/helpers/greyLabel'

export class WizardOffers extends Component {
  static propTypes = {
    wizard: PropTypes.object,
    setInfo: PropTypes.func,
    t: PropTypes.func,
    location: PropTypes.object,
    getPriceList: PropTypes.func
  };

  get administratorPageLink () {
    return '/wizard/administrator'
  }

  saveCurrentStep () {
    addHashForIos()
    this.props.setInfo({currentStep: this.props.location.pathname})
  }

  get navigateActions () {
    return [{icon: 'icon-folding', path: '/?skipWizard=true', onClick: this.saveCurrentStep.bind(this)}]
  }

  get easyChangeText () {
    const {t} = this.props
    return (
      <div className='wizard-offers__easy-change'>
        <div className='wizard-offers__easy-change__items'>
          <div>
            <div>1</div>
            <div>{t('easyChangeLabels', '1').s}</div>
          </div>
          <div>
            <div>2</div>
            <div>{t('easyChangeLabels', '2').s}</div>
          </div>
          <div>
            <div>3</div>
            <div>{t('easyChangeLabels', '3').s}</div>
          </div>
        </div>
        <div className='wizard-offers__easy-change__text'>{t('easyChangeText').s}</div>
      </div>
    )
  }

  restrictText (text) {
    const length = 142
    let trimmedText = text.substring(0, length)
    if (trimmedText.slice(-1) === ',') {
      trimmedText = text.substring(0, length - 1)
    }
    return `${trimmedText}...`
  }

  get backLink () {
    const { wizard: { newPrice: { companyType } } } = this.props
    if (companyType === NOT_STARTED_COMPANY) {
      return '/wizard/not-started-company'
    } else {
      return '/wizard/base-info'
    }
  }

  componentDidMount () {
    this.props.getPriceList()
  }

  render () {
    const {wizard, t, setInfo} = this.props
    const whiteLabelAdditionalClass = getGreyLabelOptions().whiteLabel

    if (wizard.priceList.length === 0) {
      return (
        <Navigation navigationWithMaxWidth className='wizard wizard-offers' title={t('title').s} actions={this.navigateActions} hideFooter backLink={this.backLink} forceBackLink>
          <div className='wizard-offers__no-offers-text'>
            <span className='text'>
              {t('noOffers').s}
            </span>
          </div>
        </Navigation>
      )
    }

    return (
      <Navigation navigationWithMaxWidth className='wizard wizard-offers' title={t('title').s} actions={this.navigateActions} hideFooter backLink={this.backLink} forceBackLink>
        {wizard.priceList.length && <div className={cs('wizard-offers__text', whiteLabelAdditionalClass)}>
          <div className='wizard-offers__text__account'>
            <div>{t('offersMatch').replace('%count%', wizard.priceList.length)}</div>
          </div>
          {wizard.wizardCategory === 'easyChange' && this.easyChangeText}
        </div>}
        <div className='wizard-offers__list'>
          {wizard.priceList.map((price, index) => {
            return (
              <Link to={this.administratorPageLink} onClick={() => setInfo({accountingCompanyId: price.accounting_company_id})} className='wizard-offers__list__item' key={index}>
                <div>
                  <div className='wizard-offers__list__item__content'>
                    <div className='wizard-offers__list__item__img'>
                      <img src={price.logo_href} />
                    </div>
                    <div className={cs('wizard-offers__list__item__right__text', whiteLabelAdditionalClass)}>
                      <div>{price.name}</div>
                      <span>{this.restrictText(price.description)}</span>
                    </div>
                  </div>
                  <div className={cs('wizard-offers__list__item__text', whiteLabelAdditionalClass)}>
                    {price.packet.total_price ? t('offerPrice').replace('%price%', price.packet.total_price) : t('uponRequest').s}
                  </div>
                  <div className={cs('wizard-offers__list__item__arrow', whiteLabelAdditionalClass)} />
                </div>
              </Link>
            )
          })}
        </div>
      </Navigation>
    )
  }
}

function mapStateToProps (state) {
  return {
    wizard: state.wizard,
    t: state.i18n.get('app', 'views', 'Wizard', 'Offers')
  }
}

function mapDispatchToProps (dispatch) {
  return {
    getPriceList: () => { dispatch(getPriceListAction()) },
    setInfo: (data) => { dispatch(setInfoAction(data)) }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WizardOffers)
