import './RepresentationView.scss?global'

import Smartphones from 'Events/Expense/views/RepresentationView/containers/Smartphones'
import Tablets from 'Events/Expense/views/RepresentationView/containers/Tablets'

import {getCurrentDeviceView} from 'shared/helpers/utils'

const RepresentationView = getCurrentDeviceView(Smartphones, Tablets)

export default RepresentationView
