export default function getGreyLabelOptions (hostname = window.__MOCKED_WHITELABEL__ || document.location.hostname) {
  switch (true) {
    case /\w*dg9economy/i.test(hostname):
      return dg9economyOptions()
    case /\w*slipp/i.test(hostname):
      return slippOptions()
    case /\w*adviceaccounting/i.test(hostname):
      return adviceAccountingOptions()
    case /\w*digitalaforetaget/i.test(hostname):
      return digitalaForetagetOptions()
    case /\w*xn--ebokfrarna-icb/i.test(hostname):
      return ebokforarnaOptions()
    case /\w*winwin-go/i.test(hostname):
      return winwinOptions()
    case /\w*werkeys/i.test(hostname):
      return werkeysOptions()
    default:
      return mrShoeboxOptions()
  }
}

function dg9economyOptions () {
  return {
    greyLabel: 'dg9economy',
    language: 'sk',
    country: 'sk',
    faviconCategory: 'dg9economy',
    logoClass: 'logo-dg9economy',
    title: 'DG9 Economy',
    entryPageIconPrefix: 'dg9economy',
    whiteLabel: 'dg9economy'
  }
}

function slippOptions () {
  return {
    greyLabel: 'slippab',
    language: 'sv',
    country: 'se',
    faviconCategory: 'slipp',
    logoClass: 'logo-slipp',
    title: 'Slipp',
    entryPageIconPrefix: 'slipp',
    whiteLabel: 'slipp'
  }
}

function adviceAccountingOptions () {
  return {
    greyLabel: 'advice',
    language: 'sv',
    country: 'se',
    faviconCategory: 'advice-accounting',
    logoClass: 'logo-advice-accounting',
    title: 'Advice Accounting'
  }
}

export function digitalaForetagetOptions () {
  return {
    greyLabel: 'blinfo',
    language: 'sv',
    country: 'se',
    faviconCategory: 'digitala-foretaget',
    logoClass: 'logo-digitala-foretaget',
    title: 'Digitala Företaget',
    phone: '0650-541405',
    whiteLabel: 'digitalaforetaget',
    entryPageIconPrefix: 'digitala'
  }
}

export function mrShoeboxOptions () {
  return {
    faviconCategory: 'mrShoebox',
    language: 'sv',
    country: 'se',
    logoClass: 'logo-mshoebox',
    title: 'Mr Shoebox',
    phone: '0771-245 245',
    whiteLabel: 'mrshoebox',
    entryPageIconPrefix: 'shoebox'
  }
}

export function winwinOptions () {
  return {
    greyLabel: 'winwin',
    language: 'sv',
    country: 'se',
    faviconCategory: 'winwin',
    logoClass: 'winwin-logo',
    logoExtension: 'png',
    title: 'Winwin',
    entryPageIconPrefix: 'winwin',
    whiteLabel: 'winwin'
  }
}

export function werkeysOptions () {
  return {
    greyLabel: 'werkeys',
    language: 'sv',
    country: 'se',
    faviconCategory: 'werkeys',
    logoClass: 'werkeys-logo',
    title: 'Werkeys',
    entryPageIconPrefix: 'werkeys',
    whiteLabel: 'werkeys',
    mainTextColor: '#002232'
  }
}

function ebokforarnaOptions () {
  return {
    greyLabel: 'ebokförarna',
    language: 'sv',
    country: 'se',
    faviconCategory: 'ebokforarna',
    logoClass: 'logo-ebokforarna',
    title: 'Ebokförarna'
  }
}
