import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { directPaymentClean } from 'Invoices/CustomerInvoices/actions/invoicePreview'

export default function DirectPaymentHOC (Component) {
  class DirectPayment extends React.Component {
    static propTypes = {
      cleanDirectPayment: PropTypes.func.isRequired
    }

    componentWillUnmount () {
      this.props.cleanDirectPayment()
    }

    render () {
      return <Component {...this.props} />
    }
  }

  function mapStateToProps (state) {
    return {
      t: state.i18n.get('app', 'views', 'Invoices', 'DirectPaymentView')
    }
  }

  function mapDispatchToProps (dispatch) {
    return {
      cleanDirectPayment: () => dispatch(directPaymentClean())
    }
  }

  return connect(mapStateToProps, mapDispatchToProps)(DirectPayment)
}
