import { createAction } from 'redux-actions'
import axios from 'api-client-connector'
import { omit } from 'lodash'
import {
  SUPPLIER_CLEAR, SUPPLIERS_CLEAR, LOAD_SUPPLIERS,
  LOAD_SUPPLIER, EXTEND_SUPPLIERS
} from '../constants/ActionTypes'
import { SUPPLIERS_LIST, SUPPLIER } from '../constants/Api'
import { camelizeKeys, decamelizeKeys } from 'humps'
import { hideNavbarSpinnerAction, showSlowNavbarSpinnerAction } from '../../../shared/actions/navbarSpinner'
import {routerActions} from 'react-router-redux'
import { showMessageBoxWithParamsAction } from '../../../MessageBox/actions/messageBox'
import {loadDataToExpenseViewAction} from '../../../Events/Expense/actions/newExpense'

export const clearSupplierInfoAction = createAction(SUPPLIER_CLEAR)
export const clearSuppliersListAction = createAction(SUPPLIERS_CLEAR)
export const loadSuppliersListAction = createAction(LOAD_SUPPLIERS)
export const loadSupplierInfoAction = createAction(LOAD_SUPPLIER)
export const extendSuppliersAction = createAction(EXTEND_SUPPLIERS)

export function loadSuppliersList (filters, offset, companyId) {
  return async (dispatch, getState) => {
    dispatch(showSlowNavbarSpinnerAction())
    const params = {
      ...decamelizeKeys(omit(filters, 'search')),
      offset,
      company_id: companyId,
      name__icontains: filters.search,
      limit: 20
    }

    try {
      const suppliersResponse = await fetchSuppliersList(params)
      const { total } = suppliersResponse.data
      const hasNextPage = offset < total && total > 20
      const payload = { suppliers: suppliersResponse.data.suppliers, offset, hasNextPage }
      dispatch(
        offset === 0
          ? loadSuppliersListAction(camelizeKeys(payload))
          : extendSuppliersAction(camelizeKeys(payload))
      )
      dispatch(hideNavbarSpinnerAction())
    } catch (e) {
      processError(e, getState, dispatch)
      dispatch(hideNavbarSpinnerAction())
    }
  }
}

export function fetchSuppliersList (params) {
  return axios.get(SUPPLIERS_LIST(params).toString())
}

export function saveSupplier (supplier, companyId, supplierId) {
  return async (dispatch, getState) => {
    dispatch(showSlowNavbarSpinnerAction())
    try {
      await saveSupplierRequest(prepareBody(supplier, companyId), supplierId)
      dispatch(routerActions.push(`/suppliers`))
    } catch (e) {
      processError(e, getState, dispatch)
    }
  }
}

export function pushToExpenseInvoice (supplier, companyId) {
  return async (dispatch, getState) => {
    dispatch(showSlowNavbarSpinnerAction())
    try {
      const body = prepareBody(supplier, companyId)
      const response = await saveSupplierRequest(body, 'new')

      dispatch([
        hideNavbarSpinnerAction(),
        loadDataToExpenseViewAction({supplier: {...supplier, id: response.data.id}}),
        routerActions.go(-2)
      ])
    } catch (e) {
      dispatch(hideNavbarSpinnerAction())
      processError(e, getState, dispatch)
    }
  }
}

const prepareBody = ({iban, bic, invoiceAddress1, invoiceAddress2, supplierNumber, ...supplier}, companyId) =>
  ({
    company_id: companyId,
    IBAN: iban,
    BIC: bic,
    invoice_address_1: invoiceAddress1,
    invoice_address_2: invoiceAddress2,
    supplier_number: Number(supplierNumber),
    ...decamelizeKeys(supplier)
  })

function saveSupplierRequest (body, id) {
  return id === 'new'
    ? axios.post(SUPPLIERS_LIST({}).toString(), body)
    : axios.patch(SUPPLIER({ id }).toString(), body)
}

export function loadSupplierInfo (id, companyId) {
  return async (dispatch, getState) => {
    try {
      const response = await loadSupplierRequest(id, companyId)

      dispatch(loadSupplierInfoAction(response))
    } catch (e) {
      processError(e, getState, dispatch)
    }
  }
}

export async function getLastSupplierNumber (companyId) {
  const requestParams = {
    company_id: companyId,
    order_type: 'desc',
    order_by: 'supplier_number',
    limit: 1
  }
  const { data: { suppliers } } = await fetchSuppliersList(requestParams)

  return suppliers.length ? suppliers[0].supplier_number : null
}

export async function loadSupplierRequest (id, companyId) {
  const response = await axios.get(SUPPLIER({id, company_id: companyId}))
  const { BIC, IBAN, invoiceAddress1, invoiceAddress2, ...data } = response.data
  return {
    bic: BIC,
    iban: IBAN,
    invoice_address_1: invoiceAddress1,
    invoice_address_2: invoiceAddress2,
    ...camelizeKeys(data)
  }
}

export function deleteSupplier (id, companyId) {
  return async (dispatch, getState) => {
    try {
      const params = decamelizeKeys({ id, companyId })
      await deleteSupplierRequest(params)
      dispatch(routerActions.push(`/suppliers`))
    } catch (e) {
      processError(e, getState, dispatch)
    }
  }
}

function deleteSupplierRequest (params) {
  return axios.delete(SUPPLIER(params).toString())
}

function processError (error, getState, dispatch) {
  const t = getState().i18n.get('app', 'shared', 'messages')
  dispatch(showMessageBoxWithParamsAction(null, t('unexpectedError', 'description').s))
  console.log('error', error)
}
