import { result, find } from 'lodash'

export function addProjectName (settings, changes) {
  if (changes.projectId) {
    changes.projectId = Number(changes.projectId)
    changes.project = result(find(settings.projects, {value: changes.projectId}), 'label')
  }

  return changes
}

export function addPaymentMethod (settings, changes) {
  if (changes.paymentMethodId) {
    changes.paymentMethodId = Number(changes.paymentMethodId)
    changes.paymentMethod = result(find(settings.expensePaymentMethods, {value: changes.paymentMethodId}), 'label')
  }

  return changes
}

export function addHumanizedAmount (settings, changes) {
  if (changes.currencyId && changes.amount) {
    changes.currency = result(find(settings.currencies, {value: changes.currencyId}), 'label')
    changes.humanizedAmount = `${changes.currency} ${changes.amount}`
  }

  return changes
}

export function addDocumentCategory (settings, changes) {
  if (changes.categoryId) {
    changes.categoryId = parseInt(changes.categoryId, 10)
    changes.category = result(find(settings.documentCategories, {value: changes.categoryId}), 'label')
  }

  return changes
}

export function getCurrencyName (settings, currencyId) {
  return result(find(settings.currencies, {value: currencyId}), 'label')
}

export function getPaymentMethodName (settings, paymentMethodId) {
  return result(find(settings.expensePaymentMethods, {value: paymentMethodId}), 'label')
}
