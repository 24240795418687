import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { loadIntegrations } from 'Integrations/Integrations/actions'

export default function withRequests (WrappedComponent) {
  class Component extends React.Component {
    static propTypes = {
      location: PropTypes.object.isRequired,
      t: PropTypes.func.isRequired,
      loadIntegrations: PropTypes.func.isRequired
    }

    componentDidMount () {
      this.props.loadIntegrations({expiring_soon: true})
    }

    render () {
      return <WrappedComponent {...this.props} />
    }
  }

  function mapStateToProps (state) {
    const t = state.i18n.get('app', 'views', 'Todo', 'TodoIntegrations')
    return {
      t,
      integrations: state.integrations.integrationsList.map(integration =>
        ({
          title: t('integrationsNames', integration.type).s,
          value: integration.expiresIn <= 0 ? t('expired').s : `${Math.floor(integration.expiresIn / 86400)} ${t('days').s}`,
          path: '/todo/integrations/open-payments' // TODO: for now only open payments can expire, later we will need to pass different paths here.
        })
      )
    }
  }

  function mapDispatchToProps (dispatch) {
    return {
      loadIntegrations: (params) => dispatch(loadIntegrations(params))
    }
  }

  return connect(mapStateToProps, mapDispatchToProps)(Component)
}
