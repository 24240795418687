import React from 'react'
import { connect } from 'react-redux'

import IpadNavigation from 'Navigation/IpadNavigation'

import Vacancies from 'Vacancies/views/VacanciesView/containers/Smartphones'
import { connectedVacancyView as Vacancy } from 'Vacancies/views/VacancyView/containers/Smartphones'

const VacancyView = (props) =>
  <IpadNavigation {...props} withoutMaxWidth notBack>
    <Vacancies />
    <Vacancy {...props} />
  </IpadNavigation>

const mapStateToProps = (state) => ({
  title: state.vacancies.vacancy.title || ''
})

export default connect(mapStateToProps)(VacancyView)
