import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { routerActions } from 'react-router-redux'

import ButtonsBlock from 'MarketPlace/shared/components/ButtonsBlock'
import Icon from 'shared/components/Icon'
import Modal from 'shared/components/NewModal/NewModal'

import './index.scss?global'

class DetailsModal extends Component {
  constructor (props) {
    super(props)
    props.provideController && props.provideController({
      open: () => { this.modal.open() },
      close: () => { this.modal.close() }
    })
    this.open = this.open.bind(this)
    this.close = this.close.bind(this)
  }

  open () {
    this.modal.open()
  }

  close () {
    this.modal.close()
  }

  render () {
    return (
      <Modal ref={(node) => { this.modal = node }}>
        <div className='capcito-details'>
          <div>
            <Icon type='capcito' />
            <p>{this.props.t('description').s}</p>
          </div>
          <ButtonsBlock
            startButtonText={this.props.t('start').s}
            startHandler={this.props.startHandler}
            cancelButtonText={this.props.t('close').s}
            cancelHandler={() => this.close()}
            cancelButtonView='transparent-red'
          />
        </div>
      </Modal>
    )
  }
}

DetailsModal.propTypes = {
  t: PropTypes.func.isRequired,
  startHandler: PropTypes.func.isRequired,
  provideController: PropTypes.func.isRequired
}

const mapStateToProps = (state) => {
  return {
    t: state.i18n.get('app', 'views', 'MarketPlace', 'capcitoCollaboration', 'capcitoDetails')
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    startHandler: () => dispatch(routerActions.push('/marketplace/capcito/onboarding'))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DetailsModal)
