import React, {Component} from 'react'

import IpadNavigation from 'Navigation/IpadNavigation'
import More from 'More/views/MoreView/containers/Smartphones'

export default class MoreView extends Component {
  render () {
    return (
      <IpadNavigation {...this.props} onlyLeftSide>
        <More {...this.props} notRightIcon />
      </IpadNavigation>
    )
  }
}
