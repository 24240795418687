import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'

// Components
import List from 'shared/components/ListItems/ListItems'

// Actions
import { loadTodoItemsAction } from 'Todo/actions/todo'

class TodoView extends Component {
  static propTypes = {
    t: PropTypes.func,
    loadTodoList: PropTypes.func,
    userSettings: PropTypes.object,
    todoList: PropTypes.arrayOf(PropTypes.object)
  }

  componentWillMount () {
    this.props.loadTodoList()
  }

  addTitle (list) {
    const {t} = this.props
    const newList = list.map((item) => {
      return {
        ...item,
        title: t('list', String(item.type)).s
      }
    })
    return newList
  }

  get emptyListText () {
    const {t, userSettings} = this.props
    if (!userSettings.accountant) {
      return t('noTodosWithoutAccountant').s
    } else {
      return t('noTodos').s
    }
  }

  render () {
    const {todoList} = this.props

    return (
      <List data={this.addTitle(todoList)} emptyListOptions={{ title: this.emptyListText, icon: 'icon-todo' }} />
    )
  }
}

function mapStateToProps (state) {
  return {
    todoList: state.todo.items,
    userSettings: state.userSettings
  }
}

function mapDispatchToProps (dispatch) {
  return {
    loadTodoList: (t) => { dispatch(loadTodoItemsAction(t)) }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TodoView)
