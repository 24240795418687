import React from 'react'
import { Link } from 'react-router'
import cs from 'classnames'

import Icon from 'shared/components/Icon'
import FormatDate from 'shared/components/FormatDate/FormatDate'
import FormatNumber from 'shared/components/FormatNumber/FormatNumber'
import SelectableListItem from 'shared/components/SelectableListItem/SelectableListItem'
import { CREDIT_INVOICE } from '../../constants/InvoiceTypes'
import * as INVOICE_STATUSES from '../../constants/StatusTypes'
import {
  COLLECTOR_INVOICE_PENDING, COLLECTOR_INVOICE_OPEN,
  COLLECTOR_INVOICE_CLOSED, COLLECTOR_INVOICE_CANCELLED
} from '../../constants/CollectorInvoiceStatuses'

import styles from './ListItem.scss'

import { isOverdue } from '../../helpers'

export function getListItemIcon (invoice) {
  if (isOverdue(invoice)) {
    return 'invoice-overdue'
  }

  if (invoice.status === INVOICE_STATUSES.NOT_PAID) {
    return invoice.sentAt ? 'invoice-sent' : 'invoice-draft'
  } else if (invoice.status === INVOICE_STATUSES.PAID) {
    return 'invoice-paid'
  } else if (invoice.status === INVOICE_STATUSES.PARTIALLY_PAID) {
    return 'invoice-partially-paid'
  }
}

function getSupplierListItemIcon (invoice) {
  return getListItemIcon({sentAt: true, ...invoice})
}

export function getCollectorStatusIcon (collectorStatus) {
  if (collectorStatus === COLLECTOR_INVOICE_PENDING) {
    return 'pending-status-yellow'
  } else if (collectorStatus === COLLECTOR_INVOICE_CLOSED) {
    return 'closed-status'
  } else if (collectorStatus === COLLECTOR_INVOICE_OPEN) {
    return 'open-status'
  } else if (collectorStatus === COLLECTOR_INVOICE_CANCELLED) {
    return 'cancelled-status'
  }
}

export function SupplierInvoiceListCheckboxItem (invoice) {
  const {onCheckboxChange, data: { checkedForAction, id }} = invoice
  // FIXME using -1 for uncheck, strange api
  const checkedAsId = (checkedForAction && id) || -1
  return (
    <div className={styles.supplierItemContainer}>
      <SelectableListItem id={checkedAsId} data={{ id: id }} onChange={onCheckboxChange} />
      <SupplierInvoiceListItem {...invoice} className={styles.checkboxListItem} />
    </div>
  )
}

export function SupplierInvoiceListItem (invoice) {
  const { data: { id, number, supplierName, invoiceApprovedForPayment, amount, date, dueDate, currency, title }, className } = invoice
  return (
    <Link to={`/supplier-invoices/${id}`} className={cs('list-multiline-item', className)}>
      <div className='list-multiline-item__row'>
        <div className={styles.firstLine}>
          <div className={styles.firstLineDescription}>
            { invoiceApprovedForPayment && <Icon className={styles.statusIcon} type={'invoice-approved'} /> }
            <Icon className={styles.statusIcon} type={getSupplierListItemIcon(invoice.data)} />
            <span className={`${styles.description}`}>{ title }</span>
          </div>
          <span><FormatNumber value={amount} /> { currency } </span>
        </div>
        <div>
          <h5><span id='invoiceNumber'>{ number }</span>{ supplierName && ' | ' }{ supplierName }</h5>
          <h5 className={styles.dueDate}><FormatDate value={date} />&nbsp;&nbsp;&nbsp;<DueDate value={dueDate} /></h5>
        </div>
      </div>
      <h4 className='list-multiline-item__arrow'>
        <span className='icon-arrow-right' />
      </h4>
    </Link>
  )
}

function DueDate (dueDate) {
  if (dueDate.value) {
    return <FormatDate value={dueDate.value} />
  }

  return <span>-</span>
}

export default function CustomerInvoiceListItem (values) {
  const {id, invoiceNumber, customer: { name } = {}, additionalStatus, totals: { total }, invoiceDate, dueDate, currency, invoiceType, collectorStatus} = values.data
  const totalMultiplier = invoiceType === CREDIT_INVOICE ? -1 : 1
  return (
    <Link to={`/invoices/${id}`} className='list-multiline-item'>
      <div className='list-multiline-item__row'>
        <div className={styles.firstLine}>
          <div className={styles.firstLineDescription}>
            <Icon className={styles.statusIcon} type={getListItemIcon(values.data)} />
            { collectorStatus &&
              <Icon className={styles.statusIcon} type={getCollectorStatusIcon(collectorStatus)} />
            }
            <span className={`${styles.description}`} id='invoiceNumber'>{ invoiceNumber }</span>
          </div>
          <span><FormatNumber value={total * totalMultiplier} /> { currency } { additionalStatus ? `| ${additionalStatus}` : null }</span>
        </div>
        <div>
          <h5>{ name }</h5>
          <h5><FormatDate value={invoiceDate} />&nbsp;&nbsp;&nbsp;<FormatDate value={dueDate} /></h5>
        </div>
      </div>
      <h4 className='list-multiline-item__arrow'>
        <span className='icon-arrow-right' />
      </h4>
    </Link>
  )
}
