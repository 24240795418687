import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'

import { loadUnreadCollectorInvoicesAction } from 'Todo/actions/unreadCollectorInvoices'

export default function CommonContainer (Component) {
  class TodoCollectorInvoicesList extends React.Component {
    static propTypes = {
      invoices: PropTypes.array.isRequired,
      t: PropTypes.func.isRequired,
      loadUnreadCollectorInvoices: PropTypes.func.isRequired
    }

    componentDidMount () {
      this.props.loadUnreadCollectorInvoices()
    }

    render () {
      return (
        <Component {...this.props} />
      )
    }
  }

  function mapStateToProps (state) {
    return {
      invoices: state.invoices.customerInvoices.listData,
      t: state.i18n.get('app', 'views', 'Todo', 'TodoCollectorInvoices')
    }
  }

  function mapDispatchToProps (dispatch) {
    return {
      loadUnreadCollectorInvoices: () => { dispatch(loadUnreadCollectorInvoicesAction()) }
    }
  }

  return connect(mapStateToProps, mapDispatchToProps)(TodoCollectorInvoicesList)
}
