import { bind } from 'redux-effects'
import { createAction } from 'redux-actions'
import { fetch } from 'shared/helpers/fetch'
import { routerActions } from 'react-router-redux'
import { camelizeKeys, decamelizeKeys } from 'humps'
import { change } from 'redux-form'
import { omitBy, isNil } from 'lodash'

import { showNavbarSpinnerAction, hideNavbarSpinnerAction } from 'shared/actions/navbarSpinner'
import { showMessageBoxWithParamsAction } from 'MessageBox/actions/messageBox'

import { CUSTOMER_UPDATE_PROPS, CUSTOMER_CLEAR } from '../constants/ActionTypes'
import { CUSTOMER_ACTIVE, CUSTOMER_INACTIVE } from '../constants/CustomerStates'
import { CUSTOMER_INFO } from '../constants/Api'

import { invoiceUpdatePropsAction } from 'Invoices/CustomerInvoices/actions/invoiceCreate'

const updateCustomerProps = createAction(CUSTOMER_UPDATE_PROPS)
export const clearCustomerInfoAction = createAction(CUSTOMER_CLEAR)

export function loadCustomerInfoAction (id) {
  return [showNavbarSpinnerAction(), bind(fetch(CUSTOMER_INFO, { id }), mapCustomerInfo)]
}

function mapCustomerInfo (response) {
  const customer = omitBy(response.value, isNil)

  return [updateCustomerProps({
    ...camelizeKeys(customer),
    vatNumber: customer.VAT_number
  }), hideNavbarSpinnerAction()]
}

export function updateCustomerPropAction (field, value) {
  return updateCustomerPropsAction({ [field]: value })
}

export function updateCustomerPropsAction (changes) {
  return updateCustomerProps(changes)
}

export function pushCustomerInfoAction (customerInfo) {
  return [showNavbarSpinnerAction(), bind(pushInfo(customerInfo), afterSuccessActions, processError)]
}

export function markAsInactive (customerInfo) {
  const info = {
    ...customerInfo,
    state: CUSTOMER_INACTIVE
  }
  return [showNavbarSpinnerAction(), bind(pushInfo(info), afterSuccessActions)]
}

export function markAsActive (customerInfo) {
  const info = {
    ...customerInfo,
    state: CUSTOMER_ACTIVE
  }
  return [showNavbarSpinnerAction(), bind(pushInfo(info), afterSuccessActions)]
}

function pushInfo (customerInfo) {
  const data = {
    customer: decamelizeKeys(customerInfo)
  }
  data.customer.VAT_number = customerInfo.vatNumber

  return fetch(CUSTOMER_INFO, { id: customerInfo.id }, {
    method: customerInfo.id ? 'PATCH' : 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
}

function afterSuccessActions (response) {
  console.log(response)
  return [hideNavbarSpinnerAction(), clearCustomerInfoAction(), routerActions.replace('/customers')]
}

function processError () {
  return (dispatch, getState) => {
    dispatch(hideNavbarSpinnerAction())
    const t = getState().i18n.get('app', 'shared', 'messages')
    dispatch(showMessageBoxWithParamsAction('', t('unexpectedError', 'description').s))
  }
}

export function deleteCustomerAction (id) {
  return [showNavbarSpinnerAction(), bind(deleteCustomer(id), afterSuccessActions, processDeleteCustomerError)]
}

function deleteCustomer (id) {
  return fetch(CUSTOMER_INFO, { id: id }, {
    method: 'DELETE'
  })
}

function processDeleteCustomerError (response) {
  return (dispatch, getState) => {
    dispatch(hideNavbarSpinnerAction())

    if (response.status === 422) {
      const t = getState().i18n.get('app', 'views', 'Customers', 'CustomerView', 'messages')
      dispatch(showMessageBoxWithParamsAction('', t('attachedCustomer').s))
    } else {
      const t = getState().i18n.get('app', 'shared', 'messages')
      dispatch(showMessageBoxWithParamsAction('', t('unexpectedError', 'description').s))
    }
  }
}

export function pushCustomerInfoForInvoiceAction (customerInfo) {
  return [showNavbarSpinnerAction(), bind(pushInfo(customerInfo), saveToInvoice)]
}

function saveToInvoice (response) {
  const customer = {
    id: response.value.entity.id,
    name: response.value.entity.name,
    email: response.value.entity.email,
    customerType: response.value.entity.customer_type
  }
  return [hideNavbarSpinnerAction(), clearCustomerInfoAction(), invoiceUpdatePropsAction({customer}), routerActions.go(-2)]
}

export function pushCustomerInfoForProjectAction (customerInfo) {
  return [showNavbarSpinnerAction(), bind(pushInfo(customerInfo), updateProjectProps)]
}

function updateProjectProps (response) {
  return [
    clearCustomerInfoAction(),
    hideNavbarSpinnerAction(),
    change('project', 'customerId', response.value.entity.id),
    change('project', 'customerName', response.value.entity.name),
    routerActions.go(-2)
  ]
}
