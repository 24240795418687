import './PaymentAddForm.scss?global'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { reduxForm, Field, formValueSelector } from 'redux-form'
import moment from 'moment'

// Components
import Input from 'shared/components/FormInput'
import Button from 'shared/components/Button/Button'
import DropDown from 'shared/components/FormDropdown/'
import FormatNumber from 'shared/components/FormatNumber/FormatNumber'

// Actions
import { addPaymentAction } from 'Payments/actions'

// Constants
import { isExpense } from 'Events/shared/constants/eventType'

class PaymentAddForm extends Component {
  static propTypes = {
    savePayment: PropTypes.func,
    formValues: PropTypes.object,
    t: PropTypes.func,
    handleSubmit: PropTypes.func,
    paymentMethods: PropTypes.arrayOf(PropTypes.object),
    eventType: PropTypes.number,
    eventBalance: PropTypes.number
  }

  constructor (props) {
    super(props)
    this.savePayment = this.savePayment.bind(this)
  }

  static defaultProps = { formValues: { amount: 0 } }

  savePayment (values) {
    this.props.savePayment(values, isExpense(this.props.eventType))
  }

  render () {
    const { formValues: { amount }, t, eventBalance, handleSubmit, paymentMethods } = this.props
    const remainingAmount = Number(eventBalance) - Number(amount)

    return (
      <div className='f-column'>
        <div className='form f-column-top'>
          <div className='form__item'>
            <Field
              name='paymentMethodId'
              component={DropDown}
              big
              options={paymentMethods}
              hint={t('props', 'paymentMethod').s} emptyOption={t('props', 'paymentMethod').s}
            />
          </div>
          <div className='form__item form__input-select payment-add__amount-field'>
            <Field component={Input} name='date' placeholder={t('props', 'date').s} type='date' />
            <Field component={Input} name='amount' placeholder={t('props', 'amount').s} type='float' />
          </div>
          { remainingAmount ? <div className='payment-status__amount-to-pay'>
            {t('tabs', 'paymentStatus', 'remainingAmount').s}: <FormatNumber value={remainingAmount} />
          </div> : null }
          <div className='form__item'>
            <Button saveButton onClick={handleSubmit(this.savePayment)} view='transparent-black'>{t('buttons', 'addPaymentDetails').s}</Button>
          </div>
        </div>
      </div>
    )
  }
}

const formName = 'paymentAdd'
const selector = formValueSelector(formName)

function mapStateToProps (state) {
  return {
    initialValues: {
      date: moment().format('YYYY-MM-DD'),
      amount: Math.abs(state.events.newEvent.balance) || 0
    },
    eventBalance: Math.abs(state.events.newEvent.balance),
    paymentMethods: state.userSettings.expensePaymentMethods,
    t: state.i18n.get('app', 'views', 'ExpenseView'),
    eventType: state.events.newEvent.eventType,
    formValues: selector(state, 'amount')
  }
}

function mapDispatchToProps (dispatch, ownProps) {
  return {
    savePayment: (paymentInfo, isExpense) => { dispatch(addPaymentAction(paymentInfo, ownProps.eventId, isExpense)) }
  }
}

function validate (values) {
  let errors = {}
  if (!values.paymentMethodId) {
    errors.paymentMethodId = true
  }
  if (!values.date) {
    errors.date = true
  }
  if (!values.amount) {
    errors.amount = true
  }

  return errors
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: formName,
  validate,
  enableReinitialize: true
}, mapStateToProps, mapDispatchToProps)(PaymentAddForm))
