import React from 'react'
import { Route } from 'react-router'

import {requireAuthentication} from 'User/Auth/higherOrderComponents/AuthComponent'

import TimesheetsView from 'Timesheet/views/TimesheetsView/TimesheetsView'
import TimesheetView from 'Timesheet/views/TimesheetView/TimesheetView'
import TimesheetsImportView from 'Timesheet/views/TimesheetsImportView/TimesheetsImportView'

// Form-cleaning components
import createClearFormComponent from 'shared/factories/ClearForm'
import { cleanTimesheetAction } from 'Timesheet/actions/timesheetsList'
const ClearTimesheetForm = createClearFormComponent(cleanTimesheetAction)

const timesheetsRoutes = (
  <Route>
    <Route path='/timesheets' component={requireAuthentication(TimesheetsView)} />
    <Route path='/timesheets/import' component={requireAuthentication(TimesheetsImportView)} />
    <Route component={ClearTimesheetForm} >
      <Route path='/timesheets/new' component={requireAuthentication(TimesheetView)} />
      <Route path='/timesheets/:timesheetId/edit' component={requireAuthentication(TimesheetView)} />
    </Route>
  </Route>
)

export default timesheetsRoutes
