import PropTypes from 'prop-types'
import React, { Component } from 'react'

// Components
import Navigation from 'Navigation/Navigation'
import Representation from 'Events/Expense/views/RepresentationView/components/Representation'

// Containers
import RepresentationContainer from 'Events/Expense/views/RepresentationView/containers/Common'

class RepresentationView extends Component {
  static propTypes = {
    t: PropTypes.func
  }
  render () {
    const {t} = this.props
    return (
      <Navigation title={t('title').s} className='f-column' hideFooter>
        <Representation {...this.props} />
      </Navigation>
    )
  }
}

export default RepresentationContainer(RepresentationView)
