import axios from 'api-client-connector/lib'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { API_BASE } from 'shared/constants/Api'
import './RoaringCompanies.scss?global'

class RoaringCompanies extends Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    personalNumber: PropTypes.string,
    t: PropTypes.func.isRequired
  }

  componentDidMount () {
    this.fetchRoaringCompanies()
  }

  fetchCompany = async company => {
    try {
      this.setState({ company: { error: null, pending: true, value: {} } })
      const orgNumber = company.org_number.replace(/\D/g, '')
      const { data, status } = await axios(`${API_BASE}/api/v2/company_info_by_query/?org_number=${orgNumber}`)
      if (status >= 400) {
        return this.setState({ company: { error: data, pending: false, value: {} } })
      }
      this.setState({ company: { error: null, pending: false, value: data } })
    } catch (error) {
      this.setState({ company: { error, pending: true, value: {} } })
      console.error(error)
    }
  }

  fetchRoaringCompanies = async () => {
    try {
      this.setState({ companies: { error: null, pending: true, value: new Map() } })
      const { data, status } = await axios(`${API_BASE}/api/v2/roaringio-fetcher/engagements/${this.props.personalNumber}`)
      if (status >= 400) {
        return this.setState({ companies: { error: data, pending: false, value: new Map() } })
      }
      const value = new Map(data.map(company => [company.org_number, company]))
      this.setState({ companies: { error: null, pending: false, value } })
    } catch (error) {
      this.setState({ companies: { error, pending: false, value: new Map() } })
      console.error(error)
    }
  }

  fetchRoaringCompany = async company => {
    try {
      this.setState({ company: { error: null, pending: true, value: {} } })
      const { data, status } = await axios(`${API_BASE}/api/v2/roaringio-fetcher/companies-info/${company.org_number}`)
      if (status >= 400) {
        return this.setState({ company: { error: data, pending: false, value: {} } })
      }
      this.setState({ company: { error: null, pending: false, value: data } })
    } catch (error) {
      this.setState({ company: { error, pending: true, value: {} } })
      console.error(error)
    }
  }

  render () {
    return this.state.companies.value.size === 0 ? null : (
      <div className='roaring-companies'>
        <div className='roaring-companies-instruction'>
          {this.props.t('pleaseChooseACompany').s}
        </div>
        <ul className='list-view'>
          {[...this.state.companies.value.values()].map(company => (
            <li
              className='list-view__item'
              data-key={company.org_number}
              key={company.org_number}
              onClick={this.selectCompany}
            >
              <div className='list-multiline-item'>
                <div className='list-multiline-item__row'>
                  {company.name}
                </div>
                <div className='list-multiline-item__arrow'>
                  <span className='icon-arrow-right' />
                </div>
              </div>
            </li>
          ))}
        </ul>
        <div className='roaring-companies-instruction'>
          {this.props.t('orFillInTheInformation').s}
        </div>
      </div>
    )
  }

  state = {
    companies: { error: null, pending: false, value: new Map(), pristine: true },
    company: { error: null, pending: false, value: {}, pristine: true }
  }

  selectCompany = async event => {
    const company = this.state.companies.value.get(event.currentTarget.dataset.key)
    // await this.fetchRoaringCompany(company)
    await this.fetchCompany(company)
    if (this.state.company.error) {
      console.error(this.state.company.error)
    } else {
      this.props.onChange(this.state.company.value)
    }
  }
}

export default connect(state => ({
  t: state.i18n.get('app', 'views', 'Wizard', 'RoaringCompanies')
}))(RoaringCompanies)
