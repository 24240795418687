import './TotalsVisualizationView.scss?global'

import PropTypes from 'prop-types'

import React, { Component } from 'react'
import {connect} from 'react-redux'

// Components
import Navigation from 'Navigation/Navigation'
import Grid from 'shared/components/Grid/Grid'
import FormatNumber from 'shared/components/FormatNumber/FormatNumber'
import ArcChart from 'mrshoebox-ui-components/src/components/Charts/Arc'

// Actions
import {loadProjectInfoAction, projectInfoClearAction} from '../../../actions/project'

class TotalsVisualizationView extends Component {
  static propTypes = {
    getProjectInfo: PropTypes.func,
    project: PropTypes.object,
    t: PropTypes.func
  };
  componentDidMount () {
    if (Object.keys(this.props.project).length === 0) this.props.getProjectInfo()
  }
  render () {
    const {project: {totalIncome, totalOutcome, totalAmount}, t} = this.props
    return (
      <Navigation title='Totals' hideFooter className='totals-visualization-view'>
        <Grid className='header'>
          <div>
            <div>{t('revenueSek').s}</div>
            <div>
              <small>{t('includeVat').s}:</small> <b><FormatNumber value={totalIncome} /></b>
            </div>
          </div>
          <div>
            <div>{t('outcomeSek').s}</div>
            <div>
              <small>{t('excludeVat').s}:</small> <b><FormatNumber value={totalOutcome} /></b>
            </div>
          </div>
        </Grid>
        <ArcChart income={totalIncome} outcome={totalOutcome} different={totalAmount} />
      </Navigation>
    )
  }
}

function mapStateToProps (state) {
  return {
    project: state.project,
    t: state.i18n.get('app', 'views', 'ProjectView', 'overview')
  }
}

function mapDispatchToProps (dispatch, ownProps) {
  const {projectId} = ownProps.params
  return {
    getProjectInfo: () => { dispatch(loadProjectInfoAction(projectId)) },
    projectInfoClear: () => { dispatch(projectInfoClearAction()) }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TotalsVisualizationView)
