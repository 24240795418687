import React from 'react'
import { Route } from 'react-router'

import { requireAuthentication } from 'User/Auth/higherOrderComponents/AuthComponent'

import MarketplaceView from 'MarketPlace/Index/views/Index'
import FactoringInfoView from 'MarketPlace/shared/views/FactoringInfoView/FactoringInfoView'
import FactoringMoreView from 'MarketPlace/shared/views/FactoringMoreView/FactoringMoreView'
import FactoringMissingInfoView from 'MarketPlace/shared/views/FactoringMissingInfoView/FactoringMissingInfoView'
import PensionCollaborationView from 'MarketPlace/PensionCollaboration/views/PensionCollaborationView'
import LawCollaborationView from 'MarketPlace/LawCollaboration/views/LawCollaborationView'
import LawFrameView from 'MarketPlace/LawCollaboration/views/LawFrameView/'
import CapcitoCollaborationView from 'MarketPlace/Capcito/views/CapcitoCollaborationView'
import CapcitoTermsFrameView from 'MarketPlace/Capcito/views/CapcitoTermsFrameView'
import CapcitoOnboardingView from 'MarketPlace/Capcito/views/CapcitoOnboardingView'
import CapcitoFrameView from 'MarketPlace/Capcito/views/CapcitoFrameView'
import AdvertisingCollaborationView from 'MarketPlace/Advertising/views/AdvertisingCollaborationView'
import AdvertisingFrameView from 'MarketPlace/Advertising/views/AdvertisingFrameView'
import CreditsafeOnboardingView from 'MarketPlace/Creditsafe/views/CreditsafeOnboardingView'
import CreditsafeCollaborationView from 'MarketPlace/Creditsafe/views/CreditsafeCollaborationView'
import CreditsafeFrameView from 'MarketPlace/Creditsafe/views/CreditsafeFrameView'

const moreRoutes = (
  <Route>
    <Route path='/marketplace' component={requireAuthentication(MarketplaceView)} />
    <Route path='/marketplace/advertising' component={requireAuthentication(AdvertisingCollaborationView)} />
    <Route path='/marketplace/advertising/portal' component={requireAuthentication(AdvertisingFrameView)} />
    <Route path='/marketplace/factoring' component={requireAuthentication(FactoringInfoView)} />
    <Route path='/marketplace/factoring/more' component={requireAuthentication(FactoringMoreView)} />
    <Route path='/marketplace/factoring/form' component={requireAuthentication(FactoringMissingInfoView)} />
    <Route path='/marketplace/pension' component={requireAuthentication(PensionCollaborationView)} />
    <Route path='/marketplace/law' component={requireAuthentication(LawCollaborationView)} />
    <Route path='/marketplace/law/portal' component={requireAuthentication(LawFrameView)} />
    <Route path='/marketplace/capcito' component={requireAuthentication(CapcitoCollaborationView)} />
    <Route path='/marketplace/capcito/terms' component={requireAuthentication(CapcitoTermsFrameView)} />
    <Route path='/marketplace/capcito/onboarding' component={requireAuthentication(CapcitoOnboardingView)} />
    <Route path='/marketplace/capcito/portal' component={requireAuthentication(CapcitoFrameView)} />
    <Route path='/marketplace/creditsafe' component={requireAuthentication(CreditsafeCollaborationView)} />
    <Route path='/marketplace/creditsafe/onboarding' component={requireAuthentication(CreditsafeOnboardingView)} />
    <Route path='/marketplace/creditsafe/portal' component={requireAuthentication(CreditsafeFrameView)} />
  </Route>
)

export default moreRoutes
