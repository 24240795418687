import React, {Component} from 'react'

import Events from 'Events/Events/views/EventsView/containers/Smartphones'
import IpadNavigation from 'Navigation/IpadNavigation'

export default class EventsView extends Component {
  render () {
    return (
      <IpadNavigation {...this.props} onlyLeftSide setCurrentLink>
        <Events {...this.props} />
      </IpadNavigation>
    )
  }
}
