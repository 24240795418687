import Smartphones from './containers/Smartphones'
import Tablets from './containers/Tablets'

import './index.scss?global'

import { getCurrentDeviceView } from 'shared/helpers/utils'

const CapcitoOnboardingView = getCurrentDeviceView(Smartphones, Tablets)

export default CapcitoOnboardingView
