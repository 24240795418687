import React from 'react'
import PropTypes from 'prop-types'

import Button from 'shared/components/Button/Button'
import Icon from 'shared/components/Icon'
import FactoringButtons from 'MarketPlace/Collector/components/FactoringButtons/FactoringButtons'
import './FactoringMore.scss?global'

const FactoringMore = ({ t, startHandler, cancelHandler }) =>
  <div className='factoring-more'>
    <Button view='primary' onClick={startHandler}>{t('shared', 'button', 'start').s}</Button>
    <h2>{t('FactoringMore', 'factoringTitle').s}</h2>
    <div>{t('FactoringMore', 'factoringDescription').s}</div>
    <div className='hr'>
      <span className='factoring-more__delimeter'>{t('FactoringMore', 'firstDelimeter').s}</span>
    </div>
    <h1>{t('FactoringMore', 'status').s}</h1>
    <span>{t('shared', 'statusDescription').s}</span>

    <div className='factoring-more__explanation_positive'>
      <div>
        <Icon type='open-status' />
        <h1>{t('FactoringMore', 'positive').s}</h1>
      </div>
      <div className='factoring-more__description-wrapper'>
        <span>{t('FactoringMore', 'positiveDescription').s}</span>
      </div>
    </div>

    <div className='factoring-more__explanation_negative'>
      <div>
        <Icon type='cancelled-status' />
        <h1>{t('FactoringMore', 'negative').s}</h1>
      </div>
      <div className='factoring-more__description-wrapper'>
        <span>{t('FactoringMore', 'negativeDescription').s}</span>
        <span>{t('FactoringMore', 'advice').s}</span>
      </div>
    </div>

    <div className='hr'>
      <span className='factoring-more__delimeter'>{t('FactoringMore', 'secondDelimeter').s}</span>
    </div>

    <div className='factoring-more__contacts'>
      <div>Tel: <span>010-161 00 40</span></div>
      <div>E-post: <span>support.factoring@collectorbank.se</span></div>
    </div>

    <FactoringButtons
      t={t}
      startHandler={startHandler}
      cancelHandler={cancelHandler}
      cancelButtonView={'transparent-red'}
    />

  </div>

FactoringMore.propTypes = {
  startHandler: PropTypes.func.isRequired,
  cancelHandler: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
}

export default FactoringMore
