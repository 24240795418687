import React from 'react'
import PropTypes from 'prop-types'
import { reduxForm, Field, formValueSelector, change } from 'redux-form'
import { connect } from 'react-redux'
import classnames from 'classnames'

import Dropdown from 'shared/components/FormDropdown'
import SearchField from 'shared/components/FormSearchField'

const filterClassNames = classnames([
  'filter', 'filter-wrap', 'filter--without-fixed-height', 'filter--open'
])

const VacanciesListFilters = ({ roleAreaOptions, roleOptions, resetSelectedRole, selectedRoleAreaId, t }) =>
  <div className={filterClassNames}>
    <div className='filter__main-part'>
      <Field
        name='roleAreaId'
        component={Dropdown}
        options={roleAreaOptions}
        emptyOption={t('filters', 'roleArea').s}
        onChange={resetSelectedRole}
      />
      <Field
        name='roleId'
        component={Dropdown}
        options={roleOptions}
        emptyOption={t('filters', 'role').s}
        disabled={!selectedRoleAreaId}
      />
      <div className='search-field'>
        <Field
          name='title__contains'
          component={SearchField}
          changeAfterBlur
          placeholder={t('filters', 'search').s}
        />
      </div>
    </div>
  </div>

VacanciesListFilters.propTypes = {
  resetSelectedRole: PropTypes.func,
  roleAreaOptions: PropTypes.array,
  roleOptions: PropTypes.array,
  selectedRoleAreaId: PropTypes.string,
  t: PropTypes.func
}

const formName = 'vacanciesListFilters'
const selector = formValueSelector(formName)
const mapStateToProps = (state) => ({
  roleAreaOptions: state.vacancies.list.roleAreas.map(e => ({ label: e.title, value: e.id })),
  selectedRoleAreaId: selector(state, 'roleAreaId'),
  roleAreas: state.vacancies.list.roleAreas,
  t: state.i18n.get('app', 'views', 'VacanciesView')
})

const mapDispatchToProps = (dispatch) => ({
  resetSelectedRole: () => dispatch(change(formName, 'roleId', ''))
})

function mergeProps (stateProps, dispatchProps, ownProps) {
  const { roleAreas, selectedRoleAreaId } = stateProps
  const getRoleOptions = () => {
    const selectedRoleArea = roleAreas.find(e => e.id === Number(selectedRoleAreaId))
    if (selectedRoleArea) {
      return selectedRoleArea.roles.map(e => ({ label: e.title, value: e.id }))
    } else {
      return []
    }
  }

  return ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    roleOptions: getRoleOptions()
  })
}

export default reduxForm({
  form: 'vacanciesListFilters'
})(connect(mapStateToProps, mapDispatchToProps, mergeProps)(VacanciesListFilters))
