import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import IpadNavigation from 'Navigation/IpadNavigation'
import Filters from '../../../components/Filters/Filters'
import {Suppliers} from './Smartphones'

import getLeftComponent from 'Navigation/helpers/linkAccordance'

class SelectableSuppliersView extends Component {
  static propTypes = {
    t: PropTypes.func,
    hideNavbarActions: PropTypes.bool,
    previousLink: PropTypes.string,
    cameFrom: PropTypes.string
  }

  constructor (props) {
    super(props)
    this.extendList = this.extendList.bind(this)
  }

  extendList () {
    this.refs.suppliers.extendList()
  }

  render () {
    const {t, previousLink, hideNavbarActions, cameFrom} = this.props

    const RightSideItems = getLeftComponent('selectableItemList', previousLink)
    const actions = [{icon: 'icon-add', path: `/suppliers/new`, state: cameFrom}]

    return (
      <IpadNavigation {...this.props} title={t('navbar', 'title').s} filter={<Filters />} actions={!hideNavbarActions && actions} infinityScrollHandler={this.extendList} backLink>
        <RightSideItems {...this.props} />
        <Suppliers {...this.props} ref='suppliers' />
      </IpadNavigation>
    )
  }
}

function mapStateToProps (state) {
  return {
    previousLink: state.appInfo.previousLink
  }
}

export default connect(mapStateToProps)(SelectableSuppliersView)
