import React, { Component } from 'react'
import PropTypes from 'prop-types'

import IpadNavigation from 'Navigation/IpadNavigation'
import More from 'More/views/MoreView/containers/Smartphones'
import Frame from 'MarketPlace/shared/components/Frame'
import { CAPCITO_TERMS } from 'MarketPlace/shared/constants/frameUrls'

import styles from './index.scss'

export default class CapcitoTermsView extends Component {
  static propTypes = {
    t: PropTypes.func,
    selectedCategory: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.func
    ])
  }

  render () {
    const { t, selectedCategory } = this.props

    return (
      <IpadNavigation
        {...this.props}
        title={t('categories', selectedCategory).s}
        withoutScroll
        withoutMaxWidth
        backLink='/marketplace/capcito'
      >
        <More {...this.props} notRightIcon isIpad />
        <Frame
          className={styles.tabletFrame}
          url={CAPCITO_TERMS}
        />
      </IpadNavigation>
    )
  }
}
