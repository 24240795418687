import { bind } from 'redux-effects'
import { loadLang } from 'redux-pagan'

// Actions
import { addPropsToUserSettings } from 'User/Settings/actions/userSettings'
import { showNavbarSpinnerAction, hideNavbarSpinnerAction } from 'shared/actions/navbarSpinner'
import getGreyLabelOptions from 'GreyLabel/helpers/greyLabel'

// Bridges
import { saveLanguageForApp } from 'appBridge/notificators/app'
import { isBridgeAvailable } from 'appBridge/bridge'

function getLangData (locale) {
  const whitelabelLocale = whitelabelLanguage()
  if (locale === whitelabelLocale) {
    return () => import(`I18N/lang/${whitelabelLocale}.js`).then(f => f.default) // eslint-disable-line
  } else {
    return () => import('I18N/lang/en.js').then(f => f.default) // eslint-disable-line
  }
}

export function whitelabelLanguage () {
  return getGreyLabelOptions().language
}

export default function initializeLanguage () {
  return (dispatch, getState) => {
    const { userSettings: { lang = whitelabelLanguage() } } = getState()

    let actions = isBridgeAvailable()
      ? [setLanguage(lang)]
      : [setLanguage(lang), saveLanguage(lang)]

    return dispatch(actions)
  }
}

export function saveLanguage (newLang) {
  if (isBridgeAvailable()) saveLanguageForApp(newLang)

  return addPropsToUserSettings({lang: newLang})
}

export function setLanguage (locale) {
  return [
    showNavbarSpinnerAction(),
    bind(loadLang(locale, getLangData), () => hideNavbarSpinnerAction())
  ]
}
