import { bind } from 'redux-effects'
import { createAction } from 'redux-actions'
import { getCurrentClientCompany } from 'api-client-connector/utils'
import { DOCUMENTS_LIST_UPDATE } from '../constants/ActionTypes'
import { DOCUMENTS_LIST } from '../constants/Api'
import { DOCUMENTS_PER_PAGE } from '../constants'

import { fetch } from 'shared/helpers/fetch'
import { showNavbarSpinnerAction, hideNavbarSpinnerAction } from 'shared/actions/navbarSpinner'

const updateDocumentsList = createAction(DOCUMENTS_LIST_UPDATE)

export function reloadDocumentsListAction (filters) {
  return [showNavbarSpinnerAction(), bind(fetchDocuments(filters), processDocuments.bind(null, [], 1), processError)]
}

export function extendDocumentsListAction (currentList, filters, pageToLoad) {
  return [
    showNavbarSpinnerAction(),
    bind(fetchDocuments(filters, pageToLoad), processDocuments.bind(null, currentList, pageToLoad), processError)
  ]
}

function fetchDocuments (filters, pageToLoad = 1) {
  const currentCompanyId = getCurrentClientCompany()
  const newFilters = processFilters(filters)
  return fetch(DOCUMENTS_LIST, {
    ...newFilters,
    company_id: currentCompanyId,
    limit: DOCUMENTS_PER_PAGE,
    offset: (pageToLoad - 1) * DOCUMENTS_PER_PAGE
  }, {
    method: 'GET'
  })
}

function processDocuments (oldList, pageToLoad, response) {
  console.log('processing documents')
  const documents = response.value.documents
  const newDocuments = documents.map((document) => {
    return {
      companyId: document.company_id,
      id: document.id,
      title: document.title,
      date: document.date_created,
      category: document.category ? document.category.name : ''
    }
  })
  const updatedList = oldList.concat(newDocuments)

  return [
    updateDocumentsList({
      documents: updatedList,
      hasNextPage: (response.value.number_of_documents / DOCUMENTS_PER_PAGE) > pageToLoad,
      lastPageLoaded: pageToLoad
    }),
    hideNavbarSpinnerAction()
  ]
}

function processError (response) {
  console.error('documents-processError', response)
}

function processFilters (filters) {
  if (!filters) return {}

  const res = {
    sort: filters.orderBy && `${filters.orderType === 'desc' ? '-' : ''}${filters.orderBy}`
  }
  if (filters.search) {
    res['title__icontains'] = filters.search
  }
  return res
}
